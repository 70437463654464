import LogoLightWord from "../../../assets/brand/logos/LogoLightWord.png";

const LINKS = [
  {
    name: "Twitter",
    href: "https://twitter.com/pralenthq",
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/pralent",
  },
  // {
  //   name: "Guides",
  //   href: "/guides",
  // },
  {
    name: "Help Center",
    href: "https://help.pralent.com",
  },
  {
    name: "Contact",
    href: "/contact",
  },
  {
    name: "Privacy",
    href: "/privacy",
  },
  {
    name: "Terms of Service",
    href: "/terms",
  },
];

export default function Footer() {
  return (
    <footer>
      <div className="mx-auto max-w-6xl py-12 px-4 md:flex md:items-center md:justify-between">
        <div className="flex flex-wrap justify-center space-x-6 md:order-2">
          {LINKS.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="text-base font-medium text-gray-400 duration-300 ease-in-out hover:text-white"
            >
              {link.name}
            </a>
          ))}
        </div>
        <div className="mt-8 text-center md:order-1 md:mt-0 md:text-left">
          <img src={LogoLightWord} alt="logo" className="h-6 w-auto" />
          <p className="flex items-center text-sm text-gray-400">
            Designed on 🌎
          </p>
        </div>
      </div>
    </footer>
  );
}
