import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";

import { getRegistrations } from "../../../redux/actions/challenge";

import Button from "../../../shared/Button";

const headers = [
  { label: "Name", key: "teamName" },
  { label: "Username", key: "userName" },
  { label: "Email", key: "email" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "School", key: "school" },
  { label: "Degree", key: "degree" },
  { label: "Field of Study", key: "fieldOfStudy" },
  { label: "Completion Year", key: "completionYear" },
  { label: "Registration Date", key: "registeredDateTime" },
  { label: "Pralent Profile", key: "pralentLink" },
];

const createRegistrationDownloadData = (registrations) => {
  let data = [];
  const length =
    registrations && registrations.length > 0 ? registrations.length : 0;
  for (let i = 0; i < length; i++) {
    const registration = registrations[i];
    const team = registration.team;
    if (team && team.members && team.members.length > 0) {
      const members = team.members;
      for (let memberIndex = 0; memberIndex < members.length; memberIndex++) {
        const user = members[memberIndex].user;
        const profile = members[memberIndex].profile;
        const row = {
          teamName: (team && team.name) || "",
          userName: (user && user.userName) || "",
          email: (user && user.email) || "",
          firstName: (profile && profile.firstName) || "",
          lastName: (profile && profile.lastName) || "",
          school:
            (profile &&
              profile.education &&
              profile.education.length > 0 &&
              profile.education[0].school) ||
            "",
          degree:
            (profile &&
              profile.education &&
              profile.education.length > 0 &&
              profile.education[0].degree) ||
            "",
          fieldOfStudy:
            (profile &&
              profile.education &&
              profile.education.length > 0 &&
              profile.education[0].fieldOfStudy) ||
            "",
          completionYear:
            (profile &&
              profile.education &&
              profile.education.length > 0 &&
              profile.education[0].completionDate &&
              new Date(profile.education[0].completionDate).getFullYear()) ||
            "",
          registeredDateTime:
            (registration.registeredDateTime &&
              new Date(registration.registeredDateTime).toLocaleDateString()) ||
            "",
          pralentLink:
            (profile && user && `https://www.pralent.com/@${user.userName}`) ||
            "",
        };
        data.push(row);
      }
    }
  }
  return data;
};

const ChallengeRegistrationsDownload = ({
  challenge,
  registrations,
  getRegistrations,
}) => {
  const [registrationExport, setRegistrationExport] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted && registrations) {
      const res = createRegistrationDownloadData(registrations);
      setRegistrationExport(res);
    }
    return () => (mounted = false);
  }, [registrations]);

  const fetchRegistrations = async () => {
    await getRegistrations(challenge.id);
  };

  return (
    <Box display="flex" alignItems="center">
      <CSVLink
        data={registrationExport}
        headers={headers}
        filename={`${challenge && challenge.title} Registrations.csv`}
        target="_blank"
      >
        <Tooltip
          title={"Export all registration team information in .csv file"}
          placement="top"
        >
          <Button color="primary" variant="text" startIcon={<GetAppIcon />}>
            Export
          </Button>
        </Tooltip>
      </CSVLink>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  registrations: state.challenge.registrations,
});

const mapDispatchToProps = { getRegistrations };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeRegistrationsDownload);
