import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { createUpdateJobBoard } from "../../../redux/actions/jobs";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Divider from "@material-ui/core/Divider";
// Components
import Button from "../../../shared/Button";

export const JobBoardSettingsView = ({
  jobBoard: {
    loading: jobBoardLoading,
    data: jobBoard,
    error: jobBoardError,
  } = { loading: false, data: null, error: null },
  companyProfile,
  createUpdateJobBoard,
}) => {
  const [jobBoardFormData, setJobBoardFormData] = useState({
    title: "",
    description: "",
    allowOutsideOrganizations: false,
    outsideOrganizationsNeedApproval: false,
  });

  useEffect(() => {
    if (jobBoard) {
      setJobBoardFormData({
        ...jobBoardFormData,
        id: jobBoard.id,
        title: jobBoard.title,
        description: jobBoard.description,
        allowOutsideOrganizations: jobBoard.allowOutsideOrganizations,
        outsideOrganizationsNeedApproval:
          jobBoard.outsideOrganizationsNeedApproval,
      });
    }
  }, [jobBoard]);

  const handleJobBoardChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setJobBoardFormData({
      ...jobBoardFormData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createUpdateJobBoard(companyProfile.id, jobBoardFormData);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box p={{ xs: 2, md: 4, lg: 6, xl: 8 }}>
        <Container maxWidth="sm">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                <Box fontWeight={600}>Job Board</Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box mb={2}>Manage job board settings</Box>
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h3">
                <Box fontWeight={600}>Overview</Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Title</InputLabel>
                <TextField
                  placeholder="Enter a title for your job board"
                  id="title"
                  name="title"
                  required
                  value={jobBoardFormData.title}
                  onChange={handleJobBoardChange}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Description</InputLabel>
                <TextField
                  placeholder="Enter a description for your job board"
                  id="description"
                  name="description"
                  multiline
                  rows={8}
                  value={jobBoardFormData.description}
                  onChange={handleJobBoardChange}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h3">
                <Box fontWeight={600}>Privacy</Box>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="allowOutsideOrganizations"
                      name="allowOutsideOrganizations"
                      color="primary"
                      checked={jobBoardFormData.allowOutsideOrganizations}
                      onChange={handleJobBoardChange}
                    />
                  }
                  label={
                    <Box ml={2}>
                      <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight={600}>
                          Allow outside organizations to post in your job board
                        </Box>
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        <Box lineHeight={1.2}>
                          This setting is recommended for organization that have
                          communities on Pralent. Enabling this option will
                          allow other individuals that are part of your
                          community to post jobs onto your job board. Keep this
                          disabled if you are an employer using the job board
                          functionalities.
                        </Box>
                      </Typography>
                    </Box>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  disabled={!jobBoardFormData.allowOutsideOrganizations}
                  control={
                    <Switch
                      id="outsideOrganizationsNeedApproval"
                      name="outsideOrganizationsNeedApproval"
                      color="primary"
                      checked={
                        jobBoardFormData.outsideOrganizationsNeedApproval
                      }
                      onChange={handleJobBoardChange}
                    />
                  }
                  label={
                    <Box ml={2}>
                      <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight={600}>
                          Outside organizations need approval before posting in
                          your board
                        </Box>
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        <Box lineHeight={1.2}>
                          This setting is only available if outside
                          organizations are allowed to post in your job board.
                          This would ensure that every job post needed approval
                          from your organization before being available for your
                          community to view.
                        </Box>
                      </Typography>
                    </Box>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit">Save</Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </form>
  );
};

const mapStateToProps = (state) => ({
  jobBoard: state.jobs.jobBoard,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { createUpdateJobBoard };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobBoardSettingsView);
