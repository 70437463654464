import React from "react";
// react-share
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
// Material UI
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
// Material UI Icons
import ShareIcon from "@material-ui/icons/Share";

const ShareIcons = ({ url, title }) => {
  return (
    <>
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32} style={{ marginRight: "2px" }} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={32} style={{ marginRight: "2px" }} round />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title} separator=" - ">
        <WhatsappIcon size={32} style={{ marginRight: "2px" }} round />
      </WhatsappShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon size={32} style={{ marginRight: "2px" }} round />
      </LinkedinShareButton>
      <RedditShareButton
        url={url}
        title={title}
        windowWidth={660}
        windowHeight={460}
      >
        <RedditIcon size={32} style={{ marginRight: "2px" }} round />
      </RedditShareButton>
      <EmailShareButton url={url} subject={title} body="">
        <EmailIcon size={32} style={{ marginRight: "2px" }} round />
      </EmailShareButton>
    </>
  );
};

const SocialMediaShare = ({ title, url, popover = true }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? "social-media-share-popover" : undefined;

  if (!popover) {
    return <ShareIcons url={url} title={title} />;
  }

  return (
    <>
      <Tooltip title="Share to social platforms">
        <IconButton aria-describedby={id} color="default" onClick={handleClick}>
          <ShareIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box display="flex" alignItems="center" width="100%" p={2}>
          <ShareIcons url={url} title={title} />
        </Box>
      </Popover>
    </>
  );
};

export default SocialMediaShare;
