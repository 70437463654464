import React, { useState } from "react";
// React query
import { useMutation, useQueryClient } from "react-query";
import { addEditPost } from "../../../hooks/room";
// Context
import { useRoomContext } from "./RoomContext";
// Material UI
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core";
// Icons
import SendIcon from "@material-ui/icons/Send";
// Utils
import { POST_TYPES, ROOM_TYPE } from "../../../utils/globalValues";
// Rich text
import RichTextEditor from "../../RichTextEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "sticky",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
  inputBox: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    width: "100%",
    overflow: "auto",
    maxHeight: "50vh",
    backgroundColor: theme.palette.background.paper,
    borderWidth: "0.25px",
    borderRadius: theme.shape.borderRadius + 5,
    borderColor: theme.palette.action.disabled,
    "&:hover": {
      transition: "0.2s",
      borderColor: theme.palette.action.active,
    },
    "&:focus": {
      transition: "0.2s",
      borderColor: theme.palette.action.active,
    },
  },
}));

const POST_FORM_DATA_DEFAULT = {
  title: "",
  text: "",
  typeId: POST_TYPES.MESSAGE,
};

const PostForm = ({ isReply, parentPostId }) => {
  const classes = useStyles();

  const { room } = useRoomContext();

  const [postFormData, setPostFormData] = useState(POST_FORM_DATA_DEFAULT);

  const addEditPostMutation = useMutation(addEditPost, {
    onSuccess: (data) => {
      console.log(data);
    },
  });

  const onChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    setPostFormData({
      ...postFormData,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let finalPostDetails = {
      ...postFormData,
    };
    if (isReply) {
      finalPostDetails = {
        ...finalPostDetails,
        isReply: true,
        parentPostId: parentPostId,
      };
    }
    addEditPostMutation.mutate({
      roomId: room.id,
      postDetails: finalPostDetails,
    });
    setPostFormData(POST_FORM_DATA_DEFAULT);
  };

  return (
    <Box className={classes.root}>
      <Box>
        <form onSubmit={onSubmit}>
          {/* {room.type !== ROOM_TYPE.CHAT && (
            <InputBase
              value={postFormData.title}
              id="title"
              name="title"
              placeholder={`Send a ${isReply ? "reply" : "message"} to ${
                room.name
              }`}
              fullWidth
              onChange={onChange}
              autoFocus
              // multiline
            />
          )} */}
          <RichTextEditor
            value={postFormData.text}
            placeholder={`Send a ${isReply ? "reply" : "message"} to ${
              room.name
            }`}
            setValue={(value) =>
              setPostFormData({
                ...postFormData,
                text: value,
              })
            }
          />
          <Box display="flex" justifyContent="end">
            <IconButton type="submit">
              <SendIcon />
            </IconButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default PostForm;
