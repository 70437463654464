import React, { useState } from "react";
// React router
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// React query
import { useMutation, useQueryClient } from "react-query";
import { joinCommunity, useGetCommunityMember } from "../../hooks/community";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
// Components
import Button from "../Button";
import RichTextEditor from "../RichTextEditor";
// Utils
import { MEMBER_STATUS } from "../../utils/globalValues";
import LogInForm from "../LogInForm";

const JoinCommunity = ({ user, isAuthenticated, companyDetails }) => {
  const queryClient = useQueryClient();

  const {
    isLoading,
    data: { data: communityMember } = { data: null },
    isError,
    error,
  } = useGetCommunityMember(companyDetails);

  const joinCommunityMutation = useMutation(joinCommunity, {
    onSuccess: async (data) => {
      if (data && data.data) {
        await queryClient.setQueryData(
          ["communityMember", { companyId: companyDetails.id }],
          data
        );
        await queryClient.invalidateQueries(["myCommunities", user?.id]);

        await queryClient.invalidateQueries([
          "myCommunityRooms",
          {
            communityId: companyDetails.id,
            userAccountId: user.id,
          },
        ]);
      }
      toggle();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [isPublicToCommunity, setIsPublicToCommunity] = useState(true);

  const [joinModal, setJoinModal] = useState(false);

  const handleJoinCommunity = async (e) => {
    e.preventDefault();
    await joinCommunityMutation.mutate({
      companyId: companyDetails.id,
      isPublicToCommunity: isPublicToCommunity,
    });
  };

  const toggle = async () => {
    await queryClient.invalidateQueries([
      "communityMember",
      { companyId: companyDetails.id },
    ]);

    if (!communityMember) {
      setJoinModal(!joinModal);
    } else {
      setJoinModal(false);
    }
  };

  return (
    <>
      <Dialog
        open={joinModal}
        onClose={toggle}
        aria-labelledby="community-join-dialog-title"
      >
        <form onSubmit={handleJoinCommunity}>
          <DialogContent>
            <Typography gutterBottom variant="body1" component="div">
              <Box fontWeight={600} mb={1}>
                Join Community
              </Box>
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id="isPublicToCommunity"
                    name="isPublicToCommunity"
                    color="primary"
                    checked={isPublicToCommunity}
                    onChange={(e) => setIsPublicToCommunity(e.target.checked)}
                  />
                }
                label={
                  <Box ml={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                      <Box fontWeight={600}>
                        I would like to be included in the member directory
                      </Box>
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        If you select this option, other members of the
                        community will be able to see your profile and be able
                        to contact you.
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Confirm
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isError && (
        <Alert severity="error">
          {communityMember && communityMember.data && communityMember.data.msg}
        </Alert>
      )}
      {communityMember ? (
        <>
          {communityMember.isBanned ? (
            <Alert severity="error">
              You are banned from this community. If this is a problem, please
              contact the community organizers or contact support.
            </Alert>
          ) : communityMember.approvalStatus === MEMBER_STATUS.DENIED ? (
            <Alert severity="error">
              Unfortunately, you have been denied access to this community. If
              this is a problem, please contact the community organizers or
              contact support.
            </Alert>
          ) : companyDetails.doMembersNeedApproval &&
            communityMember.approvalStatus === MEMBER_STATUS.PENDING ? (
            <Alert severity="info">
              This community requires that members are approved by the community
              organizers. The community organizers are reviewing your profile
              and will get back to you shortly.
            </Alert>
          ) : (
            <Box
              p={1.5}
              borderRadius="borderRadius"
              bgcolor="background.paper"
              display="flex"
              alignItems="center"
            >
              <Box lineHeight={1.2}>
                {companyDetails.communityWelcomeMessage ? (
                  <RichTextEditor
                    value={companyDetails.communityWelcomeMessage}
                    readOnly
                  />
                ) : (
                  `Welcome to the ${companyDetails.companyName} community!`
                )}
              </Box>
            </Box>
          )}
        </>
      ) : (
        companyDetails.isCommunityEnabled && (
          <>
            {isAuthenticated ? (
              <Button onClick={toggle} size="large">
                {companyDetails.doMembersNeedApproval
                  ? "Request to Join"
                  : "Join Community"}
              </Button>
            ) : (
              <Box maxWidth={450}>
                <LogInForm
                  signup={true}
                  actionCallback={toggle}
                  buttonText={
                    companyDetails.doMembersNeedApproval
                      ? "Request to Join"
                      : "Join Community"
                  }
                />
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JoinCommunity);
