import React, { useState, Fragment } from "react";
// React router
import { useParams, Link, useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { addUpdateChallenge } from "../../redux/actions/challenge";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import isBefore from "date-fns/isBefore";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import "react-datetime/css/react-datetime.css";
import {
  convertTimeToUTC,
  convertUTCTimeToZonedTime,
  getActiveTimeZones,
} from "../../utils/utilFunc";
// Material-ui-color
import { ColorPicker } from "material-ui-color";
// Helper Functions
import { useTheme } from "@material-ui/core/styles";
// Components
import Button from "../../shared/Button";
import {
  DEFAULT_PRIMARY_COLOR,
  MAX_PER_TEAM_OPTION,
  SUBMISSION_TYPE_ID,
  DEFAULT_TIME_ZONE,
} from "../../utils/globalValues";

const FORMGROUP_CLASS = "my-4";

const TOTAL_STEPS = 1;

const OrgChallengeCreate = ({ company, addUpdateChallenge, setAlert }) => {
  const { orgId } = useParams();
  const history = useHistory();

  const [openChallengeModal, setOpenChallengeModal] = useState(false);
  const theme = useTheme();

  const [teamMemberOption, setTeamMemberOption] = useState(
    MAX_PER_TEAM_OPTION.TEAM
  );
  const [challengeFormData, setChallengeFormData] = useState({
    title: "",
    slug: "",
    tagLine: "",
    totalPrizeValue: "",
    categoryTypes: "",
    location: "",
    isPublic: false,
    isActive: false,
    numberOfRounds: 1,
    maxPeopleTeam: null,
    timeZone: DEFAULT_TIME_ZONE,
    companyId: company && company.id,
    registrationDeadlineDatetime: null,
    primaryColor: DEFAULT_PRIMARY_COLOR,
  });

  const [roundFormData, setRoundFormData] = useState([
    {
      name: "",
      roundNumber: 1,
      isCurrentRound: true,
      isFinalRound: true,
      startDatetime: null,
      endDatetime: null,
      lateSubmissionAllowed: false,
      lateSubmissionDateTime: null,
      submissionTypeId: SUBMISSION_TYPE_ID.CUSTOM,
    },
  ]);

  const handleRoundChange = (e, index) => {
    // e.preventDefault();
    let oldRounds = [...roundFormData];
    let selectedRound = { ...oldRounds[index] };
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.name === "submissionTypeId") {
      value = parseInt(e.target.value);
    }

    selectedRound[e.target.name] = value;
    oldRounds[index] = selectedRound;

    if (e.target.name === "lateSubmissionAllowed") {
      oldRounds[index].lateSubmissionDateTime = null;
    }
    setRoundFormData([...oldRounds]);
  };

  const handleRoundDateChange = (name, date, index) => {
    let oldRounds = [...roundFormData];
    let selectedRound = { ...oldRounds[index] };
    selectedRound = {
      ...selectedRound,
      [name]: date,
    };
    oldRounds[index] = { ...selectedRound };
    setRoundFormData(oldRounds);
  };

  const handleChallengeChange = (e) => {
    // e.preventDefault();

    let value;
    if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    if (e.target.name === "slug") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }
    setChallengeFormData({
      ...challengeFormData,
      [e.target.name]: value,
    });
  };

  const handleTeamMemberRadioChange = (e) => {
    setTeamMemberOption(e.target.value);
    if (e.target.value) {
      if (e.target.value === MAX_PER_TEAM_OPTION.INDIVIDUAL) {
        setChallengeFormData({
          ...challengeFormData,
          maxPeopleTeam: 1,
        });
      } else {
        setChallengeFormData({
          ...challengeFormData,
          maxPeopleTeam: null,
        });
      }
    }
  };

  const handlePrimaryColorChange = (color) => {
    if (color && color.css && color.css.backgroundColor) {
      // Save the selected primary color to the local state
      setChallengeFormData({
        ...challengeFormData,
        primaryColor: color.css.backgroundColor,
      });
    }
  };

  const handleTimeZoneChange = (e) => {
    const timeZone = e.target.value ? e.target.value : DEFAULT_TIME_ZONE;
    setChallengeFormData({
      ...challengeFormData,
      timeZone: timeZone,
    });
  };
  const [step, setStep] = useState(1);

  const clearFormData = () => {
    setRoundFormData([
      {
        name: "",
        roundNumber: 1,
        isCurrentRound: true,
        isFinalRound: true,
        startDatetime: null,
        endDatetime: null,
        lateSubmissionAllowed: false,
        lateSubmissionDateTime: null,
      },
    ]);
    setChallengeFormData({
      title: "",
      slug: "",
      tagLine: "",
      totalPrizeValue: "",
      categoryTypes: "",
      location: "",
      isPublic: false,
      isActive: false,
      numberOfRounds: 1,
      timeZone: DEFAULT_TIME_ZONE,
      registrationDeadlineDatetime: null,
      maxPeopleTeam: null,
      companyId: company && company.id,
      primaryColor: DEFAULT_PRIMARY_COLOR,
    });
  };

  const toggle = () => {
    setOpenChallengeModal(!openChallengeModal);
    setStep(1);
    clearFormData();
  };

  const validateChallengeInfo = () => {
    if (
      challengeFormData.registrationDeadlineDatetime &&
      isBefore(challengeFormData.registrationDeadlineDatetime, new Date())
    ) {
      setAlert("Please select a registration deadline in the future", "error");
      return false;
    }

    for (let round of roundFormData) {
      const startDatetime =
        round.startDatetime && new Date(round.startDatetime);
      const endDatetime = round.endDatetime && new Date(round.endDatetime);
      const lateSubmissionAllowed = round.lateSubmissionAllowed;
      const lateSubmissionDateTime =
        round.lateSubmissionDateTime && new Date(round.lateSubmissionDateTime);

      if (
        startDatetime &&
        endDatetime &&
        isBefore(endDatetime, startDatetime)
      ) {
        setAlert(
          "Please ensure the round start date is before the end date.",
          "error"
        );
        return false;
      }
      if (lateSubmissionAllowed && !endDatetime) {
        setAlert("Please add an end date for the round.", "error");
        return false;
      }
      if (lateSubmissionAllowed && !lateSubmissionDateTime) {
        setAlert("Please add a late submission date for the round.", "error");
        return false;
      }

      if (
        lateSubmissionAllowed &&
        isBefore(lateSubmissionDateTime, endDatetime)
      ) {
        setAlert(
          "Please ensure the round late submission date is after the end date.",
          "error"
        );
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (step === TOTAL_STEPS && validateChallengeInfo()) {
      const body = {
        ...challengeFormData,
        round: [...roundFormData],
      };

      challengeFormData.registrationDeadlineDatetime = convertTimeToUTC(
        challengeFormData.registrationDeadlineDatetime,
        challengeFormData.timeZone
      );
      const res = await addUpdateChallenge(body);
      if (res) {
        toggle();
        history.push(`/org/${orgId}/programs`);
      }
    }
    if (step < TOTAL_STEPS) {
      setStep((step) => step + 1);
    }
  };

  const {
    title,
    slug,
    tagLine,
    totalPrizeValue,
    categoryTypes,
    location,
    isPublic,
    isActive,
    numberOfRounds,
    timeZone,
    registrationDeadlineDatetime,
    maxPeopleTeam,
    primaryColor,
    companyId,
  } = challengeFormData;
  return (
    <Fragment>
      <form onSubmit={(e) => onSubmit(e)}>
        <Container maxWidth="sm">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                component={Link}
                to={`/org/${orgId}/programs`}
              >
                <ArrowBackIcon />{" "}
                <Typography variant="body2" component="div">
                  <Box ml={1}>Back to programs</Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                <Box fontWeight={600}>Create new program</Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box>
                  Let's get started creating your program with just a few simple
                  steps.
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <span className="mr-2">Title</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Make your program title standout
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter your program title"
                type="text"
                required
                onChange={handleChallengeChange}
                name="title"
                value={title}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <span className="mr-2">Tagline</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Quick sentence about your program
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter a short tagline"
                type="text"
                required
                onChange={handleChallengeChange}
                name="tagLine"
                value={tagLine}
              />
            </Grid>
            {/* <Grid item xs={12}>
                  <InputLabel>
                    <span className="mr-2">Custom Url</span>
                    <Tooltip
                      title={
                        <Fragment>
                          <p className="text-sm font-normal p-1">
                            This is the ending url for your program (i.e.
                            carnegie-mellon-pitch-competition). Make sure there
                            are no spaces.
                          </p>
                        </Fragment>
                      }
                      placement="right"
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter an url slug (your unique program url ending)"
                    type="text"
                    required
                    onChange={handleChallengeChange}
                    name="slug"
                    value={slug}
                    InputProps={{
                      startAdornment: (
                        <div className="font-semibold" position="start">
                          pralent.com/programs/
                        </div>
                      ),
                    }}
                    inputProps={{ pattern: "^[-a-zA-Z0-9@.+_]+$" }}
                  />
                </Grid> */}

            <Grid item xs={12}>
              <InputLabel>
                <span className="mr-2">Individual or Team</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Would you like to have teams with one or more members
                        per team or single person submissions?
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <RadioGroup
                aria-label="teamSize"
                name="teamSize"
                value={teamMemberOption}
                onChange={handleTeamMemberRadioChange}
              >
                <FormControlLabel
                  value={MAX_PER_TEAM_OPTION.INDIVIDUAL}
                  control={<Radio />}
                  label={MAX_PER_TEAM_OPTION.INDIVIDUAL}
                />
                <FormControlLabel
                  value={MAX_PER_TEAM_OPTION.TEAM}
                  control={<Radio />}
                  label={MAX_PER_TEAM_OPTION.TEAM}
                />
              </RadioGroup>
            </Grid>
            {/* <Grid item xs={12}>
                  <InputLabel>
                    <span className="mr-2">Location</span>
                    <Tooltip
                      title={
                        <Fragment>
                          <p className="text-sm font-normal p-1">
                            Where will this program occur? Will it happen
                            remotely?
                          </p>
                        </Fragment>
                      }
                      placement="right"
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter the location (i.e. Philadelphia or Remote)"
                    type="text"
                    onChange={handleChallengeChange}
                    name="location"
                    value={location}
                  />
                </Grid> */}
            <Grid item xs={12}>
              <InputLabel>
                <span className="mr-2">Time Zone</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Select the Time Zone to be used for all the dates and
                        times set in this program (Default: America/New_York).
                        This Time Zone will be effective and set for
                        "Registration Deadline Date Time", "Start Date Time",
                        "End Date Time" and "Late Submission Date Time" at all
                        the program round levels.
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <Select
                labelId="timeZone-label"
                id="timeZone-select"
                value={timeZone}
                margin="dense"
                variant="outlined"
                onChange={handleTimeZoneChange}
              >
                {getActiveTimeZones().map((tzName) => (
                  <MenuItem key={tzName} value={tzName}>
                    {tzName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>
                <span className="mr-2">Registration Deadline Date & Time</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        This datetime corresponds to the last datetime that
                        people have to register by. If they are not registered
                        by this datetime, then they cannot submit. Leave blank
                        if you don't want to have a deadline.
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  fullWidth
                  name="registrationDeadlineDatetime"
                  inputVariant="outlined"
                  value={registrationDeadlineDatetime}
                  onChange={(date) => {
                    setChallengeFormData({
                      ...challengeFormData,
                      ["registrationDeadlineDatetime"]: date,
                    });
                  }}
                  onError={console.log}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setChallengeFormData({
                              ...challengeFormData,
                              ["registrationDeadlineDatetime"]: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <>
              {roundFormData &&
                roundFormData.map((roundData, index) => {
                  return (
                    <Fragment key={index}>
                      <Grid item xs={12}>
                        <Divider light />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h2">
                          <Box fontWeight={600}>Round</Box>
                        </Typography>
                        <Typography
                          variant="body2"
                          component="div"
                          color="textSecondary"
                        >
                          <Box>
                            A program must have at least one round. A round
                            corresponds to a round of submissions and have a
                            submission form, accept submissions, reviewers,
                            scores/feedback, etc. Please enter your first
                            round's information below.
                          </Box>
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel>
                          <span className="mr-2">First Round Name</span>
                          <Tooltip
                            title={
                              <Fragment>
                                <p className="p-1 text-sm font-normal">
                                  For example, Round 1 or Early Decision
                                </p>
                              </Fragment>
                            }
                            placement="right"
                          >
                            <InfoIcon fontSize="small" />
                          </Tooltip>
                        </InputLabel>
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Enter the round name"
                          type="text"
                          onChange={(e) => handleRoundChange(e, index)}
                          name="name"
                          value={roundData.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>
                          <span className="mr-2">Start Date & Time</span>
                          <Tooltip
                            title={
                              <Fragment>
                                <p className="p-1 text-sm font-normal">
                                  When does this round start? Leave blank if no
                                  start date.
                                </p>
                              </Fragment>
                            }
                            placement="right"
                          >
                            <InfoIcon fontSize="small" />
                          </Tooltip>
                        </InputLabel>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            fullWidth
                            name="startDatetime"
                            inputVariant="outlined"
                            value={roundData.startDatetime}
                            onChange={(date) => {
                              handleRoundDateChange(
                                "startDatetime",
                                date,
                                index
                              );
                            }}
                            onError={console.log}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleRoundDateChange(
                                        "startDatetime",
                                        null,
                                        index
                                      )
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>
                          <span className="mr-2">End Date & Time</span>
                          <Tooltip
                            title={
                              <Fragment>
                                <p className="p-1 text-sm font-normal">
                                  When does this round end? Last date/time for
                                  submissions. Leave blank if forever.
                                </p>
                              </Fragment>
                            }
                            placement="right"
                          >
                            <InfoIcon fontSize="small" />
                          </Tooltip>
                        </InputLabel>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DateTimePicker
                            fullWidth
                            name="endDatetime"
                            inputVariant="outlined"
                            value={roundData.endDatetime}
                            onChange={(date) => {
                              handleRoundDateChange("endDatetime", date, index);
                            }}
                            onError={console.log}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      handleRoundDateChange(
                                        "endDatetime",
                                        null,
                                        index
                                      )
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel>
                          <span className="mr-2">Select Submission Type</span>
                          <Tooltip
                            title={
                              <Fragment>
                                <p className="p-1 text-sm font-normal">
                                  Select what type of submission you would like
                                  to use for this round
                                </p>
                              </Fragment>
                            }
                            placement="right"
                          >
                            <InfoIcon fontSize="small" />
                          </Tooltip>
                        </InputLabel>
                        <RadioGroup
                          aria-label="submissionTypeId"
                          name="submissionTypeId"
                          value={roundData.submissionTypeId}
                        >
                          <FormControlLabel
                            value={1}
                            control={
                              <Radio
                                onChange={(e) => handleRoundChange(e, index)}
                              />
                            }
                            label="Custom Submission Form"
                          />
                          <FormControlLabel
                            value={2}
                            control={
                              <Radio
                                onChange={(e) => handleRoundChange(e, index)}
                              />
                            }
                            label="Pralent Project"
                          />
                        </RadioGroup>
                      </Grid>
                    </Fragment>
                  );
                })}
            </>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button type="submit">Submit</Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </form>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.companyProfile,
});
const mapDispatchToProps = { addUpdateChallenge, setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(OrgChallengeCreate);
