import React, { useState, useEffect, useRef } from "react";
// Redux
import { connect } from "react-redux";
import { getSubmissions } from "../../../redux/actions/challenge";

import { downloadResults } from "../../../redux/actions/reports";
// Material UI
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
// Components
import Button from "../../../shared/Button";
// Icons
import GetAppIcon from "@material-ui/icons/GetApp";
// Utils
import { SUBMISSION_TYPE_ID } from "../../../utils/globalValues";

import { CSVLink } from "react-csv";

const ChallengeSubmissionsDownload = ({
  challenge,
  currentRoundIndex,
  submissions,
  downloadResults,
  getSubmissions,
  criterias,
}) => {
  const [submissionExport, setSubmissionExport] = useState([]);
  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const downloadResultsReport = async () => {
    if (challenge) {
      const challengeRoundId =
        challenge && challenge.round && challenge.round[currentRoundIndex].id;
      const res = await downloadResults(challengeRoundId);
      setSubmissionExport(res);
      if (csvLink && csvLink.current) {
        csvLink.current.link.click();
      }
    }
  };

  return (
    <Box display="flex" alignItems="center">
      {challenge && submissionExport && (
        <CSVLink
          data={submissionExport}
          filename={`${challenge.title} Submission Results - Round ${
            currentRoundIndex + 1
          }.csv`}
          ref={csvLink}
          target="_blank"
        />
      )}
      <Tooltip title={"Export all submissions with scores"} placement="top">
        <Button
          variant="text"
          color="primary"
          onClick={downloadResultsReport}
          startIcon={<GetAppIcon />}
        >
          Export
        </Button>
      </Tooltip>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  submissions: state.challenge.submissions,
  criterias: state.judge.criterias,
});

const mapDispatchToProps = { getSubmissions, downloadResults };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeSubmissionsDownload);
