const colorList = [
  // index 0 is the primary color
  "#1c48f5",
  "#4438e7",
  "#5f2fdd",
  "#8a82f1",
  "#2c43f0",
  "#6b6cf3",
  "#5154ec",
  "#542ce1",
  "#4f51d7",
  "#824ba7",
];
// const redBluePurpleColorList = [
//   // index 0 is the primary color
//   "#1c48f5",
//   "#824ba7",
//   "#4f51d7",
//   "#b44b7f",
//   "#d04463",
//   "#544bcc",
//   "#c4466c",
//   "#c3a4cb",
//   "#9c74c0",
//   "#a44c84",
// ];

export default colorList;
