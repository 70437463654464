import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import CodeIcon from "@material-ui/icons/Code";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckIcon from "@material-ui/icons/Check";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
// Components
import ReactImageFallback from "react-image-fallback";
// Utils
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  legend: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  avatar: {
    width: theme.spacing(12.5),
    height: theme.spacing(12.5),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    fontSize: "30px",
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(8),
    borderColor: theme.palette.common.white,
    borderWidth: theme.spacing(0.5),
    boxShadow: theme.shadows[3],
    [theme.breakpoints.up("md")]: {
      borderWidth: theme.spacing(1),
      width: theme.spacing(20),
      height: theme.spacing(20),
      fontSize: "60px",
      marginTop: -theme.spacing(14),
    },
  },
  card: {
    width: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.h4.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  messageButton: {
    fontSize: "1rem",
    textTransform: "capitalize",
    boxShadow:
      "rgba(0, 12, 32, 0.1) 0px 0px 1px 0px, rgba(10, 31, 68, 0.13) 0px 10px 16px 0px",
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: "white",
    "&:hover": {
      boxShadow: "none",
      textDecoration: "none",
      color: "white",
      backgroundColor: theme.palette.primary.dark,
    },
    "&:focus": {
      outline: "none",
    },
  },
  header: {
    textAlign: "left",
  },
  aboutMe: {
    lineHeight: 1.6,
    letterSpacing: 0.1,
    wordSpacing: 1.5,
  },
  backgroundImage: {
    maxHeight: 300,
    width: "100%",
    boxShadow: theme.shadows[1],
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
}));

const ProfileCard = ({
  profile: {
    user: { id, userName, avatar, email, registrationDate },
    firstName,
    lastName,
    skillSets,
    education,
    location,
    publicProfileLinks,
    aboutMe,
    resumeLocation,
    needVisa,
    workAuthorization,
    veteranStatus,
    disabilityStatus,
    activelySeekingJob,
  },
  skillSet,
  user,
}) => {
  const classes = useStyles();

  const [links, setLinks] = useState({
    github: "",
    linkedin: "",
    personalWebsite: "",
  });
  useEffect(() => {
    if (publicProfileLinks && publicProfileLinks.length > 0) {
      const GITHUB = publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 1
      );
      const LINKEDIN = publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 2
      );
      const PERSONALWEBSITE = publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 3
      );
      setLinks({
        ...links,
        github: (GITHUB && GITHUB.url) || "",
        linkedin: (LINKEDIN && LINKEDIN.url) || "",
        personalWebsite: (PERSONALWEBSITE && PERSONALWEBSITE.url) || "",
      });
    }
  }, []);
  const { github, linkedin, personalWebsite } = links;
  return (
    <>
      <Box width="100%">
        <ReactImageFallback
          title="background-image"
          src={
            "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
          }
          fallbackImage={
            "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
          }
          alt={`background-image`}
          className={classes.backgroundImage}
        />

        <Container maxWidth="md">
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            p={{ xs: 1, md: 2, lg: 3 }}
          >
            <Avatar
              src={avatar}
              alt={firstName.charAt(0).toUpperCase()}
              className={classes.avatar}
            >
              {firstName
                ? firstName.charAt(0).toUpperCase()
                : userName.charAt(0).toUpperCase()}
            </Avatar>
            <Box textAlign="left">
              <Typography variant="h4" component="h1">
                <Box fontWeight={700}>
                  {firstName} {lastName}
                </Box>
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                style={{ gap: "16px" }}
                mb={2}
                mt={0.5}
                color="text.secondary"
              >
                <Typography variant="body1" component="div">
                  @{userName}
                </Typography>
                {location && (
                  <Box display="flex" alignItems="center">
                    <LocationOnOutlinedIcon fontSize="small" />{" "}
                    <Typography variant="body1" component="div">
                      <Box ml={1} textAlign="left">
                        {location}
                      </Box>
                    </Typography>
                  </Box>
                )}
                {education && education.length > 0 && (
                  <Tooltip
                    title={`${education[0].degree} in ${
                      education[0].fieldOfStudy
                    } from ${education[0].school} Class of ${format(
                      new Date(education[0].completionDate),
                      "yyyy"
                    )}`}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ cursor: "pointer" }}
                    >
                      <SchoolOutlinedIcon fontSize="small" />{" "}
                      <Typography variant="body1" component="div">
                        <Box ml={1} textAlign="left">
                          {/* {education[0].degree}, {education[0].fieldOfStudy} @{" "} */}
                          {education[0].school} '
                          {format(new Date(education[0].completionDate), "yy")}
                        </Box>
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
                {registrationDate && (
                  <Box display="flex" alignItems="center">
                    <CalendarTodayOutlinedIcon fontSize="small" />{" "}
                    <Typography variant="body1" component="div">
                      <Box ml={1} textAlign="left">
                        Joined {format(new Date(registrationDate), "MMMM yyyy")}
                      </Box>
                    </Typography>
                  </Box>
                )}
              </Box>
              {aboutMe && (
                <Box mt={1}>
                  <Typography variant="h6" component="div" gutterBottom>
                    <Box lineHeight={1.2}>{aboutMe}</Box>
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              mt={2}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: "8px" }}
            >
              {activelySeekingJob && (
                <Box
                  display="flex"
                  alignItems="center"
                  px={2}
                  py={1}
                  bgcolor="background.paper"
                  borderRadius={50}
                >
                  <CheckIcon className={classes.icon} />
                  <Typography variant="body2" component="div">
                    <Box textAlign="left">Open to new opportunities</Box>
                  </Typography>
                </Box>
              )}

              {github !== "" && (
                <Box>
                  <Link
                    href={github}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                    color="inherit"
                  >
                    <Tooltip
                      placement="left"
                      title={"Code Repository"}
                      aria-label={`github-link-${github}`}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        py={1}
                        bgcolor="background.paper"
                        borderRadius={50}
                      >
                        <CodeIcon
                          rel={"noopener noreferrer"}
                          target={"_blank"}
                          className={classes.icon}
                        />{" "}
                        <Typography variant="body2" component="div">
                          <Box textAlign="left">{github}</Box>
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Link>
                </Box>
              )}
              {linkedin !== "" && (
                <Box>
                  <Link
                    href={linkedin}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                    color="inherit"
                  >
                    <Tooltip
                      placement="left"
                      title={"LinkedIn Profile"}
                      aria-label={`linkedin-link-${linkedin}`}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        py={1}
                        bgcolor="background.paper"
                        borderRadius={50}
                      >
                        <LinkedInIcon
                          rel={"noopener noreferrer"}
                          target={"_blank"}
                          className={classes.icon}
                          style={{ color: "#0077B5" }}
                        />{" "}
                        <Typography variant="body2" component="div">
                          <Box textAlign="left">{linkedin}</Box>
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Link>
                </Box>
              )}
              {personalWebsite !== "" && (
                <Box>
                  <Link
                    href={personalWebsite}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                    color="inherit"
                  >
                    <Tooltip
                      placement="left"
                      title={"Personal Website"}
                      aria-label={`personalWebsite-link-${personalWebsite}`}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        py={1}
                        bgcolor="background.paper"
                        borderRadius={50}
                      >
                        <LanguageIcon
                          rel={"noopener noreferrer"}
                          target={"_blank"}
                          className={classes.icon}
                          style={{ color: "#C9186E" }}
                        />{" "}
                        <Typography variant="body2" component="div">
                          <Box textAlign="left">{personalWebsite}</Box>
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Link>
                </Box>
              )}
              {resumeLocation && (
                <Box>
                  <Link
                    href={resumeLocation}
                    rel={"noopener noreferrer"}
                    target={"_blank"}
                    color="inherit"
                  >
                    <Tooltip
                      placement="left"
                      title={"Download Resume"}
                      aria-label={`resumeLocation-link-${resumeLocation}`}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        px={2}
                        py={1}
                        bgcolor="background.paper"
                        borderRadius={50}
                      >
                        <DescriptionIcon
                          rel={"noopener noreferrer"}
                          target={"_blank"}
                          className={classes.icon}
                          style={{ color: "#008891" }}
                        />{" "}
                        <Typography variant="body2" component="div">
                          <Box textAlign="left">Download Resume</Box>
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Link>
                </Box>
              )}
            </Box>

            {/* <Can
                  role={user && user.typeId}
                  perform="profile:view-workInfo"
                  data={{
                    userId: user && user.id,
                    profileId: id,
                  }}
                  yes={() => (
                    <Fragment>
                      <Box textAlign={"left"}>
                        <Typography variant="body2">
                          {activelySeekingJob ? (
                            <CheckIcon className={classes.check} />
                          ) : (
                            <ClearIcon className={classes.clear} />
                          )}{" "}
                          I am actively seeking a full-time job or internship.
                        </Typography>
                        <Typography variant="body2">
                          {workAuthorization ? (
                            <CheckIcon className={classes.check} />
                          ) : (
                            <ClearIcon className={classes.clear} />
                          )}{" "}
                          I am authorized to work in the U.S.
                        </Typography>
                        <Typography variant="body2">
                          {needVisa ? (
                            <CheckIcon className={classes.check} />
                          ) : (
                            <ClearIcon className={classes.clear} />
                          )}{" "}
                          I need a Visa to work in the U.S.
                        </Typography>
                        <Typography variant="body2">
                          {disabilityStatus ? (
                            <CheckIcon className={classes.check} />
                          ) : (
                            <ClearIcon className={classes.clear} />
                          )}{" "}
                          I have a disability or previously had a disability.
                        </Typography>
                        <Typography variant="body2">
                          {veteranStatus ? (
                            <CheckIcon className={classes.check} />
                          ) : (
                            <ClearIcon className={classes.clear} />
                          )}{" "}
                          I identify as one or more of the classifications of
                          protected veteran.
                        </Typography>
                      </Box>
                      <Box textAlign="center" mt={2}>
                        <Link
                          href={`mailto:${email}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            fullWidth
                            className="flex items-center justify-center"
                          >
                            <EmailIcon className="text-lg mr-2" />
                            Message
                          </Button>
                          {/* <Button
                            fullWidth
                            variant="contained"
                            startIcon={<EmailIcon />}
                            className={classes.messageButton}
                          >
                            Message
                          </Button> 
                        </Link>
                      </Box>
                    </Fragment>
                  )}
                /> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

ProfileCard.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  skillSet: state.typedef.skillSet,
  user: state.auth.user,
  profile: state.profile.profile,
});
export default connect(mapStateToProps)(ProfileCard);
