import React, { useEffect } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { getSubmissionScores } from "../../../redux/actions/challenge";
// Material UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Spinner from "../../../shared/Spinner";

export const ChallengeViewSubmissionScoresDialog = ({
  open,
  setOpen,
  submissionId,
  submissionScores,
  getSubmissionScores,
}) => {
  useEffect(() => {
    if (submissionId && open) {
      getSubmissionScores(submissionId);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby={`submission-dialog`}
    >
      <Container maxWidth="lg">
        {submissionScores && submissionScores.loading ? (
          <Spinner />
        ) : (
          <>
            <Box p={[2, 4]}>
              <Box>
                <Typography variant="h5">
                  <strong>
                    {(submissionScores &&
                      submissionScores.data &&
                      submissionScores.data.submission &&
                      submissionScores.data.submission.team &&
                      submissionScores.data.submission.team.name) ||
                      ""}
                  </strong>
                </Typography>
              </Box>
              {submissionScores &&
              submissionScores.data &&
              submissionScores.data.scores ? (
                Object.keys(submissionScores.data.scores).map(
                  (judgingCriteriaId) => {
                    const currentScore =
                      submissionScores.data.scores[judgingCriteriaId];
                    const scores = currentScore.scores;
                    const name = currentScore.name;
                    const description = currentScore.description;
                    const maxScore = currentScore.maxScore;
                    return (
                      <Box my={[2, 4]} key={judgingCriteriaId}>
                        <Box mb={[1, 2]}>
                          <Typography variant="h6">
                            <strong>{name}</strong>
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {description}
                          </Typography>

                          <Typography variant="body2" color="textSecondary">
                            Max Score: {maxScore}
                          </Typography>
                        </Box>
                        <Box>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Reviewer</TableCell>
                                  <TableCell>Feedback</TableCell>
                                  <TableCell>Score</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {scores && scores.length > 0 ? (
                                  scores.map((score, scoreIndex) => {
                                    return (
                                      <TableRow key={score.id}>
                                        <TableCell>
                                          {score.judgeFirstName}{" "}
                                          {score.judgeLastName}
                                        </TableCell>
                                        <TableCell>
                                          <Box whiteSpace="pre-line">
                                            {score.feedback}
                                          </Box>
                                        </TableCell>
                                        <TableCell>{score.score}</TableCell>
                                      </TableRow>
                                    );
                                  })
                                ) : (
                                  <TableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        There are no scores for this criteria.
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    );
                  }
                )
              ) : (
                <Typography>
                  There are no scores for this submission.
                </Typography>
              )}
            </Box>
          </>
        )}
      </Container>
    </Dialog>
  );
};

ChallengeViewSubmissionScoresDialog.propTypes = {
  submissionScores: PropTypes.object,
  getSubmissionScores: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  submissionScores: state.challenge.submissionScores,
});

const mapDispatchToProps = {
  getSubmissionScores,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeViewSubmissionScoresDialog);
