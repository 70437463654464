import React from "react";
import { IoMdCheckmark } from "react-icons/io";

const ALL_FEATURES = [
  {
    name: "Unlimited programs",
  },
  {
    name: "Multiple rounds / stages",
  },
  {
    name: "Dedicated & customizable program page",
  },
  {
    name: "Custom submission forms",
  },
  {
    name: "Collect reviewers / judges scores & feedback",
  },
  {
    name: "Basic insights",
  },
  {
    name: "Advanced user profiles",
  },
  {
    name: "Interactive community features (Coming Soon)",
  },
  {
    name: "Privacy & security control",
  },
  {
    name: "Email and chat support",
  },
];

const AllPlanFeatures = () => {
  return (
    <div className="bg-gray-50">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-lg leading-6 font-semibold text-gray-500 uppercase tracking-wider">
              Pricing
            </h2>
            <p className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl">
              A plan for every organization
            </p>
            <p className="text-xl text-gray-500">
              Start building your program or community for free, then utilize
              other plans for more advanced features.
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <h2 className="text-base font-semibold text-primary-600 tracking-wide uppercase">
              Everything you need
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900">
              Pralent is free to use
            </p>
            <p className="mt-4 text-lg text-gray-500">
              All the features you will need to amaze your community and then
              some. Every plan includes these features.
            </p>
            <a
              href="mailto:sales@pralent.com"
              className="block w-full bg-gray-800 border border-gray-800 rounded-md mt-2 md:mt-4 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
              aria-describedby={`tier-request-invite`}
            >
              Request Invite
            </a>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
            <ul className="divide-y divide-gray-200">
              {ALL_FEATURES &&
                ALL_FEATURES.slice(0, 5).map((f, i) => {
                  return (
                    <li key={i} className="py-4 flex">
                      <IoMdCheckmark className="text-green-500 text-xl" />
                      <span className="ml-3 text-base text-gray-500">
                        {f.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
            <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
              {ALL_FEATURES &&
                ALL_FEATURES.slice(5, 10).map((f, i) => {
                  return (
                    <li key={i} className="py-4 flex">
                      <IoMdCheckmark className="text-green-500 text-xl" />
                      <span className="ml-3 text-base text-gray-500">
                        {f.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPlanFeatures;
