import React, { Fragment, useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { addProfileSkills } from "../../redux/actions/profile";
// Material UI
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
// Images
import coding from "../../assets/images/coding_.svg";
// Components
import Button from "../Button";
import RenderGroup from "../RenderGroup";
import Listbox from "../Listbox";

const sliderMarks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 100,
    label: "100%",
  },
];

const SkillsForm = ({ myprofile, addProfileSkills, skillSet }) => {
  //const theme = useTheme();

  const mySkillSet =
    myprofile && myprofile.skillSets && myprofile.skillSets.selectedSkills;

  const [selectedSkillSet, setSelectedSkillSet] = useState([]);

  // Submit the request to server to save the skills
  const onSubmit = (e) => {
    e.preventDefault();
    //console.log("onSubmit");
    //setFormdata({ ...formData, skills: selectedSkillSet });
    addProfileSkills({
      selectedSkills: selectedSkillSet,
    });
    //addProfileSkills(selectedSkillSet);
  };
  // console.log(selectedSkillSet);
  const handleSkillSetChange = (selectedSkills) => {
    const max = 10;
    setInitialSkills(selectedSkills);

    if (selectedSkills !== undefined) {
      let newSkills = selectedSkills;
      if (newSkills.length > max) newSkills.shift();

      // Loop through the newSkills list
      // Check if the values in the list matches with the value in
      // the state object list
      let tempSkillsArray = [];
      newSkills.forEach((element) => {
        const found =
          selectedSkillSet &&
          selectedSkillSet.find((elem) => elem.name === element);
        if (found) {
          tempSkillsArray.push(found);
        } else {
          // Create a new skillset object
          const skillObj = {
            name: element,
            value: 50,
          };
          tempSkillsArray.push(skillObj);
        }

        // Assign the new array to the state variable
      });
      setSelectedSkillSet(tempSkillsArray);
    }
  };

  const handleSliderChange = (selectedSlider, value) => {
    // setTotalValueLeft(100 - totalValue - value);
    let newSkillObj = [];
    selectedSkillSet.map((element) => {
      let skillObj = {};
      if (element && element.name === selectedSlider.name) {
        skillObj = {
          name: selectedSlider && selectedSlider.name,
          value: value,
        };
      } else {
        skillObj = {
          ...element,
        };
      }
      newSkillObj.push(skillObj);
    });
    setSelectedSkillSet(newSkillObj);
  };

  const [initialSkills, setInitialSkills] = useState([]);
  useEffect(() => {
    if (mySkillSet && mySkillSet.length > 0) {
      setSelectedSkillSet(mySkillSet);
    }
    // console.log(mySkillSet);
    let initialSkills = [];
    mySkillSet &&
      mySkillSet.map((elem) => {
        initialSkills.push(elem && elem.name);
      });

    setInitialSkills(initialSkills);
  }, [mySkillSet]);

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    async function fetchData() {
      fetch(
        "https://raw.githubusercontent.com/Pralent/skills/master/skills.txt"
      )
        // .then((res) => res.json())
        .then((res) => {
          const text = res.text();

          return text;
        })
        .then(async (res) => {
          const data = await res.split("\n");
          if (active) {
            setOptions(data);
          }
        })
        .catch((res) => console.log(res));
    }
    fetchData();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Fragment>
      <form onSubmit={(e) => onSubmit(e)}>
        <Autocomplete
          multiple
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          id="skillSetOptions"
          options={options}
          freeSolo
          ListboxComponent={Listbox}
          renderGroup={RenderGroup}
          value={initialSkills}
          onChange={(e, newValue) => {
            handleSkillSetChange(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: "100%" }}
              variant="outlined"
              placeholder="Type your skills and hit enter"
            />
          )}
        />
        <Typography gutterBottom variant="body2" component="div">
          <Box my={2}>
            Highlight your skills to organizations by updating the skill
            proficiency percentage.
          </Box>
        </Typography>

        {selectedSkillSet && selectedSkillSet.length > 0 ? (
          selectedSkillSet.map((skill, index) => {
            // add an object to the selectedSliderList
            return (
              <Box key={index} mx={2}>
                <Typography variant="body2">{skill && skill.name}</Typography>

                <Slider
                  name={skill && skill.name}
                  defaultValue={0}
                  onChange={(e, value) => {
                    handleSliderChange(skill, value);
                  }}
                  aria-labelledby="discrete-slider-small-steps"
                  step={5}
                  marks={sliderMarks}
                  min={0}
                  max={100}
                  value={skill && skill.value}
                  valueLabelDisplay="auto"
                />
              </Box>
            );
          })
        ) : (
          <Box
            display="block"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            my={2}
          >
            <img
              height="100%"
              width="350px"
              src={coding}
              alt="No skills added image"
            />
            <Typography variant="body1" component="div" color="textSecondary">
              There are no skills added to your profile yet. Add a your skills
              and update your proficiency percentages.
            </Typography>
          </Box>
        )}
        <div className="mt-2 text-right">
          <Button onClick={(e) => onSubmit(e)}>Save</Button>
        </div>
      </form>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  skillSet: state.typedef.skillSet,
  myprofile: state.profile.myprofile,
});

export default connect(mapStateToProps, {
  addProfileSkills,
})(SkillsForm);
