import React, { useState } from "react";
// React router
import { Link } from "react-router-dom";
// Material UI
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
// Components
import OrganizationList from "./components/OrganizationList";
import UserDashboardMenu from "./components/UserDashboardMenu";

const drawerWidth = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  AppBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    marginBottom: 0,
    backgroundColor: theme.palette.background.default,
    borderBottomWidth: theme.spacing(0.05),
    borderBottomColor: theme.palette.action.hover,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    borderRightWidth: theme.spacing(0.05),
    borderRightColor: theme.palette.action.hover,
  },
  drawerContainer: {
    overflowY: "auto",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    width: "100%",
    overflow: "hidden",
    paddingTop: "48px",
  },
  LogoLightWord: {
    height: 25,
    width: "auto",
  },
}));

const UserDashboard = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newValue) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.AppBar}>
        <Toolbar variant="dense">
          <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
            {mobileScreen && (
              <IconButton
                onClick={toggleDrawer(true)}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>
            )}
            {/* <Box height="100%" width="100%">
              <Link to="/">
                <img
                  src={
                    theme.palette.type === "dark" ? LogoLightWord : LogoDarkWord
                  }
                  alt="LogoLightWord"
                  className={classes.LogoLightWord}
                />
              </Link>
            </Box> */}
          </Box>
          <Box flexGrow={1} />
          <UserDashboardMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={open}
        className={classes.drawer}
        variant={mobileScreen ? "temporary" : "permanent"}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={toggleDrawer(false)}
      >
        {!mobileScreen && <Toolbar variant="dense" />}
        <div className={classes.drawerContainer}>
          <List dense>
            <OrganizationList />
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>{props.children}</main>
    </div>
  );
};

export default UserDashboard;
