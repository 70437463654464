import React, { useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

// colors
import colorList from "./colorList";
const backgroundColorList = colorList.map((color) => `${fade(color, 0.8)}`);

const HorizontalBarChart = ({ data }) => {
  // Need to show percentages when user is hovering
  const theme = useTheme();
  const graphData = {
    labels: data.map((nameCountObject) => nameCountObject.name),
    datasets: [
      {
        data: data.map((nameCountObject) => nameCountObject.count),
        backgroundColor: backgroundColorList,
        borderColor: "rgba(255, 99, 132, 0.03)", //`${fade(theme.palette.primary.main, 0.0)} 2px solid`,
        borderWidth: 2,
        hoverBackgroundColor: theme.palette.primary.main,
        hoverBorderColor: `${fade(theme.palette.primary.main, 0.05)}`,
      },
    ],
  };

  return (
    <Box>
      <HorizontalBar
        data={graphData}
        width={300}
        height={300}
        options={{
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0.025)",
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0.025)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default HorizontalBarChart;
