import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
// React query
import { useAddReactionToPost } from "../../../hooks/room";
// Context
import {
  useRoomMembersContext,
  usePostReplyContext,
  useRoomContext,
} from "./RoomContext";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core";
// Components
import CustomAvatar from "../../CustomAvatar";
import ProfilePopover from "../../ProfilePopover";
import PostPopover from "./PostPopover";
import RichTextEditor from "../../RichTextEditor";
// Utils
import { Emoji } from "emoji-mart";
import format from "date-fns/format";
import formatRelative from "date-fns/formatRelative";
import { convertTimeToUTC } from "../../../utils/utilFunc";
import { DEFAULT_TIME_ZONE, ROOM_TYPE } from "../../../utils/globalValues";
import { capitalize } from "../../../utils/string";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: (props) => (props.chat ? 0 : theme.spacing(1.5)),
    paddingBottom: (props) => (props.chat ? 0 : theme.spacing(1.5)),
    height: "100%",
  },
  postBox: {
    backgroundColor: (props) =>
      props.chat ? "transparent" : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    height: "100%",
  },
  avatar: {
    width: (props) => (props.chat ? theme.spacing(3.75) : theme.spacing(5)),
    height: (props) => (props.chat ? theme.spacing(3.75) : theme.spacing(5)),
    marginRight: (props) => (props.leftAlign ? theme.spacing(0.5) : 0),
    marginLeft: (props) => (props.leftAlign ? 0 : theme.spacing(0.5)),
    borderRadius: "50%",
    fontSize: theme.typography.body1.fontSize,
  },
  repliedAvatar: {
    width: theme.spacing(2.75),
    height: theme.spacing(2.75),
    marginRight: theme.spacing(0.5),
    borderRadius: "50%",
    fontSize: theme.typography.body2.fontSize,
  },
  emojiChipRoot: {
    paddingRight: theme.spacing(0.6),
    paddingLeft: theme.spacing(0.6),
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    display: "flex",
    alignItems: "center",
  },
  emoji: {
    backgroundColor: "transparent",
    fontSize: 0,
  },
  text: {
    backgroundColor: (props) =>
      props.chat
        ? props.leftAlign
          ? theme.palette.background.paper
          : theme.palette.info.main
        : "transparent",
    color: (props) =>
      props.leftAlign ? theme.palette.text.primary : theme.palette.common.white,
    maxWidth: (props) => (props.chat ? "75%" : "90%"),
    borderRadius: theme.shape.borderRadius + 5,
    paddingLeft: theme.spacing(1),
    paddingRight: (props) => (props.chat ? theme.spacing(1) : 0),
    paddingTop: (props) => (props.chat ? theme.spacing(1) : 0),
    paddingBottom: (props) => (props.chat ? theme.spacing(1) : 0),
  },
  emojiChipColorPrimary: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.info.light,
      transition: "0.2s",
    },
    "&:focus": {
      backgroundColor: theme.palette.info.light,
      transition: "0.2s",
    },
    "&:selected": {
      backgroundColor: theme.palette.info.light,
      transition: "0.2s",
    },
  },
  emojiChipColorSecondary: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      transition: "0.2s",
    },
    "&:focus": {
      backgroundColor: theme.palette.background.default,
      transition: "0.2s",
    },
    "&:selected": {
      backgroundColor: theme.palette.background.default,
      transition: "0.2s",
    },
  },
  replyButton: {
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    width: "100%",
  },
}));

const Post = ({ post, user, postUser, postProfile }) => {
  const { room } = useRoomContext();

  let leftAlign = true;
  let chat;

  if (post.isReply || room.type === ROOM_TYPE.CHAT) {
    chat = true;
  }

  if (chat && user && post.postedBy === user.id) {
    leftAlign = false;
  }
  const classes = useStyles({ leftAlign, chat });

  const { roomMembers } = useRoomMembersContext();
  const { handleDrawerOpen } = usePostReplyContext();

  const useAddReactionToPostMutation = useAddReactionToPost(
    post.roomId || "",
    post.parentPostId || "",
    post.isReply ? true : false
  );

  const [displayPopover, setDisplayPopover] = useState(false);

  const handleEmojiSelect = (emoji) => {
    useAddReactionToPostMutation.mutate({
      postId: post.id,
      emoji: emoji,
    });
  };

  const postReactions = post.reactions;
  const postReactionsKeys = Object.keys(postReactions) || [];

  const convertedCreatedDateTime = convertTimeToUTC(
    post.createdDateTime,
    DEFAULT_TIME_ZONE
  );

  return (
    <Box className={classes.root}>
      <Box
        className={classes.postBox}
        display="flex"
        flexDirection={leftAlign ? "row" : "row-reverse"}
        alignItems="start"
        width="100%"
        px={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6 }}
        py={chat ? 1 : 3}
        position="relative"
        onMouseEnter={() => setDisplayPopover(true)}
        onTouchStart={() => setDisplayPopover(true)}
        onMouseLeave={() => setDisplayPopover(false)}
        onTouchEnd={() => setDisplayPopover(false)}
      >
        <ProfilePopover userId={postUser && postUser.id}>
          <CustomAvatar
            src={postUser && postUser.avatar}
            fallbackText={postUser && postUser.userName}
            alt={`${postUser && postUser.userName}-avatar`}
            className={classes.avatar}
          />
        </ProfilePopover>
        <Box width="100%">
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            lineHeight={1}
            flexDirection={leftAlign ? "row" : "row-reverse"}
            width="100%"
            mx={1}
          >
            <ProfilePopover userId={postUser && postUser.id}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" component="div">
                  <Box
                    lineHeight={1.2}
                    fontWeight={600}
                    mx={leftAlign ? 0 : 1.5}
                  >
                    {!leftAlign
                      ? "You"
                      : postProfile
                      ? `${postProfile.firstName} ${postProfile.lastName}`
                      : postUser
                      ? postUser.userName
                      : "[User Left Room]"}
                  </Box>
                </Typography>
                {leftAlign && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    <Box lineHeight={1.2} mx={1}>
                      @{postUser.userName}
                    </Box>
                  </Typography>
                )}
              </Box>
            </ProfilePopover>
            <Tooltip
              title={capitalize(
                formatRelative(convertedCreatedDateTime, new Date())
              )}
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >
                {/* {format(convertedCreatedDateTime, "hh:mm a")} */}
                {capitalize(
                  formatRelative(convertedCreatedDateTime, new Date())
                )}
              </Typography>
            </Tooltip>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={leftAlign ? "row" : "row-reverse"}
            width="100%"
            mt={0.5}
          >
            <Box className={classes.text} borderRadius="borderRadius">
              <Typography variant="body1" component="div">
                <Box whiteSpace="pre-line" lineHeight={1.2}>
                  {post.title}
                </Box>
              </Typography>
              {post.text && JSON.parse(post.text) && (
                <RichTextEditor value={JSON.parse(post.text)} readOnly={true} />
              )}
            </Box>
            <Box mx={0.5}>
              <PostPopover displayPopover={displayPopover} post={post} />
            </Box>
          </Box>
          {postReactionsKeys && postReactionsKeys.length > 0 && (
            <Box
              mt={chat ? -1 : 1}
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              justifyContent={leftAlign ? "flex-start" : "flex-end"}
              style={{ gap: "1px" }}
            >
              {postReactionsKeys.map((key, index) => {
                const reaction = postReactions[key];
                if (!reaction || !reaction.users || !reaction.users.length) {
                  return;
                }

                const userClicked = reaction.users.find(
                  (elem) => elem === (user && user.id)
                );

                const usersNames = reaction.users.map((elem) => {
                  const member = roomMembers[elem];
                  if (!member || !member.user) {
                    return null;
                  }

                  const memberUser = member.user;
                  const profile = member.profile;

                  if ((user && user.id) === (memberUser && memberUser.id)) {
                    return "You";
                  }

                  let name;
                  if (profile) {
                    name = `${profile.firstName || ""} ${
                      profile.lastName || ""
                    }`;
                  } else if (memberUser) {
                    name = `${memberUser.userName || ""}`;
                  }

                  return `${name}`;
                });

                let reactedUsersNames = usersNames.slice(0, 10).join(", ");
                return (
                  <Tooltip
                    key={index}
                    placement="top"
                    title={
                      <Box textAlign="center" p={1}>
                        <Emoji emoji={reaction.emoji} set="apple" size={36} />
                        <Box>
                          <Box fontWeight={600} component="span">
                            {reactedUsersNames}
                            {usersNames && usersNames.length >= 10
                              ? ", and more"
                              : ""}{" "}
                          </Box>
                          <Box color="textDisabled" component="span">
                            reacted with {reaction.emoji}
                          </Box>
                        </Box>
                      </Box>
                    }
                  >
                    <Chip
                      icon={
                        <Emoji
                          emoji={reaction.emoji}
                          set="apple"
                          size={12}
                          className={classes.emoji}
                        />
                      }
                      label={
                        <Typography variant="caption" component="div">
                          {reaction.users.length}
                        </Typography>
                      }
                      clickable
                      onClick={() =>
                        handleEmojiSelect(
                          reaction.emoji,
                          userClicked ? "remove" : "add"
                        )
                      }
                      color={userClicked ? "primary" : "secondary"}
                      classes={{
                        root: classes.emojiChipRoot,
                        colorPrimary: classes.emojiChipColorPrimary,
                        colorSecondary: classes.emojiChipColorSecondary,
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          )}
          {!post.isReply && post.replyCount > 0 && post.replyUsers && (
            <Box
              component={ButtonBase}
              display="flex"
              alignItems="center"
              justifyContent={leftAlign ? "flex-start" : "flex-end"}
              onClick={() => handleDrawerOpen(post)}
              className={classes.replyButton}
            >
              <Box display="flex" alignItems="center" mr={0.5}>
                {post.replyUsers &&
                  (JSON.parse(post.replyUsers) || [])
                    .slice(0, 8)
                    .map((repliedUserId, index) => {
                      const repliedMember = roomMembers[repliedUserId];
                      const repliedUser = repliedMember?.user;
                      return (
                        <CustomAvatar
                          key={index}
                          src={repliedUser && repliedUser.avatar}
                          fallbackText={repliedUser && repliedUser.userName}
                          alt={`${repliedUser && repliedUser.userName}-avatar`}
                          className={classes.repliedAvatar}
                        />
                      );
                    })}
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  component="div"
                  color="textSecondary"
                >
                  <Box lineHeight={1.2}>
                    {post.replyCount} repl{post.replyCount > 1 ? "ies" : "y"}
                  </Box>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Post));
