const Careers = () => {
  return (
    <div className="relative bg-white overflow-hidden py-16 sm:py-24">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-primary-600 tracking-wide uppercase">
              Careers
            </h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              We're hiring.
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              Contact us if you are interested in joining our team at one of the
              roles below. We are not open for any full-time roles at the
              moment.
            </p>
          </div>
        </div>
      </div>
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <rect
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </rect>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mt-6 prose prose-primary prose-lg text-gray-500 mx-auto">
          <div>
            <h2>Business Development Intern</h2>
            <h4>About the Role</h4>
            <p>
              Pralent is looking for a Business Development Intern to work on
              introducing new organizations to our platform with the goal of
              facilitating awesome experiential learning communities. You will
              work closely with our founding team to help guide, manage and
              execute critical business tasks for the growth of Pralent, and to
              help us fulfill our mission. We are seeking someone that is
              self-motivated and excited about our product and the future of
              online communities. This role will provide you with the
              opportunity to grow in sales, marketing, finance, and general
              business. Pralent is an equal opportunity employer.
            </p>
            <p>
              We encourage all students that are interested in a part-time
              internship to reach out to us.
            </p>
            <h4>Roles and Responsibilities</h4>
            <ul>
              <li>
                In collaboration with our founding team, you will be responsible
                for ...
              </li>
              <li>Developing and managing business development pipelines</li>
              <li>
                Learning Pralent's products / solutions and competitive
                solutions
              </li>
              <li>Qualifying leads according to industry definitions</li>
              <li>Performing comprehensive market analysis</li>
              <li>Providing weekly reporting on progress</li>
            </ul>
            <h4>About You</h4>
            <ul>
              <li>Pursuing a relevant bachelor’s or master’s degree</li>
              <li>
                Strongly considering a career in sales, marketing, business
                operations, finance, or consulting
              </li>
              <li>
                Ability to quickly learn sales and business development skills
              </li>
              <li>Love working with startups and excited to wear many hats</li>
            </ul>
            <h4>Compensation</h4>
            <p>The position is part-time and paid.</p>
            <h4>Location</h4>
            <p>
              Remote - anywhere in the US. US work authorization is required.
            </p>
            If you are interested in the position, please{" "}
            <a href="mailto:samarth@pralent.com">contact us</a>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
