import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
// Components
import OrgChallengeAppBar from "../../shared/OrgChallengeAppBar";
import ChallengeRegistrationAppBar from "./components/ChallengeRegistrationAppBar";
import TeamView from "./components/TeamView";
import ProfileView from "./components/ProfileView";
import Spinner from "../../shared/Spinner";
// Material UI
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
// Images
import PerfectScrollbar from "react-perfect-scrollbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`registrations-tabpanel-${index}`}
      aria-labelledby={`registrations-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `registrations-tab-${index}`,
    "aria-controls": `registrations-tabpanel-${index}`,
  };
}

export const ChallengeRegistrations = ({ challenge }) => {
  const [tabValue, setTabValue] = useState(
    challenge && challenge.maxPeopleTeam && challenge.maxPeopleTeam === 1
      ? 1
      : 0
  );

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  return (
    <Fragment>
      <Box height="100%" margin="auto">
        <OrgChallengeAppBar />
        <Box>
          <ChallengeRegistrationAppBar />
          <PerfectScrollbar>
            <AppBar position="static">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="program registrations tabs"
              >
                <Tab label="By Team" {...a11yProps(0)} />
                <Tab label="By User" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <TeamView />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <ProfileView />
            </TabPanel>
          </PerfectScrollbar>
        </Box>
      </Box>
    </Fragment>
  );
};

ChallengeRegistrations.propTypes = {};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeRegistrations);
