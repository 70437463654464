import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import { addEditCriteria } from "../../../redux/actions/judge";
// Components
import Button from "../../../shared/Button";
// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
// Helper Functions
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
// Icons
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    boxShadow: "none",
  },
}));

const ChallengeCriteriaDialog = ({
  challenge,
  criteria = null,
  open,
  currentRoundIndex,
  setOpen,
  addEditCriteria,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
      challengeRoundId:
        challenge &&
        challenge.round.length &&
        challenge.round[currentRoundIndex].id,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Make sure that you have a valid values for the fields
    if (formData.name && formData.description && formData.maxScore) {
      // Send a request to save/update  criteria details
      //console.log(formData);
      //console.log(formData);
      const res = await addEditCriteria(formData);
      if (res) {
        handleClose();
      }
    }
  };

  const [formData, setFormData] = useState({
    challengeId: challenge.id,
    challengeRoundId:
      challenge &&
      challenge.round.length &&
      challenge.round[currentRoundIndex].id,
    name: "",
    isRequired: false,
    description: "",
    maxScore: "",
    unitType: "points",
  });

  const clearFormData = () => {
    setFormData({
      challengeId: challenge.id,
      challengeRoundId:
        challenge &&
        challenge.round.length &&
        challenge.round[currentRoundIndex].id,
      name: "",
      isRequired: false,
      description: "",
      maxScore: "",
      unitType: "points",
    });
  };

  useEffect(() => {
    if (criteria) {
      setFormData({
        ...formData,
        id: criteria.id,
        challengeId: criteria.challengeId,
        challengeRoundId: criteria.challengeRoundId,
        name: criteria.name,
        isRequired: criteria.isRequired,
        description: criteria.description,
        maxScore: criteria.maxScore,
        unitType: criteria.unitType,
      });
    }
  }, [criteria, open === true]);

  const { name, description, maxScore, isRequired, unitType } = formData;

  //console.log(criteria, formData);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <form onSubmit={(e) => onSubmit(e)}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Box flex="1">
              <Typography variant="h6">
                {criteria !== null ? "Edit" : "Add"} Criteria
              </Typography>
            </Box>
            <Button type="submit" color="primary">
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Criteria Name</InputLabel>
                <TextField
                  placeholder="Name for this criteria"
                  type="text"
                  required
                  onChange={handleChange}
                  name="name"
                  value={name}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Description</InputLabel>
                <TextField
                  multiline
                  placeholder="Description for this criteria"
                  type="text"
                  required
                  onChange={handleChange}
                  name="description"
                  value={description}
                  rows={6}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Maximum Score</InputLabel>
                <TextField
                  placeholder="Maximum Score Possible for this criteria"
                  type="number"
                  required
                  onChange={handleChange}
                  name="maxScore"
                  value={maxScore}
                  min="1"
                  max="100"
                  step="1"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Feedback Required</InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isRequired}
                      value={isRequired}
                      name="isRequired"
                      onChange={handleChange}
                    />
                  }
                  label="Reviewers must enter feedback for this criteria"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

//const mapDipatchToProps = { createChallengeJudge, setAlert };
const mapDispatchToProps = { addEditCriteria, setAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCriteriaDialog);
