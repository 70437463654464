import React, { Suspense, lazy } from "react";
import { Router } from "react-router-dom";
// Utils
import history from "../../utils/history";
// Components
import Spinner from "../../shared/Spinner";
import CustomSwitch from "../../shared/CustomSwitch";
// Routing
import DefaultRoute from "../../routes/Default";
import PrivateRoute from "../../routes/Private";
// Layouts
import CommunityLayout from "../../layouts/Community";
import DefaultLayout from "../../layouts/Default";
import StaticPagesLayout from "../../layouts/StaticPages";
import UserDashboardLayout from "../../layouts/UserDashboard";
import DiscoverLayout from "../../layouts/Discover";
import OrgDashboardLayout from "../../layouts/OrgDashboard";
import ChatLayout from "../../layouts/Chat";
// Pages / Routes
import OrgRoomMembers from "../../pages/OrgRoomMembers";
import OrgRoomEdit from "../../pages/OrgRoomEdit";
import OrgKnowledgeBase from "../../pages/OrgKnowledgeBase";
import OrgKnowledgeArticleCreate from "../../pages/OrgKnowledgeArticleCreate";
import OrgEventSettings from "../../pages/OrgEventSettings";
import OrgEventInsights from "../../pages/OrgEventInsights";
import OrgEventEmails from "../../pages/OrgEventEmails";
import OrgEventAttendees from "../../pages/OrgEventAttendees";
import OrgEventEdit from "../../pages/OrgEventEdit";
import OrgEventCreate from "../../pages/OrgEventCreate";
import OrgRoomCreate from "../../pages/OrgRoomCreate";
import OrgMembers from "../../pages/OrgMembers";
import OrgCalendar from "../../pages/OrgCalendar";
import OrgJobBoard from "../../pages/OrgJobBoard";
import OrgMyJobs from "../../pages/OrgMyJobs";
import OrgEvents from "../../pages/OrgEvents";
import Settings from "../../pages/Settings";
import Home from "../../pages/Home";
import FeaturesPrograms from "../../pages/FeaturesPrograms";
import FeaturesEvents from "../../pages/FeaturesEvents";
import FeaturesMembers from "../../pages/FeaturesMembers";
import FeaturesInsights from "../../pages/FeaturesInsights";
import Judge from "../../pages/Judge";
import JudgingSubmission from "../../pages/JudgeSubmission";
import JudgeSignUp from "../../pages/JudgeSignUp";
import Careers from "../../pages/Careers";
import Pricing from "../../pages/Pricing";
import RequestDemo from "../../pages/RequestDemo";
import Contact from "../../pages/Contact";
import Changelog from "../../pages/Changelog";
import Terms from "../../pages/Terms";
import Privacy from "../../pages/Privacy";
import Credit from "../../pages/Credit";
import ChallengeRulesCodeConduct from "../../pages/ChallengeRulesCodeConduct";
import Job from "../../pages/Job";
import Jobs from "../../pages/Jobs";
import NotFoundPage from "../../pages/404";
import Resources from "../../pages/Resources";
import Profiles from "../../pages/Profiles";
import Profile from "../../pages/Profile";
import Projects from "../../pages/Projects";
import Project from "../../pages/Project";
import ForgotPassword from "../../pages/ForgotPassword";
import UserSignUp from "../../pages/UserSignUp";
import LogIn from "../../pages/LogIn";
import Onboarding from "../../pages/Onboarding";
import OrgCreate from "../../pages/OrgCreate";
import Event from "../../pages/Event";
import Challenges from "../../pages/Challenges";
import Challenge from "../../pages/Challenge";
import ChallengeSubmission from "../../pages/ChallengeSubmission";
import EditProfile from "../../pages/EditProfile";
import UserDashboard from "../../pages/UserDashboard";
import OrgHome from "../../pages/OrgHome";
import OrgNewsletters from "../../pages/OrgNewsletters";
import OrgRooms from "../../pages/OrgRooms";
import OrgSettings from "../../pages/OrgSettings";
import OrgChallenges from "../../pages/OrgChallenges";
import OrgChallengeCreate from "../../pages/OrgChallengeCreate";
import OrgChallengeRegistrations from "../../pages/OrgChallengeRegistrations";
import OrgChallengeRound from "../../pages/OrgChallengeRound";
import OrgChallengeDetails from "../../pages/OrgChallengeDetails";
import OrgChallengeInsights from "../../pages/OrgChallengeInsights";
import OrgChallengeSettings from "../../pages/OrgChallengeSettings";
import CommunityKnowledgeArticle from "../../pages/CommunityKnowledgeArticle";
import CommunityKnowledgeBase from "../../pages/CommunityKnowledgeBase";
import CommunityCalendar from "../../pages/CommunityCalendar";
import CommunityMembers from "../../pages/CommunityMembers";
import CommunityJobs from "../../pages/CommunityJobs";
import CommunityPrograms from "../../pages/CommunityPrograms";
import CommunityEvents from "../../pages/CommunityEvents";
import CommunityHome from "../../pages/CommunityHome";
import CommunityInvite from "../../pages/CommunityInvite";
import CommunityRoom from "../../pages/CommunityRoom";
import CommunityRooms from "../../pages/CommunityRooms";
import ChatRoom from "../../pages/ChatRoom";
import NewChat from "../../pages/NewChat";

const ROUTES = [
  {
    exact: true,
    path: "/",
    component: Home,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/features/programs",
    component: FeaturesPrograms,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/features/members",
    component: FeaturesMembers,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/features/events",
    component: FeaturesEvents,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/features/insights",
    component: FeaturesInsights,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/terms",
    component: Terms,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/privacy",
    component: Privacy,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/credit",
    component: Credit,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/careers",
    component: Careers,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/pricing",
    component: Pricing,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/request-demo",
    component: RequestDemo,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/contact",
    component: Contact,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/new",
    component: Changelog,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/program-rules-code-of-conduct",
    component: ChallengeRulesCodeConduct,
    route: DefaultRoute,
    layout: StaticPagesLayout,
  },
  {
    exact: true,
    path: "/login",
    component: LogIn,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/signup",
    component: UserSignUp,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/forgot",
    component: ForgotPassword,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/me",
    component: EditProfile,
    route: PrivateRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard",
    component: UserDashboard,
    route: PrivateRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/programs",
    component: Challenges,
    route: DefaultRoute,
    layout: DiscoverLayout,
  },
  {
    exact: true,
    path: "/profiles",
    component: Profiles,
    route: PrivateRoute,
    layout: DiscoverLayout,
  },
  {
    exact: true,
    path: "/projects",
    component: Projects,
    route: PrivateRoute,
    layout: DiscoverLayout,
  },
  {
    exact: true,
    path: "/resources",
    component: Resources,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/settings",
    component: Settings,
    route: PrivateRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/events/:slug",
    component: Event,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/programs/:slug",
    component: Challenge,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/programs/:slug/submit",
    component: ChallengeSubmission,
    route: PrivateRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/@:userName",
    component: Profile,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/projects/:id",
    component: Project,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/programs/:slug/reviewer/:token",
    component: Judge,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/programs/:slug/reviewer/signup/:challengeRoundId",
    component: JudgeSignUp,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/programs/:slug/reviewer/:token/:submissionId",
    component: JudgingSubmission,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/jobs/:jobId",
    component: Job,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/jobs",
    component: Jobs,
    route: DefaultRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/onboarding",
    component: Onboarding,
    route: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    exact: true,
    path: "/org/new",
    component: OrgCreate,
    route: PrivateRoute,
    layout: UserDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/home",
    component: OrgHome,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/programs",
    component: OrgChallenges,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/programs/new",
    component: OrgChallengeCreate,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events/new",
    component: OrgEventCreate,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events",
    component: OrgEvents,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events/:slug/attendees",
    component: OrgEventAttendees,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events/:slug/edit",
    component: OrgEventEdit,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events/:slug/emails",
    component: OrgEventEmails,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events/:slug/insights",
    component: OrgEventInsights,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/events/:slug/settings",
    component: OrgEventSettings,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/knowledge/new",
    component: OrgKnowledgeArticleCreate,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/knowledge/:articleId/edit",
    component: OrgKnowledgeArticleCreate,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/knowledge",
    component: OrgKnowledgeBase,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: false,
    path: "/org/:orgId/jobboard",
    component: OrgJobBoard,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: false,
    path: "/org/:orgId/recruit",
    component: OrgMyJobs,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: false,
    path: "/org/:orgId/settings",
    component: OrgSettings,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/calendar",
    component: OrgCalendar,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: false,
    path: "/org/:orgId/members",
    component: OrgMembers,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  // {
  //   exact: true,
  //   path: "/org/:orgId/rooms",
  //   component: OrgRooms,
  //   route: PrivateRoute,
  //   layout: OrgDashboardLayout,
  // },
  // {
  //   exact: true,
  //   path: "/org/:orgId/rooms/new",
  //   component: OrgRoomCreate,
  //   route: PrivateRoute,
  //   layout: OrgDashboardLayout,
  // },
  // {
  //   exact: true,
  //   path: "/org/:orgId/rooms/:roomId/members",
  //   component: OrgRoomMembers,
  //   route: PrivateRoute,
  //   layout: OrgDashboardLayout,
  // },
  // {
  //   exact: true,
  //   path: "/org/:orgId/rooms/:roomId/edit",
  //   component: OrgRoomEdit,
  //   route: PrivateRoute,
  //   layout: OrgDashboardLayout,
  // },
  {
    exact: true,
    path: "/org/:orgId/newsletters",
    component: OrgNewsletters,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: false,
    path: "/org/:orgId/programs/:slug/details",
    component: OrgChallengeDetails,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },

  {
    exact: true,
    path: "/org/:orgId/programs/:slug/registrations",
    component: OrgChallengeRegistrations,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: false,
    path: "/org/:orgId/programs/:slug/round",
    component: OrgChallengeRound,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/programs/:slug/insights",
    component: OrgChallengeInsights,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/org/:orgId/programs/:slug/settings",
    component: OrgChallengeSettings,
    route: PrivateRoute,
    layout: OrgDashboardLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug",
    component: CommunityHome,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/invite",
    component: CommunityInvite,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/calendar",
    component: CommunityCalendar,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/members",
    component: CommunityMembers,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/programs",
    component: CommunityPrograms,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/programs/:slug",
    component: Challenge,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/programs/:slug/submit",
    component: ChallengeSubmission,
    route: PrivateRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/events",
    component: CommunityEvents,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/events/:slug",
    component: Event,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/jobs",
    component: CommunityJobs,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/jobs/:jobId",
    component: Job,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/knowledge",
    component: CommunityKnowledgeBase,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  {
    exact: true,
    path: "/c/:communitySlug/knowledge/:articleId",
    component: CommunityKnowledgeArticle,
    route: DefaultRoute,
    layout: CommunityLayout,
  },
  // {
  //   exact: true,
  //   path: "/c/:communitySlug/rooms/:roomSlug",
  //   component: CommunityRoom,
  //   route: DefaultRoute,
  //   layout: CommunityLayout,
  // },
  // {
  //   exact: true,
  //   path: "/c/:communitySlug/rooms",
  //   component: CommunityRooms,
  //   route: DefaultRoute,
  //   layout: CommunityLayout,
  // },
  // {
  //   exact: true,
  //   path: "/chats/new",
  //   component: NewChat,
  //   route: PrivateRoute,
  //   layout: ChatLayout,
  // },
  // {
  //   exact: true,
  //   path: "/chats/:roomSlug",
  //   component: ChatRoom,
  //   route: PrivateRoute,
  //   layout: ChatLayout,
  // },
  {
    exact: false,
    component: NotFoundPage,
    route: DefaultRoute,
    layout: DefaultLayout,
  },
];

function Routes(props) {
  return (
    <Router history={history}>
      {props.children}
      <Suspense fallback={<Spinner />}>
        <CustomSwitch>
          {(ROUTES || []).map(
            (
              {
                layout: Layout,
                component: Component,
                route: Route,
                exact,
                path,
              },
              index
            ) => {
              const RouteComponent = () => (
                <Layout>
                  <Component />
                </Layout>
              );
              return (
                <Route
                  key={index}
                  exact={exact}
                  path={path}
                  component={RouteComponent}
                />
              );
            }
          )}
        </CustomSwitch>
      </Suspense>
    </Router>
  );
}

export default Routes;
