import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const SidebarTab = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.body2.fontSize,
    borderRadius: theme.shape.borderRadius,
  },
  wrapper: {
    alignItems: "flex-start",
    textAlign: "left",
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  tabs: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      borderBottom: "0px solid #e8e8e8",
      minWidth: 125,
    },
  },
}));

const ChallengeSidebar = ({ currentDetail, setCurrentDetail, challenge }) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setCurrentDetail(newValue);
  };
  return (
    <>
      <Tabs
        orientation={mobileScreen ? "horizontal" : "vertical"}
        variant="scrollable"
        scrollButtons="auto"
        value={currentDetail}
        onChange={handleChange}
        aria-label="Program details tabs"
        className={classes.tabs}
      >
        {challenge &&
          challenge.properties &&
          challenge.properties.length > 0 &&
          challenge.properties.map((property, index) => {
            return (
              <SidebarTab
                wrapped
                key={index}
                label={property.name}
                {...a11yProps(0)}
              />
            );
          })}
      </Tabs>
    </>
  );
};

export default ChallengeSidebar;
