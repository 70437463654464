import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  createCompanyProfile,
  uploadCompanyLogo,
} from "../../../redux/actions/company";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
import Button from "../../../shared/Button";

const SIZES = ["1-10", "11-50", "51-200", "201-500", "501-1000", "1000+"];

const INDUSTRIES = [
  "AI / ML",
  "AdTech",
  "Aerospace",
  "Big Data",
  "Cyber Security",
  "Blockchain",
  "Cloud Computing",
  "SAAS",
  "Entertainment",
  "Fintech",
  "Hardware",
  "Marketplace",
  "Social",
  "AR / VR",
  "Gaming",
  "Manufacturing",
  "Finance",
  "Production",
  "Agriculture",
  "Investment",
  "Venture Capital",
  "Banking",
  "Pharmaceutical",
  "Transportation",
  "Insurance",
  "Healthcare",
  "Telecommunications",
  "Food",
  "Travel / Hotel",
  "Retail",
  "Real Estate",
  "Sales",
  "Education",
  "Energy",
  "Clothing",
  "Infrastructure",
  "Medicine",
  "Biotechnology",
  "Commodity",
  "Other",
];

const STAGES = [
  "High School Club",
  "University Club",
  "Educational Institution",
  "Alumni Group",
  "Nonprofit",
  "Early Stage Startup",
  "Growth Stage Startup",
  "Small Business",
  "Big Business",
  "Unicorn",
  "Publicly Traded",
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
  },
  orgLogoUpload: {
    cursor: "pointer",
    width: theme.spacing(25),
    height: theme.spacing(25),
    objectFit: "object-cover",
    borderRadius: theme.shape.borderRadius,
  },
  orgLogo: {
    fontSize: "50px",
    width: theme.spacing(15),
    height: theme.spacing(15),
    objectFit: "object-cover",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[200],
    borderWidth: "1px",
    transition: "ease-in-out 0.2s",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      boxShadow: theme.shadows[4],
    },
  },
  uploadLogoLabel: {
    cursor: "pointer",
  },
}));

const OrgProfileGeneral = ({
  createCompanyProfile,
  companyProfile,
  uploadCompanyLogo,
  setAlert,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    companyName: "",
    companyDescription: "",
    companyStage: "",
    industries: [],
    location: "",
    numEmployees: "",
  });

  useEffect(() => {
    if (companyProfile) {
      setFormData({
        id: companyProfile.id,
        companyName: formData.companyName || companyProfile.companyName || "",
        companyDescription:
          formData.companyDescription ||
          companyProfile.companyDescription ||
          "",
        companyStage:
          formData.companyStage || companyProfile.companyStage || "",
        industries: formData.industries || companyProfile.industries || [],
        location: formData.location || companyProfile.location || "",
        numEmployees: formData.numEmployees || companyProfile.numEmployees || 1,
        companyLogoPath: companyProfile.companyLogoPath || "",
      });
    }
  }, [companyProfile]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (companyProfile && companyProfile.id) {
      formData.id = companyProfile.id;
      formData.orgId = companyProfile.orgId;
    }
    await createCompanyProfile(formData);
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const [file, setFile] = useState("");
  const [companyLogoUrl, setCompanyLogoUrl] = useState("");
  const [modal, openModal] = useState(false);
  const closeModal = () => {
    setCompanyLogoUrl("");
    openModal(false);
  };
  const onChangeCompanyLogo = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 5242880) {
        setCompanyLogoUrl(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        openModal(true);
      } else {
        setAlert("Maximum file size 5MB exceeded", "error");
      }
    }
  };
  const onSubmitCompanyLogo = () => {
    const formData = new FormData();
    formData.append("myFile", file);
    uploadCompanyLogo(companyProfile.id, formData);
    closeModal();
  };

  const handleArrayChange = (fieldName, elem, isAdding) => {
    let newArr = [];
    if (isAdding) {
      newArr = [...formData[fieldName], elem];
    } else {
      newArr = formData[fieldName]?.filter((el) => el !== elem);
    }
    setFormData({
      ...formData,
      [fieldName]: newArr,
    });
  };

  const {
    companyName,
    companyDescription,
    companyStage,
    industries,
    location,
    numEmployees,
    communitySlug,
    communityWelcomeMessage,
    isCommunityEnabled,
    isCommunityPublic,
    doMembersNeedApproval,
  } = formData;

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            <Box fontWeight={600}>General</Box>
          </Typography>
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>Manage your organization information.</Box>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {companyLogoUrl !== "" && (
            <Dialog
              onClose={closeModal}
              aria-labelledby={"program-header-image-dialog"}
              open={modal}
              maxWidth={"sm"}
              fullWidth={true}
            >
              <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={closeModal}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box flex="1">
                    <Typography variant="h6">Logo</Typography>
                  </Box>

                  <Button variant="outlined" onClick={onSubmitCompanyLogo}>
                    Upload
                  </Button>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={2}
                >
                  <img
                    className={classes.orgLogoUpload}
                    alt={
                      companyLogoUrl && companyLogoUrl.charAt(0).toUpperCase()
                    }
                    src={companyLogoUrl}
                  />
                </Box>
              </DialogContent>
            </Dialog>
          )}

          <Box display="flex" alignItems="center">
            <input
              accept=".png, .jpg, .jpeg, .gif, .svg"
              className="hidden"
              id="company-logo-button"
              type="file"
              onChange={onChangeCompanyLogo}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <Box>
              <label
                htmlFor="company-logo-button"
                className={classes.uploadLogoLabel}
              >
                <CustomAvatar
                  src={companyProfile.companyLogoPath}
                  fallbackText={companyProfile.companyName}
                  alt="organization-logo"
                  className={classes.orgLogo}
                />
              </label>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel required>Name</InputLabel>
            <TextField
              placeholder="Enter your organization's name"
              type="text"
              required
              onChange={handleChange}
              name="companyName"
              value={companyName}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Location</InputLabel>
            <TextField
              placeholder="Enter your organization's location"
              type="text"
              onChange={handleChange}
              name="location"
              value={location}
            />
          </FormGroup>
        </Grid>
        {/* <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Size</InputLabel>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: "8px" }}
            >
              {SIZES.map((size, index) => {
                const clicked = formData.numEmployees === size;
                return (
                  <Button
                    key={index}
                    variant={clicked ? "contained" : "outlined"}
                    color="primary"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        numEmployees: size,
                      })
                    }
                  >
                    {size}
                  </Button>
                );
              })}
            </Box>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Type</InputLabel>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: "8px" }}
            >
              {STAGES.map((stage, index) => {
                const clicked = formData.companyStage === stage;
                return (
                  <Button
                    key={index}
                    variant={clicked ? "contained" : "outlined"}
                    color="primary"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        companyStage: stage,
                      })
                    }
                  >
                    {stage}
                  </Button>
                );
              })}
            </Box>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Industries</InputLabel>
            <Box
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              style={{ gap: "8px" }}
            >
              {INDUSTRIES.map((industry, index) => {
                const clicked = formData.industries.includes(industry);
                return (
                  <Button
                    key={index}
                    variant={clicked ? "contained" : "outlined"}
                    color="primary"
                    onClick={() =>
                      handleArrayChange("industries", industry, !clicked)
                    }
                  >
                    {industry}
                  </Button>
                );
              })}
            </Box>
          </FormGroup>
        </Grid> */}
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel required>Description</InputLabel>
            <TextField
              placeholder="Enter a description"
              type="text"
              required
              onChange={handleChange}
              name="companyDescription"
              value={companyDescription}
              rows="12"
              multiline={true}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  createCompanyProfile,
  setAlert,
  uploadCompanyLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgProfileGeneral);
