import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import {
  uploadHeaderImage,
  setRoundAsCurrent,
  setCurrentChallengeRoundIndex,
  setPrimaryColorForChallenge,
} from "../../../redux/actions/challenge";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
// Components
import Spinner from "../../../shared/Spinner";
import OrgAppBar from "../../../shared/OrgAppBar";
import EditChallengeForm from "./EditChallengeForm";

const OrgChallengeHome = ({
  challenge,
  loading,
  setCurrentChallengeRoundIndex,
}) => {
  useEffect(() => {
    // Hard code to round 0 for now
    const foundIndex =
      challenge &&
      challenge.round &&
      challenge.round.length > 0 &&
      challenge.round.findIndex((elem) => elem.id === challenge.currentRoundId);
    setCurrentChallengeRoundIndex(foundIndex ? foundIndex : 0);
  }, []);

  return (
    <>
      <Box height="100%" margin="auto" my={4}>
        {loading ? (
          <Spinner />
        ) : challenge ? (
          <Box>
            <Container maxWidth="sm">
              <EditChallengeForm />
            </Container>
          </Box>
        ) : (
          "Could not find this program, please refresh."
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  company: state.company.companyProfile,
  loading: state.challenge.loading,
});

const mapDispatchToProps = {
  setAlert,
  uploadHeaderImage,
  setRoundAsCurrent,
  setCurrentChallengeRoundIndex,
  setPrimaryColorForChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgChallengeHome);
