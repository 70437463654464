import React from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
// react-query
import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  getAllArticlesForOrganization,
  deleteArticle,
} from "../../hooks/knowledge";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import { makeStyles } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import grey from "@material-ui/core/colors/grey";
// Icons
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/Edit";
// Components
import Spinner from "../../shared/Spinner";
import Button from "../../shared/Button";
import OrgAppBar from "../../shared/OrgAppBar";
import CustomAvatar from "../../shared/CustomAvatar";
import Card from "../../shared/Card";
import EmptyState from "../../shared/EmptyState";
// Icons
import { IoReaderOutline } from "react-icons/io5";
// Utils
import { serializeToString } from "../../utils/serializeToString";
import { formatDistanceToNow } from "date-fns";

const useStyles = makeStyles((theme) => ({
  lastUpdateByAvatar: {
    fontSize: 20,
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
  },
  articleCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    transition: "ease-in-out 0.2s",
    "&:hover": {
      boxShadow: theme.shadows[4],
      transition: "ease-in-out 0.2s",
    },
  },
}));

const OrgKnowledgeBase = ({ companyProfile }) => {
  const classes = useStyles();
  const location = useLocation();
  const queryClient = useQueryClient();
  const {
    isLoading,
    data: { data: articles } = { data: null },
    isError,
    error,
  } = useQuery(
    ["knowledgeArticles", { companyId: companyProfile.id }],
    getAllArticlesForOrganization
  );

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ["knowledgeArticles", { companyId: companyProfile.id }],
        (prevArticles) => {
          if (prevArticles && prevArticles.data) {
            return {
              ...prevArticles,
              data: prevArticles.data.filter(
                (elem) => elem.id !== variables.articleId
              ),
            };
          }
        }
      );
    },
  });

  const handleDeleteArticle = (articleId) => {
    if (
      window.confirm(
        "Are you sure you would like to delete this knowledge article?"
      )
    ) {
      deleteArticleMutation.mutate({
        articleId: articleId,
      });
    }
  };

  return (
    <Box>
      <OrgAppBar
        title={
          <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
            <IoReaderOutline /> Knowledge Base
          </Box>
        }
        subtitle="View all of the knowledge articles in your community"
        actions={
          <>
            <Button component={Link} to={`${location.pathname}/new`}>
              Create New Article
            </Button>
          </>
        }
      />
      <Box>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        ) : (
          <Box>
            {articles && articles.length > 0 ? (
              <Grid container spacing={4}>
                {articles.map((article) => {
                  const contentString =
                    article.content && serializeToString(article.content);

                  const lastUpdateByUser = article.lastUpdateByUser;
                  return (
                    <Grid item xs={12} md={6} lg={4} key={article.id}>
                      <Card
                        style={{
                          backgroundColor: article.isPublished
                            ? "background.paper"
                            : "action.disabledBackground",
                        }}
                        className={classes.articleCard}
                      >
                        <Box>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            color="textPrimary"
                          >
                            <Box lineHeight={1} fontWeight={600}>
                              {article.title}
                            </Box>
                          </Typography>

                          {article.lastUpdateDateTime && lastUpdateByUser && (
                            <>
                              <Box my={1} display="flex" alignItems="center">
                                <CustomAvatar
                                  src={lastUpdateByUser.avatar}
                                  alt={lastUpdateByUser.userName}
                                  fallbackText={lastUpdateByUser.userName}
                                  className={classes.lastUpdateByAvatar}
                                />
                                <Typography
                                  variant="body2"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box lineHeight={1.2}>
                                    Last updated by{" "}
                                    {article.lastUpdateByProfile
                                      ? `${article.lastUpdateByProfile.firstName}`
                                      : lastUpdateByUser.userName}{" "}
                                    {formatDistanceToNow(
                                      new Date(article.lastUpdateDateTime)
                                    )}{" "}
                                    ago
                                  </Box>
                                </Typography>
                              </Box>
                            </>
                          )}
                          {contentString && (
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                            >
                              <Box>
                                {contentString.substring(0, 200)}
                                {contentString.length >= 200 && "..."}
                              </Box>
                            </Typography>
                          )}
                        </Box>
                        <Box
                          mt={2}
                          display="flex"
                          alignItems="center"
                          style={{ gap: "8px" }}
                        >
                          <Button
                            component={Link}
                            to={`${location.pathname}/${article.id}/edit`}
                            startIcon={<EditIcon />}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={<DeleteOutlineOutlinedIcon />}
                            onClick={() => handleDeleteArticle(article.id)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <EmptyState
                title="No articles added yet"
                subtitle="Knowledge base articles are perfect for your members to
              learn more about your community. It works well for adding
              resources, help docs, how-tos, and more."
                link="https://www.notion.so/Creating-community-articles-95a8604bd5764fe292199d46ab3af0a2"
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(OrgKnowledgeBase);
