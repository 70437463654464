import React, { useState, Fragment } from "react";
import EditIcon from "@material-ui/icons/Edit";
import ProfileForm from "./ProfileForm";
import Button from "../../../shared/Button";

const ProfileButton = ({ fullWidth = true, edit = true }) => {
  const [modal, toggleModal] = useState(false);

  const toggle = () => {
    toggleModal(!modal);
  };
  return (
    <Fragment>
      <div>
        <Button color="primary" onClick={toggle} startIcon={<EditIcon />}>
          {edit ? "Edit" : "Create Your"} Profile
        </Button>
      </div>

      <ProfileForm modal={modal} toggle={toggle} />
    </Fragment>
  );
};

export default ProfileButton;
