import CMU_WORDMARK from "../../../assets/images/cmu-wordmark-horizontal-70k.png";
import ALLYSON from "../../../assets/avatars/allyson.png";

const Testimonials = () => {
  return (
    <section className="py-12 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <img className="mx-auto h-8" src={CMU_WORDMARK} alt="CMU WORDMARK" />
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo;Pralent is the perfect business competition platform.
                From accepting videos and business plans, to communicating with
                teams and managing judges... the software is easy to use and
                navigate through, and the support you receive from the creators
                are amazing!&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src={ALLYSON}
                    alt="allyson hince"
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    Allyson Hince
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-red-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    Program Manager, CMU Swartz Center for Entrepreneurship
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
