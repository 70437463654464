import React from "react";
import { useParams } from "react-router";
// hooks
import {
  useGetEventBySlug,
  useGetAttendingUsersForEvent,
  useGetNumberOfEventRegistrations,
} from "../../hooks/event";
// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Alert from "@material-ui/lab/Alert";
// Components
import EventHeaderImage from "./components/EventHeaderImage";
import EventDateTime from "./components/EventDateTime";
import EventLocation from "./components/EventLocation";
import EventOnline from "./components/EventOnline";
import EventSpeaker from "./components/EventSpeaker";
import EventOrgCard from "./components/EventOrgCard";
import EventRegister from "./components/EventRegister";
import EventPrimaryContact from "./components/EventPrimaryContact";
import EventSocialMediaShare from "./components/EventSocialMediaShare";
import EventAttendees from "./components/EventAttendees";
import Spinner from "../../shared/Spinner";
import RichTextEditor from "../../shared/RichTextEditor";
// Utils
import { DEFAULT_TIME_ZONE } from "../../utils/globalValues";

const Event = () => {
  const { slug } = useParams();
  const {
    isLoading,
    isError,
    data: { data: event } = { data: null },
    error,
  } = useGetEventBySlug(slug);

  const { data: { data: attendees } = { data: null } } =
    useGetAttendingUsersForEvent(event);

  const {
    isLoading: isLoadingNumberOfRegistrations,
    data: { data: { numberOfRegistrations } } = {
      data: { numberOfRegistrations: 0 },
    },
  } = useGetNumberOfEventRegistrations(event);

  return (
    <>
      <Box height="100%" p={{ xs: 2, md: 4 }} overflow="auto">
        {isLoading || isLoadingNumberOfRegistrations ? (
          <Spinner />
        ) : isError ? (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        ) : (
          event && (
            <Box pb={8}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ gap: "20px" }}
                  >
                    {!event.isActive && (
                      <Alert severity="error">
                        This event is no longer active.
                      </Alert>
                    )}
                    <EventHeaderImage event={event} />
                    <Box>
                      <Typography variant="h3" component="h1" gutterBottom>
                        <Box fontWeight={700} lineHeight={1}>
                          {event.title}
                        </Box>
                      </Typography>
                      <EventOrgCard
                        company={event.company}
                        primaryContactEmail={event.primaryContactEmail}
                      />
                    </Box>
                    {event.description && (
                      <RichTextEditor
                        value={event.description}
                        readOnly={true}
                      />
                    )}
                    {event.tags && event.tags.length > 0 && (
                      <Box>
                        {event.tags.map((tag, index) => (
                          <Chip color="primary" key={index} label={tag} />
                        ))}
                      </Box>
                    )}
                    <EventSocialMediaShare event={event} />
                  </Box>
                  {event.sessions && event.sessions.length > 0 && (
                    <Box mt={3}>
                      <Typography variant="h6" component="div">
                        <Box px={{ xs: 2, md: 0 }} fontWeight={600}>
                          Schedule
                        </Box>
                      </Typography>
                      <Typography variant="body2" component="div">
                        {event.sessions.map((session) => {
                          return (
                            <Box
                              key={session.id}
                              borderRadius="borderRadius"
                              boxShadow={{ xs: 0, md: 3, lg: 5 }}
                              p={{ xs: 2, md: 2 }}
                              my={1}
                            >
                              <EventDateTime
                                event={event}
                                startDateTime={session.startDateTime}
                                endDateTime={session.endDateTime}
                                timezone={DEFAULT_TIME_ZONE}
                                size="sm"
                              />
                              {session.location && (
                                <Box mt={1}>
                                  <EventLocation
                                    location={session.location}
                                    size="sm"
                                  />
                                </Box>
                              )}
                              <Typography component="div">
                                <Box mt={1} fontWeight={600}>
                                  {session.title}
                                </Box>
                              </Typography>
                              <Typography
                                variant="body2"
                                component="div"
                                color="textSecondary"
                                gutterBottom
                              >
                                <Box>{session.description}</Box>
                              </Typography>
                              <Box mt={2}>
                                <AvatarGroup>
                                  {session.speakers &&
                                    session.speakers.map((speaker) => {
                                      return (
                                        <EventSpeaker
                                          key={speaker.id}
                                          speaker={speaker}
                                        />
                                      );
                                    })}
                                </AvatarGroup>
                              </Box>
                            </Box>
                          );
                        })}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ gap: "20px" }}
                  >
                    <EventRegister
                      event={event}
                      numberOfRegistrations={numberOfRegistrations}
                    />
                    <EventDateTime
                      event={event}
                      startDateTime={event.startDateTime}
                      endDateTime={event.endDateTime}
                      timezone={DEFAULT_TIME_ZONE}
                    />

                    {event.online && (
                      <Box>
                        <EventOnline online={event.online} />
                      </Box>
                    )}
                    {event.location && (
                      <Box>
                        <EventLocation location={event.location} size="md" />
                      </Box>
                    )}
                    {event.primaryContactEmail && (
                      <Box>
                        <EventPrimaryContact
                          primaryContactEmail={event.primaryContactEmail}
                          size="md"
                        />
                      </Box>
                    )}
                    {numberOfRegistrations && numberOfRegistrations > 10 ? (
                      <Box>
                        <EventAttendees
                          event={event}
                          attendees={attendees}
                          numberOfRegistrations={numberOfRegistrations}
                          size="md"
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )
        )}
      </Box>
    </>
  );
};

export default Event;
