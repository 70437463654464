import React from "react";
import { Link, useLocation } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// react-query
// Material UI
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
// Utils
import { serializeToString } from "../../../utils/serializeToString";
import { formatDistanceToNow, format, isBefore } from "date-fns";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
  },
  companyLogo: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    objectFit: "object-cover",
  },
  lastUpdateByAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
  },
  jobCard: {
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const JobCard = ({ job, jobCategoryType }) => {
  const classes = useStyles();

  const location = useLocation();

  const jobDescription = job.description && serializeToString(job.description);
  return (
    <Card
      component={Link}
      to={`jobs/${job.id}`}
      square={false}
      className={classes.jobCard}
    >
      <CardContent>
        <Box>
          <Box mb={1} display="flex" alignItems="center">
            {job && job.company && (
              <Box my={2}>
                <CustomAvatar
                  src={job.company.companyLogoPath}
                  fallbackText={job.company.companyName}
                  alt="organization-logo"
                  className={classes.companyLogo}
                />
              </Box>
            )}
            <Box>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color="textPrimary"
              >
                <Box lineHeight={1} fontWeight={700}>
                  {job.title}
                </Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box lineHeight={1}>
                  {job.company && job.company.companyName}
                </Box>
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            style={{ gap: "8px" }}
            mb={2}
          >
            {job.typeId && (
              <Typography variant="body2" component="div" color="textSecondary">
                <Box display="flex" alignItems="center">
                  <WorkOutlineOutlinedIcon className={classes.icon} />{" "}
                  {
                    (
                      jobCategoryType.find((elem) => elem.id === job.typeId) ||
                      {}
                    ).jobType
                  }
                </Box>
              </Typography>
            )}
            {job.location && (
              <Typography variant="body2" component="div" color="textSecondary">
                <Box display="flex" alignItems="center">
                  <LocationOnOutlinedIcon className={classes.icon} />{" "}
                  {job.location}
                </Box>
              </Typography>
            )}
            {job.postedDate && (
              <Typography variant="body2" component="div" color="textSecondary">
                <Box display="flex" alignItems="center">
                  <ScheduleOutlinedIcon className={classes.icon} /> Posted{" "}
                  {formatDistanceToNow(new Date(job.postedDate))} ago
                </Box>
              </Typography>
            )}

            <Typography variant="body2" component="div" color="textSecondary">
              <Box display="flex" alignItems="center">
                <AttachMoneyOutlinedIcon className={classes.icon} />{" "}
                {job.paid ? "Paid" : "Unpaid"}
              </Box>
            </Typography>

            {job.closeDateTime && (
              <Typography variant="body2" component="div" color="textSecondary">
                <Box display="flex" alignItems="center">
                  <HourglassEmptyOutlinedIcon className={classes.icon} />{" "}
                  {isBefore(new Date(), new Date(job.closeDateTime))
                    ? `Closes in ${formatDistanceToNow(
                        new Date(job.closeDateTime)
                      )}`
                    : "Closed"}{" "}
                  on {format(new Date(job.closeDateTime), "MMM d, yyyy")}
                </Box>
              </Typography>
            )}
          </Box>
        </Box>
        {/* {jobDescription && (
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>
              {jobDescription.substring(0, 200)}
              {jobDescription.length >= 200 && "..."}
            </Box>
          </Typography>
        )} */}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  jobCategoryType: state.typedef.jobCategoryType,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobCard);
