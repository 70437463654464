import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPublicProfiles,
  clearProfilesInState,
} from "../../redux/actions/profile";
import { SKIP, LIMIT } from "../../utils/globalValues";
import ProfileItem from "../../shared/ProfileItem";
// Material UI
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// Utils
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  card: {
    justify: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Profiles = ({
  getPublicProfiles,
  skip,
  limit,
  user,
  clearProfilesInState,
  profile: { profiles, loading },
}) => {
  useEffect(() => {
    skip = SKIP;
    limit = LIMIT;
    //console.log(skip, limit);
    clearProfilesInState();
    getPublicProfiles(skip, limit, user.typeId);
  }, []);

  const classes = useStyles();
  return (
    <Fragment>
      <Helmet>
        <title>Profiles | Pralent</title>
        <meta
          name="description"
          content="Connect with the community on Pralent."
        />
      </Helmet>
      <Box
        py={[1, 2]}
        flexGrow={1}
        height="100%"
        maxHeight="100vh"
        overflow="auto"
      >
        <Container maxWidth="md">
          <Box textAlign="left" mb={[2, 3, 4]}>
            <Typography variant="h5">
              <strong>Profiles</strong>
            </Typography>
            <Typography color="textSecondary">
              Connect with other like-minded professionals.
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {loading || profiles === null || profiles.length <= 0
              ? Array.from(new Array(9)).map((item, index) => {
                  return (
                    <Card key={index} className={classes.card}>
                      <Skeleton
                        className={classes.avatar}
                        variant={"circle"}
                        width={100}
                        height={100}
                      />

                      <Skeleton width={"90%"} />
                      <Skeleton width={"90%"} />
                      <Skeleton width={"90%"} />
                      <Skeleton width={"60%"} />
                      <Skeleton width={"45%"} />
                    </Card>
                  );
                })
              : profiles &&
                profiles.length > 0 &&
                profiles.map((profile, index) => (
                  <Grid key={index} item xs={12} md={6} lg={4}>
                    <ProfileItem profile={profile} />
                  </Grid>
                ))}
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

Profiles.propTypes = {
  getPublicProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  clearProfilesInState: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  skip: state.skip,
  limit: state.limit,
  user: state.auth.user,
  hasMore: state.hasMore,
});

export default connect(mapStateToProps, {
  getPublicProfiles,
  clearProfilesInState,
})(Profiles);
