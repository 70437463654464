import React from "react";
//Images
import mlimage from "../../assets/images/mlimage.svg";
import engineeringimage from "../../assets/images/engineeringimage.svg";
import webdevelopmentimage from "../../assets/images/webdevelopmentimage.svg";
import businessimage from "../../assets/images/businessimage.svg";
import designimage from "../../assets/images/designimage.svg";

const PlaceholderProjectImage = ({ categories = [1] }) => {
  const [SVG, setSVG] = React.useState(mlimage);
  const [color, setColor] = React.useState("bg-primary-700");

  const imageMap = {
    1: { color: "bg-orange-400", image: webdevelopmentimage },
    2: { color: "bg-orange-400", image: webdevelopmentimage },
    3: { color: "bg-orange-400", image: webdevelopmentimage },
    4: { color: "bg-primary-700", image: mlimage },
    5: { color: "bg-primary-700", image: mlimage },
    6: { color: "bg-red-700", image: designimage },
    7: { color: "bg-indigo-700", image: engineeringimage },
    8: { color: "bg-indigo-700", image: engineeringimage },
    9: { color: "bg-indigo-700", image: engineeringimage },
    10: { color: "bg-indigo-700", image: engineeringimage },
    11: { color: "bg-teal-700", image: businessimage },
    12: { color: "bg-teal-700", image: businessimage },
    13: { color: "bg-red-700", image: designimage },
    14: { color: "bg-teal-700", image: businessimage },
    15: { color: "bg-teal-700", image: businessimage },
    16: { color: "bg-primary-700", image: mlimage },
    17: { color: "bg-primary-700", image: mlimage },
    18: { color: "bg-teal-700", image: businessimage },
    19: { color: "bg-teal-700", image: businessimage },
    20: { color: "bg-teal-700", image: businessimage },
    21: { color: "bg-teal-700", image: businessimage },
  };

  const findImageColor = () => {
    if (categories && categories.length > 0) {
      const imageRes = imageMap[categories[0]];
      setSVG((imageRes && imageRes.image) || mlimage);
      setColor((imageRes && imageRes.color) || "bg-primary-700");
    }
  };

  React.useEffect(() => {
    findImageColor();
  }, [categories]);

  return (
    <div className={`rounded-t`}>
      <img
        src={
          "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
        }
        className="object-cover mb-4"
      />
    </div>
  );
};

export default PlaceholderProjectImage;
