import React, { Fragment } from "react";
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
import YouTubeIcon from "@material-ui/icons/YouTube";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CodeIcon from "@material-ui/icons/Code";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(2),
  },
  text: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export const ProjectLinks = ({ project, extLinks }) => {
  const classes = useStyles();

  const linkIcons = {
    1: <CodeIcon className={classes.icon} />,
    2: <PermMediaIcon className={classes.icon} />,
    3: <DescriptionIcon className={classes.icon} />,
    4: <YouTubeIcon className={classes.icon} />,
    5: <AccountBalanceIcon className={classes.icon} />,
    6: <LanguageIcon className={classes.icon} />,
  };

  return (
    <Box my={[2, 4]}>
      <Box mb={[1, 2]}>
        <Typography variant="h6">
          <strong>External Links</strong>
        </Typography>
      </Box>

      {project && project.extLinks && project.extLinks.length ? (
        Object.entries(project.extLinks).map(([key, value], index) => {
          const link = value.externalUrl;
          const id = value.extLinkTypeId;
          const found = extLinks.find((elem) => elem.id === id);
          const tooltip = (found && `View ${found.name} Link`) || "";

          if (link !== "") {
            return (
              <Box mb={[1, 2]} key={index}>
                <a href={link} rel="noopener noreferrer" target="_blank">
                  <Box display="flex" alignItems="center">
                    {linkIcons[id]}
                    <Typography className={classes.text} variant="body2">
                      {tooltip}
                    </Typography>
                  </Box>
                </a>
              </Box>
            );
          }
        })
      ) : (
        <div>
          <Typography variant="body2">
            There are no external links for this project.
          </Typography>
        </div>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  extLinks: state.typedef.extLinks,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLinks);
