import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const RadioWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
}) => {
  const { enumOptions, enumDisabled } = options;

  const _onChange = ({}, value) =>
    onChange(schema.type == "boolean" ? value !== "false" : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  const row = options ? options.inline : false;

  return (
    <>
      <Box display={disabled ? "none" : "block"} width="100%">
        <RadioGroup
          value={`${value}`}
          row={row}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
        >
          {enumOptions.map((option, i) => {
            const itemDisabled =
              enumDisabled && enumDisabled.indexOf(option.value) != -1;

            const radio = (
              <FormControlLabel
                control={<Radio color="primary" key={i} />}
                label={`${option.label}`}
                value={`${option.value}`}
                key={i}
                disabled={disabled || itemDisabled || readonly}
              />
            );

            return radio;
          })}
        </RadioGroup>
      </Box>
      {disabled && (
        <Box>
          <Typography variant="body2" color="textPrimary">
            <em>{value}</em>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RadioWidget;
