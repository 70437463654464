import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { DEFAULT_PAGE_SIZE } from "../../utils/globalValues";
// Components
import Spinner from "../Spinner";

// Custom Pralent Table component
function PTable(props) {
  const { records, headerCells, columns, noRowsText, isLoading } = props;

  const [recordsToDisplay, setRecordsToDisplay] = useState([]);

  // Pagination
  const getRowsPerPageOptions = (records) => {
    let options = [];
    if (records && records.length > 0) {
      if (records.length > 50) {
        options.push(50);
        if (records.length > 100) {
          options.push(100);
        }
      }
      options.push(records.length);
    } else options = [DEFAULT_PAGE_SIZE];

    return options;
  };

  const [paginationConfig, setPaginationConfig] = useState({
    page: 0,
    rowsPerPage: DEFAULT_PAGE_SIZE,
    rowsPerPageOptions: [],
  });

  useEffect(() => {
    const getOptions = getRowsPerPageOptions(records);

    setPaginationConfig({
      ...paginationConfig,
      rowsPerPageOptions: getOptions,
    });
  }, [records]);

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [sortFn, setSortFn] = useState(null);

  function stableSort(array, comparator) {
    let stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis = stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    const columnToSort =
      headerCells && headerCells.find((elem) => elem.id === orderBy);

    if (
      columnToSort &&
      !columnToSort.disableSorting &&
      columnToSort.sortComparator
    ) {
      const sortFn = columnToSort.sortComparator;
      return sortFn(a, b);
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleChangePage = (event, newPage) => {
    setPaginationConfig({
      ...paginationConfig,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginationConfig({
      ...paginationConfig,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const recordsAfterPagingAndSorting = () => {
    let newRecords = [];
    const { page, rowsPerPage } = paginationConfig;
    /* if (records && records.length > 0) {
      newRecords = records.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    } else {
      newRecords = [];
    } */
    if (records && records.length > 0) {
      newRecords = stableSort(records, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage
      );
    } else newRecords = [];

    setRecordsToDisplay(newRecords);
  };

  useEffect(() => {
    if (records) {
      recordsAfterPagingAndSorting();
    }
  }, [records, paginationConfig, order, orderBy]); // add sort config, filter config

  const CustomNoRowsOverlay = () => {
    return (
      <Box p={{ xs: 2, md: 4 }} textAlign="center">
        <Typography variant="body2">
          {noRowsText || "There are no rows."}
        </Typography>
      </Box>
    );
  };

  const TblPagination = () => {
    return (
      <TablePagination
        component="div"
        colSpan={3}
        page={paginationConfig.page}
        rowsPerPageOptions={paginationConfig.rowsPerPageOptions}
        rowsPerPage={paginationConfig.rowsPerPage || DEFAULT_PAGE_SIZE}
        count={(records && records.length) || 0}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    );
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headerCells.map((headerCell, index) => {
                if (!headerCell.hide)
                  return (
                    <TableCell
                      key={index}
                      key={headerCell.id}
                      sortDirection={orderBy === headerCell.id ? order : false}
                    >
                      {headerCell.disableSorting ? (
                        headerCell.label
                      ) : (
                        <TableSortLabel
                          active={orderBy === headerCell.id}
                          direction={orderBy === headerCell.id ? order : "asc"}
                          onClick={() => {
                            setSortFn(headerCell.sortComparator);
                            handleSortRequest(headerCell.id);
                          }}
                        >
                          {headerCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {recordsToDisplay &&
              recordsToDisplay.length > 0 &&
              recordsToDisplay.map((row, index) => (
                <TableRow key={index}>
                  {columns.map((col, j) => {
                    const header = headerCells[j];
                    if (!header.hide) {
                      return (
                        <TableCell key={j}>{col.renderCell(row)}</TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!recordsToDisplay ||
        (recordsToDisplay.length === 0 && <CustomNoRowsOverlay />)}
      <TblPagination />
    </>
  );
}

export default PTable;
