import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// react-router-dom
import { useHistory } from "react-router-dom";
// Material UI
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
// Components
import Button from "../../shared/Button";
import CustomAvatar from "../../shared/CustomAvatar";
// Utils
import { DEFAULT_PRIMARY_COLOR } from "../../utils/globalValues";
import { ColorPicker } from "material-ui-color";
// react-query
import { useMutation } from "react-query";
// Hooks
import {
  createUpdateOrganization,
  useUploadCompanyLogo,
} from "../../hooks/company";
// React spring
import { useSpring, animated, config } from "@react-spring/web";
import {
  IoEllipsisHorizontal,
  IoBriefcaseOutline,
  IoSchoolOutline,
  IoTrophyOutline,
  IoHandLeftOutline,
  IoDiamondOutline,
  IoGlassesOutline,
  IoBookOutline,
  IoLibraryOutline,
} from "react-icons/io5";

const COMMUNITY_TYPES = [
  {
    name: "Club",
    icon: IoBookOutline,
  },
  {
    name: "Creator",
    icon: IoGlassesOutline,
  },
  {
    name: "Company",
    icon: IoBriefcaseOutline,
  },

  {
    name: "University",
    icon: IoSchoolOutline,
  },
  {
    name: "NFT / DAO",
    icon: IoDiamondOutline,
  },
  {
    name: "Competition",
    icon: IoTrophyOutline,
  },
  {
    name: "Non-profit",
    icon: IoHandLeftOutline,
  },
  {
    name: "Class",
    icon: IoLibraryOutline,
  },
  {
    name: "Other",
    icon: IoEllipsisHorizontal,
  },
];

const useStyles = makeStyles((theme) => ({
  orgLogo: {
    fontSize: "40px",
    width: theme.spacing(13),
    height: theme.spacing(13),
    objectFit: "object-cover",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[200],
    borderWidth: "1px",
    transition: "ease-in-out 0.2s",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      boxShadow: theme.shadows[4],
    },
  },
}));

function OrgCreate({ setAlert }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const mediumScreens = useMediaQuery(theme.breakpoints.up("md"));

  const [formData, setFormData] = useState({
    companyId: null,
    orgId: null,
    companyLogoPath: null,
    companyName: "",
    communitySlug: "",
    companyDescription: "",
    communityWelcomeMessage: [
      {
        type: "paragraph",
        children: [
          {
            text: "Welcome to our community, we are so excited to have you with us. Be sure to add your profile in our members directory and take a look at our upcoming programs and events as well as new job opportunities.",
          },
        ],
      },
    ],
    isCommunityEnabled: true,
    isCommunityPublic: true,
    doMembersNeedApproval: false,
    primaryColor: theme.palette.primary.main || DEFAULT_PRIMARY_COLOR,
    teamMembers: [],
    industries: null,
  });

  const createUpdateMutation = useMutation(createUpdateOrganization, {
    onSuccess: async (data) => {
      if (
        data &&
        data.data &&
        data.data.company &&
        data.data.company.id &&
        data.data.company.orgId
      ) {
        await setAlert("Community was successfully created!");
        setFormData({
          ...formData,
          ["companyId"]: data.data.company.id,
          ["orgId"]: data.data.company.orgId,
        });

        // Upload the logo
        await useUploadCompanyLogoMutation.mutate({
          companyId: data.data.company.id,
          companyLogoPath: formData.companyLogoPath,
        });

        // Redirect user to dashboard page
        history.push(`/org/${data.data.company.orgId}/home`);
      }
    },
    onError: async (error) => {
      await setAlert(error?.data?.msg || "Error creating community", "error");
    },
  });

  const useUploadCompanyLogoMutation = useMutation(useUploadCompanyLogo, {
    onSuccess: async (data) => {
      if (data && data.data) {
        await setAlert("Community Logo was successfully uploaded!");
      }
    },
    onError: async (error) => {
      if (
        error &&
        error.status === 415 &&
        error.statusText === "Unsupported Media Type"
      ) {
        // Display message and Redirect user to dashboard page
        await setAlert(
          "There was an error uploading community logo. Upload community logo in the community's settings page",
          "error"
        );
      } else {
        await setAlert(
          error?.data?.msg ||
            "Upload community logo in the community's settings page",
          "error"
        );
      }
      if (formData.orgId) {
        history.push(`/org/${formData.orgId}/home`);
      }
    },
  });

  const validateFormData = async () => {
    // TODO
    // Verify if the user can actualy go to this step
    // Verify if the other fields before this step are complete
    // Make sure that the community name is already entered in step 0
    if (formData.companyName === "") {
      setAlert("Company Name is required", "error");
      return false;
    }
    if (formData.communitySlug === "") {
      setAlert("Custom Page Url is required", "error");
      return false;
    }
    if (formData.communityWelcomeMessage) {
      const welcomeMsg = JSON.stringify(formData.communityWelcomeMessage);
      if (welcomeMsg.length > 550) {
        setAlert("Welcome message can't exceed 550 characters", "error");
        return false;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    let value;
    const name = e.target.name;
    const type = e.target.type;
    if (type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if necessary data in formData exists (i.e. title, slug, etc.)
    const validateRes = await validateFormData();
    if (validateRes) {
      //  Create the company first
      await createUpdateMutation.mutate({
        organizationFormData: formData,
      });
    }
  };

  const animationProps = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: 10 },
    config: {
      ...config.slow,
    },
  });

  const handlePrimaryColorChange = (color) => {
    if (color && color.css && color.css.backgroundColor) {
      // Save the selected primary color to the local state
      setFormData({
        ...formData,
        primaryColor: color.css.backgroundColor,
      });
    }
  };

  const onChangeCompanyLogo = (e) => {
    if (e.target.files.length > 0) {
      // Check the file size, file type before moving to the next step
      if (e.target.files[0].size < 5242880) {
        setFormData({
          ...formData,
          companyLogoPath: e.target.files[0],
        });
      } else {
        setAlert("Maximum file size 5MB exceeded", "error");
      }
    }
  };

  const updateCommunityIndustries = (type) => {
    setFormData({
      ...formData,
      industries: type.name,
    });
  };

  return (
    <Box flexGrow={1} height="100%" maxHeight="100vh" overflow="auto">
      <animated.div style={animationProps}>
        <ThemeProvider
          theme={(theme) =>
            createMuiTheme({
              ...theme,
              palette: {
                ...theme.palette,
                primary: {
                  main: formData.primaryColor,
                },
              },
            })
          }
        >
          <Box my={{ xs: 2, md: 4, lg: 8 }}>
            <Container maxWidth="sm">
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      mb={2}
                      textAlign="center"
                    >
                      <Typography variant="h4" component="h1">
                        <Box fontWeight={700} lineHeight={1.2}>
                          Create a new community
                        </Box>
                      </Typography>
                      <Typography
                        variant="body1"
                        component="div"
                        color="textSecondary"
                      >
                        <Box>
                          Setup a home page for your community. You can always
                          modify these settings later.
                        </Box>
                      </Typography>
                    </Box>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {COMMUNITY_TYPES.map((type, index) => {
                        const Icon = type.icon;
                        return (
                          <Grid item xs={12} sm={4} key={index}>
                            <Button
                              fullWidth
                              startIcon={<Icon />}
                              variant={
                                formData.industries &&
                                formData.industries === type.name
                                  ? "contained"
                                  : "outlined"
                              }
                              color="primary"
                              size="large"
                              onClick={() => updateCommunityIndustries(type)}
                            >
                              <Box fontSize="h6.fontSize" py={1}>
                                {type.name}
                              </Box>
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {formData.industries && (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h1">
                          <Box fontWeight={600}>Basic Information</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          flexDirection={mediumScreens ? "row" : "column"}
                          style={{ gap: mediumScreens ? "48px" : "24px" }}
                        >
                          <Box>
                            <FormGroup>
                              <InputLabel>Logo</InputLabel>
                              <Box display="flex" alignItems="center">
                                <input
                                  //accept="image/*"
                                  accept=".png, .jpg, .jpeg, .gif, .svg"
                                  className="hidden"
                                  id="company-logo-button"
                                  type="file"
                                  onChange={onChangeCompanyLogo}
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                />
                                <Box>
                                  <label
                                    htmlFor="company-logo-button"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <CustomAvatar
                                      src={
                                        formData.companyLogoPath &&
                                        URL.createObjectURL(
                                          formData.companyLogoPath
                                        )
                                      }
                                      fallbackText={formData.companyName || "P"}
                                      alt="organization-logo"
                                      className={classes.orgLogo}
                                    />
                                  </label>
                                </Box>
                              </Box>
                            </FormGroup>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            style={{ gap: "24px" }}
                          >
                            <FormGroup>
                              <InputLabel required>Name</InputLabel>
                              <TextField
                                required
                                name="companyName"
                                placeholder="My Awesome Community"
                                fullWidth
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <ColorPicker
                                    value={formData.primaryColor}
                                    deferred
                                    hideTextfield
                                    onChange={handlePrimaryColorChange}
                                  />
                                }
                                label={
                                  <Box ml={1}>
                                    <Typography
                                      variant="body2"
                                      component="div"
                                      gutterBottom
                                    >
                                      <Box fontWeight={600}>
                                        Choose a community theme color
                                      </Box>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="textSecondary"
                                    >
                                      <Box lineHeight={1.2}>
                                        We recommend choosing a darker and
                                        brighter primary color for your
                                        community. This primary color will be
                                        used on all community pages.
                                      </Box>
                                    </Typography>
                                  </Box>
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <InputLabel required>Page URL</InputLabel>
                              <TextField
                                type="text"
                                required
                                onChange={handleChange}
                                name="communitySlug"
                                value={formData.communitySlug}
                                placeholder="custom-url"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ margin: 0, padding: 0 }}
                                    >
                                      <Box fontWeight={600}>
                                        {document.location.origin}/c/
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{ pattern: "^[-a-zA-Z0-9@.+_]+$" }}
                              />
                            </FormGroup>
                            <FormGroup>
                              <InputLabel required>Description</InputLabel>
                              <TextField
                                placeholder="Enter a description for your community"
                                type="text"
                                required
                                onChange={handleChange}
                                name="companyDescription"
                                value={formData.companyDescription}
                                rows="4"
                                multiline={true}
                              />
                            </FormGroup>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" component="h1">
                          <Box fontWeight={600}>Community Privacy</Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                id="isCommunityEnabled"
                                name="isCommunityEnabled"
                                color="primary"
                                checked={formData.isCommunityEnabled}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Box ml={1}>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  gutterBottom
                                >
                                  <Box fontWeight={600}>
                                    Enable members to join the community
                                  </Box>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box lineHeight={1.2}>
                                    If you select this option, you will be
                                    enabling community features for your
                                    organization. If enabled, individuals will
                                    be able to join your community, view and
                                    interact with other members, and much more.
                                  </Box>
                                </Typography>
                              </Box>
                            }
                          />
                        </FormGroup>
                      </Grid>

                      {formData.isCommunityEnabled && (
                        <>
                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    id="doMembersNeedApproval"
                                    name="doMembersNeedApproval"
                                    color="primary"
                                    checked={formData.doMembersNeedApproval}
                                    onChange={handleChange}
                                  />
                                }
                                label={
                                  <Box ml={1}>
                                    <Typography
                                      variant="body2"
                                      component="div"
                                      gutterBottom
                                    >
                                      <Box fontWeight={600}>
                                        Require Membership Approval
                                      </Box>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="textSecondary"
                                    >
                                      <Box lineHeight={1.2}>
                                        If you select this option, members will
                                        require approval from an organizer to
                                        have access to your community.
                                      </Box>
                                    </Typography>
                                  </Box>
                                }
                              />
                            </FormGroup>
                          </Grid>

                          <Grid item xs={12}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    id="isCommunityPublic"
                                    name="isCommunityPublic"
                                    color="primary"
                                    checked={formData.isCommunityPublic}
                                    onChange={handleChange}
                                  />
                                }
                                label={
                                  <Box ml={1}>
                                    <Typography
                                      variant="body2"
                                      component="div"
                                      gutterBottom
                                    >
                                      <Box fontWeight={600}>
                                        Public to Pralent
                                      </Box>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      component="div"
                                      color="textSecondary"
                                    >
                                      <Box lineHeight={1.2}>
                                        When you enable this setting, the
                                        community will be available for all
                                        Pralent users to discover.
                                      </Box>
                                    </Typography>
                                  </Box>
                                }
                              />
                            </FormGroup>
                          </Grid>

                          {/* <Grid item xs={12}>
                      <FormGroup>
                        <InputLabel>Welcome Message</InputLabel>

                        <RichTextEditor
                          placeholder="Enter a welcome message for community members to see once they join."
                          setValue={(value) =>
                            setFormData({
                              ...formData,
                              communityWelcomeMessage: value,
                            })
                          }
                          value={formData.communityWelcomeMessage}
                        />
                      </FormGroup>
                    </Grid> */}
                        </>
                      )}
                      <Grid item xs={12}>
                        <Box
                          mt={4}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          flexWrap="wrap"
                          style={{ gap: "8px" }}
                        >
                          <Button
                            size="medium"
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Create
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Container>
          </Box>
        </ThemeProvider>
      </animated.div>
    </Box>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(OrgCreate);
