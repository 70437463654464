import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Material UI
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
// icons
import YouTubeIcon from "@material-ui/icons/YouTube";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CodeIcon from "@material-ui/icons/Code";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// components
import PlaceholderProjectImage from "../../shared/PlaceholderProjectImage";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    objectFit: "cover",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    fontWeight: "600",
  },
  chip: {
    fontSize: "10px",
    cursor: "pointer",
  },
  header: {
    paddingBottom: 0,
    "& > div": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  content: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  editButton: {
    color: theme.palette.warning.main,
    fontWeight: "600",
    fontSize: 12,
  },
  likeButton: {
    fontWeight: "600",
    "&:focus": {
      outline: "none",
    },
  },
  commentButton: {
    fontWeight: "600",
    "&:focus": {
      outline: "none",
    },
  },
  card: {
    width: "100%",
    height: "100%",
    display: "inline-block",
    boxShadow: theme.shadows[1],
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
    },
    transition: "ease-in-out 0.2s",
  },
}));

const SimplePublicProjectView = ({
  project,
  projectCategories,
  extLinks,
  auth,
}) => {
  const classes = useStyles();
  const [categoryNames, setCategoryNames] = useState([]);
  const [youtubeVideoId, setYouTubeVideoId] = useState("");
  const [linkIcons] = useState({
    1: <CodeIcon />,
    2: <PermMediaIcon />,
    3: <DescriptionIcon />,
    4: <YouTubeIcon />,
    5: <AccountBalanceIcon />,
    6: <LanguageIcon />,
  });
  useEffect(() => {
    const categories = project.categories;
    let newCategoryNames = [];
    for (let i = 0; i < categories.length; i++) {
      const found = projectCategories.findIndex(
        (elem) => elem.id === categories[i]
      );
      if (found !== -1) {
        newCategoryNames.push(projectCategories[found].name);
      }
    }
    setCategoryNames(newCategoryNames);
  }, [project]);

  /* useEffect(() => {
    if (project && project.skills) {
      const skills = project.skills;
      let newSkillSetNames = [];
      for (let i = 0; i < skills.length; i++) {
        const found = skillSet.findIndex(elem => elem.id === skills[i]);
        if (found !== -1) {
          newSkillSetNames.push(skillSet[found].skillSetName);
        }
      }
      setSkillSetNames(newSkillSetNames);
    }
  }, [project]);
 */
  useEffect(() => {
    for (let i = 0; i < project.extLinks.length; i++) {
      const currentExtLink = project.extLinks[i];
      const extLinkTypeId = currentExtLink.extLinkTypeId;
      const externalUrl = currentExtLink.externalUrl;
      if (extLinkTypeId === 4) {
        const split = externalUrl.split("v=")[1];
        let string;
        if (split) {
          const secondIndex = split.indexOf("&");
          string = split.substring(
            0,
            secondIndex !== -1 ? secondIndex : split.length
          );
        }
        setYouTubeVideoId(string || "");
      }
    }
  }, [project]);

  return (
    <Fragment>
      <Card className={classes.card}>
        <CardActionArea
          component={RouterLink}
          to={`/projects/${project.id}`}
          style={{ height: "100%" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {project.projectImages && project.projectImages.length > 0 ? (
              <CardMedia
                className={classes.media}
                image={project.projectImages[0].filePath}
                title={project.projectImages[0].filePath}
              />
            ) : youtubeVideoId && youtubeVideoId !== "" ? (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "56.25%",
                  height: "0",
                }}
              >
                <iframe
                  title={youtubeVideoId}
                  src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                  frameBorder="0"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: "absolute",
                  }}
                />
              </div>
            ) : (
              <PlaceholderProjectImage
                categories={project && project.categories}
              />
            )}
            <CardContent style={{ height: "100%" }}>
              <Typography variant="body1" component="div">
                <Box fontWeight={600}>{project.title}</Box>
              </Typography>
              <Box display="flex" flexWrap="wrap" mt={1} alignItems="center">
                {categoryNames &&
                  categoryNames.length > 0 &&
                  categoryNames.slice(0, 1).map((name, index) => {
                    return (
                      <Chip
                        color="default"
                        key={index}
                        label={name}
                        className={classes.chip}
                      />
                    );
                  })}
                {project &&
                  project.skills &&
                  project.skills.length > 0 &&
                  project.skills.slice(0, 2).map((skill, index) => {
                    return (
                      <Chip
                        color="default"
                        key={index}
                        label={skill.name}
                        className={classes.chip}
                      />
                    );
                  })}
              </Box>

              <Box display="flex" flexWrap="wrap" mt={1} alignItems="center">
                {project.extLinks &&
                  Object.entries(project.extLinks).map(([key, value]) => {
                    const link = value.externalUrl;
                    const id = value.extLinkTypeId;
                    const found = extLinks.find((elem) => elem.id === id);
                    const tooltip = (found && `View ${found.name} Link`) || "";

                    if (link !== "") {
                      return (
                        <Box mr={1} key={key}>
                          <Tooltip title={tooltip}>{linkIcons[id]}</Tooltip>
                        </Box>
                      );
                    }
                  })}
              </Box>
            </CardContent>

            <Box>
              <Divider />
              <CardActions>
                <AvatarGroup max={10}>
                  {project &&
                    project.projectTeam &&
                    project.projectTeam.length &&
                    project.projectTeam.map((member, index) => {
                      const userAccount = member.userAccount;
                      if (userAccount !== undefined && userAccount) {
                        return (
                          <Tooltip title={userAccount.userName} key={index}>
                            <Avatar
                              src={userAccount.avatar}
                              alt={userAccount.userName}
                            />
                          </Tooltip>
                        );
                      } else {
                        return;
                      }
                    })}
                </AvatarGroup>
              </CardActions>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </Fragment>
  );
};

SimplePublicProjectView.propTypes = {
  project: PropTypes.object.isRequired,
  projectCategories: PropTypes.array.isRequired,
  extLinks: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  projectCategories: state.typedef.projectCategories,
  extLinks: state.typedef.extLinks,
  auth: state.auth,
});

export default connect(mapStateToProps)(SimplePublicProjectView);
