import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { red } from "@material-ui/core/colors";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Fade from "@material-ui/core/Fade";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { removeAlert } from "../../redux/actions/alert";
import { Box } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

const variantIcon = {
  success: <CheckCircleIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
};

const useStyles1 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },

  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: red[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
  snackbar: {
    zIndex: "1000000",
  },
  alert: {
    boxShadow: "none",
    margin: theme.spacing(0.5),
  },
  alertDiv: {
    position: "fixed",
    bottom: 0,
    zIndex: theme.zIndex.snackbar,
    boxShadow: "none",
  },
}));

const PralentAlert = ({ alert, removeAlert }) => {
  const classes = useStyles1();
  const [isIn, setIn] = useState(false);
  const variant = alert.alertType;
  const Icon = variantIcon[variant];
  useEffect(() => {
    setTimeout(() => setIn(true), 100);
  }, []);
  return (
    <Fade in={isIn}>
      <Alert
        key={alert.id}
        icon={Icon}
        severity={variant}
        variant="standard"
        className={classes.alert}
        onClose={() => removeAlert(alert.id)}
      >
        {alert.msg}
      </Alert>
    </Fade>
  );
};

const Alerts = ({ alert, removeAlert }) => {
  const classes = useStyles1();
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.alertDiv}
    >
      {alert &&
        alert.length > 0 &&
        alert.map((alert, index) => {
          return (
            <PralentAlert key={index} alert={alert} removeAlert={removeAlert} />
          );
        })}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alert.alerts,
});

export default connect(mapStateToProps, { removeAlert })(Alerts);
