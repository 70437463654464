import React, { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
// Icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import LibraryBooksOutlinedIcon from "@material-ui/icons/LibraryBooksOutlined";
// Redux
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/auth";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MuiLink from "@material-ui/core/Link";
// Icons
import {
  IoCalendarOutline,
  IoPeopleOutline,
  IoBulbOutline,
  IoHomeOutline,
  IoTicketOutline,
  IoBriefcaseOutline,
  IoReaderOutline,
  IoIdCardOutline,
  IoSettingsOutline,
  IoEarth,
  IoInformation,
  IoAdd,
} from "react-icons/io5";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    border: "0px",
    boxShadow: theme.shadows[1],
  },
  OrgAppBarButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    justifyContent: "flex-start",
    fontWeight: 600,
    fontSize: theme.typography.body2.fontSize,
  },
  orgLogo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    objectFit: "object-cover",
  },
  UserAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  ListItemIcon: (props) => ({
    margin: "auto",
    marginRight: theme.spacing(1.5),
    fontSize: theme.typography.body1.fontSize,
    minWidth: 0,
    borderRadius: props.iconHover && theme.shape.borderRadius,
    borderColor:
      props.iconHover && props.currentTab ? blueGrey[300] : "transparent",
    borderWidth: props.iconHover && "3px",
    padding: props.iconHover && "3px",
    transition: "0.2s ease-in-out",
    color: theme.palette.text.primary,
    "&:hover": {
      borderColor: !props.iconHover ? "transparent" : blueGrey[100],
      transition: "0.2s ease-in-out",
    },
  }),
  ListItemText: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.primary,
    fontWeight: 500,
    lineHeight: 1.2,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  ListItemTextSecondary: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  ListItem: {
    backgroundColor: "transparent",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
  },
}));

const OrgSidebar = ({ user, companyProfile }) => {
  const classes = useStyles();

  const { orgId } = useParams();

  const ORG_TABS = [
    {
      name: "Home",
      route: "/org/" + orgId + "/home",
      icon: <IoHomeOutline />,
    },
    {
      name: "Calendar",
      route: "/org/" + orgId + "/calendar",
      icon: <IoCalendarOutline />,
    },
    {
      name: "Members",
      route: "/org/" + orgId + "/members",
      icon: <IoPeopleOutline />,
    },
    // {
    //   name: "Rooms",
    //   route: "/org/" + orgId + "/rooms",
    //   icon: <span>💬</span>,
    // },
    // {
    //   name: "Newsletters",
    //   route: "/org/" + orgId + "/newsletters",
    //   icon: <span>📧</span>,
    // },
    {
      name: "Programs",
      route: "/org/" + orgId + "/programs",
      icon: <IoBulbOutline />,
    },
    {
      name: "Events",
      route: "/org/" + orgId + "/events",
      icon: <IoTicketOutline />,
    },
    {
      name: "Job Board",
      route: "/org/" + orgId + "/jobboard",
      icon: <IoBriefcaseOutline />,
    },
    {
      name: "Recruit",
      route: "/org/" + orgId + "/recruit",
      icon: <IoIdCardOutline />,
    },
    {
      name: "Knowledge Base",
      route: "/org/" + orgId + "/knowledge",
      icon: <IoReaderOutline />,
    },
    {
      name: "Settings",
      route: "/org/" + orgId + "/settings",
      icon: <IoSettingsOutline />,
    },
    {
      name: "My Community Page",
      route: `/c/${companyProfile?.communitySlug}`,
      icon: <IoEarth />,
    },
  ];

  const openCrisp = () => {
    global.$crisp.push(["do", "chat:open"]);
  };

  const [helpFeedbackDialog, setHelpFeedbackDialog] = useState(false);
  const handleHelpFeedbackDialog = () => {
    setHelpFeedbackDialog(!helpFeedbackDialog);
  };

  const HELPFEEDBACK_LIST = [
    {
      primary: "Pralent Guide",
      secondary: "Read more about Pralent's features",
      icon: LibraryBooksOutlinedIcon,
      href: "https://help.pralent.com",
      component: MuiLink,
      target: "_blank",
      rel: "noopener noreferrer",
    },
    {
      primary: "New Features & Updates",
      secondary: "Learn more about new features",
      icon: NewReleasesOutlinedIcon,
      href: "https://twitter.com/PralentHQ",
      component: MuiLink,
      target: "_blank",
      rel: "noopener noreferrer",
    },
    {
      primary: "Contact support",
      secondary:
        "Let us know if there is an issue. You can also email us at support@pralent.com",
      icon: HelpOutlineIcon,
      component: MuiLink,
      href: "mailto:support@pralent.com",
    },
    {
      primary: "Share feedback",
      secondary: "Share feedback and/or submit a new feature request",
      icon: ChatOutlinedIcon,
      component: MuiLink,
      href: "mailto:samarth@pralent.com",
    },
  ];

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        {companyProfile && (
          <Box display="flex" flex="initial" alignItems="center" px={2} py={1}>
            <Box
              display="flex"
              alignItems="center"
              flexGrow={1}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              component={Link}
              to={`/org/${companyProfile.orgId}/home`}
            >
              <CustomAvatar
                src={companyProfile.companyLogoPath}
                fallbackText={companyProfile.companyName}
                alt={`${companyProfile.companyName}-logo`}
                className={classes.orgLogo}
              />
              <Box
                lineHeight={1}
                fontWeight={600}
                fontSize="body2.fontSize"
                marginLeft={1.5}
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                color="text.primary"
              >
                {companyProfile.companyName}
              </Box>
            </Box>
            <Box ml={1}>
              <Link to="/me">
                <CustomAvatar
                  alt={user?.userName}
                  src={user?.avatar}
                  fallbackText={user?.userName}
                  className={classes.UserAvatar}
                />
              </Link>
            </Box>
          </Box>
        )}

        <Box>
          <List>
            {ORG_TABS &&
              ORG_TABS.map((tab, index) => {
                return (
                  <ListItem
                    className={classes.ListItem}
                    button
                    component={Link}
                    to={tab.route}
                    key={index}
                  >
                    <ListItemIcon className={classes.ListItemIcon}>
                      {tab.icon}
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        className: classes.ListItemText,
                      }}
                      primary={tab.name}
                    />
                  </ListItem>
                );
              })}
          </List>
        </Box>

        <div className={classes.grow} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mb={1}
        >
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={helpFeedbackDialog}
            onClose={handleHelpFeedbackDialog}
          >
            <Box py={2}>
              <Typography variant="h6" component="div">
                <Box fontWeight={600} mb={2} px={2}>
                  Help & Feedback
                </Box>
              </Typography>
              {(HELPFEEDBACK_LIST || []).map(
                ({ primary, secondary, icon: Icon, ...other }, index) => {
                  return (
                    <Box my={0.5} key={index}>
                      <ListItem className={classes.ListItem} button {...other}>
                        <ListItemIcon className={classes.ListItemIcon}>
                          <Icon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{
                            className: classes.ListItemText,
                          }}
                          secondaryTypographyProps={{
                            className: classes.ListItemTextSecondary,
                          }}
                          primary={primary}
                          secondary={secondary}
                        />
                      </ListItem>
                    </Box>
                  );
                }
              )}
            </Box>
          </Dialog>

          <ListItem
            className={classes.ListItem}
            button
            component={Link}
            to={`/org/${orgId}/settings/invite`}
          >
            <ListItemIcon className={classes.ListItemIcon}>
              <IoAdd />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.ListItemText,
              }}
              primary="Invite members"
            />
          </ListItem>
          <ListItem
            className={classes.ListItem}
            button
            onClick={handleHelpFeedbackDialog}
          >
            <ListItemIcon className={classes.ListItemIcon}>
              <IoInformation />
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                className: classes.ListItemText,
              }}
              primary="Help & Feedback"
            />
          </ListItem>
        </Box>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(OrgSidebar);
