import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Can from "../../../shared/Can";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    marginRight: theme.spacing(3),
  },
  text: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export const ProjectSharedUsers = ({ project, user }) => {
  const classes = useStyles();
  return (
    <>
      <Box>
        <Box mb={[1, 2]}>
          <Typography variant="h6">
            <strong>Team Members</strong>
          </Typography>
        </Box>
        {project &&
          project.projectTeam &&
          project.projectTeam.length &&
          project.projectTeam.map((member, index) => {
            const userAccount = member.userAccount;
            const profile = member.profile;
            const education =
              profile &&
              profile.education &&
              profile.education.length > 0 &&
              profile.education[0];
            return (
              <Can
                key={index}
                role={(user && user.typeId) || 1}
                perform="project:view-profile"
                data={{
                  userId: user && user.id,
                  profileId: userAccount.id,
                  privacySetting: profile.privacySetting,
                }}
                yes={() => (
                  <Link
                    to={`/@${userAccount.userName}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      lineHeight={1.2}
                      mb={[2, 3]}
                      width="100%"
                      className={classes.text}
                    >
                      <Avatar
                        src={userAccount.avatar}
                        alt={userAccount.userName}
                        className={classes.avatar}
                      />

                      <div>
                        <Typography variant="body1">
                          <strong>
                            {profile && profile.firstName}{" "}
                            {profile && profile.lastName}
                          </strong>
                        </Typography>
                        {education && (
                          <Box fontSize="caption.fontSize">
                            <p>{education.degree}</p>
                            <p>
                              {education.school},{" "}
                              {new Date(education.completionDate).getFullYear()}
                            </p>

                            <p>{education.fieldOfStudy}</p>
                          </Box>
                        )}
                      </div>
                    </Box>
                  </Link>
                )}
                no={() => (
                  <Box display="flex" alignItems="center" mb={[1, 2]}>
                    <Avatar className={classes.avatar} />

                    <Typography variant="body1">
                      This user's profile is not public.
                    </Typography>
                  </Box>
                )}
              />
            );
          })}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSharedUsers);
