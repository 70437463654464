import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core";
import MuiLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import grey from "@material-ui/core/colors/grey";
import blueGrey from "@material-ui/core/colors/blueGrey";
// Icons
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
  icon: {
    backgroundColor: grey[100],
    color: blueGrey[700],
    fontSize: theme.typography.h1.fontSize,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const EmptyState = ({
  title,
  subtitle,
  link,
  icon: Icon = HourglassEmptyOutlinedIcon,
  minHeight = "564px",
}) => {
  const classes = useStyles();
  return (
    <Box
      minHeight={minHeight}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Container maxWidth="sm">
        <Icon className={classes.icon} />
        <Typography variant="h6" component="h2">
          <Box mt={2} fontWeight={600}>
            {title}
          </Box>
        </Typography>
        <Typography variant="body2" component="div" color="textSecondary">
          <Box mb={2}>{subtitle}</Box>
        </Typography>
        {link && (
          <Typography variant="body2" component="div" color="textSecondary">
            <Box fontWeight={600}>
              <MuiLink
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                Learn more <ArrowForwardIcon />
              </MuiLink>
            </Box>
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default EmptyState;
