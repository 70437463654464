import React from "react";
// React Query
import { useGetAllChallengesForUser } from "../../../hooks/challenge";

// Material UI
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockIcon from "@material-ui/icons/Lock";
import Divider from "@material-ui/core/Divider";
// Components
import Spinner from "../../../shared/Spinner";
import EmptyState from "../../../shared/EmptyState";
import ProgramCard from "./ProgramCard";

const DashboardPrograms = (program) => {
  const {
    isLoading,
    data: { data: data } = { data: null },
    isError,
    error,
    isFetching,
  } = useGetAllChallengesForUser();

  const useStyles = makeStyles((theme) => ({
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  }));

  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {/* Displaying Programs information */}
          <Box>
            <Typography variant="h5" component="h2">
              <Box fontWeight={700} pb={2}>
                🏆 Registered Programs
              </Box>
            </Typography>
            <Grid container spacing={2}>
              {data && data.length > 0 ? (
                data.map((upcomingProgram) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      width="100%"
                      key={upcomingProgram.id}
                    >
                      <ProgramCard program={upcomingProgram} />
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <EmptyState
                    title="No registered programs"
                    subtitle="You have not registered for any programs yet."
                    minHeight={300}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default DashboardPrograms;
