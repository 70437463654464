const Privacy = () => {
  return (
    <>
      <div className="relative overflow-hidden py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <article className="mt-6 prose-invert prose-primary prose-lg text-gray-100 max-w-prose mx-auto">
            <h1 align="center">PRIVACY POLICY</h1>

            <p>Effective date: 01/23/2021</p>

            <p>
              <strong>1. Introduction</strong>
            </p>

            <p>
              Welcome to <strong>Pralent, Inc</strong>
            </p>

            <p>
              Pralent, Inc (“us”, “we”, or “our”) operates
              https://www.pralent.com (hereinafter referred to as “
              <strong>Service</strong>”).
            </p>

            <p>
              Our Privacy Policy governs your visit to https://www.pralent.com,
              and explains how we collect, safeguard and disclose information
              that results from your use of our Service.{" "}
            </p>

            <p>
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy. Unless otherwise defined in this Privacy Policy,
              the terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions.
            </p>

            <p>
              Our Terms and Conditions (“<strong>Terms</strong>”) govern all use
              of our Service and together with the Privacy Policy constitutes
              your agreement with us (“<strong>agreement</strong>”).
            </p>

            <p>
              <strong>2. Definitions</strong>
            </p>

            <p>
              <strong>SERVICE </strong>means the https://www.pralent.com website
              operated by Pralent, Inc
            </p>

            <p>
              <strong>PERSONAL DATA</strong> means data about a living
              individual who can be identified from those data (or from those
              and other information either in our possession or likely to come
              into our possession).
            </p>

            <p>
              <strong>USAGE DATA</strong> is data collected automatically either
              generated by the use of Service or from Service infrastructure
              itself (for example, the duration of a page visit).
            </p>

            <p>
              <strong>COOKIES</strong> are small files stored on your device
              (computer or mobile device).
            </p>

            <p>
              <strong>DATA CONTROLLER</strong> means a natural or legal person
              who (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
            </p>

            <p>
              <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any
              natural or legal person who processes the data on behalf of the
              Data Controller. We may use the services of various Service
              Providers in order to process your data more effectively.
            </p>

            <p>
              <strong>DATA SUBJECT </strong>is any living individual who is the
              subject of Personal Data.
            </p>

            <p>
              <strong>THE USER </strong>is the individual using our Service. The
              User corresponds to the Data Subject, who is the subject of
              Personal Data.
            </p>

            <p>
              <strong>3. Information Collection and Use</strong>
            </p>

            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>

            <p>
              <strong>4. Types of Data Collected</strong>
            </p>

            <p>
              <strong>Personal Data</strong>
            </p>

            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“<strong>Personal Data</strong>”). Personally
              identifiable information may include, but is not limited to:
            </p>

            <p>(a) Email address</p>

            <p>(b) First name and last name</p>

            <p>(c) Phone number</p>

            <p>(d) Address, State, Province, ZIP/Postal code, City</p>

            <p>(e) Cookies and Usage Data</p>

            <p>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or by emailing at info@pralent.com.
            </p>

            <p>
              <strong>Usage Data</strong>
            </p>

            <p>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through a
              mobile device (“<strong>Usage Data</strong>”).
            </p>

            <p>
              This Usage Data may include information such as your computer's
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that you visit, the time and
              date of your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </p>

            <p>
              When you access Service with a mobile device, this Usage Data may
              include information such as the type of mobile device you use,
              your mobile device unique ID, the IP address of your mobile
              device, your mobile operating system, the type of mobile Internet
              browser you use, unique device identifiers and other diagnostic
              data.
            </p>

            <p>
              <strong>Location Data</strong>
            </p>

            <p>
              We may use and store information about your location if you give
              us permission to do so (“<strong>Location Data</strong>”). We use
              this data to provide features of our Service, to improve and
              customize our Service.
            </p>

            <p>
              You can enable or disable location services when you use our
              Service at any time by way of your device settings.
            </p>

            <p>
              <strong>Tracking Cookies Data</strong>
            </p>

            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </p>

            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
            </p>

            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>

            <p>Examples of Cookies we use:</p>

            <p>
              (a) <strong>Session Cookies:</strong> We use Session Cookies to
              operate our Service.
            </p>

            <p>
              (b) <strong>Preference Cookies:</strong> We use Preference Cookies
              to remember your preferences and various settings.
            </p>

            <p>
              (c) <strong>Security Cookies:</strong> We use Security Cookies for
              security purposes.
            </p>

            <p>
              (d) <strong>Advertising Cookies:</strong> Advertising Cookies are
              used to serve you with advertisements that may be relevant to you
              and your interests.
            </p>

            <p>
              <strong>Other Data</strong>
            </p>

            <p>
              While using our Service, we may also collect the following
              information: sex, age, date of birth, place of birth, passport
              details, citizenship, registration at place of residence and
              actual address, telephone number (work, mobile), details of
              documents on education, qualification, professional training,
              employment agreements, non-disclosure agreements, information on
              bonuses and compensation, information on marital status, family
              members, social security (or other taxpayer identification)
              number, office location and other data.{" "}
            </p>

            <p>
              <strong>5. Use of Data</strong>
            </p>

            <p>Pralent, Inc uses the collected data for various purposes:</p>

            <p>(a) to provide and maintain our Service;</p>

            <p>(b) to notify you about changes to our Service; </p>

            <p>
              (c) to allow you to participate in interactive features of our
              Service when you choose to do so;{" "}
            </p>

            <p>(d) to provide customer support; </p>

            <p>
              (e) to gather analysis or valuable information so that we can
              improve our Service;{" "}
            </p>

            <p>(f) to monitor the usage of our Service;</p>

            <p>(g) to detect, prevent and address technical issues;</p>

            <p>(h) to fulfill any other purpose for which you provide it;</p>

            <p>
              (i) to carry out our obligations and enforce our rights arising
              from any contracts entered into between you and us, including for
              billing and collection;
            </p>

            <p>
              (j) to provide you with notices about your account and/or
              subscription, including expiration and renewal notices,
              email-instructions, etc.;
            </p>

            <p>
              (k) to provide you with news, special offers and general
              information about other goods, services and events which we offer
              that are similar to those that you have already purchased or
              enquired about unless you have opted not to receive such
              information;
            </p>

            <p>
              (l) in any other way we may describe when you provide the
              information;
            </p>

            <p>(m) for any other purpose with your consent. </p>

            <p>
              <strong>6. Retention of Data</strong>
            </p>

            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>

            <p>
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </p>

            <p>
              <strong>7. Transfer of Data</strong>
            </p>

            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>

            <p>
              If you are located outside United States and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to United States and process it there.
            </p>

            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>

            <p>
              Pralent, Inc will take all the steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your Personal Data will
              take place to an organisation or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </p>

            <p>
              <strong>8. Disclosure of Data</strong>
            </p>

            <p>
              We may disclose personal information that we collect, or you
              provide:
            </p>

            <p>
              (a) <strong>Disclosure for Law Enforcement.</strong>
            </p>

            <p>
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </p>

            <p>
              (b) <strong>Business Transaction.</strong>
            </p>

            <p>
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.
            </p>

            <p>
              (c){" "}
              <strong>
                Other cases. We may disclose your information also:
              </strong>
            </p>

            <p>(i) to our subsidiaries and affiliates;</p>

            <p>
              (ii) to contractors, service providers, and other third parties we
              use to support our business;
            </p>

            <p>(iii) to fulfill the purpose for which you provide it;</p>

            <p>
              (iv) for the purpose of including your company’s logo on our
              website;
            </p>

            <p>
              (v) for any other purpose disclosed by us when you provide the
              information;
            </p>

            <p>(vi) with your consent in any other cases;</p>

            <p>
              (vii)if we believe disclosure is necessary or appropriate to
              protect the rights, property, or safety of the Company, our
              customers, or others.
            </p>

            <p>
              <strong>9. Security of Data</strong>
            </p>

            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>

            <p>
              <strong>
                10. Your Data Protection Rights Under General Data Protection
                Regulation (GDPR)
              </strong>
            </p>

            <p>
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR. – See more at{" "}
              <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">
                https://eur-lex.europa.eu/eli/reg/2016/679/oj
              </a>
            </p>

            <p>
              We aim to take reasonable steps to allow you to correct, amend,
              delete, or limit the use of your Personal Data.
            </p>

            <p>
              If you wish to be informed what Personal Data we hold about you
              and if you want it to be removed from our systems, please email us
              at info@pralent.com.{" "}
            </p>

            <p>
              In certain circumstances, you have the following data protection
              rights:
            </p>

            <p>
              (a) the right to access, update or to delete the information we
              have on you;
            </p>

            <p>
              (b) the right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete;
            </p>

            <p>
              (c) the right to object. You have the right to object to our
              processing of your Personal Data;
            </p>

            <p>
              (d) the right of restriction. You have the right to request that
              we restrict the processing of your personal information;
            </p>

            <p>
              (e) the right to data portability. You have the right to be
              provided with a copy of your Personal Data in a structured,
              machine-readable and commonly used format;
            </p>

            <p>
              (f) the right to withdraw consent. You also have the right to
              withdraw your consent at any time where we rely on your consent to
              process your personal information;
            </p>

            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not able to
              provide Service without some necessary data.
            </p>

            <p>
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>

            <p>
              <strong>
                11. Your Data Protection Rights under the California Privacy
                Protection Act (CalOPPA)
              </strong>
            </p>

            <p>
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivable the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy. – See more at:{" "}
              <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">
                https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
              </a>
            </p>

            <p>According to CalOPPA we agree to the following:</p>

            <p>(a) users can visit our site anonymously;</p>

            <p>
              (b) our Privacy Policy link includes the word “Privacy”, and can
              easily be found on the page specified above on the home page of
              our website;
            </p>

            <p>
              (c) users will be notified of any privacy policy changes on our
              Privacy Policy Page;
            </p>

            <p>
              (d) users are able to change their personal information by
              emailing us at info@pralent.com.{" "}
            </p>

            <p>Our Policy on “Do Not Track” Signals:</p>

            <p>
              We honor Do Not Track signals and do not track, plant cookies, or
              use advertising when a Do Not Track browser mechanism is in place.
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked.{" "}
            </p>

            <p>
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </p>

            <p>
              <strong>
                12. Your Data Protection Rights under the California Consumer
                Privacy Act (CCPA)
              </strong>
            </p>

            <p>
              If you are a California resident, you are entitled to learn what
              data we collect about you, ask to delete your data and not to sell
              (share) it. To exercise your data protection rights, you can make
              certain requests and ask us:
            </p>

            <p>
              (a) <strong>What personal information we have about you</strong>.
              If you make this request, we will return to you:
            </p>

            <p>
              (i) The categories of personal information we have collected about
              you.
            </p>

            <p>
              (ii) The categories of sources from which we collect your personal
              information.
            </p>

            <p>
              (iii) The business or commercial purpose for collecting or selling
              your personal information.
            </p>

            <p>
              (iv) The categories of third parties with whom we share personal
              information.
            </p>

            <p>
              (v) The specific pieces of personal information we have collected
              about you.
            </p>

            <p>
              (vi) A list of categories of personal information that we have
              sold, along with the category of any other company we sold it to.
              If we have not sold your personal information, we will inform you
              of that fact.
            </p>

            <p>
              (vii)A list of categories of personal information that we have
              disclosed for a business purpose, along with the category of any
              other company we shared it with.
            </p>

            <p>
              Please note, you are entitled to ask us to provide you with this
              information up to two times in a rolling twelve-month period. When
              you make this request, the information provided may be limited to
              the personal information we collected about you in the previous 12
              months.
            </p>

            <p>
              (b) <strong>To delete your personal information</strong>. If you
              make this request, we will delete the personal information we hold
              about you as of the date of your request from our records and
              direct any service providers to do the same. In some cases,
              deletion may be accomplished through de-identification of the
              information. If you choose to delete your personal information,
              you may not be able to use certain functions that require your
              personal information to operate.
            </p>

            <p>
              (c) <strong>To stop selling your personal information</strong>. If
              you submit a request to stop selling your personal information, we
              will stop selling it. If you are a California resident, to opt-out
              of the sale of your personal information, click “Do Not Sell My
              Personal Information” at the bottom of our home page to submit
              your request.
            </p>

            <p>
              Please note, if you ask us to delete or stop selling your data, it
              may impact your experience with us, and you may not be able to
              participate in certain programs or membership services which
              require the usage of your personal information to function. But in
              no circumstances, we will discriminate against you for exercising
              your rights.
            </p>

            <p>
              To exercise your California data protection rights described
              above, please send your request(s) by one of the following means:
            </p>

            <p>By email: info@pralent.com</p>

            <p>
              Your data protection rights, described above, are covered by the
              CCPA, short for the California Consumer Privacy Act. To find out
              more, visit the official California Legislative Information
              website. The CCPA took effect on 01/01/2020.{" "}
              <a href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375">
                http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375
              </a>
            </p>

            <p>
              <strong>13. Service Providers</strong>
            </p>

            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“<strong>Service Providers</strong>”), provide
              Service on our behalf, perform Service-related services or assist
              us in analysing how our Service is used.
            </p>

            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>

            {/* <p>
              <strong>14. Analytics</strong>
            </p>

            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p> */}

            {/* <p>Google Analytics</p> */}

            {/* <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network.
            </p>

            <p>
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page:
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </p>

            <p>
              We also encourage you to review the Google's policy for
              safeguarding your data:
              <a href="https://support.google.com/analytics/answer/6004245">
                https://support.google.com/analytics/answer/6004245
              </a>
              .
            </p> */}

            {/* <p>Mixpanel</p>

            <p>Mixpanel is provided by Mixpanel Inc.</p>

            <p>
              You can prevent Mixpanel from using your information for analytics
              purposes by opting-out. To opt-out of Mixpanel service, please
              visit this page:
              <a href="https://mixpanel.com/optout/">
                https://mixpanel.com/optout/
              </a>
            </p>

            <p>
              For more information on what type of information Mixpanel
              collects, please visit the Terms of Use page of Mixpanel:
              <a href="https://mixpanel.com/terms/">
                https://mixpanel.com/terms/
              </a>
            </p> */}

            {/* <p>
              <strong>FullStory</strong>
            </p>

            <p>FullStory is provided by FullStory, Inc.</p>

            <p>
              Thousands of companies use FullStory every day to see their
              websites through their users’ eyes. FullStory’s session replay and
              analytics capabilities help web teams troubleshoot bugs, support
              their customers, and build better experiences. To learn more about
              FullStory, visit our home page.
            </p>

            <p>
              You can prevent FullStory from using your information for
              analytics purposes by opting-out. To opt-out of FullStory service,
              please visit this page:{" "}
              <a href="https://www.fullstory.com/optout/">
                https://www.fullstory.com/optout/
              </a>
            </p>

            <p>
              For more information on what type of information FullStory
              collects, please visit the Terms and Conditions page of FullStory:{" "}
              <a href="https://www.fullstory.com/legal/terms-and-conditions">
                https://www.fullstory.com/legal/terms-and-conditions
              </a>
            </p> */}

            {/* <p>
              <strong>Smartlook</strong>
            </p>
            <p>
              Smartlook is a product analytics solution for websites & mobile
              apps with user behavior replay. Smartlook enables companies to
              take a closer look at behavior of users on your website or inside
              your mobile application for debugging and product improvement
              purposes.
            </p>

            <p>
              You can prevent Smartlook from using your information for
              analytics purposes by opting-out. To opt-out of Smartlook service,
              please visit this page:{" "}
              <a href="https://www.smartlook.com/opt-out/">
                https://www.smartlook.com/opt-out/
              </a>
            </p>

            <p>
              For more information on what type of information Smartlook
              collects, please visit the Terms of Service page of Smartlook:{" "}
              <a href="https://help.smartlook.com/en/articles/3244453-terms-of-service">
                https://help.smartlook.com/en/articles/3244453-terms-of-service
              </a>
            </p> */}

            <p>
              <strong>15. CI/CD tools</strong>
            </p>

            <p>
              We may use third-party Service Providers to automate the
              development process of our Service.{" "}
            </p>

            <p>
              <strong>GitHub</strong>
            </p>

            <p>GitHub is provided by GitHub, Inc.</p>

            <p>
              GitHub is a development platform to host and review code, manage
              projects, and build software.
            </p>

            <p>
              For more information on what data GitHub collects for what purpose
              and how the protection of the data is ensured, please visit GitHub
              Privacy Policy page:{" "}
              <a href="https://help.github.com/en/articles/github-privacy-statement">
                https://help.github.com/en/articles/github-privacy-statement.
              </a>
            </p>

            {/* <p>
              <strong>GitLab CI/CD</strong>
            </p>

            <p>GitLab CI/CD is provided by GitLab, Inc.</p>

            <p>
              GitLab CI (Continuous Integration) service is a part of GitLab
              that build and test the software whenever developer pushes code to
              application.{" "}
            </p>

            <p>
              GitLab CD (Continuous Deployment) is a software service that
              places the changes of every code in the production which results
              in every day deployment of production.{" "}
            </p>

            <p>
              For more information on what data GitLab CI/CD collects for what
              purpose and how the protection of the data is ensured, please
              visit GitLab CI/CD Privacy Policy page:
              <a href="https://about.gitlab.com/privacy/">
                https://about.gitlab.com/privacy/
              </a>
              .
            </p> */}

            {/* <p>
              <strong>CircleCI</strong>
            </p>

            <p>CircleCI is provided by Circle Internet Services, Inc. </p>

            <p>
              CircleCI is Continuous Integration, a development practice which
              is being used by software teams allowing them to build, test and
              deploy applications easier and quicker on multiple platforms.{" "}
            </p>

            <p>
              For more information on what data Circle CI collects for what
              purpose and how the protection of the data is ensured, please
              visit Circle CI Privacy Policy page:{" "}
              <a href="https://circleci.com/privacy/">
                https://circleci.com/privacy/.
              </a>
            </p> 

            <p>
              <strong>16. Advertising</strong>
            </p>

            <p>
              We may use third-party Service Providers to show advertisements to
              you to help support and maintain our Service.
            </p>

            <p>Google AdSense DoubleClick Cookie</p>

            <p>
              Google, as a third party vendor, uses cookies to serve ads on our
              Service. Google's use of the DoubleClick cookie enables it and its
              partners to serve ads to our users based on their visit to our
              Service or other websites on the Internet.
            </p>

            <p>
              You may opt out of the use of the DoubleClick Cookie for
              interest-based advertising by visiting the Google Ads Settings web
              page:
              <a href="http://www.google.com/ads/preferences/">
                http://www.google.com/ads/preferences/
              </a>
            </p>
*/}
            {/* <p>
              <strong>17. Behavioral Remarketing</strong>
            </p>

            <p>
              Pralent, Inc uses remarketing services to advertise on third party
              websites to you after you visited our Service. We and our
              third-party vendors use cookies to inform, optimise and serve ads
              based on your past visits to our Service.
            </p>

            <p>Google Ads (AdWords)</p>

            <p>
              Google Ads (AdWords) remarketing service is provided by Google
              Inc.
            </p>

            <p>
              You can opt-out of Google Analytics for Display Advertising and
              customize the Google Display Network ads by visiting the Google
              Ads Settings page:
              <a href="http://www.google.com/settings/ads">
                http://www.google.com/settings/ads
              </a>
            </p>

            <p>
              Google also recommends installing the Google Analytics Opt-out
              Browser Add-on – https://tools.google.com/dlpage/gaoptout – for
              your web browser. Google Analytics Opt-out Browser Add-on provides
              visitors with the ability to prevent their data from being
              collected and used by Google Analytics.
              <a href="https://tools.google.com/dlpage/gaoptout">
                https://tools.google.com/dlpage/gaoptout
              </a>
            </p>

            <p>
              For more information on the privacy practices of Google, please
              visit the Google Privacy Terms web page:
              <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
              </a>
            </p>

            <p>Bing Ads Remarketing</p>

            <p>Bing Ads remarketing service is provided by Microsoft Inc.</p>

            <p>
              You can opt-out of Bing Ads interest-based ads by following their
              instructions:
              <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
              </a>
            </p>

            <p>
              You can learn more about the privacy practices and policies of
              Microsoft by visiting their Privacy Policy page:
              <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
                https://privacy.microsoft.com/en-us/PrivacyStatement
              </a>
            </p>

            <p>Twitter</p>

            <p>Twitter remarketing service is provided by Twitter Inc.</p>

            <p>
              You can opt-out from Twitter's interest-based ads by following
              their instructions:
              <a href="https://support.twitter.com/articles/20170405">
                https://support.twitter.com/articles/20170405
              </a>
            </p>

            <p>
              You can learn more about the privacy practices and policies of
              Twitter by visiting their Privacy Policy page:
              <a href="https://twitter.com/privacy">
                https://twitter.com/privacy
              </a>
            </p>

            <p>Facebook</p>

            <p>Facebook remarketing service is provided by Facebook Inc.</p>

            <p>
              You can learn more about interest-based advertising from Facebook
              by visiting this page:
              <a href="https://www.facebook.com/help/164968693837950">
                https://www.facebook.com/help/164968693837950
              </a>
            </p>

            <p>
              To opt-out from Facebook's interest-based ads, follow these
              instructions from Facebook:
              <a href="https://www.facebook.com/help/568137493302217">
                https://www.facebook.com/help/568137493302217
              </a>
            </p>

            <p>
              Facebook adheres to the Self-Regulatory Principles for Online
              Behavioural Advertising established by the Digital Advertising
              Alliance. You can also opt-out from Facebook and other
              participating companies through the Digital Advertising Alliance
              in the USA http://www.aboutads.info/choices/, the Digital
              Advertising Alliance of Canada in Canada http://youradchoices.ca/
              or the European Interactive Digital Advertising Alliance in Europe
              http://www.youronlinechoices.eu/, or opt-out using your mobile
              device settings.
              <a href="http://www.aboutads.info/choices/">
                http://www.aboutads.info/choices/
              </a>
              <a href="http://youradchoices.ca/">http://youradchoices.ca/</a>
              <a href="http://www.youronlinechoices.eu/">
                http://www.youronlinechoices.eu/
              </a>
            </p>

            <p>
              For more information on the privacy practices of Facebook, please
              visit Facebook's Data Policy:
              <a href="https://www.facebook.com/privacy/explanation">
                https://www.facebook.com/privacy/explanation
              </a>
            </p>

            <p>Pinterest</p>

            <p>Pinterest remarketing service is provided by Pinterest Inc.</p>

            <p>
              You can opt-out from Pinterest's interest-based ads by enabling
              the “Do Not Track” functionality of your web browser or by
              following Pinterest instructions:
              <a href="http://help.pinterest.com/en/articles/personalization-and-data">
                http://help.pinterest.com/en/articles/personalization-and-data
              </a>
            </p>

            <p>
              You can learn more about the privacy practices and policies of
              Pinterest by visiting their Privacy Policy page:
              <a href="https://about.pinterest.com/en/privacy-policy">
                https://about.pinterest.com/en/privacy-policy
              </a>
            </p> */}

            <p>
              <strong>18. Payments</strong>
            </p>

            <p>
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing
              (e.g. payment processors).
            </p>

            <p>
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy. These payment processors adhere to the
              standards set by PCI-DSS as managed by the PCI Security Standards
              Council, which is a joint effort of brands like Visa, Mastercard,
              American Express and Discover. PCI-DSS requirements help ensure
              the secure handling of payment information.
            </p>

            <p>The payment processors we work with are:</p>

            <p>PayPal or Braintree:</p>

            <p>
              Their Privacy Policy can be viewed at
              <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
                https://www.paypal.com/webapps/mpp/ua/privacy-full
              </a>
            </p>

            <p>Stripe:</p>

            <p>
              Their Privacy Policy can be viewed at:
              <a href="https://stripe.com/us/privacy">
                https://stripe.com/us/privacy
              </a>
            </p>

            <p>
              <strong>19. Links to Other Sites</strong>
            </p>

            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>

            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>

            <p>
              <strong>20. Children's Privacy</strong>
            </p>

            <p>
              Our Services are not intended for use by children under the age of
              13 (“<strong>Children</strong>”).{" "}
            </p>

            <p>
              We do not knowingly collect personally identifiable information
              from Children under 13. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that ce have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
            </p>

            <p>
              <strong>21. Changes to This Privacy Policy</strong>
            </p>

            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>

            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
            </p>

            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <p>
              <strong>22. Contact Us</strong>
            </p>

            <p>
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>

            <p>By email: info@pralent.com.</p>
          </article>
        </div>
      </div>
    </>
  );
};

export default Privacy;
