import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { utils } from "@rjsf/core";

const { asNumber, guessType } = utils;

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema, value) => {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;
  if (value === "") {
    return undefined;
  } else if (type === "array" && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every((x) => guessType(x) === "number")) {
      return asNumber(value);
    } else if (schema.enum.every((x) => guessType(x) === "boolean")) {
      return value === "true";
    }
  }

  return value;
};

const SelectWidget = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors = [],
}) => {
  const { enumOptions, enumDisabled } = options;

  const emptyValue = multiple ? [] : "";

  value =
    multiple && !Array.isArray(value)
      ? (value && typeof value === "string" && [value]) || []
      : !multiple && Array.isArray(value)
      ? value && value.length > 0
        ? value[0]
        : ""
      : value;

  const _onChange = ({ target: { value } }) =>
    onChange(processValue(schema, value));
  const _onBlur = ({ target: { value } }) =>
    onBlur(id, processValue(schema, value));
  const _onFocus = ({ target: { value } }) =>
    onFocus(id, processValue(schema, value));

  return (
    <>
      <Box display={disabled ? "none" : "block"} maxWidth={500} width="100%">
        <TextField
          id={id}
          select
          value={typeof value === "undefined" ? emptyValue : value}
          required={required}
          disabled={disabled || readonly}
          autoFocus={autofocus}
          error={rawErrors.length > 0}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          label={null}
          style={{ width: "100%" }}
          SelectProps={{
            multiple: typeof multiple === "undefined" ? false : multiple,
          }}
        >
          {enumOptions.map(({ value, label }, i) => {
            const disabled = enumDisabled && enumDisabled.indexOf(value) != -1;
            return (
              <MenuItem key={i} value={value} disabled={disabled}>
                {label}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
      {disabled && (
        <Box>
          {multiple ? (
            <Box>
              {Array.isArray(value) &&
                value &&
                value.length > 0 &&
                value.map((option, index) => {
                  return (
                    <Box key={index}>
                      <Typography variant="body2" color="textPrimary">
                        - <em>{option}</em>
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          ) : (
            <Typography variant="body2" color="textPrimary">
              <em>{value}</em>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default SelectWidget;
