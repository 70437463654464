import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getChallenge,
  getCurrentUserStatus,
  clearChallenge,
  clearMyTeam,
  fetchTeamInformation,
} from "../../redux/actions/challenge";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
// Components
import ChallengeNotFound from "../../shared/ChallengeNotFound";
import ChallengeHeader from "./components/ChallengeHeader";
import LinkToHelp from "../../shared/LinkToHelp";
import Spinner from "../../shared/Spinner";
import ChallengeThemeProvider from "../../shared/ChallengeThemeProvider";
// Icons
import HomeIcon from "@material-ui/icons/Home";
// Tabs
import ChallengeDescriptions from "./components/Tabs/ChallengeDescriptions";
import ChallengeMyHome from "./components/Tabs/ChallengeMyHome";
import ChallengePrizes from "./components/Tabs/ChallengePrizes";
import ChallengeRegistrations from "./components/Tabs/ChallengeRegistrations";
import ChallengeSubmissions from "./components/Tabs/ChallengeSubmissions";
import ChallengeJudging from "./components/Tabs/ChallengeJudging";
import ChallengeTimeline from "./components/Tabs/ChallengeTimeline";

import { Helmet } from "react-helmet";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  isRegisteredTab: {},
  tabs: {
    width: "100%",
  },
}));

const Challenge = ({
  loading,
  challenge,
  currentUserStatus,
  user,
  getChallenge,
  getCurrentUserStatus,
  clearChallenge,
  clearMyTeam,
  fetchTeamInformation,
}) => {
  const classes = useStyles();

  const { slug } = useParams();

  const clearData = async () => {
    await clearChallenge();
  };

  const fetchData = async () => {
    await getChallenge(slug);
  };

  useEffect(() => {
    fetchData();

    return () => {
      clearData();
    };
  }, [slug]);

  const fetchChallengeTeamInformation = async () => {
    await fetchTeamInformation(challenge.id, user && user.id);
  };

  useEffect(() => {
    if (challenge && currentUserStatus && currentUserStatus.isRegistered) {
      fetchChallengeTeamInformation();
    }
    return () => {
      clearMyTeam();
    };
  }, [challenge, currentUserStatus]);

  useEffect(() => {
    if (challenge) {
      getCurrentUserStatus(challenge.id);
    }
  }, [challenge]);

  const [tabs, setTabs] = useState([
    {
      tabName: (
        <Box>
          <HomeIcon /> Home
        </Box>
      ),
      component: <ChallengeMyHome />,
      isRegistered: true,
    },
    {
      tabName: "About",
      component: <ChallengeDescriptions />,
    },
    {
      tabName: "Timeline",
      component: <ChallengeTimeline />,
    },
    {
      tabName: "Participants",
      component: <ChallengeRegistrations />,
    },
    {
      tabName: "Submissions",
      component: <ChallengeSubmissions />,
    },
    {
      tabName: "Reviewers & Criterias",
      component: <ChallengeJudging />,
    },
    {
      tabName: "Awards",
      component: <ChallengePrizes />,
    },
  ]);

  useEffect(() => {
    let newTabs = [];
    // Home for users registered for program
    newTabs.push({
      tabName: (
        <Box>
          <HomeIcon /> Home
        </Box>
      ),
      component: <ChallengeMyHome />,
      isRegistered: true,
    });

    // Information section
    newTabs.push({
      tabName: "About",
      component: <ChallengeDescriptions />,
    });

    // Timeline
    newTabs.push({
      tabName: "Timeline",
      component: <ChallengeTimeline />,
    });

    // Registrations
    if (challenge && challenge.isRegistrationPublic) {
      newTabs.push({
        tabName: "Participants",
        component: <ChallengeRegistrations />,
      });
    }

    // Submissions
    let showSubmissions = false;
    challenge &&
      challenge.round &&
      challenge.round.length > 0 &&
      challenge.round.map((round) => {
        if (round.isSubmissionPublic) {
          showSubmissions = true;
        }
      });

    if (showSubmissions) {
      newTabs.push({
        tabName: "Submissions",
        component: <ChallengeSubmissions />,
      });
    }

    // Reviewing
    let showJudges = false;
    challenge &&
      challenge.round &&
      challenge.round.length > 0 &&
      challenge.round.map((round) => {
        if (round.isJudgesPublic || round.isCriteriaPublic) {
          showJudges = true;
        }
      });
    if (showJudges) {
      newTabs.push({
        tabName: "Reviewers & Criterias",
        component: <ChallengeJudging />,
      });
    }

    // Awards
    if (challenge && challenge.isPrizePublic) {
      newTabs.push({
        tabName: "Awards",
        component: <ChallengePrizes />,
      });
    }

    setTabs(newTabs);
  }, [challenge, currentUserStatus]);

  const [tabValue, setTabValue] = useState(1);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  useEffect(() => {
    if (tabValue === 0) {
      if (!currentUserStatus || !currentUserStatus.isRegistered) {
        setTabValue(1);
      }
    }
    if (currentUserStatus && currentUserStatus.isRegistered) {
      setTabValue(0);
    }
  }, [challenge, currentUserStatus]);

  return (
    <ChallengeThemeProvider challenge={challenge}>
      <Box width="100%" height="100%" p={{ xs: 2, md: 4 }} overflow="auto">
        {challenge && (
          <Helmet>
            <title>{challenge.title} | Pralent Program</title>
            <meta name="description" content={challenge.tagLine} />
          </Helmet>
        )}
        {loading || !challenge ? (
          <Box>
            <Spinner />
          </Box>
        ) : challenge && challenge.id ? (
          <Box pb={8}>
            <ChallengeHeader />
            <Box py={4} borderRadius="borderRadius">
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                flexGrow={1}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  orientation="horizontal"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="Program details tabs"
                  className={classes.tabs}
                >
                  {tabs &&
                    tabs.map((tab, index) => {
                      return (
                        <Tab
                          key={index}
                          label={
                            <Typography variant="body1" component="div">
                              <Box
                                fontWeight={600}
                                style={{ textTransform: "uppercase" }}
                                px={1}
                              >
                                {tab.tabName}
                              </Box>
                            </Typography>
                          }
                          {...a11yProps(index)}
                          className={
                            tab.isRegistered && classes.isRegisteredTab
                          }
                          style={{
                            display:
                              tab.isRegistered &&
                              currentUserStatus &&
                              !currentUserStatus.isRegistered
                                ? "none"
                                : "block",
                          }}
                        />
                      );
                    })}
                </Tabs>
              </Box>
              <Box mt={[2, 4]}>
                {tabs && tabs[tabValue] && tabs[tabValue].component}
              </Box>
            </Box>
            <Box pb={2} display="flex" alignItems="center">
              <LinkToHelp
                link="https://www.notion.so/Programs-7d67d087471c41f3a1407cd865db098d#af0d445ed34740a1b902e4970f2eb1a1"
                label="How to sign up?"
              />
              <LinkToHelp
                link="https://www.notion.so/Programs-7d67d087471c41f3a1407cd865db098d#034185b1945845abb0da770abc1357d0"
                label="How to submit?"
              />
              <LinkToHelp
                link="https://www.notion.so/Programs-7d67d087471c41f3a1407cd865db098d#5153caebcce94043bc695217ee41b14b"
                label="How to view submission?"
              />
              <LinkToHelp
                link="https://www.notion.so/Programs-7d67d087471c41f3a1407cd865db098d#421ca56f714347a28d43a573d6d5b266"
                label="How to view feedback and scores?"
              />
            </Box>
          </Box>
        ) : (
          <ChallengeNotFound />
        )}
      </Box>
    </ChallengeThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  challenge: state.challenge.challenge,
  currentUserStatus: state.challenge.currentUserStatus,
  loading: state.challenge.loading,
});

const mapDispatchToProps = {
  getChallenge,
  getCurrentUserStatus,
  clearChallenge,
  clearMyTeam,
  fetchTeamInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenge);
