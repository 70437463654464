import React from "react";
import ReactImageFallback from "react-image-fallback";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    objectFit: "cover",
    maxHeight: 400,
    width: "auto",
  },
}));

const ChallengeHeaderImagePath = ({ challenge }) => {
  const classes = useStyles();
  return (
    <Box>
      <ReactImageFallback
        src={challenge.headerImagePath}
        fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
        alt={`${challenge.title}-header-image`}
        className={classes.root}
      />
    </Box>
  );
};

export default ChallengeHeaderImagePath;
