import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// Components
import AddEditEvent from "../../shared/AddEditEvent";

const OrgEventCreate = () => {
  return (
    <Box px={{ xs: 2, md: 4, lg: 6, xl: 8 }}>
      <Container maxWidth="md">
        <AddEditEvent />
      </Container>
    </Box>
  );
};

export default OrgEventCreate;
