import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// Material Ui
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Can from "../Can";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    marginRight: theme.spacing(3),
  },
}));

export const SubmissionTeam = ({ challenge, submission }) => {
  const classes = useStyles();
  const members = submission && submission.team && submission.team.members;
  const team = submission && submission.team;

  return (
    <Fragment>
      <Box width="100%">
        {challenge &&
        challenge.maxPeopleTeam &&
        challenge.maxPeopleTeam === 1 ? (
          <Box>
            <Typography variant="caption" color="textSecondary">
              APPLICANT
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="caption" color="textSecondary">
              TEAM
            </Typography>
            <Typography variant="h5" component="div">
              <Box mb={1} fontWeight={600}>
                {team.name}
              </Box>
            </Typography>
          </Box>
        )}

        {members.length &&
          members.map((member, index) => {
            const userAccount = member.user;
            const profile = member.profile;
            const education =
              profile &&
              profile.education &&
              profile.education.length > 0 &&
              profile.education[0];
            return (
              <Can
                key={index}
                role={(userAccount && userAccount.typeId) || 1}
                perform="project:view-profile"
                data={{
                  userId: userAccount && userAccount.id,
                  profileId: profile && profile.id,
                  privacySetting: profile && profile.privacySetting,
                }}
                yes={() => (
                  <Link to={`/@${userAccount.userName}`}>
                    <Box
                      display="flex"
                      alignItems="center"
                      mb={{ xs: 2, md: 4 }}
                    >
                      <Avatar
                        src={userAccount.avatar}
                        alt={
                          userAccount &&
                          userAccount.userName &&
                          typeof userAccount.userName === "string" &&
                          userAccount.userName.charAt(0).toUpperCase() +
                            userAccount.userName.slice(1)
                        }
                        className={classes.avatar}
                      />

                      <Box>
                        <Typography variant="h6" component="div">
                          <Box fontWeight={600} lineHeight={1.2}>
                            {profile && profile.firstName}{" "}
                            {profile && profile.lastName}
                          </Box>
                        </Typography>
                        {education && (
                          <Box>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="div"
                            >
                              <Box lineHeight={1.2}>{education.degree}</Box>
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="div"
                            >
                              <Box lineHeight={1.2}>
                                {education.school},{" "}
                                {new Date(
                                  education.completionDate
                                ).getFullYear()}
                              </Box>
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="div"
                            >
                              <Box lineHeight={1.2}>
                                {education.fieldOfStudy}
                              </Box>
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Link>
                )}
                no={() => (
                  <Box display="flex" alignItems="center" mb={{ xs: 2, md: 4 }}>
                    <Avatar className={classes.avatar} />

                    <Typography variant="body2">
                      This user's profile is not public.
                    </Typography>
                  </Box>
                )}
              />
            );
          })}
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionTeam);
