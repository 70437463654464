import React from "react";
import { Link as RouterLink } from "react-router-dom";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Tooltip from "@material-ui/core/Tooltip";
// Components
import Button from "../../shared/Button";
// Icons
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import AppsOutlinedIcon from "@material-ui/icons/AppsOutlined";
// Layouts
import UserDashboardLayout from "../UserDashboard";

const LINKS = [
  {
    icon: <DescriptionOutlinedIcon />,
    name: "Programs",
    to: "/programs",
  },
  {
    icon: <PeopleOutlineOutlinedIcon />,
    name: "Profiles",
    to: "/profiles",
    disabled: true,
    comingSoon: true,
  },
  {
    icon: <AppsOutlinedIcon />,
    name: "Projects",
    to: "/projects",
    disabled: true,
    comingSoon: true,
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  AppBar: {
    boxShadow: theme.shadows[2],
  },
}));

const DiscoverLayout = (props) => {
  const classes = useStyles();

  return (
    <UserDashboardLayout>
      <AppBar position="sticky" className={classes.AppBar}>
        <Container maxWidth="md">
          <Toolbar disableGutters variant="dense">
            {LINKS.map((l, i) => {
              return (
                <Tooltip
                  key={i}
                  title={
                    l.comingSoon ? "Coming Soon" : `View the ${l.name} page`
                  }
                >
                  <Box>
                    <Button
                      color="default"
                      variant="text"
                      className={classes.menuButton}
                      startIcon={l.icon}
                      component={RouterLink}
                      to={l.to}
                      disabled={l.disabled}
                    >
                      {l.name}
                    </Button>
                  </Box>
                </Tooltip>
              );
            })}
          </Toolbar>
        </Container>
      </AppBar>
      <Box>{props.children}</Box>
    </UserDashboardLayout>
  );
};

export default DiscoverLayout;
