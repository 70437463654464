import React from "react";

import { Link } from "react-router-dom";

import programpage from "../../../assets/screenshots/program-pages/program-page.png";

const Hero = () => {
  return (
    <div className="bg-gradient-to-br from-orange-100 via-white to-primary-100  pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-20">
              <div>
                <span className="rounded bg-orange-100 px-2.5 py-1 text-xs font-semibold text-orange-600 tracking-wide uppercase">
                  Programs
                </span>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  🚀 A better way to manage your challenges and programs
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Create your own community of founders, students, and tech
                  enthusiasts to exchange ideas, participate in programs, and
                  access new opportunities.
                </p>
                <div className="mt-4 md:mt-8">
                  <Link
                    to="/signup?redirect=/org/new"
                    className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-500 bg-opacity-90 hover:bg-opacity-80 sm:px-8"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img
                className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src={programpage}
                alt="Community program page"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
