import {
  ADD_NEW_FIELD,
  DELETE_FIELD,
  CHANGE_FIELD,
  UPDATE_FIELD,
  INSERT_FIELD,
  RESET_FIELD,
  UPDATE_FORM_TITLE,
  UPDATE_FORM_DESCRIPTION,
  SAVE_CUSTOM_FORM,
  FETCH_CUSTOM_FORM,
  CLEAR_CUSTOM_FORM,
} from "../actions/actionTypes";

// const initialState = {
//   error: null,
//   schema: {
//     type: "object",
//     title: "Untitled form",
//     description: "Enter some description for your form here",
//     properties: {},
//   },
//   uiSchema: {
//     "ui:order": [],
//   },
//   formData: {},
//   currentIndex: 0,
// };

const initialState = {
  customForm: {
    loading: false,
    error: null,
    data: {
      id: null,
      uiSchema: null,
      jsonSchema: null,
      formTypeId: null,
    },
  },
};

// function clone(obj) {
//   return JSON.parse(JSON.stringify(obj));
// }

// function createSlug(currentIndex) {
//   const slug = `Question_${currentIndex}`;
//   return slug;
// }

// function addNewField(state = initialState, field) {
//   // Generate a unique index for the field
//   state.currentIndex += 1;
//   const slug = createSlug(state.currentIndex);
//   state.schema.properties[slug] = { ...field.jsonSchema, title: slug };
//   state.uiSchema[slug] = field.uiSchema;
//   state.uiSchema["ui:order"] = (state.uiSchema["ui:order"] || []).concat(slug);
//   //console.log("Inside addNewField");
//   //console.log(state, field);
//   return state;
// }

// function changeField(state, field) {
//   //console.log("Inside reducer->changeField");
//   const slug = createSlug(state.currentIndex);
//   //console.log(slug, field);
//   state.schema.properties[slug] = { ...field.jsonSchema };
//   state.uiSchema[slug] = field.uiSchema;
//   //console.log(state, field);
//   return { ...state, error: null };
// }

// function updateField(state, field) {
//   console.log("Inside reducer->updateField");
//   console.log(state, field);
// }

// function deleteField(state, field) {
//   console.log("Inside deleteField");
//   const requiredFields = state.schema.required || [];
//   const slug = createSlug(state.currentIndex);
//   console.log(slug);
//   console.log(state.schema.properties[slug]);
//   console.log(state.uiSchema[slug]);
//   delete state.schema.properties[slug];
//   delete state.uiSchema[slug];
//   state.uiSchema["ui:order"] = state.uiSchema["ui:order"].filter(
//     (field) => field !== slug
//   );
//   state.schema.required = requiredFields.filter(
//     (requiredFieldName) => slug !== requiredFieldName
//   );
//   if (state.schema.required.length === 0) {
//     delete state.schema.required;
//   }
//   return { ...state, error: null };
// }

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // case ADD_NEW_FIELD: {
    //   //console.log(state, type, payload);
    //   return addNewField(clone(state), payload);
    // }
    // case CHANGE_FIELD: {
    //   return changeField(clone(state), payload.formField);
    // }
    // case UPDATE_FIELD: {
    //   console.log(state, type, payload);
    //   return updateField(clone(state), payload);
    // }
    // case DELETE_FIELD: {
    //   return deleteField(clone(state), payload);
    // }
    case FETCH_CUSTOM_FORM:
    case SAVE_CUSTOM_FORM: {
      return {
        ...state,
        customForm: {
          ...state.customForm,
          loading: false,
          error: null,
          data: {
            id: payload.id,
            formTypeId: payload.formTypeId,
            uiSchema: payload.uiSchema,
            jsonSchema: payload.jsonSchema,
          },
        },
      };
    }
    case CLEAR_CUSTOM_FORM: {
      return {
        ...state,
        customForm: {
          ...state.customForm,
          loading: false,
          error: null,
          data: {
            id: null,
            formTypeId: null,
            uiSchema: null,
            jsonSchema: null,
          },
        },
      };
    }
    // case CLEAR_CUSTOM_FORM:
    //   return {
    //     ...state,
    //     error: null,
    //     schema: {
    //       type: "object",
    //       title: "Untitled form",
    //       description: "Enter some description for your form here",
    //       properties: {},
    //     },
    //     uiSchema: {
    //       "ui:order": [],
    //     },
    //     formData: {},
    //     currentIndex: 0,
    //   };
    default:
      return state;
  }
};
