import React, { useState } from "react";
// React-Router
import { Link as RouterLink } from "react-router-dom";
// React query
import { useMutation, useQueryClient } from "react-query";
import { deleteJobPost } from "../../../hooks/job";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// Components
import PostJob from "../../../shared/PostJob";
import Card from "../../../shared/Card";
import CandidateDialog from "./CandidateDialog";
// Material UI
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
// Icons
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
// Utils
import format from "date-fns/format";

export const MyJobsCard = ({
  job,
  companyProfile,
  jobCategoryType,
  setAlert,
}) => {
  const queryClient = useQueryClient();

  const deleteJobPostMutation = useMutation(deleteJobPost, {
    onSuccess: async (data) => {
      const deletedJobId = data.data;
      let myCompanyJobs = await queryClient.getQueryData([
        "myCompanyJobs",
        { companyId: companyProfile.id },
      ]);

      let myCompanyJobsData =
        myCompanyJobs &&
        myCompanyJobs.data.filter((oldJob) => oldJob.id !== deletedJobId);

      await queryClient.setQueryData(
        ["myCompanyJobs", { companyId: companyProfile.id }],
        {
          ...myCompanyJobs,
          data: myCompanyJobsData,
        }
      );

      await setAlert("Sucessfully deleted the job post.", "success");
    },
    onError: async (error) => {
      await setAlert(
        (error && error.data && error.data.msg) ||
          "There was an error with deleting the job post. Please try again.",
        "error"
      );
    },
  });
  const handleDeleteJob = async (jobPostId) => {
    if (
      window.confirm(
        "Are you sure you would like to delete this job post from your organization? There is no reversing this action."
      )
    ) {
      deleteJobPostMutation.mutate({ jobPostId });
    }
  };

  const [openCandidateDialog, setOpenCandidateDialog] = useState(false);

  const handleOpenCandidateDialog = () => {
    setOpenCandidateDialog(true);
  };

  return (
    <>
      <CandidateDialog
        job={job}
        open={openCandidateDialog}
        setOpen={setOpenCandidateDialog}
      />
      <Card
        key={job.id}
        borderRadius="borderRadius"
        bgcolor={
          job.isActive ? "background.paper" : "action.disabledBackground"
        }
        p={2}
        mb={1}
        display="flex"
        alignItems="center"
        width="100%"
      >
        <Box>
          <PostJob job={job} />
        </Box>
        <Box flexGrow={1}>
          <Typography variant="body1" component="h3">
            <Box fontWeight={600}>{job.title}</Box>
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography component="div" variant="caption" color="textSecondary">
              <Box mr={1}>
                {jobCategoryType &&
                  jobCategoryType.find((elem) => elem.id === job.typeId) &&
                  jobCategoryType.find((elem) => elem.id === job.typeId)
                    .jobType}
              </Box>
            </Typography>
            {job.location && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                <Box mr={1}>{job.location}</Box>
              </Typography>
            )}
            {job.acceptApplicationsThroughPralent && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                <Box mr={1}>Pralent 1-Click</Box>
              </Typography>
            )}
            {job.postedDate && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                <Box mr={1}>
                  Posted on {format(new Date(job.postedDate), "MMM dd, yyyy")}
                </Box>
              </Typography>
            )}
            {job.closeDateTime && (
              <Typography
                component="div"
                variant="caption"
                color="textSecondary"
              >
                <Box mr={1}>
                  Closes {format(new Date(job.closeDateTime), "MMM dd, yyyy")}
                </Box>
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
          <Tooltip
            title={
              job.jobPostActivity && job.jobPostActivity.length > 0
                ? "Click here to view the candidates for this job"
                : "No applications yet for this job"
            }
          >
            <Chip
              clickable
              onClick={handleOpenCandidateDialog}
              label={
                <Box style={{ cursor: "pointer" }}>
                  <span style={{ fontWeight: 600 }}>
                    {(job.jobPostActivity && job.jobPostActivity.length) || 0}
                  </span>{" "}
                  Candidate(s)
                </Box>
              }
              variant={
                job.jobPostActivity && job.jobPostActivity.length > 0
                  ? "default"
                  : "outlined"
              }
              color="primary"
            />
          </Tooltip>
          {job.isActive ? (
            <Chip label="Active" color="primary" />
          ) : (
            <Chip label="Inactive" color="default" />
          )}
          <Tooltip title="Open job post link">
            <IconButton
              component={RouterLink}
              to={`/c/${companyProfile.communitySlug}/jobs/${job.id}`}
            >
              <OpenInNewOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Permanently delete job post from your organization">
            <IconButton onClick={() => handleDeleteJob(job.id)}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  jobCategoryType: state.typedef.jobCategoryType,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(MyJobsCard);
