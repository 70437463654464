import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  ACCOUNT_DELETED,
  UPDATE_USER,
  UPLOAD_AVATAR,
  SET_TOKEN,
  GET_GOOGLE_CLIENT_ID,
  SET_REDIRECT_URL,
  SET_DEFAULT_REDIRECT,
  USER_NOT_LOADED,
} from "../actions/actionTypes";
import Cookies from "js-cookie";

const initialState = {
  token: Cookies.get("token"),
  isAuthenticated: null,
  loading: true,
  user: null,
  redirectUrl: "/me",
  googleClientId: null,
  versionNumber: "",
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_DEFAULT_REDIRECT:
      return {
        ...state,
        redirectUrl: "/me",
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: payload,
      };
    case USER_LOADED:
    case UPDATE_USER:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case USER_NOT_LOADED: {
      return {
        ...state,
        token: Cookies.get("token"),
        isAuthenticated: null,
        loading: false,
        user: null,
        redirectUrl: "/me",
      };
    }
    case SET_TOKEN:
      Cookies.set("token", payload.token, {
        expires: 30,
      });
      return {
        ...state,
        ...payload,
      };
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
      };
    case GET_GOOGLE_CLIENT_ID:
      return {
        ...state,
        googleClientId: payload,
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
      Cookies.remove("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        redirectUrl: "/me",
      };
    case UPLOAD_AVATAR: {
      return {
        ...state,
        user: {
          ...state.user,
          avatar: payload.filePath,
        },
      };
    }
    default:
      return state;
  }
}
