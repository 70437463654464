import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Components
import AccountForm from "./components/AccountForm";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

const Settings = ({ auth: { user } }) => {
  return user === null ? (
    <Box>No user logged in. Please login to access settings.</Box>
  ) : (
    <>
      <Box py={2} flexGrow={1} height="100%" maxHeight="100vh" overflow="auto">
        <Container maxWidth="sm">
          <AccountForm />
        </Container>
      </Box>
    </>
  );
};

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
});

export default connect(mapStateToProps)(Settings);
