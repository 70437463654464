import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
// hooks
import { useFetchChallengeDemographics } from "../../../hooks/insights";

// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
// Components
import Chart from "../../../shared/Chart";
// Icons
import { IoAnalytics } from "react-icons/io5";

const ChallengeInsightsDemographics = ({ challenge }) => {
  const {
    isLoading,
    data: { data: data } = { data: null },
    isError,
    error,
  } = useFetchChallengeDemographics(challenge && challenge.id);

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        {isLoading ? (
          <Box height="100%" width="100%" my={{ xs: 4, lg: 8 }}>
            <Container maxWidth="xs">
              <Box textAlign="center" mb={2}>
                <Box
                  p={2}
                  mb={1}
                  borderRadius="borderRadius"
                  bgcolor="background.paper"
                  display="inline-block"
                  fontSize="h4.fontSize"
                >
                  <IoAnalytics />
                </Box>
                <Typography variant="body1" component="div">
                  We are crunching the numbers for your registrants.
                </Typography>
              </Box>
              <LinearProgress />
            </Container>
          </Box>
        ) : isError ? (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        ) : (
          <>
            {data ? (
              <>
                <Grid item xs={12}>
                  <Chart
                    title="Overview"
                    subtitle="See some of the most common attributes for your program"
                    data={[
                      { ...data?.school[0], subText: "School" },
                      {
                        ...data?.educationLevel[0],
                        subText: "Education Level",
                      },
                      {
                        ...data?.fieldOfStudy[0],
                        subText: "Field of Study",
                      },
                      { ...data?.allSkills[0], subText: "Skill" },
                      { ...data?.isActivelySeekingJob, subText: "Job" },
                    ]}
                    isProportion={true}
                    type="number-title"
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  md={6}
                  // Gender
                >
                  <Chart
                    data={data.gender}
                    title="Gender"
                    subtitle="See the gender breakdown for your program"
                    type="linear-progress"
                  />
                </Grid>

                <Grid
                  item
                  sm={12}
                  md={6}
                  // Race
                >
                  <Chart
                    data={data?.race?.slice(0, 5)}
                    title="Race"
                    subtitle="See the race breakdown for your program"
                    type="linear-progress"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <Chart
                    data={data?.educationLevel?.slice(0, 5)}
                    title="Education Level"
                    subtitle="See the education level breakdown of your progam"
                    type="bullet"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <Chart
                    data={data?.fieldOfStudy?.slice(0, 5)}
                    title="Field of Study"
                    subtitle="See the field of study breakdown of your progam"
                    type="bullet"
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <Chart
                    data={data?.allSkills?.slice(0, 5)}
                    title="Skill"
                    subtitle="See the most common skills of your progam"
                    type="bullet"
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <Chart
                    data={data?.school?.slice(0, 5)}
                    title="School"
                    type="bar"
                    subtitle="Top 5 most common schools for your program"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Chart
                    data={data?.educationCompletionYear?.slice(0, 5)}
                    title="Education Completion Year"
                    type="bar"
                    subtitle="Top 5 most common completion years for your program"
                  />
                </Grid>
                <Grid item sm={12}>
                  <Chart
                    title="Interests"
                    subtitle="These are the most common answers to interest questions for participants in your program."
                    data={data?.interest}
                    type="interest"
                  />
                </Grid>
              </>
            ) : (
              <Box textAlign="center" width="100%" py={{ xs: 2, md: 4 }}>
                <Typography variant="body1" component="div">
                  <Box fontWeight={400}>
                    Your program has no registrations at the moment, please
                    comeback when more people register.
                  </Box>
                </Typography>
              </Box>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeInsightsDemographics);
