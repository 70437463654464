import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "../redux/store/store";
import Routes from "./components/Routes";
// Theme & Components
import ThemeProvider from "./components/ThemeProvider";
// Components (Specific to App)
import ScrollToTop from "./components/ScrollToTop";
import CookieConsent from "./components/CookieConsent";
import Alert from "./components/Alert";
import AlertModal from "./components/AlertModal";

import { clearAllAlerts } from "../redux/actions/alert";
import { loadUser } from "../redux/actions/auth";
import { loadTypeDef } from "../redux/actions/typedef";

import { Helmet } from "react-helmet";

import "../styles/app.css";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 1000 * 60 * 60 * 1,
    },
  },
});

const App = () => {
  const onAppLoad = async () => {
    await store.dispatch(clearAllAlerts());
    await store.dispatch(loadTypeDef());
    await store.dispatch(loadUser());
  };
  useEffect(() => {
    onAppLoad();
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Pralent: The Professional Community Platform.</title>
          <meta
            name="description"
            content="All-in-one platform for professional communities."
          />
        </Helmet>

        <Provider store={store}>
          <ThemeProvider>
            <Alert />
            <AlertModal />
            <CookieConsent />
            <Routes>
              <ScrollToTop />
            </Routes>
          </ThemeProvider>
        </Provider>
      </div>
    </QueryClientProvider>
  );
};

export default App;
