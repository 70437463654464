import { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";

const ChallengeRulesCodeConduct = () => {
  const [rules, setRules] = useState("");

  useEffect(() => {
    async function fetchData() {
      fetch(
        "https://raw.githubusercontent.com/Pralent/pralent-challenge-rules-code-of-conduct/master/README.md"
      )
        .then((res) => {
          const text = res.text();
          return text;
        })
        .then((res) => setRules(res))
        .catch((res) => console.log(res));
    }
    fetchData();
  }, []);

  return (
    <div className="relative bg-white overflow-hidden py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto">
          <Markdown className="mt-6 prose prose-primary prose-lg text-gray-500 max-w-prose mx-auto">
            {rules}
          </Markdown>
        </div>
      </div>
    </div>
  );
};

export default ChallengeRulesCodeConduct;
