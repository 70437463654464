import React from "react";

import { makeStyles } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  InputLabel: {
    marginBottom: theme.spacing(0.5),
  },
}));

const CustomFieldTemplate = (props) => {
  const classes = useStyles();
  const {
    id,
    classNames,
    label,
    help,
    required,
    rawDescription,
    errors,
    children,
  } = props;
  return (
    <>
      {id !== "root" && (
        <FormGroup className={classNames}>
          <Box mb={2}>
            <InputLabel
              htmlFor={id}
              required={required}
              className={classes.InputLabel}
            >
              {label}
            </InputLabel>
            {rawDescription && (
              <Typography color="textSecondary" variant="caption">
                <Box lineHeight="1.2">{rawDescription}</Box>
              </Typography>
            )}
          </Box>
        </FormGroup>
      )}
      {children}
      {errors}
      {help}
    </>
  );
};

export default CustomFieldTemplate;
