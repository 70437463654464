import React from "react";
import { Link, useLocation } from "react-router-dom";
// react-query
// Material UI
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
// Utils
import { serializeToString } from "../../../utils/serializeToString";
import { formatDistanceToNow } from "date-fns";

const useStyles = makeStyles((theme) => ({
  lastUpdateByAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
  },
  articleCard: {
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    transition: "ease-in-out 0.2s",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CommunityArticleCard = ({ article }) => {
  const classes = useStyles();

  const location = useLocation();

  const contentString = article.content && serializeToString(article.content);
  const lastUpdateByUser = article.lastUpdateByUser;

  return (
    <Card
      component={Link}
      to={`${location.pathname}/${article.id}`}
      square={false}
      className={classes.articleCard}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          color="textPrimary"
        >
          <Box lineHeight={1} fontWeight={600}>
            {article.title}
          </Box>
        </Typography>

        {article.lastUpdateDateTime && lastUpdateByUser && (
          <>
            <Box my={1} display="flex" alignItems="center">
              <CustomAvatar
                src={lastUpdateByUser.avatar}
                alt={lastUpdateByUser.userName}
                fallbackText={lastUpdateByUser.userName}
                className={classes.lastUpdateByAvatar}
              />
              <Typography variant="body2" component="div" color="textSecondary">
                <Box lineHeight={1.2}>
                  Last updated by{" "}
                  {article.lastUpdateByProfile
                    ? `${article.lastUpdateByProfile.firstName}`
                    : lastUpdateByUser.userName}{" "}
                  {formatDistanceToNow(new Date(article.lastUpdateDateTime))}{" "}
                  ago
                </Box>
              </Typography>
            </Box>
          </>
        )}
        {contentString && (
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>
              {contentString.substring(0, 200)}
              {contentString.length >= 200 && "..."}
            </Box>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default CommunityArticleCard;
