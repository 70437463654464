import clsx from "clsx";

// Material ui
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import { withStyles } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

// date-fns
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import React, { PureComponent } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import DateFnsUtils from "@date-io/date-fns";
import differenceInDays from "date-fns/differenceInDays";

const DateRangePicker = ({ dateRange, setDateRange }) => {
  const handleChangeStartDate = (date) => {
    setDateRange({
      ...dateRange,
      startDate: date,
    });
  };
  const handleChangeEndDate = (date) => {
    setDateRange({
      ...dateRange,
      endDate: date,
    });
  };

  const { startDate, endDate } = dateRange;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            label="Select Start Date"
            value={startDate}
            onChange={handleChangeStartDate}
            KeyboardButtonProps={{
              "aria-label": "change start date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            label="Select End Date"
            value={endDate}
            onChange={handleChangeEndDate}
            KeyboardButtonProps={{
              "aria-label": "change end date",
            }}
          />
        </Box>
        {differenceInDays(dateRange.endDate, dateRange.startDate) <= 0 ? (
          <Alert severity="warning">
            Please ensure that the start date is before the end date
          </Alert>
        ) : null}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePicker;
