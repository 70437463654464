import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
// Icons
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";

const EventPrimaryContact = ({ primaryContactEmail, size }) => {
  return (
    <Tooltip title="Primary contact email">
      <Typography
        variant={size === "sm" ? "body2" : "body1"}
        component="div"
        color="textSecondary"
      >
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <MailOutlinedIcon />
          </Box>{" "}
          <Link href={`mailto:${primaryContactEmail}`}>
            {primaryContactEmail}
          </Link>
        </Box>
      </Typography>
    </Tooltip>
  );
};

export default EventPrimaryContact;
