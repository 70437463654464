import React, { useState } from "react";
// Material UI
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Components
import OrgChallengeAppBar from "../../shared/OrgChallengeAppBar";
import OrgAppBar from "../../shared/OrgAppBar";
// import Chart from "../../shared/Chart";
// import WeekPicker from "../../shared/WeekPicker";
import ChallengeInsightsRegistrations from "./components/ChallengeInsightsRegistrations";
import ChallengeInsightsSubmissions from "./components/ChallengeInsightsSubmissions";
import ChallengeInsightsDemographics from "./components/ChallengeInsightsDemographics";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const OrgChallengeInsights = () => {
  const theme = useTheme();

  // State for tabs
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <OrgChallengeAppBar />
      <OrgAppBar
        title="Insights"
        subtitle="Dive deeper into who's engaging in your program"
      />
      <Tabs value={value} onChange={handleChangeTab}>
        <Tab label="Registrations & Submissions" />
        <Tab label="Demographics" />
      </Tabs>

      {/* Registration & Submission */}
      <TabPanel value={value} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ChallengeInsightsRegistrations />
          </Grid>
          <Grid item xs={12}>
            <ChallengeInsightsSubmissions />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Demographics Tab */}
      <TabPanel value={value} index={1}>
        <ChallengeInsightsDemographics />
      </TabPanel>
    </Box>
  );
};

export default OrgChallengeInsights;
