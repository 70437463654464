import axios from "../utils/axios";
import { useQuery, useMutation } from "react-query";

export const useGetCompanyDetailsByCompanyId = async (companyId) => {
  try {
    const result = useQuery(
      ["companyDetails", { companyId }],
      getCompanyDetailsByCompanyId
    );
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

export const getCompanyDetailsByCompanyId = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/company/id/${companyId}`);
};

export const useGetCompanyDetailsBySlug = (communitySlug) => {
  try {
    const result = useQuery(
      ["companyDetails", { communitySlug: communitySlug }],
      getCompanyDetailsBySlug,
      {
        enabled: !!communitySlug,
      }
    );
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

export const getCompanyDetailsBySlug = async ({ queryKey }) => {
  const [_key, { communitySlug }] = queryKey;
  return await axios.get(`/api/company/slug/${communitySlug}`);
};

export const uploadCompanyHeaderImage = async ({
  companyId,
  headerImageFormData,
}) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.post(
    `/api/company/upload/headerImage/${companyId}`,
    headerImageFormData,
    config
  );
};

// Call this first when creating a company
export const createUpdateOrganization = async ({ organizationFormData }) => {
  const body = {
    companyData: {
      ...organizationFormData,
      companyLogoPath: null,
    },
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const companyResult = await axios.post(
    `/api/company/create`,
    body,
    config
  );

  return companyResult;
};

// Call this after uplaoding the community logo
export const useUploadCompanyLogo = async ({ companyId, companyLogoPath }) => {
  const configFileUpload = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  if (companyLogoPath && companyId) {
    const logoPathFormData = new FormData();

    if (companyLogoPath) {
      logoPathFormData.append("myFile", companyLogoPath);

      const resultUploadLogo = await axios.post(
        `/api/company/upload/companyLogo/${companyId}`,
        logoPathFormData,
        configFileUpload
      );
      return resultUploadLogo;
    }
    // If there is an error, return the error message
  }
};
