import React, { useEffect } from "react";
// Redux
import { connect } from "react-redux";
import {
  getSubmissionsForChallengeRound,
  submissionsToGroup,
} from "../../../redux/actions/group";
// Material UI
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
// Components
import Spinner from "../../Spinner";
// Utils
import { SUBMITTED_STATUS_TYPE_ID } from "../../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  ChipRoot: {
    fontSize: "0.65rem",
    cursor: "pointer",
    height: "18px",
  },
  ChipLabel: {
    padding: "3px",
  },
}));

export const GroupSubmissions = ({
  challenge,
  currentRoundIndex,
  group,
  submissions,
  getSubmissionsForChallengeRound,
  submissionsToGroup,
}) => {
  const classes = useStyles();

  const deleteSubmission = (submissionId) => {
    if (
      window.confirm(
        "Are you sure you want to remove this submission from the group?"
      ) &&
      submissionId &&
      challenge &&
      group.data
    ) {
      const currentRound = challenge.round[currentRoundIndex];
      const submissionsToAssign = [];
      const submissionsToRemove = [submissionId];

      submissionsToGroup(
        challenge.id,
        currentRound.id,
        submissionsToAssign,
        submissionsToRemove,
        group.data.id
      );
    }
  };

  useEffect(() => {
    if (challenge) {
      const currentRound = challenge.round[currentRoundIndex];
      getSubmissionsForChallengeRound(currentRound.id);
    }
  }, [currentRoundIndex, group]);

  const assignSubmissionsToGroup = (value) => {
    if (value && challenge && group.data) {
      const currentRound = challenge.round[currentRoundIndex];
      const submissionsToAssign = [value.id];
      const submissionsToRemove = [];

      submissionsToGroup(
        challenge.id,
        currentRound.id,
        submissionsToAssign,
        submissionsToRemove,
        group.data.id
      );
    }
  };

  return (
    <Box>
      {group.data && group.data.submissions && (
        <Box mb={1}>
          <Typography variant="caption">
            {group.data.submissions.length || 0} Submission(s)
          </Typography>
        </Box>
      )}
      <Box width="100%" mb={[1, 2]}>
        <Autocomplete
          id="submissions-assign-group"
          autoHighlight
          options={submissions.data || []}
          loading={submissions.loading}
          disableCloseOnSelect
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => {
            if (option && option.team) {
              return option.team.name;
            }
          }}
          onChange={(event, newValue) => {
            assignSubmissionsToGroup(newValue);
          }}
          renderOption={(option) => (
            <>
              <Box lineHeight={"0.8rem"} width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body2"
                    style={{
                      textDecoration:
                        option.team && option.team.eliminatedFlag
                          ? "line-through solid red"
                          : "none",
                      color:
                        option.team &&
                        option.team.eliminatedFlag &&
                        "lightgray",
                    }}
                  >
                    <strong>{option.team && option.team.name}</strong>
                  </Typography>
                  <Box fontSize="0.5rem">
                    <Box>
                      {option.submittedStatusTypeId ===
                      SUBMITTED_STATUS_TYPE_ID.SUBMITTED
                        ? "Submitted"
                        : option.submittedStatusTypeId ===
                          SUBMITTED_STATUS_TYPE_ID.SAVED
                        ? "In Progress"
                        : null}
                    </Box>
                    <Box>
                      {option.team &&
                        option.team.eliminatedFlag &&
                        "Eliminated"}
                    </Box>
                  </Box>
                </Box>

                <Box mt={0.25}>
                  {option.groups &&
                    option.groups.map((e, index) => {
                      return (
                        <Chip
                          key={`${e.id}-${index}`}
                          classes={{
                            root: classes.ChipRoot,
                            label: classes.ChipLabel,
                          }}
                          label={e.name}
                          variant="outlined"
                          color={
                            group.data && e.id === group.data.id
                              ? "primary"
                              : "default"
                          }
                        />
                      );
                    })}
                </Box>
              </Box>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={`Add submissions to ${
                group.data && group.data.name
              }`}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {submissions.loading && <Spinner />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <TableContainer>
        <Table aria-label="submissions-tables">
          <TableHead>
            <TableRow>
              <TableCell>
                {!challenge.maxPeopleTeam ? "Team" : ""} Name
              </TableCell>
              <TableCell>Groups</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {group.data &&
            group.data.submissions &&
            group.data.submissions.length > 0 ? (
              group.data.submissions.map((sub, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        <strong>{sub.team.name}</strong>
                      </Typography>

                      <Typography variant="caption" color="textSecondary">
                        {sub.submittedStatusTypeId ===
                        SUBMITTED_STATUS_TYPE_ID.SUBMITTED
                          ? "Submitted"
                          : sub.submittedStatusTypeId ===
                            SUBMITTED_STATUS_TYPE_ID.SAVED
                          ? "In Progress"
                          : null}
                        {sub.team && sub.team.eliminatedFlag && " | Eliminated"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`Groups that are assigned to ${sub.team.name}`}
                        placement="top"
                      >
                        <Box>
                          {sub.groups &&
                            sub.groups.map((g, j) => {
                              return (
                                <Chip
                                  color="default"
                                  label={g.name}
                                  key={`${g.id}-${j}`}
                                />
                              );
                            })}
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`Remove submission from ${group.data.name}`}
                        placement="top"
                      >
                        <IconButton onClick={() => deleteSubmission(sub.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No submissions assigned to this group.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  group: state.group.group,
  submissions: state.group.submissions,
});

const mapDispatchToProps = {
  getSubmissionsForChallengeRound,
  submissionsToGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSubmissions);
