import axios from "../../utils/axios";
import { setAlert } from "./alert";
import {
  USER_NOT_LOADED,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_MYPROFILE,
  UPDATE_USER,
  UPLOAD_AVATAR,
  SET_TOKEN,
  CLEAR_MYPROJECTS,
  GET_GOOGLE_CLIENT_ID,
  CLEAR_COMPANY,
  SET_REDIRECT_URL,
  SET_DEFAULT_REDIRECT,
  CLEAR_ALL_CHALLENGES,
  CLEAR_CHALLENGE,
  CLEAR_PROFILES,
  CLEAR_PROFILE,
  CLEAR_PROJECT,
  CLEAR_PUBLICPROJECTS,
  CLEAR_ALL_ALERTS,
  CLEAR_CUSTOM_FORM,
} from "./actionTypes";
import { USER_TYPE } from "../../utils/globalValues";
import { handleServerError } from "./error";
import Cookies from "js-cookie";
//import { Event } from "../../utils/tracking";
// import analytics from "../../analytics";
// import {
//   A_LOG_IN,
//   A_LOG_OUT,
//   A_REGISTER_USER,
// } from "../../analytics/eventTypes";

import history from "../../utils/history";

export const setRedirectUrl = (redirectUrl) => async (dispatch) => {
  try {
    await dispatch({
      type: SET_REDIRECT_URL,
      payload: redirectUrl,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const setDefaultRedirectUrl = () => async (dispatch) => {
  try {
    await dispatch({
      type: SET_DEFAULT_REDIRECT,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/auth");

    if (res.status !== 204) {
      await dispatch({
        type: USER_LOADED,
        payload: res.data,
      });

      // analytics.identify(res.data.id);
      // analytics.people.set({
      //   $email: res.data.email,
      //   $avatar: res.data.avatar,
      //   $distinct_id: res.data.id,
      //   $created: res.data.registrationDate,
      //   userName: res.data.userName,
      // });
    } else {
      await dispatch({
        type: USER_NOT_LOADED,
      });
    }
  } catch (error) {
    await dispatch({
      type: USER_NOT_LOADED,
    });

    dispatch(handleServerError(error));
  }
};

// Register User
export const register =
  ({
    firstName,
    lastName,
    type,
    loginType,
    email,
    password,
    terms,
    emailNotificationActive,
  }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      firstName,
      lastName,
      type,
      loginType,
      email,
      password,
      terms,
      emailNotificationActive,
    });

    try {
      const res = await axios.post("/api/users", body, config);

      // analytics profile and tracking

      // analytics.people.set({
      //   emailNotificationActive: emailNotificationActive,
      //   hasResume: false,
      // });

      // analytics.track(A_REGISTER_USER, {
      //   userType: type,
      //   loginType: loginType,
      // });

      // Track the register event
      //Event("USER", `User: ${email} registered`, "REGISTER PAGE");

      await dispatch({
        type: SET_TOKEN,
        payload: res.data,
      });
      await dispatch(loadUser());
      await dispatch({
        type: REGISTER_SUCCESS,
      });

      return true;
    } catch (error) {
      dispatch(handleServerError(error));
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login = (didToken, body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${didToken}`,
      },
    };
    const reqBody = { ...body };
    const res = await axios.post("/api/auth", reqBody, config);

    await dispatch({
      type: SET_TOKEN,
      payload: res.data,
    });

    await dispatch(loadUser());
    await dispatch({
      type: LOGIN_SUCCESS,
    });

    await dispatch(setDefaultRedirectUrl());

    return res.data.userId;
  } catch (error) {
    dispatch(handleServerError(error));
    dispatch({
      type: LOGIN_FAIL,
    });
    return false;
  }
};

export const oauthGoogle =
  (accessToken, typeId, register, history) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        code: accessToken,
        typeId: typeId,
      };
      const res = await axios.post("/api/auth/google", body, config);

      await dispatch({
        type: SET_TOKEN,
        payload: res.data,
      });
      await dispatch(loadUser());
      await dispatch({
        type: LOGIN_SUCCESS,
      });
      if (register && history) {
        if (typeId === USER_TYPE.STUDENT) history.push("/onboarding");
        else history.push("/me");
      }
    } catch (error) {
      dispatch(handleServerError(error));

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

export const oauthRegisterGoogle =
  (accessToken, typeId, register, history) => async (dispatch) => {
    try {
      //console.log(accessToken, typeId, register, history);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        code: accessToken,
        typeId: typeId,
      };
      //console.log("google/register");
      const res = await axios.post("/api/auth/google/register", body, config);

      await dispatch({
        type: SET_TOKEN,
        payload: res.data,
      });
      await dispatch(loadUser());
      await dispatch({
        type: LOGIN_SUCCESS,
      });
      if (register && history) {
        if (typeId === USER_TYPE.STUDENT) history.push("/onboarding");
        else history.push("/me");
      }
    } catch (error) {
      dispatch(handleServerError(error));

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

export const updateUserAccount = (reqUser) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let response = {
    success: true,
    statusCode: 0,
    message: "",
  };
  try {
    const res = await axios.post("/api/users/update", reqUser, config);

    // analytics.people.set({
    //   emailNotificationActive: reqUser.emailNotificationActive,
    //   userName: reqUser.userName,
    // });

    await dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    await dispatch(
      setAlert("Your user information is updated successfully.", "success")
    );
    response.success = true;
    return response;
  } catch (error) {
    dispatch(handleServerError(error));
    response.success = false;
    response.statusCode = error.status;
    response.message =
      (error.data && error.data.msg) || "Error updating user name";
    return response;
  }
};

export const resetPassword =
  ({ newPassword, confirmPassword }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ newPassword, confirmPassword });
    if (newPassword !== confirmPassword) {
      setAlert("Passwords do not match", "danger");
    } else if (
      window.confirm("Are you sure you want to change your password?")
    ) {
      try {
        const res = await axios.post("/api/users/reset", body, config);

        dispatch(setAlert(res.data.message, res.data.alertType));
      } catch (error) {
        dispatch(handleServerError(error));
      }
    }
  };

export const setResetPasswordToken = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });

  try {
    const res = await axios.post("/api/users/password-token", body, config);
    dispatch(setAlert(res.data.message, res.data.alertType));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const resetForgotPassword =
  ({ newPassword, confirmPassword, token }, history) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(newPassword, confirmPassword);
    const body = JSON.stringify({ newPassword, confirmPassword, token });
    if (newPassword !== confirmPassword) {
      setAlert("Passwords do not match", "danger");
    } else if (
      window.confirm("Are you sure you want to change your password?")
    ) {
      try {
        const res = await axios.post("/api/users/forgot", body, config);

        dispatch(setAlert(res.data.message, res.data.alertType));

        history.push("/login");
      } catch (error) {
        dispatch(handleServerError(error));
      }
    }
  };

export const uploadAvatar = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axios.post("/api/users/upload/image", formData, config);

    dispatch({
      type: UPLOAD_AVATAR,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Load GoogleClientId
export const getGoogleClientId = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/auth/googleKeyId");
    dispatch({
      type: GET_GOOGLE_CLIENT_ID,
      payload: res.data.googleClientId,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Logout
export const logout = () => async (dispatch) => {
  await dispatch({ type: CLEAR_PROFILE });
  await dispatch({ type: CLEAR_MYPROFILE });
  await dispatch({ type: LOGOUT });
  await dispatch({ type: CLEAR_MYPROJECTS });
  await dispatch({ type: CLEAR_COMPANY });
  await dispatch({ type: CLEAR_ALL_CHALLENGES });
  await dispatch({ type: CLEAR_CHALLENGE });
  await dispatch({ type: CLEAR_PROFILES });
  await dispatch({ type: CLEAR_PROJECT });
  await dispatch({ type: CLEAR_PUBLICPROJECTS });
  await dispatch({ type: CLEAR_ALL_ALERTS });
  await dispatch({ type: CLEAR_COMPANY });
  await dispatch({ type: CLEAR_CUSTOM_FORM });

  // analytics.track(A_LOG_OUT);
  // analytics.reset();

  Cookies.remove("token");

  history.push("/login");
};

export const clearCache = () => async (dispatch) => {
  await dispatch({ type: LOGOUT });
  await dispatch({ type: CLEAR_PROFILE });
  await dispatch({ type: CLEAR_MYPROFILE });
  await dispatch({ type: CLEAR_MYPROJECTS });
  await dispatch({ type: CLEAR_ALL_CHALLENGES });
  await dispatch({ type: CLEAR_CHALLENGE });
  await dispatch({ type: CLEAR_PROFILES });
  await dispatch({ type: CLEAR_PROJECT });
  await dispatch({ type: CLEAR_PUBLICPROJECTS });
  await dispatch({ type: CLEAR_ALL_ALERTS });
  await dispatch({ type: CLEAR_COMPANY });
  await dispatch({ type: CLEAR_CUSTOM_FORM });
};
