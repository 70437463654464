import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { addComment } from "../../../redux/actions/project";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import Button from "../../../shared/Button";

const CommentForm = ({ projectId, addComment, auth: { isAuthenticated } }) => {
  const history = useHistory();

  const [pathname] = useState(history.location.pathname);
  const [text, setText] = useState("");
  const onSubmit = (e) => {
    e.preventDefault();
    addComment(projectId, { text });
    setText("");
  };

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <Box mb={2}>
          <TextField
            id={"comment"}
            fullWidth
            margin={"dense"}
            autoComplete={"comment"}
            variant={"outlined"}
            type="text"
            placeholder={
              isAuthenticated
                ? `Write a comment...`
                : `Please login or create an account to post a comment.`
            }
            name="text"
            required
            multiline={true}
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Box>
        <div>
          {!isAuthenticated ? (
            <Link to={`/login?redirect=${pathname}`}>
              <Button>Submit</Button>
            </Link>
          ) : (
            <Button type="submit">Submit</Button>
          )}
        </div>
      </form>
    </Fragment>
  );
};
CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { addComment })(CommentForm);
