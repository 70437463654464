import React from "react";
// Redux
import { connect } from "react-redux";
// React query
import { useAddReactionToPost } from "../../../hooks/room";
// Context
import { usePostReplyContext } from "./RoomContext";
// Material UI
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// Icons
import InsertEmoticonOutlinedIcon from "@material-ui/icons/InsertEmoticonOutlined";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
// Emoji
import { Picker } from "emoji-mart";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    top: 0,
    right: 0,
    padding: theme.spacing(0),
    backgroundColor: "transparent",
    // borderWidth: theme.spacing(0.05),
    // borderColor: theme.palette.action.selected,
    borderRadius: theme.shape.borderRadius,
    margin: 0,
    marginTop: -theme.spacing(1),
    zIndex: theme.zIndex.appBar + 1,
  },
  optionIconButton: {
    color: (props) =>
      props.displayPopover ? theme.palette.text.secondary : "transparent",
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
  },
  optionIcon: {
    fontSize: theme.typography.body1.fontSize,
  },
  iconButton: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
  },
  icon: {
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5),
    fontSize: theme.typography.body1.fontSize,
    padding: 0,
  },
  emoji: {
    color: theme.palette.text.primary,
    margin: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
    paddingLeft: theme.spacing(0.1),
    paddingRight: theme.spacing(0.15),
  },
  emojiPopover: {
    zIndex: theme.zIndex.appBar + 2,
  },
}));

const PostPopover = ({ post, user, displayPopover }) => {
  const theme = useTheme();
  const classes = useStyles({ displayPopover });

  const { handleDrawerOpen } = usePostReplyContext();

  const OPTIONS = [
    {
      emoji: "👍",
      classes: classes.emoji,
      action: () => handleEmojiSelect(":thumbsup:"),
      tooltip: ":thumbsup:",
      display: true,
    },
    {
      emoji: "🔥",
      classes: classes.emoji,
      action: () => handleEmojiSelect(":fire:"),
      tooltip: ":fire:",
      display: true,
    },
    {
      emoji: "👏",
      classes: classes.emoji,
      action: () => handleEmojiSelect(":clap:"),
      tooltip: ":clap:",
      display: !post.isReply,
    },
    {
      emoji: "🎉",
      classes: classes.emoji,
      action: () => handleEmojiSelect(":tada:"),
      tooltip: ":tada:",
      display: !post.isReply,
    },
    {
      icon: InsertEmoticonOutlinedIcon,
      classes: classes.icon,
      action: (e) => handleClickPopover(e),
      tooltip: "React",
      display: true,
    },
    {
      icon: CommentOutlinedIcon,
      classes: classes.icon,
      action: () => handleDrawerOpen(post),
      tooltip: "Reply",
      display: !post.isReply,
    },
    {
      icon: MoreVertOutlinedIcon,
      classes: classes.icon,
      action: () => console.log("open more menu for post"),
      tooltip: "More",
      display: true,
    },
  ];

  const useAddReactionToPostMutation = useAddReactionToPost(
    post.roomId || "",
    post.parentPostId || "",
    post.isReply ? true : false
  );

  const [state, setState] = React.useState({
    native: true,
    set: "apple",
    theme: theme.palette.type || "auto",
    emoji: ":ok_hand:",
    title: "Pick your emoji…",
    useButton: false,
    showPreview: false,
  });

  React.useEffect(() => {
    setState({
      ...state,
      theme: theme.palette.type,
    });
  }, [theme.palette.type]);

  const handleEmojiSelect = (emoji) => {
    // generic function to react to any emoji
    let emojiColons;
    if (emoji && emoji.colons) {
      emojiColons = emoji.colons;
    } else {
      emojiColons = emoji;
    }
    // make request to react
    useAddReactionToPostMutation.mutate({
      postId: post.id,
      emoji: emojiColons,
    });
    handleClosePopover();
  };

  const [anchorElPopover, setAnchorElPopover] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? "emoji-picker-popover" : undefined;

  return (
    <>
      {/* <Box
        position="absolute"
        className={classes.tooltip}
        display={displayPopover ? "block" : "none"}
      >
        {(OPTIONS || []).map((option, index) => {
          if (!option.display) return null;
          let iconEmoji;
          if (option.emoji) {
            iconEmoji = <Box className={option.classes}>{option.emoji}</Box>;
          } else {
            iconEmoji = <option.icon className={option.classes} />;
          }

          return (
            <Tooltip key={index} title={option.tooltip}>
              <IconButton
                
                className={classes.iconButton}
                onClick={(e) => option.action(e)}
              >
                {iconEmoji}
              </IconButton>
            </Tooltip>
          );
        })}
      </Box> */}
      <Tooltip title="React">
        <IconButton
          onClick={handleClickPopover}
          className={classes.optionIconButton}
        >
          <InsertEmoticonOutlinedIcon className={classes.optionIcon} />
        </IconButton>
      </Tooltip>
      {!post.isReply && (
        <Tooltip title="Reply">
          <IconButton
            onClick={() => handleDrawerOpen(post)}
            className={classes.optionIconButton}
          >
            <CommentOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      )}
      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        className={classes.emojiPopover}
      >
        <Picker {...state} onSelect={handleEmojiSelect} />
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PostPopover);
