import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Container,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import challenge_not_found from "../../assets/images/challenge_not_found.svg";
import { MdArrowBack } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "block",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    textTransform: "none",
  },
  oops: {
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  imageGrid: {
    order: 1,
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  image: {
    width: "100%",
  },
}));
const ChallengeNotFound = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={6} className={classes.oops}>
            <Typography variant="h5" component="div" color="initial">
              <Box fontWeight={600}>
                The program you were looking for doesn't exist.
              </Box>
            </Typography>
            <Typography variant="subtitle1" color="initial" gutterBottom>
              You may have mistyped the url or the program page may have moved.
            </Typography>
            <Link to="/programs" className={classes.link}>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                className={classes.button}
                size="medium"
                startIcon={<MdArrowBack />}
              >
                Go to Programs
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} className={classes.imageGrid}>
            <img
              src={challenge_not_found}
              alt="No Profile Image"
              height="auto"
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default ChallengeNotFound;
