import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const selectValue = (value, selected, all) => {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));

  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
};

const deselectValue = (value, selected) => {
  return selected.filter((v) => v !== value);
};

const CheckboxesWidget = ({
  schema,
  label,
  id,
  disabled,
  options,
  value,
  autofocus,
  readonly,
  required,
  onChange,
  onBlur,
  onFocus,
}) => {
  const { enumOptions, enumDisabled, inline } = options;
  value = Array.isArray(value)
    ? value
    : typeof value === "string"
    ? [value]
    : [];

  const _onChange =
    (option) =>
    ({ target: { checked } }) => {
      const all = enumOptions.map(({ value }) => value);

      if (checked) {
        onChange(selectValue(option.value, value, all));
      } else {
        onChange(deselectValue(option.value, value));
      }
    };

  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  return (
    <>
      <Box display={disabled ? "none" : "block"} width="100%">
        <FormGroup row={!!inline}>
          {enumOptions.map((option, index) => {
            const checked = value.indexOf(option.value) !== -1;
            const itemDisabled =
              enumDisabled && enumDisabled.indexOf(option.value) != -1;
            const checkbox = (
              <Switch
                id={`${id}_${index}`}
                checked={checked}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && index === 0}
                onChange={_onChange(option)}
                onBlur={_onBlur}
                onFocus={_onFocus}
                color="primary"
              />
            );
            return (
              <FormControlLabel
                control={checkbox}
                key={index}
                label={option.label}
              />
            );
          })}
        </FormGroup>
      </Box>
      {disabled && (
        <Box>
          {enumOptions.map((option, index) => {
            const checked = value.indexOf(option.value) !== -1;
            if (!checked) {
              return <Box key={index} />;
            }
            return (
              <Box key={index}>
                <Typography variant="body2" color="textPrimary">
                  - <em>{option.value}</em>
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default CheckboxesWidget;
