import React, { Fragment, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { closeServerError } from "../../redux/actions/error";
// Material UI
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReportProblemTwoToneIcon from "@material-ui/icons/ReportProblemTwoTone";

const AlertModal = ({ serverError, closeServerError }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(serverError);
  }, [serverError]);

  const handleClose = () => {
    setOpen(false);
    closeServerError();
  };

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box textAlign={"center"}>
          <DialogTitle id="alert-dialog-title">
            <ReportProblemTwoToneIcon
              style={{ fontSize: 150 }}
              color={"error"}
            />
            <br />
            Oops, an error occurred on our part.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sorry, something went wrong on our end and we are looking into it.
              Please refresh the page or clear your cookies and try again. If
              this problem persists, please contact{" "}
              <a href={"mailto:support@pralent.com"}>support@pralent.com</a>.
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  serverError: state.alert.serverError,
});

const mapDispatchToProps = { closeServerError };

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
