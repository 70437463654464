import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Link, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getAllMyOrganizationChallenges,
  clearMyChallenges,
} from "../../redux/actions/challenge";
// Material UI
import Box from "@material-ui/core/Box";
// Icons
import { IoBulbOutline } from "react-icons/io5";
// Components
import Spinner from "../../shared/Spinner";
import OrgAppBar from "../../shared/OrgAppBar";
import EmptyState from "../../shared/EmptyState";
import Button from "../../shared/Button";
import OrgChallengeCard from "./components/OrgChallengeCard";

export const Challenges = ({
  myChallenges,
  loading,
  getAllMyOrganizationChallenges,
  clearMyChallenges,
  companyProfile,
}) => {
  const { orgId } = useParams();

  const fetchChallenges = async () => {
    await getAllMyOrganizationChallenges(companyProfile.id);
  };
  useEffect(() => {
    fetchChallenges();
    return () => clearMyChallenges();
  }, []);

  if (!companyProfile) {
    return <Redirect to={`/org/${companyProfile.orgId}/profile`} />;
  }

  const OrgChallengesActions = () => {
    return (
      <>
        <Button component={Link} to={`/org/${orgId}/programs/new`}>
          Create New Program
        </Button>
      </>
    );
  };

  return (
    <>
      <Box height="100%" margin="auto">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {!companyProfile ? (
              <div>
                Please create an organization profile before accessing program
                features.
              </div>
            ) : (
              <>
                <Box>
                  <OrgAppBar
                    title={
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: "8px" }}
                      >
                        <IoBulbOutline /> Programs
                      </Box>
                    }
                    subtitle="Organize hackathons, competitions, fellowships, applications, and more."
                    actions={<OrgChallengesActions />}
                  />
                  {myChallenges && myChallenges.length > 0 ? (
                    myChallenges.map((challenge, index) => {
                      return (
                        <OrgChallengeCard challenge={challenge} key={index} />
                      );
                    })
                  ) : (
                    <EmptyState
                      title="No programs created yet"
                      subtitle="Create and manage any type of submission or application. Manage everything including competitions, hackathons, applications, grants, and more."
                      link="https://www.notion.so/Program-Overview-Customization-1257e2e49b974f3a9980c72529ab8f2e"
                    />
                  )}
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
};

Challenges.propTypes = {
  myChallenges: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  getAllMyOrganizationChallenges: PropTypes.func.isRequired,
  clearMyChallenges: PropTypes.func.isRequired,
  companyProfile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  myChallenges: state.challenge.myChallenges,
  loading: state.challenge.loading,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  getAllMyOrganizationChallenges,
  clearMyChallenges,
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
