import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const ObjectFieldTemplate = ({ properties, title, description }) => {
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h5" component="h2">
          <strong>{title}</strong>
        </Typography>
        {description && (
          <Typography variant="body1" color="textSecondary" component="div">
            <Box mt={1}>{description}</Box>
          </Typography>
        )}
      </Box>
      {properties.map((element) => (
        <Box my={{ xs: 2, md: 3, lg: 4 }} key={element.content.key}>
          {element.content}
        </Box>
      ))}
    </Box>
  );
};

export default ObjectFieldTemplate;
