import React, { Fragment, useState } from "react";
// Material UI
// Icons
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
// Components
import ProjectForm from "../ProjectForm";
import Button from "../Button";

const ProjectFormButton = () => {
  const [isDialogOpen, toggleDialog] = useState(false);

  const toggle = () => {
    toggleDialog(!isDialogOpen);
  };

  return (
    <Fragment>
      <ProjectForm isDialogOpen={isDialogOpen} toggleDialog={toggleDialog} />

      <Button startIcon={<AddOutlinedIcon />} onClick={toggle}>
        Add Project
      </Button>
    </Fragment>
  );
};

export default ProjectFormButton;
