import React, { useRef, useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import {
  saveCustomForm,
  fetchCustomForm,
} from "../../../redux/actions/formBuilder";
// Material UI Components
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
// Components
import FormFieldSettings from "./FormFieldSettings";
import FormBuilderPreviewDialog from "./FormBuilderPreviewDialog";
import Button from "../../../shared/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
// Utils
import { animateScroll as scroll } from "react-scroll";
import {
  formFieldConfig,
  defaultJsonSchema,
  defaultUiSchema,
  defaultWidget,
} from "../../../utils/CustomFormConfig";
import { CUSTOM_FORM_TYPE_ID } from "../../../utils/globalValues";

import { nanoid } from "nanoid";

const useStyles = makeStyles((theme) => ({
  formTitleInput: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 600,
  },
}));

const FormBuilder = ({
  challenge,
  currentRoundIndex,
  customForm,
  setAlert,
  saveCustomForm,
  fetchCustomForm,
}) => {
  const classes = useStyles();
  const [formOptionsAnchorEl, setFormOptionsAnchorEl] = useState(null);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);

  const handlePreviewButtonClick = () => {
    setOpenPreviewDialog(true);
  };

  const handleFormOptionsOpen = (e) => {
    setFormOptionsAnchorEl(e.currentTarget);
  };
  const handleFormOptionsClose = () => {
    setFormOptionsAnchorEl(null);
  };

  const [jsonSchema, setJsonSchema] = useState({
    ...defaultJsonSchema,
  });

  const [uiSchema, setUiSchema] = useState({
    ...defaultUiSchema,
  });

  const [widget, setWidget] = useState({
    ...defaultWidget,
  });

  const [formBuilderState, setFormBuilderState] = useState([]);

  useEffect(() => {
    fetchCustomForm(
      challenge.round[currentRoundIndex].id,
      CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM
    );
  }, [currentRoundIndex]);

  useEffect(() => {
    try {
      if (
        customForm &&
        customForm.data &&
        customForm.data.uiSchema &&
        customForm.data.jsonSchema
      ) {
        const currentJsonSchema = customForm.data.jsonSchema;
        const currentUiSchema = customForm.data.uiSchema;

        // required set
        const requiredSet = new Set(currentJsonSchema.required);
        // update json schema accordingly
        setJsonSchema({
          ...defaultJsonSchema,
          title: currentJsonSchema.title,
          description: currentJsonSchema.description,
        });

        const fieldKeys = currentUiSchema["ui:order"];

        let newFormBuilderState = [];

        fieldKeys &&
          fieldKeys.length > 0 &&
          fieldKeys.map((key, index) => {
            const jsonProperty = currentJsonSchema.properties[key];
            const uiProperty = currentUiSchema[key];

            let newFieldElem = {
              id: key,
              fieldType: jsonProperty.pralentFieldType,
              title: jsonProperty.title,
              description: jsonProperty.description,
              required: requiredSet.has(key),
            };

            // Handling a file type
            if (jsonProperty.pralentFieldType === "file") {
              const fileAcceptStr =
                formFieldConfig.properties.file.uiSchema["ui:options"].accept;
              const fieldAcceptStr =
                uiProperty["ui:options"] && uiProperty["ui:options"].accept;

              const fileAccepts = fileAcceptStr.split(",");
              const fieldAccepts = fieldAcceptStr.split(",");

              let res = {};
              for (var i = 0; i < fileAccepts.length; i++)
                res[fileAccepts[i]] =
                  fieldAccepts.includes(fileAccepts[i]) || false;
              newFieldElem["accept"] = res;
            } else if (
              ["multiselect", "checkboxes"].includes(
                jsonProperty.pralentFieldType
              )
            ) {
              const enums =
                (jsonProperty.items && jsonProperty.items.enum) || [];
              newFieldElem["enum"] = enums;
            } else if (
              ["select", "radio"].includes(jsonProperty.pralentFieldType)
            ) {
              const enums = jsonProperty.enum || [];
              newFieldElem["enum"] = enums;
            }
            newFormBuilderState = [...newFormBuilderState, newFieldElem];
          });
        setFormBuilderState(newFormBuilderState);
      } else {
        clearFormBuilderState();
      }
    } catch (error) {
      clearFormBuilderState();
    }
  }, [customForm, currentRoundIndex]);

  const changeJsonSchema = (e) => {
    setJsonSchema({
      ...jsonSchema,
      [e.target.name]: e.target.value,
    });
  };

  const clearFormBuilderState = () => {
    // set the default jsonSchema and uiSchema
    setJsonSchema({
      ...defaultJsonSchema,
    });
    setUiSchema({
      ...defaultUiSchema,
    });
    // set the default form builder state
    setFormBuilderState([]);
  };

  const onSubmit = () => {
    try {
      if (!jsonSchema.title) {
        return setAlert("Please add a title to your form.", "error");
      } else if (!formBuilderState || formBuilderState.length <= 0) {
        return setAlert(
          "Please add at least one field to your form before saving.",
          "error"
        );
      } else {
        let newJsonSchema = { ...jsonSchema };
        let newUiSchema = { ...uiSchema };
        // go through the form builder
        for (let index = 0; index < formBuilderState.length; index++) {
          const field = formBuilderState[index];
          let fieldId = field.id;
          const fieldTitle = field.title;
          const fieldType = field.fieldType;
          const fieldDescription = field.description;
          const fieldRequired = field.required;
          // make sure there is an id
          if (!fieldId) {
            setAlert(
              "There was an error during saving, please contact support.",
              "error"
            );
            return;
          }
          // add to required if it is required
          if (fieldRequired) {
            newJsonSchema = {
              ...newJsonSchema,
              required: [...newJsonSchema.required, fieldId],
            };
          }
          // add to ordering of fields
          newUiSchema = {
            ...newUiSchema,
            ["ui:order"]: [...newUiSchema["ui:order"], fieldId],
          };
          // creating new json schema
          let configProperties = formFieldConfig.properties[fieldType];
          // need to add different jsonSchema based on fieldType
          let newJsonProperty = {};
          let newUiProperty = {};
          let newWidget = {};
          // switch for json schema
          switch (fieldType) {
            case "uri":
            case "number":
            case "date":
            case "datetime":
            case "email":
            case "shortText":
            case "color":
            case "multiline":
            case "file":
              newJsonProperty = {
                ...configProperties.jsonSchema,
                pralentFieldType: fieldType,
                title: fieldTitle,
                description: fieldDescription,
              };
              break;
            case "select":
            case "radio":
              newJsonProperty = {
                ...configProperties.jsonSchema,
                pralentFieldType: fieldType,
                title: fieldTitle,
                description: fieldDescription,
                enum: field.enum || [],
              };
              break;
            case "checkboxes":
            case "multiselect":
              newJsonProperty = {
                ...configProperties.jsonSchema,
                pralentFieldType: fieldType,
                title: fieldTitle,
                description: fieldDescription,
                items: {
                  type: "string",
                  enum: field.enum || [],
                },
              };
              break;
          }
          // switch for ui schema
          switch (fieldType) {
            case "uri":
            case "number":
            case "date":
            case "datetime":
            case "email":
            case "shortText":
            case "color":
            case "multiline":
            case "checkboxes":
            case "multiselect":
            case "select":
            case "radio":
              newUiProperty = {
                ...configProperties.uiSchema,
              };
              break;
            case "file":
              const currentFieldAccept = Object.keys(field.accept).filter(
                (fileType) => field.accept[fileType]
              );

              newUiProperty = {
                ...configProperties.uiSchema,
                ["ui:options"]: {
                  accept: currentFieldAccept.join(","),
                },
              };
              break;
          }

          // switch for widget schema
          switch (fieldType) {
            case "uri":
            case "number":
            case "date":
            case "datetime":
            case "email":
            case "shortText":
            case "color":
            case "multiline":
            case "checkboxes":
            case "multiselect":
            case "select":
            case "radio":
            case "file": {
              newWidget = {
                ...configProperties.widget,
              };
            }
          }
          // update jsonSchema with newJsonProperty
          newJsonSchema = {
            ...newJsonSchema,
            properties: {
              ...newJsonSchema.properties,
              [fieldId]: {
                ...newJsonProperty,
              },
            },
          };
          newUiSchema = {
            ...newUiSchema,
            [fieldId]: {
              ...newUiProperty,
            },
          };
        }

        // call redux
        saveCustomForm(
          challenge.id,
          challenge.round[currentRoundIndex].id,
          newJsonSchema,
          newUiSchema,
          CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM
        );
      }
    } catch (error) {
      setAlert(
        "Something went wrong, please try again or contact support.",
        "error"
      );
    }
  };

  const addNewField = (fieldType) => {
    let newField = {
      id: nanoid(10), // generate a unique id size 10 which will be key for field
      fieldType: fieldType,
      title: `[NEED TO EDIT] ${formFieldConfig.properties[fieldType].name}`,
      description: "",
      required: false,
    };

    if (["checkboxes", "multiselect", "select", "radio"].includes(fieldType)) {
      newField.enum = ["First choice", "Second choice", "Third choice"];
    }

    if (fieldType === "file") {
      const fileAcceptStr =
        formFieldConfig.properties.file.uiSchema["ui:options"].accept;

      const fileAccepts = fileAcceptStr.split(",");

      let res = {};
      for (var i = 0; i < fileAccepts.length; i++) res[fileAccepts[i]] = false;
      newField.accept = res;
    }

    setFormBuilderState([...formBuilderState, newField]);

    handleFormOptionsClose();

    scroll.scrollToBottom();
  };

  const [syncBackend, setSyncBackend] = useState(false);

  useEffect(() => {
    if (syncBackend) {
      onSubmit();
    }

    setSyncBackend(false);
  }, [syncBackend]);

  const handleSaveField = async (data, index) => {
    const newFormBuilderState = formBuilderState.map((item, itemIndex) => {
      if (itemIndex === index) {
        return data;
      }
      return item;
    });
    await setFormBuilderState(newFormBuilderState);
    await setSyncBackend(true);
  };

  const handleDeleteField = async (index) => {
    if (window.confirm("Are you sure you want to delete this field?")) {
      const newFields = formBuilderState.filter(
        (item, itemIndex) => itemIndex !== index
      );
      await setFormBuilderState(newFields);
      await setSyncBackend(true);
      return true;
    } else {
      return false;
    }
  };

  const moveElementUp = (index) => {
    if (index > 0) {
      let oldFormBuilderState = [...formBuilderState];
      const previousElement = oldFormBuilderState[index - 1];
      oldFormBuilderState[index - 1] = oldFormBuilderState[index];
      oldFormBuilderState[index] = previousElement;
      setFormBuilderState(oldFormBuilderState);
    }
  };

  const moveElementDown = (index) => {
    if (index < formBuilderState.length - 1) {
      let oldFormBuilderState = [...formBuilderState];
      const nextElement = oldFormBuilderState[index + 1];
      oldFormBuilderState[index + 1] = oldFormBuilderState[index];
      oldFormBuilderState[index] = nextElement;
      setFormBuilderState(oldFormBuilderState);
    }
  };

  const [scrollY, setScrollY] = useState();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [window]);

  const handleScroll = () => {
    let scrollTop = window.scrollY;
    setScrollY(scrollTop);
  };

  return (
    <>
      <Box my={4}>
        <FormBuilderPreviewDialog
          open={openPreviewDialog}
          setOpen={setOpenPreviewDialog}
          customForm={customForm}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} sm={9}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup>
                    <TextField
                      id="title"
                      name="title"
                      placeholder="Enter a title for this form"
                      fullWidth
                      required
                      value={jsonSchema.title}
                      onChange={changeJsonSchema}
                      variant="outlined"
                      InputProps={{
                        classes: {
                          input: classes.formTitleInput,
                        },
                      }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <TextField
                      id="description"
                      name="description"
                      placeholder="Enter a general description for this form. This is information that the participants will see when they are working on submitting this form."
                      multiline
                      rows={8}
                      fullWidth
                      value={jsonSchema.description}
                      onChange={changeJsonSchema}
                      variant="outlined"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
            <Box my={{ xs: 2 }}>
              <Divider />
            </Box>
            <Box>
              <InputLabel>Form Fields</InputLabel>
              {formBuilderState &&
                formBuilderState.length > 0 &&
                formBuilderState.map((formField, index) => {
                  return (
                    <FormFieldSettings
                      key={index}
                      index={index}
                      formField={formField}
                      handleSaveField={handleSaveField}
                      onSubmit={onSubmit}
                      moveElementUp={moveElementUp}
                      moveElementDown={moveElementDown}
                      handleDeleteField={handleDeleteField}
                    />
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              display="flex"
              justifyContent="flex-end"
              position="relative"
              height={0}
              width="100%"
            >
              <Box
                width="100%"
                bgcolor="background.paper"
                borderRadius="borderRadius"
                boxShadow={1}
                display="flex"
                flexDirection="column"
                position="absolute"
                top={scrollY}
                style={{ transition: "all 0.75s cubic-bezier(0.4,0.0,0.2,1)" }}
              >
                <Button
                  onClick={handleFormOptionsOpen}
                  variant="text"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  Add New Field
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={formOptionsAnchorEl}
                  keepMounted
                  open={Boolean(formOptionsAnchorEl)}
                  onClose={handleFormOptionsClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  {formFieldConfig &&
                    formFieldConfig.properties &&
                    Object.keys(formFieldConfig.properties).map(
                      (option, index) => {
                        const field = formFieldConfig.properties[option];
                        const Icon = field.icon;
                        return (
                          <Tooltip
                            placement="right"
                            key={index}
                            title={(field && field.about) || ""}
                          >
                            <MenuItem onClick={() => addNewField(option)}>
                              <Icon /> <Box ml={1}>{field.name}</Box>
                            </MenuItem>
                          </Tooltip>
                        );
                      }
                    )}
                </Menu>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<VisibilityIcon />}
                  onClick={() => handlePreviewButtonClick()}
                >
                  Preview
                </Button>
                <Button variant="contained" onClick={onSubmit}>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  customForm: state.formBuilder.customForm,
});

const mapDispatchToProps = { setAlert, saveCustomForm, fetchCustomForm };

export default connect(mapStateToProps, mapDispatchToProps)(FormBuilder);
