import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { authenticateJudge } from "../../redux/actions/judge";
// Material UI
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// Components
import Button from "../Button";

export const AuthenticateJudge = ({
  judgeAuth,
  judgeDetails,
  challengeDetails,
  authenticateJudge,
}) => {
  const location = useLocation();

  const [displayModal, setDisplayModal] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const currentRoundInfo =
      challengeDetails &&
      challengeDetails.round &&
      challengeDetails.round.find(
        (elem) => elem.id === judgeDetails.challengeRoundId
      );
    if (
      currentRoundInfo &&
      currentRoundInfo.judgingPasswordEnabled &&
      judgeAuth
    ) {
      if (!judgeAuth.isAuthenticated || judgeAuth.judgeId !== judgeDetails.id) {
        setDisplayModal(true);
      } else {
        setDisplayModal(false);
      }
    } else {
      setDisplayModal(false);
    }
    setPassword("");
    setShowPassword(false);
  }, [judgeAuth, judgeDetails, challengeDetails, location]);

  const handleAuthenticateJudge = (e) => {
    e.preventDefault();

    authenticateJudge(judgeDetails.id, password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const currentRound =
    judgeDetails &&
    challengeDetails &&
    challengeDetails.round &&
    challengeDetails.round.length > 0 &&
    challengeDetails.round.find(
      (elem) => elem.id === judgeDetails.challengeRoundId
    );

  return (
    <>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={displayModal}
        PaperProps={{
          style: { boxShadow: "none" },
        }}
        BackdropProps={{
          style: { backgroundColor: "rgb(255, 255, 255, 0.95)" },
        }}
      >
        <Box m="auto">
          <DialogTitle id="confirmation-dialog-title">
            <strong>Enter your reviewer password</strong>
            {challengeDetails && (
              <>
                <Box>
                  {challengeDetails.title}
                  {currentRound && <span> - {currentRound.name}</span>}
                </Box>
              </>
            )}
          </DialogTitle>
          <form onSubmit={handleAuthenticateJudge}>
            <Box px={[2, 3]}>
              <Box>
                Please enter the password that was sent to you in the reviewing
                email for this round. If you do not have access to this password
                and you are supposed to have access to it, please contact the
                program organizers or{" "}
                <Link href="mailto:support@pralent.com">
                  support@pralent.com
                </Link>
                .
              </Box>
              <Box mt={[1, 2]}>
                <FormGroup>
                  <InputLabel required>Enter password</InputLabel>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormGroup>
              </Box>
            </Box>

            <DialogActions>
              <Button type="submit">Submit</Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  judgeAuth: state.judge.judgeAuth,
  judgeDetails: state.judge.judgeDetails,
  challengeDetails: state.judge.challengeDetails,
});

const mapDispatchToProps = { authenticateJudge };

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateJudge);
