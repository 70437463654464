import React from "react";
// Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
// Utils
import { EVENT_ONLINE } from "../../../utils/globalValues";

const EventOnline = ({ online }) => {
  return (
    <Typography variant="body1" component="div" color="textSecondary">
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          {online === EVENT_ONLINE.INPERSON ? (
            <PeopleOutlineOutlinedIcon />
          ) : (
            <VideocamOutlinedIcon />
          )}
        </Box>{" "}
        {online === EVENT_ONLINE.ONLINE
          ? "Online"
          : online === EVENT_ONLINE.HYBRID
          ? "Hybrid"
          : "In Person"}
      </Box>
    </Typography>
  );
};

export default EventOnline;
