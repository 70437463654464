import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// Components
import Spinner from "../../Spinner";
import EditGroup from "./EditGroup";
import GroupSubmissions from "./GroupSubmissions";
import GroupJudges from "./GroupJudges";
// Assets
import NO_GROUPS_IMAGE from "../../../assets/images/groups.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`groups-tabpanel-${index}`}
      aria-labelledby={`groups-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `groups-tab-${index}`,
    "aria-controls": `groups-tabpanel-${index}`,
  };
}

export const GroupTabs = ({ group }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {group.loading ? (
        <Spinner />
      ) : (
        <Box width="100%" p={[1, 2, 3]} mx="auto">
          {group.data === null ? (
            <Box textAlign="center">
              <img
                src={NO_GROUPS_IMAGE}
                alt="no-group-image"
                height="100%"
                width="50%"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              />
              <Typography variant="body2">
                Add a group to this round. Groups allow you to group together
                similar submissions and reviewers. Groups can be used for
                managing different categories of submissions, organizing
                multiple tracks, different reviewing teams, and much more. This
                is not where you will assign submissions and reviewers for
                scoring purposes. Please use the Assign Reviewers button for
                assigning submissions and reviewers.
              </Typography>
            </Box>
          ) : (
            <>
              <EditGroup />
              <Box py={[2, 3]}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Submissions" {...a11yProps(0)} />
                  <Tab label="Reviewers" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <GroupSubmissions />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <GroupJudges />
                </TabPanel>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  group: state.group.group,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GroupTabs);
