import React from "react";

import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const EmailWidget = (props) => {
  const _onChange = ({ target: { value } }) =>
    props.onChange(value === "" ? props.options.emptyValue : value);
  return (
    <>
      <Box display={props.disabled ? "none" : "block"} width="100%">
        <TextField
          id={props.id}
          value={props.value || ""}
          type="email"
          onChange={_onChange}
          required={props.required}
          placeholder={props.placeholder}
          autoFocus={props.autofocus}
          disabled={props.disabled}
          label={null}
          fullWidth
        />
      </Box>
      {props.disabled && (
        <Box>
          <Typography variant="body2" color="textPrimary" component="div">
            <Link href={`mailto:${props.value}`} color="primary">
              <em>{props.value}</em>
            </Link>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EmailWidget;
