// Icons
import {
  CodeIcon,
  AcademicCapIcon,
  LightBulbIcon,
  BriefcaseIcon,
  UserGroupIcon,
  BookOpenIcon,
  CashIcon,
  GlobeIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";

const usecases = [
  {
    name: "Clubs & Organizations",
    description:
      "One powerful, free tool perfect for your clubs to engage with each other and keep up-to-date on your latest events and opportunities.",
    icon: UserGroupIcon,
  },
  {
    name: "Employers & Recruiters",
    description:
      "Create a home for your talent community. Host recruiting events, post new jobs, and keep track of your diversity goals.",
    icon: BriefcaseIcon,
  },
  {
    name: "Startups & Brands",
    description:
      "Build your brand through community. Connect with your investors, announce major milestones, and increase awareness.",
    icon: LightningBoltIcon,
  },
  {
    name: "Customers",
    description:
      "Create spaces for your customers to learn more, access guides & tutorials, new product updates, and gain feedback.",
    icon: CashIcon,
  },
  {
    name: "Tech Groups & Hackathons",
    description:
      "Empower your members with one platform to run hackathons, your tech meetups, and new job opportunities.",
    icon: CodeIcon,
  },
  {
    name: "Instructors & Courses",
    description:
      "Keep your students engaged and provide resources and new opportunities to interact and network with each other.",
    icon: BookOpenIcon,
  },
  {
    name: "Entrepreneurial Ecosystems",
    description:
      "Host unique pitch competitions, manage all of your events, connect your startups and entrepreneurs with each other.",
    icon: LightBulbIcon,
  },
  {
    name: "Non-profit Organizations",
    description:
      "Connect with your donors, members, and volunteers in one place. Provide updates, host events, and more.",
    icon: GlobeIcon,
  },

  {
    name: "University & Alumni Engagement",
    description:
      "Bring all of your student clubs and alumni relations in one place. Contact us for custom solutions that fit your needs.",
    icon: AcademicCapIcon,
  },
];

export default function UseCases() {
  return (
    <div className="bg-gray-100 px-4 py-12 md:py-24 lg:py-40">
      <div className="relative mx-auto max-w-6xl">
        <div className="text-center">
          <p className="mx-auto max-w-3xl text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl xl:text-7xl">
            Created with unlimited use cases in mind
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {usecases.map((usecase) => (
                <div key={usecase.name}>
                  <div className="rounded-lg bg-gray-200 bg-opacity-40 p-6 shadow-sm">
                    <div>
                      <div>
                        <span className="inline-flex items-center justify-center rounded-md p-3">
                          <usecase.icon
                            className="text-primary-500 h-8 w-8"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-2 text-xl font-semibold tracking-tight text-gray-900">
                        {usecase.name}
                      </h3>
                      <p className="mt-2 text-base text-gray-700">
                        {usecase.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
