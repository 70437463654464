import React from "react";
import { connect } from "react-redux";
// React query
import { useMutation, useQueryClient } from "react-query";
import {
  useFetchCommunityMembers,
  useRemoveUserFromCommunity,
  useBanUserFromCommunity,
  updateMemberApprovalStatus,
} from "../../../hooks/community";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
// Icons
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import BlockIcon from "@material-ui/icons/Block";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
import Button from "../../../shared/Button";
import PTable from "../../../shared/PTable";
//import PVirtualizedTable from "../../../shared/PVirtualizedTable";
import Spinner from "../../../shared/Spinner";
import ProfilePopover from "../../../shared/ProfilePopover";
import EmptyState from "../../../shared/EmptyState";
import OrgCommunityMemberStatus from "./OrgCommunityMemberStatus";
// Utils
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";
import { DEFAULT_TIME_ZONE } from "../../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const MemberInfo = ({ member }) => {
  const classes = useStyles();

  const memberUser = member.user;
  const memberProfile = member.profile;

  const memberEducation =
    memberProfile &&
    memberProfile.education &&
    memberProfile.education.length > 0 &&
    memberProfile.education[0];
  return (
    <Box minWidth={250} display="flex" alignItems="center">
      <CustomAvatar
        src={memberUser && memberUser.avatar}
        fallbackText={memberUser && memberUser.userName}
        alt={`${memberUser && memberUser.userName}-avatar`}
        className={classes.avatar}
      />
      <Box>
        <Typography variant="body1" component="div">
          <Box lineHeight={1.2} fontWeight={600}>
            {memberProfile
              ? `${memberProfile.firstName} ${memberProfile.lastName}`
              : memberUser && memberUser.userName}
          </Box>
        </Typography>
        {memberEducation && (
          <>
            <Typography variant="caption" component="div" color="textSecondary">
              <Box lineHeight={1.2}>{memberEducation.fieldOfStudy}</Box>
            </Typography>
            <Typography variant="caption" component="div" color="textSecondary">
              <Box lineHeight={1.2}>
                {memberEducation.school},{" "}
                {memberEducation.completionDate &&
                  new Date(memberEducation.completionDate).getFullYear()}
              </Box>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const OrgCommunityMemberTable = ({ company, currentUser }) => {
  const companyId = company.id;

  const {
    isSuccess,
    isFetching: isMemberFetching,
    isLoading: isMemberLoading,
    isError: isMemberError,
    data,
    error,
  } = useFetchCommunityMembers(company.id);

  const queryClient = useQueryClient();

  const removeUserFromCommunityMutation = useMutation(
    useRemoveUserFromCommunity,
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData(
          ["fetchCommunityMembers", { companyId: company.id }],
          (members) => members.filter((elem) => elem.id !== variables.memberId)
        );
      },
    }
  );

  const banUserFromCommunityMutation = useMutation(useBanUserFromCommunity, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ["fetchCommunityMembers", { companyId: company.id }],
        (members) => {
          if (members && members.length > 0) {
            const foundIndex = members.findIndex(
              (elem) => elem.id === variables.memberId
            );
            let newMembers = [...members];
            if (foundIndex >= 0 && foundIndex < members.length) {
              newMembers[foundIndex] = {
                ...newMembers[foundIndex],
                isBanned: variables.isBanned,
              };
            }
            return newMembers;
          }
        }
      );
    },
  });

  const updateMemberApprovalStatusMutation = useMutation(
    updateMemberApprovalStatus,
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData(
          ["fetchCommunityMembers", { companyId: company.id }],
          (members) => {
            if (members && members.length > 0) {
              const foundIndex = members.findIndex(
                (elem) => elem.id === variables.memberId
              );
              let newMembers = [...members];
              if (foundIndex >= 0 && foundIndex < members.length) {
                newMembers[foundIndex] = {
                  ...newMembers[foundIndex],
                  approvalStatus: variables.approvalStatus,
                };
              }
              return newMembers;
            }
          }
        );
      },
    }
  );

  const handleBanCommmunityMember = (memberId, isBanned) => {
    let confirmMessage = "";
    if (isBanned)
      confirmMessage =
        "Are you sure you want to Reinstate this member in your community?";
    else
      confirmMessage =
        "Are you sure you want to ban this member in your community?";

    if (window.confirm(confirmMessage)) {
      banUserFromCommunityMutation.mutate({
        companyId: company.id,
        memberId: memberId,
        isBanned: !isBanned,
      });
    }
  };

  const handleRemoveCommunityMember = (memberId) => {
    if (window.confirm("Are you sure you want to remove this member?")) {
      removeUserFromCommunityMutation.mutate({
        companyId: company.id,
        memberId: memberId,
      });
    }
  };

  const headerCells = [
    {
      id: "id",
      label: "id",
      hide: true,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "member",
      label: "Member",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const name1 =
          param1 && param1.profile && param1.profile.firstName
            ? param1.profile.firstName
            : param1 && param1.user && param1.user.userName
            ? param1.user.userName
            : "";
        const name2 =
          param2 && param2.profile && param2.profile.firstName
            ? param2.profile.firstName
            : param2 && param2.user && param2.user.userName
            ? param2.user.userName
            : "";

        return name1.localeCompare(name2);
      },
    },
    {
      id: "email",
      label: "Email address",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const email1 =
          param1 && param1.user && param1.user.email ? param1.user.email : "";
        const email2 =
          param2 && param2.user && param2.user.email ? param2.user.email : "";

        return email1.localeCompare(email2);
      },
    },
    {
      id: "joinedDateTime",
      label: "Joined Date & Time",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        return (param1?.joinedDateTime || "").localeCompare(
          param2?.joinedDateTime || ""
        );
      },
    },
    {
      id: "approvalStatus",
      label: "Approval Status",
      //hide: !(param1.approvalStatus && company.doMembersNeedApproval),
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        return param1?.approvalStatus > param2?.approvalStatus;
      },
    },
    {
      id: "actions",
      label: "Actions",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
  ];

  const columns = [
    {
      field: "id",
      renderCell: (data) => {
        return data.id;
      },
    },
    {
      field: "member",
      renderCell: (member) => {
        const memberUser = member.user;

        return (
          <ProfilePopover userId={memberUser && memberUser.id}>
            <MemberInfo member={member} />
          </ProfilePopover>
        );
      },
    },
    {
      field: "email",
      renderCell: (data) => {
        const email = data && data.user && data.user.email;
        return (
          <Link
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            color="textPrimary"
            href={`mailto:${email}`}
          >
            {email}
          </Link>
        );
      },
    },
    {
      field: "joinedDateTime",
      renderCell: (data) => {
        const joinedDateTime = new Date(data.joinedDateTime);
        return convertUTCTimeToZonedTime(
          joinedDateTime,
          DEFAULT_TIME_ZONE,
          "MMM d, yyyy h:mmaaa zzz"
        );
      },
    },
    {
      field: "approvalStatus",
      renderCell: (data) => {
        return (
          <OrgCommunityMemberStatus
            companyId={companyId}
            data={data}
            updateMemberApprovalStatusMutation={
              updateMemberApprovalStatusMutation
            }
          />
        );
      },
    },
    {
      field: "actions",
      renderCell: (data) => {
        let isCurrentUserOrgUser = false;
        if (company && company.team && company.team.length > 0) {
          const indx = company.team.findIndex(
            (elem) => elem.id !== currentUser.id
          );
          if (indx >= 0) isCurrentUserOrgUser = true;
        }
        return (
          <>
            {isCurrentUserOrgUser && currentUser.id !== data.userAccountId && (
              <>
                <Button
                  startIcon={!data.isBanned ? <BlockIcon /> : ""}
                  variant="text"
                  color={data.isBanned ? "primary" : "inherit"}
                  onClick={() =>
                    handleBanCommmunityMember(data.id, data.isBanned)
                  }
                >
                  {data.isBanned ? "Reinstate" : "Ban"}
                </Button>

                <Button
                  startIcon={<DeleteOutlinedIcon />}
                  variant="text"
                  color="default"
                  onClick={() => handleRemoveCommunityMember(data.id)}
                >
                  Remove
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Box>
      {isMemberLoading ? (
        <Spinner />
      ) : isMemberError ? (
        <Alert severity="error">
          {communityMemberError &&
            communityMemberError.data &&
            communityMemberError.data.msg}
        </Alert>
      ) : data && data.length > 0 ? (
        <PTable
          records={data}
          headerCells={headerCells}
          columns={columns}
          noRowsText="There are no members in your community at this time."
          isLoading={isMemberFetching || isMemberLoading}
        />
      ) : (
        <EmptyState
          title="No members have joined yet"
          subtitle="Manage all of your community members in one place."
          link="https://www.notion.so/Managing-members-20a7d241fa5d4fd7a7c818d8ad645346"
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.companyProfile,
  currentUser: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgCommunityMemberTable);
