import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  shape: {
    borderRadius: 5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shadows: [
    "none",
    `0 0 0 1px rgba(0, 0, 0, 0.05)`,
    `0 1px 2px 0 rgba(0, 0, 0, 0.05)`,
    `0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
    `0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)`,
    `0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)`,
    `0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)`,
    `0 25px 50px -12px rgba(0, 0, 0, 0.25)`,
    `0 25px 50px -12px rgba(0, 0, 0, 0.3)`,
    `0 25px 50px -12px rgba(0, 0, 0, 0.35)`,
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
});
theme = createMuiTheme({
  ...theme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*:root": {
          colorScheme: "dark",
        },
        "*::-webkit-scrollbar": {
          width: "0.5em",
        },
        "*::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          margin: theme.spacing(0.5),
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(122, 124, 126)",
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiLink: {
      root: {
        textDecoration: "none",
        cursor: "pointer",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "0.875rem",
        fontWeight: 600,
        marginBottom: theme.spacing(1),
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.875rem",
      },
    },

    MuiChip: {
      root: {
        margin: "2px",
        fontSize: 13,
        textOverflow: "wrap",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: "1.25rem",
      },
    },
    MuiSkeleton: {
      root: {
        borderRadius: theme.shape.borderRadius,
      },
    },
    MuiIconButton: {
      root: {
        "&:focus": {
          outline: "none",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButtonBase: {
      root: {
        "&:active": {
          transform: "scale(0.99)",
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: "0.9rem",
        lineHeight: 1.2,
        fontWeight: 600,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        textTransform: "capitalize",
        "&:focus": {
          outline: "none",
        },
      },
      sizeSmall: {
        fontSize: "0.8rem",
        paddingRight: theme.spacing(1.75),
        paddingLeft: theme.spacing(1.75),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      sizeLarge: {
        fontSize: "1rem",
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "14px",
        minWidth: "200px",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 600,
        lineHeight: 1.2,
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
        marginBottom: theme.spacing(2),
      },
    },
    MuiTabs: {
      root: {
        borderBottom: "1px solid rgba(232, 232, 232, 0.6)",
      },
    },
    MuiTab: {
      root: {
        fontSize: "0.85rem",
        fontWeight: 600,
        textTransform: "capitalize",
        minWidth: "20px !important",
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 14,
        textAlign: "center",
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: theme.shadows[0],
        borderRadius: theme.shape.borderRadius,
        "&:before": {
          display: "none",
        },
        "&$expanded": {
          margin: "auto",
        },
        "&:hover": {
          boxShadow: theme.shadows[0],
          transition: "ease-in-out 0.2s",
        },
        transition: "ease-in-out 0.2s",
      },
      expanded: {},
    },
    MuiAccordionSummary: {
      root: {
        marginBottom: -1,
        minHeight: 56,
        borderRadius: theme.shape.borderRadius,
        "&$expanded": {
          minHeight: 56,
        },
      },
      content: {
        "&$expanded": {
          margin: "12px 0",
        },
      },
      expanded: {},
    },
    MuiAccordionDetails: {
      root: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: 1.2,
        marginTop: theme.spacing(0.5),
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 4,
    },
    MuiDivider: {
      light: true,
    },
    MuiDialog: {
      scroll: "body",
      PaperProps: {
        elevation: 9,
        square: false,
      },
    },
    MuiMenuItem: {
      dense: false,
    },
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
      disableFocusRipple: true,
      color: "primary",
      variant: "contained",
      size: "small",
    },
    MuiButtonGroup: {
      disableElevation: true,
      disableRipple: true,
      disableFocusRipple: true,
      color: "primary",
      variant: "contained",
      size: "small",
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiIconButton: {
      disableFocusRipple: true,
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiChip: {
      size: "small",
      color: "default",
    },
    MuiTextField: {
      // size: "small",
      variant: "outlined",
    },
    MuiInput: {
      // margin: "dense",
      variant: "outlined",
      // size: "small",
    },
    MuiAppBar: {
      color: "transparent",
      position: "static",
    },
    MuiSwitch: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
    MuiTabs: {
      indicatorColor: "primary",
      orientation: "horizontal",
    },
    MuiTab: {
      disableFocusRipple: true,
      disableRipple: true,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      "Source Sans Pro",
      "system-ui",
      "-apple-system",
      "Nunito",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

theme = responsiveFontSizes(theme);

export const BASE_COLORS = {
  type: "dark",
  primary: {
    main: "#a749f6",
    light: "#bb5dff",
  },
  dark: {
    main: "#151e39",
  },
  secondary: {
    main: "#ffc229",
  },
  info: {
    light: "#468af7",
    main: "#2c79f5",
    dark: "#186df5",
  },
};

export const LIGHT_COLORS = {
  type: "light",
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
  },
  background: {
    default: "#fff",
    paper: "#f6f6f6",
  },
};

export const ASH_COLORS = {
  type: "light",
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
  },
  background: {
    default: "#fff",
    paper: "#edeef3",
  },
};

export const DARK_COLORS = {
  type: "dark",
  text: {
    primary: "#fff",
  },
  background: {
    default: "#242424",
    paper: "#201e24",
  },
};

export const MIDNIGHT_COLORS = {
  type: "dark",
  text: {
    primary: "#fff",
  },
  background: {
    default: "#0f0f10",
    paper: "#18181a",
  },
  primary: {
    main: "#c46568",
  },
};

export const PALE_COLORS = {
  type: "dark",
  text: {
    primary: "#fff",
  },
  background: {
    default: "#2a2d3d",
    paper: "#303445",
  },
  primary: {
    main: "#7659bb",
  },
};

export const DAWN_COLORS = {
  type: "dark",
  text: {
    primary: "#fff",
  },
  background: {
    default: "#29222d",
    paper: "#312936",
  },
  primary: {
    main: "#9c4974",
  },
};

export default theme;
