import FileWidget from "./components/FileWidget";
import TextWidget from "./components/TextWidget";
import EmailWidget from "./components/EmailWidget";
import URLWidget from "./components/URLWidget";
import TextareaWidget from "./components/TextareaWidget";
import SelectWidget from "./components/SelectWidget";
import CheckboxesWidget from "./components/CheckboxesWidget";
import RadioWidget from "./components/RadioWidget";
import ColorWidget from "./components/ColorWidget";
import DateWidget from "./components/DateWidget";
import DateTimeWidget from "./components/DateTimeWidget";

export default {
  FileWidget: FileWidget,
  TextWidget: TextWidget,
  EmailWidget: EmailWidget,
  URLWidget: URLWidget,
  TextareaWidget: TextareaWidget,
  SelectWidget: SelectWidget,
  CheckboxesWidget: CheckboxesWidget,
  RadioWidget: RadioWidget,
  ColorWidget: ColorWidget,
  DateWidget: DateWidget,
  DateTimeWidget: DateTimeWidget,
};
