import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchAllJudgingCriterias,
  deleteCriteria,
} from "../../../redux/actions/judge";
// Material UI
import Tooltip from "@material-ui/core/Tooltip";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// Components
import ChallengeCriteriaDialog from "./ChallengeCriteriaDialog";
import OrgAppBar from "../../../shared/OrgAppBar";
import Spinner from "../../../shared/Spinner";
import Button from "../../../shared/Button";
import LinkToHelp from "../../../shared/LinkToHelp";

const CriteriasActions = () => {
  const [openCriteriaDialog, setOpenCriteriaDialog] = useState(false);
  return (
    <>
      <Tooltip title={"Add a new criteria"} placement="top">
        <div>
          <Button onClick={() => setOpenCriteriaDialog(true)}>
            Add New Criteria
          </Button>
        </div>
      </Tooltip>

      <ChallengeCriteriaDialog
        criteria={null}
        open={openCriteriaDialog}
        setOpen={setOpenCriteriaDialog}
      />
    </>
  );
};

export const CriteriasView = ({
  criterias,
  challenge,
  currentRoundIndex,
  loading,
  fetchAllJudgingCriterias,
  deleteCriteria,
}) => {
  const fetchAllCriteriasForChallengeRound = async () => {
    if (challenge) {
      await fetchAllJudgingCriterias(challenge.round[currentRoundIndex].id);
    }
  };
  useEffect(() => {
    fetchAllCriteriasForChallengeRound();
  }, [currentRoundIndex]);

  const [openCriteriaDialog, setOpenCriteriaDialog] = useState(false);
  const [editCriteria, setEditCriteria] = useState(null);
  const handleEditCriteriaModal = (index) => {
    setEditCriteria(criterias[index]);
    setOpenCriteriaDialog(true);
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ChallengeCriteriaDialog
            criteria={editCriteria}
            open={openCriteriaDialog}
            setOpen={setOpenCriteriaDialog}
          />
          <Box px={[1, 2]}>
            <OrgAppBar
              title={null}
              subtitle={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" component="div">
                    <Box mr={1}>
                      {(criterias && criterias.length) || 0} Criteria(s)
                    </Box>
                  </Typography>
                  <LinkToHelp
                    label="Criteria 101"
                    link="https://www.notion.so/Review-c611ae7c790c4c70aa76e92f50e41128#e547c40301494e2082ea8a507235363f"
                  />
                </Box>
              }
              actions={<CriteriasActions />}
            />
          </Box>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Edit</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Maximum Score</TableCell>
                    <TableCell>Feedback Required</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {criterias &&
                    criterias.length > 0 &&
                    criterias.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <IconButton
                              onClick={() => handleEditCriteriaModal(index)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>{item.maxScore}</TableCell>
                          <TableCell>
                            <Chip
                              label={item.isRequired ? "Yes" : "No"}
                              variant={item.isRequired ? "default" : "outlined"}
                              color={"default"}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => deleteCriteria(item.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

CriteriasView.propTypes = {
  criterias: PropTypes.array,
  challenge: PropTypes.object,
  currentRoundIndex: PropTypes.number,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  criterias: state.judge.criterias,
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  loading: state.challenge.loading,
});

const mapDispatchToProps = {
  fetchAllJudgingCriterias,
  deleteCriteria,
};

export default connect(mapStateToProps, mapDispatchToProps)(CriteriasView);
