const Privacy = () => {
  return (
    <>
      <div className="relative bg-white overflow-hidden py-16 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto mt-6 prose prose-primary prose-lg text-gray-500 max-w-prose mx-auto">
            <h1 align="center">Credits</h1>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.freepik.com/free-photos-vectors/business"
            >
              <p>Business vector created by katemangostar - www.freepik.com</p>
            </a>
            <h5>Icons</h5>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://thenounproject.com/search/?q=design&i=1724569"
            >
              <p>design by Trevor Dsouza from the Noun Project</p>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://thenounproject.com/search/?q=business&i=1984285"
            >
              <p>business bag by Adi Kurniawan from the Noun Project</p>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://thenounproject.com/search/?q=machine%20learning&i=2010152"
            >
              <p> Machine Learning by Angela from the Noun Project</p>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://thenounproject.com/search/?q=machine%20learning&i=2010152"
            >
              <p>Engineering by Symbolon from the Noun Project</p>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://thenounproject.com/search/?q=web%20development&i=1733716"
            >
              <p>web development by b farias from the Noun Project</p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
