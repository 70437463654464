import { Link } from "react-router-dom";

const InfoSection = ({
  title,
  subtitle,
  image,
  icon: Icon,
  topText,
  learnMoreText,
  learnMoreUrl,
  textLeft = true,
  bggray = false,
  color = "primary",
  secondaryColor = "primary",
}) => {
  const TEXTCSS = textLeft ? "lg:col-start-1" : "lg:col-start-4";
  const IMAGECSS = textLeft ? "lg:col-start-3" : "lg:col-start-1";

  return (
    <div className={`py-6 md:py-12  ${bggray ? "bg-gray-100" : "bg-gray-50"}`}>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:items-center lg:gap-24">
        <div
          className={`px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 col-span-2 ${TEXTCSS}`}
        >
          {Icon && (
            <div
              className={`flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-br from-${color}-500 to-${secondaryColor}-500 text-white mb-4`}
            >
              <Icon className="h-6 w-6" aria-hidden="true" />
            </div>
          )}
          <div>
            <div>
              {topText && (
                <p
                  className={`text-sm md:text-base font-semibold uppercase text-${color}-500 mb-2`}
                >
                  {topText}
                </p>
              )}
              <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold tracking-tight text-gray-900">
                {title}
              </h2>
              <p className="mt-4 text-lg text-gray-500">{subtitle}</p>
              {learnMoreText && (
                <div className="mt-4 md:mt-8">
                  <Link
                    to={learnMoreUrl}
                    className={`px-3 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-${color}-500 bg-opacity-90 hover:bg-opacity-80 sm:px-8`}
                  >
                    {learnMoreText}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`mt-12 sm:mt-16 lg:mt-0 col-span-3 ${IMAGECSS}`}>
          <img
            className="w-full rounded-lg shadow-2xl"
            src={image}
            alt={title}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
