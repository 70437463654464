import React, { useState } from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const NumberTitleChart = ({
  // Object will be mutated has 3 attributes (none required), name, subtext, count
  data,
  isProportion,
  orientation = "row",
}) => {
  return (
    <Box
      display="flex"
      flexDirection={orientation}
      alignItems="flex-start"
      justifyContent="space-between"
      height="100%"
      width="100%"
    >
      {data.map((stat, index) => {
        if (isNaN(stat.count)) return null;
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            p={2}
            key={index}
          >
            {stat.subText && (
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                gutterBottom
              >
                <Box lineHeight={1.2}>{stat.subText}</Box>
              </Typography>
            )}
            <Box>
              <Typography variant="h3" component="div">
                <Box lineHeight={1.2} fontWeight={600}>
                  {isProportion
                    ? `${((stat.count / stat.total) * 100).toFixed(0)}%`
                    : `${stat.count}`}
                </Box>
              </Typography>
            </Box>
            <Typography variant="body2" component="div" gutterBottom>
              <Box fontWeight={600} lineHeight={1.2}>
                {stat.name}
              </Box>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default NumberTitleChart;
