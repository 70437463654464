import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Button from "../Button";

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: "10px",
  },
  QA: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  button: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  answersDiv: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  question: {
    marginBottom: "15px",
  },
}));

const InterestsForm = ({ myprofile, typedef, state, setState }) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleListChange = (input, value, max = 5) => {
    const index = state.findIndex((elem) => elem.questionId === input);
    if (index < 0 || index >= state.length) return;
    if (state[index].answers.includes(value)) {
      const prevState = [...state];
      prevState[index].answers = state[index].answers.filter(
        (el) => el !== value
      );
      setState(prevState);
    } else if (state[index].answers.length >= max) {
      const array = [...state[index].answers];
      array.pop();
      array.push(value);
      const prevState = [...state];
      prevState[index].answers = array;
      setState(prevState);
    } else {
      const prevState = [...state];
      prevState[index].answers = [...prevState[index].answers, value];
      setState(prevState);
    }
  };
  useEffect(() => {
    if (
      typedef &&
      typedef.questionsAnswers &&
      typedef.questionsAnswers.length > 0
    ) {
      const prevState = [];
      typedef.questionsAnswers.map((QA) => {
        const stateQAFound =
          state &&
          state.length > 0 &&
          state.find((elem) => elem.questionId == QA.id);

        const profileQAFound =
          myprofile &&
          myprofile.questionsAnswers &&
          myprofile.questionsAnswers.length > 0 &&
          myprofile.questionsAnswers.find((elem) => elem.questionId === QA.id);

        return prevState.push({
          id: stateQAFound
            ? stateQAFound.id
            : profileQAFound
            ? profileQAFound.id
            : undefined,
          questionId: QA.id,
          answers: stateQAFound
            ? stateQAFound.answers
            : profileQAFound
            ? profileQAFound.answers
            : [],
          query: "",
          more: false,
        });
      });
      setState(prevState);
    }
  }, [myprofile && myprofile.questionsAnswers]);

  const onQueryChange = (e) => {
    let prevState = [...state];
    prevState[e.target.dataset.id].query = e.target.value;
    setState(prevState);
  };

  const onMoreChange = (index) => {
    let prevState = [...state];
    prevState[index].more = !prevState[index].more;
    setState(prevState);
  };

  return (
    <Fragment>
      <div>
        <Box>
          <Grid container spacing={2}>
            {typedef &&
              typedef.questionsAnswers &&
              typedef.questionsAnswers.length > 0 &&
              state &&
              state.length > 0 && (
                <Fragment>
                  {typedef.questionsAnswers.map((QA) => {
                    const id = QA.id;
                    const index = state.findIndex(
                      (elem) => elem.questionId === QA.id
                    );
                    if (index === -1) return;
                    const description = QA.description;
                    const count = QA.questionAnswer.length;
                    const more = state[index].more;
                    const slice =
                      count < 20 ? 20 : more ? count : fullScreen ? 15 : 30;
                    const answers = QA.questionAnswer.filter(
                      (el) =>
                        el.answer
                          .toLowerCase()
                          .indexOf(state[index].query.toLowerCase()) !== -1
                    );
                    const queryVal = state[index].query;
                    return (
                      <Grid item xs={12} key={id} className={classes.QA}>
                        <Typography gutterBottom variant="h6" component="div">
                          <Box fontWeight={600}>{description}</Box>
                        </Typography>

                        {count >= 20 && (
                          <Fragment>
                            {state[index].answers.map((id, index) => {
                              return (
                                <Chip
                                  key={index}
                                  size={"small"}
                                  className={classes.chip}
                                  label={
                                    QA.questionAnswer.find(
                                      (elem) => elem.id === id
                                    ).answer
                                  }
                                />
                              );
                            })}
                            <div>
                              <TextField
                                fullWidth
                                variant={"outlined"}
                                placeholder={"Search ..."}
                                type="text"
                                name={`search-${id}`}
                                inputProps={{ "data-id": index }}
                                value={queryVal}
                                className={classes.input}
                                onChange={(e) => onQueryChange(e)}
                              />
                            </div>
                          </Fragment>
                        )}
                        <div>
                          {answers &&
                            answers.length > 0 &&
                            answers.slice(0, slice).map((a) => {
                              return (
                                <Button
                                  key={a.id}
                                  onClick={() =>
                                    handleListChange(
                                      QA.id,
                                      a.id,
                                      QA.maxNumChoices
                                    )
                                  }
                                  variant={
                                    state[index].answers.includes(a.id)
                                      ? "contained"
                                      : "outlined"
                                  }
                                  className={classes.button}
                                >
                                  {a.answer}
                                </Button>
                              );
                            })}
                          {answers.length >= slice && (
                            <Typography
                              variant={"body1"}
                              color={"primary"}
                              onClick={() => onMoreChange(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {more ? (
                                <Fragment>Less</Fragment>
                              ) : (
                                <Fragment>
                                  More <span>&#8230;</span>
                                </Fragment>
                              )}
                            </Typography>
                          )}
                        </div>
                      </Grid>
                    );
                  })}
                </Fragment>
              )}
          </Grid>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  typedef: state.typedef,
  myprofile: state.profile.myprofile,
});

export default connect(mapStateToProps)(InterestsForm);
