import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createCompanyProfile } from "../../../redux/actions/company";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
// Components
import Button from "../../../shared/Button";
// Icons
import {
  IoGlobeOutline,
  IoLogoLinkedin,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from "react-icons/io5";

const OrgProfileSocials = ({
  createCompanyProfile,
  companyProfile,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    companyWebsiteUrl: "",
    linkedinUrl: "",
    facebookUrl: "",
    instagramUrl: "",
    twitterUrl: "",
  });

  useEffect(() => {
    if (companyProfile) {
      setFormData({
        id: companyProfile.id,
        companyWebsiteUrl: companyProfile.companyWebsiteUrl || "",
        linkedinUrl: companyProfile.linkedinUrl || "",
        instagramUrl: companyProfile.instagramUrl || "",
        facebookUrl: companyProfile.facebookUrl || "",
        twitterUrl: companyProfile.twitterUrl || "",
      });
    }
  }, [companyProfile]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (companyProfile && companyProfile.id) {
      formData.id = companyProfile.id;
      formData.orgId = companyProfile.orgId;
    }
    await createCompanyProfile(formData);
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const {
    companyWebsiteUrl,
    linkedinUrl,
    facebookUrl,
    instagramUrl,
    twitterUrl,
  } = formData;

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            <Box fontWeight={600}>Socials</Box>
          </Typography>
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>
              Make your community social links available for people to view.
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoGlobeOutline />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>Website Url</InputLabel>
                <TextField
                  placeholder="Enter your website url"
                  type="url"
                  onChange={handleChange}
                  name="companyWebsiteUrl"
                  value={companyWebsiteUrl}
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoLogoLinkedin />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>LinkedIn Url</InputLabel>
                <TextField
                  placeholder="Enter your LinkedIn url"
                  type="url"
                  onChange={handleChange}
                  name="linkedinUrl"
                  value={linkedinUrl}
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoLogoInstagram />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>Instagram Url</InputLabel>
                <TextField
                  placeholder="Enter your Instagram url"
                  type="url"
                  onChange={handleChange}
                  name="instagramUrl"
                  value={instagramUrl}
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoLogoFacebook />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>Facebook Url</InputLabel>
                <TextField
                  placeholder="Enter your Facebook url"
                  type="url"
                  onChange={handleChange}
                  name="facebookUrl"
                  value={facebookUrl}
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoLogoTwitter />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>Twitter Url</InputLabel>
                <TextField
                  placeholder="Enter your Twitter url"
                  type="url"
                  onChange={handleChange}
                  name="twitterUrl"
                  value={twitterUrl}
                />
              </FormGroup>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  createCompanyProfile,
  setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgProfileSocials);
