import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { getChallenge } from "../../redux/actions/challenge";
import { getAllGroupsForRound } from "../../redux/actions/group";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Alert, AlertTitle } from "@material-ui/lab";
// Components
import Spinner from "../../shared/Spinner";
import Button from "../../shared/Button";
// Utils
import axios from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  FormControl: {
    width: "100%",
    maxWidth: 525,
  },
}));

export const JudgeSignUp = ({
  loading,
  challenge,
  groups,
  getChallenge,
  getAllGroupsForRound,
  setAlert,
}) => {
  const params = useParams();
  const classes = useStyles();

  const [currentRound, setCurrentRound] = useState(null);
  const [challengeRoundId, setChallengeRoundId] = useState(null);

  useEffect(() => {
    if (params) {
      setChallengeRoundId(params.challengeRoundId);
      getAllGroupsForRound(params.challengeRoundId);
      getChallenge(params.slug);
    }
  }, [params]);

  useEffect(() => {
    if (challenge && challenge.round && challengeRoundId) {
      let foundRound = challenge.round.find(
        (elem) => elem.id === challengeRoundId
      );
      setCurrentRound(foundRound);
    }
  }, [challengeRoundId, challenge]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    groups: [],
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const body = {
        challengeId: challenge.id,
        challengeRoundId: challengeRoundId,
        judgeInfo: {
          ...formData,
        },
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`/api/judge/signUpForm`, body, config);
      setAlert(res.data.msg, "success");
      setSubmitted(true);
    } catch (error) {
      setAlert(error.data.msg, "error");
    }
  };

  return (
    <Box bgcolor="background.main" height="100vh">
      <Container maxWidth="sm">
        {loading ? (
          <Spinner />
        ) : (
          <Box py={[2, 4, 8]}>
            {challenge ? (
              <Card elevation={4}>
                <CardContent>
                  {submitted && (
                    <Box mb={[1, 2]}>
                      <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        Your information has been added as a reviewer for this
                        round.
                      </Alert>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box textAlign="left" mb={[2, 4]} width="100%">
                      <Typography component="h1" variant="h6">
                        <strong>Reviewer Sign Up Form</strong>
                      </Typography>

                      <Typography component="p" variant="subtitle1">
                        {challenge.title}{" "}
                        {currentRound && ` | ${currentRound.name}`}
                      </Typography>

                      <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                      >
                        Use this form to signup as a reviewer for this program
                        round. After signing up, the organizers will have access
                        to this information.
                      </Typography>
                    </Box>

                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <InputLabel required>First Name</InputLabel>
                            <TextField
                              autoComplete="fname"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleChange}
                              required
                              fullWidth
                              id="firstName"
                              autoFocus
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormGroup>
                            <InputLabel required>Last Name</InputLabel>
                            <TextField
                              autoComplete="lname"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleChange}
                              required
                              fullWidth
                              id="lastName"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup>
                            <InputLabel required>Email Address</InputLabel>
                            <TextField
                              autoComplete="email"
                              name="emailAddress"
                              value={formData.emailAddress}
                              onChange={handleChange}
                              required
                              fullWidth
                              id="emailAddress"
                            />
                          </FormGroup>
                        </Grid>
                        {groups.data && groups.data.length > 0 && (
                          <Grid item xs={12}>
                            <InputLabel>Select Your Groups</InputLabel>
                            <FormControl
                              variant="outlined"
                              className={classes.FormControl}
                            >
                              <FormGroup>
                                <Select
                                  id="groups"
                                  name="groups"
                                  multiple
                                  value={formData.groups}
                                  onChange={handleChange}
                                  margin="dense"
                                >
                                  {groups.data.map((g, index) => {
                                    return (
                                      <MenuItem value={g.id} key={index}>
                                        {g.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        )}
                      </Grid>
                      <Box mt={[2, 4]}>
                        <Button type="submit" fullWidth size="medium">
                          Sign Up
                        </Button>
                      </Box>
                    </form>
                  </Box>
                </CardContent>
              </Card>
            ) : (
              <Box>
                <Typography>
                  Program not found. Please check if the url is correct.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

JudgeSignUp.propTypes = {
  loading: PropTypes.bool,
  challenge: PropTypes.object,
  groups: PropTypes.object,
  getChallenge: PropTypes.func,
  getAllGroupsForRound: PropTypes.func,
  setAlert: PropTypes.func,
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  challenge: state.challenge.challenge,
  groups: state.group.groups,
});

const mapDispatchToProps = { getChallenge, getAllGroupsForRound, setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(JudgeSignUp);
