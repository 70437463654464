import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateOrgId,
  getCompanyProfile,
  clearCompany,
} from "../../redux/actions/company";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// Components
import Spinner from "../../shared/Spinner";
import OrgSidebar from "./components/OrgSidebar";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  content: {
    width: `calc(100% - ${drawerWidth}px)`,
    flexGrow: 1,
  },
}));

const OrgDashboard = ({
  updateOrgId,
  companyProfile,
  getCompanyProfile,
  clearCompany,
  orgId,
  children,
}) => {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    updateOrgId(params?.orgId); // call redux action that will update a redux state that will set the orgId
  }, [params?.orgId]);

  const fetchCompanyProfile = async (orgId) => {
    await getCompanyProfile(orgId);
  };

  useEffect(() => {
    if (orgId) {
      fetchCompanyProfile(orgId);
    }
  }, [orgId]);

  if (!orgId) {
    return <Spinner />;
  }

  return (
    <>
      <Box>
        <Box display={{ xs: "block", sm: "none" }} p={2}>
          Please login to organization pages on a larger screen.
        </Box>
        <Box display={{ xs: "none", sm: "block" }} minHeight="100vh">
          <Box display="flex">
            <OrgSidebar />
            <Box className={classes.content}>
              <Box
                pt={{ xs: 1, md: 2, lg: 4 }}
                px={{ xs: 1, md: 2, lg: 4 }}
                pb={{ xs: 4, md: 8, lg: 10 }}
              >
                <Container maxWidth="xl">
                  {companyProfile && children}
                </Container>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  orgId: state.company.orgId,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { updateOrgId, getCompanyProfile, clearCompany };

export default connect(mapStateToProps, mapDispatchToProps)(OrgDashboard);
