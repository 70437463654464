import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createCompanyProfile } from "../../../redux/actions/company";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
// Material-ui-color
import { ColorPicker } from "material-ui-color";
// Components
import Button from "../../../shared/Button";
// Shared component
import { DEFAULT_PRIMARY_COLOR } from "../../../utils/globalValues";
// utils
import { useMutation } from "react-query";
// Hooks
import { uploadCompanyHeaderImage } from "../../../hooks/company";

const OrgProfileBranding = ({
  createCompanyProfile,
  companyProfile,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    primaryColor: DEFAULT_PRIMARY_COLOR,
    headerImageUrl: "",
  });

  useEffect(() => {
    if (companyProfile) {
      setFormData({
        id: companyProfile.id,
        primaryColor: companyProfile.primaryColor || DEFAULT_PRIMARY_COLOR,
        headerImageUrl: companyProfile.headerImageUrl || "",
      });
    }
  }, [companyProfile]);

  const uploadHeaderImageMutation = useMutation(uploadCompanyHeaderImage);

  useEffect(() => {
    if (uploadHeaderImageMutation.data && uploadHeaderImageMutation.data.data) {
      setFormData({
        ...formData,
        headerImageUrl: uploadHeaderImageMutation.data.data,
      });
    }
  }, [uploadHeaderImageMutation.data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (companyProfile && companyProfile.id) {
      formData.id = companyProfile.id;
      formData.orgId = companyProfile.orgId;
    }
    await createCompanyProfile(formData);
  };

  const handlePrimaryColorChange = (color) => {
    if (color && color.css && color.css.backgroundColor) {
      // Save the selected primary color to the local state
      setFormData({
        ...formData,
        primaryColor: color.css.backgroundColor,
      });
    }
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const onChangeHeaderImage = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 5242880) {
        const headerImageFormData = new FormData();
        headerImageFormData.append("myFile", e.target.files[0]);
        uploadHeaderImageMutation.mutate({
          companyId: companyProfile.id,
          headerImageFormData: headerImageFormData,
        });
      } else {
        setAlert("Maximum file size 5MB exceeded", "error");
      }
    }
  };

  const { headerImageUrl, primaryColor } = formData;

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            <Box fontWeight={600}>Branding</Box>
          </Typography>
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>Customize to match your organization's brand.</Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <ColorPicker
                  value={primaryColor}
                  deferred
                  hideTextfield
                  onChange={handlePrimaryColorChange}
                />
              }
              label={
                <Box ml={1}>
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight={600}>Choose a community theme color</Box>
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      We recommend choosing a darker and brighter primary color
                      for your community. This primary color will be used on all
                      pages across the entire organization.
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Header Image</InputLabel>

            <Box mb={2}>
              <Box
                style={{ objectFit: "cover" }}
                width="100%"
                height="100%"
                maxHeight={600}
                borderRadius="borderRadius"
                component="img"
                src={
                  headerImageUrl instanceof File
                    ? URL.createObjectURL(headerImageUrl)
                    : headerImageUrl ||
                      "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                }
              />
            </Box>
            <Box>
              <input
                accept=".png, .jpg, .jpeg, .gif, .svg"
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onChangeHeaderImage}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload New Image
                </Button>
              </label>
            </Box>
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  createCompanyProfile,
  setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgProfileBranding);
