import axios from "../utils/axios";
import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "react-query";
import store from "../redux/store/store";
import { updateReactionPosts } from "../redux/actions/room";

export const useGetInfinitePosts = (roomId, postId, isReply, limit) => {
  const result = useInfiniteQuery(
    ["posts", { isReply, roomId, postId, limit }],
    getInfinitePosts,
    {
      getNextPageParam: (lastPage, pages) => {
        const lastPageSize = (Object.keys(lastPage) || []).length;

        return lastPageSize < limit ? undefined : pages.length * limit;
      },
    }
  );
  return result;
};

export const getInfinitePosts = async ({ pageParam: offset = 0, queryKey }) => {
  const [_key, { isReply, roomId, postId, limit }] = queryKey;

  const res = await axios.get(
    `/api/room/posts/all?isReply=${isReply}&roomId=${roomId}&postId=${postId}&offset=${offset}&limit=${limit}`
  );

  return res.data;
};

export const addEditPost = async ({ roomId, postDetails }) => {
  const body = { roomId, postDetails };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/room/post`, body, config);
};

export const useGetPostById = (postId) => {
  const result = useQuery(
    [
      "roomPost",
      {
        postId: postId,
      },
    ],
    getPostById
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getPostById = async ({ queryKey }) => {
  const [_key, { postId }] = queryKey;
  return await axios.get(`/api/room/post/id/${postId}`);
};

export const addEditRoom = async ({ roomDetails }) => {
  const body = { roomDetails };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/room`, body, config);
};

export const joinRoomById = async ({ roomId }) => {
  const body = { roomId };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/room/join`, body, config);
};

export const useCheckUsersCanCreateRoom = (userIds) => {
  const result = useQuery(
    [
      "checkUsersRoom",
      {
        userIds: userIds,
      },
    ],
    checkUsersCanCreateRoom,
    {
      enabled: userIds && userIds.length > 0,
    }
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const checkUsersCanCreateRoom = async ({ queryKey }) => {
  const [_key, { userIds }] = queryKey;

  let searchParams = new URLSearchParams();
  userIds.map((userId) => searchParams.append("user", userId));

  const res = await axios.get(`/api/room/join/check?${searchParams}`);
  return res.data;
};

export const joinRoomByUserName = async ({ userName, roomId }) => {
  const body = { userName, roomId };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/room/join/userName`, body, config);
};

export const useJoinRoomByUserName = () => {
  return useMutation(joinRoomByUserName);
};

export const useGetAllRoomsForCommunity = (communityId) => {
  const result = useQuery(
    [
      "communityRooms",
      {
        communityId: communityId,
      },
    ],
    getAllRoomsForCommunity
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllRoomsForCommunity = async ({ queryKey }) => {
  const [_key, { communityId }] = queryKey;
  return await axios.get(`/api/room/all/${communityId}`);
};

export const useGetAllMyRoomsForCommunity = (communityId, userAccountId) => {
  const result = useQuery(
    [
      "myCommunityRooms",
      {
        communityId: communityId,
        userAccountId: userAccountId,
      },
    ],
    getAllMyRoomsForCommunity
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllMyRoomsForCommunity = async ({ queryKey }) => {
  const [_key, { communityId, userAccountId }] = queryKey;
  return await axios.get(`/api/room/all/${communityId}/my/${userAccountId}`);
};

export const useGetAllMyChatRooms = () => {
  const result = useQuery("myChatRooms", getAllMyChatRooms);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllMyChatRooms = async ({ queryKey }) => {
  const [_key] = queryKey;
  const res = await axios.get(`/api/room/chat/all`);
  return res.data;
};

export const useGetRoomBySlug = (roomSlug, communitySlug) => {
  const result = useQuery(
    [
      "room",
      {
        roomSlug: roomSlug,
        communitySlug: communitySlug,
      },
    ],
    getRoomBySlug
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getRoomBySlug = async ({ queryKey }) => {
  const [_key, { roomSlug, communitySlug }] = queryKey;
  return await axios.get(`/api/room/slug/${roomSlug}/${communitySlug}`);
};

export const useGetUserInRoomById = (roomId) => {
  const result = useQuery(
    [
      "userInRoom",
      {
        roomId: roomId,
      },
    ],
    getUserInRoomById,
    {
      enabled: !!roomId,
    }
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getUserInRoomById = async ({ queryKey }) => {
  const [_key, { roomId }] = queryKey;
  return await axios.get(`/api/room/id/${roomId}/user`);
};

export const useGetRoomById = (roomId) => {
  const result = useQuery(
    [
      "communityRoom",
      {
        roomId: roomId,
      },
    ],
    getRoomById
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getRoomById = async ({ queryKey }) => {
  const [_key, { roomId }] = queryKey;
  return await axios.get(`/api/room/id/${roomId}`);
};

export const leaveRoomById = async ({ roomId }) => {
  return await axios.del(`/api/room/leave/${roomId}`);
};

export const removeUserFromRoom = async ({ roomId, userAccountId }) => {
  return await axios.del(`/api/room/leave/${roomId}/${userAccountId}`);
};

export const useGetAllMembersInRoom = (roomId, onlyApproved) => {
  const result = useQuery(
    [
      "roomMembers",
      {
        roomId: roomId,
        onlyApproved: onlyApproved,
      },
    ],
    getAllMembersInRoom,
    {
      enabled: !!roomId,
    }
  );
  if (result.isError) {
    // console.log("Error");
  }

  return result;
};

export const getAllMembersInRoom = async ({ queryKey }) => {
  const [_key, { roomId, onlyApproved }] = queryKey;
  const res = await axios.get(
    `/api/room/members/all/${roomId}/${onlyApproved}`
  );
  return res.data;
};

export const updateUserRoomStatus = async ({
  roomId,
  userRoomId,
  approvalStatus,
}) => {
  const body = { roomId, userRoomId, approvalStatus };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/room/member/status`, body, config);
};

export const useAddReactionToPost = (roomId, parentPostId, isReply) => {
  const client = useQueryClient();
  return useMutation(addReactionToPost, {
    onSuccess: (updatedReaction) => {
      const id = isReply ? parentPostId : roomId;
      store.dispatch(updateReactionPosts(id, updatedReaction));
      // client.setQueryData(
      //   ["posts", { isReply: isReply, roomId: roomId, postId: parentPostId }],
      //   (oldPages) => {
      //     const foundPageIndex =
      //       oldPages &&
      //       oldPages.pages &&
      //       oldPages.pages.findIndex((page) => updatedReaction.postId in page);

      //     let newPages = { ...oldPages };

      //     if (foundPageIndex >= 0) {
      //       let postToUpdate =
      //         newPages.pages[foundPageIndex][updatedReaction.postId];
      //       let reactions = postToUpdate.reactions || {};

      //       reactions[updatedReaction.emoji] = {
      //         ...updatedReaction,
      //       };

      //       postToUpdate = {
      //         ...postToUpdate,
      //         reactions: reactions,
      //       };

      //       newPages.pages[foundPageIndex][updatedReaction.postId] = {
      //         ...postToUpdate,
      //       };
      //     }

      //     return newPages;
      //   }
      // );
    },
  });
};

export const addReactionToPost = async ({ postId, emoji }) => {
  const body = { postId, emoji };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.post(`/api/room/post/reaction`, body, config);
  return res.data;
};
