import React, { useState, useEffect } from "react";
// Redux
import { getAllGroupsForRound } from "../../../redux/actions/group";
import {
  fetchAllJudges,
  assignAllJudgesToAllSubmissions,
  assignJudgesToSubmissionsIntellassign,
  assignJudgesToSubmissionsByGroup,
  clearAllJudgeToSubmissionAssignments,
} from "../../../redux/actions/judge";
import { getSubmissions } from "../../../redux/actions/challenge";
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinkToHelp from "../../../shared/LinkToHelp";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
// Components
import Button from "../../../shared/Button";

import { JUDGE_ASSIGNMENT_TYPE_ID } from "../../../utils/globalValues";

const ALL = "ALL";
const INTELLASSIGN = "INTELLASSIGN";
const GROUP = "GROUP";

const EACH_SUBMISSION = "EACH_SUBMISSION";
const EACH_JUDGE = "EACH_JUDGE";

const useStyles = makeStyles((theme) => ({
  NumberInput: {
    maxWidth: 100,
  },
  GroupSelectFormControl: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}));

export const ChallengeAssignJudgeDialog = ({
  groups,
  getAllGroupsForRound,
  challenge,
  currentRoundIndex,
  fetchAllJudges,
  assignAllJudgesToAllSubmissions,
  assignJudgesToSubmissionsIntellassign,
  assignJudgesToSubmissionsByGroup,
  clearAllJudgeToSubmissionAssignments,
  getSubmissions,
}) => {
  const classes = useStyles();

  const [assigning, setAssigning] = useState(false); // true when assigning is happening in the backend

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    if (openDialog) {
      const currentRound = challenge.round[currentRoundIndex];
      getAllGroupsForRound(currentRound.id);
    }
    setAssigning(false);
  }, [openDialog, currentRoundIndex]);

  const [assignmentMethod, setAssignmentMethod] = useState(ALL);

  const handleAssignmentMethodChange = (e) => {
    setAssignmentMethod(e.target.value);
  };

  const [intellassignSettings, setIntellassignSettings] = useState({
    type: EACH_SUBMISSION,
    number: 0,
    useGroups: false,
    onlySubmitted: false,
  });

  const handleIntellassignSettingsChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setIntellassignSettings({
      ...intellassignSettings,
      [name]: value,
    });
  };

  const [groupSettings, setGroupSettings] = useState([]);

  const addGroupAssignment = () => {
    let newGroupAssignment = {
      submissionGroups: [],
      judgeGroups: [],
    };
    setGroupSettings([...groupSettings, newGroupAssignment]);
  };

  const handleGroupChange = (e, i) => {
    let newGroupSettings = groupSettings.map(
      (groupSetting, groupSettingIndex) => {
        if (groupSettingIndex === i) {
          return {
            ...groupSetting,
            [e.target.name]: e.target.value,
          };
        }

        return groupSetting;
      }
    );
    setGroupSettings(newGroupSettings);
  };

  const cancelGroupAssignment = (index) => {
    let newGroupSettings = groupSettings.filter((_, i) => i !== index);
    setGroupSettings(newGroupSettings);
  };

  const handleAssign = async () => {
    try {
      if (
        window.confirm(
          "Are you sure you want to proceed with the assignment configuration?"
        )
      ) {
        await setAssigning(true);
        const currentRound = challenge.round[currentRoundIndex];
        switch (assignmentMethod) {
          case ALL:
            await assignAllJudgesToAllSubmissions(currentRound.id);
            break;
          case INTELLASSIGN:
            const { number, useGroups, onlySubmitted } = intellassignSettings;
            const type =
              intellassignSettings.type === EACH_SUBMISSION
                ? JUDGE_ASSIGNMENT_TYPE_ID.ASSIGN_JUDGES_TO_SUBMISSION
                : JUDGE_ASSIGNMENT_TYPE_ID.ASSIGN_SUBMISSION_TO_JUDGES;
            const newSettings = {
              number,
              type,
              useGroups,
              onlySubmitted,
            };
            await assignJudgesToSubmissionsIntellassign(
              currentRound.id,
              newSettings
            );
            break;
          case GROUP:
            await assignJudgesToSubmissionsByGroup(
              currentRound.id,
              groupSettings
            );
            break;
          default:
            break;
        }
        await getSubmissions(challenge.id, currentRound.id);
        await fetchAllJudges(currentRound.id);
        await setAssigning(false);
      }
    } catch (error) {
      await setAssigning(false);
    }
  };

  const handleClear = async () => {
    if (
      window.confirm(
        "Are you sure you would like to remove all the reviewer to submission assignments for this round?"
      )
    ) {
      await setAssigning(true);
      const currentRound = challenge.round[currentRoundIndex];
      await clearAllJudgeToSubmissionAssignments(currentRound.id);
      await getSubmissions(challenge.id, currentRound.id);
      await fetchAllJudges(currentRound.id);
      await setAssigning(false);
    }
  };

  return (
    <>
      <Tooltip
        title="Assign reviewers to submissions for this round to provide scores and feedback."
        placement="top"
      >
        <Button onClick={handleOpen}>Assign Reviewers</Button>
      </Tooltip>
      <Backdrop className={classes.backdrop} open={assigning}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        aria-labelledby="assign-reviewers-submissions-dialog"
        fullWidth={true}
        maxWidth="md"
        open={openDialog}
        onClose={handleClose}
      >
        <DialogTitle id="assign-reviewers-submissions-dialog">
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="div">
              <Box mr={1}>Assign Reviewers to Submissions</Box>
            </Typography>
            <LinkToHelp link="https://www.notion.so/Submissions-badd1ba1036149508dd1b2256faccbbe#06cff124b1ff415dadc21ed0f934b03f" />
          </Box>
        </DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Select method of assignment
            </FormLabel>
            <RadioGroup
              aria-label="assignment-method"
              name="assignment-method"
              value={assignmentMethod}
              onChange={handleAssignmentMethodChange}
            >
              <FormControlLabel
                value={ALL}
                control={<Radio color="primary" />}
                label="Assign all reviewers to all submissions"
              />
              <FormControlLabel
                value={GROUP}
                control={<Radio color="primary" />}
                label="Assign by reviewer and submission groups"
              />
              <FormControlLabel
                value={INTELLASSIGN}
                control={<Radio color="primary" />}
                label={
                  <Box>
                    Assign reviewers to submissions intelligently{" "}
                    <Chip label="INTELLASSIGN" />
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <Box my={[2, 4]}>
            {assignmentMethod === INTELLASSIGN ? (
              <Box>
                <FormControl component="div">
                  <FormLabel component="legend">
                    <Typography>Select the method of intellassign</Typography>
                    <Typography variant="caption">
                      Note: All previous assignments{" "}
                      <strong>
                        <u>will be</u>
                      </strong>{" "}
                      removed.
                    </Typography>
                  </FormLabel>

                  <RadioGroup
                    aria-label="intellassign-method"
                    name="type"
                    value={intellassignSettings.type}
                    onChange={handleIntellassignSettingsChange}
                  >
                    <FormControlLabel
                      value={EACH_SUBMISSION}
                      control={<Radio color="primary" />}
                      label={
                        <Box>
                          Assign{" "}
                          <TextField
                            inputProps={{
                              min: 0,
                              style: { textAlign: "center" },
                            }}
                            className={classes.NumberInput}
                            value={
                              intellassignSettings.type === EACH_SUBMISSION
                                ? intellassignSettings.number
                                : ""
                            }
                            variant="standard"
                            onChange={handleIntellassignSettingsChange}
                            name="number"
                            type="number"
                            min="0"
                          />{" "}
                          reviewers to each submission
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value={EACH_JUDGE}
                      control={<Radio color="primary" />}
                      label={
                        <Box>
                          Assign{" "}
                          <TextField
                            inputProps={{
                              min: 0,
                              style: { textAlign: "center" },
                            }}
                            className={classes.NumberInput}
                            value={
                              intellassignSettings.type === EACH_JUDGE
                                ? intellassignSettings.number
                                : ""
                            }
                            variant="standard"
                            onChange={handleIntellassignSettingsChange}
                            name="number"
                            type="number"
                          />{" "}
                          submissions to each reviewer
                        </Box>
                      }
                    />
                  </RadioGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={intellassignSettings.useGroups}
                        onChange={handleIntellassignSettingsChange}
                        name="useGroups"
                        color="primary"
                      />
                    }
                    label={
                      <Box my={0.5}>
                        <Typography>Prioritize Group(s)</Typography>
                        <Typography
                          color="textSecondary"
                          variant="caption"
                          component="div"
                        >
                          Uses groups for the reviewers and submissions when
                          assigning them to each other first before assigning
                          the rest.
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={intellassignSettings.onlySubmitted}
                        onChange={handleIntellassignSettingsChange}
                        name="onlySubmitted"
                        color="primary"
                      />
                    }
                    label={
                      <Box my={0.5}>
                        <Typography>Only Submitted Submissions</Typography>
                        <Typography
                          color="textSecondary"
                          variant="caption"
                          component="div"
                        >
                          This will not include any In Progress Submissions when
                          assigning
                        </Typography>
                      </Box>
                    }
                  />
                </FormControl>
              </Box>
            ) : assignmentMethod === GROUP ? (
              <Box>
                {groups && groups.data && groups.data.length > 0 ? (
                  <>
                    <FormLabel component="legend">
                      <Typography>Add group assignments</Typography>
                      <Typography variant="caption">
                        Note: All previous assignments{" "}
                        <strong>
                          <u>will not</u>
                        </strong>{" "}
                        be removed.
                      </Typography>
                    </FormLabel>
                    <Box display="flex" justifyContent="flex-end">
                      <Tooltip title="For each group assignment, all submissions and reviewers will be assigned to each other">
                        <Button
                          onClick={addGroupAssignment}
                          variant="text"
                          startIcon={<AddIcon />}
                        >
                          Add Group Assignment
                        </Button>
                      </Tooltip>
                    </Box>
                    <TableContainer>
                      <Table>
                        <colgroup>
                          <col style={{ width: "45%" }} />
                          <col style={{ width: "45%" }} />
                          <col style={{ width: "10%" }} />
                        </colgroup>
                        <TableHead>
                          <TableRow>
                            <TableCell>Submission Group(s)</TableCell>
                            <TableCell>Reviewer Group(s)</TableCell>
                            <TableCell>Cancel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {groupSettings && groupSettings.length > 0 ? (
                            groupSettings.map((g, i) => {
                              return (
                                <TableRow key={i}>
                                  <TableCell colSpan={1}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.GroupSelectFormControl}
                                    >
                                      <Select
                                        id={`select-submission-groups-${i}`}
                                        multiple
                                        value={g.submissionGroups}
                                        onChange={(e) =>
                                          handleGroupChange(e, i)
                                        }
                                        margin="dense"
                                        name="submissionGroups"
                                      >
                                        {groups &&
                                          groups.data &&
                                          groups.data.map((group) => (
                                            <MenuItem
                                              key={group.id}
                                              value={group.id}
                                            >
                                              {group.name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell colSpan={1}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.GroupSelectFormControl}
                                    >
                                      <Select
                                        id={`select-reviewer-groups-${i}`}
                                        multiple
                                        value={g.judgeGroups}
                                        onChange={(e) =>
                                          handleGroupChange(e, i)
                                        }
                                        margin="dense"
                                        name="judgeGroups"
                                      >
                                        {groups &&
                                          groups.data &&
                                          groups.data.map((group) => (
                                            <MenuItem
                                              key={group.id}
                                              value={group.id}
                                            >
                                              {group.name}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() => cancelGroupAssignment(i)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={2}>
                                Add an assignment to match reviewers and
                                submissions together by groups.
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <Typography>
                    Please add groups and assign the submissions / reviewers to
                    the groups before using this feature.
                  </Typography>
                )}
              </Box>
            ) : null}
          </Box>
          <DialogActions>
            <Tooltip title="Remove all the assignments for this round.">
              <Button onClick={handleClear} variant="outlined">
                Clear All
              </Button>
            </Tooltip>
            <Tooltip title="Assign the submissions based on the configuration above.">
              <Button onClick={handleAssign}>Assign</Button>
            </Tooltip>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

ChallengeAssignJudgeDialog.propTypes = {};

const mapStateToProps = (state) => ({
  groups: state.group.groups,
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {
  fetchAllJudges,
  getAllGroupsForRound,
  assignAllJudgesToAllSubmissions,
  assignJudgesToSubmissionsIntellassign,
  assignJudgesToSubmissionsByGroup,
  clearAllJudgeToSubmissionAssignments,
  getSubmissions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeAssignJudgeDialog);
