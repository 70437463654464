import React, { useState } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { makeStyles } from "@material-ui/core";
import InterestsForm from "../../../shared/InterestsForm";
import { addProfileQuestions } from "../../../redux/actions/profile";

import Button from "../../../shared/Button";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    boxShadow: "none",
  },
}));

const InterestsDialog = ({ addProfileQuestions, modal, toggle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState([]);
  const onSubmit = (e) => {
    e.preventDefault();
    addProfileQuestions({
      questionChoice: state,
    });
    toggle();
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"sm"}
      open={modal}
      onClose={toggle}
      scroll={"body"}
      aria-labelledby="responsive-dialog-title"
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggle}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography component="div" variant="h6">
            <Box fontWeight={600}>My Interests</Box>
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <InterestsForm state={state} setState={setState} />
      </DialogContent>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
        style={{ gap: "8px" }}
      >
        <Button onClick={(e) => onSubmit(e)} color="primary">
          Save
        </Button>
      </Box>
    </Dialog>
  );
};

export default connect(null, { addProfileQuestions })(InterestsDialog);
