import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
// React query
import { useQuery } from "react-query";
// Redux
import { connect } from "react-redux";
import { getCurrentProfile } from "../../redux/actions/profile";
// Material UI
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LockIcon from "@material-ui/icons/Lock";
import Divider from "@material-ui/core/Divider";
// Components
import Spinner from "../../shared/Spinner";
import Experience from "./components/Experience";
import Interests from "./components/Interests";
import Projects from "./components/Projects";
import Profile from "./components/Profile";
import Skills from "./components/Skills";
// utils
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  Divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  ProfileSectionHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
  },
  UserDashboardAppBar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
  },
  UserDashboardToolbar: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "start",
    },
  },
  Button: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0),
      paddingTop: 0,
      paddingBottom: 0,
      textAlign: "left",
    },
  },
  toolbar: theme.mixins.toolbar,
}));

const EditProfilePage = ({ myprofile }) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <Box pt={2} flexGrow={1} height="100%" maxHeight="100vh" overflow="auto">
      {myprofile && (
        <Helmet>
          <title>
            Me | {myprofile.firstName} {myprofile.lastName}
          </title>
          <meta name="description" content={myprofile.aboutMe} />
        </Helmet>
      )}
      <Container maxWidth="md">
        <Box
          mb={{ xs: 3 }}
          boxShadow={{ xs: 0, md: 2, lg: 4 }}
          pb={2}
          borderRadius="borderRadius"
          bgcolor="background.paper"
        >
          <Profile />
          {myprofile ? (
            <Box px={{ xs: 2, md: 4 }}>
              <Divider
                variant="middle"
                orientation="horizontal"
                light={true}
                className={classes.Divider}
              />

              <Typography
                variant="h6"
                component="h2"
                className={classes.ProfileSectionHeader}
              >
                🎨 My Interests
              </Typography>
              <Interests />
              <Divider
                variant="middle"
                orientation="horizontal"
                light={true}
                className={classes.Divider}
              />
              <Typography
                variant="h6"
                component="h2"
                className={classes.ProfileSectionHeader}
              >
                🛠️ My Skills
              </Typography>
              <Skills />
              <Divider
                variant="middle"
                orientation="horizontal"
                light={true}
                className={classes.Divider}
              />
              <Typography
                variant="h6"
                component="h2"
                className={classes.ProfileSectionHeader}
              >
                👩‍💻 My Projects
              </Typography>
              <Projects />

              <Divider
                variant="middle"
                orientation="horizontal"
                light={true}
                className={classes.Divider}
              />
              <Typography
                variant="h6"
                component="h2"
                className={classes.ProfileSectionHeader}
              >
                💼 My Experiences
              </Typography>
              <Experience experience={myprofile && myprofile.experience} />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justify="center"
              my={[4, 6]}
            >
              <LockIcon fontSize="large" color="primary" />
              <Box my={2}>
                <Typography variant="body1">
                  Please create your basic profile, before unlocking more
                  features.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

const EditProfile = ({
  getCurrentProfile,
  user,
  profile: { myprofile, loading },
}) => {
  useQuery(["myprofile", user?.id], async () => await getCurrentProfile());

  return (
    <Fragment>
      {loading ? <Spinner /> : <EditProfilePage myprofile={myprofile} />}
    </Fragment>
  );
};

EditProfile.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  user: state.auth.user,
});

const mapDispatchToProps = { getCurrentProfile };

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
