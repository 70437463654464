/* This example requires Tailwind CSS v2.0+ */
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import {
  LoginIcon,
  BookOpenIcon,
  RssIcon,
  CalendarIcon,
} from "@heroicons/react/outline";

const links = [
  {
    title: "Twitter & Product Updates",
    description:
      "We would love to connect with you on Twitter. Follow us @PralentHQ to receive updates. Feel free to contact us there with any questions as well.",
    icon: RssIcon,
    href: "https://twitter.com/pralenthq",
  },
  {
    title: "Help Center",
    description:
      "Read our latest updates, articles, and community building tips.",
    icon: BookOpenIcon,
    href: "https://help.pralent.com",
  },
  {
    title: "Schedule Demo",
    description: "Learn more about how Pralent can help your community.",
    icon: CalendarIcon,
    href: "https://cal.com/pralent/30min",
  },
  {
    title: "Sign Up",
    description: "Get started with Pralent communities today!",
    icon: LoginIcon,
    href: "/login",
  },
];

export default function Example() {
  return (
    <div className="h-screen">
      <main className="mx-auto w-full max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-xl py-16 sm:py-24">
          <div className="text-center">
            <p className="text-primary-600 text-sm font-semibold uppercase tracking-wide">
              404 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-100 sm:text-5xl">
              This page does not exist.
            </h1>
            <p className="mt-2 text-lg text-gray-300">
              The page you are looking for could not be found.
            </p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold uppercase tracking-wide text-gray-300">
              Get Started
            </h2>
            <ul
              role="list"
              className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200"
            >
              {links.map((link, linkIdx) => (
                <li key={linkIdx}>
                  <a
                    href={link.href}
                    className="relative flex items-start space-x-4 py-6 focus:outline-none"
                  >
                    <div className="flex-shrink-0">
                      <span className="bg-primary-50 flex h-12 w-12 items-center justify-center rounded-lg">
                        <link.icon
                          className="text-primary-700 h-6 w-6"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <h3 className="text-base font-semibold text-gray-100">
                        <span className="focus-within:ring-primary-500 rounded-sm focus-within:ring-2 focus-within:ring-offset-2">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          {link.title}
                        </span>
                      </h3>
                      <p className="text-base text-gray-300">
                        {link.description}
                      </p>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </a>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <Link
                to="/"
                className="text-primary-400 hover:text-primary-500 text-base font-medium"
              >
                Or go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
