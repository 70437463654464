import TextLoop from "react-text-loop";

import CommunityHomePage from "../../../assets/screenshots/community-pages/community-home-page.png";

const TEXT_OPTIONS = [
  "community",
  "student club",
  "non-profit",
  "talent community",
  "hackathon",
  "customers",
  "developers",
  "coaching community",
  "alumni group",
];

export default function Hero() {
  return (
    <div>
      <div className="px-4 pb-12 pt-6 md:pb-24 md:pt-12 lg:pb-40 lg:pt-20">
        <div className="mx-auto max-w-4xl text-center">
          <h1 className="text-6xl font-bold text-gray-100 sm:tracking-tight lg:text-7xl xl:text-8xl">
            🏠 A home for your{" "}
            <span className="block bg-gradient-to-r from-blue-400 to-pink-600 bg-clip-text text-transparent md:hidden">
              community.
            </span>
          </h1>
          <h1 className="hidden text-6xl font-bold sm:tracking-tight md:block lg:text-7xl xl:text-8xl">
            <TextLoop>
              {(TEXT_OPTIONS || []).map((option, index) => (
                <span
                  className="bg-gradient-to-r from-blue-400 to-fuchsia-600 bg-clip-text text-transparent"
                  key={index}
                >
                  {option}
                </span>
              ))}
            </TextLoop>
          </h1>
          <p className="my-5 text-lg text-gray-400 md:text-xl">
            An all-in-one platform to run your entire community. Pralent
            empowers you to create cohesive experiences for your members through
            events, networking, programs, and more. By the way, it's free to
            use!
          </p>
          <div className="space-y-4 space-x-4 sm:flex sm:justify-center md:space-y-0">
            <a
              href="/signup"
              className="bg-primary-500 hover:bg-primary-600 flex items-center justify-center gap-2 rounded-md px-4 py-2 text-base font-semibold text-white duration-300 ease-in-out"
            >
              Join the Beta{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 animate-pulse"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="mx-auto mt-8 max-w-5xl md:mt-16 lg:mt-20">
          <div className="relative">
            <div
              className="absolute -inset-0.5 animate-pulse rounded-lg bg-gray-100 opacity-75"
              style={{ filter: "blur(16px)" }}
            ></div>
            <img
              src={CommunityHomePage}
              className="shadow-3xl relative h-full w-full rounded-lg object-cover object-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
