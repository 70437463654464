import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Link,
} from "@material-ui/core";
import Button from "../../../shared/Button";
import {
  registerUserChallenge,
  leaveChallenge,
  eligibleToSubmitToRound,
} from "../../../redux/actions/challenge";
import {
  submissionDetails,
  returnTextAction,
  registrationDetails,
  getCurrentRound,
} from "../../../utils/challenge";

const RegisterButton = React.memo(
  ({ text, leave, disabled, buttonAction, handleLeaveChallenge }) => {
    return (
      <Fragment>
        {text && (
          <Button
            disabled={disabled}
            size="large"
            variant="contained"
            onClick={buttonAction}
          >
            {text}
          </Button>
        )}
      </Fragment>
    );
  }
);

const ChallengeRegister = ({
  challenge,
  currentUserStatus,
  isAuthenticated,
  user,
  registerUserChallenge,
  eligibleToSubmitToRound,
  leaveChallenge,
  myTeam,
}) => {
  const { communitySlug } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [buttonDetails, setButtonDetails] = useState({
    text: null,
    action: null,
    leave: null,
    disabled: null,
  });

  useEffect(() => {
    if (challenge && challenge.id) {
      const currentRound = getCurrentRound(challenge);

      const submissionRelation = submissionDetails(challenge);
      const isBeforeRegistration = registrationDetails(challenge);
      // Get the round Index of the current active challenge and set the round Index to the current round

      const { text, action, leave, disabled } = returnTextAction(
        currentUserStatus.isRegistered,
        currentUserStatus.submittedStatusTypeId,
        myTeam && myTeam.eliminatedFlag,
        submissionRelation,
        isBeforeRegistration,
        challenge.isActive,
        isAuthenticated,
        user && user.typeId,
        challenge.round,
        currentRound
      );
      //console.log(action, text, leave);
      setButtonDetails({
        ...buttonDetails,
        disabled: disabled,
        action: action,
        text: text,
        leave: leave,
      });
    }
  }, [challenge.id, currentUserStatus, myTeam]);

  const [registerModal, setRegisterModal] = useState(false);

  const registerUser = async () => {
    await registerUserChallenge({
      challengeId: challenge.id,
      termsAccepted: true,
      isLeader: true,
    });
    // eligbile
    const currentRound = getCurrentRound(challenge);
    // await eligibleToSubmitToRound(challenge.id, currentRound);

    setRegisterModal(false);
  };

  const handleSubmitChallenge = () => {
    return history.push(
      `${communitySlug ? `/c/${communitySlug}` : ""}/programs/${
        challenge.slug
      }/submit`
    );
  };

  const handleLeaveChallenge = () => {
    leaveChallenge(challenge.id);
  };

  const buttonAction = React.useCallback(() => {
    const action = buttonDetails.action;
    if (action === null) return null;
    if (action === "SUBMIT_CHALLENGE") {
      handleSubmitChallenge();
    } else if (action === "OPEN_REGISTRATION_MODAL") {
      setRegisterModal(true);
    } else if (action === "SEND_TO_LOGIN") {
      history.push(`/login?redirect=${location.pathname}`);
    }
  }, [buttonDetails]);

  return (
    <Fragment>
      <Dialog
        open={registerModal}
        onClose={() => setRegisterModal(false)}
        aria-labelledby="program-register-dialog-title"
      >
        <DialogTitle id="program-register-dialog-title">
          Join {challenge.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            I have read and agree to be bound by the{" "}
            <Link
              component={RouterLink}
              to="/program-rules-code-of-conduct"
              target="_blank"
              rel="noopener noreferrer"
            >
              Program Terms and Conditions
            </Link>
            , and the Pralent{" "}
            <Link
              component={RouterLink}
              to="/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </Link>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRegisterModal(false)}
            color="primary"
            variant="outlined"
          >
            Disagree
          </Button>
          <Button onClick={() => registerUser()} color="primary">
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      {buttonDetails.text && (
        <RegisterButton
          text={buttonDetails.text}
          leave={buttonDetails.leave}
          disabled={buttonDetails.disabled}
          handleLeaveChallenge={handleLeaveChallenge}
          buttonAction={buttonAction}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentUserStatus: state.challenge.currentUserStatus,
  myTeam: state.challenge.myTeam,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  registerUserChallenge,
  leaveChallenge,
  eligibleToSubmitToRound,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeRegister);
