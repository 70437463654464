import React from "react";
import { Link } from "react-router-dom";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import MuiLink from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";

const useStylesListItem = makeStyles((theme) => ({
  ListItemIcon: (props) => ({
    margin: "auto",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: props.currentTab ? 600 : 400,
    color: theme.palette.text.primary,
    minWidth: 0,
    borderRadius: 999999,
    borderColor:
      props.iconHover && props.currentTab
        ? theme.palette.action.active
        : "transparent",
    borderWidth: props.iconHover && "2.5px",
    padding: props.iconHover && "1.5px",
    transition: "0.2s ease-in-out",
    "&:hover": {
      borderColor: !props.iconHover
        ? "transparent"
        : props.currentTab
        ? theme.palette.action.active
        : theme.palette.text.secondary,
      transition: "0.2s ease-in-out",
    },
  }),
  ListItemText: (props) => ({
    fontSize: theme.typography.body2.fontSize,
    fontWeight: props.currentTab ? 600 : 400,
    color: theme.palette.text.primary,
    lineHeight: 1.2,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginLeft: theme.spacing(1.5),
  }),
  ListItemSecondary: (props) => ({
    fontSize: theme.typography.caption.fontSize,
    fontWeight: props.currentTab ? 600 : 400,
    color: theme.palette.text.primary,
    lineHeight: 1.2,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
  }),
  ListItem: (props) => ({
    backgroundColor: props.currentTab
      ? theme.palette.background.default
      : "transparent",
    borderRadius: theme.shape.borderRadius,
    justifyContent: "center",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  }),
}));

const CustomListItem = ({
  to,
  href,
  icon,
  text,
  secondary,
  onClick,
  currentTab,
  iconHover = false,
  displayText = true,
}) => {
  const classes = useStylesListItem({ currentTab, iconHover });
  let component;
  if (to) {
    component = Link;
  } else if (href) {
    component = MuiLink;
  }

  return (
    <Tooltip placement="left" title={text} disableHoverListener={displayText}>
      <ListItem
        button
        component={component}
        to={to}
        href={href}
        onClick={onClick}
        className={classes.ListItem}
        dense
      >
        <ListItemIcon className={classes.ListItemIcon}>{icon}</ListItemIcon>
        {displayText && (
          <ListItemText
            primary={text}
            secondary={secondary ? secondary : null}
            secondaryTypographyProps={{
              className: classes.ListItemSecondary,
            }}
            primaryTypographyProps={{
              className: classes.ListItemText,
            }}
          />
        )}
      </ListItem>
    </Tooltip>
  );
};

export default CustomListItem;
