import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const Resources = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      height="100%"
      maxHeight="100vh"
      overflow="auto"
    >
      <Typography variant="h6" component="h1">
        Resources page coming soon
      </Typography>
    </Box>
  );
};

export default Resources;
