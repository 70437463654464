import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
// Utils
import { EVENT_REGISTRATION_STATUS } from "../../../utils/globalValues";

const STATUS_MENU_OPTIONS = [
  { label: "Denied", key: EVENT_REGISTRATION_STATUS.DENIED, color: red[900] },
  {
    label: "Pending",
    key: EVENT_REGISTRATION_STATUS.PENDING,
    color: amber[900],
  },
  {
    label: "Approved",
    key: EVENT_REGISTRATION_STATUS.APPROVED,
    color: green[900],
  },
];

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
  },
  pendingChip: {
    backgroundColor: amber[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: amber[900],
    },
    "&:focus": {
      backgroundColor: amber[900],
    },
  },
  deniedChip: {
    backgroundColor: red[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: red[900],
    },
    "&:focus": {
      backgroundColor: red[900],
    },
  },
  approvedChip: {
    backgroundColor: green[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: green[900],
    },
    "&:focus": {
      backgroundColor: green[900],
    },
  },
}));

const OrgEventAttendeeStatus = ({
  data,
  eventId,
  updateAttendeeRegistrationStatusMutation,
}) => {
  const classes = useStyles();
  const [anchorElStatusMenu, setAnchorElStatusMenu] = React.useState(null);

  const openStatusMenu = Boolean(anchorElStatusMenu);

  const handleClickStatusMenu = (event) => {
    setAnchorElStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElStatusMenu(null);
  };
  const approvalStatus = data.registrationStatus;
  const approvalStatusName =
    approvalStatus === EVENT_REGISTRATION_STATUS.PENDING
      ? "Pending"
      : approvalStatus === EVENT_REGISTRATION_STATUS.APPROVED
      ? "Approved"
      : approvalStatus === EVENT_REGISTRATION_STATUS.DENIED
      ? "Denied"
      : "";

  const handleUpdateAttendeeStatus = (newRegistrationStatus) => {
    updateAttendeeRegistrationStatusMutation.mutate({
      eventId: eventId,
      registrationId: data.id,
      registrationStatus: newRegistrationStatus,
    });
    handleCloseStatusMenu();
  };
  return (
    <>
      <Menu
        id="long-menu"
        getContentAnchorEl={null}
        anchorEl={anchorElStatusMenu}
        keepMounted
        open={openStatusMenu}
        onClose={handleCloseStatusMenu}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        {STATUS_MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.key}
            selected={option.label === approvalStatusName}
            onClick={() => handleUpdateAttendeeStatus(option.key)}
          >
            <Box
              width={12}
              height={12}
              bgcolor={option.color}
              borderRadius="borderRadius"
              marginRight={1}
            />
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <Tooltip title="Change the status of this attendee">
        <Chip
          label={approvalStatusName}
          onClick={handleClickStatusMenu}
          clickable
          className={
            approvalStatus === EVENT_REGISTRATION_STATUS.PENDING
              ? classes.pendingChip
              : approvalStatus === EVENT_REGISTRATION_STATUS.APPROVED
              ? classes.approvedChip
              : approvalStatus === EVENT_REGISTRATION_STATUS.DENIED
              ? classes.deniedChip
              : null
          }
        />
      </Tooltip>
    </>
  );
};

export default OrgEventAttendeeStatus;
