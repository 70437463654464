import React, { useState } from "react";
// React router
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";

const useStyles = makeStyles((theme) => ({
  list: {
    minWidth: 300,
    maxWidth: 300,
    padding: theme.spacing(1),
  },
  ListItem: {
    borderRadius: theme.shape.borderRadius,
  },
  listItemAvatar: {
    minWidth: 0,
  },
  listTextPrimary: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    fontWeight: 600,
    lineHeight: 1.2,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  listTextSecondary: {
    color: theme.palette.text.secondary,
    lineHeight: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  listText: {
    marginLeft: theme.spacing(1),
  },
  drawerPaper: {
    position: "relative",
  },
}));

const ChatSidebar = ({ chatRooms, user }) => {
  const classes = useStyles();

  const [openSidebar, setOpenSidebar] = useState(true);

  const handleDrawerOpen = () => {
    setOpenSidebar(true);
  };

  const handleDrawerClose = () => {
    setOpenSidebar(false);
  };

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={openSidebar}
      classes={{ paper: classes.drawerPaper }}
    >
      <Typography variant="h5" component="h2">
        <Box fontWeight={600} px={{ xs: 2, md: 4 }} py={{ xs: 2 }}>
          💬 Chats
        </Box>
      </Typography>
      <List className={classes.list}>
        {(chatRooms || []).map((chat, index) => {
          const users = chat.users;
          const chatRoomNames =
            users && users.length
              ? [
                  ...users.map((chatUser) => {
                    const chatUserProfile = chatUser.profile;

                    if (chatUser.id !== user.id) {
                      return chatUserProfile.firstName;
                    } else {
                      return "";
                    }
                  }),
                ]
              : [];

          return (
            <ListItem
              key={index}
              button
              component={Link}
              to={`/chats/${chat.slug}`}
              className={classes.ListItem}
            >
              <ListItemAvatar className={classes.listItemAvatar}>
                <AvatarGroup max={3}>
                  {(users || []).map((chatUser, index) => {
                    if (chatUser.id !== user.id) {
                      return (
                        <CustomAvatar
                          key={index}
                          src={chatUser.avatar}
                          fallbackText={chatUser.userName}
                          alt={`${chatUser.userName}-logo`}
                        />
                      );
                    }
                  })}
                </AvatarGroup>
              </ListItemAvatar>
              <ListItemText
                className={classes.listText}
                primaryTypographyProps={{
                  className: classes.listTextPrimary,
                }}
                secondaryTypographyProps={{
                  className: classes.listTextSecondary,
                }}
                primary={
                  chat.name
                    ? chat.name
                    : chatRoomNames.filter((name) => name !== "").join(", ")
                }
                secondary={
                  <>
                    {chat.lastPost ? (
                      <Typography component="span" variant="body2">
                        {chat.lastPost.title}
                      </Typography>
                    ) : (
                      <Typography component="span" variant="body2">
                        Send your first message.
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSidebar);
