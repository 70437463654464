import React, { Fragment, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../shared/Spinner";
import { getProject, clearProject } from "../../redux/actions/project";
// Material UI
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
// images
import team_meeting from "../../assets/images/team_meeting.svg";
// components
import ComplexProjectCard from "../../shared/ComplexProjectCard";
import CommentForm from "./components/CommentForm";
import CommentItem from "./components/CommentItem";
import ProjectSharedUsers from "./components/ProjectSharedUsers";
import ProjectFiles from "../../shared/ProjectFiles";
import ProjectLinks from "../../shared/ProjectLinks";
import Can from "../../shared/Can";
// Utils
import { USER_TYPE } from "../../utils/globalValues";

import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.main,

    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  commentCard: {
    boxShadow: "none",
  },
}));
const Project = ({
  getProject,
  clearProject,
  project: { project, loading },
  user,
}) => {
  const { id } = useParams();
  const classes = useStyles();

  const fetchProject = async () => {
    await clearProject();
    await getProject(id || "");
  };

  useEffect(() => {
    fetchProject();

    return () => clearProject();
  }, [id]);

  return loading || !project ? (
    <Spinner />
  ) : (
    <Box
      flexGrow={1}
      height="100%"
      maxHeight="100vh"
      overflow="auto"
      p={{ xs: 2, md: 4 }}
    >
      <Can
        role={USER_TYPE.STUDENT}
        perform="project:view"
        data={{
          userId: user && user.id,
          team:
            project &&
            project.projectTeam &&
            project.projectTeam.length &&
            project.projectTeam,
          isPublic: project && project.isPublic,
        }}
        yes={() => {
          return (
            <Fragment>
              {project && (
                <Helmet>
                  <title>{project.title} | Pralent Project</title>
                  <meta name="description" content={project.description} />
                </Helmet>
              )}
              <Box bgcolor="background.default" display="block">
                <Grid container spacing={4}>
                  <Grid item xs={12} md={8}>
                    <Box borderRadius="borderRadius" bgcolor="background.paper">
                      <ComplexProjectCard project={project} />
                    </Box>

                    <Box
                      mt={{ xs: 2, md: 4 }}
                      p={2}
                      borderRadius="borderRadius"
                      bgcolor="background.paper"
                    >
                      <div>
                        <Box>
                          <Typography variant="h6" component="div">
                            <Box fontWeight={600}>Comments</Box>
                          </Typography>

                          <CommentForm projectId={project.id} />
                        </Box>
                        <List>
                          {project.comments.map((comment) => (
                            <CommentItem
                              key={comment.id}
                              comment={comment}
                              projectId={project.id}
                            />
                          ))}
                        </List>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box
                      p={2}
                      borderRadius="borderRadius"
                      bgcolor="background.paper"
                    >
                      <ProjectSharedUsers project={project} />
                      <ProjectFiles project={project} />
                      <ProjectLinks project={project} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Fragment>
          );
        }}
        no={() => {
          return (
            <Box
              my={[2, 4]}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100vh"
            >
              <Box width={[1, 1 / 2, 1 / 3]} mx="auto">
                <img src={team_meeting} alt="project-not-public" />
              </Box>
              <Typography variant="h6">
                This project is not available to the public.
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};

Project.propTypes = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  project: state.project,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, { getProject, clearProject })(Project)
);
