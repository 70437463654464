import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  fetchTeamInformation,
  modifyTeamName,
  addTeamMember,
  deleteTeamMember,
} from "../../redux/actions/challenge";
// Material UI
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core";
// Components
import Button from "../Button";
// Icons
import { IoMdMail } from "react-icons/io";
// Utils
import axios from "../../utils/axios";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 0,
  },
  ProfileLink: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    "&:hover": {
      textDecoration: "none",
    },
  },
  LeaderChip: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.caption.fontSize,
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[100],
    color: theme.palette.grey[800],
  },
  TeamUserAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(1),
  },
}));

const ChallengeTeam = ({
  challenge,
  currentUserStatus,
  user,
  myTeam,
  fetchTeamInformation,
  modifyTeamName,
  addTeamMember,
  deleteTeamMember,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [teamName, setTeamName] = useState("");

  const [teamMemberSearch, setTeamMemberSearch] = useState("");
  const [teamMemberError, setTeamMemberError] = useState("");
  const [teamMembersToAdd, setTeamMembersToAdd] = useState([]);

  useEffect(() => {
    setTeamName((myTeam && myTeam.name) || "");
  }, [myTeam]);

  const modifyName = async (e) => {
    e.preventDefault();
    await modifyTeamName(challenge.id, myTeam.id, teamName);
  };

  const findTeamMembers = async (e) => {
    e.preventDefault();
    setTeamMemberError("");
    const searchForUser = async (search) => {
      try {
        if (search && search !== "") {
          const res = await axios.get(
            `/api/team/find/${challenge.id}/${myTeam.id}/${search}`
          );
          return res.data;
        }
      } catch (error) {
        if (error && error.data) {
          setTeamMemberError(error.data.msg);
        }
      }
    };
    const userTeam = await searchForUser(teamMemberSearch);
    setTeamMemberSearch("");

    if (userTeam) {
      let oldTeam = [...teamMembersToAdd];
      oldTeam = oldTeam.filter((oldTeamUser) => oldTeamUser.id !== userTeam.id);

      oldTeam.push({ ...userTeam });

      setTeamMembersToAdd(oldTeam);
    }
  };

  useEffect(() => {
    if (challenge && currentUserStatus && currentUserStatus.isRegistered) {
      fetchTeamInformation(challenge.id, user && user.id);
    }
  }, [challenge, currentUserStatus]);

  const handleConfirmAddUser = (userId) => {
    addTeamMember(userId, challenge.id, myTeam.id);
    handleConfirmRemoveAddUser(userId);
  };

  const handleConfirmRemoveAddUser = (userId) => {
    let oldTeam = [...teamMembersToAdd];
    oldTeam = oldTeam.filter((elem) => elem.id !== userId);
    setTeamMembersToAdd(oldTeam);
  };

  const removeTeamMember = (userId) => {
    deleteTeamMember(
      userId,
      challenge.id,
      myTeam.id,
      user && userId === user.id
    );
  };

  return (
    <Fragment>
      <Box>
        {(challenge && currentUserStatus && !currentUserStatus.isRegistered) ||
        !myTeam ? (
          <Typography variant="body2" color="textSecondary">
            You are not registered for this program.
          </Typography>
        ) : challenge &&
          challenge.maxPeopleTeam &&
          challenge.maxPeopleTeam === 1 ? (
          <Box>
            {user &&
              myTeam &&
              myTeam.members &&
              myTeam.members.length > 0 &&
              myTeam.members.map((member, index) => {
                const profile = member.profile;
                const teamUser = member.user;

                const education =
                  profile &&
                  profile.education &&
                  profile.education.length > 0 &&
                  profile.education[0];

                const me = myTeam.members.find(
                  (elem) => elem.userAccountId === user.id
                );
                return (
                  <Box
                    display="flex"
                    flexDirection={mobileScreen ? "column" : "row"}
                    alignItems={mobileScreen ? "flex-start" : "center"}
                    justifyContent="space-between"
                    my={2}
                    key={index}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Avatar
                        alt={teamUser && teamUser.userName}
                        src={teamUser && teamUser.avatar}
                        className={classes.TeamUserAvatar}
                        component={RouterLink}
                        to={`/@${teamUser.userName}`}
                      />

                      <Box
                        ml={1}
                        component={RouterLink}
                        to={`/@${teamUser.userName}`}
                      >
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          component="div"
                        >
                          <Box fontWeight={600} lineHeight={1.2}>
                            {profile ? (
                              <span>
                                {profile.firstName} {profile.lastName}
                              </span>
                            ) : (
                              <span>{teamUser && teamUser.userName}</span>
                            )}
                          </Box>
                        </Typography>
                        {education && (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="div"
                            >
                              <Box lineHeight={1.2}>
                                {education && education.school}
                              </Box>
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="div"
                            >
                              <Box lineHeight={1.2}>
                                {education && education.fieldOfStudy},{" "}
                                {education &&
                                  education.completionDate &&
                                  new Date(
                                    education.completionDate
                                  ).getFullYear()}
                              </Box>
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Box mt={{ xs: 2, md: 0 }}>
                      <Box display="flex" alignItems="center">
                        {(member.userAccountId === user.id || me.isLeader) && (
                          <Button
                            variant="outlined"
                            onClick={() =>
                              removeTeamMember(member.userAccountId)
                            }
                          >
                            Leave Program
                          </Button>
                        )}

                        <Box ml={2}>
                          {teamUser && (
                            <Button component={RouterLink} to={`/me`}>
                              Update Profile
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        ) : (
          <Box>
            <Box width="100%">
              <Box>
                <InputLabel>Team Name</InputLabel>

                <form onSubmit={modifyName}>
                  <Box display="flex">
                    <TextField
                      id={"name"}
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="name"
                      placeholder="Enter your team name"
                      required
                      value={teamName}
                      className={classes.input}
                      onChange={(e) => setTeamName(e.target.value)}
                    />
                    <Button variant="contained" color="primary" type="submit">
                      Save
                    </Button>
                  </Box>
                </form>
              </Box>
              <Box my={{ xs: 2, md: 4 }}>
                <InputLabel>My Team Members</InputLabel>
                <List dense>
                  {user &&
                    myTeam &&
                    myTeam.members &&
                    myTeam.members.length > 0 &&
                    myTeam.members.map((member, index) => {
                      const profile = member.profile;
                      const teamUser = member.user;

                      const education =
                        profile &&
                        profile.education &&
                        profile.education.length > 0 &&
                        profile.education[0];

                      const me = myTeam.members.find(
                        (elem) => elem.userAccountId === user.id
                      );
                      return (
                        <Box
                          display="flex"
                          flexDirection={mobileScreen ? "column" : "row"}
                          alignItems={mobileScreen ? "flex-start" : "center"}
                          justifyContent="space-between"
                          my={2}
                          key={index}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Avatar
                              alt={teamUser && teamUser.userName}
                              src={teamUser && teamUser.avatar}
                              className={classes.TeamUserAvatar}
                              component={RouterLink}
                              to={`/@${teamUser.userName}`}
                            />

                            <Box
                              ml={1}
                              component={RouterLink}
                              to={`/@${teamUser.userName}`}
                            >
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                component="div"
                              >
                                <Box fontWeight={600} lineHeight={1.2}>
                                  {profile ? (
                                    <span>
                                      {profile.firstName} {profile.lastName}
                                    </span>
                                  ) : (
                                    <span>{teamUser && teamUser.userName}</span>
                                  )}
                                  <Chip
                                    variant="outlined"
                                    label={
                                      member.isLeader ? "Leader" : "Member"
                                    }
                                    className={classes.LeaderChip}
                                  />
                                </Box>
                              </Typography>
                              {education && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="flex-start"
                                >
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    <Box lineHeight={1.2}>
                                      {education && education.school}
                                    </Box>
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    <Box lineHeight={1.2}>
                                      {education && education.fieldOfStudy},{" "}
                                      {education &&
                                        education.completionDate &&
                                        new Date(
                                          education.completionDate
                                        ).getFullYear()}
                                    </Box>
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>

                          <Box>
                            <Box display="flex" alignItems="center">
                              {(member.userAccountId === user.id ||
                                me.isLeader) && (
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    removeTeamMember(member.userAccountId)
                                  }
                                >
                                  Remove
                                </Button>
                              )}

                              <Box ml={2}>
                                {teamUser && (
                                  <IconButton
                                    component={Link}
                                    href={`mailto:${teamUser.email}`}
                                  >
                                    <IoMdMail />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </List>
              </Box>
              <Box my={{ xs: 2, md: 4 }}>
                <InputLabel>Add New Team Members</InputLabel>

                <form onSubmit={findTeamMembers}>
                  <Box display="flex" flexDirection="row" alignItems="stretch">
                    <TextField
                      id={"findTeamMembers"}
                      fullWidth
                      variant={"outlined"}
                      type="text"
                      placeholder="Search for an existing Pralent user by their email"
                      name="findTeamMember"
                      value={teamMemberSearch}
                      onChange={(e) => setTeamMemberSearch(e.target.value)}
                      className={classes.input}
                      required
                    />
                    <Button variant="contained" color="primary" type="submit">
                      Search
                    </Button>
                  </Box>
                </form>
                <Box mt={1} color="error.main">
                  {teamMemberError && teamMemberError}
                </Box>
                <Box mt={2}>
                  {teamMembersToAdd &&
                    teamMembersToAdd.map((teamuser, index) => {
                      return (
                        <Box
                          display="flex"
                          flexDirection={mobileScreen ? "column" : "row"}
                          alignItems={mobileScreen ? "flex-start" : "center"}
                          justifyContent="space-between"
                          my={2}
                          key={index}
                        >
                          <Box
                            mb={2}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                          >
                            <Avatar
                              alt={teamuser && teamuser.userName}
                              src={teamuser.avatar}
                            >
                              {teamuser &&
                                teamuser.userName &&
                                teamuser.userName.substring(0, 1).toUpperCase()}
                            </Avatar>

                            <Box ml={1}>
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                component="div"
                              >
                                <Box fontWeight={600} lineHeight={1.2}>
                                  {teamuser && teamuser.userName}
                                </Box>
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {teamuser && teamuser.email}
                              </Typography>
                            </Box>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Button
                              variant="outlined"
                              onClick={() =>
                                handleConfirmRemoveAddUser(teamuser.id)
                              }
                            >
                              Remove
                            </Button>

                            <Button
                              onClick={() => handleConfirmAddUser(teamuser.id)}
                            >
                              Confirm
                            </Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  myTeam: state.challenge.myTeam,
  challenge: state.challenge.challenge,
  currentUserStatus: state.challenge.currentUserStatus,
  user: state.auth.user,
});

const mapDispatchToProps = {
  fetchTeamInformation,
  modifyTeamName,
  addTeamMember,
  deleteTeamMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeTeam);
