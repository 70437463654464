import {
  GET_PROFILE,
  GET_MYPROFILE,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  CLEAR_PROFILES,
  CLEAR_MYPROFILE,
  UPDATE_PROFILE,
  GET_PROFILES,
  GET_REPOS,
  PROFILE_LOADING,
  GET_PROFILE_PROJECTS,
  UPLOAD_PROFILE_IMAGE,
  DELETE_EXPERIENCE,
  UPLOAD_RESUME,
  ADD_PROFILE_LINKS,
  ADD_PROFILE_ANSWERS,
  GET_PROFILE_SKILLS,
  ADD_PROFILE_SKILLS,
  UPDATE_PROFILE_SKILLS,
  LOADING_ORGANIZATIONS,
  GET_MY_ORGANIZATIONS,
  CLEAR_MY_ORGANIZATIONS,
} from "../actions/actionTypes";

const initialState = {
  myprofile: null,
  profile: null,
  profiles: [],
  repos: [],
  myOrganizations: [],
  loading: true,
  error: {},
  skip: 1,
  limit: 10,
  hasMore: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MYPROFILE:
    case UPDATE_PROFILE:
      return {
        ...state,
        myprofile: payload,
        loading: false,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case GET_PROFILE_PROJECTS:
      let prevProfile = { ...state.profile };
      prevProfile.projects = payload;
      return {
        ...state,
        profile: prevProfile,
        loading: false,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: [...state.profiles, ...payload.profileDetails],
        skip: state.skip + state.limit,
        hasMore: payload.hasMore,
        loading: false,
      };
    case CLEAR_PROFILES:
      return {
        ...state,
        profiles: [],
        skip: 1,
        limit: 10,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_MYPROFILE:
      return {
        ...state,
        myprofile: null,
        loading: false,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
        profiles: [],
        repos: [],
        loading: false,
      };
    case GET_REPOS:
      return {
        ...state,
        repos: payload,
        loading: false,
      };
    case UPLOAD_PROFILE_IMAGE:
      return {
        ...state,
        myprofile: payload,
        loading: false,
      };
    case UPLOAD_RESUME: {
      return {
        ...state,
        myprofile: {
          ...state.myprofile,
          resumeLocation: payload.filePath,
        },
      };
    }
    case DELETE_EXPERIENCE:
      return {
        ...state,
        myprofile: {
          ...state.myprofile,
          experience: state.myprofile.experience.filter(
            (exp) => exp.id !== payload
          ),
        },
      };
    case ADD_PROFILE_LINKS:
      return {
        ...state,
        myprofile: {
          ...state.myprofile,
          publicProfileLinks: payload,
        },
      };
    case ADD_PROFILE_ANSWERS:
      return {
        ...state,
        myprofile: {
          ...state.myprofile,
          questionsAnswers: payload,
        },
      };
    case GET_PROFILE_SKILLS:
      return {
        ...state,
        myprofile: {
          ...state.myprofile,
          skillSets: payload,
        },
      };
    case ADD_PROFILE_SKILLS:
      return {
        ...state,
        myprofile: {
          ...state.myprofile,
          skillSets: payload,
        },
      };
    case GET_MY_ORGANIZATIONS:
      return {
        ...state,
        myOrganizations: payload,
      };
    default:
      return state;
  }
}
