import React, { useMemo } from "react";
// Redux
import { connect } from "react-redux";
// Material UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// Custom theme
import theme, {
  BASE_COLORS,
  LIGHT_COLORS,
  DARK_COLORS,
  ASH_COLORS,
  MIDNIGHT_COLORS,
  PALE_COLORS,
  DAWN_COLORS,
} from "../../styles/theme/theme";
import { THEME_TYPE } from "../../utils/globalValues";

const CustomThemeProvider = ({ user, children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const paletteColors =
    user && user.theme
      ? user.theme === THEME_TYPE.LIGHT
        ? LIGHT_COLORS
        : user.theme === THEME_TYPE.DARK
        ? DARK_COLORS
        : user.theme === THEME_TYPE.ASH
        ? ASH_COLORS
        : user.theme === THEME_TYPE.MIDNIGHT
        ? MIDNIGHT_COLORS
        : user.theme === THEME_TYPE.PALE
        ? PALE_COLORS
        : user.theme === THEME_TYPE.DAWN
        ? DAWN_COLORS
        : user.theme === THEME_TYPE.SYSTEM
        ? prefersDarkMode
          ? DARK_COLORS
          : LIGHT_COLORS
        : DARK_COLORS
      : prefersDarkMode
      ? DARK_COLORS
      : LIGHT_COLORS;

  const customTheme = useMemo(
    () =>
      createMuiTheme({
        ...theme,
        palette: {
          ...BASE_COLORS,
          ...paletteColors,
        },
      }),
    [prefersDarkMode, user && user.theme]
  );
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomThemeProvider);
