import React from "react";
// Material UI
import { styled } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import Link from "@material-ui/core/Link";

export const MarkdownH1 = styled(Typography)(({ theme }) => ({
  fontSize: "1.6rem",
  fontWeight: 600,
}));
export const MarkdownH2 = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  fontWeight: 600,
}));
export const MarkdownH3 = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: 600,
}));
export const MarkdownH4 = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
}));
export const Paragraph = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  marginBottom: theme.spacing(2),
  lineHeight: 1.6,
  letterSpacing: 0.1,
  wordSpacing: 1.5,
}));

export const ATag = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  fontWeight: 600,
  color: theme.palette.primary.main,
  transition: "0.2s",
  "&:hover": {
    color: theme.palette.primary.dark,
    transition: "0.2s",
  },
}));

export const Ul = (props) => {
  const { children, ...other } = props;
  return (
    <ul
      style={{
        listStyleType: "disc",
        paddingInlineStart: "40px",
      }}
      {...other}
    >
      {children}
    </ul>
  );
};
export const Ol = (props) => {
  const { children, ...other } = props;
  return (
    <ul
      style={{
        paddingInlineStart: "40px",
        listStyleType: "decimal",
      }}
      {...other}
    >
      {children}
    </ul>
  );
};
