import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import { addEditJudge } from "../../../redux/actions/judge";
// Components
import Button from "../../../shared/Button";
// Material UI
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Helper Functions
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
// Icons
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    boxShadow: "none",
  },
}));

const ChallengeJudgeDialog = ({
  open,
  setOpen,
  currentRoundIndex,
  challenge,
  judge = null,
  addEditJudge,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
    clearFormData();
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
      challengeRoundId:
        challenge &&
        challenge.round.length &&
        challenge.round[currentRoundIndex].id,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Make sure that you have a valid values for the fields
    if (formData.firstName && formData.lastName && formData.emailAddress) {
      // Send a request to save/update judge details

      const res = await addEditJudge(formData);
      if (res) {
        handleClose();
      }
    }
  };

  const [formData, setFormData] = useState({
    challengeId: challenge.id,
    challengeRoundId:
      challenge &&
      challenge.round.length &&
      challenge.round[currentRoundIndex].id,
    firstName: "",
    lastName: "",
    emailAddress: "",
    inviteSent: false,
    photoUrl: "",
    isActive: false,
    inviteAccepted: false,
  });

  //console.log(formData);

  const clearFormData = () => {
    setFormData({
      challengeId: challenge.id,
      challengeRoundId:
        challenge &&
        challenge.round.length &&
        challenge.round[currentRoundIndex].id,
      firstName: "",
      lastName: "",
      emailAddress: "",
      inviteSent: false,
      photoUrl: "",
      isActive: false,
      inviteAccepted: false,
    });
  };

  useEffect(() => {
    if (judge) {
      setFormData({
        ...formData,
        id: judge.id,
        challengeId: judge.challengeId,
        challengeRoundId: judge.challengeRoundId,
        firstName: judge.firstName,
        lastName: judge.lastName,
        emailAddress: judge.emailAddress,
        inviteSent: judge.inviteSent,
        inviteAccepted: judge.inviteAccepted,
        photoUrl: judge.photoUrl,
        isActive:
          judge.isActive === null || judge.isActive === undefined
            ? false
            : judge.isActive,
      });
    } else {
      clearFormData();
    }
  }, [judge, open]);

  const { firstName, lastName, emailAddress, isActive } = formData;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <form onSubmit={(e) => onSubmit(e)}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Box flex="1">
              <Typography variant="h6">
                {judge !== null ? "Edit" : "Add"} Reviewer
              </Typography>
            </Box>
            <Button type="submit" color="primary">
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>First Name</InputLabel>
                <TextField
                  placeholder="Reviewer's first name"
                  type="text"
                  required
                  onChange={handleChange}
                  name="firstName"
                  value={firstName}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Last Name</InputLabel>
                <TextField
                  placeholder="Reviewer's last name"
                  type="text"
                  required
                  onChange={handleChange}
                  name="lastName"
                  value={lastName}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Email Address</InputLabel>
                <TextField
                  placeholder="Reviewer's email address"
                  type="email"
                  required
                  onChange={handleChange}
                  name="emailAddress"
                  value={emailAddress}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Active</InputLabel>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isActive}
                      value={isActive}
                      onChange={handleChange}
                      color="primary"
                      name={"isActive"}
                    />
                  }
                  label="Active"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = { addEditJudge, setAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeJudgeDialog);
