import React, { useState, useEffect } from "react";
// React-Router
import { Link } from "react-router-dom";
import { Route, useRouteMatch, useLocation } from "react-router";
// Redux
import { connect } from "react-redux";
// Components
import OrgAppBar from "../../shared/OrgAppBar";
import OrgCommunityMemberTable from "./components/OrgCommunityMemberTable";
import CommunityMemberAnalytics from "./components/CommunityMemberAnalytics";
// Material UI
import Box from "@material-ui/core/Box";
// Icons
import { IoPeopleOutline } from "react-icons/io5";
// Utils
import PerfectScrollbar from "react-perfect-scrollbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      my={2}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const OrgMembers = () => {
  // State for tabs
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChangeTab}>
        <Tab label="Members" />
        <Tab label="Insights" />
      </Tabs>

      <TabPanel value={value} index={0}>
        <OrgAppBar
          title={
            <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
              <IoPeopleOutline /> Members
            </Box>
          }
          subtitle="Manage and view your community members"
        />

        <PerfectScrollbar>
          <Box>
            <OrgCommunityMemberTable />
          </Box>
        </PerfectScrollbar>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CommunityMemberAnalytics />
      </TabPanel>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgMembers);
