const QA = [
  {
    question: "What type of programs can I run on Pralent?",
    answer:
      "Pralent is able to manage competitions, hackathons, recruiting challenges, contests, grant and scholarship applications, accelerator and incubator programs, admissions applications, and everything in between.",
  },
  {
    question: "What is the difference between Basic and Pro?",
    answer:
      "Basic is great for organizing small communities and programs. It is perfect for a small weekend hackathon or a small application. Pro is great for larger communities that would like access to more features. It also works well for larger programs with more submissions and members.",
  },
  {
    question: "What happens if I exceed the limit for my plan?",
    answer:
      "If you’re approaching your plan's limits, you can upgrade your account to a plan with higher limits and capabilities. If you do exceed your plan's limits, any ongoing programs and communities will automatically become inactive and be unavailable to your users. The ongoing programs and communities will only go live again after you upgrade your account or on the first day of the next year, whichever comes first.",
  },
  {
    question:
      "Why is Pralent Premium free for student organizations? How about non-profits?",
    answer:
      "Pralent loves to help out student organizations. Whether your student organization is K-12 or Higher Education, you will be able to access our entire suite of tools and features all for free. If you are a non-profit organization, please contact us and we'll be happy to set you up with Pralent for free as well.",
  },
];

const FAQ = () => {
  return (
    <div className="bg-primary-700">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white">
          Frequently asked questions
        </h2>
        <div className="mt-6 border-t border-primary-300 border-opacity-25 pt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            {QA &&
              QA.map((q, i) => {
                return (
                  <div key={i}>
                    <dt className="text-lg leading-6 font-medium text-white">
                      {q.question}
                    </dt>
                    <dd className="mt-2 text-base text-primary-200">
                      {q.answer}
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
