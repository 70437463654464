import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// React router
import { Link, useParams } from "react-router-dom";
// react-query
import { useMutation, useQueryClient } from "react-query";
import { deleteCalendarEvent } from "../../../hooks/calendar";
// Material UI
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
// Icons
import ScheduleIcon from "@material-ui/icons/Schedule";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// Components
import CalendarEventDialog from "./CalendarEventDialog";
import Button from "../../../shared/Button";
// Utils
import ReactImageFallback from "react-image-fallback";
import { CALENDAR_EVENT_TYPE } from "../../../utils/globalValues";
import {
  convertUTCTimeToZonedTime,
  getFirstAndLastDayOfMonth,
} from "../../../utils/utilFunc";
import isUrl from "../../../utils/isUrl";

const useStyles = makeStyles((theme) => ({
  imageUrl: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    objectFit: "cover",
  },
  card: {
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    transition: "ease-in-out 0.2s",
    textDecoration: "none",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
      textDecoration: "none",
    },
  },
}));

const CalendarEventCard = ({
  calEvent,
  selectedMonth,
  companyDetails,
  setAlert,
}) => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const [openDialog, setOpenDialog] = useState(false);

  const deleteCalendarEventMutation = useMutation(deleteCalendarEvent, {
    onSuccess: async (data) => {
      if (data?.data) {
        // TODO: remove from query
        queryClient.invalidateQueries([
          "communityCalendarEvents",
          {
            companyId: companyDetails?.id,
            selectedDateRange: getFirstAndLastDayOfMonth(selectedMonth),
          },
        ]);

        await setAlert(`Succesfully deleted ${calEvent.title}`, "success");
      }
    },
  });

  const handleEditEvent = () => {
    // Open the event details dialog box
    setOpenDialog(true);
  };

  const handleDeleteEvent = () => {
    if (
      window.confirm("Are you sure you want to delete this calendar event?")
    ) {
      const delCalEvent = {
        companyId: companyDetails.id,
        calEventId: calEvent.id,
      };
      deleteCalendarEventMutation.mutate({
        calEventDetails: delCalEvent,
      });

      return true;
    } else {
      return false;
    }
  };

  if (!calEvent) {
    return <Box />;
  }

  let cardComponentProps = {
    component: calEvent.url ? (isUrl(calEvent.url) ? MuiLink : Link) : Box,
  };
  if (calEvent.url) {
    if (isUrl(calEvent.url)) {
      cardComponentProps.href = calEvent.url;
      cardComponentProps.target = "_blank";
      cardComponentProps.rel = "noopener noreferrer";
    } else {
      cardComponentProps.to = `/c/${companyDetails.communitySlug}${calEvent.url}`;
    }
  }

  const startDateTimeDisplay = calEvent.reminder
    ? ""
    : convertUTCTimeToZonedTime(new Date(calEvent.startDateTime)) + " - ";

  return (
    <Box
      my={1}
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      style={{ gap: "16px" }}
    >
      <Card {...cardComponentProps} className={classes.card}>
        <CardContent>
          <Grid
            container
            spacing={4}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} lg={4} xl={3}>
              <Box
                style={{
                  position: "relative",
                  paddingBottom: "56.2%",
                }}
              >
                <ReactImageFallback
                  src={calEvent.imageUrl}
                  fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                  alt={`${calEvent.title}-header-image`}
                  className={classes.imageUrl}
                />
              </Box>
            </Grid>
            <Grid item xs={12} lg={8} xl={9}>
              <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
                <Box>
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h3"
                    color="textPrimary"
                  >
                    <Box fontWeight={600} lineHeight={1}>
                      {calEvent.title}
                    </Box>
                  </Typography>
                  {calEvent.description && (
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      <Box
                        lineHeight={1.2}
                        fontWeight={600}
                        display="flex"
                        alignItems="center"
                      >
                        {calEvent.description}
                      </Box>
                    </Typography>
                  )}
                  <Box
                    mt={1}
                    display="flex"
                    alignItems="center"
                    style={{ gap: "8px" }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      <Box
                        fontWeight={600}
                        display="flex"
                        alignItems="center"
                        style={{ gap: "4px" }}
                      >
                        <ScheduleIcon /> {startDateTimeDisplay}{" "}
                        {convertUTCTimeToZonedTime(
                          new Date(calEvent.endDateTime)
                        )}
                      </Box>
                    </Typography>
                    {calEvent.location && (
                      <Typography
                        variant="body2"
                        component="div"
                        color="textSecondary"
                      >
                        <Box
                          fontWeight={600}
                          display="flex"
                          alignItems="center"
                        >
                          <LocationOnOutlinedIcon /> {calEvent.location}
                        </Box>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box>
        {calEvent.type === CALENDAR_EVENT_TYPE.CALENDAR_CUSTOM_EVENT && (
          <>
            <CalendarEventDialog
              companyDetails={companyDetails}
              calendarItem={calEvent}
              selectedMonth={selectedMonth}
              open={openDialog}
              setOpen={setOpenDialog}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              style={{ gap: "8px" }}
            >
              <Button variant="contained" onClick={handleEditEvent}>
                Edit
              </Button>

              <Button variant="outlined" onClick={handleDeleteEvent}>
                Delete
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEventCard);
