import React, { Fragment, useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import {
  setPublicChallenge,
  setActiveChallenge,
} from "../../redux/actions/challenge";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// Components
import OrgChallengeAppBar from "../../shared/OrgChallengeAppBar";
import OrgAppBar from "../../shared/OrgAppBar";

const ChallengeSettings = ({
  challenge,
  setPublicChallenge,
  setActiveChallenge,
  setAlert,
}) => {
  const [settingsState, setSettingsState] = useState({
    isPublic: false,
    isActive: false,
    isPublished: true,
  });

  useEffect(() => {
    if (challenge) {
      setSettingsState({
        ...settingsState,
        isPublic: challenge.isPublic,
        isActive: challenge.isActive,
        isPublished: challenge.isPublished,
      });
    }
  }, [challenge]);

  return (
    <>
      <OrgChallengeAppBar />
      <Box>
        <OrgAppBar
          title="Settings"
          subtitle="Control the public and active settings"
          actions={null}
        />
        <Box>
          {challenge && (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settingsState.isPublic}
                      onChange={() =>
                        setPublicChallenge(
                          !settingsState.isPublic,
                          challenge.id
                        )
                      }
                      name="isPublic"
                      color="primary"
                    />
                  }
                  label={
                    <Box mb={2} maxWidth="500px" lineHeight={1}>
                      <Typography variant="subtitle1" component="div">
                        <Box fontWeight={600}>
                          {settingsState.isPublic ? "Public" : "Private"}
                        </Box>
                      </Typography>
                      <Typography variant="caption">
                        Public programs are available for everyone to view.
                        Private programs are only available to people with your
                        program link.
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={settingsState.isActive}
                      onChange={() => {
                        setActiveChallenge(
                          !settingsState.isActive,
                          challenge.id
                        );
                      }}
                      name="isActive"
                      color="primary"
                    />
                  }
                  label={
                    <Fragment>
                      <Box mb={2} maxWidth="500px" lineHeight={1}>
                        <Typography variant="subtitle1" component="div">
                          <Box fontWeight={600}>
                            {settingsState.isActive ? "Active" : "Inactive"}
                          </Box>
                        </Typography>
                        <Typography variant="caption">
                          Mark your program as active when you would like people
                          to register and submit. Keep your program as inactive
                          if you are still working on it.
                        </Typography>
                      </Box>
                    </Fragment>
                  }
                />
              </FormGroup>
              <FormGroup>
                <Typography color="error">Delete Program</Typography>
                <Typography variant="caption">
                  Please contact{" "}
                  <Link
                    href="mailto:support@pralent.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@pralent.com
                  </Link>{" "}
                  to delete this program.
                </Typography>
              </FormGroup>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = { setPublicChallenge, setActiveChallenge, setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeSettings);
