import React, { useEffect } from "react";
// Redux
import { connect } from "react-redux";
import {
  getJudgesForChallengeRound,
  judgesToGroup,
} from "../../../redux/actions/group";
// Material UI
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
// Components
import Spinner from "../../Spinner";

const useStyles = makeStyles((theme) => ({
  ChipRoot: {
    fontSize: "0.65rem",
    cursor: "pointer",
    height: "18px",
  },
  ChipLabel: {
    padding: "3px",
  },
}));

export const GroupJudges = ({
  challenge,
  currentRoundIndex,
  group,
  judges,
  getJudgesForChallengeRound,
  judgesToGroup,
}) => {
  const classes = useStyles();

  const deleteJudge = (judgeId) => {
    if (
      window.confirm(
        "Are you sure you want to remove this reviewer from the group?"
      ) &&
      judgeId &&
      challenge &&
      group.data
    ) {
      const currentRound = challenge.round[currentRoundIndex];
      const judgesToAssign = [];
      const judgesToRemove = [judgeId];

      judgesToGroup(
        challenge.id,
        currentRound.id,
        judgesToAssign,
        judgesToRemove,
        group.data.id
      );
    }
  };

  useEffect(() => {
    if (challenge) {
      const currentRound = challenge.round[currentRoundIndex];
      getJudgesForChallengeRound(currentRound.id);
    }
  }, [currentRoundIndex, group]);

  const assignJudgesToGroup = (value) => {
    if (value && challenge && group.data) {
      const currentRound = challenge.round[currentRoundIndex];
      const judgesToAssign = [value.id];
      const judgesToRemove = [];

      judgesToGroup(
        challenge.id,
        currentRound.id,
        judgesToAssign,
        judgesToRemove,
        group.data.id
      );
    }
  };

  return (
    <Box>
      {group.data && group.data.judges && (
        <Box mb={1}>
          <Typography variant="caption">
            {group.data.judges.length || 0} Reviewer(s)
          </Typography>
        </Box>
      )}
      <Box width="100%" mb={[1, 2]}>
        <Autocomplete
          id="reviewers-assign-group"
          autoHighlight
          options={judges.data || []}
          loading={judges.loading}
          disableCloseOnSelect
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => {
            if (option && option.firstName) {
              return `${option.firstName} ${
                option.lastName ? option.lastName : ""
              }`;
            }
          }}
          onChange={(event, newValue) => {
            assignJudgesToGroup(newValue);
          }}
          renderOption={(option) => (
            <>
              <Box lineHeight={"0.8rem"} width="100%">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body2">
                    <strong>
                      {option.firstName} {option.lastName}
                    </strong>
                  </Typography>
                  <Box fontSize="0.5rem">
                    <Box>{option.isActive ? "Active" : "Inactive"}</Box>
                  </Box>
                </Box>
                <Box mt={0.25}>
                  {option.groups &&
                    option.groups.map((e, index) => {
                      return (
                        <Chip
                          key={`${e.id}-${index}`}
                          classes={{
                            root: classes.ChipRoot,
                            label: classes.ChipLabel,
                          }}
                          label={e.name}
                          variant="outlined"
                          color={
                            group.data && e.id === group.data.id
                              ? "primary"
                              : "default"
                          }
                        />
                      );
                    })}
                </Box>
              </Box>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={`Add reviewers to ${group.data.name}`}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {judges.loading && <Spinner />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <TableContainer>
        <Table aria-label="reviewers-tables">
          <TableHead>
            <TableRow>
              <TableCell>Name / Email</TableCell>
              <TableCell>Groups</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {group.data && group.data.judges && group.data.judges.length > 0 ? (
              group.data.judges.map((judge, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        <strong>
                          {judge.firstName} {judge.lastName}
                        </strong>
                      </Typography>

                      <Link
                        color="textSecondary"
                        href={`mailto:${judge.emailAddress}`}
                      >
                        <Typography variant="caption">
                          {judge.emailAddress}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`Groups that are assigned to ${judge.firstName}`}
                        placement="top"
                      >
                        <Box>
                          {judge.groups &&
                            judge.groups.map((g, j) => {
                              return (
                                <Chip
                                  color="default"
                                  label={g.name}
                                  key={`${g.id}-${j}`}
                                />
                              );
                            })}
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`Remove reviewer from ${group.data.name}`}
                        placement="top"
                      >
                        <IconButton onClick={() => deleteJudge(judge.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No reviewers assigned to this group.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  group: state.group.group,
  judges: state.group.judges,
});

const mapDispatchToProps = {
  getJudgesForChallengeRound,
  judgesToGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupJudges);
