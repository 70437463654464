import axios from "../utils/axios";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
} from "react-query";
import { JOB_POST_BOARD_STATUS } from "../utils/globalValues";

export const useGetAllJobsForCommunity = (companyDetails) => {
  const result = useQuery(
    ["communityJobBoard", { companyId: companyDetails.id }],
    getAllJobsForCommunity,
    {
      enabled: !!companyDetails,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const getAllJobsForCommunity = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  const res = await axios.get(`/api/jobs/community/all/id/${companyId}`);
  return res.data;
};

export const useFetchMyJobsAndPostedStatus = (companyId, open) => {
  const result = useQuery(
    ["fetchMyJobsAndPostedStatus", { companyId }],
    fetchMyJobsAndPostedStatus,
    {
      enabled: open,
    }
  );

  if (result.isError) {
    // console.log("Error");
  }

  return result;
};

export const fetchMyJobsAndPostedStatus = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  const res = await axios.get(
    `/api/jobs/fetchMyJobsAndPostedStatus/${companyId}`
  );
  return res.data;
};

export const getAllJobsForCompany = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/jobs/company/${companyId}`);
};

export const useGetAllJobsInJobBoard = (companyId) => {
  const result = useQuery(
    ["orgJobBoardJobs", { companyId: companyId }],
    getAllJobsInJobBoard,
    {
      enabled: !!companyId,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const getAllJobsInJobBoard = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  const res = await axios.get(`/api/jobs/company/${companyId}/external`);
  return res.data;
};

export const createUpdateJob = async ({ companyId, jobDetails }) => {
  const body = {
    companyId,
    jobDetails,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/jobs`, body, config);
};

export const deleteJobPost = async ({ jobPostId }) => {
  return await axios.del(`/api/jobs/${jobPostId}`);
};

export const updateCandidateApplicationStatus = async ({
  jobPostId,
  candidateUserId,
  newStatus,
}) => {
  const body = { jobPostId, candidateUserId, newStatus };
  const config = { headers: { "Content-Type": "application/json" } };
  return await axios.post(`/api/jobs/candidate/status`, body, config);
};

export const useGetAllUserJobActivities = () => {
  const result = useQuery(["userJobActivities"], getAllUserJobActivities);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllUserJobActivities = async ({}) => {
  return await axios.get("/api/jobs/user/activity/all");
};

export const useDeleteJobPostFromBoard = (companyId) => {
  const queryClient = useQueryClient();
  return useMutation(deleteJobPostFromBoard, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["orgJobBoardJobs", { companyId: companyId }],
        (oldJobs) => {
          if (oldJobs && oldJobs.posts) {
            return {
              ...oldJobs,
              posts: oldJobs.posts.filter((elem) => elem.id !== data.jobPostId),
            };
          }
          return oldJobs;
        }
      );
    },
  });
};

export const useDeleteMyJobPostFromExternalBoard = (companyId) => {
  const queryClient = useQueryClient();
  return useMutation(deleteJobPostFromBoard, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["fetchMyJobsAndPostedStatus", { companyId }],
        (oldJobs) => {
          if (oldJobs) {
            const elemFoundIndex = oldJobs.findIndex(
              (elem) => elem.jobId === data.jobPostId
            );
            if (elemFoundIndex < 0) {
              return oldJobs;
            }
            let newJobPosts = [...oldJobs];
            newJobPosts[elemFoundIndex] = {
              ...newJobPosts[elemFoundIndex],
              approvalStatus: null,
              alreadyPostedInExternalCommunity: false,
            };

            return newJobPosts;
          }
          return oldJobs;
        }
      );
      queryClient.setQueryData(
        ["orgJobBoardJobs", { companyId: companyId }],
        (oldJobBoard) => {
          if (oldJobBoard && oldJobBoard.posts) {
            return {
              ...oldJobBoard,
              posts: oldJobBoard.posts.filter(
                (elem) => elem.id !== data.jobPostId
              ),
            };
          }
          return oldJobBoard;
        }
      );
      queryClient.setQueryData(
        ["communityJobBoard", { companyId: companyId }],
        (oldJobBoard) => {
          if (oldJobBoard && oldJobBoard.posts) {
            return {
              ...oldJobBoard,
              posts: oldJobBoard.posts.filter(
                (elem) => elem.id !== data.jobPostId
              ),
            };
          }
          return oldJobBoard;
        }
      );
    },
  });
};

export const deleteJobPostFromBoard = async ({ jobPostId, jobBoardId }) => {
  const res = await axios.del(`/api/jobs/${jobPostId}/external/${jobBoardId}`);
  return res.data;
};

export const useAddExternalJobToJobBoard = () => {
  const queryClient = useQueryClient();
  return useMutation(addExternalJobToJobBoard, {
    onSuccess: (data) => {
      const { post, JobPostBoardRelationship } = data;
      queryClient.setQueryData(
        [
          "fetchMyJobsAndPostedStatus",
          { companyId: JobPostBoardRelationship.companyId },
        ],
        (oldJobs) => {
          if (oldJobs) {
            const elemFoundIndex = oldJobs.findIndex(
              (elem) => elem.jobId === JobPostBoardRelationship.jobPostId
            );
            if (elemFoundIndex < 0) {
              return oldJobs;
            }
            let newJobPosts = [...oldJobs];
            newJobPosts[elemFoundIndex] = {
              ...newJobPosts[elemFoundIndex],
              approvalStatus: JobPostBoardRelationship.approvalStatus,
              alreadyPostedInExternalCommunity: true,
            };

            return newJobPosts;
          }
          return oldJobs;
        }
      );

      queryClient.setQueryData(
        ["orgJobBoardJobs", { companyId: JobPostBoardRelationship.companyId }],
        (oldJobBoard) => {
          if (oldJobBoard) {
            const newPost = {
              ...post,
              JobPostBoardRelationship: JobPostBoardRelationship,
            };
            return {
              ...oldJobBoard,
              posts: [newPost, ...(oldJobBoard.posts || [])],
            };
          }
          return oldJobBoard;
        }
      );

      if (
        JobPostBoardRelationship.approvalStatus ===
        JOB_POST_BOARD_STATUS.APPROVED
      ) {
        queryClient.setQueryData(
          [
            "communityJobBoard",
            { companyId: JobPostBoardRelationship.companyId },
          ],
          (oldJobBoard) => {
            if (oldJobBoard) {
              return {
                ...oldJobBoard,
                posts: [post, ...(oldJobBoard.posts || [])],
              };
            }
            return oldJobBoard;
          }
        );
      }
    },
  });
};

export const addExternalJobToJobBoard = async ({
  jobBoardId,
  jobPostId,
  companyId,
}) => {
  const body = { jobBoardId, jobPostId, companyId };
  const config = { headers: { "Content-Type": "application/json" } };
  const res = await axios.post(
    `/api/jobs/addExternalJobToJobBoard`,
    body,
    config
  );
  return res.data;
};

export const useUpdateJobPostBoardApprovalStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateJobPostBoardApprovalStatus, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["orgJobBoardJobs", { companyId: data.companyId }],
        (oldJobs) => {
          if (oldJobs && oldJobs.posts) {
            const elemFoundIndex = oldJobs.posts.findIndex(
              (elem) => elem.id === data.jobPostId
            );
            if (elemFoundIndex < 0) {
              return oldJobs;
            }
            let newJobPosts = [...oldJobs.posts];
            newJobPosts[elemFoundIndex] = {
              ...newJobPosts[elemFoundIndex],
              JobPostBoardRelationship: {
                ...newJobPosts[elemFoundIndex].JobPostBoardRelationship,
                approvalStatus: data.approvalStatus,
              },
            };

            return {
              ...oldJobs,
              posts: newJobPosts,
            };
          }
        }
      );
    },
  });
};

export const updateJobPostBoardApprovalStatus = async ({
  jobPostId,
  jobBoardCompanyId,
  approvalStatus,
}) => {
  const body = {
    jobPostId,
    jobBoardCompanyId,
    approvalStatus,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.post(
    `/api/jobs/jobPostBoard/approvalStatus`,
    body,
    config
  );
  return res.data;
};
