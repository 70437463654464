import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
// Icons
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import EditIcon from "@material-ui/icons/Edit";
// Components
import InterestsDialog from "./InterestsDialog";
import Button from "../../../shared/Button";

const InterestsButton = ({ myprofile }) => {
  const [modal, toggleModal] = useState(false);

  const toggle = () => {
    toggleModal(!modal);
  };
  return (
    <Fragment>
      <Button
        startIcon={
          myprofile &&
          myprofile.questionsAnswers &&
          myprofile.questionsAnswers.length > 0 ? (
            <EditIcon />
          ) : (
            <AddOutlinedIcon />
          )
        }
        onClick={toggle}
      >
        {myprofile &&
        myprofile.questionsAnswers &&
        myprofile.questionsAnswers.length > 0
          ? "Edit"
          : "Add"}{" "}
        Interests
      </Button>
      <InterestsDialog modal={modal} toggle={toggle} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
});
export default connect(mapStateToProps)(InterestsButton);
