import {
  GET_SKILLSET,
  QUESTIONS_ANSWERS,
  PROJECT_CATEGORIES,
  PROJECT_STATUS,
  EXTLINK_TYPE,
  PUBLIC_PROFILE_TYPE,
  JOB_CATEGORY_TYPE,
  GENDER_TYPE,
  RACE_TYPE
} from "../actions/actionTypes";

const initialState = {
  skillSet: [],
  questionsAnswers: [],
  projectCategories: [],
  projectStatus: [],
  extLinks: [],
  publicProfileType: [],
  jobCategoryType: [],
  genderTypes: [],
  raceTypes: []
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case JOB_CATEGORY_TYPE:
      return {
        ...state,
        jobCategoryType: payload
      };
    case GET_SKILLSET:
      return {
        ...state,
        skillSet: payload
      };
    case QUESTIONS_ANSWERS:
      return {
        ...state,
        questionsAnswers: payload
      };
    case PROJECT_CATEGORIES:
      return {
        ...state,
        projectCategories: payload
      };
    case PROJECT_STATUS:
      return {
        ...state,
        projectStatus: payload
      };
    case EXTLINK_TYPE:
      return {
        ...state,
        extLinks: payload
      };
    case PUBLIC_PROFILE_TYPE:
      return {
        ...state,
        publicProfileType: payload
      };
    case GENDER_TYPE:
      return {
        ...state,
        genderTypes: payload
      };
      case RACE_TYPE:
        return {
          ...state,
          raceTypes: payload
        }
    default:
      return state;
  }
}
