import React, { useEffect } from "react";
import {
  Route,
  Link,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCompanyProfile, clearCompany } from "../../redux/actions/company";
// Material UI
import { makeStyles, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// Components
import CustomSwitch from "../../shared/CustomSwitch";
import OrgAppBar from "../../shared/OrgAppBar";
import OrgProfileGeneral from "./components/OrgProfileGeneral";
import OrgProfileCommunity from "./components/OrgProfileCommunity";
import OrgProfileBranding from "./components/OrgProfileBranding";
import OrgProfileSocials from "./components/OrgProfileSocials";
import OrgManagers from "./components/OrgManagers";
import OrgMessagingAppSettings from "./components/OrgMessagingAppSettings";
import OrgInviteManager from "./components/OrgInviteManager";
// Icons
import { IoSettingsOutline } from "react-icons/io5";
// Utils
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabs: {
    textAlign: "left",
  },
}));

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      id={`jobs-tabpanel-${index}`}
      aria-labelledby={`jobs-tab-${index}`}
      width="100%"
      pt={{ xs: 2, md: 4 }}
      {...other}
    >
      <Box>{children}</Box>
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `org-tab-${index}`,
    "aria-controls": `org-tabpanel-${index}`,
  };
}

const OrgSettingsTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 600,
    fontSize: theme.typography.body2.fontSize,
    borderRadius: theme.shape.borderRadius,
  },
  selected: {},
}))((props) => <Tab {...props} />);

const SETTINGS_TABS = [
  {
    name: "General",
    component: OrgProfileGeneral,
    urlEnding: "",
  },
  {
    name: "Community",
    component: OrgProfileCommunity,
    urlEnding: "/community",
  },
  {
    name: "Branding",
    component: OrgProfileBranding,
    urlEnding: "/branding",
  },
  {
    name: "Slack / Discord",
    component: OrgMessagingAppSettings,
    urlEnding: "/messaging",
  },
  {
    name: "Invite",
    component: OrgInviteManager,
    urlEnding: "/invite",
  },
  {
    name: "Managers",
    component: OrgManagers,
    urlEnding: "/managers",
  },
  {
    name: "Socials",
    component: OrgProfileSocials,
    urlEnding: "/socials",
  },
];

const Organization = ({ companyProfile, getCompanyProfile }) => {
  const classes = useStyles();
  const { orgId } = useParams();
  const location = useLocation();
  let { url } = useRouteMatch();

  const fetchCompanyProfile = async (orgId) => {
    await getCompanyProfile(orgId);
  };

  useEffect(() => {
    if (orgId) {
      fetchCompanyProfile(orgId);
    }
  }, [orgId]);

  return (
    <Box height="100%" margin="auto">
      <Box maxWidth="700px" width="100%" mx="auto">
        <OrgAppBar
          title={
            <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
              <IoSettingsOutline /> Settings
            </Box>
          }
          subtitle="Manage your organization settings"
        />
        <PerfectScrollbar>
          <div className={classes.root}>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Organization setting tabs"
              value={location.pathname}
              className={classes.tabs}
            >
              {(SETTINGS_TABS || []).map((tab, index) => {
                return (
                  <OrgSettingsTab
                    component={Link}
                    to={`${url}${tab.urlEnding}`}
                    value={`${url}${tab.urlEnding}`}
                    key={index}
                    label={tab.name}
                    {...a11yProps(index)}
                    className={classes.tab}
                    wrapped
                  />
                );
              })}
            </Tabs>
            {companyProfile && (
              <CustomSwitch>
                {(SETTINGS_TABS || []).map((tab, index) => {
                  return (
                    <Route key={index} exact path={`${url}${tab.urlEnding}`}>
                      <TabPanel index={index}>
                        <tab.component />
                      </TabPanel>
                    </Route>
                  );
                })}
              </CustomSwitch>
            )}
          </div>
        </PerfectScrollbar>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  getCompanyProfile,
  clearCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
