import React, { useState, useEffect } from "react";
// React router
import { useLocation, useHistory } from "react-router";
// React query
import { useQuery } from "react-query";
// Redux
import { connect } from "react-redux";
import {
  getCurrentProfile,
  addEducation,
  createProfile,
  addProfileQuestions,
} from "../../redux/actions/profile";
import { updateUserAccount } from "../../redux/actions/auth";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// Components
import Account from "./components/Account";
import Education from "./components/Education";
import Demographics from "./components/Demographics";
import Interests from "./components/Interests";
import Work from "./components/Work";
import Button from "../../shared/Button";
// React spring
import { useSpring, animated, config } from "@react-spring/web";

const STEPS = [Account, Education, Demographics, Interests, Work];

const Onboarding = ({
  user,
  myprofile,
  getCurrentProfile,
  addEducation,
  createProfile,
  updateUserAccount,
  addProfileQuestions,
}) => {
  const [activeStep, setStep] = useState(0);

  const StepComponent = STEPS[activeStep];

  const handleNext = () => {
    if (activeStep < STEPS.length - 1) {
      setStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setStep(activeStep - 1);
    }
  };

  const [formData, setFormData] = useState({
    userName: "",
    aboutMe: "",
    school: "",
    degree: "",
    fieldOfStudy: "",
    completionDate: new Date(),
    startDate: new Date(),
    privacySetting: true,
    activelySeekingJob: true,
    gender: { id: 0, name: "" },
    race: [],
    interestsAnswers: [],
  });

  useEffect(() => {
    let newFormData = { ...formData };
    if (user) {
      newFormData.userName = newFormData.userName
        ? newFormData.userName
        : user.userName || "";
    }
    if (myprofile) {
      newFormData.aboutMe = newFormData.aboutMe
        ? newFormData.aboutMe
        : myprofile.aboutMe || "";
      newFormData.privacySetting = myprofile.privacySetting;
      newFormData.activelySeekingJob = myprofile.activelySeekingJob;

      let education = myprofile.education && myprofile.education[0];
      if (education) {
        newFormData.school = education.school || "";
        newFormData.degree = education.degree || "";
        newFormData.fieldOfStudy = education.fieldOfStudy || "";
        newFormData.completionDate = new Date(education.completionDate);
        newFormData.startDate = new Date(education.startDate);
      }
    }
    setFormData({
      ...newFormData,
    });
  }, [myprofile, user]);

  useQuery(["myprofile", user?.id], async () => await getCurrentProfile());

  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location?.search);
  const redirect = query.get("redirect");

  const handleChange = (e) => {
    let value;
    const name = e.target.name;
    const type = e.target.type;
    if (type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (activeStep === STEPS.length - 1) {
      const response = await updateUserAccount({
        userName: formData.userName,
      });
      if (response && response.success) {
        await createProfile({
          aboutMe: formData.aboutMe,
          activelySeekingJob: formData.activelySeekingJob,
          privacySetting: formData.privacySetting,
          gender: formData.gender && formData.gender.id,
          race: (formData.race || []).map((elem) => elem.id),
        });
        await addEducation({
          school: formData.school,
          degree: formData.degree,
          fieldOfStudy: formData.fieldOfStudy,
          completionDate: formData.completionDate,
          startDate: formData.startDate,
        });
        await addProfileQuestions({
          questionChoice: formData.interestsAnswers,
        });
        history.push(redirect ? redirect : `/me`);
      } else {
        // Send the user back to the username page
        setStep(0);
      }
    } else {
      handleNext();
    }
  };

  const animationProps = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: 10 },
    config: {
      ...config.slow,
    },
  });

  return (
    <animated.div style={animationProps}>
      <Box my={{ xs: 2, md: 4, lg: 8 }}>
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            {StepComponent && (
              <Box>
                <StepComponent
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                />
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="flex-end"
              mt={{ xs: 2, md: 4 }}
              style={{ gap: "8px" }}
            >
              {activeStep > 0 && (
                <Button onClick={handleBack} variant="outlined">
                  Back
                </Button>
              )}

              <Button size="medium" type="submit">
                {activeStep === STEPS.length - 1 ? "Create" : "Continue"}
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </animated.div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  myprofile: state.profile.myprofile,
  genderTypes: state.typedef.genderTypes,
  raceTypes: state.typedef.raceTypes,
});

const mapDispatchToProps = {
  getCurrentProfile,
  addEducation,
  createProfile,
  updateUserAccount,
  addProfileQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
