import React from "react";
import PropTypes from "prop-types";
// React Router
// hooks
import { useGetAllChallengesForCommunity } from "../../hooks/challenge";
// Material UI
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Icons
import { IoBulbOutline } from "react-icons/io5";
// Components
import EmptyState from "../../shared/EmptyState";
import Spinner from "../../shared/Spinner";
import CommunityProgramCard from "./components/CommunityProgramCard";

const CommunityPrograms = ({ companyDetailsQuery }) => {
  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const {
    isLoading: isLoadingPrograms,
    data: { data: communityPrograms } = { data: null },
    isError: isErrorPrograms,
    error: errorPrograms,
  } = useGetAllChallengesForCommunity(companyDetails);

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container maxWidth="xl">
          <Box py={2} px={{ xs: 0, md: 2 }}>
            <Box>
              {isErrorPrograms && (
                <Alert severity="error">
                  {errorPrograms &&
                    errorPrograms.data &&
                    errorPrograms.data.msg}
                </Alert>
              )}
              {isLoadingPrograms ? (
                <Spinner />
              ) : (
                <Box>
                  <Typography variant="h5" component="h2">
                    <Box
                      mb={2}
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                      style={{ gap: "8px" }}
                    >
                      <IoBulbOutline /> Programs
                    </Box>
                  </Typography>
                  {communityPrograms && communityPrograms.length > 0 ? (
                    <Grid container spacing={2}>
                      {communityPrograms.map((program) => (
                        <Grid key={program.id} item xs={12}>
                          <CommunityProgramCard program={program} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <EmptyState
                      title="No programs"
                      subtitle="There are no programs for the community at this time."
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

CommunityPrograms.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

export default CommunityPrograms;
