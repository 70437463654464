import React, { Fragment } from "react";
import { connect } from "react-redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import { useTheme, makeStyles } from "@material-ui/core/styles";

// Components
import Form from "@rjsf/core";
import widgets from "../../../shared/CustomFormWidgets";
import CustomFieldTemplate from "../../../shared/CustomFieldTemplate";
import ObjectFieldTemplate from "../../../shared/ObjectFieldTemplate";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
  },
}));

const onError = (errors) => console.log(errors);

const FormBuilderPreviewDialog = ({ customForm, open, setOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>

          <Box>
            <Typography variant="h6">Submission Form Preview</Typography>
            <Typography variant="body2">
              This is a preview of the form that users will see when they are
              submitting for this round. Please save before previewing this
              form.
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <div className="rjsf builder-form">
          <FormGroup>
            {customForm &&
              customForm.data &&
              customForm.data.jsonSchema &&
              customForm.data.uiSchema && (
                <Form
                  FieldTemplate={CustomFieldTemplate}
                  ObjectFieldTemplate={ObjectFieldTemplate}
                  schema={customForm.data.jsonSchema}
                  uiSchema={customForm.data.uiSchema}
                  onError={onError}
                  widgets={widgets}
                >
                  <button className="hidden" type="submit"></button>
                </Form>
              )}
          </FormGroup>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormBuilderPreviewDialog);
