import React, { useState, useEffect } from "react";
// React router dom
import { useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// React query
import { addEditRoom } from "../../hooks/room";
import { useMutation, QueryClient } from "react-query";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
// Utils
import { ROOM_TYPE, POST_TYPES } from "../../utils/globalValues";
import { capitalize } from "../../utils/string";
// Components
import EmojiPicker from "../EmojiPicker";
import Button from "../Button";
// Icons
import {
  HiChevronDown,
  HiChevronUp,
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";

const AddEditRoom = ({ companyProfile, room, setAlert }) => {
  const history = useHistory();
  const queryClient = new QueryClient();

  const [roomFormData, setRoomFormData] = useState({
    name: "",
    description: "",
    postTypes: POST_TYPES
      ? new Set(Object.keys(POST_TYPES).map((key) => POST_TYPES[key]))
      : new Set(),
    emoji: ":ok_hand:",
    type: ROOM_TYPE.CHAT,
    readOnly: false,
    needApprovalToJoin: false,
    postsNeedApproval: false,
    isDefault: true,
    isPublic: true,
    isActive: true,
  });

  useEffect(() => {
    if (room) {
      setRoomFormData({
        ...roomFormData,
        name: room.name,
        description: room.description,
        postTypes:
          room.postTypes && room.postTypes.length > 0
            ? new Set(room.postTypes)
            : POST_TYPES
            ? new Set(Object.keys(POST_TYPES).map((key) => POST_TYPES[key]))
            : new Set(),
        emoji: room.emoji,
        type: room.type,
        readOnly: room.readOnly ? true : false,
        needApprovalToJoin: room.needApprovalToJoin ? true : false,
        postsNeedApproval: room.postsNeedApproval ? true : false,
        isDefault: room.isDefault ? true : false,
        isPublic: room.isPublic ? true : false,
        isActive: room.isActive ? true : false,
      });
    }
  }, [room]);

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(true);

  const handleRoomChange = (e) => {
    const target = e.target;
    let value =
      target.type === "checkbox"
        ? target.checked
        : target.type == "radio"
        ? parseInt(target.value)
        : target.value;
    const name = target.name;

    if (name === "name") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }

    setRoomFormData({
      ...roomFormData,
      [name]: value,
    });
  };

  const handlePostTypeChange = (e) => {
    const target = e.target;
    const checked = target.checked;
    let name = target.name;
    name = name.toUpperCase();

    const typeId = POST_TYPES[name];

    let postTypes = roomFormData.postTypes;

    if (checked) {
      postTypes.add(typeId);
    } else {
      postTypes.delete(typeId);
    }

    setRoomFormData({
      ...roomFormData,
      postTypes: postTypes,
    });
  };

  const addEditRoomMutation = useMutation(addEditRoom, {
    onSuccess: async (data) => {
      if (data && data.data) {
        if (!room) {
          await queryClient.invalidateQueries([
            "communityRooms",
            { communityId: companyProfile.id },
          ]);
        }
        await queryClient.setQueryData(
          ["communityRoom", { roomId: data.data.id }],
          data.data
        );
        await setAlert(
          `Succesfully ${room ? "updated" : "created"} the room!`,
          "success"
        );
        if (!room) {
          history.push(`/org/${companyProfile.orgId}/rooms`);
        }
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    addEditRoomMutation.mutate({
      roomDetails: {
        ...roomFormData,
        id: room ? room.id : undefined,
        communityId: companyProfile.id,
        postTypes: [...roomFormData.postTypes],
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>{room ? "Edit" : "Create New"} Room</Box>
            </Typography>
            <Typography variant="body2" component="div" color="textSecondary">
              <Box mb={2}>
                Get started {room ? "editing your room" : "creating a new room"}
                .
              </Box>
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" component="div">
              <Box fontWeight={600}>Overview</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Emoji</InputLabel>
              <Box>
                <EmojiPicker
                  emojiColons={roomFormData.emoji}
                  handleEmojiChange={(emoji) =>
                    setRoomFormData({ ...roomFormData, emoji: emoji.colons })
                  }
                />
              </Box>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel required>Name</InputLabel>
              <TextField
                placeholder="Enter a name for your room"
                id="name"
                name="name"
                required
                value={roomFormData.name}
                onChange={handleRoomChange}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Description</InputLabel>
              <TextField
                placeholder="Enter a description for your room"
                id="description"
                name="description"
                value={roomFormData.description}
                onChange={handleRoomChange}
                multiline
                rows={4}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Room Type</InputLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="type"
                  name="type"
                  value={roomFormData.type}
                  onChange={handleRoomChange}
                >
                  <FormControlLabel
                    value={ROOM_TYPE.CHAT}
                    control={<Radio />}
                    label={
                      <Box py={1} ml={1}>
                        <Box display="flex" alignItems="center">
                          <HiOutlineChat />
                          <Typography variant="body1" component="div">
                            <Box ml={1} fontWeight={600}>
                              Chat
                            </Box>
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.1}>
                            Chat rooms are structured similarly to rooms in
                            Slack or Discord. These are perfect to encourage
                            more causal conversation and a great place for
                            people to meet each other.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value={ROOM_TYPE.FORUM}
                    control={<Radio />}
                    label={
                      <Box py={1} ml={1}>
                        <Box display="flex" alignItems="center">
                          <HiOutlinePencilAlt />
                          <Typography variant="body1" component="div">
                            <Box ml={1} fontWeight={600}>
                              Forum
                            </Box>
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.1}>
                            Forum rooms are structured to resemble a classic
                            forum. Optimal for posts that are in many formats
                            and work really well for announcements and longer
                            discussions.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                  {/* <FormControlLabel
                    value={ROOM_TYPE.BOARD}
                    control={<Radio />}
                    label={
                      <Box py={1} ml={1}>
                        <Box display="flex" alignItems="center">
                          <HiOutlineViewGrid />
                          <Typography variant="body1" component="div">
                            <Box ml={1} fontWeight={600}>
                              Board
                            </Box>
                          </Typography>
                        </Box>
                        <Typography
                          variant="body2"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.1}>
                            Board rooms are structured to feel like a message
                            board. These are great to showcase job openings, new
                            opportunities, or simply create a board to display a
                            list of resources or items for your members to view.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  /> */}
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </Grid>
          {/* <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Posts Allowed</InputLabel>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >
                <Box lineHeight={1.2}>
                  These are the types of posts that are allowed to be made in
                  this room.
                </Box>
              </Typography>
              <Box mt={2}>
                <Grid container spacing={2}>
                  {POST_TYPES &&
                    Object.keys(POST_TYPES).map((key, index) => {
                      const value = POST_TYPES[key];
                      const typeName = capitalize(key);
                      const checked = roomFormData?.postTypes?.has(value);
                      return (
                        <Grid item xs={12} sm={6} md={4} key={index} mb={1}>
                          <Tooltip
                            title={`Allow members to post ${typeName.toLowerCase()}s in this room.`}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  id={typeName}
                                  name={typeName}
                                  color="primary"
                                  
                                  checked={checked}
                                  onChange={handlePostTypeChange}
                                />
                              }
                              label={
                                <Box ml={1}>
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    gutterBottom
                                  >
                                    <Box fontWeight={600}>{typeName}</Box>
                                  </Typography>
                                </Box>
                              }
                            />
                          </Tooltip>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </FormGroup>
          </Grid> */}
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" component="div">
              <Box fontWeight={600}>Settings</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id="isDefault"
                    name="isDefault"
                    color="primary"
                    checked={roomFormData.isDefault}
                    onChange={handleRoomChange}
                  />
                }
                label={
                  <Box ml={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                      <Box fontWeight={600}>Default</Box>
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        If you select this option, this room will be considered
                        a default room. This means that once a member joins your
                        community or is approved, they will automatically be
                        added to this room.
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id="isPublic"
                    name="isPublic"
                    color="primary"
                    checked={roomFormData.isPublic}
                    onChange={handleRoomChange}
                  />
                }
                label={
                  <Box ml={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                      <Box fontWeight={600}>Public</Box>
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        If you select this option, this room is public and
                        members will be able to view and join. If this room is
                        private, then members are only able to join through an
                        invite link.
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    id="isActive"
                    name="isActive"
                    color="primary"
                    checked={roomFormData.isActive}
                    onChange={handleRoomChange}
                  />
                }
                label={
                  <Box ml={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                      <Box fontWeight={600}>Active</Box>
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        If you deselect this option, this room will be inactive.
                        Inactive rooms will not be visible to anyone. However,
                        the room will not be deleted and you can reactivate the
                        room at anytime.
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" style={{ gap: "12px" }}>
              <Typography
                onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                gutterBottom
                variant="body1"
                component="div"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ cursor: "pointer", gap: "8px" }}
                  fontWeight={600}
                >
                  Advanced Settings{" "}
                  {showAdvancedSettings ? <HiChevronUp /> : <HiChevronDown />}
                </Box>
              </Typography>
            </Box>
          </Grid>
          {showAdvancedSettings && (
            <>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="readOnly"
                        name="readOnly"
                        color="primary"
                        checked={roomFormData.readOnly}
                        onChange={handleRoomChange}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Read only</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            If you select this option, regular members will not
                            be able to post in this room. Only community
                            managers will be able to make posts in this room.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="needApprovalToJoin"
                        name="needApprovalToJoin"
                        color="primary"
                        checked={roomFormData.needApprovalToJoin}
                        onChange={handleRoomChange}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Need approval to join</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            If you select this option, members will need to be
                            approved from a manager to join this room.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="postsNeedApproval"
                        name="postsNeedApproval"
                        color="primary"
                        checked={roomFormData.postsNeedApproval}
                        onChange={handleRoomChange}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>
                            Posts need approval{" "}
                            <Chip
                              label={
                                <Box
                                  fontWeight={600}
                                  fontSize="caption.fontSize"
                                >
                                  COMING SOON
                                </Box>
                              }
                            />
                          </Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            If you select this option, posts will need approval
                            from a manager in order to be visible in the room to
                            everyone.
                          </Box>
                        </Typography>
                      </Box>
                    }
                    disabled
                  />
                </FormGroup>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button type="submit">{room ? "Save" : "Create"} Room</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AddEditRoom);
