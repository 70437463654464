const rules = {
  1: {
    static: [
      "user-dashboard:visit",
      "project:create",
      "project:edit",
      "project:upload-image",
      "project:comment",
      "project:like",
      "profile:create",
      "profile:edit",
      "profile:upload-resume",
      "profile:add-interests",
      "profile:add-education",
      "profile:add-experience",
      "profile:add-links",
      "profile:add-skills",
      "profile:view-interests",
      "user:edit",
      "user:upload-image",
      "user:change-password",
      "challenge:register",
      "challenge:submit",
    ],
    dynamic: {
      "profile:view": ({ userId, profileId, privacySetting }) => {
        if (privacySetting !== null && privacySetting) return true;
        if (!userId || !profileId) return false;
        return userId === profileId;
      },
      "profile:view-go-back-dashboard": ({ userId, profileId }) => {
        if (!userId || !profileId) return false;
        return userId === profileId;
      },
      "profile:view-interests": ({ userId, profileId }) => {
        return true;
        // if (!userId || !profileId) return false;
        // return userId === profileId;
      },
      "profile:view-workInfo": ({ userId, profileId }) => {
        if (!userId || !profileId) return false;
        return userId === profileId;
      },
      "project:view-profile": ({ userId, profileId, privacySetting }) => {
        if (privacySetting !== null && privacySetting) return true;
        if (userId === null || profileId === null) return false;
        return userId === profileId;
      },
      "project:view": ({ userId, team, isPublic }) => {
        if (isPublic !== null && isPublic) return true;
        if (!isPublic && !userId) return false;
        for (let i = 0; i < team.length; i++) {
          if (team[i] && team[i].userAccountId === userId) return true;
        }
        return false;
      },
    },
  },
  2: {
    static: [
      "org-dashboard:visit",
      "user:edit",
      "user:upload-image",
      "user:change-password",
      "project:view",
      "project:comment",
      "project:like",
      "profile:view-workInfo",
      "profile:view-interests",
      "project:view-profile",
      "profile:view",
      "challenge:show-company-register-button",
      "challenge:view-submissions",
    ],
    dynamic: [],
  },
};

export default rules;
