import { Link } from "react-router-dom";

export default function CallToAction() {
  return (
    <div className="px-4 py-12 md:py-24">
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-3xl font-bold leading-tight tracking-normal text-gray-100 sm:text-4xl sm:leading-tight lg:text-5xl lg:leading-tight">
          <span className="block bg-gradient-to-r from-blue-400 to-fuchsia-600 bg-clip-text text-transparent">
            Supercharge your community.
          </span>
          <span className="block">Get started with Pralent today.</span>
        </h2>
        <p className="mx-auto mt-4 max-w-xl text-lg leading-6 text-gray-400 md:text-xl">
          All of your community in one place: Members, Events, Programs, Jobs,
          Networking, & more. Free to use forever.
        </p>
        <Link
          to="/signup"
          className="mt-8 inline-flex w-full items-center justify-center rounded-md bg-gray-100 py-2 px-4 text-base font-semibold text-gray-900 duration-300 ease-in-out hover:bg-gray-200 sm:w-auto"
        >
          Sign up for free
        </Link>
      </div>
    </div>
  );
}
