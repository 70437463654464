import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// Icons
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
// Components
import Button from "../Button";
// Utils
import { convertUTCTimeToZonedTime } from "../../utils/utilFunc";
import { DEFAULT_TIME_ZONE } from "../../utils/globalValues";

export const OrgEventAppBar = ({ event, companyProfile }) => {
  const params = useParams();
  const location = useLocation();
  const orgId = params && params.orgId ? params.orgId : "";

  const EVENT_TABS = [
    {
      name: "Attendees",
      icon: <PeopleOutlinedIcon />,
      route: `/org/${orgId}/events/${params.slug}/attendees`,
    },
    {
      name: "Edit",
      icon: <EditOutlinedIcon />,
      route: `/org/${orgId}/events/${params.slug}/edit`,
    },
    // {
    //   name: "Emails",
    //   icon: <MailOutlineOutlinedIcon />,
    //   route: `/org/${orgId}/events/${params.slug}/emails`,
    // },
    {
      name: "Insights",
      icon: <PollOutlinedIcon />,
      route: `/org/${orgId}/events/${params.slug}/insights`,
    },
    {
      name: "Settings",
      icon: <SettingsOutlinedIcon />,
      route: `/org/${orgId}/events/${params.slug}/settings`,
    },
  ];

  return (
    <Box mb={{ xs: 2, md: 4 }}>
      <Container maxWidth="lg">
        {event && (
          <Box display="flex" mb={2}>
            <Box flexGrow={1}>
              <Typography variant="h5" component="h1">
                <Box fontWeight={600}>{event.title}</Box>
              </Typography>
              <Typography color="textSecondary" variant="body2" component="div">
                <Box>
                  {convertUTCTimeToZonedTime(
                    event.startDateTime,
                    DEFAULT_TIME_ZONE
                  )}{" "}
                  -{" "}
                  {convertUTCTimeToZonedTime(
                    event.endDateTime,
                    DEFAULT_TIME_ZONE
                  )}
                </Box>
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" component="div">
                <Box fontWeight={600}>View event</Box>
              </Typography>
              <Button
                component={Link}
                to={`/c/${companyProfile.communitySlug}/events/${event.slug}`}
                variant="outlined"
                style={{
                  textTransform: "none",
                  lineHeight: 1.1,
                }}
                endIcon={<OpenInBrowserOutlinedIcon />}
              >{`/c/${companyProfile.communitySlug}/events/${event.slug}`}</Button>
            </Box>
          </Box>
        )}
        <Tabs
          variant="scrollable"
          scrollButtons="off"
          value={location.pathname}
        >
          {EVENT_TABS &&
            EVENT_TABS.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <Box display="flex" alignItems="center">
                      <Box>{tab.name}</Box>
                    </Box>
                  }
                  component={Link}
                  to={tab.route}
                  value={tab.route}
                />
              );
            })}
        </Tabs>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgEventAppBar);
