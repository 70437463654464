import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// react-query
import { useMutation, useQuery, QueryClient } from "react-query";
import { addEditArticle, getKnowledgeArticleById } from "../../hooks/knowledge";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// Icons
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
// Components
import Button from "../../shared/Button";
import Spinner from "../../shared/Spinner";
import RichTextEditor from "../../shared/RichTextEditor";

const OrgKnowledgeArticleCreate = ({ companyProfile, setAlert }) => {
  const history = useHistory();

  const { articleId } = useParams();

  // ARTICLE FORM DATA
  const [articleFormData, setArticleFormData] = useState({
    title: "",
    content: "",
    isPublished: true,
  });

  // GET THE ARTICLE IF EDIT MODE
  const {
    isLoading,
    data: { data: knowledgeArticle } = { data: null },
    isError,
    error,
  } = useQuery(
    ["knowledgeArticle", { articleId: articleId }],
    getKnowledgeArticleById,
    {
      enabled: !!articleId,
    }
  );

  // ADD/EDIT MUTATION
  const addEditArticleMutation = useMutation(addEditArticle, {
    onSuccess: (_) => {
      setAlert(
        `Successfully ${knowledgeArticle ? "updated" : "created"} article`,
        "success"
      );
      if (!knowledgeArticle) {
        history.push(`/org/${companyProfile.orgId}/knowledge`);
      }
    },
  });

  useEffect(() => {
    if (knowledgeArticle) {
      setArticleFormData({
        ...articleFormData,
        id: knowledgeArticle.id,
        title: knowledgeArticle.title,
        content: knowledgeArticle.content,
        isPublished: knowledgeArticle.isPublished,
      });
    }
  }, [knowledgeArticle]);

  const handleArticleChange = (e) => {
    const target = e.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type == "radio"
        ? parseInt(target.value)
        : target.value;
    const name = target.name;

    setArticleFormData({
      ...articleFormData,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addEditArticleMutation.mutate({
      companyId: companyProfile.id,
      articleDetails: articleFormData,
    });
  };
  return (
    <Container maxWidth="sm">
      {isError && (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      )}
      <form onSubmit={onSubmit}>
        <Grid container spacing={4}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  component={Link}
                  to={`/org/${companyProfile.orgId}/knowledge`}
                >
                  <ArrowBackIcon />{" "}
                  <Typography variant="body2" component="div">
                    <Box ml={1}>Back to knowledge base</Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  <Box fontWeight={600}>
                    {knowledgeArticle ? "Edit" : "Create new"} article
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box mb={2}>Get started created your knowledge article!</Box>
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Title</InputLabel>
                  <TextField
                    placeholder="Enter a title for your event"
                    id="title"
                    name="title"
                    required
                    value={articleFormData.title}
                    onChange={handleArticleChange}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Content</InputLabel>
                  <RichTextEditor
                    value={articleFormData.content}
                    setValue={(value) =>
                      setArticleFormData({
                        ...articleFormData,
                        content: value,
                      })
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isPublished"
                        name="isPublished"
                        color="primary"
                        checked={articleFormData.isPublished}
                        onChange={handleArticleChange}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Publish</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            If you select this option, this article will be
                            published on your community page.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button type="submit">
                    {knowledgeArticle ? "Save" : "Create"} article
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgKnowledgeArticleCreate);
