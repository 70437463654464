import React, { useRef } from "react";
// React router
import { useHistory, useLocation } from "react-router";
// react-query
import { useMutation, useQueryClient } from "react-query";
import {
  useGetUserEventActivity,
  registerUserForEvent,
  removeUserFromEvent,
  useGetNumberOfEventRegistrations,
} from "../../../hooks/event";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
// Colors
import blueGrey from "@material-ui/core/colors/blueGrey";
// Components
import Button from "../../../shared/Button";
import Spinner from "../../../shared/Spinner";
import LogInForm from "../../../shared/LogInForm";
// utils
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";
import {
  DEFAULT_TIME_ZONE,
  EVENT_REGISTRATION_STATUS,
} from "../../../utils/globalValues";
import Reward from "react-rewards";

const EventRegister = ({
  event,
  numberOfRegistrations,
  user,
  isAuthenticated,
  setAlert,
}) => {
  const history = useHistory();
  const location = useLocation();
  const rewardRef = useRef(null);

  const {
    isLoading,
    isError,
    data: userEventActivity = { data: null },
    error,
  } = useGetUserEventActivity(event, user);

  const queryClient = useQueryClient();

  const registerUserEventMutation = useMutation(registerUserForEvent, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["userEventActivity", { eventId: event.id, userAccountId: user.id }],
        data
      );
      rewardRef && rewardRef.current && rewardRef.current.rewardMe();
      setAlert("Successfully registered for event.", "success");
    },
    onError: (error) => {
      setAlert(error.data.msg, "error");
    },
  });

  const removeUserFromEventMutation = useMutation(removeUserFromEvent, {
    onSuccess: (_) => {
      queryClient.setQueryData(
        ["userEventActivity", { eventId: event.id, userAccountId: user.id }],
        null
      );
    },
  });

  const handleRegister = async (userId) => {
    registerUserEventMutation.mutate({
      userAccountId: user ? user.id : userId,
      eventId: event.id,
    });
  };

  const handleLeave = () => {
    if (
      userEventActivity &&
      userEventActivity.data &&
      window.confirm(
        "Are you sure you want to remove yourself from this event?"
      )
    ) {
      removeUserFromEventMutation.mutate({
        registrationId: userEventActivity.data.id,
        eventId: event.id,
      });
    }
  };

  const requireRegistration = event.requireRegistration;
  const userIsRegistered = userEventActivity && userEventActivity.data;

  const registrationStatus =
    userEventActivity &&
    userEventActivity.data &&
    userEventActivity.data.registrationStatus;
  const userIsApproved =
    registrationStatus &&
    registrationStatus === EVENT_REGISTRATION_STATUS.APPROVED;
  const userIsPending =
    registrationStatus &&
    registrationStatus === EVENT_REGISTRATION_STATUS.PENDING;
  const userIsDenied =
    registrationStatus &&
    registrationStatus === EVENT_REGISTRATION_STATUS.DENIED;

  const showEventOnlineLinks = event.link
    ? !requireRegistration || (userIsRegistered && userIsApproved)
    : null;

  const registrationLimitHit =
    event.maxRegistrations !== null &&
    numberOfRegistrations >= event.maxRegistrations;

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {userIsApproved ? (
            <Typography variant="body1" color="textPrimary" component="div">
              <Box mb={2} lineHeight={1.2}>
                See you soon!
              </Box>
            </Typography>
          ) : userIsPending ? (
            <Typography variant="body1" color="textSecondary" component="div">
              <Box mb={2} lineHeight={1.2}>
                The event organizers are reviewing your information and will be
                letting you know shortly about your status to this event.
              </Box>
            </Typography>
          ) : userIsDenied ? (
            <Typography variant="body1" color="textSecondary" component="div">
              <Box mb={2} lineHeight={1.2} color="red">
                Unfortunately, the event organizers have decided to deny you
                access to this event. If this is a problem, please contact the
                event organizers.
              </Box>
            </Typography>
          ) : null}

          {showEventOnlineLinks && (
            <>
              <Typography
                variant="body2"
                component="div"
                color="textSecondary"
                gutterBottom
              >
                <Box mb={1}>Online event link</Box>
              </Typography>
              <Button
                component={Link}
                href={event.link}
                target="_blank"
                rel="noopener noreferrer"
                size="medium"
                fullWidth
              >
                Join Online
              </Button>
            </>
          )}

          {!event.link && !requireRegistration && !userIsRegistered && (
            <Typography variant="body1" color="textSecondary" component="div">
              <Box lineHeight={1.2} fontStyle="italic">
                {isBefore(new Date(), new Date(event.endDateTime))
                  ? "🎉 Hope to see you soon at our event!"
                  : "This event has ended."}
              </Box>
            </Typography>
          )}

          {!userIsRegistered && requireRegistration && (
            <>
              <Typography
                variant="body2"
                component="div"
                color="textSecondary"
                gutterBottom
              >
                <Box mb={1}>
                  Register to attend{" "}
                  {event.registrationDeadlineDatetime &&
                    `by ${convertUTCTimeToZonedTime(
                      event.registrationDeadlineDatetime,
                      DEFAULT_TIME_ZONE
                    )}`}
                </Box>
              </Typography>
              {!isAuthenticated ? (
                <LogInForm
                  signup={true}
                  actionCallback={
                    event.stopRegistration ||
                    registrationLimitHit ||
                    !event.isActive ||
                    isAfter(new Date(), new Date(event.endDateTime)) ||
                    (event.registrationDeadlineDatetime &&
                      isAfter(
                        new Date(),
                        new Date(event.registrationDeadlineDatetime)
                      ))
                      ? null
                      : handleRegister
                  }
                />
              ) : (
                <>
                  <Box wdith="100%" maxHeight="500px" position="relative">
                    <Reward
                      ref={rewardRef}
                      type="confetti"
                      config={{
                        lifetime: 360,
                        angle: 90,
                        decay: 0.9,
                        spread: 360,
                        startVelocity: 75,
                        elementCount: 250,
                        elementSize: 10,
                      }}
                    >
                      <Box display="none">Reward</Box>
                    </Reward>
                  </Box>
                  <Button
                    disabled={
                      event.stopRegistration ||
                      registrationLimitHit ||
                      !event.isActive ||
                      isAfter(new Date(), new Date(event.endDateTime)) ||
                      (event.registrationDeadlineDatetime &&
                        isAfter(
                          new Date(),
                          new Date(event.registrationDeadlineDatetime)
                        ))
                    }
                    size="medium"
                    fullWidth
                    onClick={handleRegister}
                  >
                    {isAfter(new Date(), new Date(event.endDateTime))
                      ? "Past Event"
                      : event.registrationDeadlineDatetime &&
                        isAfter(
                          new Date(),
                          new Date(event.registrationDeadlineDatetime)
                        )
                      ? "Past Registration"
                      : event.stopRegistration
                      ? "Registration Stopped"
                      : registrationLimitHit
                      ? "Reached Registration Limit"
                      : event.isActive
                      ? "Register"
                      : "Inactive"}
                  </Button>
                </>
              )}
            </>
          )}
          {userIsRegistered && !userIsDenied && (
            <Box>
              <Box mt={1}>
                <Link onClick={handleLeave} color="textSecondary">
                  Leave event
                </Link>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(EventRegister);
