import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
// react-query
import { useMutation, QueryClient } from "react-query";
import { addEditEvent, uploadEventHeaderImage } from "../../hooks/event";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// Icons
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
// Components
import Button from "../../shared/Button";
import RichTextEditor from "../../shared/RichTextEditor";
// Utils
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns-tz";
import { EVENT_ONLINE } from "../../utils/globalValues";
import { Link as ScrollLink, Element } from "react-scroll";
import isBefore from "date-fns/isBefore";

const EVENT_TAGS = [
  "Entrepreneurship",
  "Venture Capital",
  "Tech",
  "AI",
  "Robotics",
  "Recruiting",
  "Software Engineering",
  "Data Science",
  "Product Management",
  "Design",
  "Business",
  "Finance",
  "Fashion",
  "Film",
  "Media & Entertainment",
  "Arts",
  "Music",
  "Family",
  "Medicine",
  "Food & Drink",
  "Government & Politics",
  "Charity",
  "Health & Wellness",
  "Religion & Spirituality",
  "Sports & Fitness",
  "Engineering",
  "Education & University",
  "Lifestyle",
  "Community",
  "Other",
];

export const AddEditEvent = ({ companyProfile, setAlert, event }) => {
  const history = useHistory();
  const queryClient = new QueryClient();
  const [eventFormData, setEventFormData] = useState({
    slug: "",
    title: "",
    headerImagePath: null,
    primaryContactEmail: "",
    tags: [],
    location: "",
    online: EVENT_ONLINE.ONLINE,
    link: "",
    timeZone: "",
    requireRegistration: true,
    requireRegistrationApproval: false,
    limitEventRegistrations: false,
    maxRegistrations: null,
    registrationDeadlineDatetime: null,
    startDateTime: new Date(),
    endDateTime: new Date(),
    description: "",
    isPublicToPralent: true,
    isPublicToCommunity: true,
    stopRegistration: false,
    isActive: true,
  });

  const addEditMutation = useMutation(addEditEvent, {
    onSuccess: async (data) => {
      if (data && data.data) {
        if (!event) {
          await queryClient.invalidateQueries([
            "orgEvents",
            { companyId: companyProfile.id },
          ]);
        }
        await queryClient.setQueryData(
          ["orgEvent", { eventSlug: data.data.slug }],
          data.data
        );
        await setAlert(
          `Succesfully ${event ? "updated" : "created"} your event!`,
          "success"
        );
        if (!event) {
          history.push(`/org/${companyProfile.orgId}/events`);
        }
      }
    },
  });
  const uploadHeaderImageMutation = useMutation(uploadEventHeaderImage);

  useEffect(() => {
    if (event) {
      setEventFormData({
        ...eventFormData,
        id: event.id,
        slug: event.slug,
        title: event.title,
        headerImagePath: event.headerImagePath,
        primaryContactEmail: event.primaryContactEmail,
        tags: event.tags,
        location: event.location,
        online: event.online,
        link: event.link,
        timeZone: event.timeZone,
        requireRegistration: event.requireRegistration,
        requireRegistrationApproval: event.requireRegistrationApproval,
        stopRegistration: event.stopRegistration,
        limitEventRegistrations: event.maxRegistrations ? true : false,
        maxRegistrations: event.maxRegistrations,
        registrationDeadlineDatetime: event.registrationDeadlineDatetime
          ? new Date(event.registrationDeadlineDatetime)
          : null,
        startDateTime:
          (event.startDateTime && new Date(event.startDateTime)) || null,
        endDateTime: (event.endDateTime && new Date(event.endDateTime)) || null,
        description: event.description,
        isPublicToPralent: event.isPublicToPralent,
        isPublicToCommunity: event.isPublicToCommunity,
        isActive: event.isActive,
      });
    }
  }, [event]);

  const handleEventChange = (e) => {
    const target = e.target;
    let value =
      target.type === "checkbox"
        ? target.checked
        : target.type == "radio"
        ? parseInt(target.value)
        : target.value;
    const name = target.name;

    if (name === "slug") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }

    setEventFormData({
      ...eventFormData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const regdt = eventFormData.registrationDeadlineDatetime;
    const startdt = eventFormData.startDateTime;
    const enddt = eventFormData.endDateTime;
    const description = eventFormData.description;

    if (!description || description === "") {
      setAlert("Please enter a description for your event.", "error");
      return false;
    }
    if (startdt && enddt && isBefore(new Date(enddt), new Date(startdt))) {
      setAlert(
        "Please ensure the start date & time is before the end date & time.",
        "error"
      );
      return false;
    } else if (
      eventFormData.requireRegistration &&
      regdt &&
      isBefore(new Date(startdt), new Date(regdt))
    ) {
      setAlert(
        "Please ensure the registration deadline is before the start date & time.",
        "error"
      );
      return false;
    }
    addEditMutation.mutate({
      companyId: companyProfile.id,
      eventDetails: eventFormData,
    });
  };

  const onChangeHeaderImage = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 5242880) {
        const headerImageFormData = new FormData();
        headerImageFormData.append("myFile", e.target.files[0]);
        uploadHeaderImageMutation.mutate({
          companyId: companyProfile.id,
          headerImageFormData: headerImageFormData,
        });
      } else {
        setAlert("Maximum file size 5MB exceeded", "error");
      }
    }
  };

  useEffect(() => {
    if (uploadHeaderImageMutation.data && uploadHeaderImageMutation.data.data) {
      setEventFormData({
        ...eventFormData,
        headerImagePath: uploadHeaderImageMutation.data.data,
      });
      if (event) {
        addEditMutation.mutate({
          companyId: companyProfile.id,
          eventDetails: {
            ...eventFormData,
            headerImagePath: uploadHeaderImageMutation.data.data,
          },
        });
      }
    }
  }, [uploadHeaderImageMutation.data]);

  const [scrollY, setScrollY] = useState();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [window]);

  const handleScroll = () => {
    let scrollTop = window.scrollY;
    setScrollY(scrollTop);
  };

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12} md={8}>
          <form onSubmit={handleSubmit}>
            <Box>
              <Element name="scroll-overview">
                <Grid container spacing={4}>
                  {!event && (
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        component={Link}
                        to={`/org/${
                          companyProfile && companyProfile.orgId
                        }/events`}
                      >
                        <ArrowBackIcon />{" "}
                        <Typography variant="body2" component="div">
                          <Box ml={1}>Back to events</Box>
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h2">
                      <Box fontWeight={600}>
                        {event ? "Edit your" : "Create new"} event
                      </Box>
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      <Box>
                        {event
                          ? "Welcome back, let's edit your event."
                          : "Get started creating your new event today!"}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="body1" component="div">
                      <Box fontWeight={600}>Overview</Box>
                    </Typography>
                    <FormGroup>
                      <InputLabel>
                        Header Image (16x9 aspect ratio recommended)
                      </InputLabel>
                      <Box
                        mb={1}
                        style={{
                          position: "relative",
                          paddingBottom: "56.2%",
                        }}
                      >
                        <Box
                          style={{ objectFit: "cover" }}
                          position="absolute"
                          width="100%"
                          height="100%"
                          borderRadius="borderRadius"
                          component="img"
                          src={
                            eventFormData.headerImagePath instanceof File
                              ? URL.createObjectURL(
                                  eventFormData.headerImagePath
                                )
                              : eventFormData.headerImagePath ||
                                "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                          }
                        />
                      </Box>
                      <input
                        accept=".png, .jpg, .jpeg, .gif, .svg"
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onChangeHeaderImage}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload New Image
                        </Button>
                      </label>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel required>Title</InputLabel>
                      <TextField
                        placeholder="Enter a title for your event"
                        id="title"
                        name="title"
                        required
                        value={eventFormData.title}
                        onChange={handleEventChange}
                      />
                    </FormGroup>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel required>Custom Url</InputLabel>
                      <TextField
                        placeholder="Enter an url slug (your unique event url ending)"
                        id="slug"
                        name="slug"
                        required
                        value={eventFormData.slug}
                        onChange={handleEventChange}
                        inputProps={{ pattern: "^[-a-zA-Z0-9@.+_]+$" }}
                      />
                    </FormGroup>
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel>Primary Contact Email</InputLabel>
                      <TextField
                        placeholder="Enter your email or the email of the primary contact for this event"
                        id="primaryContactEmail"
                        name="primaryContactEmail"
                        type="email"
                        value={eventFormData.primaryContactEmail}
                        onChange={handleEventChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel>Tags</InputLabel>
                      <Autocomplete
                        multiple
                        id="tags"
                        options={EVENT_TAGS.map((option) => option)}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip label={option} {...getTagProps({ index })} />
                          ))
                        }
                        value={eventFormData.tags}
                        onChange={(event, value) =>
                          setEventFormData({ ...eventFormData, tags: value })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Add tags for this event, create your own tags"
                          />
                        )}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel>Location</InputLabel>
                      <TextField
                        placeholder="Enter the location for your event"
                        id="location"
                        name="location"
                        value={eventFormData.location}
                        onChange={handleEventChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel>Online, Hybrid, or In Person</InputLabel>

                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="online"
                          name="online"
                          value={eventFormData.online}
                          onChange={handleEventChange}
                        >
                          <FormControlLabel
                            value={EVENT_ONLINE.ONLINE}
                            control={<Radio />}
                            label="Online"
                          />
                          <FormControlLabel
                            value={EVENT_ONLINE.HYBRID}
                            control={<Radio />}
                            label="Hybrid"
                          />
                          <FormControlLabel
                            value={EVENT_ONLINE.INPERSON}
                            control={<Radio />}
                            label="In Person"
                          />
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  {eventFormData.online !== EVENT_ONLINE.INPERSON && (
                    <Grid item xs={12}>
                      <FormGroup>
                        <InputLabel>
                          Online Event Link{" "}
                          <Tooltip
                            style={{ marginLeft: 5 }}
                            title="This is perfect place to include your Zoom or livestream link for attendees to access. This link will be available after they register for your event."
                          >
                            <HelpOutlineOutlinedIcon />
                          </Tooltip>
                        </InputLabel>
                        <TextField
                          placeholder="Enter the link for your event"
                          id="link"
                          name="link"
                          value={eventFormData.link}
                          onChange={handleEventChange}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormGroup>
                      <InputLabel required>Description</InputLabel>
                      <RichTextEditor
                        value={eventFormData.description}
                        setValue={(value) =>
                          setEventFormData({
                            ...eventFormData,
                            description: value,
                          })
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                </Grid>
              </Element>

              <Element name="scroll-datetime">
                <Grid container spacing={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="body1" component="div">
                        <Box fontWeight={600}>
                          Date/Time -{" "}
                          {format(new Date(), "z (zzzz)", {
                            timeZone:
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                          })}
                          <Tooltip
                            style={{ marginLeft: 5 }}
                            title="Use your timezone to create and modify the event. Event attendees will see the event in their timezone."
                          >
                            <HelpOutlineOutlinedIcon />
                          </Tooltip>
                        </Box>
                      </Typography>
                      <FormGroup>
                        <InputLabel required>Start Date & Time</InputLabel>
                        <DateTimePicker
                          name="startDateTime"
                          inputVariant="outlined"
                          required
                          value={eventFormData.startDateTime}
                          onChange={(newDateTime) =>
                            setEventFormData({
                              ...eventFormData,
                              startDateTime: newDateTime,
                            })
                          }
                          onError={console.log}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <InputLabel required>End Date & Time</InputLabel>
                        <DateTimePicker
                          name="endDateTime"
                          inputVariant="outlined"
                          required
                          value={eventFormData.endDateTime}
                          onChange={(newDateTime) =>
                            setEventFormData({
                              ...eventFormData,
                              endDateTime: newDateTime,
                            })
                          }
                          onError={console.log}
                        />
                      </FormGroup>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                </Grid>
              </Element>

              <Element name="scroll-registration-information">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="body1" component="div">
                      <Box fontWeight={600}>Registration Information</Box>
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            id="requireRegistration"
                            name="requireRegistration"
                            color="primary"
                            checked={eventFormData.requireRegistration}
                            onChange={handleEventChange}
                          />
                        }
                        label={
                          <Box ml={1}>
                            <Typography
                              variant="body2"
                              component="div"
                              gutterBottom
                            >
                              <Box fontWeight={600}>Require Registration</Box>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              <Box lineHeight={1.2}>
                                If you select this option, attendees will be
                                required to register to attend this event. If
                                the event is online/hybrid, the online links
                                will be made available only after they register.
                              </Box>
                            </Typography>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  {eventFormData.requireRegistration && (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12}>
                          <FormGroup>
                            <InputLabel>
                              Registration Deadline Date & Time{" "}
                              <Tooltip
                                style={{ marginLeft: 5 }}
                                title="Enter a date and time that attendees must register to your event before. Leave blank if you do not want to have a registration deadline datetime."
                              >
                                <HelpOutlineOutlinedIcon />
                              </Tooltip>
                            </InputLabel>
                            <DateTimePicker
                              name="registrationDeadlineDatetime"
                              inputVariant="outlined"
                              value={eventFormData.registrationDeadlineDatetime}
                              onChange={(newDateTime) =>
                                setEventFormData({
                                  ...eventFormData,
                                  registrationDeadlineDatetime: newDateTime,
                                })
                              }
                              onError={console.log}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        setEventFormData({
                                          ...eventFormData,
                                          registrationDeadlineDatetime: null,
                                        });
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormGroup>
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                id="requireRegistrationApproval"
                                name="requireRegistrationApproval"
                                color="primary"
                                checked={
                                  eventFormData.requireRegistrationApproval
                                }
                                onChange={handleEventChange}
                              />
                            }
                            label={
                              <Box ml={1}>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  gutterBottom
                                >
                                  <Box fontWeight={600}>
                                    Require Registration Approval
                                  </Box>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box lineHeight={1.2}>
                                    If you select this option, attendees will
                                    need to be approved from an organizer to
                                    count as approved for this event. Once fully
                                    registered, attendees will receive the event
                                    details such as online links.
                                  </Box>
                                </Typography>
                              </Box>
                            }
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                id="limitEventRegistrations"
                                name="limitEventRegistrations"
                                color="primary"
                                checked={eventFormData.limitEventRegistrations}
                                onChange={(e) => {
                                  setEventFormData({
                                    ...eventFormData,
                                    limitEventRegistrations: e.target.checked,
                                    maxRegistrations: e.target.checked
                                      ? 50
                                      : null,
                                  });
                                }}
                              />
                            }
                            label={
                              <Box ml={1}>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  gutterBottom
                                >
                                  <Box fontWeight={600}>
                                    Limit Event Registration
                                  </Box>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box lineHeight={1.2}>
                                    If you select this option, attendees will no
                                    longer be able to register once you hit the
                                    maximum number of registrations provided.
                                  </Box>
                                </Typography>
                              </Box>
                            }
                          />
                          {eventFormData.limitEventRegistrations && (
                            <Box mt={2} width="100%">
                              <InputLabel>
                                Maximum Number of Registrations
                              </InputLabel>
                              <TextField
                                placeholder="Enter the maximum number of registrations"
                                id="maxRegistrations"
                                name="maxRegistrations"
                                required
                                value={eventFormData.maxRegistrations}
                                onChange={handleEventChange}
                                type="number"
                                min="1"
                                step="1"
                                fullWidth
                              />
                            </Box>
                          )}
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                id="stopRegistration"
                                name="stopRegistration"
                                color="primary"
                                checked={eventFormData.stopRegistration}
                                onChange={handleEventChange}
                              />
                            }
                            label={
                              <Box ml={1}>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  gutterBottom
                                >
                                  <Box fontWeight={600}>Stop Registration</Box>
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box lineHeight={1.2}>
                                    If you select this option, registration will
                                    be stopped and information such as links to
                                    online/hybrid events will not be visible.
                                  </Box>
                                </Typography>
                              </Box>
                            }
                          />
                        </FormGroup>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                </Grid>
              </Element>

              <Element name="scroll-privacy-settings">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="body1" component="div">
                      <Box fontWeight={600}>Privacy & Settings</Box>
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            id="isPublicToPralent"
                            name="isPublicToPralent"
                            color="primary"
                            checked={eventFormData.isPublicToPralent}
                            onChange={handleEventChange}
                          />
                        }
                        label={
                          <Box ml={1}>
                            <Typography
                              variant="body2"
                              component="div"
                              gutterBottom
                            >
                              <Box fontWeight={600}>Public to Pralent</Box>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              <Box lineHeight={1.2}>
                                When you enable this setting, the event will be
                                available for all Pralent users to view.
                              </Box>
                            </Typography>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            id="isPublicToCommunity"
                            name="isPublicToCommunity"
                            color="primary"
                            checked={eventFormData.isPublicToCommunity}
                            onChange={handleEventChange}
                          />
                        }
                        label={
                          <Box ml={1}>
                            <Typography
                              variant="body2"
                              component="div"
                              gutterBottom
                            >
                              <Box fontWeight={600}>Public to Community</Box>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              <Box lineHeight={1.2}>
                                When you enable this setting, the event will be
                                available on your community page for members to
                                view.
                              </Box>
                            </Typography>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            id="isActive"
                            name="isActive"
                            color="primary"
                            checked={eventFormData.isActive}
                            onChange={handleEventChange}
                          />
                        }
                        label={
                          <Box ml={1}>
                            <Typography
                              variant="body2"
                              component="div"
                              gutterBottom
                            >
                              <Box fontWeight={600}>Active</Box>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              <Box lineHeight={1.2}>
                                Activating the event will allow people to begin
                                registering to attend.
                              </Box>
                            </Typography>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button type="submit">
                        {event ? "Save" : "Create"} event
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Element>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display="flex"
            justifyContent="flex-start"
            position="relative"
            height={0}
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              position="absolute"
              top={scrollY}
              style={{ transition: "all 0.75s cubic-bezier(0.4,0.0,0.2,1)" }}
            >
              <Button
                component={ScrollLink}
                to="scroll-overview"
                spy={true}
                smooth={true}
                duration={500}
                variant="text"
                color="default"
                size="medium"
              >
                Overview
              </Button>
              <Button
                component={ScrollLink}
                to="scroll-datetime"
                spy={true}
                smooth={true}
                duration={500}
                variant="text"
                color="default"
                size="medium"
              >
                Date/Time
              </Button>
              <Button
                component={ScrollLink}
                to="scroll-registration-information"
                spy={true}
                smooth={true}
                duration={500}
                variant="text"
                color="default"
                size="medium"
              >
                Registration Information
              </Button>
              <Button
                component={ScrollLink}
                to="scroll-privacy-settings"
                spy={true}
                smooth={true}
                duration={500}
                variant="text"
                color="default"
                size="medium"
              >
                Privacy & Settings
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEvent);
