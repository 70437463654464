import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveJudgeScores,
  updateDirtyScoreState,
} from "../../../redux/actions/judge";
// Components
import Button from "../../../shared/Button";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  criteriaName: {
    marginRight: theme.spacing(1),
  },
  FeedbackInput: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

export const ScoreCard = ({
  judgingCriteria,
  judgeInfo,
  currentSubmission,
  saveJudgeScores,
  updateDirtyScoreState,
  dirtyState,
}) => {
  const classes = useStyles();
  const handleSaveJudgingScore = async (e) => {
    e.preventDefault();
    // Get the evaluation scores for each judging criteria
    // Save this information in the database
    await saveJudgeScores(
      currentSubmission.challengeRoundId,
      currentSubmission.id,
      judgeInfo.id,
      formData
    );
  };

  const [formData, setFormData] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [totalPossibleScore, setTotalPossibleScore] = useState(0);

  useEffect(() => {
    let currentScore = 0;
    for (let i = 0; i < formData.length; i++) {
      currentScore += formData[i].score;
    }
    setTotalScore(currentScore);
  }, [formData]);

  useEffect(() => {
    let maxPossibleScore = 0;
    for (let i = 0; i < judgingCriteria.length; i++) {
      maxPossibleScore += judgingCriteria[i].maxScore;
    }
    setTotalPossibleScore(maxPossibleScore);
  }, [judgingCriteria]);

  const handlefeedbackChange = (id, value) => {
    if (!dirtyState) {
      updateDirtyScoreState(true);
    }
    let tempJudgingScores = [...formData];
    for (let indx = 0; indx < tempJudgingScores.length; indx++) {
      if (
        tempJudgingScores &&
        tempJudgingScores[indx].judgingCriteriaId === id
      ) {
        tempJudgingScores[indx].feedback = value;
      }
    }
    setFormData([...tempJudgingScores]);
  };

  const handleJudgingCriteriaChange = (id, name, value) => {
    if (!dirtyState) {
      updateDirtyScoreState(true);
    }

    let tempJudgingScores = [...formData];
    for (let indx = 0; indx < tempJudgingScores.length; indx++) {
      if (
        tempJudgingScores &&
        tempJudgingScores[indx].judgingCriteriaId === id
      ) {
        tempJudgingScores[indx].score = value;
      }
    }
    setFormData([...tempJudgingScores]);
  };

  useEffect(() => {
    let submissionScores = [];

    // If there are scores already stored in the database, read and assign them to the local variable
    if (
      currentSubmission &&
      currentSubmission.submissionScore &&
      currentSubmission.submissionScore.length > 0
    ) {
      for (
        let indx = 0;
        indx < currentSubmission.submissionScore.length > 0;
        indx++
      ) {
        const score = currentSubmission.submissionScore[indx];
        submissionScores.push({
          id: score.id,
          challengeId: score.challengeId,
          challengeRoundId: score.challengeRoundId,
          challengeSubmissionId: score.challengeSubmissionId,
          judgingCriteriaId: score.judgingCriteriaId,
          challengeJudgeId: score.challengeJudgeId,
          score: score.score,
          feedback: score.feedback,
          feedbackDateTime: score.feedbackDateTime,
        });
      }
    } else {
      if (judgingCriteria && judgingCriteria.length > 0) {
        for (let indx = 0; indx < judgingCriteria.length; indx++) {
          const criteria = judgingCriteria[indx];
          submissionScores.push({
            id: null,
            challengeId: criteria.challengeId,
            challengeRoundId: criteria.challengeRoundId,
            challengeSubmissionId: currentSubmission.id,
            judgingCriteriaId: criteria.id,
            challengeJudgeId: judgeInfo.id,
            score: 0,
            feedback: "",
            isRequired: criteria.isRequired,
            feedbackDateTime: null,
          });
        }
      }
    }
    // Set the form data to the judgign scores retrieved from the database
    setFormData(submissionScores || []);
  }, [currentSubmission]);

  return (
    <form onSubmit={handleSaveJudgingScore}>
      <Fragment>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={[2, 3]}
        >
          <Typography variant="body1" component="div">
            <Box fontWeight={600}>Score</Box>
          </Typography>
          <Typography variant="body1" component="div">
            <Box fontWeight={600}>
              {totalScore} / {totalPossibleScore}
            </Box>
          </Typography>
          <Button disabled={!dirtyState} color="primary" type="submit">
            Save
          </Button>
        </Box>

        {judgingCriteria &&
          judgingCriteria.length > 0 &&
          judgingCriteria.map((criteria, index) => {
            const id = criteria.id;
            const name = criteria.name;
            const maxScore = criteria.maxScore;
            const description = criteria.description;
            const isRequired = criteria.isRequired;
            const sliderMarks = [
              {
                value: 0,
                label: 0,
              },
              { value: maxScore, label: maxScore },
            ];

            const foundScore =
              formData &&
              formData.find((elem) => elem.judgingCriteriaId === id);
            if (!foundScore) return; // need to fix this ASAP

            const currentScore = (foundScore && foundScore.score) || 0;
            const currentFeedback = (foundScore && foundScore.feedback) || "";

            if (id !== "") {
              return (
                <Box key={id} mb={[2, 3]}>
                  <Box>
                    <Box mb={1}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          className={classes.criteriaName}
                          variant="body1"
                          component="div"
                        >
                          <Box fontWeight={600}>{name}</Box>
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2} whiteSpace="pre-line">
                            {description}
                          </Box>
                        </Typography>
                      </Box>

                      <Slider
                        name={name}
                        defaultValue={0}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks={sliderMarks}
                        min={0}
                        max={maxScore}
                        value={currentScore}
                        onChange={(e, newValue) => {
                          handleJudgingCriteriaChange(id, name, newValue);
                        }}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                    <Box mb={1}>
                      <Typography color="textSecondary">
                        Feedback {isRequired ? "(required)" : "(not required)"}
                      </Typography>

                      <TextField
                        id={id}
                        onChange={(e) =>
                          handlefeedbackChange(id, e.target.value)
                        }
                        variant="outlined"
                        value={currentFeedback}
                        margin="dense"
                        fullWidth={true}
                        multiline={true}
                        rows={5}
                        type="text"
                        required={isRequired}
                        InputProps={{
                          className: classes.FeedbackInput,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            }
          })}
      </Fragment>
    </form>
  );
};

const mapStateToProps = (state) => ({
  judgingCriteria: state.judge.judgingCriteria,
  judgeInfo: state.judge.judgeDetails,
  currentSubmission: state.judge.currentSubmission,
  dirtyState: state.judge.dirtyState,
});

const mapDispatchToProps = { saveJudgeScores, updateDirtyScoreState };

export default connect(mapStateToProps, mapDispatchToProps)(ScoreCard);
