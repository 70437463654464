import React, { useState } from "react";
// React-Router
import { Link as RouterLink } from "react-router-dom";
// React query
import { useMutation, useQueryClient } from "react-query";
import { updateCandidateApplicationStatus } from "../../../hooks/job";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import amber from "@material-ui/core/colors/amber";
import deepPurple from "@material-ui/core/colors/deepPurple";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";
// Icons
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
// Utils
import format from "date-fns/format";
import { JOB_APPLICATION_STATUS } from "../../../utils/globalValues";

const JOB_APPLICATION_STATUS_TYPES = {
  [JOB_APPLICATION_STATUS.APPLIED]: {
    name: "Applied",
    color: amber.A700,
  },
  [JOB_APPLICATION_STATUS.REVIEWED]: {
    name: "Reviewed",
    color: deepPurple.A400,
  },
  [JOB_APPLICATION_STATUS.INTERVIEW]: {
    name: "Interview",
    color: blue.A400,
  },
  [JOB_APPLICATION_STATUS.OFFER]: {
    name: "Offer",
    color: green.A700,
  },
  [JOB_APPLICATION_STATUS.REJECTED]: {
    name: "Rejected",
    color: red.A700,
  },
  [JOB_APPLICATION_STATUS.CLOSED]: {
    name: "Closed",
    color: blueGrey[700],
  },
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

export const CandidateCard = ({
  job,
  candidate,
  companyProfile,
  genderTypes,
  raceTypes,
  setAlert,
}) => {
  const classes = useStyles();

  const [anchorElStatusMenu, setAnchorElStatusMenu] = useState(null);
  const openStatusMenu = Boolean(anchorElStatusMenu);

  const handleClickStatusMenu = (event) => {
    setAnchorElStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElStatusMenu(null);
  };

  const queryClient = useQueryClient();

  const updateCandidateApplicationStatusMutation = useMutation(
    updateCandidateApplicationStatus,
    {
      onSuccess: async (data) => {
        if (!data.data) {
          return;
        }
        const { candidateUserId, jobPostId, newStatus } = data.data;
        let myCompanyJobs = await queryClient.getQueryData([
          "myCompanyJobs",
          { companyId: companyProfile.id },
        ]);

        const newJobs = (myCompanyJobs.data || []).map((job) => {
          if (job.id === jobPostId) {
            const newJobPostActivity = (job.jobPostActivity || []).map(
              (candidate) => {
                if (candidate.userAccountId === candidateUserId) {
                  return {
                    ...candidate,
                    applicationStatusId: newStatus,
                  };
                }
                return candidate;
              }
            );
            return {
              ...job,
              jobPostActivity: newJobPostActivity,
            };
          }
          return job;
        });

        await queryClient.setQueryData(
          ["myCompanyJobs", { companyId: companyProfile.id }],
          {
            ...myCompanyJobs,
            data: newJobs,
          }
        );

        handleCloseStatusMenu();
      },
      onError: async (error) => {
        await setAlert(
          (error && error.data && error.data.msg) ||
            "There was an error updating the candidate status. Please try again.",
          "error"
        );
      },
    }
  );

  const handleUpdateCandidateStatus = async (newStatus) => {
    const jobPostId = job.id;
    const candidateUserId = candidate.userAccountId;
    updateCandidateApplicationStatusMutation.mutate({
      jobPostId,
      candidateUserId,
      newStatus,
    });
  };

  const candidateUser = candidate.user;
  const candidateProfile = candidate.profile;
  if (!candidateProfile) return <Box />;
  const candidateEducation =
    candidateProfile &&
    candidateProfile.education &&
    candidateProfile.education.length > 0 &&
    candidateProfile.education[0];
  const candidateGender =
    candidateProfile.gender &&
    genderTypes &&
    genderTypes.find((elem) => elem.id === candidateProfile.gender);
  const candidateRace =
    candidateProfile.race &&
    candidateProfile.race.map((r) => {
      const raceFound = raceTypes.find((elem) => elem.id === r);
      return (raceFound && raceFound.name) || "";
    });

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={2}
      >
        <Box display="flex" alignItems="center">
          <CustomAvatar
            src={candidateUser.avatar}
            fallbackText={candidateUser.userName}
            alt={candidateUser.avatar?.charAt(0).toUpperCase()}
            className={classes.avatar}
          />
          <Box>
            <Typography variant="h6" component="div">
              <Box lineHeight={1.2} fontWeight={600}>
                {candidateProfile.firstName} {candidateProfile.lastName}
              </Box>
            </Typography>
            {candidateEducation && (
              <>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box>
                    {candidateEducation.fieldOfStudy} |{" "}
                    {candidateEducation.school},{" "}
                    {candidateEducation.completionDate &&
                      new Date(candidateEducation.completionDate).getFullYear()}
                  </Box>
                </Typography>
              </>
            )}
            <Box display="flex" alignItems="center" style={{ gap: "6px" }}>
              {candidateGender && (
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box lineHeight={1.2}>{candidateGender.name}</Box>
                </Typography>
              )}
              {candidateRace && (
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box lineHeight={1.2}>{candidateRace.join(", ")}</Box>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
            <Menu
              id="long-menu"
              getContentAnchorEl={null}
              anchorEl={anchorElStatusMenu}
              keepMounted
              open={openStatusMenu}
              onClose={handleCloseStatusMenu}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              {JOB_APPLICATION_STATUS_TYPES &&
                Object.keys(JOB_APPLICATION_STATUS_TYPES).map((id) => {
                  const obj = JOB_APPLICATION_STATUS_TYPES[id];
                  return (
                    <MenuItem
                      key={id}
                      selected={id == candidate.applicationStatusId}
                      onClick={() => handleUpdateCandidateStatus(id)}
                    >
                      <Box
                        width={12}
                        height={12}
                        bgcolor={obj.color}
                        borderRadius="borderRadius"
                        marginRight={1}
                      />
                      {obj.name}
                    </MenuItem>
                  );
                })}
            </Menu>
            <Tooltip title="Click here to change the status of the candidate">
              <Chip
                clickable
                onClick={handleClickStatusMenu}
                style={{
                  backgroundColor:
                    JOB_APPLICATION_STATUS_TYPES &&
                    JOB_APPLICATION_STATUS_TYPES[
                      candidate.applicationStatusId
                    ] &&
                    JOB_APPLICATION_STATUS_TYPES[candidate.applicationStatusId]
                      .color,
                }}
                label={
                  <Box>
                    {JOB_APPLICATION_STATUS_TYPES &&
                      JOB_APPLICATION_STATUS_TYPES[
                        candidate.applicationStatusId
                      ] &&
                      JOB_APPLICATION_STATUS_TYPES[
                        candidate.applicationStatusId
                      ].name}
                  </Box>
                }
              />
            </Tooltip>
            {candidateProfile.resumeLocation && (
              <Tooltip title="Download candidate resume">
                <IconButton
                  component={Link}
                  href={candidateProfile.resumeLocation}
                  color="default"
                >
                  <DescriptionOutlinedIcon color="inherit" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Open candidate email">
              <IconButton
                component={Link}
                href={`mailto:${candidateUser.email}`}
              >
                <MailOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View candidate profile">
              <IconButton
                component={RouterLink}
                to={`/@${candidateUser.userName}`}
                color="primary"
              >
                <PersonOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="caption" component="div" color="textSecondary">
            <Box textAlign="right">
              Applied on{" "}
              {candidate.applyDate &&
                format(new Date(candidate.applyDate), "MMM dd, yyyy")}
            </Box>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  raceTypes: state.typedef.raceTypes,
  genderTypes: state.typedef.genderTypes,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCard);
