import React from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import { bulkUploadJudges, fetchAllJudges } from "../../../redux/actions/judge";
// Material UI
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
// Components
import Button from "../../../shared/Button";
// Utils
import Papa from "papaparse";

const ChallengeJudgeBulkUpload = ({
  open,
  setOpen,
  challenge,
  currentRoundIndex,
  setAlert,
  bulkUploadJudges,
  fetchAllJudges,
}) => {
  const [fileData, setFileData] = React.useState({
    file: null,
    data: null,
  });

  const handleClose = () => {
    setOpen(false);
    setFileData({
      file: null,
      data: null,
    });
  };

  const onSubmit = async () => {
    let error = false;

    if (!fileData.data || fileData.data.length === 0) {
      setAlert("There are no rows to upload", "error");
      return;
    }

    let dataToUpload = [];

    for (let i = 0; i < fileData.data.length; i++) {
      const row = fileData.data[i];
      if (
        row.first_name !== "" &&
        row.last_name !== "" &&
        row.email_address !== ""
      ) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(row.email_address)) {
          setAlert(
            "Please make sure that all email_address fields are valid. There should be nothing besides the email address in this field.",
            "error"
          );
          error = true;
          break;
        } else {
          dataToUpload.push({
            firstName: row.first_name,
            lastName: row.last_name,
            emailAddress: row.email_address,
          });
        }
      } else {
        setAlert(
          "Please make sure that all rows have entries for first_name, last_name, and email_address",
          "error"
        );
        error = true;
        break;
      }
    }

    if (!error && dataToUpload) {
      const challengeRoundId = challenge.round[currentRoundIndex].id;
      const result = await bulkUploadJudges(
        challenge.id,
        challengeRoundId,
        dataToUpload
      );
      if (result) {
        handleClose();
        fetchAllJudges(challengeRoundId);
      }
    }
  };

  const handleChange = async (e) => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      Papa.parse(fileToUpload, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFileData({
            file: fileToUpload,
            data: results.data,
          });
        },
      });
    }
  };

  const handleDelete = (deleteIndex) => {
    if (fileData.data && fileData.data.length) {
      let newFileData = fileData.data.filter(
        (_, index) => index !== deleteIndex
      );
      setFileData({
        ...fileData,
        data: newFileData,
      });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="bulk-upload-dialog-title"
        aria-describedby="bulk-upload-dialog-description"
      >
        <DialogTitle id="bulk-upload-dialog-title">
          Bulk Upload Reviewers
        </DialogTitle>
        <DialogContent>
          <Box fontSize="body1.fontSize">
            Upload a csv file that contains the columns{" "}
            <Box fontWeight={600} color="primary.main">
              first_name, last_name, email_address
            </Box>
          </Box>
          <Box my={2}>
            <input
              accept=".csv"
              style={{ display: "none" }}
              id="bulk-upload-button-file"
              type="file"
              onChange={handleChange}
              value={""}
            />
            <label htmlFor="bulk-upload-button-file">
              <Button variant="contained" color="primary" component="span">
                Select CSV File
              </Button>
            </label>
          </Box>
          {fileData && fileData.data && (
            <Box>
              <Typography variant="body1" gutterBottom>
                {fileData.file && fileData.file.name} |{" "}
                {fileData.data.length || 0} Rows
              </Typography>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email Addresss</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileData.data &&
                      fileData.data.map((row, index) => {
                        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        let validEmail = false;

                        if (re.test(row.email_address)) {
                          validEmail = true;
                        }

                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {row.first_name}
                            </TableCell>
                            <TableCell>{row.last_name}</TableCell>
                            <TableCell>
                              {!validEmail && (
                                <Box
                                  color="error.main"
                                  fontSize="caption.fontSize"
                                >
                                  Invalid Email
                                </Box>
                              )}
                              {row.email_address}
                            </TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleDelete(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {
  setAlert,
  bulkUploadJudges,
  fetchAllJudges,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeJudgeBulkUpload);
