import LogoLightWord from "../../../assets/brand/logos/LogoLightWord.png";
// TODO: Need to move this to separate component
/* This example requires Tailwind CSS v2.0+ */
const navigation = [
  // { name: "Guides", href: "#" },
  {
    name: "Product Updates",
    href: "https://twitter.com/pralenthq",
  },
  {
    name: "Schedule Demo",
    href: "https://cal.com/pralent/30min",
  },
];

export default function Navigation() {
  return (
    <header>
      <nav className="mx-auto max-w-6xl px-4" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-gray-500 py-6 lg:border-none">
          <div className="flex items-center">
            <a href="/">
              <span className="sr-only">Pralent</span>
              <img src={LogoLightWord} className="h-8" />
            </a>
          </div>
          <div className="ml-10 flex items-center space-x-4">
            {navigation.map((link) => (
              <a
                key={link.name}
                href={link.href}
                className="hidden rounded-md px-2 py-1 text-base font-semibold text-gray-300 duration-300 ease-in-out hover:bg-gray-800 hover:text-white md:block"
              >
                {link.name}
              </a>
            ))}
            <a
              href="/login"
              className="rounded-md px-2 py-1 text-base font-semibold text-gray-300 duration-300 ease-in-out hover:bg-gray-800 hover:text-white"
            >
              Log In
            </a>
            <a
              href="/signup"
              className="text-primary-300 hover:text-primary-400 rounded-md px-2 py-1 text-base font-semibold duration-300 ease-in-out hover:bg-gray-800"
            >
              Sign Up
            </a>
          </div>
        </div>
        <div className="flex flex-wrap justify-center space-x-2 py-4 md:hidden">
          {navigation.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="rounded-md px-2 py-1 text-base font-semibold text-white hover:bg-gray-800"
            >
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  );
}
