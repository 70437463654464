import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// Material UI
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
// Components
import CustomAvatar from "../CustomAvatar";
// Icons
import CodeIcon from "@material-ui/icons/Code";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "auto",
    height: 75,
    width: 75,
    [theme.breakpoints.up("lg")]: {
      height: 90,
      width: 90,
    },
    [theme.breakpoints.up("xl")]: {
      height: 120,
      width: 120,
    },
    fontSize: "50px",
    objectFit: "cover",
    borderRadius: 9999999,
  },
  card: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(1),
    boxShadow: theme.shadows[0],
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
    },
    transition: "ease-in-out 0.2s",
  },
  cardContent: {
    textAlign: "center",

    display: "flex",
    flexDirection: "column",
    justify: "center",
  },
  link: {
    textDecoration: "none",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
  },
  tooltip: {
    maxWidth: "none",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  header: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
}));

const ProfileItem = ({
  profile: {
    user: { userName, avatar },
    firstName,
    lastName,
    education,
    location,
    skillSets,
    publicProfileLinks,
  },
  skillSet,
  team = null,
}) => {
  const classes = useStyles();
  const [links, setLinks] = useState({
    github: "",
    linkedin: "",
    personalWebsite: "",
  });
  useEffect(() => {
    if (publicProfileLinks && publicProfileLinks.length > 0) {
      const GITHUB = publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 1
      );
      const LINKEDIN = publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 2
      );
      const PERSONALWEBSITE = publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 3
      );
      setLinks({
        ...links,
        github: (GITHUB && GITHUB.url) || "",
        linkedin: (LINKEDIN && LINKEDIN.url) || "",
        personalWebsite: (PERSONALWEBSITE && PERSONALWEBSITE.url) || "",
      });
    }
  }, []);
  const { github, linkedin, personalWebsite } = links;
  return (
    <Fragment>
      <Link to={`/@${userName}`} className={classes.link}>
        <Box display="flex" flexDirection="column" height="100%">
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box mb={2}>
                <CustomAvatar
                  src={avatar}
                  alt={firstName}
                  fallbackText={firstName}
                  className={classes.avatar}
                />
              </Box>
              {team && team.name && (
                <Typography variant="caption" color="primary">
                  {team.name}
                </Typography>
              )}
              <Typography variant="h5" component="h3">
                <Box fontWeight={600}>
                  {firstName} {lastName}
                </Box>
              </Typography>

              {education && education.length > 0 && (
                <Fragment>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      {education[0].school},{" "}
                      {new Date(education[0].completionDate).getFullYear()}
                    </Box>
                  </Typography>

                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      {education[0].degree} - {education[0].fieldOfStudy}
                    </Box>
                  </Typography>
                </Fragment>
              )}
              {location && (
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box lineHeight={1.2}>{location}</Box>
                </Typography>
              )}
              <Box my={1}>
                {github !== "" && (
                  <Tooltip
                    title={
                      <Typography variant="body2" component="div">
                        {github}
                      </Typography>
                    }
                    aria-label={`github-link-${github}`}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <CodeIcon
                      tag="a"
                      href={github}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      className={classes.icon}
                    />
                  </Tooltip>
                )}
                {linkedin !== "" && (
                  <Tooltip
                    title={
                      <Typography variant={"body2"}>{linkedin}</Typography>
                    }
                    aria-label={`linkedin-link-${linkedin}`}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <LinkedInIcon
                      tag="a"
                      href={linkedin}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      className={classes.icon}
                    />
                  </Tooltip>
                )}
                {personalWebsite !== "" && (
                  <Tooltip
                    title={
                      <Typography variant={"body2"}>
                        {personalWebsite}
                      </Typography>
                    }
                    aria-label={`personalWebsite-link-${personalWebsite}`}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <LanguageIcon
                      tag="a"
                      href={personalWebsite}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      className={classes.icon}
                    />
                  </Tooltip>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Link>
    </Fragment>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  skillSet: state.typedef.skillSet,
});

export default connect(mapStateToProps)(ProfileItem);
