import React from "react";
// React-Router
import { Link as RouterLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// React query
import { useQuery } from "react-query";
import { getAllJobsForCompany } from "../../hooks/job";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Spinner from "../../shared/Spinner";
import OrgAppBar from "../../shared/OrgAppBar";
import PostJob from "../../shared/PostJob";
import EmptyState from "../../shared/EmptyState";
import MyJobCard from "./components/MyJobCard";
// Icons
import { IoIdCardOutline } from "react-icons/io5";

export const OrgMyJobs = ({ companyProfile }) => {
  const {
    isLoading,
    data: { data: myCompanyJobs } = { data: null },
    isError,
    error,
  } = useQuery(
    ["myCompanyJobs", { companyId: companyProfile.id }],
    getAllJobsForCompany
  );

  const OrgJobsActions = () => {
    return (
      <Box display="flex" alignItems="center">
        <PostJob />
      </Box>
    );
  };

  return (
    <>
      <Box>
        <OrgAppBar
          title={
            <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
              <IoIdCardOutline /> Recruit
            </Box>
          }
          subtitle="Manage your jobs and recruiting initiatives."
        />
        <OrgAppBar
          title={null}
          subtitle={
            <Box display="flex" alignItems="center">
              <Typography variant="body2" component="div">
                <Box mr={1}>
                  {myCompanyJobs ? myCompanyJobs.length : 0} Jobs(s)
                </Box>
              </Typography>
            </Box>
          }
          actions={<OrgJobsActions />}
        />
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        ) : myCompanyJobs && myCompanyJobs.length > 0 ? (
          <>
            {myCompanyJobs.map((job) => {
              return <MyJobCard key={job.id} job={job} />;
            })}
          </>
        ) : (
          <EmptyState
            title="Post a job or opportunity"
            subtitle="Create a job posting for your community or organization. Manage all of the applicants in one place."
            link="https://www.notion.so/Posting-a-job-d09327beac694f85a68d499729c93447"
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgMyJobs);
