// React
import React, { useState } from "react";
// Contexts
import { useRoomContext, useUserInRoomContext } from "./RoomContext";
// Redux
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Components
import Spinner from "../../Spinner";
import RoomPosts from "./RoomPosts";
import PostForm from "./PostForm";
import RoomHeader from "./RoomHeader";
import ReplyDrawer from "./ReplyDrawer";
// Utils
import { USER_ROOM_STATUS, ROOM_TYPE } from "../../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const Room = () => {
  const classes = useStyles();

  const { isLoadingRoom, room, isErrorRoom, errorRoom } = useRoomContext();

  const { isLoadingUserInRoom, userInRoom } = useUserInRoomContext();

  const maxContainerWidth = room && room.type === ROOM_TYPE.FORUM ? "md" : "xl";

  return (
    <Box width="100%" height="100%">
      {isLoadingRoom || isLoadingUserInRoom ? (
        <Spinner />
      ) : isErrorRoom ? (
        <Alert severity="error">
          {errorRoom && errorRoom.error.data && errorRoom.error.data.msg}
        </Alert>
      ) : (
        <Box height="100%">
          {room ? (
            <Box display="flex" height="100%">
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                width="100%"
              >
                <RoomHeader />

                {userInRoom ? (
                  userInRoom.approvalStatus === USER_ROOM_STATUS.PENDING ? (
                    <Box>Status pending</Box>
                  ) : userInRoom.approvalStatus === USER_ROOM_STATUS.DENIED ? (
                    <Box>Status denied</Box>
                  ) : userInRoom.approvalStatus ===
                    USER_ROOM_STATUS.APPROVED ? (
                    <Container
                      maxWidth={maxContainerWidth}
                      className={classes.container}
                    >
                      {room.type === ROOM_TYPE.CHAT ? (
                        <>
                          <Box flex="1 1 auto">
                            <RoomPosts
                              parentPostId={null}
                              isReply={false}
                              preview={false}
                            />
                          </Box>
                          <Box flex="0 1 40px">
                            <PostForm parentPostId={null} isReply={false} />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box flex="0 1 40px">
                            <PostForm parentPostId={null} isReply={false} />
                          </Box>
                          <Box flex="1 1 auto">
                            <RoomPosts
                              parentPostId={null}
                              isReply={false}
                              preview={false}
                            />
                          </Box>
                        </>
                      )}
                    </Container>
                  ) : (
                    <Box>
                      Your status in this room is unknown, please contact
                      support.
                    </Box>
                  )
                ) : (
                  <>
                    {room.needApprovalToJoin ? (
                      <Box>You need approval to join this room.</Box>
                    ) : (
                      <Container
                        maxWidth={maxContainerWidth}
                        className={classes.container}
                      >
                        <Box flex="1 1 auto">
                          <RoomPosts
                            parentPostId={null}
                            isReply={false}
                            preview={false}
                          />
                        </Box>
                      </Container>
                    )}
                  </>
                )}

                {/* <Box>
                  {userInRoom ? (
                    userInRoom.approvalStatus === USER_ROOM_STATUS.PENDING ? (
                      <Box>Status pending</Box>
                    ) : userInRoom.approvalStatus ===
                      USER_ROOM_STATUS.DENIED ? (
                      <Box>You have been denied</Box>
                    ) : userInRoom.approvalStatus ===
                      USER_ROOM_STATUS.APPROVED ? (
                      <Box>
                        <RoomPosts
                          parentPostId={null}
                          isReply={false}
                          preview={false}
                        />
                        <PostForm parentPostId={null} isReply={false} />
                      </Box>
                    ) : (
                      <Box>
                        Your status in this room is unknown, please contact
                        support.
                      </Box>
                    )
                  ) : (
                    <Box>
                      {room.needApprovalToJoin ? (
                        <Box>You need approval to join this room.</Box>
                      ) : (
                        <Box>
                          <RoomPosts preview={true} />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box> */}
              </Box>
              <ReplyDrawer />
            </Box>
          ) : (
            <Box>
              <Box>
                <Typography>
                  Unable to find a room with this name. Please double check that
                  this room exists.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Room);
