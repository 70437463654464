// React Router
import { useState } from "react";
// React query
import { useFetchCommunityRegistration } from "../../../hooks/insights";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";
// Components
import DateRangePicker from "../../../shared/DatePickers/DateRangePicker";
import Chart from "../../../shared/Chart";

//datafns
import addWeeks from "date-fns/addWeeks";
import startOfWeek from "date-fns/startOfWeek";
import format from "date-fns-tz/format";
import addDays from "date-fns/addDays";

const CommunityMemberAnalyticsSignups = ({ companyId }) => {
  const theme = useTheme();
  // for the member signups chart
  const isScreenSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));

  const [dateRange, setDateRange] = useState({
    startDate: startOfWeek(addWeeks(new Date(), -4)),
    endDate: addDays(new Date(), 1),
  });

  const {
    isLoading,
    isError,
    data: { data: data } = { data: null },
    error,
  } = useFetchCommunityRegistration(companyId, dateRange);

  console.log(data);

  return (
    <Box>
      <Box mb={1}>
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
      </Box>
      {isLoading ? (
        <Box mt={2}>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={400}
          />
        </Box>
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Chart
                data={data?.memberDateData}
                title={`Signups`}
                subtitle={`${format(
                  dateRange.startDate,
                  "MMM dd, yyyy"
                )} - ${format(dateRange.endDate, "MMM dd, yyyy")}`}
                type="time-series"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: "16px" }}
              >
                <Chart
                  type="number-title"
                  subtitle={`${format(
                    dateRange.startDate,
                    "MMM dd, yyyy"
                  )} - ${format(dateRange.endDate, "MMM dd, yyyy")}`}
                  data={[
                    {
                      count: data?.memberCountForTimePeriod,
                      name: "Member Signups",
                    },
                    {
                      count: data?.activeMemberCount,
                      name: "Active Members",
                    },
                    {
                      count: data?.inActiveMemberCount,
                      name: "In-active Members",
                    },
                  ]}
                  orientation={isScreenSmOrSmaller ? "row" : "column"}
                />
                <Chart
                  type="number-title"
                  data={[
                    {
                      count: data?.ttlMemberCount,
                      name: "Total Members",
                    },
                  ]}
                  orientation={isScreenSmOrSmaller ? "row" : "column"}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.company.companyProfile.id,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps)(CommunityMemberAnalyticsSignups);
