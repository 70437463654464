// React Router
import { Link, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { getCompanyProfile } from "../../redux/actions/company";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MuiLink from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// Icons
import {
  IoVideocamOutline,
  IoPeopleOutline,
  IoBulbOutline,
  IoTicketOutline,
  IoBriefcaseOutline,
  IoColorPaletteOutline,
  IoLogoDiscord,
  IoFileTrayFullOutline,
  IoCallOutline,
} from "react-icons/io5";
// Components
import OrgAppBar from "../../shared/OrgAppBar";
import HomeMemberAnalytics from "./components/HomeMemberAnalytics";
import Button from "../../shared/Button";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  card: {
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    transition: "ease-in-out 0.2s",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const OrgHome = ({ myprofile, companyProfile }) => {
  const classes = useStyles();

  const { orgId } = useParams();

  const time = new Date().getHours();

  useEffect(() => {
    if (!companyProfile && orgId) {
      // Fetch the profile from the database
      getCompanyProfile(orgId);
    }
  }, [orgId]);

  const ORG_HOME_FEATURES = [
    {
      title: "Create New Event",
      icon: <IoTicketOutline />,
      description:
        "Engage your community members by hosting events. Manage your event attendees, track event insights, and  more",
      route: `/org/${orgId}/events/new`,
    },
    {
      title: "Manage Jobs",
      icon: <IoBriefcaseOutline />,
      description:
        "Provide new and exciting job opportunities for your members. You can create a job board for others to post jobs for the community to view",
      route: `/org/${orgId}/jobboard`,
    },
    {
      title: "Create New Program",
      icon: <IoBulbOutline />,
      description:
        "Create and manage applications including competitions, hackathons, fellowships, applications, and more",
      route: `/org/${orgId}/programs/new`,
    },
    {
      title: "Manage Members",
      icon: <IoPeopleOutline />,
      description:
        "Manage and view all of the members in your community in one place. Approve members and track the community growth.",
      route: `/org/${orgId}/members`,
    },
    {
      title: "Customize Branding",
      icon: <IoColorPaletteOutline />,
      description:
        "Update and customize your community to match your brand. Add your own logo, colors, and header image. Make the page your own.",
      route: `/org/${orgId}/settings/branding`,
    },
    {
      title: "Connect Slack / Discord",
      icon: <IoLogoDiscord />,
      description:
        "Already using Slack or Discord? No problem, connect your Slack workspace or Discord server to your community for members to join.",
      route: `/org/${orgId}/settings/messaging`,
    },
  ];

  const ORG_LEARN_MORE = [
    {
      title: "Onboarding Info",
      icon: <IoVideocamOutline />,
      description: "Get started with Pralent in 5 minutes",
      href: `https://www.notion.so/pralent/Pralent-in-2-minutes-c04b8d070ff1429aba7b1d9635ad7a9e`,
    },
    {
      title: "Features Guide",
      icon: <IoFileTrayFullOutline />,
      description: "Learn more about all of our features",
      href: `https://help.pralent.com`,
    },
    {
      title: "Setup Onboarding Call",
      icon: <IoCallOutline />,
      description: "Setup a free onboarding call to get you all setup",
      href: `https://cal.com/pralent/30min`,
    },
  ];

  return (
    <>
      <OrgAppBar
        title={`Welcome back${
          myprofile ? ` ${myprofile.firstName}` : ""
        }, good ${
          time < 10 ? "morning" : time < 18 ? "afternoon" : "evening"
        }!`}
        subtitle="Manage your organization and community"
        actions={
          <Button
            component={Link}
            to={`/c/${companyProfile?.communitySlug}`}
            startIcon={<>🌎</>}
          >
            My Community Page
          </Button>
        }
      />
      <Divider className={classes.divider} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="div">
                <Box fontWeight={600}>Quick Links</Box>
              </Typography>
            </Grid>
            {(ORG_HOME_FEATURES || []).map((feature, index) => {
              return (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <Card
                    component={Link}
                    to={feature.route}
                    className={classes.card}
                  >
                    <CardContent>
                      <Box mb={1} display="flex" alignItems="center">
                        <Box mr={1} fontSize="h5.fontSize" color="primary.main">
                          {feature.icon}
                        </Box>
                        <Typography variant="body1" component="div">
                          <Box fontWeight={600}>{feature.title}</Box>
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        component="div"
                        color="textSecondary"
                        style={{ flexGrow: 1 }}
                      >
                        <Box mb={1} lineHeight={1.3}>
                          {feature.description}
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
            {/* Quick Links Container ends */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <HomeMemberAnalytics />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="stretch"
          >
            <Grid item xs={12}>
              <Typography variant="h5" component="div">
                <Box fontWeight={600}>Learn more</Box>
              </Typography>
            </Grid>
            {(ORG_LEARN_MORE || []).map((feature, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <Card
                    component={MuiLink}
                    href={feature.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="textSecondary"
                    style={{ textDecoration: "none" }}
                    className={classes.card}
                  >
                    <CardContent>
                      <Box mb={1} display="flex" alignItems="center">
                        <Box mr={1} fontSize="h5.fontSize" color="primary.main">
                          {feature.icon}
                        </Box>
                        <Typography
                          variant="body1"
                          component="div"
                          color="textPrimary"
                        >
                          <Box fontWeight={600}>{feature.title}</Box>
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        component="div"
                        color="textSecondary"
                        style={{ flexGrow: 1 }}
                      >
                        <Box mb={1} lineHeight={1.3}>
                          {feature.description}
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps)(OrgHome);
