import axios from "../../utils/axios";
// actionTypes
import {
  GET_POSTS,
  LOADING_POSTS,
  CLEAR_POSTS_KEYS,
  UPDATE_REACTION_POSTS,
} from "./actionTypes";
import { handleServerError } from "./error";

export const getRoomPosts =
  (isReply, roomId, postId, offset, limit, lastPostOnBottom) =>
  async (dispatch) => {
    try {
      if (offset === 0) {
        const id = isReply ? postId : roomId;
        await dispatch({
          type: LOADING_POSTS,
          payload: { id },
        });
      }
      console.log(isReply, roomId, postId, offset, limit, lastPostOnBottom);
      const res = await axios.get(
        `/api/room/posts/all?isReply=${isReply}&roomId=${roomId}&postId=${postId}&offset=${offset}&limit=${limit}`
      );
      if (res.data) {
        await dispatch({
          type: GET_POSTS,
          payload: {
            isReply,
            roomId,
            postId,
            offset,
            limit,
            lastPostOnBottom,
            posts: res.data.posts,
            count: res.data.count,
          },
        });
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const handleClearPostsKeys = (id) => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_POSTS_KEYS,
      payload: { id },
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const updateReactionPosts =
  (id, updatedReaction) => async (dispatch) => {
    try {
      await dispatch({
        type: UPDATE_REACTION_POSTS,
        payload: { id, updatedReaction },
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };
