import React from "react";

import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const TextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  ...textFieldProps
}) => {
  const _onChange = ({ target: { value } }) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  return (
    <>
      <Box display={disabled ? "none" : "block"} width="100%">
        <TextField
          id={id}
          placeholder={placeholder}
          label={null}
          autoFocus={autofocus}
          required={required}
          disabled={disabled || readonly}
          type={"color"}
          value={value || value === 0 ? value : "#000000"}
          error={rawErrors.length > 0}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          {...textFieldProps}
          style={{ width: "100%", maxWidth: 200 }}
        />
      </Box>
      {disabled && (
        <Box display="flex" alignItems="center">
          <Box
            width={5}
            mr={1}
            p={2}
            borderRadius="borderRadius"
            style={{ backgroundColor: value }}
          />
          <Typography variant="body2" color="textPrimary" component="div">
            <em>{value}</em>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TextWidget;
