import React, { useState, useEffect } from "react";
import { MdErrorOutline, MdCheckCircle } from "react-icons/md";
import Markdown from "markdown-to-jsx";

const ATag = ({ href, children }) => (
  <a href={href} className="text-primary-500 hover:text-primary-600">
    {children}
  </a>
);

const defaultProps = {
  color: "red",
  title: "Error",
  text:
    "There seems to have been an error on our part. Contact support if this issue persists.",
};
const Alert = ({ color, title, text }) => {
  const iconStyles = `fill-current h-6 w-6 text-${color}-500 mr-4`;

  const [Icon, setIcon] = useState(<MdErrorOutline className={iconStyles} />);

  useEffect(() => {
    if (color === "red") {
      setIcon(<MdErrorOutline className={iconStyles} />);
    } else if (color === "green") {
      setIcon(<MdCheckCircle className={iconStyles} />);
    }
  }, [color, title, text]);

  return (
    <div
      className={`w-full mx-auto bg-${color}-100 border-t-4 border-${color}-500 rounded-b text-${color}-900 px-4 py-3 shadow mb-4 md:mb-6`}
      role="success"
    >
      <div className="flex">
        <div className="py-1">{Icon}</div>
        <div>
          <p className="font-bold">{title}</p>
          <Markdown
            options={{
              forceBlock: true,
              overrides: {
                a: {
                  component: ATag,
                },
              },
            }}
          >
            {text}
          </Markdown>
        </div>
      </div>
    </div>
  );
};

Alert.defaultProps = defaultProps;

export default Alert;
