import React, { useState } from "react";
import { connect } from "react-redux";
import {
  addUserCompanyRelationship,
  deleteUserCompanyRelationship,
} from "../../../redux/actions/company";
import { setAlert } from "../../../redux/actions/alert";
import axios from "../../../utils/axios";
// Material UI
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
// Components
import ProfilePopover from "../../../shared/ProfilePopover";
import CustomAvatar from "../../../shared/CustomAvatar";
import Button from "../../../shared/Button";
import useClipboard from "react-use-clipboard";

const useStyles = makeStyles((theme) => ({
  teamMemberAvatar: {
    height: "auto",
    borderRadius: theme.shape.borderRadius,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Team = ({
  companyProfile,
  addUserCompanyRelationship,
  deleteUserCompanyRelationship,
  user,
  setAlert,
}) => {
  const classes = useStyles();
  const [searchEmail, setSearchEmail] = useState("");
  const [foundUser, setFoundUser] = useState(null);
  const [searchEmailError, setSearchEmailError] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      if (foundUser) {
        setSearchEmailError(
          "Add or remove user before searching for another one."
        );
      } else {
        const res = await axios.get(`/api/company/findUser/${searchEmail}`);
        setFoundUser(res.data);
        setSearchEmailError("");
      }
    } catch (error) {
      setSearchEmailError(
        error && error.data && error.data.msg
          ? error.data.msg
          : "There was an error searching for this user."
      );
      setFoundUser(null);
    }
    setSearchEmail("");
  };

  const handleAddUserRelationship = async () => {
    await addUserCompanyRelationship(companyProfile.id, foundUser.id);
    setFoundUser(null);
    setSearchEmail("");
    setSearchEmailError("");
  };

  const handleDeleteUserRelationship = async (userId) => {
    await deleteUserCompanyRelationship(companyProfile.id, userId);
  };

  const [isCopiedSignUpLink, setCopiedSignUpLink] = useClipboard(
    `${document.location.origin}/signup`
  );
  const onCopySignUpLink = () => {
    setCopiedSignUpLink();
    setAlert("Successfully copied organization signup link.", "success");
  };

  return (
    <>
      <Typography variant="h5" component="h2">
        <Box fontWeight={600}>Community Managers</Box>
      </Typography>
      <Typography variant="body2" component="div" color="textSecondary">
        <Box mb={2}>
          Before you add community manager(s), you must instruct them to create
          an account.{" "}
          <Link onClick={onCopySignUpLink}>Copy link to signup.</Link>
        </Box>
      </Typography>

      <form onSubmit={onSubmit}>
        <Box display="flex" alignItems="stretch" my={[1, 2]}>
          <TextField
            placeholder="Enter email address to add a community manager"
            type="email"
            required
            onChange={(e) => setSearchEmail(e.target.value)}
            name="searchEmail"
            value={searchEmail}
            fullWidth
          />
          <Button type="submit">Submit</Button>
        </Box>
        {searchEmailError && (
          <Typography color="error" variant="caption">
            {searchEmailError}
          </Typography>
        )}
      </form>
      <Box>
        {foundUser && (
          <>
            <Box display="flex" alignItems="center" my={2}>
              <Box lineHeight={0} flexGrow="1">
                <ProfilePopover userId={foundUser.id}>
                  <Box display="flex" alignItems="center">
                    <CustomAvatar
                      src={foundUser && foundUser.avatar}
                      fallbackText={foundUser && foundUser.userName}
                      alt={foundUser && foundUser.userName}
                      className={classes.teamMemberAvatar}
                    />

                    <Box ml={2}>
                      <Typography variant="body1" component="div">
                        <Box fontWeight={600}>
                          {foundUser && foundUser.userName}
                        </Box>
                      </Typography>
                      <Typography variant="body2">
                        {foundUser && foundUser.email}
                      </Typography>
                    </Box>
                  </Box>
                </ProfilePopover>
              </Box>

              <Box flexShrink="1">
                <Button variant="outlined" onClick={() => setFoundUser(null)}>
                  Remove
                </Button>
                <Button onClick={handleAddUserRelationship}>Add</Button>
              </Box>
            </Box>
            <Divider />
          </>
        )}
        {companyProfile &&
          companyProfile.team &&
          companyProfile.team.length > 0 &&
          companyProfile.team.map((member, index) => {
            const memberUser = member.user;
            return (
              <Box key={index} my={2} display="flex" alignItems="center">
                <Box lineHeight={0} flexGrow="1">
                  <ProfilePopover userId={memberUser.id}>
                    <Box display="flex" alignItems="center">
                      <CustomAvatar
                        src={memberUser && memberUser.avatar}
                        fallbackText={memberUser && memberUser.userName}
                        alt={memberUser && memberUser.userName}
                        className={classes.teamMemberAvatar}
                      />

                      <Box ml={2}>
                        <Typography variant="body1" component="div">
                          <Box fontWeight={600}>
                            {memberUser && memberUser.userName}
                          </Box>
                        </Typography>
                        <Typography variant="body2">
                          {memberUser && memberUser.email}
                        </Typography>
                      </Box>
                    </Box>
                  </ProfilePopover>
                </Box>

                {user && user.id !== memberUser.id && (
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteUserRelationship(memberUser.id)}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            );
          })}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
  user: state.auth.user,
});

const mapDispatchToProps = {
  addUserCompanyRelationship,
  deleteUserCompanyRelationship,
  setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
