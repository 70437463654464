import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// Material Ui
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(4),
  },
}));

export const ProfileSkills = ({ profile: { skillSets } }) => {
  const classes = useStyles();
  const [displaySkills, setDisplaySkills] = useState(false);

  useEffect(() => {
    if (
      skillSets &&
      skillSets.selectedSkills &&
      skillSets.selectedSkills.length > 0
    ) {
      setDisplaySkills(true);
    }
    return () => {
      setDisplaySkills(false);
    };
  }, [skillSets]);

  return (
    <>
      {displaySkills && (
        <Box>
          <Box my={{ xs: 2, md: 4 }}>
            <Divider light />
          </Box>

          <Box mb={{ xs: 2 }}>
            <Typography variant="h5" component="div">
              <Box px={2} fontWeight={600}>
                Skills
              </Box>
            </Typography>
          </Box>
          {skillSets &&
            skillSets.selectedSkills &&
            skillSets.selectedSkills.length > 0 &&
            skillSets.selectedSkills
              .sort((a, b) => b.value - a.value)
              .map((skill, index) => {
                return (
                  <Box
                    key={index}
                    px={2}
                    pb={2}
                    display="flex"
                    alignItems="center"
                  >
                    <Box width={125}>
                      <Typography variant="body1" color="textPrimary">
                        <Box fontWeight={500} lineHeight={1.2}>
                          {skill.name}
                        </Box>
                      </Typography>
                    </Box>

                    <Box width="100%" mx={1}>
                      <LinearProgress
                        variant="determinate"
                        value={skill.value}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                      >{`${Math.round(skill.value)}%`}</Typography>
                    </Box>
                  </Box>
                );
              })}
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSkills);
