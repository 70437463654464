import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
// Material UI
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import CardMedia from "@material-ui/core/CardMedia";
// Icons
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import CodeIcon from "@material-ui/icons/Code";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
// components
import UserAvatar from "../../../shared/UserAvatar";
import Button from "../../../shared/Button";
import ProfileButton from "./ProfileButton";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  icon: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.h4.fontSize,
  },
  check: {
    color: theme.palette.success.main,
  },
  clear: {
    color: theme.palette.error.main,
  },
  tooltip: {
    maxWidth: "none",
  },
  header: {
    textAlign: "left",
  },
  card: {
    boxShadow: "none",
    borderRadius: theme.shape.borderRadius,
  },
  educationText: {
    lineHeight: 1.3,
  },
  ProfileLinks: {
    // color: theme.palette.grey[700],
    marginRight: theme.spacing(2),
  },
  backgroundImage: {
    height: 200,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
  },
}));

const Profile = ({ user, myprofile }) => {
  const classes = useStyles();

  const [links, setLinks] = useState({
    github: "",
    linkedin: "",
    personalWebsite: "",
  });

  useEffect(() => {
    if (
      myprofile &&
      myprofile.publicProfileLinks &&
      myprofile.publicProfileLinks.length > 0
    ) {
      const github = myprofile.publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 1
      );
      const linkedin = myprofile.publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 2
      );
      const personalWebsite = myprofile.publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 3
      );
      setLinks({
        ...links,
        github: github ? github.url : "",
        linkedin: linkedin ? linkedin.url : "",
        personalWebsite: personalWebsite ? personalWebsite.url : "",
      });
    }
  }, [myprofile && myprofile.publicProfileLinks]);

  const { github, linkedin, personalWebsite } = links;

  return (
    <>
      <Box>
        <CardMedia
          className={classes.backgroundImage}
          component="img"
          src="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
          title="background-image"
        />
        <Box ml={2}>
          <UserAvatar style={{ zIndex: "1000" }} />
        </Box>
        <Box px={{ xs: 2, md: 4 }}>
          <Grid container spacing={4}>
            {myprofile && (
              <Grid item xs={12} md={6}>
                <Typography variant="h5" component="div">
                  <Box fontWeight={600}>
                    {myprofile.firstName} {myprofile.lastName}
                  </Box>
                </Typography>
                {myprofile && myprofile.aboutMe && (
                  <Typography variant="body1" component="div" gutterBottom>
                    <Box lineHeight={1.2}>{myprofile.aboutMe}</Box>
                  </Typography>
                )}
                {myprofile.education && myprofile.education.length > 0 && (
                  <>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                    >
                      {myprofile.education[0].degree},{" "}
                      {myprofile.education[0].fieldOfStudy}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="div"
                    >
                      {myprofile.education[0].school},{" "}
                      {new Date(
                        myprofile.education[0].completionDate
                      ).getFullYear()}
                    </Typography>
                  </>
                )}
                {myprofile.location && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    {myprofile.location}
                  </Typography>
                )}
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Box mt={1} display="flex" flexDirection="column">
                {github !== "" && (
                  <Box mb={1}>
                    <Link
                      href={github}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      color="inherit"
                    >
                      <Tooltip
                        placement="left"
                        title={"Code Repository"}
                        aria-label={`github-link-${github}`}
                      >
                        <Box display="flex" alignItems="center">
                          <CodeIcon
                            rel={"noopener noreferrer"}
                            target={"_blank"}
                            className={classes.icon}
                          />{" "}
                          <Typography variant="body2" component="div">
                            <Box textAlign="left">{github}</Box>
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Link>
                  </Box>
                )}
                {linkedin !== "" && (
                  <Box mb={1}>
                    <Link
                      href={linkedin}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      color="inherit"
                    >
                      <Tooltip
                        placement="left"
                        title={"LinkedIn Profile"}
                        aria-label={`linkedin-link-${linkedin}`}
                      >
                        <Box display="flex" alignItems="center">
                          <LinkedInIcon
                            rel={"noopener noreferrer"}
                            target={"_blank"}
                            className={classes.icon}
                            style={{ color: "#0077B5" }}
                          />{" "}
                          <Typography variant="body2" component="div">
                            <Box textAlign="left">{linkedin}</Box>
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Link>
                  </Box>
                )}
                {personalWebsite !== "" && (
                  <Box mb={1}>
                    <Link
                      href={personalWebsite}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      color="inherit"
                    >
                      <Tooltip
                        placement="left"
                        title={"Personal Website"}
                        aria-label={`personalWebsite-link-${personalWebsite}`}
                      >
                        <Box display="flex" alignItems="center">
                          <LanguageIcon
                            rel={"noopener noreferrer"}
                            target={"_blank"}
                            className={classes.icon}
                            style={{ color: "#C9186E" }}
                          />{" "}
                          <Typography variant="body2" component="div">
                            <Box textAlign="left">{personalWebsite}</Box>
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Link>
                  </Box>
                )}
                {myprofile && myprofile.resumeLocation && (
                  <Box mb={1}>
                    <Link
                      href={myprofile.resumeLocation}
                      rel={"noopener noreferrer"}
                      target={"_blank"}
                      color="inherit"
                    >
                      <Tooltip
                        placement="left"
                        title={"Download Resume"}
                        aria-label={`resumeLocation-link-${myprofile.resumeLocation}`}
                      >
                        <Box display="flex" alignItems="center">
                          <DescriptionIcon
                            rel={"noopener noreferrer"}
                            target={"_blank"}
                            className={classes.icon}
                            style={{ color: "#008891" }}
                          />{" "}
                          <Typography variant="body2" component="div">
                            <Box textAlign="left">Download Resume</Box>
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Link>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Typography gutterBottom variant="body2" component="div">
              <Box fontWeight={600}>Actions</Box>
            </Typography>
            <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
              <ProfileButton edit={myprofile !== null} />
              <Button
                color="default"
                variant="outlined"
                startIcon={<OpenInBrowserOutlinedIcon />}
                component={RouterLink}
                to={`/@${user && user.userName}`}
              >
                Preview Profile
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

Profile.propTypes = {
  myprofile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Profile);
