import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
// React router
import { Link, useParams } from "react-router-dom";
// React query
import { useQueryClient } from "react-query";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
// Components
import LogInForm from "../LogInForm";

const CommunityLock = ({ companyDetails, isAuthenticated }) => {
  const queryClient = useQueryClient();

  const { communitySlug } = useParams();

  const [showLogIn, setShowLogIn] = useState(false);

  const loginLockAction = async () => {
    await queryClient.invalidateQueries([
      "communityMember",
      { companyId: companyDetails.id },
    ]);
  };

  return (
    <Container maxWidth="sm">
      <Box
        p={{ xs: 2, lg: 4, xl: 6 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor="background.paper"
        borderRadius="borderRadius"
        my={4}
      >
        <LockOutlinedIcon fontSize="large" />
        <Typography variant="h5" component="div" gutterBottom>
          <Box mt={1} textAlign="center" fontWeight={600}>
            Members Only
          </Box>
        </Typography>
        <Typography variant="body1" component="div" color="textSecondary">
          <Box textAlign="center" lineHeight={1.2}>
            Unfortunately, only members are able to access this page at this
            time. Request access to become a member or login to access. If this
            is a problem, please contact the community organizers or support.
          </Box>
        </Typography>
        <Box mt={2}>
          <Typography variant="body1" component="div" color="textSecondary">
            <Box fontWeight={600} component={Link} to={`/c/${communitySlug}`}>
              Request Access <ArrowForwardIcon />
            </Box>
          </Typography>
        </Box>
        {!isAuthenticated && (
          <>
            <Box mt={2}>
              <Typography variant="body1" component="div" color="textSecondary">
                <Box
                  fontWeight={600}
                  onClick={() => setShowLogIn(!showLogIn)}
                  style={{ cursor: "pointer" }}
                >
                  Login <LockOpenOutlinedIcon />
                </Box>
              </Typography>
            </Box>
            {!showLogIn && (
              <Box mt={2}>
                <LogInForm actionCallback={loginLockAction} />
              </Box>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityLock);
