import React from "react";
// Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
import LocationOnIcon from "@material-ui/icons/LocationOn";

const EventLocation = ({ location, size = "md" }) => {
  return (
    <Typography
      variant={size === "sm" ? "body2" : "body1"}
      component="div"
      color="textSecondary"
    >
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <LocationOnIcon />
        </Box>{" "}
        {location}
      </Box>
    </Typography>
  );
};

export default EventLocation;
