import React from "react";
// react router
import { useLocation, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getAllMyOrganizations,
  clearAllOrganizations,
} from "../../../redux/actions/profile";
// React query
import { useQuery } from "react-query";
import { useGetAllMyCommunities } from "../../../hooks/community";
// Material UI
import Spinner from "../../../shared/Spinner";
import { makeStyles, useTheme } from "@material-ui/core";
// Icons
import AddIcon from "@material-ui/icons/Add";
// Components
import CustomListItem from "../../../shared/CustomListItem";
import CustomAvatar from "../../../shared/CustomAvatar";

const useStyles = makeStyles((theme) => ({
  communityLogo: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: 999999,
    objectFit: "object-cover",
  },
  addIcon: {
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.h3.fontSize,
    color: theme.palette.text.primary,
    padding: theme.spacing(0.5),
  },
}));

const OrganizationList = ({
  user,
  getAllMyOrganizations,
  clearAllOrganizations,
}) => {
  const theme = useTheme();

  const dark = theme.palette.type === "dark";

  const classes = useStyles();

  const location = useLocation();

  const { communitySlug } = useParams();

  const {
    isLoading: isLoadingCommunities,
    data: { data: myCommunities } = { data: null },
    isError: isErrorCommunities,
    error: errorCommunities,
  } = useGetAllMyCommunities(user);

  useQuery(["myOrganizations", user?.id], async () => getAllMyOrganizations);

  return (
    <>
      {isErrorCommunities && (
        <Alert severity="error">
          {errorCommunities &&
            errorCommunities.data &&
            errorCommunities.data.msg}
        </Alert>
      )}
      {isLoadingCommunities ? (
        <Spinner />
      ) : (
        myCommunities &&
        myCommunities.map((communities, index) => {
          const company = communities.company;
          if (!company) return null;
          const to = `/c/${company.communitySlug}`;
          const name = company.companyName;
          const primaryColor = company.primaryColor;

          return (
            <CustomListItem
              key={index}
              dark={dark}
              iconHover
              to={to}
              icon={
                <CustomAvatar
                  src={company.companyLogoPath}
                  fallbackText={company.companyName}
                  alt="company-logo"
                  className={classes.communityLogo}
                  style={{ backgroundColor: primaryColor }}
                />
              }
              text={`${name}`}
              displayText={false}
              currentTab={communitySlug === company.communitySlug}
            />
          );
        })
      )}
      <CustomListItem
        dark={dark}
        iconHover
        icon={<AddIcon color="disabled" className={classes.addIcon} />}
        text="Create new community"
        displayText={false}
        to={"/org/new"}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  challenges: state.challenge.challenges,
});

const mapDispatchToProps = {
  getAllMyOrganizations,
  clearAllOrganizations,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
