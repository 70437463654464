import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IoLogoGoogle } from "react-icons/io";
import GoogleLogin from "react-google-login";
import { oauthGoogle, oauthRegisterGoogle } from "../../redux/actions/auth";

import Button from "../../shared/Button";

const GoogleLoginButton = ({
  oauthGoogle,
  oauthRegisterGoogle,
  text,
  typeId = 1,
  register = false,
  history,
}) => {
  const responseGoogle = (res) => {
    if (res && res.code && res.code !== "") {
      //console.log(res);
      if (register) {
        //console.log("register");
        oauthRegisterGoogle(res.code, typeId, register, history);
      } else {
        //console.log("auth");
        oauthGoogle(res.code, typeId, register, history);
      }
    }
  };
  return (
    <Fragment>
      {
        <GoogleLogin
          clientId={process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID}
          buttonText=""
          autoLoad={false}
          isSignedIn={false}
          render={(renderProps) => (
            <Button
              fullWidth
              variant={"outlined"}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              size="large"
              startIcon={<IoLogoGoogle />}
            >
              {text}
            </Button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          responseType="code"
        />
      }
    </Fragment>
  );
};

GoogleLoginButton.propTypes = {
  oauthGoogle: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default connect(null, { oauthGoogle, oauthRegisterGoogle })(
  GoogleLoginButton
);
