import React, { Fragment, useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { IoMdClose } from "react-icons/io";
import {
  addEducation,
  createProfile,
  addProfileLinks,
  addProfileSkills,
} from "../../../redux/actions/profile";
import { setAlert } from "../../../redux/actions/alert";
import { updateUserAccount } from "../../../redux/actions/auth";
// Material Ui
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
// Icons
import InfoIcon from "@material-ui/icons/Info";
// components
import RenderGroup from "../../../shared/RenderGroup";
import Listbox from "../../../shared/Listbox";
import Button from "../../../shared/Button";
import UploadResume from "./UploadResume";
import { GENDER_TYPE } from "../../../utils/globalValues";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: "0",
  },
  appBar: {
    position: "relative",
    boxShadow: "none",
  },
  button: {
    fontWeight: "600",
  },

  divider: {
    marginTop: "20px",
    marginBottom: "30px",
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  searchInput: {
    marginBottom: "10px",
  },
}));

const ProfileForm = ({
  toggle,
  modal,
  myprofile,
  user,
  createProfile,
  addEducation,
  addProfileLinks,
  addProfileSkills,
  genderTypes,
  raceTypes,
  setAlert,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const schools = require("../../../utils/Schools");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    aboutMe: "",
    location: "",
    gender: GENDER_TYPE.PREFER_NOT_TO_ANSWER,
    race: [],
    activelySeekingJob: true,
    workAuthorization: true,
    needVisa: false,
    veteranStatus: false,
    disabilityStatus: false,
    privacySetting: true,
  });

  const [educationFormData, setEducationFormData] = useState({
    id: undefined,
    school: "",
    fieldOfStudy: "",
    degree: "",
    startDate: new Date(),
    completionDate: new Date(),
  });

  const [publicProfileLinks, setPublicProfileLinks] = useState({
    GitHub: "",
    Linkedin: "",
    PersonalWebsite: "",
  });

  const [userFormData, setUserFormData] = useState({
    userName: "",
  });

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);

  const {
    firstName,
    lastName,
    disabilityStatus,
    veteranStatus,
    needVisa,
    workAuthorization,
    privacySetting,
    activelySeekingJob,
    location,
    aboutMe,
  } = formData;
  const { school, degree, fieldOfStudy, startDate, completionDate } =
    educationFormData;
  const { userName } = userFormData;
  const { GitHub, Linkedin, PersonalWebsite } = publicProfileLinks;

  useEffect(() => {
    if (myprofile) {
      let newGender = null;
      if (myprofile.gender) {
        const found = genderTypes.findIndex(
          (elem) => elem.id === myprofile.gender
        );
        if (found !== -1) {
          newGender = {
            id: genderTypes[found].id,
            name: genderTypes[found].name,
          };
        }
      }
      setSelectedGender(newGender);

      let newRaces = [];
      myprofile.race &&
        myprofile.race.map((raceid) => {
          const found = raceTypes.findIndex((elem) => elem.id === raceid);
          if (found !== -1) {
            newRaces.push({
              id: raceTypes[found].id,
              name: raceTypes[found].name,
            });
          }
        });
      setSelectedRaces(newRaces);

      setFormData({
        ...formData,
        firstName: myprofile.firstName || firstName,
        lastName: myprofile.lastName || lastName,
        aboutMe: myprofile.aboutMe || aboutMe,
        location: myprofile.location || location,
        gender: myprofile.gender,
        race: myprofile.race,
        activelySeekingJob: myprofile.activelySeekingJob,
        workAuthorization: myprofile.workAuthorization,
        needVisa: myprofile.needVisa,
        veteranStatus: myprofile.veteranStatus,
        disabilityStatus: myprofile.disabilityStatus,
        privacySetting: myprofile.privacySetting,
      });
    }
  }, [myprofile]);

  useEffect(() => {
    if (myprofile && myprofile.education && myprofile.education.length > 0) {
      const eduInfo = myprofile.education[0];
      setEducationFormData({
        ...educationFormData,
        id: eduInfo.id || undefined,
        school: eduInfo.school || school,
        fieldOfStudy: eduInfo.fieldOfStudy || fieldOfStudy,
        degree: eduInfo.degree || degree,
        startDate: eduInfo.startDate || startDate,
        completionDate: eduInfo.completionDate || completionDate,
      });
    }
  }, [myprofile && myprofile.education]);

  useEffect(() => {
    if (
      myprofile &&
      myprofile.publicProfileLinks &&
      myprofile.publicProfileLinks.length > 0
    ) {
      const github = myprofile.publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 1
      );
      const linkedin = myprofile.publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 2
      );
      const personalWebsite = myprofile.publicProfileLinks.find(
        (elem) => elem.publicProfileTypeId === 3
      );

      setPublicProfileLinks({
        GitHub: (github && github.url) || "",
        Linkedin: (linkedin && linkedin.url) || "",
        PersonalWebsite: (personalWebsite && personalWebsite.url) || "",
      });
    }
  }, [myprofile && myprofile.publicProfileLinks]);

  useEffect(() => {
    if (user) {
      setUserFormData({
        ...userFormData,
        userName: user.userName || userName,
      });
    }
  }, [user]);

  useEffect(() => {
    if (myprofile && myprofile.skillSets) {
      setSelectedSkills(myprofile.skillSets.selectedSkills);
    }
  }, [myprofile && myprofile.skillSets]);

  const checkProfileClean = (formData) => {
    if (!myprofile) {
      return false;
    }
    return (
      formData.firstName === myprofile.firstName &&
      formData.lastName === myprofile.lastName &&
      formData.aboutMe === myprofile.aboutMe &&
      formData.location === myprofile.location &&
      formData.gender === myprofile.gender &&
      formData.race === myprofile.race &&
      formData.activelySeekingJob === myprofile.activelySeekingJob &&
      formData.workAuthorization === myprofile.workAuthorization &&
      formData.needVisa === myprofile.needVisa &&
      formData.veteranStatus === myprofile.veteranStatus &&
      formData.disabilityStatus === myprofile.disabilityStatus &&
      formData.privacySetting === myprofile.privacySetting
    );
  };

  const checkEducationClean = (educationFormData) => {
    if (!(myprofile && myprofile.education && myprofile.education.length > 0)) {
      return false;
    }
    const eduInfo = myprofile.education[0];
    return (
      educationFormData.school === eduInfo.school &&
      educationFormData.degree === eduInfo.degree &&
      educationFormData.fieldOfStudy === eduInfo.fieldOfStudy &&
      educationFormData.startDate === eduInfo.startDate &&
      educationFormData.completionDate === eduInfo.completionDate
    );
  };

  const checkPublicProfileLinksClean = () => {
    if (
      !(
        myprofile &&
        myprofile.publicProfileLinks &&
        myprofile.publicProfileLinks.length > 0
      )
    ) {
      return false;
    }
    const github = myprofile.publicProfileLinks.find(
      (elem) => elem.publicProfileTypeId === 1
    );
    const linkedin = myprofile.publicProfileLinks.find(
      (elem) => elem.publicProfileTypeId === 2
    );
    const personalWebsite = myprofile.publicProfileLinks.find(
      (elem) => elem.publicProfileTypeId === 3
    );
    return (
      github &&
      github.url === GitHub &&
      linkedin &&
      linkedin.url === Linkedin &&
      personalWebsite &&
      personalWebsite.url === PersonalWebsite
    );
  };

  const checkSkillsClean = (selectedSkills) => {
    return (
      myprofile &&
      myprofile.skillSets &&
      myprofile.skillSets.selectedSkills &&
      myprofile.skillSets.selectedSkills.length === selectedSkills.length &&
      selectedSkills.every((elem) =>
        myprofile.skillSets.selectedSkills.includes(elem)
      )
    );
  };

  const degrees = [
    { name: "BA/BS" },
    { name: "MS" },
    { name: "PhD" },
    { name: "Coding Bootcamp" },
    { name: "High School Diploma" },
    { name: "MBA" },
    { name: "Junior College" },
    { name: "JD" },
    { name: "Certificate" },
    { name: "MFA" },
    { name: "BFA" },
  ];

  const handleRaceSelectChange = (selectedRaces) => {
    const max = 5;
    if (selectedRaces !== undefined) {
      let newRaces = selectedRaces;

      if (newRaces.length > max) newRaces.shift();
      setSelectedRaces(newRaces);
      let newSelectedRaces = [];
      newRaces.forEach((element) => {
        newSelectedRaces = [...newSelectedRaces, element.id];
      });
      setFormData({ ...formData, race: newSelectedRaces });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!checkProfileClean(formData)) {
      await createProfile(formData, true);
    }
    if (!checkEducationClean(educationFormData)) {
      await addEducation(educationFormData);
    }
    if (!checkPublicProfileLinksClean(publicProfileLinks)) {
      let github, linkedin, personalWebsite;
      if (
        myprofile &&
        myprofile.publicProfileLinks &&
        myprofile.publicProfileLinks.length > 0
      ) {
        github = myprofile.publicProfileLinks.find(
          (elem) => elem.publicProfileTypeId === 1
        );
        linkedin = myprofile.publicProfileLinks.find(
          (elem) => elem.publicProfileTypeId === 2
        );
        personalWebsite = myprofile.publicProfileLinks.find(
          (elem) => elem.publicProfileTypeId === 3
        );
      }
      const linksObj = {
        publicProfileLinks: [
          {
            id: (github && github.id) || undefined,
            publicProfileTypeId: 1,
            url: GitHub,
          },
          {
            id: (linkedin && linkedin.id) || undefined,
            publicProfileTypeId: 2,
            url: Linkedin,
          },
          {
            id: (personalWebsite && personalWebsite.id) || undefined,
            publicProfileTypeId: 3,
            url: PersonalWebsite,
          },
        ],
      };
      await addProfileLinks(linksObj);
    }
    if (!checkSkillsClean(selectedSkills)) {
      await addProfileSkills({
        selectedSkills: selectedSkills,
      });
    }
    toggle();
  };

  const handleGenderSelectChange = (selectedGender) => {
    if (selectedGender && selectedGender.id) {
      setFormData({
        ...formData,
        gender: selectedGender.id,
      });

      setSelectedGender(selectedGender);
    } else {
      setFormData({
        ...formData,
        gender: GENDER_TYPE.PREFER_NOT_TO_ANSWER,
      });
      setSelectedGender(null);
    }
  };

  const onChange = (e, data, set) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    set({
      ...data,
      [name]: value,
    });
  };

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"sm"}
        open={modal}
        onClose={toggle}
      >
        <form onSubmit={(e) => onSubmit(e)}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggle}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography component="div" variant="h6">
                <Box fontWeight={600}>My Profile</Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel required>First Name</InputLabel>
                  <TextField
                    id={"firstName"}
                    autoComplete={"fname"}
                    variant={"outlined"}
                    type={"text"}
                    name={"firstName"}
                    required
                    value={firstName}
                    onChange={(e) => onChange(e, formData, setFormData)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel>Last Name</InputLabel>
                  <TextField
                    id={"lastName"}
                    autoComplete={"lname"}
                    variant={"outlined"}
                    type={"text"}
                    name={"lastName"}
                    required
                    value={lastName}
                    onChange={(e) => onChange(e, formData, setFormData)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Headline</InputLabel>
                  <TextField
                    id={"aboutMe"}
                    required
                    fullWidth
                    autoComplete={"about"}
                    variant={"outlined"}
                    type="text"
                    name="aboutMe"
                    multiline={true}
                    rows={2}
                    maxLength="250"
                    value={aboutMe}
                    onChange={(e) => onChange(e, formData, setFormData)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Location</InputLabel>
                  <TextField
                    id={"location"}
                    autoComplete={"location"}
                    variant={"outlined"}
                    type={"text"}
                    name={"location"}
                    value={location}
                    onChange={(e) => onChange(e, formData, setFormData)}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider variant={"middle"} className={classes.divider} />
                <FormGroup>
                  <InputLabel required>School</InputLabel>
                  <Autocomplete
                    id="controlled-demo"
                    autoComplete
                    ListboxComponent={Listbox}
                    renderGroup={RenderGroup}
                    options={schools}
                    getOptionLabel={(option) => {
                      // typeof option === "string" ? option : option.institution
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.institution;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          institution: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    required
                    freeSolo
                    value={school}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        setEducationFormData({
                          ...educationFormData,
                          school: newValue.inputValue,
                        });
                        return;
                      }
                      setEducationFormData({
                        ...educationFormData,
                        school: (newValue && newValue.institution) || "",
                      });
                    }}
                    renderOption={(option) => option.institution}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="school"
                        required
                        fullWidth
                        autoComplete="school"
                        variant="outlined"
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <InputLabel required>Degree</InputLabel>
                  <Autocomplete
                    id="controlled-demo"
                    autoComplete
                    options={degrees}
                    getOptionLabel={(option) => {
                      // typeof option === "string" ? option : option.institution
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.name;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push({
                          inputValue: params.inputValue,
                          name: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    required
                    freeSolo
                    value={degree}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        setEducationFormData({
                          ...educationFormData,
                          degree: newValue.inputValue,
                        });
                        return;
                      }
                      setEducationFormData({
                        ...educationFormData,
                        degree: (newValue && newValue.name) || "",
                      });
                    }}
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="degree"
                        name={"degree"}
                        required
                        fullWidth
                        type="text"
                        autoComplete="degree"
                        variant="outlined"
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <InputLabel required>
                    Field of Study (Major / Minor)
                  </InputLabel>
                  <TextField
                    id="fieldOfStudy"
                    name={"fieldOfStudy"}
                    value={fieldOfStudy}
                    onChange={(e) =>
                      setEducationFormData({
                        ...educationFormData,
                        [e.target.name]: e.target.value,
                      })
                    }
                    required
                    fullWidth
                    type="text"
                    autoComplete="major"
                    variant="outlined"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel required>Start Date</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      variant="inline"
                      inputVariant="outlined"
                      openTo="year"
                      views={["year", "month"]}
                      value={startDate}
                      onChange={(date) =>
                        setEducationFormData({
                          ...educationFormData,
                          ["startDate"]: date,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <InputLabel required>Completion Date</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      variant="inline"
                      inputVariant="outlined"
                      openTo="year"
                      views={["year", "month"]}
                      value={completionDate}
                      onChange={(date) =>
                        setEducationFormData({
                          ...educationFormData,
                          ["completionDate"]: date,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider variant={"middle"} className={classes.divider} />
                <FormGroup>
                  <InputLabel>
                    Gender{" "}
                    <Tooltip
                      title={
                        <Box textAlign="left">
                          We believe that communities should be inclusive to all
                          individuals. This information is only ever visible to
                          community organizers in an anonymous way to help them
                          build more diverse and well-rounded programs, events,
                          opportunities and more.
                        </Box>
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputLabel>
                  <Autocomplete
                    id="gender-autocomplete"
                    options={genderTypes}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={selectedGender}
                    onChange={(event, newValue) => {
                      handleGenderSelectChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="gender"
                        fullWidth
                        autoComplete="gender"
                        variant="outlined"
                        placeholder="Select Gender"
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <br />
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>
                    Race/Ethnicity{" "}
                    <Tooltip
                      title={
                        <Box textAlign="left">
                          We believe that communities should be inclusive to all
                          individuals. This information is only ever visible to
                          community organizers in an anonymous way to help them
                          build more diverse and well-rounded programs, events,
                          opportunities and more.
                        </Box>
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </InputLabel>
                  <Autocomplete
                    multiple
                    id="race-autocomplete"
                    options={raceTypes}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    value={selectedRaces}
                    onChange={(event, value) => handleRaceSelectChange(value)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { onDelete, ...others } = getTagProps({ index });
                        return (
                          <Chip
                            key={index}
                            label={option && option.name}
                            onDelete={onDelete}
                            deleteIcon={<IoMdClose />}
                            {...others}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="race"
                        fullWidth
                        autoComplete="race"
                        variant="outlined"
                        placeholder="Select Race"
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider variant={"middle"} className={classes.divider} />
                <FormGroup>
                  <InputLabel>Linkedin Url</InputLabel>
                  <TextField
                    id={"Linkedin"}
                    autoComplete={"Linkedin"}
                    variant={"outlined"}
                    type={"url"}
                    name={"Linkedin"}
                    placeholder={"https://www.linkedin.com/in/username"}
                    pattern={
                      "http://www.linkedin.com/(.+)|https://www.linkedin.com/(.+)"
                    }
                    value={Linkedin}
                    onChange={(e) =>
                      onChange(e, publicProfileLinks, setPublicProfileLinks)
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Code Repository Url</InputLabel>
                  <TextField
                    id={"GitHub"}
                    autoComplete={"GitHub"}
                    type={"url"}
                    name={"GitHub"}
                    placeholder={"https://www.github.com/username"}
                    pattern={
                      "http://www.github.com/(.+)|https://www.github.com/(.+)"
                    }
                    value={GitHub}
                    onChange={(e) =>
                      onChange(e, publicProfileLinks, setPublicProfileLinks)
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Personal Website Url</InputLabel>
                  <TextField
                    id={"PersonalWebsite"}
                    autoComplete={"PersonalWebsite"}
                    variant={"outlined"}
                    type={"url"}
                    name={"PersonalWebsite"}
                    placeholder={"https://www.yourwebsite.com/"}
                    pattern={"http://.+/(.+)|https://.+/(.+)"}
                    value={PersonalWebsite}
                    onChange={(e) =>
                      onChange(e, publicProfileLinks, setPublicProfileLinks)
                    }
                  />
                </FormGroup>
              </Grid>
              {myprofile !== null && (
                <Grid item xs={12}>
                  <Divider variant={"middle"} className={classes.divider} />
                  <FormGroup>
                    <InputLabel>Resume</InputLabel>
                    <UploadResume />
                  </FormGroup>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="activelySeekingJob"
                        name="activelySeekingJob"
                        color="primary"
                        checked={activelySeekingJob}
                        onChange={(e) => onChange(e, formData, setFormData)}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Open to new opportunities</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            This will be made available to employers that have
                            communities on Pralent.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="privacySetting"
                        name="privacySetting"
                        color="primary"
                        checked={privacySetting}
                        onChange={(e) => onChange(e, formData, setFormData)}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>
                            Make my profile public to all Pralent users
                          </Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            When you enable this setting, your profile will be
                            available for all users to view.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>

                {/* <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={workAuthorization}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        value={workAuthorization}
                        color="primary"
                        name={"workAuthorization"}
                      />
                    }
                    label={"I am authorized to work in the United States."}
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={needVisa}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        value={needVisa}
                        color="primary"
                        name={"needVisa"}
                      />
                    }
                    label={
                      "I will require sponsorship for a US employment visa (e.g. H-1B)."
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={veteranStatus}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        value={veteranStatus}
                        color="primary"
                        name={"veteranStatus"}
                      />
                    }
                    label={
                      "I identify as one or more of the classifications of a protected veteran."
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={disabilityStatus}
                        onChange={(e) => onChange(e, formData, setFormData)}
                        value={disabilityStatus}
                        color="primary"
                        name={"disabilityStatus"}
                      />
                    }
                    label={
                      "I have a disability (or previously had a disability)."
                    }
                  />
                </FormGroup> */}
              </Grid>
            </Grid>
          </DialogContent>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
            style={{ gap: "8px" }}
          >
            <Button type="submit">Save</Button>
          </Box>
        </form>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
  user: state.auth.user,
  publicProfileType: state.typedef.publicProfileType,
  skillSet: state.typedef.skillSet,
  genderTypes: state.typedef.genderTypes,
  raceTypes: state.typedef.raceTypes,
});

export default connect(mapStateToProps, {
  addEducation,
  createProfile,
  updateUserAccount,
  addProfileLinks,
  addProfileSkills,
  setAlert,
})(ProfileForm);
