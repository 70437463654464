import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  inline: {
    display: "inline",
  },
  challengeTitle: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    marginBottom: 0,
  },
  thirdText: {
    lineHeight: 1,
  },
}));

const Dot = () => {
  return <Box mx={1}>•</Box>;
};

const ChallengeListItem = ({ challenge }) => {
  const classes = useStyles();

  if (!challenge) {
    return <div />;
  }

  const [currentRound, setCurrentRound] = useState(null);

  useEffect(() => {
    if (challenge && challenge.round && challenge.round.length > 0) {
      const rounds = challenge.round;
      const currentRoundId = challenge.currentRoundId;
      const found = rounds.find((elem) => elem.id === currentRoundId);
      if (found) {
        setCurrentRound(found);
      }
    }
  }, [challenge]);

  return (
    <>
      <ListItem
        button
        component={Link}
        to={`/programs/${challenge.slug}`}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={challenge.company && challenge.company.companyLogoPath}
            alt={`${challenge.company && challenge.company.companyName}-logo`}
          />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Box>
              <Typography
                className={classes.challengeTitle}
                color="textPrimary"
              >
                {challenge.title}
              </Typography>
            </Box>
          }
          secondary={
            <Box>
              <Typography
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {challenge.tagLine}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.thirdText}
                >
                  {challenge.totalPrizeValue
                    ? challenge.totalPrizeValue
                    : "Knowledge"}
                </Typography>
                <Dot />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.thirdText}
                >
                  {challenge.categoryTypes
                    ? challenge.categoryTypes
                    : "General Program"}
                </Typography>
                <Dot />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.thirdText}
                >
                  {challenge.location ? challenge.location : "Online"}
                </Typography>
                <Dot />
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.thirdText}
                >
                  {currentRound && currentRound.ended ? (
                    <span>{currentRound && currentRound.name} Ended</span>
                  ) : (
                    <span>
                      {currentRound && currentRound.endDatetime
                        ? new Date(
                            currentRound.endDatetime
                          ).toLocaleDateString()
                        : "None"}
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default ChallengeListItem;
