import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { addEditGroup } from "../../../redux/actions/group";
// Material UI
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InputBase from "@material-ui/core/InputBase";
import Box from "@material-ui/core/Box";
// Components
import Button from "../../Button";

const GroupNameTextField = withStyles((theme) => ({
  root: {},
  input: {
    borderRadius: theme.shape.borderRadius,
    position: "relative",
    border: `1px solid #fff`,
    fontSize: "1.25rem",
    padding: "8px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
}))(InputBase);

export const EditGroup = ({
  challenge,
  currentRoundIndex,
  group,
  addEditGroup,
}) => {
  const [name, setName] = useState("");

  useEffect(() => {
    if (group.data) {
      setName(group.data.name);
    }
  }, [group]);

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (group.data) {
      const currentRound = challenge.round[currentRoundIndex];
      addEditGroup(group.data.id, challenge.id, currentRound.id, name);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" alignItems="stretch" width="100%">
        <GroupNameTextField
          id="name"
          onChange={handleChange}
          value={name}
          name="name"
          fullWidth
          autoFocus
          placeholder="Edit this group name"
          inputProps={{
            "aria-label": "add or edit a group for this round",
          }}
        />

        <Tooltip title="Save group name" placement="top">
          <Button type="submit">Save</Button>
        </Tooltip>
      </Box>
    </form>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  group: state.group.group,
});

const mapDispatchToProps = { addEditGroup };

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
