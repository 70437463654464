import React from "react";
// redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// react-router-dom
import { useParams, useHistory } from "react-router-dom";
// react-query
import { useMutation } from "react-query";
import {
  useGetEventBySlugForOrganization,
  deleteEvent,
} from "../../hooks/event";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
// Components
import Spinner from "../../shared/Spinner";
import Button from "../../shared/Button";
import OrgEventAppBar from "../../shared/OrgEventAppBar";

export const OrgEventSettings = ({ companyProfile, setAlert }) => {
  const history = useHistory();
  const { slug } = useParams();
  const {
    isLoading,
    isError,
    data: { data: orgEvent } = { data: null },
    error,
  } = useGetEventBySlugForOrganization(slug);

  const deleteEventMutation = useMutation(deleteEvent, {
    onSuccess: async (_) => {
      await setAlert("Successfully deleted event.", "success");
      history.push(`/org/${companyProfile.orgId}/events`);
    },
  });

  const handleDeleteEvent = () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      deleteEventMutation.mutate({ eventId: orgEvent.id });
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Box>
          <OrgEventAppBar event={orgEvent} />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  <Box fontWeight={600}>Settings</Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="body1" component="div">
                  <Box fontWeight={600}>Danger Zone</Box>
                </Typography>
                <Box
                  my={2}
                  p={{ xs: 2 }}
                  borderColor="error.main"
                  border={1}
                  borderRadius="borderRadius"
                  width="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Box flexGrow={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                      <Box fontWeight={600}>Delete this event</Box>
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        Once you delete this event, there is no going back. All
                        information for this event including attendees and other
                        settings will be deleted permanently. Please be certain.
                      </Box>
                    </Typography>
                  </Box>
                  <Button
                    onClick={handleDeleteEvent}
                    variant="text"
                    color="default"
                  >
                    Delete
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(OrgEventSettings);
