import React from "react";
// React query
import { useMutation, useQueryClient } from "react-query";
import {
  removeUserFromEvent,
  updateAttendeeRegistrationStatus,
  useGetAllEventRegistrationsForOrganization,
} from "../../../hooks/event";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
// Icons
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
import Button from "../../../shared/Button";
import PTable from "../../../shared/PTable";
import Spinner from "../../../shared/Spinner";
import ProfilePopover from "../../../shared/ProfilePopover";
import OrgEventAttendeeStatus from "./OrgEventAttendeeStatus";
// Utils
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";
import { DEFAULT_TIME_ZONE } from "../../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const AttendeeInfo = ({ attendee }) => {
  const classes = useStyles();

  const attendeeUser = attendee.user;
  const attendeeProfile = attendee.profile;

  const attendeeEducation =
    attendeeProfile &&
    attendeeProfile.education &&
    attendeeProfile.education.length > 0 &&
    attendeeProfile.education[0];
  return (
    <Box minWidth={250} display="flex" alignItems="center">
      <CustomAvatar
        src={attendeeUser && attendeeUser.avatar}
        fallbackText={attendeeUser && attendeeUser.userName}
        alt={`${attendeeUser && attendeeUser.userName}-avatar`}
        className={classes.avatar}
      />
      <Box>
        <Typography variant="body1" component="div">
          <Box lineHeight={1.2} fontWeight={600}>
            {attendeeProfile
              ? `${attendeeProfile.firstName} ${attendeeProfile.lastName}`
              : attendeeUser && attendeeUser.userName}
          </Box>
        </Typography>
        {attendeeEducation && (
          <>
            <Typography variant="caption" component="div" color="textSecondary">
              <Box lineHeight={1.2}>{attendeeEducation.fieldOfStudy}</Box>
            </Typography>
            <Typography variant="caption" component="div" color="textSecondary">
              <Box lineHeight={1.2}>
                {attendeeEducation.school},{" "}
                {attendeeEducation.completionDate &&
                  new Date(attendeeEducation.completionDate).getFullYear()}
              </Box>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const OrgEventAttendeeTable = ({ orgEvent }) => {
  const eventId = orgEvent?.id;

  const queryClient = useQueryClient();

  const {
    isFetching: isAttendeesFetching,
    isLoading: isAttendeesLoading,
    isError: isAttendeesError,
    data: { data: orgEventAttendees } = { data: null },
    error: attendeesError,
  } = useGetAllEventRegistrationsForOrganization(eventId);

  const removeUserFromEventMutation = useMutation(removeUserFromEvent, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ["orgEventAttendees", { eventId: eventId }],
        (oldAttendees) => {
          return {
            ...oldAttendees,
            data:
              oldAttendees &&
              oldAttendees.data &&
              oldAttendees.data.filter(
                (elem) => elem.id !== variables.registrationId
              ),
          };
        }
      );
    },
  });

  const updateAttendeeRegistrationStatusMutation = useMutation(
    updateAttendeeRegistrationStatus,
    {
      onSuccess: (data) => {
        if (data && data.data) {
          const updatedAttendee = data.data;
          queryClient.setQueryData(
            ["orgEventAttendees", { eventId: eventId }],
            (oldAttendees) => {
              if (oldAttendees.data) {
                const elemFoundIndex = oldAttendees.data.findIndex(
                  (elem) => elem.id === updatedAttendee.id
                );
                if (elemFoundIndex < 0) {
                  return oldAttendees;
                }
                let newAttendeesData = [...oldAttendees.data];
                newAttendeesData[elemFoundIndex] = {
                  ...newAttendeesData[elemFoundIndex],
                  registrationStatus: updatedAttendee.registrationStatus,
                };
                const newAttendees = {
                  ...oldAttendees,
                  data: newAttendeesData,
                };
                return newAttendees;
              }
            }
          );
        }
      },
    }
  );

  const handleRemoveEventRegistration = (registrationId) => {
    if (window.confirm("Are you sure you want to remove this registration?")) {
      removeUserFromEventMutation.mutate({
        registrationId: registrationId,
        eventId: eventId,
      });
    }
  };

  const headerCells = [
    {
      id: "id",
      label: "id",
      hide: true,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "attendee",
      label: "Attendee",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const name1 =
          param1 && param1.profile && param1.profile.firstName
            ? param1.profile.firstName
            : param1 && param1.user && param1.user.userName
            ? param1.user.userName
            : "";
        const name2 =
          param2 && param2.profile && param2.profile.firstName
            ? param2.profile.firstName
            : param2 && param2.user && param2.user.userName
            ? param2.user.userName
            : "";

        return name1.localeCompare(name2);
      },
    },
    {
      id: "email",
      label: "Email address",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const email1 =
          param1 && param1.user && param1.user.email ? param1.user.email : "";
        const email2 =
          param2 && param2.user && param2.user.email ? param2.user.email : "";

        return email1.localeCompare(email2);
      },
    },
    {
      id: "registrationDateTime",
      label: "Registration Date & Time",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        return (param1?.registrationDateTime || "").localeCompare(
          param2?.registrationDateTime || ""
        );
      },
    },
    {
      id: "registrationStatus",
      label: "Registration Status",
      hide: !(
        orgEvent.requireRegistration && orgEvent.requireRegistrationApproval
      ),
      disableSorting: true,
      filterable: true,
      sortComparator: (param1, param2) => {
        return param1?.registrationStatus > param2?.registrationStatus;
      },
    },
    {
      id: "actions",
      label: "Actions",
      hide: false,
      disableSorting: true,
      filterable: true,
    },
  ];

  const columns = [
    {
      field: "id",
      renderCell: (data) => {
        return data.id;
      },
    },
    {
      field: "attendee",
      renderCell: (attendee) => {
        const attendeeUser = attendee.user;

        return (
          <ProfilePopover userId={attendeeUser && attendeeUser.id}>
            <AttendeeInfo attendee={attendee} />
          </ProfilePopover>
        );
      },
    },
    {
      field: "email",
      renderCell: (data) => {
        const email = data && data.user && data.user.email;
        return (
          <Link
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            color="textPrimary"
            href={`mailto:${email}`}
          >
            {email}
          </Link>
        );
      },
    },
    {
      field: "registrationDateTime",
      renderCell: (data) => {
        const registrationDateTime = new Date(data.registrationDateTime);
        return convertUTCTimeToZonedTime(
          registrationDateTime,
          DEFAULT_TIME_ZONE,
          "MMM d, yyyy h:mmaaa zzz"
        );
      },
    },
    {
      field: "registrationStatus",
      renderCell: (data) => {
        return (
          <OrgEventAttendeeStatus
            eventId={eventId}
            data={data}
            updateAttendeeRegistrationStatusMutation={
              updateAttendeeRegistrationStatusMutation
            }
          />
        );
      },
    },
    {
      field: "actions",
      renderCell: (data) => {
        return (
          <Button
            startIcon={<DeleteOutlinedIcon />}
            variant="text"
            color="default"
            onClick={() => handleRemoveEventRegistration(data.id)}
          >
            Remove
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      {isAttendeesLoading ? (
        <Spinner />
      ) : isAttendeesError ? (
        <Alert severity="error">
          {attendeesError && attendeesError.data && attendeesError.data.msg}
        </Alert>
      ) : (
        <PTable
          records={orgEventAttendees}
          headerCells={headerCells}
          columns={columns}
          noRowsText="There are no registrations for your event at this time."
          isLoading={isAttendeesFetching || isAttendeesLoading}
        />
      )}
    </Box>
  );
};

export default React.memo(OrgEventAttendeeTable);
