import React, { useContext, useState, useMemo, useEffect } from "react";
// Redux
import { connect } from "react-redux";
// React router
import { useParams } from "react-router-dom";
// React query
import {
  useGetRoomBySlug,
  useGetUserInRoomById,
  useGetAllMembersInRoom,
} from "../../../hooks/room";

const RoomContext = React.createContext();
const UserInRoomContext = React.createContext();
const RoomMembersContext = React.createContext();
const PostReplyContext = React.createContext();

export const useRoomContext = () => {
  return useContext(RoomContext);
};

export const useUserInRoomContext = () => {
  return useContext(UserInRoomContext);
};

export const useRoomMembersContext = () => {
  return useContext(RoomMembersContext);
};

export const usePostReplyContext = () => {
  return useContext(PostReplyContext);
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

const RoomProvider = ({ children, user }) => {
  const { roomSlug, communitySlug } = useParams();

  const [room, setRoom] = useState(null);

  const {
    isLoading: isLoadingRoom,
    data: { data: roomBySlug } = { data: null },
    isError: isErrorRoom,
    error: errorRoom,
  } = useGetRoomBySlug(roomSlug, communitySlug);

  const {
    isLoading: isLoadingUserInRoom,
    data: { data: userInRoom } = { data: null },
  } = useGetUserInRoomById(room && room.id);

  const {
    isLoading: isLoadingRoomMembers,
    data: roomMembers,
    isError: isErrorRoomMembers,
    error: errorRoomMembers,
  } = useGetAllMembersInRoom(room && room.id, true);

  useEffect(() => {
    if (!roomBySlug) return;
    let chatRoomNames = [];
    if (!roomBySlug.belongsToCommunity) {
      chatRoomNames = Object.keys(roomMembers || []).map((key) => {
        const member = roomMembers[key];
        const memberUser = member.user;
        const memberProfile = member.profile;

        if (memberUser.id !== user.id) {
          return memberProfile.firstName;
        } else {
          return "";
        }
      });
    }
    const roomName = roomBySlug.name
      ? roomBySlug.name
      : (chatRoomNames || []).filter((name) => name !== "").join(", ");

    setRoom({
      ...roomBySlug,
      name: roomName,
    });
  }, [roomBySlug, roomMembers]);

  const [replyPostInfo, setOpenDrawerPostId] = useState(false);

  const handleDrawerOpen = (replyPostInfo) => {
    setOpenDrawerPostId(replyPostInfo);
  };

  const handleDrawerClose = () => {
    setOpenDrawerPostId(null);
  };

  const RoomContextValue = useMemo(
    () => ({
      isLoadingRoom,
      room,
      isErrorRoom,
      errorRoom,
    }),
    [isLoadingRoom, room, isErrorRoom, errorRoom]
  );

  const UserInRoomContextValue = useMemo(
    () => ({ isLoadingUserInRoom, userInRoom }),
    [isLoadingUserInRoom, userInRoom]
  );

  const PostReplyContextValue = useMemo(
    () => ({ replyPostInfo, handleDrawerOpen, handleDrawerClose }),
    [replyPostInfo, handleDrawerOpen, handleDrawerClose]
  );

  const RoomMembersContextValue = useMemo(
    () => ({
      isLoadingRoomMembers,
      roomMembers,
      isErrorRoomMembers,
      errorRoomMembers,
    }),
    [isLoadingRoomMembers, roomMembers, isErrorRoomMembers, errorRoomMembers]
  );

  return (
    <RoomContext.Provider value={RoomContextValue}>
      <UserInRoomContext.Provider value={UserInRoomContextValue}>
        <PostReplyContext.Provider value={PostReplyContextValue}>
          <RoomMembersContext.Provider value={RoomMembersContextValue}>
            {children}
          </RoomMembersContext.Provider>
        </PostReplyContext.Provider>
      </UserInRoomContext.Provider>
    </RoomContext.Provider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomProvider);
