import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import ExperienceForm from "../../../shared/ExperienceForm";
// Images
import job_interview from "../../../assets/images/job_interview_.svg";

const Experience = ({ experience }) => {
  const experiences =
    experience && experience.length > 0 ? (
      experience.map((exp, index) => (
        <div key={index}>
          <Box mt={2} width="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" component="div">
                <Box fontWeight={600} mr={1}>
                  {exp.jobTitle} @ {exp.companyName}
                </Box>
              </Typography>{" "}
              <ExperienceForm experience={exp} />
            </Box>
            <Typography variant="body2" color="textSecondary">
              <Moment format="MM/YYYY">{exp.startDate}</Moment> -{" "}
              {exp.endDate === null ? (
                " Present"
              ) : (
                <Moment format="MM/YYYY">{exp.endDate}</Moment>
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {exp.location}
            </Typography>

            <Typography
              style={{ whiteSpace: "pre-line" }}
              variant="caption"
              component="div"
            >
              <Box mt={1}>{exp.description}</Box>
            </Typography>
          </Box>
          {index < experience.length - 1 && <hr className="my-6" />}
        </div>
      ))
    ) : (
      <Box
        display="block"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          height="100%"
          width="350px"
          src={job_interview}
          alt="no experiences added image"
        />
        <Typography variant="body1" component="div" color="textSecondary">
          There are no experiences added yet. Add more experiences to boost your
          profile!
        </Typography>
      </Box>
    );

  return (
    <Box>
      <ExperienceForm />
      <Box>{experiences}</Box>
    </Box>
  );
};

Experience.propTypes = {
  experience: PropTypes.array,
};

export default Experience;
