import axios from "../utils/axios";

export const addEditArticle = async ({ companyId, articleDetails }) => {
  const body = {
    companyId,
    articleDetails,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/knowledge`, body, config);
};

export const getAllArticlesForOrganization = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/knowledge/all/${companyId}`);
};

export const getKnowledgeArticleById = async ({ queryKey }) => {
  const [_key, { articleId }] = queryKey;
  return await axios.get(`/api/knowledge/id/${articleId}`);
};

export const deleteArticle = async ({ articleId }) => {
  return await axios.del(`/api/knowledge/id/${articleId}`);
};

export const getAllArticlesForCommunity = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/knowledge/community/all/id/${companyId}`);
};
