// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import InterestsForm from "../../../shared/InterestsForm";

const Interests = ({ formData, setFormData }) => {
  const { interestsAnswers } = formData;

  const handleInterestsChange = (newInterestsAnswers) => {
    setFormData({
      ...formData,
      interestsAnswers: newInterestsAnswers,
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box mb={2} textAlign="center">
          <Typography variant="h5" component="h1" gutterBottom>
            <Box fontWeight={600}>🎨 Share your interests</Box>
          </Typography>
          <Typography variant="body1" component="div" color="textSecondary">
            <Box>
              Answer a few interest questions and allow others to learn more
              about you.
            </Box>
          </Typography>
        </Box>
        <Grid item xs={12}>
          <Box my={2}>
            <InterestsForm
              state={interestsAnswers}
              setState={handleInterestsChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Interests;
