import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { addProject, deleteProjectFile } from "../../redux/actions/project";
import { setAlert } from "../../redux/actions/alert";
import isUrl from "../../utils/isUrl";
import axios from "../../utils/axios";
// Material UI
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/Add";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import { MdDelete } from "react-icons/md";
import { IoMdClose, IoIosDocument, IoMdSearch } from "react-icons/io";
// Components
import Button from "../../shared/Button";
import RenderGroup from "../../shared/RenderGroup";
import Listbox from "../../shared/Listbox";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 0,
    width: "100%",
  },
  formGroup: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  appBar: {
    // position: "relative",
    // boxShadow: "none",
    // color: theme.palette.dark.main,
  },
  button: {
    fontWeight: 600,
  },
  mappedButtons: {
    "&:focus": {
      outline: "none",
    },
    fontWeight: 600,
    margin: theme.spacing(0.5),
    textTransform: "none",
  },
  dialogContent: {
    // marginTop: "1rem",
    // marginBottom: "1rem",
  },
  imageButton: {
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageInput: {
    display: "none",
  },
  linkButton: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  addLinkInput: {
    marginTop: 0,
    width: "100%",
  },
  linkSelectInput: {
    marginTop: 0,
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      fontSize: "15px",
    },
  },
}));

const NewProjectForm = ({
  user,
  editProject = null,
  projectCategories,
  skillSet,
  projectStatus,
  externalLinks,
  addProject,
  deleteProjectFile,
  setAlert,
  isDialogOpen,
  toggleDialog,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // Use State to set Local State for Component
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    projectStatusId: 1,
    isOwner: true,
    isPublic: true,
    startDate: new Date(),
    endDate: new Date(),
    categories: [],
    extLinks: [],
    skills: [],
    projectTeam: [
      {
        avatar: user.avatar,
        userName: user.userName,
        email: user.email,
        userAccountId: user.id,
        description: "",
        status: true,
      },
    ],
    userAccountId: user.id,
  });

  const [projectImageUrl, setProjectImageUrl] = useState("");
  const [projectImage, setProjectImage] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [selectedSkillSet, setSelectedSkillSet] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [initialSkills, setInitialSkills] = useState([]);

  const [projectFiles, setProjectFiles] = useState([]);

  const uploadProjectFiles = (e) => {
    if (e.target.files.length > 0) {
      if (projectFiles.length + e.target.files.length > 5) {
        setAlert("Maximum 5 files allowed on a single project.", "error");
        return;
      }
      const files = e.target.files;
      let filesToAdd = [...projectFiles];
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < 10485760) {
          let fileObj = {
            file: files[i],
            fileName: files[i].name,
            filePath: URL.createObjectURL(files[i]),
          };
          filesToAdd.push(fileObj);
        } else {
          setAlert("Maximum file size 10MB exceeded", "error");
        }
      }
      setProjectFiles(filesToAdd);
    }
    e.target.value = null;
  };

  const deleteFile = (index) => {
    if (window.confirm(`Are you sure you want to delete this file?`)) {
      const deletedFile = projectFiles[index];
      if (deletedFile.id && editProject) {
        deleteProjectFile(deletedFile.id, editProject.id);
        //deleteProjectFile(deletedFile.id, editProject.id)
        // @TODO Delete the file from the database only if it actually exists in the database
      }
      const newFiles = [
        ...projectFiles.slice(0, index),
        ...projectFiles.slice(index + 1, projectFiles.length),
      ];
      setProjectFiles(newFiles);
    }
  };

  const addLinkInput = () => {
    let newExtLinks = [...formData.extLinks];
    newExtLinks.push({ extLinkTypeId: 1, externalUrl: "" });
    setFormData({ ...formData, extLinks: newExtLinks });
  };

  const chooseLinkType = (e, i) => {
    let newExtLinks = [...formData.extLinks];
    newExtLinks[i] = { ...newExtLinks[i], extLinkTypeId: e.target.value };
    setFormData({ ...formData, extLinks: newExtLinks });
  };

  const handleDeleteLink = (index) => {
    if (
      index < formData.extLinks.length &&
      index >= 0 &&
      window.confirm("Do you want to delete this link?")
    ) {
      let newExtLinks = [...formData.extLinks];
      newExtLinks.splice(index, 1);
      setFormData({ ...formData, extLinks: newExtLinks });
    }
  };

  const onImageChange = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 10485760) {
        setProjectImageUrl(URL.createObjectURL(e.target.files[0]));
        setProjectImage(e.target.files[0]);
        setImageFileName(e.target.files[0].name);
      } else {
        setAlert("Maximum file size 10MB exceeded", "error");
      }
    }
  };

  const handleSkillSelectChange = (selectedSkills) => {
    const max = 10;
    setInitialSkills(selectedSkills);

    if (selectedSkills !== undefined) {
      let newSkills = selectedSkills;
      if (newSkills.length > max) newSkills.shift();

      // Loop through the newSkills list
      // Check if the values in the list matches with the value in
      // the state object list
      let tempSkillsArray = [];
      newSkills.forEach((element) => {
        const found =
          selectedSkillSet &&
          selectedSkillSet.find((elem) => elem.name === element);
        if (found) {
          tempSkillsArray.push(found);
        } else {
          // Create a new skillset object
          const skillObj = {
            name: element,
          };
          tempSkillsArray.push(skillObj);
        }

        // Assign the new array to the state variable
      });
      setSelectedSkillSet(tempSkillsArray);
      setFormData({ ...formData, skills: tempSkillsArray });
    }
  };
  const handleCategorySelectChange = (selectedCategories) => {
    const max = 3;
    if (selectedCategories !== undefined) {
      let newCategories = selectedCategories;
      if (newCategories.length > max) newCategories.shift();
      setSelectedCategories(newCategories);
      let newCategoryIds = [];
      newCategories.forEach((element) => {
        newCategoryIds = [...newCategoryIds, element.id];
      });
      setFormData({ ...formData, categories: newCategoryIds });
    }
  };

  const onChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };
  const cleanData = () => {
    //console.log("cleaning");
    setFormData({
      ...formData,
      title: "",
      description: "",
      projectStatusId: 1,
      isOwner: true,
      isPublic: true,
      startDate: new Date(),
      endDate: new Date(),
      categories: [],
      extLinks: [],
      skills: [],
      userAccountId: user.id,
      projectTeam: [
        {
          avatar: user.avatar,
          userName: user.userName,
          email: user.email,
          userAccountId: user.id,
          description: "",
          status: true,
        },
      ],
    });
    setProjectImageUrl("");
    setProjectImage("");
    setImageFileName("");
    setInitialSkills([]);
    setSelectedSkillSet([]);
    setSelectedCategories([]);
    setProjectFiles([]);
  };

  const canSubmitProject = () => {
    if ((formData && formData.title === "") || formData.description === "") {
      setAlert(
        "Please fill in all the required fields for your project.",
        "error"
      );
      return false;
    }
    if (formData && formData.extLinks) {
      for (var i = 0; i < formData.extLinks.length; i++) {
        var item = formData.extLinks[i];
        if (item.externalUrl === "") {
          setAlert("Please enter a value for your external link.", "error");
          return false;
        }
        if (!isUrl(item.externalUrl)) {
          setAlert(
            "Please add a valid link starting with http:// or https://",
            "error"
          );
          return false;
        }
      }
    }
    if (formData && formData.categories && formData.categories.length <= 0) {
      setAlert("Please add at least one category to your project.", "error");
      return false;
    }
    if (formData && formData.skills && formData.skills.length <= 0) {
      setAlert("Please add at least one skill to your project.", "error");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let imageFormData = new FormData();
    if (projectImage !== "") {
      imageFormData.append("myFile", projectImage);
    } else {
      imageFormData = null;
    }
    let filesFormData = new FormData();
    for (let i = 0; i < projectFiles.length; i++) {
      if (projectFiles[i] && projectFiles[i].file !== undefined) {
        filesFormData.append("files", projectFiles[i].file);
      }
    }

    if (filesFormData.length <= 0) {
      filesFormData = null;
    }

    if (canSubmitProject()) {
      // Before you Submit check to see which project links are filled in
      await addProject(
        formData,
        imageFormData,
        filesFormData,
        editProject !== null
      );
      await setAlert(
        `Successfully ${editProject ? "updated" : "created"} project.`
      );
      await toggleDialog(false);
      await cleanData();
    } else {
      return false;
    }
  };

  const {
    title,
    description,
    projectStatusId,
    isPublic,
    startDate,
    endDate,
    categories,
    extLinks,
    skills,
    userAccountId,
    projectTeam,
  } = formData;

  useEffect(() => {
    if (editProject) {
      const projectImages = editProject.projectImages;
      setProjectImageUrl(
        (projectImages &&
          projectImages.length > 0 &&
          projectImages[0].filePath) ||
          ""
      );
      setProjectImage("");
      setImageFileName("");
      setProjectFiles(
        editProject.projectFiles && editProject.projectFiles.length > 0
          ? editProject.projectFiles
          : []
      );

      let newTeam = [];
      if (editProject.projectTeam && editProject.projectTeam.length) {
        editProject.projectTeam.forEach((member) => {
          if (member.userAccount) {
            newTeam.push({
              avatar: member.userAccount.avatar,
              userName: member.userAccount.userName,
              email: member.userAccount.email,
              userAccountId: member.userAccountId,
              description: member.description,
              status: member.status,
            });
          }
        });
      }

      setFormData({
        ...formData,
        id: editProject.id,
        title: editProject.title || "",
        description: editProject.description || "",
        projectStatusId: editProject.projectStatusId || 1,
        isOwner: editProject.isOwner === undefined ? true : editProject.isOwner,
        isPublic:
          editProject.isPublic === undefined ? true : editProject.isPublic,
        startDate: editProject.startDate || new Date(),
        endDate: editProject.endDate || new Date(),
        categories: editProject.categories || [],
        skills: editProject.skills || [],
        extLinks: editProject.extLinks || [],
        userAccountId: editProject.userAccountId || user.id,
        projectTeam: newTeam,
      });

      let newSkillSet = [];
      let initialSkills = [];
      editProject.skills &&
        editProject.skills.map((skill) => {
          initialSkills.push(skill.name);
          let skillSetItem = {
            name: skill.name,
          };
          newSkillSet.push(skillSetItem);
        });
      setInitialSkills(initialSkills);
      setSelectedSkillSet(newSkillSet);

      let newCategories = [];
      editProject.categories &&
        editProject.categories.map((cat) => {
          const found = projectCategories.findIndex((elem) => elem.id === cat);
          if (found !== -1) {
            newCategories.push({
              id: projectCategories[found].id,
              name: projectCategories[found].name,
            });
          }
        });
      setSelectedCategories(newCategories);
    } else {
      cleanData();
    }
  }, [isDialogOpen === true]);

  const [teamMemberSearch, setTeamMemberSearch] = useState("");
  const [teamMemberError, setTeamMemberError] = useState("");
  const findTeamMembers = async () => {
    setTeamMemberError("");
    const searchForUser = async (search) => {
      try {
        if (search && search !== "") {
          const res = await axios.get(`/api/users/find/${search}`);
          //console.log(res);
          return res.data;
        }
      } catch (error) {
        if (error && error.response) {
          setTeamMemberError(error.response.data.message);
        }
        //console.log(error.response);
      }
    };
    const userTeam = await searchForUser(teamMemberSearch);
    setTeamMemberSearch("");

    if (userTeam) {
      let oldTeam = [...projectTeam];
      oldTeam = oldTeam.filter(
        (oldTeamUser) => oldTeamUser.userAccountId !== userTeam.id
      );

      oldTeam.push({
        avatar: userTeam.avatar,
        userName: userTeam.userName,
        email: userTeam.email,
        userAccountId: userTeam.id,
        description: "",
        status: true,
      });

      setFormData({ ...formData, projectTeam: oldTeam });
    }
  };

  const handleRemoveMember = (removeUser) => {
    if (window.confirm("Are you sure you want to remove this team member?")) {
      let oldTeam = [...formData.projectTeam];
      oldTeam =
        oldTeam &&
        oldTeam.filter(
          (user) => user.userAccountId !== removeUser.userAccountId
        );
      setFormData({
        ...formData,
        projectTeam: oldTeam,
      });
    }
  };

  const [open, setOpen] = React.useState(false);
  const [skillsOptions, setSkillsOptions] = React.useState([]);
  const skillsLoading = open && skillsOptions.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!skillsLoading) {
      return undefined;
    }

    async function fetchData() {
      fetch(
        "https://raw.githubusercontent.com/Pralent/skills/master/skills.txt"
      )
        // .then((res) => res.json())
        .then((res) => {
          const text = res.text();

          return text;
        })
        .then(async (res) => {
          const data = await res.split("\n");
          if (active) {
            setSkillsOptions(data);
          }
        })
        .catch((res) => console.log(res));
    }
    fetchData();

    return () => {
      active = false;
    };
  }, [skillsLoading]);
  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={isDialogOpen ? isDialogOpen : false}
        onClose={() => toggleDialog(false)}
      >
        <form onSubmit={onSubmit}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => toggleDialog(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography component="div" variant="h6">
                <Box fontWeight={600}>
                  {editProject !== null ? "Edit" : "Create"} Your Project
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>
                    Thumbnail Image (16x9 aspect ratio recommended)
                  </InputLabel>

                  <Box
                    mb={1}
                    style={{
                      position: "relative",
                      paddingBottom: "56.2%",
                    }}
                  >
                    <Box
                      style={{ objectFit: "cover" }}
                      position="absolute"
                      width="100%"
                      height="100%"
                      borderRadius="borderRadius"
                      component="img"
                      src={
                        projectImageUrl ||
                        "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                      }
                    />
                  </Box>

                  <input
                    accept="image/*"
                    className={classes.imageInput}
                    id="project-image-upload-button"
                    type="file"
                    onChange={onImageChange}
                  />
                  <label
                    htmlFor="project-image-upload-button"
                    style={{ marginBottom: "0" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload New Image
                    </Button>
                  </label>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Title</InputLabel>
                  <TextField
                    id={"title"}
                    margin={"dense"}
                    autoComplete={"title"}
                    variant={"outlined"}
                    type="text"
                    name="title"
                    placeholder="Enter project title"
                    required
                    value={title}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Description</InputLabel>

                  <TextField
                    id={"description"}
                    fullWidth
                    margin={"dense"}
                    autoComplete={"description"}
                    variant={"outlined"}
                    type="text"
                    placeholder="You may format your description using Markdown."
                    name="description"
                    required
                    multiline={true}
                    rows={15}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                    InputProps={{ style: { lineHeight: 1.3 } }}
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Files </InputLabel>

                  {projectFiles &&
                    projectFiles.map((f, index) => {
                      return (
                        <Box
                          mb={1}
                          key={index}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box display="flex" alignItems="center">
                            <IconButton
                              color="primary"
                              href={f.filePath}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IoIosDocument />
                            </IconButton>
                            <Typography variant="body2" component="div">
                              {f.fileName}
                            </Typography>
                          </Box>
                          <IconButton onClick={() => deleteFile(index)}>
                            <MdDelete />
                          </IconButton>
                        </Box>
                      );
                    })}
                  <input
                    multiple
                    accept=".pdf,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.csv"
                    className={classes.imageInput}
                    id="project-file-upload-button"
                    type="file"
                    onChange={uploadProjectFiles}
                  />

                  <label
                    htmlFor="project-file-upload-button"
                    style={{ marginBottom: "0" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload New Files
                    </Button>
                  </label>
                  <FormHelperText>
                    Accept pdf, .doc, .docx, .pptx, .ppt, .xlsx, .xls, .csv
                    (Maximum 5 Files)
                  </FormHelperText>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Links</InputLabel>
                  <Box mb={1}>
                    <Button startIcon={<AddIcon />} onClick={addLinkInput}>
                      Add Link
                    </Button>
                  </Box>

                  <Grid container spacing={1}>
                    {extLinks.map((link, index) => {
                      const extLinkTypeId = link.extLinkTypeId;
                      const url = link.externalUrl;

                      return (
                        <Fragment key={index}>
                          <Grid item xs={4}>
                            <FormControl
                              variant="outlined"
                              margin="dense"
                              className={classes.linkSelectInput}
                              fullWidth={fullScreen}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={extLinkTypeId}
                                onChange={(e) => chooseLinkType(e, index)}
                                labelWidth={0}
                                className={classes.input}
                              >
                                {externalLinks.map((option, idx) => {
                                  return (
                                    <MenuItem value={option.id} key={idx}>
                                      {option.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={8}>
                            <OutlinedInput
                              pattern="https://.*"
                              margin={"dense"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => handleDeleteLink(index)}
                                  >
                                    <CloseIcon fontWeight={600} color="error" />
                                  </IconButton>
                                </InputAdornment>
                              }
                              type="url"
                              value={url}
                              fullWidth={fullScreen}
                              onChange={(e) => {
                                const value = e.target.value;
                                const newExtLinks = [];
                                extLinks &&
                                  extLinks.map((link, link_index) => {
                                    if (index !== link_index) {
                                      newExtLinks.push({
                                        ...link,
                                      });
                                    } else {
                                      newExtLinks.push({
                                        ...link,
                                        externalUrl: value,
                                      });
                                    }
                                  });
                                // newExtLinks[index].externalUrl = value;
                                setFormData({
                                  ...formData,
                                  extLinks: newExtLinks,
                                });
                              }}
                              className={classes.addLinkInput}
                            />
                          </Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Status</InputLabel>
                  <ButtonGroup>
                    {projectStatus.map((status) => {
                      return (
                        <Button
                          key={status.id}
                          variant={
                            projectStatusId === status.id
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setFormData({
                              ...formData,
                              projectStatusId: status.id,
                              endDate: status.id !== 3 ? null : endDate,
                            });
                          }}
                        >
                          {status.name}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Start Date</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      variant="inline"
                      inputVariant="outlined"
                      openTo="year"
                      views={["year", "month"]}
                      value={startDate}
                      onChange={(date) =>
                        setFormData({ ...formData, startDate: date })
                      }
                      margin={"dense"}
                      className={classes.input}
                      style={{ cursor: "pointer" }}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>End Date</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required={projectStatusId === 3}
                      disabled={projectStatusId !== 3}
                      variant="inline"
                      inputVariant="outlined"
                      openTo="year"
                      views={["year", "month"]}
                      value={endDate}
                      onChange={(date) =>
                        setFormData({ ...formData, endDate: date })
                      }
                      margin={"dense"}
                      className={classes.input}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Category</InputLabel>
                  <Autocomplete
                    multiple
                    id="categories"
                    options={projectCategories}
                    getOptionLabel={(option) => option.name}
                    value={selectedCategories}
                    onChange={(event, value) =>
                      handleCategorySelectChange(value)
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { onDelete, ...others } = getTagProps({ index });
                        return (
                          <Chip
                            key={index}
                            label={option && option.name}
                            onDelete={onDelete}
                            deleteIcon={<IoMdClose />}
                            {...others}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className={classes.input}
                        required
                        placeholder="Select categories"
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Skills</InputLabel>

                  <Autocomplete
                    multiple
                    id="skillSetOptions"
                    options={skillsOptions}
                    freeSolo
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    ListboxComponent={Listbox}
                    renderGroup={RenderGroup}
                    value={initialSkills}
                    onChange={(e, newValue) => {
                      handleSkillSelectChange(newValue);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { onDelete, ...others } = getTagProps({ index });
                        return (
                          <Chip
                            key={index}
                            label={option}
                            onDelete={onDelete}
                            deleteIcon={<IoMdClose />}
                            {...others}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        className={classes.input}
                        placeholder="Type your skills and click enter"
                        maxLength={500}
                        required
                      />
                    )}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                {userAccountId === user.id && (
                  <Fragment>
                    {teamMemberError && (
                      <Box mb={1}>
                        <Alert severity="error">{teamMemberError}</Alert>
                      </Box>
                    )}
                    <InputLabel>Share project with team members</InputLabel>
                    <Box display="flex" alignItems="stretch">
                      <TextField
                        id={"findTeamMembers"}
                        fullWidth
                        variant={"outlined"}
                        type="text"
                        placeholder="Search by email"
                        name="findTeamMember"
                        value={teamMemberSearch}
                        onChange={(e) => setTeamMemberSearch(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => findTeamMembers()}
                      >
                        <IoMdSearch />
                      </Button>
                    </Box>
                  </Fragment>
                )}

                {projectTeam &&
                  projectTeam.map((userTeam, index) => {
                    return (
                      <Box
                        my={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        key={index}
                      >
                        <Box display="flex" alignItems="center">
                          <Avatar
                            alt={userTeam && userTeam.userName}
                            src={userTeam.avatar}
                          >
                            {userTeam &&
                              userTeam.userName &&
                              userTeam.userName.substring(0, 1).toUpperCase()}
                          </Avatar>

                          <Box ml={2}>
                            <Typography variant="body2" component="div">
                              <Box lineHeight={1.2} fontWeight={600}>
                                {userTeam && userTeam.userName}
                              </Box>
                            </Typography>

                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              <Box>{userTeam && userTeam.email}</Box>
                            </Typography>
                          </Box>
                        </Box>
                        {userAccountId === user.id &&
                          userAccountId !== userTeam.userAccountId && (
                            <IconButton
                              onClick={() => handleRemoveMember(userTeam)}
                            >
                              <MdDelete />
                            </IconButton>
                          )}
                      </Box>
                    );
                  })}
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Privacy</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isPublic}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.checked,
                          });
                        }}
                        value={isPublic}
                        color="primary"
                        name={"isPublic"}
                      />
                    }
                    label={
                      <Fragment>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Public</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            I want to make this project public to everyone on
                            Pralent. Private projects are still available for
                            organizations to view.
                          </Box>
                        </Typography>
                      </Fragment>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button onClick={(e) => onSubmit(e)} color="primary">
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  skillSet: state.typedef.skillSet,
  projectCategories: state.typedef.projectCategories,
  projectStatus: state.typedef.projectStatus,
  externalLinks: state.typedef.extLinks,
  myProjects: state.project.myProjects,
});

export default connect(mapStateToProps, {
  addProject,
  setAlert,
  deleteProjectFile,
})(NewProjectForm);
