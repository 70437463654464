import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { uploadAvatar } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alert";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import CloudUpload from "@material-ui/icons/CloudUpload";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// Components
import CustomAvatar from "../CustomAvatar";

const useStyles = makeStyles((theme) => ({
  avatar: {
    transition: "0.2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.primary.dark,
    },
    width: theme.spacing(15),
    height: theme.spacing(15),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    fontSize: "50px",
    marginBottom: theme.spacing(1),
    marginTop: -theme.spacing(8),
    borderColor: theme.palette.common.white,
    borderWidth: "5px",
    boxShadow: theme.shadows[3],
  },
  input: {
    display: "none",
  },
  button: {
    fontWeight: "600",
  },
  badgeAvatar: {
    marginBottom: theme.spacing(2),
    marginTop: -theme.spacing(10),
    backgroundColor: theme.palette.secondary.main,
    color: "black",
    width: theme.spacing(3),
    height: theme.spacing(3),
    "&:hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    position: "relative",
  },
  modalAvatar: {
    width: "200px",
    height: "200px",
  },
  dialogContent: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

const UserAvatar = ({ user, uploadAvatar, setAlert }) => {
  const classes = useStyles();
  const [file, setFile] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [modal, openModal] = useState(false);

  const closeModal = () => {
    setAvatarUrl("");
    openModal(false);
  };

  const onChange = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 5242880) {
        setAvatarUrl(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        openModal(true);
      } else {
        setAlert("Maximum file size 5MB exceeded", "error");
      }
    }
  };

  const onSubmit = () => {
    const formData = new FormData();
    formData.append("myFile", file);
    uploadAvatar(formData);
    closeModal();
  };
  return (
    <Fragment>
      <input
        accept=".png, .jpg, .jpeg, .gif, .svg"
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={onChange}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <label htmlFor="contained-button-file">
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={
            <Avatar className={classes.badgeAvatar}>
              <EditIcon style={{ fontSize: "15px" }} />
            </Avatar>
          }
        >
          <CustomAvatar
            className={classes.avatar}
            alt={user && user.userName && user.userName.charAt(0).toUpperCase()}
            fallbackText={
              user && user.userName && user.userName.charAt(0).toUpperCase()
            }
            src={user && user.avatar && user.avatar}
          />
        </Badge>
      </label>
      <br />

      {avatarUrl !== "" && (
        <Dialog
          onClose={closeModal}
          aria-labelledby={"user-avatar-dialog"}
          open={modal}
          maxWidth={"xs"}
          fullWidth={true}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeModal}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Set your new profile picture
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.dialogContent}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Avatar
                className={classes.modalAvatar}
                alt={user && user.userName.charAt(0).toUpperCase()}
                src={avatarUrl}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<CloudUpload />}
              onClick={() => onSubmit()}
              className={classes.button}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, { uploadAvatar, setAlert })(UserAvatar);
