import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getSubmissions,
  clearSubmissions,
  updateSubmissionStatusTypeId,
} from "../../../redux/actions/challenge";
import { fetchAllJudges } from "../../../redux/actions/judge";
// Material UI
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
// Icons
import LaunchIcon from "@material-ui/icons/Launch";
import MailIcon from "@material-ui/icons/Mail";
// Components
import Button from "../../../shared/Button";
import PTable from "../../../shared/PTable";
import Spinner from "../../../shared/Spinner";
import OrgAppBar from "../../../shared/OrgAppBar";
import ChallengeGroupsDropdown from "../../../shared/ChallengeGroupsDropdown";
import ChallengeGroups from "../../../shared/ChallengeGroups";
import ChallengeViewSubmissionDetailsDialog from "./ChallengeViewSubmissionDetailsDialog";
import ChallengeJudgeSubmissionDropdown from "./ChallengeJudgeSubmissionDropdown";
import SubmissionExport from "./SubmissionExport";
import ChallengeAssignJudgeDialog from "./ChallengeAssignJudgeDialog";
import LinkToHelp from "../../../shared/LinkToHelp";
import ProfilePopover from "../../../shared/ProfilePopover";
// Utils
import {
  SUBMITTED_STATUS_TYPE_ID,
  DEFAULT_PAGE_SIZE,
} from "../../../utils/globalValues";

const SubmissionActions = () => (
  <>
    <ChallengeAssignJudgeDialog />
    <ChallengeGroups />
    <SubmissionExport />
  </>
);

const useStyles = makeStyles((theme) => ({
  Avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
}));

const SubmissionView = ({
  loading,
  challenge,
  submissions,
  currentRoundIndex,
  getSubmissions,
  clearSubmissions,
  fetchAllJudges,
  updateSubmissionStatusTypeId,
}) => {
  const classes = useStyles();

  const [records, setRecords] = useState([]);
  const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
  // ID for submissions dialog to open/display the correct info
  const [submissionIdDialog, setSubmissionIdDialog] = useState(null);
  const [columns, setColumns] = useState([]);

  const handleOpenSubmissionDialog = (submissionId) => {
    setOpenSubmissionDialog(true);
    setSubmissionIdDialog(submissionId);
  };

  // functions to fetch all the info for each page
  const fetchSubmissions = async () => {
    await getSubmissions(challenge.id, challenge.round[currentRoundIndex].id);
  };
  const fetchJudges = async () => {
    await fetchAllJudges(challenge.round[currentRoundIndex].id);
  };

  const headerCells = [
    {
      id: "view",
      label: "View",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "teamName",
      label: `${!challenge.maxPeopleTeam ? "Team" : ""} Name`,
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const teamName1 =
          param1 && param1.team && param1.team.name ? param1.team.name : "";
        const teamName2 =
          param2 && param2.team && param2.team.name ? param2.team.name : "";

        return teamName1.localeCompare(teamName2);
      },
    },
    {
      id: "submissionStatus",
      label: "Submission Status",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const subStatus1 =
          param1 &&
          param1.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED
            ? "Submitted"
            : param1 &&
              param1.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SAVED
            ? "In Progress"
            : "";
        const subStatus2 =
          param2 &&
          param2.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED
            ? "Submitted"
            : param2 &&
              param2.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SAVED
            ? "In Progress"
            : "";
        return subStatus1.localeCompare(subStatus2);
      },
    },
    {
      id: "groups",
      label: "Groups",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const numGroup1 = param1
          ? param1.groups
            ? param1.groups.length
            : 0
          : 0;
        const numGroup2 = param2
          ? param2.groups
            ? param2.groups.length
            : 0
          : 0;

        return numGroup1 - numGroup2;
      },
    },
    {
      id: "assignedReviewers",
      label: "Assigned Reviewers",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const numJudges1 = param1
          ? param1.judges
            ? param1.judges.length
            : 0
          : 0;
        const numJudges2 = param2
          ? param2.judges
            ? param2.judges.length
            : 0
          : 0;

        return numJudges1 - numJudges2;
      },
    },
    {
      id: "teamContact",
      label: "Contact",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
  ];

  function loadRowsColumns(params) {
    let newColumns = [];

    newColumns.push({
      field: "view",
      //width: 80,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View this submission">
            <IconButton onClick={() => handleOpenSubmissionDialog(params.id)}>
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    });

    newColumns.push({
      field: "teamName",
      headerName: `${!challenge.maxPeopleTeam ? "Team" : ""} Name`,
      //width: 250,
      sortComparator: (param1, param2) => {
        const name1 = param1.team && param1.team.name ? param1.team.name : "";
        const name2 = param2.team && param2.team.name ? param2.team.name : "";
        return name1.localeCompare(name2);
      },
      renderCell: (params) => {
        const team = params.team;
        return (
          <Box>
            <Box display="flex" flexDirection="column">
              <Typography
                variant="subtitle2"
                style={{
                  textDecoration:
                    team && team.eliminatedFlag && "line-through solid red",
                  lineHeight: 1,
                }}
              >
                <strong>{(team && team.name) || ""}</strong>{" "}
                {team && team.eliminatedFlag && <span> Eliminated</span>}
              </Typography>

              <AvatarGroup max={8}>
                {team &&
                  team.members &&
                  team.members.length > 0 &&
                  team.members.map((member, index) => {
                    const profile = member.profile;
                    const user = member.user;
                    if (!user) {
                      return null;
                    }

                    const education =
                      profile &&
                      profile.education &&
                      profile.education.length > 0 &&
                      profile.education[0];

                    return (
                      <ProfilePopover key={index} userId={user && user.id}>
                        <Avatar
                          className={classes.Avatar}
                          src={user.avatar}
                          alt={
                            user && user.userName && user.userName.toUpperCase()
                          }
                        />
                      </ProfilePopover>
                    );
                  })}
              </AvatarGroup>
            </Box>
          </Box>
        );
      },
    });

    newColumns.push({
      field: "submissionStatus",
      //width: 250,
      renderCell: (params) => {
        const sub = params;
        return (
          <Tooltip
            title={`Click here to change this submissions from ${
              sub.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED
                ? "Submitted to In Progress"
                : "In Progress to Submitted"
            }.`}
            placement="left"
          >
            <Chip
              label={
                sub.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED
                  ? "Submitted"
                  : sub.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SAVED
                  ? "In Progress"
                  : ""
              }
              color="primary"
              variant={
                sub.submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED
                  ? "default"
                  : "outlined"
              }
              onClick={() =>
                handleSubmissionStatusType(sub.id, sub.submittedStatusTypeId)
              }
            />
          </Tooltip>
        );
      },
    });

    newColumns.push({
      field: "groups",
      //width: 280,
      renderCell: (params) => {
        const sub = params;
        return (
          <ChallengeGroupsDropdown
            objectId={sub.id}
            selectedGroups={sub.groups}
            type="SUBMISSION"
          />
        );
      },
    });

    newColumns.push({
      field: "assignedReviewers",
      filterable: false,
      renderCell: (params) => {
        const sub = params;
        return (
          <Box>
            <ChallengeJudgeSubmissionDropdown
              assignedJudges={sub && sub.judges}
              submissionId={sub && sub.id}
            />
            <Typography variant="caption" color="textSecondary">
              {(sub && sub.judges && sub.judges.length) || 0} Reviewers Assigned
            </Typography>
          </Box>
        );
      },
    });
    newColumns.push({
      field: "teamContact",
      filterable: false,
      renderCell: (params) => {
        const members = params && params.team && params.team.members;
        const emails =
          members &&
          members.map((m) => {
            if (m.user && m.user.email) {
              return m.user.email;
            }
          });
        const emailJoin = emails && emails.join(", ");
        return (
          <Button
            component={MuiLink}
            href={`mailto:${emailJoin}`}
            startIcon={<MailIcon />}
            variant="text"
            color="default"
          >
            Mail
          </Button>
        );
      },
    });
    setColumns(newColumns);
  }

  useEffect(() => {
    // Load the rows and columns for the datagrid
    setRecords(submissions);
    loadRowsColumns(submissions);
  }, [submissions]);

  useEffect(() => {
    if (challenge) {
      fetchJudges();
      fetchSubmissions();
    }

    return () => clearSubmissions();
  }, [currentRoundIndex]);

  const handleSubmissionStatusType = (submissionId, submittedStatusTypeId) => {
    let newSubmittedStatusTypeId;
    if (submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED) {
      newSubmittedStatusTypeId = SUBMITTED_STATUS_TYPE_ID.SAVED;
    } else if (submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SAVED) {
      newSubmittedStatusTypeId = SUBMITTED_STATUS_TYPE_ID.SUBMITTED;
    }
    if (newSubmittedStatusTypeId) {
      updateSubmissionStatusTypeId(submissionId, newSubmittedStatusTypeId);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {submissionIdDialog && openSubmissionDialog && (
            <ChallengeViewSubmissionDetailsDialog
              open={openSubmissionDialog}
              setOpen={setOpenSubmissionDialog}
              submissionId={submissionIdDialog}
            />
          )}
          <Box px={[1, 2]}>
            <OrgAppBar
              title={null}
              subtitle={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" component="div">
                    <Box mr={1}>
                      {submissions ? submissions.length : 0} Submission(s)
                    </Box>
                  </Typography>
                  <LinkToHelp
                    label="Submissions 101"
                    link="https://www.notion.so/Submissions-badd1ba1036149508dd1b2256faccbbe"
                  />
                </Box>
              }
              actions={<SubmissionActions />}
            />
          </Box>
          <Box height="100%" margin="auto">
            <PTable
              records={records}
              headerCells={headerCells}
              columns={columns}
              noRowsText="There are no submissions at this time."
            />
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  challenge: state.challenge.challenge,
  submissions: state.challenge.submissions,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {
  getSubmissions,
  clearSubmissions,
  fetchAllJudges,
  updateSubmissionStatusTypeId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionView);
