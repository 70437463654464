import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// React router
import { Link } from "react-router-dom";
import { Route, useRouteMatch, useLocation } from "react-router";
// Redux
import { connect } from "react-redux";
import { setCurrentChallengeRoundIndex } from "../../redux/actions/challenge";
// Material UI
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import OrgChallengeAppBar from "../../shared/OrgChallengeAppBar";
import OrgAppBar from "../../shared/OrgAppBar";
import CustomSwitch from "../../shared/CustomSwitch";
import LinkToHelp from "../../shared/LinkToHelp";
import EditChallengeView from "./components/EditChallengeView";
import OrgChallengeAwards from "./components/OrgChallengeAwards";
import OrgChallengeDescriptions from "./components/OrgChallengeDescriptions";
// Utils
import PerfectScrollbar from "react-perfect-scrollbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`submissions-tabpanel-${index}`}
      aria-labelledby={`submissions-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `submissions-tab-${index}`,
    "aria-controls": `submissions-tabpanel-${index}`,
  };
}

const OrgChallengeDetails = ({
  challenge,
  currentRoundIndex,
  setCurrentChallengeRoundIndex,
}) => {
  const [tabValue, setTabValue] = useState(0);

  let { url } = useRouteMatch();
  const location = useLocation();

  return (
    <>
      <Box height="100%" margin="auto">
        <OrgChallengeAppBar />
        <Box>
          <OrgAppBar
            title="Details"
            subtitle={
              <Box display="flex" alignItems="center">
                <Typography variant="body2" component="div">
                  <Box mr={1}>Important information for this program.</Box>
                </Typography>
                <LinkToHelp
                  label="Customize Program"
                  link="https://www.notion.so/Program-Overview-Customization-1257e2e49b974f3a9980c72529ab8f2e"
                />
              </Box>
            }
          />
          <PerfectScrollbar>
            <AppBar position="static">
              <Tabs
                // value={tabValue}
                value={location.pathname}
                // onChange={handleTabChange}
                aria-label="round tabs"
              >
                <Tab
                  label="Edit"
                  component={Link}
                  value={`${url}`}
                  to={`${url}`}
                  {...a11yProps(0)}
                />
                <Tab
                  label="Descriptions"
                  component={Link}
                  value={`${url}/descriptions`}
                  to={`${url}/descriptions`}
                  {...a11yProps(1)}
                />
                <Tab
                  label="Awards"
                  component={Link}
                  value={`${url}/awards`}
                  to={`${url}/awards`}
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>

            <CustomSwitch>
              <Route exact path={`${url}`}>
                <EditChallengeView />
              </Route>
              <Route exact path={`${url}/descriptions`}>
                <OrgChallengeDescriptions />
              </Route>
              <Route exact path={`${url}/awards`}>
                <OrgChallengeAwards />
              </Route>
            </CustomSwitch>
          </PerfectScrollbar>
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = { setCurrentChallengeRoundIndex };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgChallengeDetails);
