// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
// Components
import UserAvatar from "../../../shared/UserAvatar";

const Account = ({ user, myprofile, formData, handleChange }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box mb={2} textAlign="center">
          <Typography variant="h5" component="h1" gutterBottom>
            <Box fontWeight={600}>
              👋 Welcome,{" "}
              {myprofile ? myprofile.firstName : user ? user.userName : ""}!
              Let's create your profile.
            </Box>
          </Typography>
          <Typography variant="body1" component="div" color="textSecondary">
            <Box>
              We are excited to have you join Pralent. Get started with your
              profile to let people know who you are.
            </Box>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <InputLabel required>Profile Photo</InputLabel>
          <Box mt={8}>
            <UserAvatar />
          </Box>
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <InputLabel required>Username</InputLabel>
          <TextField
            id="userName"
            name={"userName"}
            value={formData.userName}
            onChange={handleChange}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <InputLabel required>Headline</InputLabel>
          <TextField
            id="aboutMe"
            name={"aboutMe"}
            value={formData.aboutMe}
            onChange={handleChange}
            required
            multiline
            rows={2}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  myprofile: state.profile.myprofile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
