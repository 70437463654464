import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "../../utils/axios";
// Redux
import { connect } from "react-redux";
import {
  fetchSubmissionAndFormForChallengeRound,
  saveCustomFormToChallengeRound,
  submitCustomFormToChallengeRound,
} from "../../redux/actions/challenge";
// Material UI
import { makeStyles } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// Utils
import { getCurrentRound, submissionDetails } from "../../utils/challenge";
import {
  CUSTOM_FORM_TYPE_ID,
  SUBMITTED_STATUS_TYPE_ID,
} from "../../utils/globalValues";
import { setAlert } from "../../redux/actions/alert";
import isUrl from "../../utils/isUrl";
// Components
import widgets from "../CustomFormWidgets";
import CustomFieldTemplate from "../CustomFieldTemplate";
import ObjectFieldTemplate from "../ObjectFieldTemplate";
import Button from "../Button";
// Others
import Reward from "react-rewards";
import Form from "@rjsf/core";

const useStyles = makeStyles((theme) => ({
  SaveButton: {
    marginRight: theme.spacing(1),
  },
  SubmitButton: {
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ChallengeCustomSubmissionForm({
  challenge,
  currentUserStatus,
  myTeam,
  myCustomFormSubmission,
  fetchSubmissionAndFormForChallengeRound,
  saveCustomFormToChallengeRound,
  submitCustomFormToChallengeRound,
  setAlert,
}) {
  const classes = useStyles();
  const rewardRef = useRef(null);

  const [submittingForm, setSubmittingForm] = useState(false);

  const [customFormData, setCustomFormData] = useState({});

  useEffect(() => {
    const round = getCurrentRound(challenge);
    const challengeId = challenge.id;
    if (round) {
      const challengeRoundId = round.id;
      fetchSubmissionAndFormForChallengeRound(
        challengeId,
        challengeRoundId,
        CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM
      );
    }
  }, [challenge, challenge.id]);

  useEffect(() => {
    if (myCustomFormSubmission) {
      setCustomFormData(myCustomFormSubmission.formData);
    }
  }, [myCustomFormSubmission]);

  const handeChange = ({ formData }) => {
    setCustomFormData(formData);
  };

  const getCurrentRoundId = () => {
    const round = getCurrentRound(challenge);
    if (round) {
      return round.id;
    } else return "";
  };
  const handleSave = async () => {
    await setSubmittingForm(true);
    // Save the custom submission data
    const round = getCurrentRound(challenge);
    const challengeId = challenge.id;
    let challengeRoundId;
    if (round) {
      challengeRoundId = round.id;
    }

    const { data: newFormData } = await uploadFiles(customFormData);

    await saveCustomFormToChallengeRound(
      newFormData,
      challengeId,
      challengeRoundId
    );

    await setSubmittingForm(false);
  };

  const uploadFiles = async (formData) => {
    try {
      let errorUploadingFiles = false;

      const jsonSchema = myCustomFormSubmission.jsonSchema;
      const jsonSchemaProperties = jsonSchema.properties;

      let newFormData = {
        ...formData,
      };

      for (var key of Object.keys(formData)) {
        try {
          const keyProperty = jsonSchemaProperties[key];
          if (keyProperty && keyProperty.pralentFieldType === "file") {
            const fileToUpload = formData[key];
            if (fileToUpload && !isUrl(fileToUpload)) {
              const fileFormData = new FormData();
              fileFormData.append("myFile", fileToUpload);
              // make request to upload it
              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };
              const challengeId = challenge.id;
              const challengeRoundId = getCurrentRoundId();

              const res = await axios.post(
                `/api/challenge/custom/upload/file/${challengeId}/${challengeRoundId}`,
                fileFormData,
                config
              );

              if (res && res.data) {
                newFormData[key] = res.data.customSubmissionFilePath;
              } else {
                errorUploadingFiles = true;
                setAlert(
                  "Error uploading file onto server. Please contact support",
                  "error"
                );
              }
            }
          }
        } catch (error) {
          errorUploadingFiles = true;
          setAlert(`Error uploading file onto server.`, "error");
          newFormData[key] = null;
        }
      }
      return { data: newFormData, error: errorUploadingFiles };
    } catch (error) {
      errorUploadingFiles = true;
      let errorMsg = "";
      if (error) errorMsg = error.message;
      setAlert(
        `Error uploading file onto server. Please contact support: ${errorMsg}`,
        "error"
      );
    }
  };

  const handleSubmit = async () => {
    await setSubmittingForm(true);
    // Save the custom submission data
    if (
      currentUserStatus.submittedStatusTypeId ===
      SUBMITTED_STATUS_TYPE_ID.SUBMITTED
    ) {
      await setSubmittingForm(false);
      return false;
    }
    const round = getCurrentRound(challenge);
    const challengeId = challenge.id;
    let challengeRoundId;
    if (round) {
      challengeRoundId = round.id;
    }

    if (!myTeam) {
      await setAlert(
        "Please register and check you have a team before submitting.",
        "error"
      );
    } else if (myTeam && myTeam.eliminatedFlag) {
      await setAlert(
        "Your team has been eliminated from the program by the organization. Please contact support or the organizers for more information.",
        "error"
      );
    } else if (submissionDetails(challenge) !== 0) {
      await setAlert(
        "It is not within the time frame of submission. Please contact the program administrators or Pralent support if this is a problem.",
        "error"
      );
    } else if (!currentUserStatus.isRegistered) {
      setAlert("Please register before submitting.", "error");
    } else if (
      currentUserStatus.submittedStatusTypeId ===
      SUBMITTED_STATUS_TYPE_ID.SUBMITTED
    ) {
      setAlert(
        "There is a submission for you for this program. If this is a problem or an issue, please contact support.",
        "error"
      );
    } else {
      const jsonSchema = myCustomFormSubmission.jsonSchema;
      const jsonSchemaProperties = jsonSchema.properties;
      for (let key of Object.keys(jsonSchemaProperties)) {
        const keyProperty = jsonSchemaProperties[key];
        if (keyProperty && keyProperty.pralentFieldType === "file") {
          if (
            jsonSchema.required &&
            jsonSchema.required.includes(key) &&
            (!(key in customFormData) || !customFormData[key])
          ) {
            setAlert(
              "Please ensure that all required files are uploaded",
              "error"
            );
            await setSubmittingForm(false);
            return;
          }
        }
      }
      if (
        window.confirm(
          "Are you sure you want to submit to this program? All submissions are final."
        )
      ) {
        const { data: newFormData, error: errorUploadingFiles } =
          await uploadFiles(customFormData);

        if (!errorUploadingFiles) {
          await submitCustomFormToChallengeRound(
            newFormData,
            challengeId,
            challengeRoundId
          );
          rewardRef && rewardRef.current && rewardRef.current.rewardMe();
        }
      }
    }
    await setSubmittingForm(false);
  };

  const onDelete = () => {};

  const onCancel = () => {};

  return (
    <>
      <Box wdith="100%" maxHeight="500px" position="relative">
        <Reward
          ref={rewardRef}
          type="confetti"
          config={{
            lifetime: 360,
            angle: 90,
            decay: 0.9,
            spread: 360,
            startVelocity: 75,
            elementCount: 250,
            elementSize: 10,
          }}
        >
          <Box display="none">Reward</Box>
        </Reward>
      </Box>

      <Backdrop className={classes.backdrop} open={submittingForm}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box width="100%" mt={1}>
        <FormGroup>
          {myCustomFormSubmission &&
            myCustomFormSubmission.jsonSchema &&
            myCustomFormSubmission.uiSchema &&
            myCustomFormSubmission.formData && (
              <Fragment>
                <Form
                  FieldTemplate={CustomFieldTemplate}
                  ObjectFieldTemplate={ObjectFieldTemplate}
                  schema={
                    myCustomFormSubmission && myCustomFormSubmission.jsonSchema
                  }
                  uiSchema={
                    myCustomFormSubmission && myCustomFormSubmission.uiSchema
                  }
                  widgets={widgets}
                  formData={customFormData}
                  onChange={handeChange}
                  onSubmit={handleSubmit}
                  onError={(error) => console.log(error)}
                  disabled={
                    currentUserStatus.submittedStatusTypeId ===
                    SUBMITTED_STATUS_TYPE_ID.SUBMITTED
                  }
                  noValidate
                >
                  <Box display="flex" alignItems="stretch">
                    {currentUserStatus.submittedStatusTypeId !==
                      SUBMITTED_STATUS_TYPE_ID.SUBMITTED &&
                      myTeam &&
                      !myTeam.eliminatedFlag && (
                        <>
                          <Tooltip title="Click here to save your entry. You can come back and modify your entry after saving.">
                            <Button
                              className={classes.SaveButton}
                              variant="outlined"
                              onClick={handleSave}
                            >
                              Save
                            </Button>
                          </Tooltip>
                          <Tooltip title="Click here to submit your entry. You will not be able to modify your entry after submitting.">
                            <Button
                              className={classes.SubmitButton}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </Tooltip>
                        </>
                      )}
                  </Box>
                </Form>
              </Fragment>
            )}
        </FormGroup>
      </Box>
    </>
  );
}

const mapDispatchToProps = {
  fetchSubmissionAndFormForChallengeRound,
  saveCustomFormToChallengeRound,
  submitCustomFormToChallengeRound,
  setAlert,
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentUserStatus: state.challenge.currentUserStatus,
  myCustomFormSubmission: state.challenge.myCustomFormSubmission,
  myTeam: state.challenge.myTeam,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCustomSubmissionForm);
