import React from "react";
// Redux
import { connect } from "react-redux";
// Material Ui
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
// Components
import widgets from "../CustomFormWidgets";
import CustomFieldTemplate from "../CustomFieldTemplate";
import ObjectFieldTemplate from "../ObjectFieldTemplate";
// Others
import Form from "@rjsf/core";

function ChallengeCustomSubmissionView({ myCustomFormSubmission }) {
  return (
    <Box width="100%" whiteSpace="normal">
      <FormGroup>
        {myCustomFormSubmission &&
          myCustomFormSubmission.jsonSchema &&
          myCustomFormSubmission.uiSchema &&
          myCustomFormSubmission.formData && (
            <Form
              FieldTemplate={CustomFieldTemplate}
              ObjectFieldTemplate={ObjectFieldTemplate}
              schema={
                myCustomFormSubmission && myCustomFormSubmission.jsonSchema
              }
              uiSchema={
                myCustomFormSubmission && myCustomFormSubmission.uiSchema
              }
              widgets={widgets}
              formData={myCustomFormSubmission.formData}
              onError={console.log("Error loading custom form")}
              disabled
            >
              <button className="hidden" type="submit"></button>
            </Form>
          )}
      </FormGroup>
    </Box>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeCustomSubmissionView);
