import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  jobCard: {
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
  },
  cardColumns: {
    [theme.breakpoints.up("sm")]: {
      columnCount: 1,
      gridColumnGap: "2rem",
      columnGap: "2rem",
      orphans: 1,
      widows: 1,
    },
    [theme.breakpoints.up("md")]: {
      columnCount: 2,
    },
    [theme.breakpoints.up("lg")]: {
      columnCount: 3,
    },
  },
  searchInput: {
    flex: 1,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  searchPaper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    boxShadow:
      "rgba(0, 12, 32, 0.04) 0px 0px 1px 0px, rgba(10, 31, 68, 0.04) 0px 10px 16px 0px",
  },
  searchContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  searchButton: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    height: "100%",
    boxShadow: "none",
    textTransform: "capitalize",
    "&:hover": {
      boxShadow: "none",
    },
  },
  skillChip: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.dark.main,
    fontWeight: 600,
    fontSize: "0.8rem",
  },
  applyButton: {
    textTransform: "capitalize",
    fontWeight: 600,
  },
  loadMore: {
    textTransform: "capitalize",
    fontWeight: 600,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      outline: 0,
    },
  },
}));

const Jobs = ({ myprofile, isAuthenticated, publicJobs }) => {
  const classes = useStyles();

  const [jobs, setJobs] = useState([]);
  const [renderedJobs, setRenderedJobs] = useState([]);
  const [numberToRender, setNumberToRender] = useState(45);
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      fetch(
        "https://spreadsheets.google.com/feeds/list/1a032ycZdeve9SX-x4Cb3ds8cVVvJYk0gDUi66Ent-sQ/o3h5ecn/public/values?alt=json"
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data && data.feed && data.feed.entry) {
            setJobs(data.feed.entry);
            setRenderedJobs(data.feed.entry);
          }
        });
    };
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const searchForJob = (e) => {
    e.preventDefault();
    let totalJobs = [...jobs];
    let filteredJobs = [];
    if (searchValue !== "") {
      filteredJobs = totalJobs.filter((job) => {
        const title = job.gsx$title && job.gsx$title.$t.toLowerCase();
        const companyName =
          job.gsx$companyname && job.gsx$companyname.$t.toLowerCase();
        const location =
          job.gsx$joblocation && job.gsx$joblocation.$t.toLowerCase();
        const jobType = job.gsx$jobtype && job.gsx$jobtype.$t.toLowerCase();
        const skills =
          job.gsx$skills &&
          job.gsx$skills.$t.split(",").map((elem) => elem.toLowerCase().trim());
        const filter = searchValue.toLowerCase();
        return (
          title.includes(filter) ||
          location.includes(filter) ||
          jobType.includes(filter) ||
          companyName.includes(filter) ||
          (skills && skills.includes(filter))
        );
      });
    } else {
      filteredJobs = [...jobs];
      setNumberToRender(45);
    }
    setRenderedJobs(filteredJobs);
  };

  const loadMore = (e) => {
    e.preventDefault();
    if (numberToRender <= renderedJobs.length) {
      setNumberToRender(numberToRender + 30);
    }
  };

  return (
    <Box
      flexGrow={1}
      height="100%"
      maxHeight="100vh"
      overflow="auto"
      p={{ xs: 2, md: 4 }}
    >
      <Container>
        <div className="mb-4 px-3 text-left leading-normal md:mb-6">
          <h4 className="text-xl font-black leading-tight md:text-2xl lg:text-4xl">
            Jobs
          </h4>

          {myprofile === null ? (
            <p className="text-sm leading-tight text-gray-600 md:text-base lg:text-lg">
              Please <Link to="/login">login</Link> or{" "}
              <Link to="/signup">create your candidate profile</Link> in under 5
              minutes to apply to jobs.
            </p>
          ) : (
            <p className="text-sm leading-tight text-gray-600 md:text-base lg:text-lg">
              Let's find your next job or internship!
            </p>
          )}
        </div>

        {/* <Box className={classes.cardColumns}>
          {publicJobs.map((job, index) => {
            return (
              // <div key={index}>
              //   <JobCard job={job} />
              // </div>
             
            );
          })}
        </Box> */}
        {jobs && jobs.length > 0 && (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            component="form"
            className={classes.searchContainer}
            onSubmit={searchForJob}
          >
            <Grid item xs={12} md={10} lg={8}>
              <Paper className={classes.searchPaper} elevation={3}>
                <InputBase
                  className={classes.searchInput}
                  placeholder="Search for Jobs"
                  inputProps={{ "aria-label": "search for jobs" }}
                  value={searchValue}
                  onChange={(e) => handleSearchChange(e)}
                />

                <Button
                  type="submit"
                  className={classes.searchButton}
                  aria-label="search"
                  color="primary"
                  variant="contained"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          {renderedJobs.slice(0, numberToRender).map((job, index) => {
            const skills =
              job.gsx$skills &&
              job.gsx$skills !== "" &&
              job.gsx$skills.$t.split(",");
            return (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Box
                  className={classes.jobCard}
                  p={2}
                  borderRadius="borderRadius"
                  height="100%"
                >
                  <img
                    src={job.gsx$companyimageurl && job.gsx$companyimageurl.$t}
                    style={{
                      maxWidth: "75px",
                      maxHeight: "75px",
                      display: "inline-block",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  />

                  <Box my={2}>
                    <Typography component="div" variant="h6">
                      <Box fontWeight={600} lineHeight={1.1}>
                        {job.gsx$title && job.gsx$title.$t}
                      </Box>
                    </Typography>
                    <Typography
                      component="div"
                      variant="caption"
                      style={{ display: "inline-block" }}
                    >
                      <Box fontWeight={600} mr={1}>
                        {job.gsx$companyname && job.gsx$companyname.$t}
                      </Box>
                    </Typography>
                    <Typography
                      component="div"
                      variant="caption"
                      style={{ display: "inline-block" }}
                    >
                      <Box fontWeight={600} mx={1} mt={1}>
                        {job.gsx$joblocation && job.gsx$joblocation.$t}
                      </Box>
                    </Typography>
                    <Typography
                      component="div"
                      variant="caption"
                      style={{ display: "inline-block" }}
                    >
                      <Box fontWeight={600} ml={1} mt={1}>
                        {job.gsx$jobtype && job.gsx$jobtype.$t}
                      </Box>
                    </Typography>
                  </Box>

                  {skills &&
                    skills.map((skill, j) => (
                      <Chip
                        style={{ marginRight: "5px", marginBottom: "5px" }}
                        label={skill}
                        key={j}
                        className={classes.skillChip}
                      />
                    ))}
                  <Box mt={2}>
                    {isAuthenticated ? (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        href={
                          job.gsx$applicationlink && job.gsx$applicationlink.$t
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Apply
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to="/login"
                        className={classes.applyButton}
                      >
                        Apply
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
        <Box textAlign="center" mt={3}>
          {jobs && jobs.length <= 0 && <LinearProgress />}
          {numberToRender <= renderedJobs.length && !loading && (
            <Button
              variant="contained"
              color="primary"
              className={classes.loadMore}
              onClick={loadMore}
            >
              Load More
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  myprofile: state.profile.myprofile,
  publicJobs: state.jobs.publicJobs,
});

export default connect(mapStateToProps)(Jobs);
