import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMyProjects, clearMyProjects } from "../../../redux/actions/project";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import ProjectFormButton from "../../../shared/ProjectFormButton";
import SimpleMyProjectCard from "../../../shared/SimpleMyProjectCard";
// Images
import innovation from "../../../assets/images/innovation.svg";

// Import the data and action method below
const Projects = ({ myProjects, getMyProjects, clearMyProjects }) => {
  useEffect(() => {
    if (!(myProjects && myProjects.length > 0)) {
      getMyProjects();
    }
    return () => clearMyProjects();
  }, []);

  return (
    <Fragment>
      <ProjectFormButton />
      {myProjects && myProjects.length > 0 ? (
        <Box mt={2}>
          <Grid
            container
            spacing={4}
            direction="row"
            alignItems="stretch"
            justify="flex-start"
          >
            {myProjects.map((project, index) => {
              return (
                <Grid item xs={12} md={6} key={index}>
                  <SimpleMyProjectCard project={project} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Box
          display="block"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            height="100%"
            width="350px"
            src={innovation}
            alt="no projects added image"
          />
          <Typography variant="body1" component="div" color="textSecondary">
            There are no projects added yet. Showcase your awesome projects and
            real-world skills on your profile!
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

// Declare below to check if the properties are available
Projects.propTypes = {
  myProjects: PropTypes.array,
  getMyProjects: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  myProjects: state.project.myProjects,
});

export default connect(mapStateToProps, { getMyProjects, clearMyProjects })(
  Projects
);
