import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import { addEditPrize } from "../../../redux/actions/challenge";
// Material UI

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
// Helper Functions
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Components
import Button from "../../../shared/Button";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    boxShadow: "none",
  },
}));

const ChallengePrizeDialog = ({
  challenge,
  prizes,
  prize = null,
  open,
  setOpen,
  addEditPrize,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    clearFormData();
    setOpen(false);
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // Make sure that you have a valid values for the fields
    if (formData.name && formData.description && formData.challengeId) {
      // Send a request to save/update  criteria details
      const res = await addEditPrize(formData);
      if (res) {
        handleClose();
      }
    }
  };

  const [formData, setFormData] = useState({
    challengeId: challenge && challenge.id,
    name: "",
    description: "",
    numberAwarded: 1,
    ranking: (prizes && prizes.length && prizes.length + 1) || 1,
  });

  const clearFormData = () => {
    setFormData({
      challengeId: challenge && challenge.id,
      name: "",
      description: "",
      numberAwarded: 1,
      ranking: (prizes && prizes.length && prizes.length + 1) || 1,
    });
  };

  useEffect(() => {
    if (prize) {
      setFormData({
        ...formData,
        id: prize.id,
        challengeId: challenge && challenge.id,
        name: prize.name,
        description: prize.description,
        numberAwarded: prize.numberAwarded,
        ranking: prize.ranking || "",
      });
    }
  }, [prize, open === true]);

  const { name, description, numberAwarded, ranking } = formData;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <form onSubmit={(e) => onSubmit(e)}>
        <AppBar className={classes.appBar} position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Box flex="1">
              <Typography variant="h6">
                {prize !== null ? "Edit" : "Add"} Award
              </Typography>
            </Box>
            <Button type="submit">Save</Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Award Name</InputLabel>
                <TextField
                  placeholder="Name for this award"
                  type="text"
                  required
                  onChange={handleChange}
                  name="name"
                  value={name}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Ranking Order</InputLabel>
                <TextField
                  placeholder="Ranking of the awards (1 or 2 or 3 ...)"
                  type="number"
                  onChange={handleChange}
                  name="ranking"
                  value={ranking}
                  min="1"
                  step="1"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Description</InputLabel>
                <TextField
                  multiline
                  placeholder="Description for this award"
                  type="text"
                  required
                  onChange={handleChange}
                  name="description"
                  value={description}
                  rows={6}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel required>Number of Awards</InputLabel>
                <TextField
                  placeholder="Number of awards given"
                  type="number"
                  required
                  onChange={handleChange}
                  name="numberAwarded"
                  value={numberAwarded}
                  min="1"
                  step="1"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  prizes: state.challenge.prizes,
});

const mapDispatchToProps = { addEditPrize, setAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengePrizeDialog);
