import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Redux
import { setAlert } from "../../../redux/actions/alert";
import { deleteAccount } from "../../../redux/actions/profile";
import { resetPassword, updateUserAccount } from "../../../redux/actions/auth";
// Components
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MuiLink from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core";
// Components
import Button from "../../../shared/Button";
// Utils
import { THEME_TYPE } from "../../../utils/globalValues";
import {
  BASE_COLORS,
  LIGHT_COLORS,
  DARK_COLORS,
  ASH_COLORS,
  MIDNIGHT_COLORS,
  PALE_COLORS,
  DAWN_COLORS,
} from "../../../styles/theme/theme";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    marginTop: "0",
  },
  saveButton: {
    fontWeight: 600,
  },
}));

const Account = ({
  auth: { user },
  setAlert,
  resetPassword,
  updateUserAccount,
  onboarding = false,
}) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const THEME_OPTIONS = [
    {
      name: "System preference",
      value: THEME_TYPE.SYSTEM,
      color: prefersDarkMode ? DARK_COLORS : LIGHT_COLORS,
    },
    {
      name: "Light",
      value: THEME_TYPE.LIGHT,
      color: LIGHT_COLORS,
    },
    {
      name: "Dark",
      value: THEME_TYPE.DARK,
      color: DARK_COLORS,
    },
    {
      name: "Ash",
      value: THEME_TYPE.ASH,
      color: ASH_COLORS,
    },
    {
      name: "Midnight",
      value: THEME_TYPE.MIDNIGHT,
      color: MIDNIGHT_COLORS,
    },
    {
      name: "Pale",
      value: THEME_TYPE.PALE,
      color: PALE_COLORS,
    },
    {
      name: "Dawn",
      value: THEME_TYPE.DAWN,
      color: DAWN_COLORS,
    },
  ];

  const classes = useStyles();
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    newPassword: "",
    confirmPassword: "",
    emailNotificationActive: true,
    theme: THEME_TYPE.SYSTEM,
  });

  // Handle Field Change
  const handleChange = (e) => {
    const target = e.target;
    const value =
      target.type === "checkbox" || target.type === "switch"
        ? target.checked
        : target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        userName: user.userName,
        email: user.email,
        emailNotificationActive: user.emailNotificationActive,
        theme: user.theme || THEME_TYPE.SYSTEM,
      });
    }
  }, [user]);

  useEffect(() => {
    isDisabled();
  }, [formData]);

  const {
    userName,
    email,
    newPassword,
    confirmPassword,
    emailNotificationActive,
    theme,
  } = formData;

  const [disabled, setDisabled] = useState(true);

  const isDisabled = () => {
    if (theme !== user.theme) {
      setDisabled(false);
    } else if (newPassword !== "" && confirmPassword !== "") {
      setDisabled(false);
    } else if (
      user.userName !== userName ||
      user.emailNotificationActive !== emailNotificationActive
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      user.userName !== userName ||
      user.emailNotificationActive !== emailNotificationActive ||
      user.theme !== theme
    ) {
      if (userName.length < 2) {
        setAlert("Please enter a longer username", "info");
      } else {
        let reqUser = {
          userName: userName,
          emailNotificationActive: emailNotificationActive,
          theme: theme,
        };
        updateUserAccount(reqUser);
      }
    }
  };

  useEffect(() => {
    if (!disabled && theme !== user.theme) {
      let reqUser = {
        userName: userName,
        emailNotificationActive: emailNotificationActive,
        theme: theme,
      };
      updateUserAccount(reqUser);
    }
  }, [disabled]);

  return (
    <Box>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>Settings</Box>
            </Typography>
            <Typography variant="body2" component="div" color="textSecondary">
              <Box>Manage your settings and preferences.</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>Account</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Username</InputLabel>
              {user && (
                <TextField
                  className={classes.input}
                  variant="outlined"
                  // margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={userName}
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="Username"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">@</InputAdornment>
                    ),
                  }}
                />
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Email</InputLabel>
              {user && user.loginTypeId === 1 && false ? (
                <TextField
                  className={classes.input}
                  variant="outlined"
                  // margin="dense"
                  onChange={(e) => handleChange(e)}
                  value={email}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              ) : (
                <Typography variant="body1" component="div">
                  <Box fontWeight={600}>{email}</Box>
                </Typography>
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>Preferences</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Interface Theme</InputLabel>
              <Select
                labelId="interface-theme-label"
                id="interface-theme"
                name="theme"
                variant="outlined"
                margin="dense"
                value={theme}
                onChange={handleChange}
              >
                {THEME_OPTIONS &&
                  THEME_OPTIONS.map(({ name, value, color }, index) => {
                    return (
                      <MenuItem value={value} key={index}>
                        <Box display="flex" alignItems="center">
                          <Box
                            style={{
                              backgroundColor: color.background.default,
                              color: color.text.primary,
                            }}
                            py={0.25}
                            px={0.75}
                            mr={1}
                            borderRadius={8}
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              mr={0.5}
                              style={{
                                color: color.primary
                                  ? color.primary.main
                                  : BASE_COLORS.primary.main,
                              }}
                              fontSize="h6.fontSize"
                            >
                              •
                            </Box>
                            <Box fontSize="caption.fontSize" lineHeight={0}>
                              Aa
                            </Box>
                          </Box>
                          <Box>{name}</Box>
                        </Box>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>Notifications</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={emailNotificationActive}
                    onChange={(e) => handleChange(e)}
                    value={emailNotificationActive}
                    color="primary"
                    name={"emailNotificationActive"}
                  />
                }
                label={
                  <Box ml={1}>
                    <Typography variant="body2" component="div" gutterBottom>
                      <Box fontWeight={600}>Subscribe to Newsletter</Box>
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        Yes, I want to receive emails from Pralent communities
                        programs and updates.
                      </Box>
                    </Typography>
                  </Box>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button disabled={disabled} color="primary" type="submit">
                Save
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" component="div">
              <Box fontWeight={600}>Danger Zone</Box>
            </Typography>
            <Box
              my={2}
              p={{ xs: 2 }}
              borderColor="error.main"
              border={1}
              borderRadius="borderRadius"
              width="100%"
              display="flex"
              alignItems="center"
            >
              <Box flexGrow={1}>
                <Typography variant="body2" component="div" gutterBottom>
                  <Box fontWeight={600}>Delete your account</Box>
                </Typography>
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  <Box lineHeight={1.2}>
                    If you would like to delete your account, please contact{" "}
                    <MuiLink href="mailto:support@pralent.com">
                      support@pralent.com
                    </MuiLink>{" "}
                    using the email address associated with this account and let
                    us know that you would like us to delete your account.
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

Account.propTypes = {
  auth: PropTypes.object.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  updateUserAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteAccount,
  setAlert,
  resetPassword,
  updateUserAccount,
})(Account);
