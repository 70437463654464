import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
// Icons
import FileCopyIcon from "@material-ui/icons/FileCopy";
// utils
import useClipboard from "react-use-clipboard";
import axios from "../../../utils/axios";

export const JudgeLinkDialog = ({
  judge,
  challenge,
  currentRoundIndex,
  setAlert,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [link, setLink] = useState("");
  const [password, setPassword] = useState(null);

  const [isCopiedLink, setCopiedLink] = useClipboard(link);
  const [isCopiedPassword, setCopiedPassword] = useClipboard(password);

  const onClickCopyLink = () => {
    setCopiedLink();
    onCopySuccessMessage("link");
  };

  const onClickCopyPassword = () => {
    setCopiedPassword();
    onCopySuccessMessage("password");
  };

  const onCopySuccessMessage = (copiedWhat) => {
    setAlert(
      `Copied ${judge.firstName} ${judge.lastName} reviewing ${copiedWhat}`,
      "success"
    );
  };

  useEffect(() => {
    if (challenge && judge && open) {
      // link
      const link = `${document.location.origin}/programs/${challenge.slug}/reviewer/${judge.id}`;
      setLink(link);
      // password
      const passwordEnabled =
        challenge &&
        challenge.round &&
        challenge.round[currentRoundIndex].judgingPasswordEnabled;
      if (passwordEnabled) {
        const fetchRawPassword = async () => {
          try {
            const res = await axios.get(
              `/api/judge/retrievePassword/${judge.id}`
            );

            setPassword(res.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchRawPassword();
      }
      {
        setPassword(null);
      }
    }
  }, [challenge, judge, open]);

  //console.log("domain: ", document.location.origin);
  return (
    <>
      <IconButton onClick={handleOpen}>
        <Tooltip
          title={`Click here to view and copy the reviewer link for ${judge.firstName} ${judge.lastName}`}
        >
          <FileCopyIcon />
        </Tooltip>
      </IconButton>
      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          <strong>
            Reviewing Information for {judge.firstName} {judge.lastName}
          </strong>
        </DialogTitle>
        <Box px={[2, 3]} pb={[2]}>
          <Box my={2}>
            <InputLabel>Reviewer Link</InputLabel>
            <Box display="flex" alignItems="center">
              <IconButton onClick={onClickCopyLink}>
                <FileCopyIcon />
              </IconButton>

              <Box ml={2} lineHeight="1">
                <Typography>
                  {`${document.location.origin}/programs/${challenge.slug}/reviewer/${judge.id}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          {password && (
            <Box my={2}>
              <InputLabel>Unique Reviewer Password</InputLabel>
              <Box display="flex" alignItems="center">
                <IconButton onClick={onClickCopyPassword}>
                  <FileCopyIcon />
                </IconButton>
                <Box ml={2} lineHeight="1">
                  <Typography>{password}</Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(JudgeLinkDialog);
