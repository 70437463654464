import React, { Fragment, useState, useEffect } from "react";
// React query
import { useMutation, useQueryClient } from "react-query";
import { createUpdateJob } from "../../hooks/job";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// Material UI slate-js
import RichTextEditor from "../RichTextEditor";
// Icons
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
// Utils
import DateFnsUtils from "@date-io/date-fns";

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: "0",
  },
  appBar: {
    boxShadow: "none",
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

const PostJob = ({ companyProfile, job, jobCategoryType, setAlert }) => {
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState({
    title: "",
    location: "",
    typeId: 1,
    contactEmail: "",
    paid: true,
    salary: "",
    applicationLink: "",
    description: initialSlateValue,
    isActive: true,
    acceptApplicationsThroughPralent: true,
    isPublicToPralent: true,
    closeDateTime: null,
  });
  const classes = useStyles();
  const [modal, toggleModal] = useState(false);
  const [editing, handleEdit] = useState(false);

  useEffect(() => {
    if (job) {
      setFormData({
        title: job.title,
        location: job.location,
        typeId: job.typeId,
        contactEmail: job.contactEmail,
        paid: job.paid,
        salary: job.salary,
        applicationLink: job.applicationLink,
        description: job.description || initialSlateValue,
        isActive: job.isActive,
        acceptApplicationsThroughPralent: job.acceptApplicationsThroughPralent,
        isPublicToPralent: job.isPublicToPralent,
        closeDateTime: job.closeDateTime && new Date(job.closeDateTime),
      });
      handleEdit(true);
    }
  }, [job, modal]);

  const toggle = async () => {
    await toggleModal(!modal);
  };

  const {
    title,
    contactEmail,
    location,
    typeId,
    paid,
    salary,
    applicationLink,
    description,
    isActive,
    acceptApplicationsThroughPralent,
    isPublicToPralent,
    closeDateTime,
  } = formData;

  const onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };

  const createUpdateJobMutation = useMutation(createUpdateJob, {
    onSuccess: async (data, variables) => {
      const updatedJob = data.data;
      let myCompanyJobs = await queryClient.getQueryData([
        "myCompanyJobs",
        { companyId: companyProfile.id },
      ]);

      let myCompanyJobsData = [...(myCompanyJobs.data || [])];
      if (variables && variables.jobDetails && variables.jobDetails.id) {
        // update
        myCompanyJobsData =
          myCompanyJobs &&
          myCompanyJobs.data.map((oldJob, index) => {
            if (oldJob.id === updatedJob.id) {
              return updatedJob;
            }
            return oldJob;
          });
      } else {
        // add
        myCompanyJobsData = [updatedJob, ...myCompanyJobsData];
      }

      await queryClient.setQueryData(
        ["myCompanyJobs", { companyId: companyProfile.id }],
        {
          ...myCompanyJobs,
          data: myCompanyJobsData,
        }
      );

      await setAlert("Sucessfully updated the job post.", "success");

      await toggle();
    },
    onError: async (error) => {
      await setAlert(
        (error && error.data && error.data.msg) ||
          "There was an error updating the job post. Please try again.",
        "error"
      );
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    let data = { ...formData };
    if (editing) {
      data.id = job.id;
    }
    createUpdateJobMutation.mutate({
      companyId: companyProfile.id,
      jobDetails: data,
    });
  };

  return (
    <Fragment>
      {editing ? (
        <Box mr={1}>
          <Tooltip title="Click here to edit job">
            <IconButton onClick={toggle}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Button type="submit" color="primary" onClick={toggle}>
          Add New Job
        </Button>
      )}

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={modal}
        onClose={toggle}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={(e) => onSubmit(e)}>
          <AppBar className={classes.appBar} position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggle}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Box mt={2} ml={1}>
                <Typography variant="h5" component="h2">
                  <Box fontWeight={600}>
                    {editing ? "Edit Job Details" : "Post New Job"}
                  </Box>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box mb={2}>
                    {editing ? "Update" : "Add "} the job information
                  </Box>
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm">
            <Box mb={{ xs: 2, md: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                    <Box fontWeight={600}>Overview</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel required>Job Title</InputLabel>
                    <TextField
                      id={"title"}
                      fullWidth
                      margin={"dense"}
                      autoComplete={"job"}
                      variant={"outlined"}
                      type="text"
                      name="title"
                      required
                      autoFocus
                      value={title}
                      onChange={(e) => onChange(e)}
                      className={classes.input}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Job Type</InputLabel>
                    <Select
                      margin="dense"
                      labelId="jobtype-simple-select-outlined-label"
                      id="jobtype-simple-select-outlined"
                      value={typeId}
                      onChange={(e) => onChange(e)}
                      autoWidth={true}
                      variant="outlined"
                      name="typeId"
                    >
                      {jobCategoryType &&
                        jobCategoryType.length &&
                        jobCategoryType.map((cat, index) => {
                          return (
                            <MenuItem value={cat.id} key={index}>
                              {cat.jobType}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Location</InputLabel>
                    <TextField
                      id={"location"}
                      fullWidth
                      margin={"dense"}
                      autoComplete={"location"}
                      variant={"outlined"}
                      type="text"
                      name="location"
                      value={location}
                      onChange={(e) => onChange(e)}
                      className={classes.input}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Primary Contact Email</InputLabel>
                    <TextField
                      id={"contactEmail"}
                      fullWidth
                      margin={"dense"}
                      autoComplete={"email"}
                      variant={"outlined"}
                      type="email"
                      name="contactEmail"
                      value={contactEmail}
                      onChange={(e) => onChange(e)}
                      className={classes.input}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <InputLabel>Pay</InputLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={paid}
                          onChange={(e) => {
                            if (paid) {
                              setFormData({
                                ...formData,
                                paid: false,
                                salary: "",
                              });
                            } else {
                              setFormData({
                                ...formData,
                                paid: true,
                              });
                            }
                          }}
                          value={paid}
                          color="primary"
                          name={"paid"}
                        />
                      }
                      label={"This is a paid position."}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel>Description *</InputLabel>
                    <Box>
                      <RichTextEditor
                        value={description || initialSlateValue}
                        setValue={(newValue) =>
                          setFormData({
                            ...formData,
                            description: newValue,
                          })
                        }
                        placeholder="Enter the description for this job post ..."
                      />
                    </Box>
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                    <Box fontWeight={600}>How to Apply</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Application Link</InputLabel>
                  <TextField
                    id={"applicationLink"}
                    fullWidth
                    margin={"dense"}
                    autoComplete={"applicationLink"}
                    variant={"outlined"}
                    type="url"
                    name="applicationLink"
                    placeholder="https://www.mywebsite.com/jobpost"
                    value={applicationLink}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={acceptApplicationsThroughPralent}
                          onChange={(e) => onChange(e)}
                          value={acceptApplicationsThroughPralent}
                          name="acceptApplicationsThroughPralent"
                          color="primary"
                        />
                      }
                      label={
                        <Box ml={2}>
                          <Typography
                            variant="body2"
                            component="div"
                            gutterBottom
                          >
                            <Box fontWeight={600}>
                              Accept applications through Pralent
                            </Box>
                          </Typography>
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                          >
                            <Box lineHeight={1.2}>
                              Toggle this option if you want individuals to be
                              able to apply with 1-click through Pralent.
                              Applicant information will be available to see in
                              your job dashboard. If an application link is
                              added, applicants will be redirected to the link
                              after clicking apply.
                            </Box>
                          </Typography>
                        </Box>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12}>
                      <FormGroup>
                        <InputLabel>
                          Close Date & Time{" "}
                          <Tooltip
                            style={{ marginLeft: 5 }}
                            title="This is when the job will no longer accept applicants and no longer be displayed on any job boards. Leave empty if you do not want it to close at a certain date and time."
                          >
                            <HelpOutlineOutlinedIcon />
                          </Tooltip>
                        </InputLabel>
                        <DateTimePicker
                          name="closeDateTime"
                          inputVariant="outlined"
                          value={closeDateTime}
                          onChange={(newDateTime) =>
                            setFormData({
                              ...formData,
                              closeDateTime: newDateTime,
                            })
                          }
                          onError={console.log}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      closeDateTime: null,
                                    });
                                  }}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormGroup>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h3">
                    <Box fontWeight={600}>Settings</Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isActive}
                          onChange={(e) => onChange(e)}
                          value={isActive}
                          name="isActive"
                          color="primary"
                        />
                      }
                      label={
                        <Box ml={2}>
                          <Typography
                            variant="body2"
                            component="div"
                            gutterBottom
                          >
                            <Box fontWeight={600}>Active</Box>
                          </Typography>
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                          >
                            <Box lineHeight={1.2}>
                              If active, individuals can see the job on job
                              boards and apply. If inactive, the job will not be
                              available on job boards and individuals will not
                              be able to apply.
                            </Box>
                          </Typography>
                        </Box>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPublicToPralent}
                          onChange={(e) => onChange(e)}
                          value={isPublicToPralent}
                          name="isPublicToPralent"
                          color="primary"
                        />
                      }
                      label={
                        <Box ml={2}>
                          <Typography
                            variant="body2"
                            component="div"
                            gutterBottom
                          >
                            <Box fontWeight={600}>
                              Public on the Pralent Discover Page
                            </Box>
                          </Typography>
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                          >
                            <Box lineHeight={1.2}>
                              If public, this job will be available for all
                              individuals on Pralent to view and apply. If not
                              enabled, only individuals with the job link and
                              job boards with this job will be able to view and
                              apply.
                            </Box>
                          </Typography>
                        </Box>
                      }
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{ gap: "4px" }}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </form>
      </Dialog>
    </Fragment>
  );
};

PostJob.propTypes = {};

const mapStateToProps = (state) => ({
  jobCategoryType: state.typedef.jobCategoryType,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(PostJob);
