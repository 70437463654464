import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchAllJudges,
  getJudgesScoringProgress,
  clearJudges,
  deleteJudge,
} from "../../../redux/actions/judge";
// Material UI
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";
// Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
// Components
import PTable from "../../../shared/PTable";
import LinkToHelp from "../../../shared/LinkToHelp";
import Spinner from "../../../shared/Spinner";
import Button from "../../../shared/Button";
import OrgAppBar from "../../../shared/OrgAppBar";
import ChallengeGroups from "../../../shared/ChallengeGroups";
import ChallengeGroupsDropdown from "../../../shared/ChallengeGroupsDropdown";
import ChallengeJudgeDialog from "./ChallengeJudgeDialog";
import JudgeScoresDialog from "./JudgeScoresDialog";
import JudgesMore from "./JudgesMore";
import JudgeLinkDialog from "./JudgeLinkDialog";
import JudgesDownload from "./JudgesDownload";

// judges actions
const JudgesActions = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleAddNewJudgeModal = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <ChallengeJudgeDialog
        judge={null}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Tooltip title={"Add a new reviewer"} placement="top">
        <div>
          <Button onClick={handleAddNewJudgeModal}>Add new reviewer</Button>
        </div>
      </Tooltip>
      <ChallengeGroups />
      <JudgesDownload />
      <JudgesMore />
    </>
  );
};

export const JudgesView = ({
  judgeLoading,
  challengeLoading,
  judges,
  judgesScoringProgress,
  challenge,
  currentRoundIndex,
  fetchAllJudges,
  getJudgesScoringProgress,
  clearJudges,
  deleteJudge,
}) => {
  const fetchAllJudgesForRound = async (roundIndex) => {
    const round = challenge && challenge.round && challenge.round[roundIndex];
    await fetchAllJudges(round.id);
  };
  const fetchJudgesScoringProgressForRound = async (roundIndex) => {
    const round = challenge && challenge.round && challenge.round[roundIndex];
    await getJudgesScoringProgress(round.id);
  };

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filterfn, setFilterfn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headerCells = [
    {
      id: "edit",
      label: "Edit",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "scores",
      label: "Scores",
      hide: false,
      disableSorting: true,
      filterable: true,
    },
    {
      id: "link",
      label: "Link",
      hide: false,
      disableSorting: true,
      filterable: true,
    },
    {
      id: "nameEmail",
      label: "Name /  Email",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const judge1 = param1;
        const judge2 = param2;
        const name1 =
          judge1 && judge1.firstName && judge1.lastName
            ? judge1.firstName + " " + judge1.lastName
            : "";
        const name2 =
          judge2 && judge2.firstName && judge2.lastName
            ? judge2.firstName + " " + judge2.lastName
            : "";
        return name1.localeCompare(name2);
      },
    },
    {
      id: "groups",
      label: "Groups",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const group1 = param1 ? (param1.groups ? param1.groups.length : 0) : 0;
        const group2 = param2 ? (param2.groups ? param2.groups.length : 0) : 0;
        return group1 - group2;
      },
    },
    {
      id: "scoringProgress",
      label: "Scoring Progress",
      hide: false,
      disableSorting: false,
      filterable: false,
      sortComparator: (param1, param2) => {
        // judges progress
        let numberSubmissionsScored1,
          numberSubmissionsScored2 = 0;
        if (param1) {
          numberSubmissionsScored1 =
            (judgesScoringProgress && judgesScoringProgress[param1.id]) || 0;
        }
        if (param2) {
          numberSubmissionsScored2 =
            (judgesScoringProgress && judgesScoringProgress[param2.id]) || 0;
        }
        return numberSubmissionsScored1 - numberSubmissionsScored2;
      },
    },
    {
      id: "invitationSent",
      label: "Invitation Sent",
      hide: false,
      disableSorting: false,
      filterable: false,
      sortComparator: (param1, param2) => {
        const inviteSent1 = param1 ? (param1.inviteSent ? "Yes" : "No") : "No";
        const inviteSent2 = param2 ? (param2.inviteSent ? "Yes" : "No") : "No";

        return inviteSent1.localeCompare(inviteSent2);
      },
    },
    {
      id: "invitationAccepted",
      label: "Invitation Accepted",
      hide: false,
      disableSorting: false,
      filterable: false,
      sortComparator: (param1, param2) => {
        const inviteAccepted1 = param1
          ? param1.inviteAccepted
            ? "Yes"
            : "No"
          : "No";
        const inviteAccepted2 = param2
          ? param2.inviteAccepted
            ? "Yes"
            : "No"
          : "No";

        return inviteAccepted1.localeCompare(inviteAccepted2);
      },
    },
    {
      id: "active",
      label: "Active",
      hide: false,
      disableSorting: false,
      filterable: false,
      sortComparator: (param1, param2) => {
        const isActive1 = param1 ? (param1.isActive ? "Yes" : "No") : "No";
        const isActive2 = param2 ? (param2.isActive ? "Yes" : "No") : "No";

        return isActive1.localeCompare(isActive2);
      },
    },
    {
      id: "delete",
      label: "Delete",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
  ];

  function loadRowsColumns(params) {
    // -----------------------------------
    // columns
    // -----------------------------------
    let newColumns = [];

    newColumns.push({
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        const judge = params;
        return (
          <IconButton onClick={() => handleEditJudgeModal(judge)}>
            <EditIcon />
          </IconButton>
        );
      },
    });

    newColumns.push({
      field: "scores",
      headerName: "Scores",
      width: 80,
      renderCell: (params) => {
        const judge = params;
        return <JudgeScoresDialog challengeJudgeId={judge.id} />;
      },
    });

    newColumns.push({
      field: "link",
      headerName: "Link",
      width: 80,
      renderCell: (params) => {
        const judge = params;
        return <JudgeLinkDialog judge={judge} />;
      },
    });

    newColumns.push({
      field: "nameEmail",
      headerName: "Name /  Email",
      width: 225,
      renderCell: (params) => {
        const judge = params;
        return (
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">
              <strong>
                {judge.firstName} {judge.lastName}
              </strong>
            </Typography>

            <Link color="textSecondary" href={`mailto:${judge.emailAddress}`}>
              <Typography variant="body2">{judge.emailAddress}</Typography>
            </Link>
          </Box>
        );
      },
    });
    newColumns.push({
      field: "Groups",
      headerName: "Groups",
      width: 275,
      renderCell: (params) => {
        const judge = params;
        return (
          <ChallengeGroupsDropdown
            objectId={judge.id}
            selectedGroups={judge.groups}
            type="JUDGE"
          />
        );
      },
    });
    newColumns.push({
      field: "scoringProgress",
      headerName: "Scoring Progress",
      width: 140,
      renderCell: (params) => {
        // judges progress
        const numberSubmissionsAssigned =
          params.submissions && params.submissions.length;
        const numberSubmissionsScored =
          (judgesScoringProgress && judgesScoringProgress[params.id]) || 0;

        let submissionsScoredProgress;
        if (numberSubmissionsAssigned && numberSubmissionsAssigned > 0) {
          const percentage =
            numberSubmissionsScored / numberSubmissionsAssigned;

          submissionsScoredProgress = parseFloat(percentage).toFixed(2) * 100;
        }
        return (
          <Box display="flex" justifyContent="space-between" width="100%">
            {!isNaN(submissionsScoredProgress) ? (
              <>
                <Tooltip
                  title={`Completed scoring ${numberSubmissionsScored} out of ${numberSubmissionsAssigned} submissions`}
                >
                  <Box width="100%">
                    <LinearProgress
                      value={submissionsScoredProgress}
                      color={
                        submissionsScoredProgress === 100
                          ? "primary"
                          : "secondary"
                      }
                      variant="determinate"
                    />
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="caption">
                        {numberSubmissionsScored}
                      </Typography>
                      <Typography variant="caption">
                        {numberSubmissionsAssigned}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Zero submissions have been assigned">
                <Box>0 / 0</Box>
              </Tooltip>
            )}
          </Box>
        );
      },
    });

    newColumns.push({
      field: "invitationSent",
      headerName: "Invitation Sent",
      width: 140,
      renderCell: (params) => {
        const inviteSent = params.inviteSent;
        return (
          <Chip
            label={inviteSent ? "Yes" : "No"}
            color="default"
            variant={`${inviteSent ? "default" : "outlined"}`}
          />
        );
      },
    });

    newColumns.push({
      field: "invitationAccepted",
      headerName: "Invitation Accepted",
      width: 140,
      renderCell: (params) => {
        const inviteAccepted = params.inviteAccepted;
        return (
          <Chip
            label={inviteAccepted ? "Yes" : "No"}
            color="default"
            variant={`${inviteAccepted ? "default" : "outlined"}`}
          />
        );
      },
    });

    newColumns.push({
      field: "Active",
      headerName: "Active",
      width: 100,
      renderCell: (params) => {
        const isActive = params.isActive;
        return (
          <Chip
            label={isActive ? "Yes" : "No"}
            color="default"
            variant={`${isActive ? "default" : "outlined"}`}
          />
        );
      },
    });

    newColumns.push({
      field: "Delete",
      headerName: "Delete",
      width: 80,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const judge = params;
        return (
          <IconButton onClick={() => deleteJudge(judge.id)}>
            <DeleteIcon />
          </IconButton>
        );
      },
    });
    setColumns(newColumns);
  }

  useEffect(() => {
    if (challenge) {
      fetchAllJudgesForRound(currentRoundIndex);
      fetchJudgesScoringProgressForRound(currentRoundIndex);
    }
    return () => clearJudges();
  }, [currentRoundIndex]);

  useEffect(() => {
    setRecords(judges);
    loadRowsColumns();
  }, [judges, judgesScoringProgress]);

  // edit judge dialog
  const [records, setRecords] = useState([]);
  const [editJudge, setEditJudge] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleEditJudgeModal = (judge) => {
    setEditJudge(judge);
    setOpenDialog(true);
  };

  return (
    <>
      {judgeLoading || challengeLoading || !judges ? (
        <Box height="100%">
          <Spinner />
        </Box>
      ) : (
        <>
          <ChallengeJudgeDialog
            judge={editJudge}
            open={openDialog}
            setOpen={setOpenDialog}
          />
          <Box px={[1, 2]}>
            <OrgAppBar
              title={null}
              subtitle={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" component="div">
                    <Box mr={1}>
                      {(judges && judges.length) || 0} Reviewer(s)
                    </Box>
                  </Typography>
                  <LinkToHelp
                    label="Review 101"
                    link="https://www.notion.so/Review-c611ae7c790c4c70aa76e92f50e41128"
                  />
                </Box>
              }
              actions={<JudgesActions />}
            />
          </Box>
          <Box height="100%" margin="auto">
            <PTable
              records={records}
              headerCells={headerCells}
              columns={columns}
              filterfn={filterfn}
              noRowsText="There are no reviewers at this time."
            />
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  judgeLoading: state.judge.loading,
  challengeLoading: state.challenge.loading,
  judges: state.judge.judges,
  judgesScoringProgress: state.judge.judgesScoringProgress,
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {
  fetchAllJudges,
  getJudgesScoringProgress,
  clearJudges,
  deleteJudge,
};

export default connect(mapStateToProps, mapDispatchToProps)(JudgesView);
