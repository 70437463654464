import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";

const RenderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

export default RenderGroup;
