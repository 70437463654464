import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createCompanyProfile } from "../../../redux/actions/company";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
// Components
import Button from "../../../shared/Button";
// Shared component
import RichTextEditor from "../../../shared/RichTextEditor";
// utils
import { useMutation } from "react-query";
// Hooks
import community from "../../../hooks/community";
import { FormHelperText } from "@material-ui/core";

const OrgProfileCommunity = ({
  createCompanyProfile,
  companyProfile,
  setAlert,
}) => {
  const [formData, setFormData] = useState({
    headerImageUrl: "",
    communitySlug: "",
    communityWelcomeMessage: "",
    isCommunityEnabled: true,
    isCommunityPublic: false,
    doMembersNeedApproval: true,
  });

  useEffect(() => {
    if (companyProfile) {
      setFormData({
        id: companyProfile.id,
        communitySlug: companyProfile.communitySlug || "",
        communityWelcomeMessage: companyProfile.communityWelcomeMessage || "",
        isCommunityEnabled: companyProfile.isCommunityEnabled ? true : false,
        isCommunityPublic: companyProfile.isCommunityPublic ? true : false,
        doMembersNeedApproval: companyProfile.doMembersNeedApproval
          ? true
          : false,
      });
    }
  }, [companyProfile]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (companyProfile && companyProfile.id) {
      formData.id = companyProfile.id;
      formData.orgId = companyProfile.orgId;
    }
    await createCompanyProfile(formData);
  };

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const {
    communitySlug,
    communityWelcomeMessage,
    isCommunityEnabled,
    isCommunityPublic,
    doMembersNeedApproval,
  } = formData;

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            <Box fontWeight={600}>Community</Box>
          </Typography>
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>Manage all of your community settings.</Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel required>Custom Page Url</InputLabel>
            <TextField
              placeholder="Enter an url slug (your unique url ending)."
              type="text"
              required
              onChange={handleChange}
              name="communitySlug"
              value={communitySlug}
              InputProps={{
                startAdornment: (
                  <div className="font-semibold" position="start">
                    {document.location.origin}/c/
                  </div>
                ),
              }}
              inputProps={{ pattern: "^[-a-zA-Z0-9@.+_]+$" }}
            />
            <FormHelperText>
              This is the shareable url where your page is hosted. Please enter
              an unique slug at the end of the URL.
            </FormHelperText>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="isCommunityEnabled"
                  name="isCommunityEnabled"
                  color="primary"
                  checked={isCommunityEnabled}
                  onChange={handleChange}
                />
              }
              label={
                <Box ml={1}>
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight={600}>
                      Enable members to join the community
                    </Box>
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      If you select this option, you will be enabling community
                      features for your organization. If enabled, individuals
                      will be able to join your community, view and interact
                      with other members, and much more.
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </FormGroup>
        </Grid>
        {isCommunityEnabled && (
          <>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="doMembersNeedApproval"
                      name="doMembersNeedApproval"
                      color="primary"
                      checked={doMembersNeedApproval}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Box ml={1}>
                      <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight={600}>Require Membership Approval</Box>
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        <Box lineHeight={1.2}>
                          If you select this option, members will require
                          approval from an organizer to have access to your
                          community.
                        </Box>
                      </Typography>
                    </Box>
                  }
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="isCommunityPublic"
                      name="isCommunityPublic"
                      color="primary"
                      checked={isCommunityPublic}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <Box ml={1}>
                      <Typography variant="body2" component="div" gutterBottom>
                        <Box fontWeight={600}>Public to Pralent</Box>
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        <Box lineHeight={1.2}>
                          When you enable this setting, the community will be
                          available for all Pralent users to discover.
                        </Box>
                      </Typography>
                    </Box>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel>Welcome Message</InputLabel>

                <RichTextEditor
                  placeholder="Enter a welcome message for community members to see once they join."
                  setValue={(value) =>
                    setFormData({
                      ...formData,
                      communityWelcomeMessage: value,
                    })
                  }
                  value={communityWelcomeMessage}
                />
              </FormGroup>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" type="submit">
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  createCompanyProfile,
  setAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgProfileCommunity);
