import { Link } from "react-router-dom";

const CONTACT_INFO = [
  { title: "General Inquiries", email: "info@pralent.com" },
  { title: "Sales & Partnerships", email: "sales@pralent.com" },
  { title: "Press", email: "press@pralent.com" },
  { title: "Investors", email: "investors@pralent.com" },
];

const Contact = () => {
  return (
    <div>
      <div className="max-w-7xl mx-auto py-16 px-4">
        <div class="text-center pb-16 lg:pb-24">
          <h2 class="text-base font-semibold text-primary-600 tracking-wide uppercase">
            Contact
          </h2>
          <p class="mt-1 text-4xl font-extrabold text-gray-100 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Get in touch!
          </p>
          <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            Contact us and our team will get back to you within 24 hours.
          </p>
        </div>
        <div className="divide-y-2 divide-gray-200">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl font-extrabold text-gray-100 sm:text-3xl">
              Get in touch
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              {CONTACT_INFO &&
                CONTACT_INFO.map((c, i) => {
                  return (
                    <div key={i}>
                      <h3 className="text-lg leading-6 font-medium text-gray-100">
                        {c.title}
                      </h3>
                      <dl className="mt-2 text-base text-gray-500">
                        <div>
                          <dt className="sr-only">Email</dt>
                          <dd>
                            <a
                              className="hover:text-primary-500"
                              href={`mailto:${c.email}`}
                            >
                              {c.email}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  );
                })}
            </div>
            {/* <p class="mt-6 text-base text-gray-500">
              Looking for careers?{" "}
              <Link to="/careers" class="font-medium text-gray-700 underline">
                View all openings
              </Link>
              .
            </p> */}
          </div>
          <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
            <h2 className="text-2xl font-extrabold text-gray-100 sm:text-3xl">
              Locations
            </h2>
            <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-100">
                  Pittsburgh
                </h3>
                <div className="mt-2 text-base text-gray-500">
                  <p>4765 Forbes Ave </p>
                  <p className="mt-1">Pittsburgh, PA 15213</p>
                </div>
              </div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-100">
                  Philadelphia
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
