// Auth
export const USER_NOT_LOADED = "USER_NOT_LOADED";
export const CLOSE_SERVER_ERROR = "CLOSE_SERVER_ERROR";
export const SERVER_ERROR = "SERVER_ERROR";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const CLEAR_ALL_ALERTS = "CLEAR_ALL_ALERTS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UPDATE_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPLOAD_AVATAR = "UPLOAD_AVATAR";
export const SET_TOKEN = "SET_TOKEN";
export const SET_REDIRECT_URL = "SET_REDIRECT_URL";
export const SET_DEFAULT_REDIRECT = "SET_DEFAULT_REDIRECT";
// Config value setting
export const GET_GOOGLE_CLIENT_ID = "GET_GOOGLE_CLIENT_ID";

// Profile
export const GET_MYPROFILE = "GET_MYPROFILE";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE_PROJECTS = "GET_PROFILE_PROJECTS";
export const GET_REPOS = "GET_REPOS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const CLEAR_PROFILES = "CLEAR_PROFILES";
export const CLEAR_MYPROFILE = "CLEAR_MYPROFILE";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";
export const ADD_PROFILE_LINKS = "ADD_PROFILE_LINKS";
export const ADD_PROFILE_ANSWERS = "ADD_PROFILE_ANSWERS";
export const GET_PROFILE_SKILLS = "GET_PROFILE_SKILLS";
export const ADD_PROFILE_SKILLS = "ADD_PROFILE_SKILLS";
export const UPDATE_PROFILE_SKILLS = "UPDATE_PROFILE_SKILLS";
// File Upload
export const GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE";
export const UPLOAD_PROFILE_IMAGE = "UPLOAD_PROFILE_IMAGE";
export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";
export const UPLOAD_PROFILE_IMAGE_ERROR = "UPLOAD_PROFILE_IMAGE_ERROR";
export const GET_RESUME = "GET_RESUME";
export const UPLOAD_RESUME = "UPLOAD_RESUME";
export const DELETE_RESUME = "DELETE_RESUME";
// Projects
export const MY_PROJECT_LOADING = "MY_PROJECT_LOADING";
export const PROJECT_LOADING = "PROJECT_LOADING";
export const ADD_PROJECT = "ADD_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECT = "GET_PROJECT";
export const GET_MYPROJECTS = "GET_MYPROJECTS";
export const PROJECT_ERROR = "PROJECT_ERROR";
export const ADD_LIKE = "ADD_LIKE";
export const REMOVE_LIKE = "REMOVE_LIKE";
export const ADD_LIKE_PUBLIC_PROJECT = "ADD_LIKE_PUBLIC_PROJECT";
export const REMOVE_LIKE_PUBLIC_PROJECT = "REMOVE_LIKE_PUBLIC_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const UPLOAD_PROJECT_IMAGE = "UPLOAD_PROJECT_IMAGE";
export const CLEAR_MYPROJECTS = "CLEAR_MYPROJECTS";
export const CLEAR_PUBLICPROJECTS = "CLEAR_PUBLICPROJECTS";
export const DELETE_PROJECT_FILE = "DELETE_PROJECT_FILE";
export const CLEAR_PROJECT = "CLEAR_PROJECT";

// Company
export const UPDATE_ORG_ID = "UPDATE_ORG_ID";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const CREATE_COMPANY_PROFILE = "CREATE_COMPANY_PROFILE";
export const GET_COMPANY_PROFILE = "GET_COMPANY_PROFILE";
export const GET_ALL_COMPANY_JOBS = "GET_ALL_COMPANY_JOBS";
export const ADD_USER_COMPANY_RELATIONSHIP = "ADD_USER_COMPANY_RELATIONSHIP";
export const DELETE_USER_COMPANY_RELATIONSHIP =
  "DELETE_USER_COMPANY_RELATIONSHIP";
export const ADD_COMPANY_LOGO = "ADD_COMPANY_LOGO";
export const LOADING_ORGANIZATIONS = "LOADING_ORGANIZATIONS";
export const GET_MY_ORGANIZATIONS = "GET_MY_ORGANIZATIONS";
export const CLEAR_MY_ORGANIZATIONS = "CLEAR_MY_ORGANIZATIONS";
// Jobs
export const GET_ALL_ACTIVE_JOBS = "GET_ALL_ACTIVE_JOBS";
export const APPLY_JOB = "APPLY_JOB";
export const REMOVE_APPLICATION_TO_JOB = "REMOVE_APPLICATION_TO_JOB";
export const GET_JOB = "GET_JOB";
export const CLEAR_JOB = "CLEAR_JOB";
export const LOADING_JOB = "LOADING_JOB";
export const ERROR_JOB = "ERROR_JOB";
export const CLEAR_JOB_BOARD = "CLEAR_JOB_BOARD";
export const GET_JOB_BOARD = "GET_JOB_BOARD";
export const LOADING_JOB_BOARD = "LOADING_JOB_BOARD";
export const ERROR_JOB_BOARD = "ERROR_JOB_BOARD";
export const GET_MY_COMPANY_JOBS = "GET_MY_COMPANY_JOBS";
export const LOADING_MY_COMPANY_JOBS = "LOADING_MY_COMPANY_JOBS";
export const ERROR_MY_COMPANY_JOBS = "ERROR_MY_COMPANY_JOBS";
export const DELETE_JOB_EXT_BOARD = "DELETE_JOB_EXT_BOARD";
export const GET_MY_COMPANY_EXT_JOBS = "GET_MY_COMPANY_EXT_JOBS";
export const LOADING_MY_COMPANY_EXT_JOBS = "LOADING_MY_COMPANY_EXT_JOBS";
export const ERROR_MY_COMPANY_EXT_JOBS = "ERROR_MY_COMPANY_EXT_JOBS";
export const ADD_JOB = "ADD_JOB";
export const EDIT_JOB = "EDIT_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const DELETE_EXT_JOB_POST = "DELETE_EXT_JOB_POST";
export const UPDATE_EXT_JOB_POST_BOARD_APPROVAL =
  "UPDATE_EXT_JOB_POST_BOARD_APPROVAL";
export const UPDATE_CANDIDATE_APPLICATION_STATUS =
  "UPDATE_CANDIDATE_APPLICATION_STATUS";
// Typedef
export const GET_SKILLSET = "GET_SKILLSET";
export const QUESTIONS_ANSWERS = "QUESTIONS_ANSWERS";
export const PROJECT_CATEGORIES = "PROJECT_CATEGORIES";
export const PROJECT_STATUS = "PROJECT_STATUS";
export const EXTLINK_TYPE = "EXTLINK_TYPE";
export const PUBLIC_PROFILE_TYPE = "PUBLIC_PROFILE_TYPE";
export const JOB_CATEGORY_TYPE = "JOB_CATEGORY_TYPE";
export const GENDER_TYPE = "GENDER_TYPE";
export const RACE_TYPE = "RACE_TYPE";
// Challenge
export const LOADING_SUBMISSION_SCORES = "LOADING_SUBMISSION_SCORES";
export const GET_SUBMISSION_SCORES = "GET_SUBMISSION_SCORES";
export const LOADING_CHALLENGE = "LOADING_CHALLENGE";
export const ENABLE_JUDGING_PASSWORD = "ENABLE_JUDGING_PASSWORD";
export const SET_CHALLENGE_STATUS_FOR_USER = "SET_CHALLENGE_STATUS_FOR_USER";
export const UPDATE_SUBMISSION_STATUS_TYPE = "UPDATE_SUBMISSION_STATUS_TYPE";
export const SET_CURRENT_ROUND_INDEX = "SET_CURRENT_ROUND_INDEX";
export const GET_ALL_CHALLENGES = "GET_ALL_CHALLENGES";
export const GET_CHALLENGE = "GET_CHALLENGE";
export const REGISTER_USER_CHALLENGE = "REGISTER_USER_CHALLENGE";
export const LEAVE_CHALLENGE = "LEAVE_CHALLENGE";
export const IS_USER_REGISTERED = "IS_USER_REGISTERED";
export const CLEAR_CHALLENGE = "CLEAR_CHALLENGE";
export const CLEAR_SUBMISSIONS = "CLEAR_SUBMISSIONS";
export const CLEAR_REGISTRATIONS = "CLEAR_REGISTRATIONS";
export const CLEAR_ALL_CHALLENGES = "CLEAR_ALL_CHALLENGES";
export const CLEAR_MY_CHALLENGES = "CLEAR_MY_CHALLENGES";
export const LOADING_CHALLENGES = "LOADING_CHALLENGES";
export const HAS_USER_SUBMITTED = "HAS_USER_SUBMITTED";
export const ELIGIBLE_TO_SUBMIT_TO_ROUND = "ELIGIBLE_TO_SUBMIT_TO_ROUND";
export const USER_SUBMIT_ELIGIBLE_STATUS = "USER_SUBMIT_ELIGIBLE_STATUS";
export const SHOW_SUBMISSIONS = "SHOW_SUBMISSIONS";
export const SHOW_REGISTRATIONS = "SHOW_REGISTRATIONS";
export const GET_MY_CHALLENGES = "GET_MY_CHALLENGES";
export const ADD_UPDATE_CHALLENGE = "ADD_UPDATE_CHALLENGE";
export const ADD_UPDATE_CHALLENGE_ROUND = "ADD_UPDATE_CHALLENGE_ROUND";
export const IS_PUBLIC_CHALLENGE = "IS_PUBLIC_CHALLENGE";
export const IS_ACTIVE_CHALLENGE = "IS_ACTIVE_CHALLENGE";
export const SET_ROUND_AS_CURRENT = "SET_ROUND_AS_CURRENT";
export const IS_PUBLISHED_CHALLENGE = "IS_PUBLISHED_CHALLENGE";
export const ADD_CHALLENGE_PROPERTIES = "ADD_CHALLENGE_PROPERTIES";
export const CHALLENGE_HEADER_IMAGE = "CHALLENGE_HEADER_IMAGE";
export const GET_MY_SUBMISSION = "GET_MY_SUBMISSION";
export const CLEAR_MY_SUBMISSION = "CLEAR_MY_SUBMISSION";
export const CLEAR_SELECTED_SUBMISSION = "CLEAR_SELECTED_SUBMISSION";
export const SET_PRIMARY_COLOR = "SET_PRIMARY_COLOR";
export const CHALLENGE_ERROR = "CHALLENGE_ERROR";
export const GET_ALL_PRIZES = "GET_ALL_PRIZES";
export const ADD_EDIT_PRIZE = "ADD_EDIT_PRIZE";
export const ADD_PRIZE = "ADD_PRIZE";
export const EDIT_PRIZE = "EDIT_PRIZE";
export const DELETE_PRIZE = "DELETE_PRIZE";
export const GET_SUBMISSION = "GET_SUBMISSION";
export const GET_SUBMISSION_FORM_DATA = "GET_SUBMISSION_FORM_DATA";
export const GET_SUBMISSION_AND_PROJECT = "GET_SUBMISSION_AND_PROJECT";
export const ASSIGN_JUDGES_TO_SUBMISSION = "ASSIGN_JUDGES_TO_SUBMISSION";
export const GET_ASSIGN_JUDGE_SETTINGS = "GET_ASSIGN_JUDGE_SETTINGS";
export const SORT_SUBMISSION_SCORES = "SORT_SUBMISSION_SCORES";
export const UPDATE_GROUPS_SUBMISSION = "UPDATE_GROUPS_SUBMISSION";
// Judge
export const DELETE_JUDGE = "DELETE_JUDGE";
export const DELETE_CRITERIA = "DELETE_CRITERIA";
export const LOADING_JUDGE_SCORES = "LOADING_JUDGE_SCORES";
export const GET_JUDGE_SCORES = "GET_JUDGE_SCORES";
export const AUTHENTICATE_JUDGE = "AUTHENTICATE_JUDGE";
export const GET_JUDGE_SCORING_PROGRESS = "GET_JUDGE_SCORING_PROGRESS";
export const SET_ALL_ACTIVE_STATUS = "SET_ALL_ACTIVE_STATUS";
export const JUDGE_LOADED = "JUDGE_LOADED";
export const JUDGE_VALIDATED = "JUDGE_VALIDATED";
export const JUDGE_VALIDATE_SUCCESS = "JUDGE_VALIDATE_SUCCESS";
export const JUDGE_VALIDATE_FAIL = "JUDGE_VALIDATE_FAIL";
export const JUDGE_VERIFY_TOKEN = "JUDGE_VERIFY_TOKEN";
export const JUDGE_SET_REDIRECT_URL = "JUDGE_SET_REDIRECT_URL";
export const JUDGE_ACCEPT_TERMS = "JUDGE_ACCEPT_TERMS";
export const JUDGE_GET_SUBMISSION = "JUDGE_GET_SUBMISSION";
export const JUDGE_SAVE_SCORES = "JUDGE_SAVE_SCORES";
export const UPDATE_SUBMISSION_INDEX = "UPDATE_SUBMISSION_INDEX";
export const GET_PREVIOUS_SUBMISSION = "GET_PREVIOUS_SUBMISSION";
export const CLEAR_CURRENT_SUBMISSION = "CLEAR_CURRENT_SUBMISSION";
export const CLEAR_ALL_JUDGING = "CLEAR_ALL_JUDGING";
export const SET_JUDGE_LOADING = "SET_JUDGE_LOADING";
export const SET_CRITERIA_LOADING = "SET_CRITERIA_LOADING";
export const UPDATE_DIRTY_SCORE = "UPDATE_DIRTY_SCORE";
export const GET_ALL_JUDGES = "GET_ALL_JUDGES";
export const GET_ALL_CRITERIA = "GET_ALL_CRITERIA";
export const ADD_EDIT_JUDGE = "ADD_EDIT_JUDGE";
export const ADD_JUDGE = "ADD_JUDGE";
export const EDIT_JUDGE = "EDIT_JUDGE";
export const ADD_CRITERIA = "ADD_CRITERIA";
export const EDIT_CRITERIA = "EDIT_CRITERIA";
export const ADD_EDIT_CRITERIA = "ADD_EDIT_CRITERIA";
export const CLEAR_MY_TEAM = "CLEAR_MY_TEAM";
export const GET_TEAM_INFORMATION = "GET_TEAM_INFORMATION";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const MODIFY_TEAM_NAME = "MODIFY_TEAM_NAME";
export const REMOVING_SELF_TEAM = "REMOVING_SELF_TEAM";
export const IS_CHALLENGE_REGISTRATION_PUBLIC =
  "IS_CHALLENGE_REGISTRATION_PUBLIC";
export const IS_CHALLENGE_PRIZE_PUBLIC = "IS_CHALLENGE_PRIZE_PUBLIC";
export const IS_CHALLENGE_SUBMISSION_PUBLIC = "IS_CHALLENGE_SUBMISSION_PUBLIC";
export const IS_JUDGES_PUBLIC = "IS_JUDGES_PUBLIC";
export const IS_CRITERIA_PUBLIC = "IS_CRITERIA_PUBLIC";
export const RELEASE_JUDGING = "RELEASE_JUDGING";
export const RELEASE_SCORES = "RELEASE_SCORES";
export const RELEASE_FEEDBACK = "RELEASE_FEEDBACK";
export const DISQUALIFY_REGISTERED_TEAM = "DISQUALIFY_REGISTERED_TEAM";
export const UPDATE_GROUPS_JUDGE = "UPDATE_GROUPS_JUDGE";
export const CLEAR_JUDGE_SUBMISSION = "CLEAR_JUDGE_SUBMISSION";

// Onboarding
export const ONBOARDING_FROM_CHALLENGE = "ONBOARDING_FROM_CHALLENGE";
export const ONBOARDING_CLEAR_CHALLENGE = "ONBOARDING_CLEAR_CHALLENGE";

// Form Builder
export const ADD_NEW_FIELD = "ADD_NEW_FIELD";
export const CHANGE_FIELD = "CHANGE_FIELD";
export const DELETE_FIELD = "DELETE_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const INSERT_FIELD = "INSERT_FIELD";
export const RESET_FIELD = "RESET_FIELD";
export const UPDATE_FORM_TITLE = "UPDATE_FORM_TITLE";
export const UPDATE_FORM_DESCRIPTION = "UPDATE_FORM_DESCRIPTION";
export const CLEAR_CUSTOM_FORM = "CLEAR_CUSTOM_FORM";
export const SAVE_CUSTOM_FORM = "SAVE_CUSTOM_FORM";
export const FETCH_CUSTOM_FORM = "FETCH_CUSTOM_FORM";

export const GET_CHALLENGE_SUBMISSION_CUSTOM_FORM =
  "GET_CHALLENGE_SUBMISSION_CUSTOM_FORM";
export const SAVE_SUBMISSION_CUSTOM_FORM = "SAVE_SUBMISSION_CUSTOM_FORM";
export const SUBMIT_CUSTOM_FORM = "SUBMIT_CUSTOM_FORM";

// Group
export const GET_GROUP_BY_ID = "GET_GROUP_BY_ID";
export const LOADING_GROUP = "LOADING_GROUP";
export const ERROR_GROUP = "ERROR_GROUP";
export const GET_ALL_GROUPS = "GET_ALL_GROUPS";
export const LOADING_ALL_GROUPS = "LOADING_ALL_GROUPS";
export const ERROR_ALL_GROUPS = "ERROR_ALL_GROUPS";
export const DELETE_GROUP_BY_ID = "DELETE_GROUP_BY_ID";
export const ADD_EDIT_GROUPS = "ADD_EDIT_GROUPS";
export const GROUP_LOADING_SUBMISSIONS = "GROUP_LOADING_SUBMISSIONS";
export const GROUP_GET_SUBMISSIONS = "GROUP_GET_SUBMISSIONS";
export const GROUP_ERROR_SUBMISSIONS = "GROUP_ERROR_SUBMISSIONS";
export const GROUP_LOADING_JUDGES = "GROUP_LOADING_JUDGES";
export const GROUP_GET_JUDGES = "GROUP_GET_JUDGES";
export const GROUP_ERROR_JUDGES = "GROUP_ERROR_JUDGES";

// COMMUNITY
export const LOADING_COMMUNITY_SETTINGS = "LOADING_COMMUNITY_SETTINGS";
export const GET_COMMUNITY_SETTINGS = "GET_COMMUNITY_SETTINGS";
export const ERROR_COMMUNITY_SETTINGS = "ERROR_COMMUNITY_SETTINGS";
export const CLEAR_COMMUNITY_SETTING = "CLEAR_COMMUNITY_SETTING";
export const LOADING_COMMUNITY_MEMBERS = "LOADING_COMMUNITY_MEMBERS";
export const GET_ALL_MEMBERS = "GET_ALL_MEMBERS";
export const APPROVE_MEMBER = "APPROVE_MEMBER";
// room
export const GET_POSTS = "GET_POSTS";
export const LOADING_POSTS = "LOADING_POSTS";
export const CLEAR_POSTS_KEYS = "CLEAR_POSTS_KEYS";
export const ERROR_POSTS = "ERROR_POSTS";
export const UPDATE_REACTION_POSTS = "UPDATE_REACTION_POSTS";
