import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// Icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// Components
import Can from "../../../shared/Can";
import { USER_TYPE } from "../../../utils/globalValues";

const ProfileNav = ({ user, profile }) => {
  return (
    <Can
      role={USER_TYPE.STUDENT}
      perform="profile:view-go-back-dashboard"
      data={{
        userId: user && user.id,
        profileId: profile && profile.id,
      }}
      yes={() => (
        <Box mx={2} width="100%">
          <Box
            p={1}
            bgcolor="primary.main"
            color="white"
            boxShadow={2}
            borderRadius="borderRadius"
          >
            <Container maxWidth="lg">
              You are currently viewing your public profile.{" "}
              <Link to="/me">
                Edit my profile <ArrowForwardIcon />
              </Link>
            </Container>
          </Box>
        </Box>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  user: state.auth.user,
});

export default connect(mapStateToProps)(ProfileNav);
