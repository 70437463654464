import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
// Redux
import { setPrizeSetting } from "../../../redux/actions/challenge";
// Components
import ChallengePrizeDialog from "./ChallengePrizeDialog";
import OrgAppBar from "../../../shared/OrgAppBar";
import Button from "../../../shared/Button";
// Icons
import SettingsIcon from "@material-ui/icons/Settings";

const ChallengePrizeActions = ({ challenge, setPrizeSetting }) => {
  const [openSettings, setOpenSettings] = React.useState(false);
  const [openAddPrizeDialog, setOpenAddPrizeDialog] = useState(false);
  const [prizeState, setPrizeState] = useState({
    isPrizePublic: false,
  });

  useEffect(() => {
    if (challenge) {
      setPrizeState({
        isPrizePublic: challenge.isPrizePublic,
      });
    }
  }, [challenge]);

  const handlePrizePrivacyClick = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  return (
    <>
      <ChallengePrizeDialog
        open={openAddPrizeDialog}
        setOpen={setOpenAddPrizeDialog}
      />
      <Dialog
        open={openSettings}
        onClose={handleCloseSettings}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Privacy Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Public awards are available for everyone to view. Private awards are
            viewable only within your organization.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={prizeState.isPrizePublic}
                onChange={() =>
                  setPrizeSetting(!prizeState.isPrizePublic, challenge.id)
                }
                name="isPublic"
                color="primary"
              />
            }
            label={<>{prizeState.isPrizePublic ? "Public" : "Private"}</>}
          />
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => setOpenAddPrizeDialog(true)}>
        Add new award
      </Button>
      <Tooltip title={"Set the privacy setting for the awards"} placement="top">
        <Button
          variant="text"
          color="primary"
          onClick={handlePrizePrivacyClick}
          startIcon={<SettingsIcon />}
        >
          Privacy Setting
        </Button>
      </Tooltip>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  prizes: state.challenge.prizes,
});

const mapDispatchToProps = { setPrizeSetting };

const ReduxChallengePrizeActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengePrizeActions);

const ChallengePrizeAppBar = () => {
  return (
    <OrgAppBar
      // title="Program Awards"
      subtitle="Manage the awards for this program"
      actions={<ReduxChallengePrizeActions />}
    />
  );
};

export default ChallengePrizeAppBar;
