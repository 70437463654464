import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { getRegistrations } from "../../../../redux/actions/challenge";

import ProfileItem from "../../../../shared/ProfileItem";

const Profiles = ({ registrations }) => {
  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        {registrations &&
          registrations.length > 0 &&
          registrations.map((reg, i) => {
            const team = reg.team;
            if (!team) return;
            return (
              <Fragment key={i}>
                {team &&
                  team.members &&
                  team.members.length > 0 &&
                  team.members.map((member, j) => {
                    const profile = member.profile;
                    if (!profile || !profile.privacySetting) return;
                    const user = member.user;
                    const profileObj = {
                      ...profile,
                      user: {
                        ...user,
                      },
                    };
                    return (
                      <Grid key={j} item xs={12} md={6} lg={4}>
                        <ProfileItem profile={profileObj} team={team} />
                      </Grid>
                    );
                  })}
              </Fragment>
            );
          })}
      </Grid>
    </Box>
  );
};

const ChallengeRegistrations = ({
  loading,
  challenge,
  registrations,
  getRegistrations,
  auth,
}) => {
  const fetchRegistrations = async () => {
    await getRegistrations(challenge.id);
  };

  useEffect(() => {
    fetchRegistrations();
  }, [challenge]);

  const [showTeam, setShowTeam] = useState(false);

  return (
    <>
      {challenge.isRegistrationPublic ? (
        <>
          <Box>
            <Typography variant="body2" color="textSecondary">
              Only the people who have made their profiles public are displayed.
            </Typography>
            <Profiles registrations={registrations} />
          </Box>
        </>
      ) : (
        <Typography variant="body2" color="textSecondary">
          Registrations are not public at this time.
        </Typography>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  challenge: state.challenge.challenge,
  registrations: state.challenge.registrations,
  auth: state.auth,
});

const mapDispatchToProps = { getRegistrations };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeRegistrations);
