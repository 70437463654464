import React from "react";
// Connect
import { connect } from "react-redux";
// React router
import { Link } from "react-router-dom";
// hooks
import { useGetProfileForPopover } from "../../hooks/userAccount";
// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// Components
import Spinner from "../Spinner";
import Button from "../Button";
import CustomAvatar from "../CustomAvatar";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: 0,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    fontSize: theme.typography.h2.fontSize,
    objectFit: "cover",
    borderRadius: "50%",
  },
  tooltip: {
    tooltip: {},
  },
}));

const ProfilePopover = ({ userId, user, children }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const {
    isLoading,
    data: { data: profile } = { data: null },
    isError,
    error,
  } = useGetProfileForPopover(userId);

  const education = profile && profile.education && profile.education[0];

  let chatParams = new URLSearchParams();
  chatParams.append("user", userId);

  if (user) {
    chatParams.append("user", user.id);
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          placement="right-end"
          interactive
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          PopperProps={{
            disablePortal: true,
          }}
          style={{ cursor: "pointer" }}
          className={classes.tooltip}
          title={
            <Box
              bgcolor="background.paper"
              borderRadius="borderRadius"
              textAlign="left"
              p={2}
              width={350}
            >
              {isLoading ? (
                <Spinner />
              ) : isError ? (
                <Alert severity="error">{error.data.msg}</Alert>
              ) : profile ? (
                <>
                  <Box display="flex" alignItems="start">
                    <CustomAvatar
                      className={classes.avatar}
                      src={profile.user.avatar}
                      fallbackText={profile.user.userName}
                      alt={`avatar-${profile.user.userName}`}
                    />
                    <Box
                      px={2}
                      textAlign="left"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      <Typography variant="h5" component="div">
                        <Box fontWeight={600}>
                          {profile.firstName} {profile.lastName}
                        </Box>
                      </Typography>
                      {profile.aboutMe && (
                        <Typography
                          variant="body1"
                          component="div"
                          color="textSecondary"
                        >
                          <Box
                            lineHeight={1.2}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {profile.aboutMe}
                          </Box>
                        </Typography>
                      )}

                      {profile.location && (
                        <Typography
                          variant="body1"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>{profile.location}</Box>
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box mt={1}>
                    {education && (
                      <Box>
                        <Typography component="div">
                          <Box lineHeight={1.2}>
                            {education.school},{" "}
                            {education.completionDate &&
                              new Date(education.completionDate).getFullYear()}
                          </Box>
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          component="div"
                        >
                          <Box lineHeight={1.2}>
                            {education.degree} {education.fieldOfStudy}
                          </Box>
                        </Typography>
                      </Box>
                    )}
                    {profile.skillSets && profile.skillSets.selectedSkills && (
                      <Typography variant="body2" component="div">
                        <Box mt={1} lineHeight={1.2}>
                          {profile.skillSets.selectedSkills
                            .map((elem) => elem.name)
                            .join(", ")}
                        </Box>
                      </Typography>
                    )}
                  </Box>

                  <Box
                    mt={1}
                    display="flex"
                    alignItems="center"
                    style={{ gap: "8px" }}
                  >
                    <Button
                      component={Link}
                      to={`/@${profile.user.userName}`}
                      fullWidth
                      variant="outlined"
                      color="default"
                    >
                      View Profile
                    </Button>
                    {/* {user && user.id !== userId && (
                      <Button
                        component={Link}
                        to={`/chats/new?${chatParams.toString()}`}
                        fullWidth
                        variant="contained"
                      >
                        Message
                      </Button>
                    )} */}
                  </Box>
                </>
              ) : (
                <Box p={2}>This user has not completed their profile.</Box>
              )}
            </Box>
          }
        >
          <Box onClick={handleTooltipOpen}>{children}</Box>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePopover);
