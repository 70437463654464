import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Components
import ProfileProjectCard from "./ProfileProjectCard";
// Material UI
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const ProfileProjects = ({ profile }) => {
  return (
    <Fragment>
      {profile && profile.projects && profile.projects.length > 0 && (
        <>
          <Box my={{ xs: 2, md: 4 }}>
            <Divider light />
          </Box>
          <Box>
            <Box mb={{ xs: 2 }}>
              <Typography variant="h5" component="div">
                <Box px={2} fontWeight={600}>
                  Projects
                </Box>
              </Typography>
            </Box>
            <Grid
              container
              direction="row"
              alignItems="stretch"
              justify="flex-start"
            >
              {profile.projects.map((project, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <ProfileProjectCard project={project} key={project.id} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </>
      )}
    </Fragment>
  );
};

ProfileProjects.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProjects);
