import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

const TextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  ...textFieldProps
}) => {
  const _onChange = ({ target: { value } }) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  const inputType =
    (type || schema.type) === "string" ? "text" : `${type || schema.type}`;

  return (
    <>
      <Box display={disabled ? "none" : "block"} width="100%">
        <TextField
          id={id}
          placeholder={placeholder}
          label={null}
          fullWidth
          autoFocus={autofocus}
          required={required}
          disabled={disabled || readonly}
          type={inputType}
          value={value || value === 0 ? value : ""}
          error={rawErrors.length > 0}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          {...textFieldProps}
        />
      </Box>
      {disabled && (
        <Box>
          <Typography variant="body2" color="textPrimary">
            <em>{value}</em>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default TextWidget;
