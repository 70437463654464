import { useCallback, useState, useEffect } from "react";
import { usePwa } from "@dotmind/react-use-pwa";
import { IoDownloadOutline, IoLogoApple, IoLogoWindows } from "react-icons/io5";
// Material UI
import Tooltip from "@material-ui/core/Tooltip";
// Components
import Button from "../Button";

const InstallPWA = () => {
  const { installPrompt, isInstalled, isStandalone, isOffline, canInstall } =
    usePwa();

  const [OS, setOS] = useState(null);

  useEffect(() => {
    if (navigator.userAgent.match(/Windows/i)) {
      // Do Windows related stuff
      setOS("WINDOWS");
    } else if (navigator.userAgent.match(/Mac/i)) {
      // Do macOS related stuff
      setOS("MAC");
    }
  });

  const handleInstallPrompt = useCallback(() => {
    if (canInstall) {
      installPrompt();
    }
  }, [canInstall, installPrompt]);

  if (isOffline) {
    return null;
  }

  if (canInstall && (!isInstalled || !isStandalone)) {
    return (
      <Tooltip title="Download to your device">
        <Button
          onClick={handleInstallPrompt}
          startIcon={
            OS === "MAC" ? (
              <IoLogoApple />
            ) : OS === "WINDOWS" ? (
              <IoLogoWindows />
            ) : (
              <IoDownloadOutline />
            )
          }
        >
          Download
        </Button>
      </Tooltip>
    );
  }

  return null;
};

export default InstallPWA;
