import React from "react";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
  },
}));

const CustomAvatar = (props) => {
  const classes = useStyles();
  const { className, src, alt, fallbackText, ...other } = props;

  const letter =
    typeof fallbackText === "string" && fallbackText.length > 0
      ? fallbackText.toUpperCase().slice(0, 1)
      : "P";

  return (
    <Avatar
      src={src}
      alt={fallbackText}
      className={clsx(classes.root, className)}
      {...other}
    >
      {letter}
    </Avatar>
  );
};

export default CustomAvatar;
