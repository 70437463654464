import axios from "../utils/axios";
import { useQuery, useMutation } from "react-query";

export const useGetAllChallengesForCommunity = (companyDetails) => {
  const result = useQuery(
    [
      "communityPrograms",
      {
        companyId: companyDetails.id,
      },
    ],
    getAllChallengesForCommunity,
    {
      enabled: !!companyDetails,
    }
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const getAllChallengesForCommunity = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/challenge/community/all/id/${companyId}`);
};

export const useGetAllChallengesForUser = () => {
  const result = useQuery(["userPrograms"], getAllChallengesForUser);
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const getAllChallengesForUser = async ({ queryKey }) => {
  const [_key] = queryKey;
  return await axios.get(`/api/challenge/all/myRegistrations`);
};
