import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0),
    backgroundColor: "transparent",
  },
  flexGrow: {
    flexGrow: 1,
  },
}));

// Need to create actions

const OrgAppBar = ({ title, subtitle, actions }) => {
  const classes = useStyles();
  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.flexGrow}>
          {title && (
            <Typography variant="h5" component="div">
              <Box fontWeight={600}>{title}</Box>
            </Typography>
          )}
          {subtitle && (
            <Typography variant="body2" component="div" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          {actions}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default OrgAppBar;
