import { SERVER_ERROR, CLOSE_SERVER_ERROR } from "./actionTypes";
import { setAlert } from "./alert";

export const handleServerError = (error) => async (dispatch) => {
  if (error && error.status === 500) {
    await dispatch({ type: SERVER_ERROR });
  } else if (error && error.data) {
    const errors = error.data.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "error")));
    } else {
      await dispatch(
        setAlert(
          error.data.msg ||
            "There was an error with our system. Please try again",
          "error"
        )
      );
    }
  }
};

export const closeServerError = () => async (dispatch) => {
  await dispatch({
    type: CLOSE_SERVER_ERROR,
  });
};
