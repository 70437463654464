import React, { useState } from "react";
// react query
import { useGetAllJobsInJobBoard } from "../../../hooks/job";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Spinner from "../../../shared/Spinner";
import Button from "../../../shared/Button";
import OrgAppBar from "../../../shared/OrgAppBar";
import EmptyState from "../../../shared/EmptyState";
import AddRemoveJobPostDialog from "../../../shared/AddRemoveJobPostDialog";
import ExternalJobCard from "./ExternalJobCard";

export const JobsView = ({ jobBoard, companyProfile, jobCategoryType }) => {
  const { isLoading, data, isError, error } = useGetAllJobsInJobBoard(
    companyProfile.id
  );

  const [openJobPostDialog, setJobPostDialog] = useState(false);

  const JobsViewActions = () => {
    return (
      <>
        <Button color="primary" onClick={() => setJobPostDialog(true)}>
          Add / Remove My Jobs
        </Button>
      </>
    );
  };

  return (
    <>
      <Box width="100%">
        {jobBoard && jobBoard.data ? (
          <>
            <OrgAppBar
              title={null}
              subtitle={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" component="div">
                    <Box mr={1}>
                      {data && data.posts ? data.posts.length : 0} Jobs(s)
                    </Box>
                  </Typography>
                </Box>
              }
              actions={<JobsViewActions />}
            />
            <AddRemoveJobPostDialog
              open={openJobPostDialog}
              setOpen={setJobPostDialog}
              companyId={companyProfile.id}
              jobBoard={jobBoard.data}
            />
            {isLoading ? (
              <Spinner />
            ) : data && data.posts && data.posts.length > 0 ? (
              <>
                {data.posts.map((job) => {
                  const foundJobCategory =
                    jobCategoryType &&
                    jobCategoryType.find((elem) => elem.id === job.typeId);
                  const jobCategory =
                    foundJobCategory && foundJobCategory.jobType;

                  const modifiedJob = {
                    ...job,
                    jobCategory: jobCategory,
                  };

                  return (
                    <ExternalJobCard key={modifiedJob.id} job={modifiedJob} />
                  );
                })}
              </>
            ) : (
              <EmptyState
                title="No job postings yet"
                subtitle="Manage all of the job postings in your job board."
              />
            )}
          </>
        ) : (
          <EmptyState
            title="No job board"
            subtitle="Create a job board first before adding any jobs"
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  jobBoard: state.jobs.jobBoard,
  companyProfile: state.company.companyProfile,
  jobCategoryType: state.typedef.jobCategoryType,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobsView);
