import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
//import { PageView, initGA } from "./utils/tracking";
//import * as FullStory from "@fullstory/browser";
//import smartlookClient from "smartlook-client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// Sentry
import * as Sentry from "@sentry/browser";
//import ReactGA from "react-ga";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

// if (process.env.REACT_APP_FULL_STORY_ENV === "production") {
//   FullStory.init({ orgId: process.env.REACT_APP_FULL_STORY_ORG_ID });
// }

// if (process.env.REACT_APP_SMARTLOOK_ENV === "production") {
//   smartlookClient.init(process.env.REACT_APP_SMARTLOOK_PROJECT_KEY);
// }

// initGA();
// PageView();
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
