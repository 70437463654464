import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
// Material UI
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";

const Interests = ({ profile, typedef }) => {
  const questionsAnswersTypedef = typedef.questionsAnswers;

  const [displayInterests, setDisplayInterests] = useState(false);

  useEffect(() => {
    profile.questionsAnswers.map((val) => {
      const { questionId, answers } = val;
      const questionFound = questionsAnswersTypedef.find(
        (elem) => elem.id === questionId
      );
      if (!questionFound) return;
      if (!answers || answers.length <= 0) return;

      setDisplayInterests(true);
    });
  }, [profile]);
  return (
    <Fragment>
      {profile &&
        profile.questionsAnswers &&
        profile.questionsAnswers.length > 0 &&
        displayInterests && (
          <Box>
            <Box my={{ xs: 2, md: 4 }}>
              <Divider light />
            </Box>

            <Box mb={{ xs: 2 }}>
              <Typography variant="h5" component="div">
                <Box px={2} fontWeight={600}>
                  Interests
                </Box>
              </Typography>
            </Box>

            <div>
              {profile.questionsAnswers.map((val) => {
                const { questionId, answers } = val;
                const questionFound = questionsAnswersTypedef.find(
                  (elem) => elem.id === questionId
                );
                if (!questionFound) return;
                const possibleAnswers = questionFound.questionAnswer;
                if (!answers || answers.length <= 0) return;
                return (
                  <Box width="100%" pb={3} px={2} key={questionId}>
                    <Typography variant="body1" component="div" gutterBottom>
                      <Box fontWeight={500} lineHeight={1.2}>
                        {questionFound.description || ""}
                      </Box>
                    </Typography>

                    <Box mt={1.5} display="flex" flexWrap="wrap">
                      {answers &&
                        answers.length > 0 &&
                        answers.map((id, index) => {
                          const label = possibleAnswers.find(
                            (ans) => ans.id === id
                          );
                          if (!label) return;
                          return (
                            <Chip
                              key={index}
                              color="default"
                              label={
                                <Typography variant="caption">
                                  {label.answer}
                                </Typography>
                              }
                            />
                          );
                        })}
                    </Box>
                  </Box>
                );
              })}
            </div>
          </Box>
        )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
  typedef: state.typedef,
});

export default connect(mapStateToProps)(Interests);
