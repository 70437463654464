import React from "react";
// utils
import ReactImageFallback from "react-image-fallback";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    objectFit: "cover",
  },
}));

const EventHeaderImage = ({ event }) => {
  const classes = useStyles();
  return (
    <Box
      mb={1}
      style={{
        position: "relative",
        paddingBottom: "56.2%",
      }}
    >
      <ReactImageFallback
        src={event.headerImagePath}
        fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
        alt={`${event.title}-header-image`}
        className={classes.root}
      />
    </Box>
  );
};

export default EventHeaderImage;
