import request from "./request";

const get = function get(url) {
  return request({
    method: "GET",
    url: url,
  });
};

const post = function post(url, data, headers) {
  return request({
    method: "POST",
    url: url,
    data: data,
    config: headers,
  });
};

const put = function put(url, data, headers) {
  return request({
    method: "PUT",
    url: url,
    data: data,
    config: headers,
  });
};

const del = function del(url) {
  return request({
    method: "DELETE",
    url: url,
  });
};

/* const setAuthToken = (token) => {
  let headers = {};
  if (token) {
    headers["x-auth-token"] = token;
  } else {
    headers["x-auth-token"] = null;
  }
  console.log("headers:", headers);
  // For google cloud run intergation and authentication
  //console.log("Inside setAuthToken");
  return request({
    headers: headers,
  });
}; */

const apiRequest = {
  get,
  post,
  put,
  del,
  //setAuthToken,
};

export default apiRequest;
