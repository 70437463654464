import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
// React Router
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../redux/actions/alert";
// react query
import { useMutation, useQueryClient } from "react-query";
import { useGetAllPublicMembers, updateIsPublic } from "../../hooks/community";
import { useCommunityMemberContext } from "../../layouts/Community/components/CommunityContext";
// Material UI
import { makeStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
// Icons
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { IoPeopleOutline } from "react-icons/io5";
// Components
import EmptyState from "../../shared/EmptyState";
import Button from "../../shared/Button";
import Spinner from "../../shared/Spinner";
import ProfileItem from "../../shared/ProfileItem";
import { animateScroll as scroll } from "react-scroll";

const MAX_PROFILES_PER_PAGE = 48;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CommunityMembers = ({ companyDetailsQuery, setAlert }) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const topRef = useRef(null);
  const handleChangePage = (event, value) => {
    setPage(value);
    topRef.current.scrollIntoView();
  };

  const queryClient = useQueryClient();

  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const communityMemberContext = useCommunityMemberContext();
  let communityMember;
  if (
    communityMemberContext &&
    communityMemberContext.data &&
    communityMemberContext.data.data
  ) {
    communityMember = communityMemberContext.data.data;
  }

  const {
    isLoading: isLoadingMembers,
    data: communityMembers = null,
    isError: isErrorMembers,
    error: errorMembers,
  } = useGetAllPublicMembers(companyDetails);

  const updateIsPublicMutation = useMutation(updateIsPublic, {
    onSuccess: async (data, variables) => {
      if (data && data.data) {
        await setAlert(
          `Successfully ${
            variables.isPublicToCommunity ? "added" : "hid"
          } your profile on the members directory.`,
          "success"
        );
        if (variables.isPublicToCommunity) {
          await queryClient.setQueryData(
            ["communityMembers", { companyId: companyDetails.id }],
            (prevMembers) => {
              if (prevMembers) {
                return [data.data, ...prevMembers];
              }
            }
          );
        } else {
          await queryClient.setQueryData(
            ["communityMembers", { companyId: companyDetails.id }],
            (prevMembers) => {
              if (prevMembers) {
                return prevMembers.filter((elem) => elem.id !== data.data.id);
              }
            }
          );
        }
        await queryClient.setQueryData(
          [
            "communityMember",
            { companyId: companyDetails && companyDetails.id },
          ],
          (prevCommunityMember) => {
            if (prevCommunityMember && prevCommunityMember.data) {
              return {
                ...prevCommunityMember,
                data: {
                  ...prevCommunityMember.data,
                  isPublicToCommunity: variables.isPublicToCommunity,
                },
              };
            }
          }
        );
      }
    },
  });

  const handleUpdateIsPublic = () => {
    if (communityMember) {
      updateIsPublicMutation.mutate({
        companyId: companyDetails.id,
        isPublicToCommunity: !communityMember.isPublicToCommunity,
      });
    }
  };

  return (
    <Box height="100%">
      <Backdrop
        className={classes.backdrop}
        open={updateIsPublicMutation.isLoading}
      >
        <CircularProgress color="inherit" disableShrink />
      </Backdrop>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container maxWidth="xl" style={{ height: "100%" }}>
          <Box py={2} px={{ xs: 0, md: 2 }} height="100%">
            {isErrorMembers && (
              <Alert severity="error">
                {errorMembers && errorMembers.data && errorMembers.data.msg}
              </Alert>
            )}
            {isLoadingMembers ? (
              <Spinner />
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  ref={topRef}
                >
                  <Typography variant="h5" component="h2">
                    <Box
                      mb={2}
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                      style={{ gap: "8px" }}
                    >
                      <IoPeopleOutline /> Members
                    </Box>
                  </Typography>
                  {communityMember && (
                    <Tooltip
                      title={
                        communityMember.isPublicToCommunity
                          ? "Hide your profile from other community members"
                          : "Add your profile for other community members to view"
                      }
                    >
                      <Button
                        onClick={handleUpdateIsPublic}
                        variant="text"
                        color="default"
                        startIcon={
                          communityMember.isPublicToCommunity ? (
                            <RemoveCircleOutlineOutlinedIcon />
                          ) : (
                            <AddOutlinedIcon />
                          )
                        }
                      >
                        {communityMember.isPublicToCommunity
                          ? "Hide Profile"
                          : "Add Profile"}
                      </Button>
                    </Tooltip>
                  )}
                </Box>
                <Box height="100%" width="100%">
                  {communityMembers && communityMembers.length > 0 ? (
                    <>
                      <Grid container spacing={2}>
                        {communityMembers
                          .slice(
                            (page - 1) * MAX_PROFILES_PER_PAGE,
                            page * MAX_PROFILES_PER_PAGE
                          )
                          .map((member) => {
                            if (!member.profile) return null;
                            const profile = {
                              ...member.profile,
                              user: member.user,
                            };

                            return (
                              <Grid
                                key={member.id}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                xl={3}
                              >
                                <ProfileItem profile={profile} />
                              </Grid>
                            );
                          })}
                      </Grid>
                      <Box
                        py={6}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Pagination
                          count={Math.ceil(
                            communityMembers.length / MAX_PROFILES_PER_PAGE
                          )}
                          page={page}
                          onChange={handleChangePage}
                        />
                      </Box>
                    </>
                  ) : (
                    <EmptyState
                      title="No members in the directory"
                      subtitle="There are no members in the member directory at this time."
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Container>
      )}
    </Box>
  );
};

CommunityMembers.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(CommunityMembers);
