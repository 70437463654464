import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addExperience, deleteExperience } from "../../redux/actions/profile";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Button from "../Button";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: "0",
  },
  appBar: {
    position: "relative",
    boxShadow: "none",
    // backgroundColor: theme.palette.background.main,
    // color: theme.palette.dark.main,
  },
  button: {
    fontWeight: "600",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    fontWeight: "600",
    color: theme.palette.error.main,
  },
}));

const ExperienceForm = ({ addExperience, experience, deleteExperience }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    jobTitle: "",
    location: "",
    startDate: new Date(),
    endDate: new Date(),
    isCurrentJob: false,
    description: "",
  });
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [modal, toggleModal] = useState(false);
  const [editing, handleEdit] = useState(false);

  useEffect(() => {
    if (experience) {
      setFormData({
        companyName: experience.companyName,
        jobTitle: experience.jobTitle,
        location: experience.location,
        startDate: experience.startDate && new Date(experience.startDate),
        endDate: experience.endDate && new Date(experience.endDate),
        isCurrentJob: experience.isCurrentJob,
        description: experience.description,
      });
      handleEdit(true);
    }
  }, [experience]);

  const toggle = () => {
    toggleModal(!modal);
  };

  const {
    companyName,
    jobTitle,
    location,
    startDate,
    endDate,
    isCurrentJob,
    description,
  } = formData;
  const onChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setFormData({ ...formData, [name]: value });
  };

  const clearExperienceState = () => {
    setFormData({
      ...formData,
      companyName: "",
      jobTitle: "",
      location: "",
      startDate: new Date(),
      endDate: new Date(),
      isCurrentJob: false,
      description: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (editing) {
      formData.id = experience.id;
      addExperience(formData);
    } else {
      addExperience(formData);
    }
    clearExperienceState();
    toggle();
  };
  return (
    <Fragment>
      {editing ? (
        <Fragment>
          <Button
            variant="text"
            color="default"
            startIcon={<EditIcon />}
            size={"small"}
            onClick={toggle}
          >
            Edit
          </Button>
        </Fragment>
      ) : (
        <Button startIcon={<AddOutlinedIcon />} onClick={toggle}>
          Add Experience
        </Button>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={modal}
        onClose={toggle}
        aria-labelledby="responsive-dialog-title"
      >
        <form onSubmit={(e) => onSubmit(e)}>
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={toggle}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Typography component="div" variant="h6">
                <Box fontWeight={600}>
                  {editing ? "Edit Experience" : "New Experience"}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Job Title</InputLabel>
                  <TextField
                    id={"jobTitle"}
                    fullWidth
                    margin={"dense"}
                    autoComplete={"job"}
                    variant={"outlined"}
                    type="text"
                    name="jobTitle"
                    required
                    value={jobTitle}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Company / Organization</InputLabel>

                  <TextField
                    id={"companyName"}
                    fullWidth
                    margin={"dense"}
                    autoComplete={"company"}
                    variant={"outlined"}
                    type="text"
                    name="companyName"
                    required
                    value={companyName}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Location</InputLabel>

                  <TextField
                    id={"location"}
                    fullWidth
                    margin={"dense"}
                    autoComplete={"location"}
                    variant={"outlined"}
                    type="text"
                    name="location"
                    required
                    value={location}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                  />
                </FormGroup>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormGroup>
                  <InputLabel>Start Date</InputLabel>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      variant="inline"
                      inputVariant="outlined"
                      openTo="year"
                      views={["year", "month"]}
                      value={startDate}
                      onChange={(date) =>
                        setFormData({ ...formData, ["startDate"]: date })
                      }
                      margin={"dense"}
                      className={classes.input}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormGroup>
                  <InputLabel>End Date</InputLabel>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      variant="inline"
                      inputVariant="outlined"
                      openTo="year"
                      views={["year", "month"]}
                      value={endDate}
                      onChange={(date) =>
                        setFormData({ ...formData, ["endDate"]: date })
                      }
                      margin={"dense"}
                      className={classes.input}
                      disabled={isCurrentJob}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isCurrentJob}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [e.target.name]: e.target.checked,
                          });
                          if (isCurrentJob) {
                            setFormData({
                              ...formData,
                              isCurrentJob: false,
                              endDate: new Date(),
                            });
                          } else {
                            setFormData({
                              ...formData,
                              isCurrentJob: true,
                              endDate: null,
                            });
                          }
                        }}
                        value={isCurrentJob}
                        color="secondary"
                        name={"isCurrentJob"}
                      />
                    }
                    label={"I am currently working at this position."}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Description</InputLabel>

                  <TextField
                    id={"description"}
                    fullWidth
                    margin={"dense"}
                    autoComplete={"description"}
                    variant={"outlined"}
                    type="text"
                    placeholder="Tell us a little bit about what you did at this position."
                    name="description"
                    required
                    multiline={true}
                    rows={8}
                    value={description}
                    onChange={(e) => onChange(e)}
                    className={classes.input}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </DialogContent>
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
            style={{ gap: "8px" }}
          >
            {editing && (
              <Button
                onClick={() => {
                  deleteExperience(experience.id);
                  toggle();
                }}
                variant="outlined"
              >
                Delete
              </Button>
            )}
            <Button type="submit">Save</Button>
          </Box>
        </form>
      </Dialog>
    </Fragment>
  );
};

ExperienceForm.propTypes = {
  addExperience: PropTypes.func.isRequired,
  deleteExperience: PropTypes.func.isRequired,
  experience: PropTypes.object,
};

export default connect(null, { addExperience, deleteExperience })(
  withRouter(ExperienceForm)
);
