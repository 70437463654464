import React, { useState } from "react";
// Material UI
import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
// Components
import OrgAppBar from "../../../shared/OrgAppBar";
import CommunityMemberAnalyticsSignups from "./CommunityMemberAnalyticsSignups";
import CommunityMemberAnalyticsDemographics from "./CommunityMemberAnalyticsDemographics";
// Icons
import { IoAnalyticsOutline } from "react-icons/io5";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      my={2}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const CommunityMemberAnalytics = () => {
  // State for tabs
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <OrgAppBar
        title={
          <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
            <IoAnalyticsOutline /> Insights
          </Box>
        }
        subtitle="Dive deeper into who is engaging in your community"
      />
      <Tabs value={value} onChange={handleChangeTab}>
        <Tab label="Member Signups" />
        <Tab label="Demographics" />
      </Tabs>

      {/* Member Signups */}
      <TabPanel value={value} index={0}>
        <CommunityMemberAnalyticsSignups />
      </TabPanel>

      {/* Demographics Tab */}
      <TabPanel value={value} index={1}>
        <CommunityMemberAnalyticsDemographics />
      </TabPanel>
    </Box>
  );
};

export default CommunityMemberAnalytics;
