import React, { useState, useEffect } from "react";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// Icons
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ClearIcon from "@material-ui/icons/Clear";
// Components
import Button from "../../Button";
// Utils
import isUrl from "../../../utils/isUrl";

const EMBEDDABLE_GOOGLE = ["pdf", "doc", "docx", "xls", "xlsx", "pptx", "ppt"];
const IMAGE_DISPLAY = ["png", "jpg", "gif"];

const useStyles = makeStyles((theme) => ({
  Input: {
    display: "none",
  },
  InputLabel: {
    position: "initial",
    transform: "none",
  },
  Link: {
    fontSize: theme.typography.body2.fontSize,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

const FileWidget = (props) => {
  const classes = useStyles();

  const [valueIsUrl, setValueIsUrl] = useState(false);

  useEffect(() => {
    if (props && props.value) {
      const isPropsValueUrl = isUrl(props.value);
      setValueIsUrl(isPropsValueUrl);
    } else {
      setValueIsUrl(false);
    }
  }, [props]);

  const _onChange = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const fileToUpload = files[0];
      await clearFileUpload(e);
      await props.onChange(fileToUpload);
    }
  };

  const clearFileUpload = async (e) => {
    e.target.value = null;
    await props.onChange(null);
  };

  const ClearFile = () => {
    if (!props.disabled) {
      return (
        <IconButton onClick={clearFileUpload}>
          <ClearIcon />
        </IconButton>
      );
    } else {
      return <div />;
    }
  };

  const [googleEmbeddable, setGoogleEmbeddable] = useState(false);
  const [loadGoogleEmbeddable, setLoadGoogleEmbeddable] = useState(false);
  const [imageDisplay, setImageDisplay] = useState(false);

  useEffect(() => {
    if (props && props.value && valueIsUrl) {
      const split = props.value.split(".");
      if (split && split.length > 0) {
        const ext = split.pop();
        if (ext && EMBEDDABLE_GOOGLE && EMBEDDABLE_GOOGLE.includes(ext)) {
          setGoogleEmbeddable(true);
          setImageDisplay(false);
        } else if (ext && IMAGE_DISPLAY && IMAGE_DISPLAY.includes(ext)) {
          setImageDisplay(true);
          setGoogleEmbeddable(false);
        }
      }
    }
  }, [props, valueIsUrl]);

  return (
    <>
      {props.value instanceof File ? (
        <Box mb={1}>
          <Link
            href={URL.createObjectURL(props.value)}
            color="textSecondary"
            className={classes.Link}
            target="_blank"
          >
            <em>
              <AttachmentIcon /> New file - {props.value.name}
            </em>
          </Link>
          <ClearFile />
        </Box>
      ) : (
        props.value &&
        valueIsUrl && (
          <>
            {googleEmbeddable && (
              <Box mb={1}>
                <iframe
                  key={props.value}
                  width={"100%"}
                  onLoad={() => setLoadGoogleEmbeddable(true)}
                  height={loadGoogleEmbeddable ? 600 : 0}
                  frameBorder={0}
                  allowFullScreen="allowfullscreen"
                  src={`https://docs.google.com/viewer?url=${props.value}&embedded=true`}
                />
              </Box>
            )}

            {imageDisplay && (
              <Box mb={1} maxWidth={500}>
                <img src={props.value} style={{ objectFit: "cover" }} />
              </Box>
            )}

            <Box mb={1}>
              <Link
                href={props.value}
                color="primary"
                className={classes.Link}
                target="_blank"
              >
                <em>
                  <AttachmentIcon /> Uploaded file - {props.label}
                </em>
              </Link>
              <ClearFile />
            </Box>
          </>
        )
      )}

      {!props.disabled && (
        <Box>
          <Box>
            <input
              className={classes.Input}
              accept={props.options.accept}
              onChange={_onChange}
              id={props.id}
              name={props.id}
              type="file"
            />
            <label htmlFor={props.id}>
              <Button
                startIcon={<CloudUploadIcon />}
                variant="outlined"
                color="primary"
                component="span"
              >
                Upload
              </Button>
            </label>
          </Box>
          <Typography variant="caption" color="textSecondary">
            Upload {props.options.accept}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FileWidget;
