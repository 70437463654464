import React from "react";
// Components
import ChallengeTeam from "../../../../shared/ChallengeTeam";
import ChallengeViewMySubmission from "./ChallengeViewMySubmission";
// Material UI
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const ChallengeMyHome = () => {
  return (
    <Box>
      <ChallengeViewMySubmission />
      <Box my={{ xs: 2, md: 4 }}>
        <Divider light />
      </Box>
      <ChallengeTeam />
    </Box>
  );
};

export default ChallengeMyHome;
