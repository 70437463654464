import React, { Fragment } from "react";

import SkillsForm from "../../../shared/SkillsForm";

const Skills = () => {
  return (
    <>
      <SkillsForm />
    </>
  );
};

export default Skills;
