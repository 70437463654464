import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
// Colors
import blueGrey from "@material-ui/core/colors/blueGrey";
// Icons
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  logo: {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  socialIcons: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1),
  },
}));

const EventOrgCard = ({ company, primaryContactEmail }) => {
  const classes = useStyles();

  if (!company) {
    return <Box />;
  }
  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box mr={1}>
          <Avatar
            className={classes.logo}
            src={company.companyLogoPath}
            alt={company.companyName}
          />
        </Box>
        <Box>
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>Hosted by</Box>
          </Typography>
          <Typography variant="body1" component="div">
            <Box lineHeight={1} fontWeight={600}>
              {company.companyName}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box mt={1}>
        {company.companyDescription && (
          <Typography variant="body2" component="div" color="textSecondary">
            <Box lineHeight={1.2}>
              {company.companyDescription.substring(0, 200)}
              {company.companyDescription.length >= 200 && "..."}
            </Box>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default EventOrgCard;
