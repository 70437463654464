import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
// hooks
import { useFetchChallengeRegistration } from "../../../hooks/insights";
// Material UI
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
// Components
import Chart from "../../../shared/Chart";
import WeekPicker from "../../../shared/DatePickers/WeekPicker";
import DateRangePicker from "../../../shared/DatePickers/DateRangePicker";
// date fns
import startOfWeek from "date-fns/startOfWeek";
import addWeeks from "date-fns/addWeeks";
import format from "date-fns/format";

const ChallengeInsightsRegistrations = ({ challenge }) => {
  const theme = useTheme();
  // const [date, datePicker] = useState(startOfWeek(new Date()));
  const [dateRange, setDateRange] = useState({
    startDate: startOfWeek(new Date()),
    endDate: startOfWeek(addWeeks(new Date(), 1)),
  });

  const {
    isLoading,
    data: { data: data } = { data: null },
    isError,
    error,
  } = useFetchChallengeRegistration(challenge, dateRange);

  // Used for reponsive css in submissions/registrations converrts flexDirection from column to row
  // Returns boolean based on if the screen size is sm or less
  const isScreenSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box my={2}>
      <Box mb={2}>
        <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
        {/* <WeekPicker date={date} datePicker={datePicker} /> */}
      </Box>
      {isLoading ? (
        <Box mt={2}>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={400}
          />
        </Box>
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={9}>
            <Chart
              data={data?.registrationDateData}
              title={`Registrations`}
              subtitle={`${format(
                dateRange.startDate,
                "MMM dd, yyyy"
              )} - ${format(dateRange.endDate, "MMM dd, yyyy")}`}
              type="time-series"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box display="flex" flexDirection="column" style={{ gap: "16px" }}>
              <Chart
                type="number-title"
                data={[
                  {
                    count: data?.registrationsInTimePeriod,
                    name: "Registrations",
                    subText: `${format(
                      dateRange.startDate,
                      "MMM dd, yyyy"
                    )} - ${format(dateRange.endDate, "MMM dd, yyyy")}`,
                  },
                ]}
                orientation={isScreenSmOrSmaller ? "row" : "column"}
              />
              <Chart
                type="number-title"
                data={[
                  {
                    count: data?.totalParticipants,
                    name: "Total Partcipants",
                  },
                  {
                    count: data?.totalTeams,
                    name: "Total Teams",
                  },
                ]}
                orientation={isScreenSmOrSmaller ? "row" : "column"}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeInsightsRegistrations);
