import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  addUpdateChallenge,
  uploadHeaderImage,
  setPrimaryColorForChallenge,
} from "../../../redux/actions/challenge";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Tooltip from "@material-ui/core/Tooltip";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import isBefore from "date-fns/isBefore";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
// Helper Functions
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
// Constant values
import {
  MAX_PER_TEAM_OPTION,
  DEFAULT_TIME_ZONE,
} from "../../../utils/globalValues";
// Components
import Button from "../../../shared/Button";
import {
  convertTimeToUTC,
  formatToTimeZone,
  getActiveTimeZones,
} from "../../../utils/utilFunc";
// Material-ui-color
import { ColorPicker } from "material-ui-color";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  appBar: {
    boxShadow: "none",
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
  hiddenInput: {
    display: "none",
  },
}));

const EditChallengeModal = ({
  challenge,
  company,
  addUpdateChallenge,
  setAlert,
  uploadHeaderImage,
  setPrimaryColorForChallenge,
}) => {
  const classes = useStyles();
  const [openChallengeModal, setOpenChallengeModal] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [prevSlug, setPrevSlug] = useState(null);
  const history = useHistory();
  const [teamMemberOption, setTeamMemberOption] = useState(
    MAX_PER_TEAM_OPTION.TEAM
  );
  const [file, setFile] = useState("");
  const [headerImageUrl, setHeaderImageUrl] = useState("");
  const [modal, openModal] = useState(false);
  const [primaryColor, setPrimaryColor] = useState("#3861F6");

  const [challengeFormData, setChallengeFormData] = useState({
    title: "",
    slug: "",
    tagLine: "",
    totalPrizeValue: "",
    categoryTypes: "",
    location: "",
    isPublic: false,
    isActive: false,
    //numberOfRounds: 1,
    maxPeopleTeam: null,
    timeZone: DEFAULT_TIME_ZONE,
    companyId: null,
    registrationDeadlineDatetime: null,
  });

  useEffect(() => {
    if (challenge) {
      setPrevSlug(challenge.slug);
      setChallengeFormData({
        id: challenge.id,
        title: challenge.title,
        slug: challenge.slug,
        tagLine: challenge.tagLine,
        totalPrizeValue: challenge.totalPrizeValue,
        categoryTypes: challenge.categoryTypes,
        location: challenge.location,
        isPublic: challenge.isPublic === null ? false : challenge.isPublic,
        isActive: challenge.isActive === null ? false : challenge.isActive,
        maxPeopleTeam: challenge.maxPeopleTeam,
        companyId: company.id,
        //numberOfRounds: challenge.numberOfRounds,
        timeZone: challenge.timeZone,
        registrationDeadlineDatetime: formatToTimeZone(
          challenge.registrationDeadlineDatetime,
          challenge.timeZone
        ),
      });
      if (challenge.maxPeopleTeam && challenge.maxPeopleTeam === 1)
        setTeamMemberOption(MAX_PER_TEAM_OPTION.INDIVIDUAL);
      else setTeamMemberOption(MAX_PER_TEAM_OPTION.TEAM);
    }
  }, [challenge, openChallengeModal]);

  useEffect(() => {
    if (challenge && challenge.primaryColor)
      setPrimaryColor(challenge.primaryColor);
  }, [challenge]);

  const handleChallengeChange = (e) => {
    // e.preventDefault();

    let value;
    if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }
    if (e.target.name === "slug") {
      value = value.replace(/\s+/g, "-").toLowerCase();
    }
    setChallengeFormData({
      ...challengeFormData,
      [e.target.name]: value,
    });
  };

  const handleTeamMemberRadioChange = (e) => {
    setTeamMemberOption(e.target.value);
    if (e.target.value) {
      if (e.target.value === MAX_PER_TEAM_OPTION.INDIVIDUAL) {
        setChallengeFormData({
          ...challengeFormData,
          maxPeopleTeam: 1,
        });
      } else {
        setChallengeFormData({
          ...challengeFormData,
          maxPeopleTeam: null,
        });
      }
    }
  };

  const handleTimeZoneChange = (e) => {
    const timeZone = e.target.value ? e.target.value : DEFAULT_TIME_ZONE;
    setChallengeFormData({
      ...challengeFormData,
      timeZone: timeZone,
    });
  };

  const clearFormData = () => {
    setPrevSlug(null);
    setChallengeFormData({
      title: "",
      slug: "",
      tagLine: "",
      totalPrizeValue: "",
      categoryTypes: "",
      location: "",
      isPublic: false,
      isActive: false,
      maxPeopleTeam: null,
      timeZone: DEFAULT_TIME_ZONE,
      companyId: null,
      registrationDeadlineDatetime: null,
    });
  };

  const closeModal = () => {
    setHeaderImageUrl("");
    openModal(false);
  };

  const handlePrimaryColorChange = (color) => {
    if (color && color.css && color.css.backgroundColor) {
      // Save the selected primary color to the local state
      setPrimaryColor(color.css.backgroundColor);
      setPrimaryColorForChallenge(challenge.id, color.css.backgroundColor);
    }
  };

  const onChangeHeaderImage = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 5242880) {
        setHeaderImageUrl(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        openModal(true);
      } else {
        setAlert("Maximum file size 5MB exceeded", "error");
      }
    }
  };
  const onSubmitHeaderImage = () => {
    const formData = new FormData();
    formData.append("myFile", file);
    uploadHeaderImage(formData, company.id, challenge.id);
    closeModal();
  };

  const toggle = async () => {
    await setOpenChallengeModal(!openChallengeModal);
    // await clearFormData();
  };

  const validateChallengeInfo = () => {
    // Registration deadline is set in the timezone. First convert that to the current local time and use
    // that for comparison
    if (challengeFormData.registrationDeadlineDatetime) {
      const localRegistrationDeadlineDateTime = convertTimeToUTC(
        challengeFormData.registrationDeadlineDatetime,
        challengeFormData.timeZone
      );

      if (isBefore(localRegistrationDeadlineDateTime, new Date())) {
        setAlert(
          "Please select a registration deadline in the future",
          "error"
        );
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validated = await validateChallengeInfo();
    // Set the date time to correspond to the timezone
    // DateTimePicker always uses the local time
    // First convert the datetime to UTC time
    // assign the converted GMT date based on selected timezone

    challengeFormData.registrationDeadlineDatetime = convertTimeToUTC(
      challengeFormData.registrationDeadlineDatetime,
      challengeFormData.timeZone
    );

    if (validated) {
      const body = {
        ...challengeFormData,
      };

      const res = await addUpdateChallenge(body);

      if (res) {
        await setAlert("Successfully updated the program info", "success");
        await toggle();
        if (prevSlug && prevSlug !== challengeFormData.slug) {
          history.push(
            `/org/${company.orgId}/programs/${challengeFormData.slug}/details`
          );
        }
      }
    }
  };

  const {
    title,
    slug,
    tagLine,
    totalPrizeValue,
    categoryTypes,
    location,
    maxPeopleTeam,
    timeZone,
    companyId,
    registrationDeadlineDatetime,
  } = challengeFormData;

  return (
    <Box>
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>Edit program</Box>
            </Typography>
            <Typography variant="body2" component="div" color="textSecondary">
              <Box mb={2}>Welcome back, let's edit your program.</Box>
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {headerImageUrl !== "" && (
              <Dialog
                onClose={closeModal}
                aria-labelledby={"program-header-image-dialog"}
                open={modal}
                maxWidth={"md"}
                fullWidth={true}
              >
                <header>
                  <Toolbar>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Typography variant="h6" component="div">
                        <Box>Program Header Image</Box>
                      </Typography>
                      <Button color="primary" onClick={onSubmitHeaderImage}>
                        Upload
                      </Button>
                    </Box>
                  </Toolbar>
                </header>
                <DialogContent>
                  <img
                    alt={
                      headerImageUrl && headerImageUrl.charAt(0).toUpperCase()
                    }
                    src={headerImageUrl}
                  />
                </DialogContent>
              </Dialog>
            )}

            <InputLabel>
              Header Image (16x9 aspect ratio recommended)
            </InputLabel>
            <Box
              mb={1}
              style={{
                position: "relative",
                paddingBottom: "56.2%",
              }}
            >
              <Box
                style={{ objectFit: "cover" }}
                position="absolute"
                width="100%"
                height="100%"
                borderRadius="borderRadius"
                component="img"
                src={
                  challenge.headerImagePath instanceof File
                    ? URL.createObjectURL(challenge.headerImagePath)
                    : challenge.headerImagePath ||
                      "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                }
              />
            </Box>

            <input
              accept=".png, .jpg, .jpeg, .gif, .svg"
              className={classes.hiddenInput}
              id="header-image-file"
              multiple
              type="file"
              onChange={onChangeHeaderImage}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
            <Box display="flex" flexDirection="column">
              <Box>
                <label htmlFor="header-image-file">
                  <Button color="primary" component="span">
                    Upload Header Image
                  </Button>
                </label>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Title</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Make your program title standout
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter your program title"
                type="text"
                required
                onChange={handleChallengeChange}
                name="title"
                value={title}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Tagline</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Quick sentence about your program
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter a short tagline"
                type="text"
                required
                onChange={handleChallengeChange}
                name="tagLine"
                value={tagLine}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Custom Url</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        This is the ending url for your program (i.e.
                        carnegie-mellon-pitch-competition). Make sure there are
                        no spaces.
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter an url slug (your unique program url ending)"
                type="text"
                required
                onChange={handleChallengeChange}
                name="slug"
                value={slug}
                InputProps={{
                  startAdornment: (
                    <div className="font-semibold" position="start">
                      pralent.com/programs/
                    </div>
                  ),
                }}
                inputProps={{ pattern: "^[-a-zA-Z0-9@.+_]+$" }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Awards</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Enter the total value of the award or the actual award.
                        This could be a fellowship offer, monetary prize,
                        credits, etc.
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>

              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter your award(s)"
                type="text"
                onChange={handleChallengeChange}
                name="totalPrizeValue"
                value={totalPrizeValue}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Categories</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        For example, Hackathon, Pitch Competition, Machine
                        Learning, Design
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter the categories "
                type="text"
                onChange={handleChallengeChange}
                name="categoryTypes"
                value={categoryTypes}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Location</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Where will this program occur? Will it happen remotely?
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Enter the location (i.e. Philadelphia or Remote)"
                type="text"
                onChange={handleChallengeChange}
                name="location"
                value={location}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Time Zone</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Select the Time Zone to be used for this program. This
                        Time Zone will be effective and used for "Registration
                        Deadline Date Time", "Start Date Time", "End Date Time"
                        and "Late Submission Date Time" at all the program round
                        levels. If you change the Time Zone for the program,
                        please re-adjust the times based on the new Time Zone.
                      </p>
                      <p className="p-1 text-sm font-bold text-red-500 ">
                        If you change the Time Zone for the program, please
                        re-adjust all the times based on the new Time Zone.
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <Select
                labelId="timeZone-label"
                id="timeZone-select"
                value={timeZone}
                margin="dense"
                variant="outlined"
                className={classes.input}
                onChange={handleTimeZoneChange}
              >
                {getActiveTimeZones().map((tzName) => (
                  <MenuItem key={tzName} value={tzName}>
                    {tzName}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                <span className="mr-2">Registration Deadline Date & Time</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        When do you want registration to end? Leave blank if you
                        don't want to have a deadline.
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  name="registrationDeadlineDatetime"
                  inputVariant="outlined"
                  value={registrationDeadlineDatetime}
                  onChange={(date) => {
                    setChallengeFormData({
                      ...challengeFormData,
                      ["registrationDeadlineDatetime"]: date,
                    });
                  }}
                  onError={console.log}
                  className={classes.input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setChallengeFormData({
                              ...challengeFormData,
                              ["registrationDeadlineDatetime"]: null,
                            })
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel className="flex items-center">
                <span className="mr-2">Individual or Team</span>
                <Tooltip
                  title={
                    <Fragment>
                      <p className="p-1 text-sm font-normal">
                        Would you like to have teams with one or more members
                        per team or single person submissions?
                      </p>
                    </Fragment>
                  }
                  placement="right"
                >
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </InputLabel>
              <RadioGroup
                aria-label="teamSize"
                name="teamSize"
                value={teamMemberOption}
                onChange={handleTeamMemberRadioChange}
              >
                <FormControlLabel
                  value={MAX_PER_TEAM_OPTION.INDIVIDUAL}
                  control={<Radio />}
                  label={MAX_PER_TEAM_OPTION.INDIVIDUAL}
                />
                <FormControlLabel
                  value={MAX_PER_TEAM_OPTION.TEAM}
                  control={<Radio />}
                  label={MAX_PER_TEAM_OPTION.TEAM}
                />
              </RadioGroup>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <InputLabel>Primary Color</InputLabel>
              <Box display="flex" alignItems="center">
                <ColorPicker
                  deferred
                  hideTextfield
                  value={primaryColor}
                  onChange={handlePrimaryColorChange}
                />
                <Typography variant="body1" component="div">
                  <Box ml={1}>{primaryColor}</Box>
                </Typography>
              </Box>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button type="submit" color="primary">
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  company: state.company.companyProfile,
});

const mapDispatchToProps = {
  addUpdateChallenge,
  setAlert,
  uploadHeaderImage,
  setPrimaryColorForChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChallengeModal);
