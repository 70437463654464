import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import project from "./project";
import typedef from "./typedef";
import company from "./company";
import jobs from "./jobs";
import challenge from "./challenge";
import judge from "./judge";
import onboarding from "./onboarding";
import formBuilder from "./formBuilder";
import group from "./group";
import room from "./room";

export default combineReducers({
  alert,
  auth,
  profile,
  project,
  typedef,
  company,
  jobs,
  challenge,
  judge,
  onboarding,
  formBuilder,
  group,
  room,
});
