import React from "react";
// React router
import { Redirect, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// Components
import CustomAvatar from "../../shared/CustomAvatar";
import JoinCommunity from "../../shared/JoinCommunity";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    fontSize: "60px",
    borderRadius: 99999,
    boxShadow: theme.shadows[4],
    objectFit: "cover",
  },
}));

const CommunityInvite = ({ companyDetailsQuery, communityMemberQuery }) => {
  const { communitySlug } = useParams();

  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const memberInfo = communityMemberQuery?.data?.data;

  const classes = useStyles();

  if (memberInfo) {
    return <Redirect to={`/c/${communitySlug}`} />;
  }

  return (
    <>
      <Box
        textAlign="center"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box bgcolor="background.paper" width="100%" py={4}>
          <Container maxWidth="sm">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <CustomAvatar
                src={companyDetails.companyLogoPath}
                alt={companyDetails.companyName}
                fallbackText={companyDetails.companyName}
                className={classes.logo}
              />
              <Box mt={1}>
                <Typography variant="h4" component="h1" gutterBottom>
                  <Box fontWeight={700}>Join {companyDetails.companyName}</Box>
                </Typography>
              </Box>
              <Typography variant="body1" component="div" color="textSecondary">
                <Box whiteSpace="pre-line" lineHeight={1.2} fontWeight={400}>
                  {companyDetails.companyDescription}
                </Box>
              </Typography>
            </Box>
          </Container>
        </Box>
        <Box py={{ xs: 2, md: 4 }}>
          <JoinCommunity companyDetails={companyDetails} />
        </Box>
        <Box>
          <Typography variant="body1" color="textSecondary">
            Pralent is a platform that brings your community together in one
            place.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityInvite);
