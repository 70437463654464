import React, { useEffect, useState } from "react";
// Redux
// Redux
import { connect } from "react-redux";
import {
  getRegistrations,
  clearRegistrations,
  eliminateTeamFromChallenge,
} from "../../../redux/actions/challenge";
// Components
import PTable from "../../../shared/PTable";
import Spinner from "../../../shared/Spinner";
import Button from "../../../shared/Button";
import ProfilePopover from "../../../shared/ProfilePopover";
//Material UI
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
// Icons
import { IoMdMail } from "react-icons/io";
// Images

export const TeamView = ({
  loading,
  challenge,
  registrations,
  getRegistrations,
  clearRegistrations,
  eliminateTeamFromChallenge,
}) => {
  const eliminateTeam = (team) => {
    // Disqualify the team
    if (team) {
      if (
        window.confirm(
          `Are you sure you want to ${
            team.eliminatedFlag ? "reinstate" : "eliminate"
          } the team ${team.name}?`
        )
      ) {
        eliminateTeamFromChallenge(challenge.id, team.id, !team.eliminatedFlag);
      }
    }
  };
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headerCells = [
    {
      id: "id",
      label: "id",
      hide: true,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "teamName",
      label: "Team Name",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const teamName1 =
          param1 && param1.team && param1.team.name ? param1.team.name : "";
        const teamName2 =
          param2 && param2.team && param2.team.name ? param2.team.name : "";

        return teamName1.localeCompare(teamName2);
      },
    },
    {
      id: "teamMembers",
      label: "Team Members",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const cnt1 =
          param1 && param1.team && param1.team.members
            ? param1.team.members.length
            : 0;
        const cnt2 =
          param2 && param2.team && param2.team.members
            ? param2.team.members.length
            : 0;
        return cnt1 - cnt2;
      },
    },
    {
      id: "registrationDate",
      label: "Registration Date",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        let registeredDateTime1 = "";
        let registeredDateTime2 = "";
        if (param1 && param1.registeredDateTime)
          registeredDateTime1 = new Date(
            param1.registeredDateTime
          ).toLocaleDateString();
        if (param2 && param2.registeredDateTime)
          registeredDateTime2 = new Date(
            param2.registeredDateTime
          ).toLocaleDateString();

        return registeredDateTime1.localeCompare(registeredDateTime2);
      },
    },
    {
      id: "status",
      label: "Status",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const elimFlag1 =
          param1 && param1.team ? param1.team.eliminatedFlag : "";
        const elimFlag2 =
          param2 && param2.team ? param2.team.eliminatedFlag : "";
        const status1 = elimFlag1 ? "Reinstate" : "Eliminate";
        const status2 = elimFlag2 ? "Reinstate" : "Eliminate";

        return status1.localeCompare(status2);
      },
    },
    {
      id: "contact",
      label: "Contact",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
  ];

  function loadRowsColumns() {
    // Go through each headerCell and add the rows under each column
    let newCols = [];

    newCols.push({
      field: "id",
      renderCell: (data) => {
        //console.log("id:", data.id);
        return data.id;
      },
    });

    newCols.push({
      field: "teamName",
      renderCell: (data) => {
        //console.log("teamName:", data.team.name);
        return data.team && data.team.name ? data.team.name : "";
      },
    });

    newCols.push({
      field: "teamMembers",
      renderCell: (data) => {
        const team = data.team;
        return (
          <AvatarGroup max={8}>
            {team &&
              team.members &&
              team.members.length > 0 &&
              team.members.map((member, index) => {
                const user = member.user;

                return (
                  <ProfilePopover key={index} userId={user.id}>
                    <Avatar src={user.avatar} alt={user.userName} />
                  </ProfilePopover>
                );
              })}
          </AvatarGroup>
        );
      },
    });

    newCols.push({
      field: "registrationDate",
      renderCell: (data) => {
        const registrationDateTime = new Date(
          data.registeredDateTime
        ).toLocaleDateString();
        return registrationDateTime;
      },
    });

    newCols.push({
      field: "status",
      renderCell: (data) => {
        const team = data.team;
        return (
          <Tooltip
            placement="left"
            title="Eliminate this team from the program. Team members will no longer be able to submit, but will continue to have access to all their materials."
          >
            <Button
              onClick={() => eliminateTeam(team)}
              color={team.eliminatedFlag ? "primary" : "default"}
              variant="text"
            >
              {team.eliminatedFlag ? "Reinstate" : "Eliminate"}
            </Button>
          </Tooltip>
        );
      },
    });

    newCols.push({
      field: "contact",
      renderCell: (data) => {
        const team = data.team;
        if (!team) return;
        let teamEmails = [];
        team &&
          team.members &&
          team.members.length > 0 &&
          team.members.map((member) => {
            const user = member.user;
            if (user) {
              teamEmails.push(user.email);
            }
          });
        return (
          <Button
            href={`mailto:${teamEmails && teamEmails.join(",")}`}
            startIcon={<IoMdMail />}
            variant="text"
            color="default"
          >
            Mail
          </Button>
        );
      },
    });

    setColumns(newCols);
  }

  useEffect(() => {
    let active = true;

    if (registrations && registrations.length > 0) {
      setRecords(registrations);
      loadRowsColumns(registrations);
    }
    return () => {
      active = false;
    };
  }, [registrations]);

  useEffect(() => {
    if (challenge) {
      getRegistrations(challenge.id);
    }
    return () => clearRegistrations();
  }, [challenge]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Box height="100%" margin="auto">
        <PTable
          records={records}
          headerCells={headerCells}
          columns={columns}
          noRowsText="There are no registrations at this time."
        />
      </Box>
    </>
  );
};

TeamView.propTypes = {
  //registrations: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  registrations: state.challenge.registrations,
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {
  getRegistrations,
  clearRegistrations,
  eliminateTeamFromChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamView);
