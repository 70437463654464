import React from "react";
import PropTypes from "prop-types";
// react-query
import { useQuery } from "react-query";
import { getKnowledgeArticleById } from "../../hooks/knowledge";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Icons
// Components
import CustomAvatar from "../../shared/CustomAvatar";
import RichTextEditor from "../../shared/RichTextEditor";
import Spinner from "../../shared/Spinner";
// Utils
import { formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  lastUpdateByAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}));

const CommunityKnowledgeArticle = () => {
  const classes = useStyles();

  const { articleId } = useParams();

  const {
    isLoading,
    data: { data: knowledgeArticle } = { data: null },
    isError,
    error,
  } = useQuery(
    ["knowledgeArticle", { articleId: articleId }],
    getKnowledgeArticleById,
    {
      enabled: !!articleId,
    }
  );

  const lastUpdateByUser = knowledgeArticle?.lastUpdateByUser;

  console.log(isError, isLoading, knowledgeArticle);

  return (
    <Box className={classes.root} p={{ xs: 0, md: 2 }}>
      <Container maxWidth="sm">
        {isError && (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Typography variant="h3" component="h2">
              <Box lineHeight={1.2} fontWeight={600}>
                {knowledgeArticle.title}
              </Box>
            </Typography>
            {knowledgeArticle.lastUpdateDateTime && lastUpdateByUser && (
              <>
                <Box my={1} display="flex" alignItems="center">
                  <CustomAvatar
                    src={lastUpdateByUser.avatar}
                    alt={lastUpdateByUser.userName}
                    fallbackText={lastUpdateByUser.userName}
                    className={classes.lastUpdateByAvatar}
                  />
                  <Typography
                    variant="body1"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      Last updated by{" "}
                      {knowledgeArticle.lastUpdateByProfile
                        ? `${knowledgeArticle.lastUpdateByProfile.firstName}`
                        : lastUpdateByUser.userName}{" "}
                      {formatDistanceToNow(
                        new Date(knowledgeArticle.lastUpdateDateTime)
                      )}{" "}
                      ago
                    </Box>
                  </Typography>
                </Box>
              </>
            )}
            {knowledgeArticle.content && (
              <Box my={2}>
                <RichTextEditor
                  value={knowledgeArticle.content}
                  readOnly={true}
                />
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default CommunityKnowledgeArticle;
