import React, { useEffect, useState } from "react";
// Redux
import { connect } from "react-redux";
import {
  getSubmissionsWithScores,
  clearSubmissions,
} from "../../../redux/actions/challenge";
import { fetchAllJudgingCriterias } from "../../../redux/actions/judge";
// Material Ui
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LinkToHelp from "../../../shared/LinkToHelp";
// Icons
import LaunchIcon from "@material-ui/icons/Launch";
// Components
import Spinner from "../../../shared/Spinner";
import OrgAppBar from "../../../shared/OrgAppBar";
import ChallengeViewSubmissionScoresDialog from "./ChallengeViewSubmissionScoresDialog";
import ChallengeSubmissionsDownload from "./ChallengeSubmissionsDownload";
// Utils
import { DEFAULT_PAGE_SIZE } from "../../../utils/globalValues";

const ResultsActions = () => (
  <>
    <ChallengeSubmissionsDownload />
  </>
);

const ResultsView = ({
  loading,
  challenge,
  currentRoundIndex,
  submissions,
  criterias,
  getSubmissionsWithScores,
  fetchAllJudgingCriterias,
}) => {
  const [openSubmissionDialog, setOpenSubmissionDialog] = useState(false);
  // ID for submission dialog to open/display the correct info
  const [submissionIdDialog, setSubmissionIdDialog] = useState(null);

  const handleOpenSubmissionDialog = (submissionId) => {
    setOpenSubmissionDialog(true);
    setSubmissionIdDialog(submissionId);
  };

  // Loading submissions and criterias
  const fetchAllCriteriasForChallengeRound = async () => {
    await fetchAllJudgingCriterias(challenge.round[currentRoundIndex].id);
  };
  const fetchSubmissionsWithScores = async () => {
    await getSubmissionsWithScores(
      challenge.id,
      challenge.round[currentRoundIndex].id
    );
  };
  useEffect(() => {
    if (challenge) {
      fetchAllCriteriasForChallengeRound();
      fetchSubmissionsWithScores();
    }
    return () => clearSubmissions();
  }, [currentRoundIndex]);
  // Process the criteria data

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([]);

  useEffect(() => {
    // cols
    let newColumns = [];
    newColumns.push({
      field: "View",
      headerName: "View",
      sortable: false,
      filterable: false,
      width: 80,
      renderCell: (params) => (
        <Box>
          <Tooltip title="View the scores and feedback for this submission">
            <IconButton
              onClick={() => handleOpenSubmissionDialog(params.value)}
            >
              <LaunchIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    });
    newColumns.push({
      field: "Team Name",
      headerName: `${!challenge.maxPeopleTeam ? "Team" : ""} Name`,
      width: 250,
      sortComparator: (v1, v2, param1, param2) => {
        const name1 = param1.value.teamName;
        const name2 = param2.value.teamName;
        return name1.localeCompare(name2);
      },
      renderCell: (params) => {
        const teamName = params.value.teamName;
        const groupNames =
          params.value && params.value.groups.map((e) => e.name).join(", ");
        const eliminated = params.value.eliminatedFlag;
        return (
          <Tooltip
            title={
              <Box display="flex" flexDirection="column" textAlign="left">
                <Typography variant="body2" gutterBottom>
                  {teamName} {eliminated && " (Eliminated)"}
                </Typography>
                <Typography variant="caption">
                  <Box lineHeight={1.2} fontSize=".7rem">
                    {groupNames}
                  </Box>
                </Typography>
              </Box>
            }
            placement="right"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="center"
            >
              <Typography
                variant="body2"
                style={{
                  textDecoration: eliminated
                    ? "line-through solid red"
                    : "none",
                }}
              >
                <strong>{teamName}</strong>
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {groupNames}
              </Typography>
            </Box>
          </Tooltip>
        );
      },
    });
    criterias &&
      criterias.forEach((criteria) =>
        newColumns.push({
          field: criteria.name,
          headerName: criteria.name,
          width: 150,
          description: `Sort by ${criteria.name}`,
        })
      );
    newColumns.push({
      field: "Average Total",
      headerName: "Average Total",
      width: 150,
    });
    setColumns(newColumns);

    // rows
    let newRows = [];
    submissions &&
      submissions.length > 0 &&
      submissions.forEach((sub, index) => {
        let newRow = { id: index };
        newRow["View"] = sub.id;
        const team = sub.team;
        if (!team) return;
        newRow["Team Name"] = {
          teamName: team.name,
          groups: sub.groups,
          eliminatedFlag: team.eliminatedFlag,
        };

        const criteriaScores = sub.criteriaScores;
        let averageTotal = 0.0;
        if (criteriaScores) {
          for (let i = 0; i < criteriaScores.length; i++) {
            averageTotal += criteriaScores[i].averageScore;
          }
        }
        newRow["Average Total"] =
          (averageTotal && parseFloat(averageTotal.toFixed(2))) || null;
        criterias &&
          criterias.length > 0 &&
          criterias.forEach((c) => {
            const foundScore =
              criteriaScores &&
              criteriaScores.find((elem) => elem.judgingCriteriaId === c.id);
            newRow[c.name] =
              foundScore && !isNaN(foundScore.averageScore)
                ? foundScore.averageScore
                : null;
          });
        newRows.push(newRow);
      });
    setRows(newRows);

    if (submissions && submissions.length > 0) {
      // set the rowsPerPage
      let rowsPerPage = [];
      // Add at least three options
      // minimum 10, 20, 50, all
      if (submissions) {
        if (submissions.length > 10) {
          rowsPerPage.push(10);
          if (submissions.length > 20) {
            rowsPerPage.push(20);
            if (submissions.length > 50) {
              rowsPerPage.push(50);
            }
          }
        }
        rowsPerPage.push(submissions.length);
      }
      setRowsPerPageOptions(rowsPerPage);
    }
  }, [submissions, criterias]);

  function CustomNoRowsOverlay() {
    return (
      <Box p={{ xs: 2, md: 4, lg: 8 }}>
        <Typography variant="body2">
          There are no results at this time.
        </Typography>
      </Box>
    );
  }
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {submissionIdDialog && openSubmissionDialog && (
            <ChallengeViewSubmissionScoresDialog
              open={openSubmissionDialog}
              setOpen={setOpenSubmissionDialog}
              submissionId={submissionIdDialog}
            />
          )}

          <Box px={[1, 2]}>
            <OrgAppBar
              title={null}
              subtitle={
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" component="div">
                    <Box mr={1}>
                      {submissions ? submissions.length : 0} Submission(s)
                    </Box>
                  </Typography>
                  <LinkToHelp
                    label="Results 101"
                    link="https://www.notion.so/Review-c611ae7c790c4c70aa76e92f50e41128#6021ec70ea254c118cfea844bcb66022"
                  />
                </Box>
              }
              actions={<ResultsActions />}
            />
          </Box>
          <Box
            width="100%"
            minHeight={rows.length > 0 ? rows.length * 55 + 112 : 1250}
          >
            <DataGrid
              component={Paper}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnSelector={true}
              disableColumnMenu={true}
              autoHeight={true}
              rows={rows}
              columns={columns}
              pageSize={rows.length > 0 ? rows.length : DEFAULT_PAGE_SIZE}
              rowsPerPageOptions={rowsPerPageOptions}
              sortingOrder={["desc", "asc"]}
            />
          </Box>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  submissions: state.challenge.submissions,
  criterias: state.judge.criterias,
});

const mapDispatchToProps = {
  getSubmissionsWithScores,
  fetchAllJudgingCriterias,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
