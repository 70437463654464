import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Moment from "react-moment";
import { deleteProject } from "../../redux/actions/project";
// Material UI
import { makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
// icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import YouTubeIcon from "@material-ui/icons/YouTube";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CodeIcon from "@material-ui/icons/Code";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import EditIcon from "@material-ui/icons/Edit";
// components
import ProjectForm from "../ProjectForm";
import PlaceholderProjectImage from "../PlaceholderProjectImage";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    objectFit: "cover",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    fontWeight: "600",
  },
  chipPaper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  header: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  editButton: {
    color: theme.palette.warning.main,
    fontWeight: "600",
    fontSize: 12,
    "&:focus": { outline: "none" },
  },
  deleteButton: {
    color: theme.palette.error.main,
    fontWeight: "600",
    fontSize: 12,
    "&:focus": { outline: "none" },
  },
}));

const SimpleMyProjectView = ({
  project,
  projectCategories,
  extLinks,
  projectStatus,
  auth,
  deleteProject,
}) => {
  const classes = useStyles();
  // The second parameter is the function that returns the state of the variable
  // Local states:
  // useState sets the state of the state variable and the function that works on this state variable
  // you can never change the state variable directly. You have to always use the state function.
  // Here I am setting the isDialogOpen to false.
  const [isDialogOpen, toggleDialog] = useState(false);
  const [status, setProjectStatus] = useState("");
  const [categoryNames, setCategoryNames] = useState([]);
  const [linkIcons] = useState({
    1: <CodeIcon />,
    2: <PermMediaIcon />,
    3: <DescriptionIcon />,
    4: <YouTubeIcon />,
    5: <AccountBalanceIcon />,
    6: <LanguageIcon />,
  });
  const [youtubeVideoId, setYouTubeVideoId] = useState("");

  useEffect(() => {
    const categories = project.categories;
    let newCategoryNames = [];
    for (let i = 0; i < categories.length; i++) {
      const found = projectCategories.findIndex(
        (elem) => elem.id === categories[i]
      );
      if (found !== -1) {
        newCategoryNames.push(projectCategories[found].name);
      }
    }
    setCategoryNames(newCategoryNames);
  }, [project]);

  /* useEffect(() => {
    if (project && project.skills) {
      const skills = project.skills;
      let newSkillSetNames = [];
      for (let i = 0; i < skills.length; i++) {
        const found = skillSet.findIndex(elem => elem.id === skills[i]);
        if (found !== -1) {
          newSkillSetNames.push(skillSet[found].skillSetName);
        }
      }
      setSkillSetNames(newSkillSetNames);
    }
  }, [project]); */

  useEffect(() => {
    const found =
      projectStatus &&
      projectStatus.length > 0 &&
      projectStatus.find((elem) => elem.id === project.projectStatusId);
    setProjectStatus(found ? found.name : "");
  }, [project]);

  useEffect(() => {
    setYouTubeVideoId("");
    for (let i = 0; i < project.extLinks.length; i++) {
      const currentExtLink = project.extLinks[i];
      const extLinkTypeId = currentExtLink.extLinkTypeId;
      const externalUrl = currentExtLink.externalUrl;
      if (extLinkTypeId === 4) {
        const split = externalUrl.split("v=")[1];
        let string;
        if (split) {
          const secondIndex = split.indexOf("&");
          string = split.substring(
            0,
            secondIndex !== -1 ? secondIndex : split.length
          );
        }
        setYouTubeVideoId(string || "");
      }
    }
  }, [project]);

  const handleDeleteProject = (projectId) => {
    if (!auth.user || !auth.isAuthenticated) return;
    deleteProject(projectId);
  };

  return (
    <Fragment>
      <Card variant="outlined">
        {project.projectImages && project.projectImages.length > 0 ? (
          <CardMedia
            style={{ paddingTop: "56.25%" }}
            image={project.projectImages[0].filePath}
            title={project.projectImages[0].filePath}
          />
        ) : youtubeVideoId && youtubeVideoId !== "" ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
              height: "0",
            }}
            className="rounded-t"
          >
            <iframe
              title={youtubeVideoId}
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              frameBorder="0"
              top="0"
              left="0"
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
              }}
            />
          </div>
        ) : (
          <PlaceholderProjectImage categories={project && project.categories} />
        )}
        <CardHeader
          className={classes.header}
          title={
            <Fragment>
              <p className="text-base font-semibold md:text-lg">
                {project.title}{" "}
                <Tooltip title="View this project">
                  <IconButton
                    component={RouterLink}
                    to={`/projects/${project.id}`}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </p>
            </Fragment>
          }
          subheader={
            <Fragment>
              <Typography variant="caption">
                Posted on {new Date(project.postedDate).toDateString()}
              </Typography>
            </Fragment>
          }
        />

        <CardContent className={classes.content}>
          <Box className={classes.chipPaper}>
            {categoryNames.length > 0 &&
              categoryNames.map((name, index) => {
                return <Chip color="default" key={index} label={name} />;
              })}
            <div>
              {project &&
                project.skills &&
                project.skills.length > 0 &&
                project.skills.map((skill, index) => {
                  return (
                    <Chip color="default" key={index} label={skill.name} />
                  );
                })}
            </div>
            <Chip
              color="default"
              label={
                <div>
                  {project.startDate && (
                    <Moment format="MMM YYYY">{project.startDate}</Moment>
                  )}{" "}
                  -{" "}
                  {project.projectStatusId === 3 && project.endDate ? (
                    <Moment format="MMM YYYY">{project.endDate}</Moment>
                  ) : (
                    "Present"
                  )}
                </div>
              }
              icon={<AccessTimeIcon />}
            />

            <Chip color="default" label={status} icon={<TimelapseIcon />} />
          </Box>
        </CardContent>
        <CardActions>
          <Box>
            {project.extLinks &&
              Object.entries(project.extLinks).map(([key, value]) => {
                const link = value.externalUrl;
                const id = value.extLinkTypeId;
                const found = extLinks.find((elem) => elem.id === id);
                const tooltip = (found && `View ${found.name} Link`) || "";

                if (link !== "") {
                  return (
                    <Tooltip title={tooltip} key={key}>
                      <Link
                        href={link}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.icon}
                      >
                        {linkIcons[id]}
                      </Link>
                    </Tooltip>
                  );
                }
              })}
          </Box>
          <Tooltip title="Edit this project">
            <IconButton
              className={classes.editButton}
              onClick={() => toggleDialog(true)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete this project">
            <IconButton
              onClick={() => handleDeleteProject(project.id)}
              className={classes.deleteButton}
            >
              <DeleteTwoToneIcon className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
          <ProjectForm
            isDialogOpen={isDialogOpen}
            toggleDialog={toggleDialog}
            editProject={project}
          />
        </CardActions>
      </Card>
    </Fragment>
  );
};

SimpleMyProjectView.propTypes = {
  projectCategories: PropTypes.array.isRequired,
  extLinks: PropTypes.array.isRequired,
  projectStatus: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  projectCategories: state.typedef.projectCategories,
  extLinks: state.typedef.extLinks,
  projectStatus: state.typedef.projectStatus,
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteProject })(SimpleMyProjectView);
