import React, { useEffect, useState, Fragment } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { getCurrentProfile } from "../../redux/actions/profile";
import { getMyProjects } from "../../redux/actions/project";
import {
  getChallenge,
  clearChallenge,
  submitToChallengeRound,
  fetchTeamInformation,
  getCurrentUserStatus,
} from "../../redux/actions/challenge";
// Material UI
import { makeStyles } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
// Components
import ChallengeTeam from "../../shared/ChallengeTeam";
import ChallengeNotFound from "../../shared/ChallengeNotFound";
import ProjectFormButton from "../../shared/ProjectFormButton";
import SimpleMyProjectCard from "../../shared/SimpleMyProjectCard";
import ChallengeCustomSubmissionForm from "../../shared/ChallengeCustomSubmissionForm";
import Spinner from "../../shared/Spinner";
import LinkToHelp from "../../shared/LinkToHelp";
import Button from "../../shared/Button";
import ChallengeThemeProvider from "../../shared/ChallengeThemeProvider";
// Assets
import { IoMdArrowBack } from "react-icons/io";
// Utils
import {
  USER_TYPE,
  SUBMISSION_TYPE_ID,
  SUBMITTED_STATUS_TYPE_ID,
} from "../../utils/globalValues";
import { getCurrentRound, submissionDetails } from "../../utils/challenge";
import { Helmet } from "react-helmet";

const Dot = () => {
  return (
    <Box mx={1} color="text.disabled">
      •
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  ProjectCardSelection: {
    transition: "ease-in-out 0.1s",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
}));

export const ChallengeSubmission = ({
  user,
  submitToChallengeRound,
  getChallenge,
  clearChallenge,
  getCurrentProfile,
  getCurrentUserStatus,
  getMyProjects,
  fetchTeamInformation,
  myprofile,
  myProjects,
  myTeam,
  challenge,
  currentUserStatus,
  loading,
}) => {
  const { slug, communitySlug } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [submissionLoading, setSubmissionLoading] = useState(true);
  const [currentRound, setCurrentRound] = useState("");
  const [submissionType, setSubmissionType] = useState(
    SUBMISSION_TYPE_ID.PROJECT
  );
  const [submittedBanner, setSubmittedBanner] = useState(false);
  const [submission, setSubmission] = useState({
    projectId: null,
  });
  const [submissionError, setSubmissionError] = useState("");

  const fetchProfileProjectsChallengeRegister = async () => {
    await getChallenge(slug);

    //if (user && user.typeId === USER_TYPE.STUDENT) {
    if (user && user.id) {
      await getCurrentProfile();
      await getMyProjects();
    }

    setSubmissionLoading(false);
  };

  useEffect(() => {
    fetchProfileProjectsChallengeRegister();
    return () => {
      clearChallenge();
    };
  }, [slug]);

  useEffect(() => {
    const round = getCurrentRound(challenge);
    if (round) {
      setCurrentRound(round);
      setSubmissionType(round.submissionTypeId);
    }
  }, [challenge]);

  useEffect(() => {
    if (challenge) {
      getCurrentUserStatus(challenge.id);
      fetchTeamInformation(challenge.id, user && user.id);
    }
  }, [challenge]);

  const submitChallenge = () => {
    if (!myTeam) {
      setSubmissionError(
        "Please register and check you have a team before submitting."
      );
    } else if (myTeam && myTeam.eliminatedFlag) {
      setSubmissionError(
        "Your team has been eliminated from the program by the organization. Please contact support or the organizers for more information."
      );
    } else if (!submission || !submission.projectId) {
      setSubmissionError("Please select a project before submitting.");
    } else if (submissionDetails(challenge) !== 0) {
      setSubmissionError(
        "It is not within the time frame of submission. Please contact the program administrators or Pralent support if this is a problem."
      );
    } else if (!currentUserStatus.isRegistered) {
      setSubmissionError("Please register before submitting.");
    } else if (
      currentUserStatus.submittedStatusTypeId ===
      SUBMITTED_STATUS_TYPE_ID.SUBMITTED
    ) {
      setSubmissionError(
        "There is a submission for you for this program. If this is a problem or an issue, please contact support."
      );
    } else if (
      window.confirm(
        "Are you sure you want to submit the selected project? All submissions are final."
      )
    ) {
      // make request to submit the challenge
      const currentRound = getCurrentRound(challenge);
      if (currentRound) {
        submitToChallengeRound(challenge.id, currentRound.id, submission);
      }
      history.push(`/programs/${challenge.slug}`);
    }
  };

  if (challenge && !challenge.isActive) {
    return (
      <Box>
        <Alert severity="error">
          <AlertTitle>{challenge.title} is Inactive.</AlertTitle>
          {challenge.title || "This program"} is not active and the submission
          page for the round is unavailable at this time. If this is an error,
          please contact support@pralent.com or the program organizers.
        </Alert>
      </Box>
    );
  }

  return (
    <ChallengeThemeProvider challenge={challenge}>
      <Box
        flexGrow={1}
        height="100%"
        maxHeight="100vh"
        overflow="auto"
        p={{ xs: 2, md: 4 }}
      >
        {challenge && (
          <Helmet>
            <title>Submission for {challenge.title} | Pralent Program</title>
            <meta name="description" content={challenge.tagLine} />
          </Helmet>
        )}
        {loading || submissionLoading ? (
          <Spinner />
        ) : challenge ? (
          currentUserStatus.isRegistered === undefined ? (
            <Spinner />
          ) : currentUserStatus.isRegistered ? (
            myprofile ? (
              <Box>
                <Box mb={[1, 2]}>
                  <Button
                    component={RouterLink}
                    to={`${
                      communitySlug ? `/c/${communitySlug}` : ""
                    }/programs/${challenge.slug}`}
                    startIcon={<IoMdArrowBack />}
                    variant="text"
                    color="default"
                  >
                    Back to {challenge.title}
                  </Button>
                </Box>
                {myTeam && myTeam.eliminatedFlag ? (
                  <Box width="100%" mb={2}>
                    <Alert variant="standard" severity="error">
                      <Typography variant="body1" component="div">
                        <Box fontWeight={600}>Eliminated</Box>
                      </Typography>
                      <Typography variant="body2">
                        Your team has been marked as eliminated by the
                        organizers of this program. Please contact support or
                        the organizers if this is a problem.
                      </Typography>
                    </Alert>
                  </Box>
                ) : (
                  challenge &&
                  currentUserStatus.submittedStatusTypeId ===
                    SUBMITTED_STATUS_TYPE_ID.SUBMITTED && (
                    <Box width="100%" mb={2}>
                      <Alert variant="standard" severity="success">
                        <Typography variant="body1" component="div">
                          <Box fontWeight={600}>Submitted!</Box>
                        </Typography>
                        <Typography variant="body2">
                          There is a submission for you for this program. If
                          this is a problem or an issue, please contact support.
                        </Typography>
                      </Alert>
                    </Box>
                  )
                )}

                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  my={[1, 2, 3]}
                >
                  <Box>
                    <Typography variant="h5" component="h1">
                      <strong>
                        Submission for {challenge && challenge.title}
                      </strong>
                    </Typography>
                    <Box mt={1} display="flex" alignItems="center">
                      <Typography variant="body1" color="textSecondary">
                        {currentRound.name}
                      </Typography>
                      <Dot />
                      <Typography variant="body1" color="textSecondary">
                        End Date:{" "}
                        {(currentRound.endDatetime &&
                          new Date(
                            currentRound.endDatetime
                          ).toLocaleString()) ||
                          "None"}
                      </Typography>
                      <Dot />

                      <LinkToHelp
                        label="How to submit?"
                        link="https://www.notion.so/Programs-7d67d087471c41f3a1407cd865db098d#034185b1945845abb0da770abc1357d0"
                      />
                    </Box>
                  </Box>

                  {/* Team information (Only display if maxNumberOfPeople > 1) */}
                  <Box
                    p={{ xs: 0, md: 3, lg: 4 }}
                    mt={2}
                    mb={1}
                    borderRadius="borderRadius"
                    bgcolor="background.paper"
                  >
                    <Typography variant="h5" component="h1">
                      <Box fontWeight={600} mb={2}>
                        {challenge &&
                        challenge.maxPeopleTeam &&
                        challenge.maxPeopleTeam === 1
                          ? "About Me"
                          : "My Team"}
                      </Box>
                    </Typography>
                    <ChallengeTeam />
                  </Box>
                  {submissionType === SUBMISSION_TYPE_ID.CUSTOM ? (
                    <Box
                      p={{ xs: 0, md: 3, lg: 4 }}
                      my={1}
                      borderRadius="borderRadius"
                      bgcolor="background.paper"
                    >
                      <ChallengeCustomSubmissionForm />
                    </Box>
                  ) : (
                    <Box
                      p={{ xs: 0, md: 3, lg: 4 }}
                      my={2}
                      borderRadius="borderRadius"
                      bgcolor="background.paper"
                    >
                      <Box mb={2}>
                        <Typography variant="h5" component="h1">
                          <Box fontWeight={600}>Submit Your Project</Box>
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          It's time to submit! Select a project that you want to
                          submit to this program. If you do not have a project,
                          add a new one below.
                        </Typography>
                      </Box>
                      <ProjectFormButton />
                      <Box my={[1, 2, 3]} width="100%">
                        <Grid container spacing={2}>
                          {myProjects &&
                            myProjects.map((project, index) => {
                              return (
                                <Grid item xs={12} md={6} lg={4} key={index}>
                                  <Box
                                    height="100%"
                                    border={8}
                                    borderRadius="borderRadius"
                                    borderColor={
                                      submission &&
                                      submission.projectId === project.id
                                        ? "primary.main"
                                        : "transparent"
                                    }
                                    className={classes.ProjectCardSelection}
                                    onClick={() =>
                                      setSubmission({
                                        ...submission,
                                        projectId: project.id,
                                      })
                                    }
                                  >
                                    <SimpleMyProjectCard project={project} />
                                  </Box>
                                </Grid>
                              );
                            })}
                        </Grid>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        {challenge &&
                          currentUserStatus.submittedStatusTypeId !==
                            SUBMITTED_STATUS_TYPE_ID.SUBMITTED && (
                            <Button onClick={submitChallenge}>Submit</Button>
                          )}
                      </Box>
                    </Box>
                  )}

                  {submissionError && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Alert severity="error">
                        <AlertTitle>Program Submission Error</AlertTitle>
                        {submissionError}
                      </Alert>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Fragment>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  mx="auto"
                  height="100vh"
                >
                  <Typography variant="h6" component="h1">
                    Please complete your profile before submitting to this
                    program.
                  </Typography>

                  <Box my={[1, 2]}>
                    <Button
                      component={RouterLink}
                      to="/me"
                      color="primary"
                      startIcon={<IoMdArrowBack />}
                    >
                      Edit my profile
                    </Button>
                  </Box>
                </Box>
              </Fragment>
            )
          ) : (
            <Fragment>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                mx="auto"
                height="100vh"
              >
                <Typography variant="h6" component="h1">
                  Please register for this program before submitting.
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  If this is a problem, try refreshing the page or logout and
                  try again. If this problem persists,{" "}
                  <Link href="mailto:support@pralent.com">contact support</Link>
                  .
                </Typography>
                <Box my={[1, 2]}>
                  <Button
                    component={RouterLink}
                    to={`/programs/${challenge.slug}`}
                    color="primary"
                    startIcon={<IoMdArrowBack />}
                  >
                    Back to {challenge.title}
                  </Button>
                </Box>
              </Box>
            </Fragment>
          )
        ) : (
          <ChallengeNotFound />
        )}
      </Box>
    </ChallengeThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  myprofile: state.profile.myprofile,
  myProjects: state.project.myProjects,
  myTeam: state.challenge.myTeam,
  challenge: state.challenge.challenge,
  currentUserStatus: state.challenge.currentUserStatus,
  loading: state.challenge.loading,
});

const mapDispatchToProps = {
  submitToChallengeRound,
  getCurrentProfile,
  getMyProjects,
  getChallenge,
  clearChallenge,
  fetchTeamInformation,
  getCurrentUserStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeSubmission);
