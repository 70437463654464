import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";

const useStyles = makeStyles((theme) => ({
  attendeeAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: -theme.spacing(0.75),
    borderRadius: "50%",
    fontSize: theme.typography.h6.fontSize,
    borderColor: theme.palette.text.primary,
    borderWidth: theme.spacing(0.25),
  },
}));

const EventAttendees = ({ numberOfRegistrations, attendees, size }) => {
  const classes = useStyles();

  return (
    <>
      <Tooltip title={`${numberOfRegistrations} total attendees`}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {(attendees || []).map((attendee, index) => {
            const user = attendee.user;
            return (
              <CustomAvatar
                key={index}
                src={user && user.avatar}
                fallbackText={user && user.userName}
                alt={`${user && user.userName}-avatar`}
                className={classes.attendeeAvatar}
              />
            );
          })}
          {attendees &&
            attendees.length > 0 &&
            numberOfRegistrations > attendees.length && (
              <CustomAvatar
                src={null}
                fallbackText={`+-more-attendees`}
                alt={`+-more-attendees`}
                className={classes.attendeeAvatar}
              />
            )}
        </Box>
      </Tooltip>
      {attendees &&
        attendees.length > 0 &&
        numberOfRegistrations > attendees.length && (
          <Typography
            variant={size === "sm" ? "body2" : "body1"}
            component="div"
            color="textSecondary"
          >
            <Box mt={1}>{numberOfRegistrations} total attendees</Box>
          </Typography>
        )}
    </>
  );
};

export default EventAttendees;
