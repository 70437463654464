import React from "react";
// react-router-dom
import { useParams } from "react-router-dom";
// hooks
import { useGetEventBySlugForOrganization } from "../../hooks/event";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
// Components
import Spinner from "../../shared/Spinner";
import AddEditEvent from "../../shared/AddEditEvent";
import OrgEventAppBar from "../../shared/OrgEventAppBar";

export const OrgEventEdit = () => {
  const { slug } = useParams();
  const {
    isLoading,
    isError,
    data: { data: orgEvent } = { data: null },
    error,
  } = useGetEventBySlugForOrganization(slug);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Box>
          <OrgEventAppBar event={orgEvent} />
          <Container maxWidth="md">
            <AddEditEvent event={orgEvent} />
          </Container>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgEventEdit);
