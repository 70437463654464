/* This example requires Tailwind CSS v2.0+ */
import GlassFeature from "./GlassFeature";
// new images
import memberdirectory from "../../../assets/screenshots/members-pages/member-directory.png";
import programpage from "../../../assets/screenshots/program-pages/program-page.png";
import jobboardpage from "../../../assets/screenshots/job-pages/job-board-page.png";
import eventpage from "../../../assets/screenshots/event-pages/event-page.png";
import sampleprofile from "../../../assets/screenshots/profile-pages/sample-profile.png";
import orgprogramregistrationsinsights from "../../../assets/screenshots/insights-pages/org-program-registration-insights.png";
import paymentsplaceholder from "../../../assets/screenshots/payments-pages/payments-placeholder.png";
import discordchatdark from "../../../assets/screenshots/discord-chat-dark.png";

const FIRST_FEATURES = [
  {
    icon: <span>🙋</span>,
    name: "Members",
    description:
      "Keeping track of your community members in one place has never been easier. Manage approval processes, invite your current and future members to your page, create a member directory, display a community calendar and understand meaningful insights.",
    bgcolor: "bg-blue-500",
    image: memberdirectory,
    fullWidth: true,
  },
  {
    name: "Events",
    icon: <span>🎟️</span>,
    description:
      "Create and host personalized event experiences that you can share with your community. Utilize a built-in robust event management platform to track registrations. Customize the event page to fit your brand.",
    bgcolor: "bg-pink-500",
    image: eventpage,
    fullWidth: true,
  },
  {
    name: "Token Gated Access // Discord Integration",
    comingSoon: true,
    icon: <span>⚡</span>,
    description:
      "Provide access to your community, events, jobs and more through a specific token or NFT. Use Discord or Slack? An in-depth integration for your existing community is coming soon.",
    bgcolor: "bg-purple-500",
    image: discordchatdark,
    fullWidth: true,
  },
  {
    name: "Payments to monetize your community",
    comingSoon: true,
    icon: <span>💰</span>,
    description:
      "Monetize your community by offering specialized access to events, jobs, groups, and more. Donations and social tipping allows you to build a business around your community while offering members special perks.",
    bgcolor: "bg-green-500",
    image: paymentsplaceholder,
    fullWidth: true,
  },
];

const SECOND_FEATURES = [
  {
    name: "Programs",
    icon: <span>🚀</span>,
    description:
      "A better way to manage your programs. Features to power any program: fellowships, grants, competitions, hackathons, accelerators. Submission and reviewer management, multiple rounds, custom forms, in-depth reporting, insights, and more.",
    bgcolor: "bg-orange-500",
    image: programpage,
    fullWidth: false,
  },
  {
    name: "Jobs",
    icon: <span>💼</span>,
    description:
      "No setup, out of the box job board to provide new and exiting job opportunities for your members. You can create your own job board for others to post job to the community or create a job board just for yourself to post.",
    bgcolor: "bg-cyan-500",
    image: jobboardpage,
    fullWidth: false,
  },
  {
    name: "Network",
    icon: <span>🌐</span>,
    description:
      "Provide unique career-building and networking opportunities to your members. Members can join other communities while also building out their network through yours. In-depth profiles allow them to understand more about each other and make meaningful connections.",
    bgcolor: "bg-blue-500",
    image: sampleprofile,
    fullWidth: false,
  },
  {
    name: "Insights",
    icon: <span>📈</span>,
    description:
      "Empower your team to make more informed decisions to propel your community forward. Track diversity metrics and understand areas for improvement. Learn what drives community engagement. Analyze signups, registrations, educational backgrounds, interests, and more.",
    bgcolor: "bg-red-500",
    image: orgprogramregistrationsinsights,
    fullWidth: false,
  },
  {
    name: "Adapts to your community",
    icon: <span>🎨</span>,
    description:
      "Customize your community to match your brand. From adding your own logo, colors, and images to customizing the welcome message new members receive. Build a brand while building your community.",
    bgcolor: "bg-gray-800",
    fullWidth: false,
  },
  {
    name: "100+ Features",
    icon: <span>🆕</span>,
    description:
      "We have all of the features you need to get your community started and start increasing value for your members. Custom approval processes, deep insights, moderation tools, and more.",
    bgcolor: "bg-gray-800",
    fullWidth: false,
  },
];

export default function Features() {
  return (
    <div className="relative mx-auto max-w-7xl px-4 py-12 md:py-24 lg:py-40">
      <p className="mx-auto max-w-4xl text-center text-4xl font-bold tracking-tight text-gray-100 sm:text-5xl lg:text-6xl xl:text-7xl">
        Everything your community needs in one place
      </p>
      <div className="mx-auto mt-12">
        <div className="grid grid-cols-1 gap-8">
          <div className="grid grid-cols-1 items-stretch gap-8">
            <GlassFeature feature={FIRST_FEATURES[0]} />
          </div>
          <div className="grid grid-cols-1 items-stretch gap-8 md:grid-cols-2">
            {SECOND_FEATURES.slice(0, 2).map((feature, index) => (
              <GlassFeature key={index} feature={feature} />
            ))}
          </div>
          <div className="grid grid-cols-1 items-stretch gap-8">
            <GlassFeature feature={FIRST_FEATURES[1]} />
          </div>
          <div className="grid grid-cols-1 items-stretch gap-8 md:grid-cols-2">
            {SECOND_FEATURES.slice(2, 6).map((feature, index) => (
              <GlassFeature key={index} feature={feature} />
            ))}
          </div>
          <div className="grid grid-cols-1 items-stretch gap-8">
            {FIRST_FEATURES.slice(2, 4).map((feature, index) => (
              <GlassFeature key={index} feature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
