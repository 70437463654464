import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const LinkToHelp = ({ label, link }) => {
  return (
    <Box>
      {label ? (
        <Chip
          color="default"
          label={label}
          clickable
          component="a"
          href={link}
          avatar={<HelpOutlineIcon />}
          target="_blank"
          rel="noopener noreferrer"
        />
      ) : (
        <IconButton
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
        >
          <HelpOutlineIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default LinkToHelp;
