import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
// Components
import ChallengeAwardsTable from "./ChallengeAwardsTable";
import ChallengeAwardsAppBar from "./ChallengeAwardsAppBar";
import OrgChallengeAppBar from "../../../shared/OrgChallengeAppBar";

export const ChallengeAwardsView = () => {
  return (
    <>
      <Box height="100%" margin="auto">
        <ChallengeAwardsAppBar />
        <ChallengeAwardsTable />
      </Box>
    </>
  );
};

export default ChallengeAwardsView;
