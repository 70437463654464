import React from "react";
import { useState } from "react";
// React Query
import { useGetAllUserJobActivities } from "../../../hooks/job";
// Material UI
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockIcon from "@material-ui/icons/Lock";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import Spinner from "../../../shared/Spinner";
import EmptyState from "../../../shared/EmptyState";
import JobCard from "./JobCard";

const DashboardJobs = () => {
  const {
    isLoading,
    data: { data: data } = { data: null },
    error,
    isError,
    isFetching,
  } = useGetAllUserJobActivities();

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {/* Displaying Job information */}
          <Box>
            <Typography variant="h5" component="h2">
              <Box fontWeight={700} pb={2}>
                💼 Applied Jobs
              </Box>
            </Typography>
            <Grid container spacing={2}>
              {data && data.length > 0 ? (
                data.map((JobApplied) => {
                  return (
                    <Grid item xs={12} md={6} width="100%" key={JobApplied.id}>
                      <JobCard job={JobApplied.job} />
                    </Grid>
                  );
                })
              ) : (
                <Grid item xs={12}>
                  <EmptyState
                    title="No jobs applied"
                    subtitle="You have not applied to any jobs yet."
                    minHeight={300}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default DashboardJobs;
