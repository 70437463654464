import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import { makeStyles } from "@material-ui/core/styles";
import { uploadResume } from "../../../redux/actions/profile";
// Material Ui
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Grid from "@material-ui/core/Grid";
import DescriptionIcon from "@material-ui/icons/Description";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  dialogContent: {
    textAlign: "center",
  },
  resumeButton: {
    fontWeight: "600",
    "&:hover": {
      cursor: "pointer",
    },
  },
  resumeUrl: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  appBar: {
    position: "relative",
  },
  button: {
    fontWeight: "600",
    marginTop: "5px",
    marginBottom: "5px",
  },
}));

const UploadResume = ({ myprofile, uploadResume, setAlert }) => {
  const classes = useStyles();

  const [file, setFile] = useState("");
  const [filename, setFileName] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");

  useEffect(() => {
    if (myprofile && myprofile.resumeLocation !== null) {
      setResumeUrl(myprofile.resumeLocation);
    }
  }, [myprofile]);

  const onChange = (e) => {
    if (e.target.files.length > 0) {
      if (e.target.files[0].size < 1048576) {
        setResumeUrl(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
      } else {
        setAlert("Maximum file size 1MB exceeded", "error");
      }
    }
  };

  const onSubmit = () => {
    const formData = new FormData();
    formData.append("myFile", file);
    uploadResume(formData);
  };
  return (
    <Fragment>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <IconButton
          color={"primary"}
          className={classes.button}
          tag={"a"}
          href={resumeUrl}
          target={"_blank"}
          rel={"noopener noreferrer"}
          disabled={resumeUrl === ""}
        >
          <DescriptionIcon />
        </IconButton>
        <Typography>{filename !== "" && filename}</Typography>
      </Grid>
      <Grid>
        <input
          accept="application/pdf"
          className={classes.input}
          id="resume-upload-button"
          type="file"
          onChange={onChange}
        />
        <label htmlFor="resume-upload-button" style={{ marginBottom: "0" }}>
          <Button
            color={"primary"}
            startIcon={<CloudUpload />}
            className={classes.resumeButton}
            component={"span"}
          >
            {resumeUrl !== "" ? "Replace" : "Add"} Resume
          </Button>
        </label>
        <Button
          onClick={onSubmit}
          className={classes.button}
          color={"primary"}
          disabled={
            !(
              myprofile &&
              myprofile.resumeLocation !== resumeUrl &&
              resumeUrl !== ""
            )
          }
        >
          Submit
        </Button>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
});
export default connect(mapStateToProps, { uploadResume, setAlert })(
  UploadResume
);
