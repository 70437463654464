import { useQuery } from "react-query";
import axios from "../utils/axios";

export const useFetchCommunityDemographics = (companyId) => {
  const result = useQuery(
    [
      "communityRegistrationData",
      {
        companyId: companyId,
      },
    ],
    fetchCommunityDemographics
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const fetchCommunityDemographics = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;

  const body = {
    companyId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(
    `/api/insights/fetchCommunityDemographics`,
    body,
    config
  );
};

export const useFetchCommunityRegistration = (companyId, dateRange) => {
  const result = useQuery(
    [
      "communityRegistrationData",
      {
        companyId: companyId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    ],
    fetchCommunityRegistration
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const fetchCommunityRegistration = async ({ queryKey }) => {
  const [_key, { companyId, startDate, endDate }] = queryKey;

  const body = {
    companyId,
    startDate,
    endDate,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log(startDate, endDate);

  return await axios.post(
    `/api/insights/fetchCommunityRegistration`,
    body,
    config
  );
};

export const useFetchChallengeRegistration = (challenge, dateRange) => {
  const result = useQuery(
    [
      "challengeRegistrationData",
      {
        challengeId: challenge && challenge.id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    ],
    fetchChallengeRegistration
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

const fetchChallengeRegistration = async ({ queryKey }) => {
  const [_key, { challengeId, startDate, endDate }] = queryKey;

  const body = {
    challengeId,
    startDate,
    endDate,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const res = await axios.post(
    `/api/insights/fetchChallengeRegistration`,
    body,
    config
  );
  return res;
};

export const usefetchChallengeRound = (challengeRoundId, dateRange) => {
  const result = useQuery(
    [
      "challengeRoundData",
      {
        challengeRoundId: challengeRoundId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    ],
    fetchChallengeRound
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

const fetchChallengeRound = async ({ queryKey }) => {
  const [_key, { challengeRoundId, startDate, endDate }] = queryKey;

  const body = {
    challengeRoundId,
    startDate,
    endDate,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(`/api/insights/fetchChallengeRound`, body, config);
};

export const useFetchChallengeDemographics = (challengeId) => {
  const result = useQuery(
    [
      "challengeDemographicsData",
      {
        challengeId: challengeId,
      },
    ],
    fetchChallengeDemographics
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const fetchChallengeDemographics = async ({ queryKey }) => {
  const [_key, { challengeId }] = queryKey;

  const body = {
    challengeId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(
    `/api/insights/fetchChallengeDemographics`,
    body,
    config
  );
};

export const useFetchEventDemographics = (eventId) => {
  const result = useQuery(
    [
      "eventDemographicsData    ",
      {
        eventId: eventId,
      },
    ],
    fetchEventDemographics
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

const fetchEventDemographics = async ({ queryKey }) => {
  const [_key, { eventId }] = queryKey;

  const body = {
    eventId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(`/api/insights/fetchEventDemographics`, body, config);
};

export const useFetchEventRegistration = (eventId, dateRange) => {
  const result = useQuery(
    [
      "eventRegistrationData",
      {
        eventId: eventId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      },
    ],
    fetchEventRegistration
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

const fetchEventRegistration = async ({ queryKey }) => {
  const [_key, { eventId, startDate, endDate }] = queryKey;

  const body = {
    eventId,
    startDate,
    endDate,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  return await axios.post(`/api/insights/fetchEventRegistration`, body, config);
};
