import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { fetchCustomForm } from "../../../redux/actions/formBuilder";
import {
  exportCustomSubmissions,
  exportProjectSubmissions,
} from "../../../redux/actions/reports";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// Material UI Utils
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Components
import Button from "../../../shared/Button";
// Icons
import GetAppIcon from "@material-ui/icons/GetApp";

import { CSVLink } from "react-csv";
// Utils
import {
  CUSTOM_FORM_TYPE_ID,
  SUBMISSION_TYPE_ID,
} from "../../../utils/globalValues";

const defaultProjectFields = {
  projectTitle: {
    checked: true,
    title: "Project Title",
  },
  projectDescription: {
    checked: true,
    title: "Project Description",
  },
  projectStatus: {
    checked: true,
    title: "Project Status",
  },
  projectStartDate: {
    checked: true,
    title: "Project Start Date",
  },
  projectEndDate: {
    checked: true,
    title: "Project End Date",
  },
  /* projectCategories: {
    checked: true,
    title: "Project Categories",
  }, 
  projectSkills: {
    checked: true,
    title: "Project Skills",
  }, */
  projectLinks: {
    checked: true,
    title: "Project Links",
  },
  projectFiles: {
    checked: true,
    title: "Project Files",
  },
};

export const SubmissionExport = ({
  challenge,
  currentRoundIndex,
  customForm,
  fetchCustomForm,
  exportCustomSubmissions,
  exportProjectSubmissions,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentRound, setCurrentRound] = useState(null);
  const [submissionExport, setSubmissionExport] = useState([]);
  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  useEffect(() => {
    setCurrentRound(
      challenge &&
        challenge.round &&
        challenge.round.length > 0 &&
        challenge.round[currentRoundIndex]
    );
  }, [currentRoundIndex]);

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (open) {
      if (currentRound.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM) {
        fetchCustomForm(currentRound.id, CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM);
      }
    }
  }, [currentRound, open]);

  const [fieldsToExport, setFieldsToExport] = useState({});

  useEffect(() => {
    let newFields = {};
    if (!currentRound) {
      newFields = null;
    } else if (currentRound.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM) {
      const { loading, data, error } = customForm;
      data &&
        data.jsonSchema &&
        data.jsonSchema.properties &&
        Object.keys(data.jsonSchema.properties).map((fieldId) => {
          const field = data.jsonSchema.properties[fieldId];
          const title = field.title;
          newFields[fieldId] = {
            checked: true,
            title: title,
          };
        });
    } else if (currentRound.submissionTypeId === SUBMISSION_TYPE_ID.PROJECT) {
      newFields = {
        ...defaultProjectFields,
      };
    }

    setFieldsToExport(newFields);
  }, [currentRound, customForm, open]);

  const onSubmit = async (e) => {
    //e.preventDefault();

    let fieldsToSubmit = {};
    fieldsToExport &&
      Object.keys(fieldsToExport).map((fieldId) => {
        const currentField = fieldsToExport[fieldId];
        fieldsToSubmit[fieldId] = currentField.checked;
      });

    let submissionDtl;
    if (currentRound.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM) {
      submissionDtl = await exportCustomSubmissions(
        currentRound.id,
        fieldsToSubmit
      );
    } else if (currentRound.submissionTypeId === SUBMISSION_TYPE_ID.PROJECT) {
      submissionDtl = await exportProjectSubmissions(
        currentRound.id,
        fieldsToSubmit
      );
    }
    setSubmissionExport(submissionDtl);
    csvLink.current.link.click();
  };

  const handleChange = (e) => {
    const name = e.target.name;
    setFieldsToExport({
      ...fieldsToExport,
      [name]: {
        ...fieldsToExport[name],
        checked: e.target.checked,
      },
    });
  };

  return (
    <>
      <Tooltip
        title="Export all the submissions for this round"
        placement="top"
      >
        <Button
          onClick={onOpen}
          variant="text"
          color="primary"
          startIcon={<GetAppIcon />}
        >
          Export
        </Button>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        fullWidth={true}
        aria-labelledby="submission-export-dialog"
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Box flex="1" ml={[1, 2]}>
              <Typography variant="h5">Export Submissions</Typography>
              <Typography variant="body2">
                Select the fields you would like to export for this round
              </Typography>
            </Box>

            {challenge && submissionExport && (
              <CSVLink
                data={submissionExport}
                filename={`${challenge.title} Submissions Round ${
                  currentRoundIndex + 1
                }.csv`}
                ref={csvLink}
                target="_blank"
              />
            )}
            <Button onClick={onSubmit}>Export to CSV</Button>
          </Toolbar>
        </AppBar>
        <Box px={[2, 4]}>
          <Box my={[2, 3]}>
            <FormControl component="fieldset">
              <FormGroup>
                {fieldsToExport &&
                  Object.keys(fieldsToExport).map((fieldId) => {
                    const field = fieldsToExport[fieldId];
                    const title = field.title;
                    const checked = field.checked;
                    return (
                      <Box key={fieldId}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={checked}
                              onChange={handleChange}
                              name={fieldId}
                              color="primary"
                            />
                          }
                          label={title}
                        />
                      </Box>
                    );
                  })}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  customForm: state.formBuilder.customForm,
});

const mapDispatchToProps = {
  fetchCustomForm,
  exportCustomSubmissions,
  exportProjectSubmissions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionExport);
