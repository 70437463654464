import axios from "../../utils/axios";
import { setAlert } from "./alert";
// actionTypes
import {
  LOADING_GROUP,
  GET_GROUP_BY_ID,
  ERROR_GROUP,
  LOADING_ALL_GROUPS,
  GET_ALL_GROUPS,
  ERROR_ALL_GROUPS,
  DELETE_GROUP_BY_ID,
  ADD_EDIT_GROUPS,
  GROUP_LOADING_SUBMISSIONS,
  GROUP_GET_SUBMISSIONS,
  GROUP_ERROR_SUBMISSIONS,
  GROUP_LOADING_JUDGES,
  GROUP_GET_JUDGES,
  GROUP_ERROR_JUDGES,
  UPDATE_GROUPS_JUDGE,
  UPDATE_GROUPS_SUBMISSION,
} from "./actionTypes";
import { handleServerError } from "./error";

export const addEditGroup = (
  groupId,
  challengeId,
  challengeRoundId,
  name
) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_GROUP,
    });
    const body = {
      groupId,
      challengeId,
      challengeRoundId,
      name,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/group/addEditGroup", body, config);
    await dispatch({
      type: GET_GROUP_BY_ID,
      payload: res.data,
    });
    await dispatch({
      type: ADD_EDIT_GROUPS,
      payload: {
        edit: groupId ? true : false,
        group: res.data,
      },
    });
  } catch (error) {
    await dispatch({
      type: ERROR_GROUP,
      payload: error && error.data && error.data.msg,
    });
    dispatch(handleServerError(error));
  }
};

export const getGroupById = (challengeGroupId) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_GROUP,
    });
    const res = await axios.get(`/api/group/getGroupById/${challengeGroupId}`);
    await dispatch({
      type: GET_GROUP_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: ERROR_GROUP,
      payload: error && error.data && error.data.msg,
    });
    dispatch(handleServerError(error));
  }
};

export const getAllGroupsForRound = (challengeRoundId) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_ALL_GROUPS,
    });
    await dispatch({
      type: LOADING_GROUP,
    });
    const res = await axios.get(
      `/api/group/getAllGroupsForRound/${challengeRoundId}`
    );
    await dispatch({
      type: GET_ALL_GROUPS,
      payload: res.data,
    });
    await dispatch({
      type: GET_GROUP_BY_ID,
      payload: null,
    });
  } catch (error) {
    await dispatch({
      type: ERROR_ALL_GROUPS,
      payload: error && error.data && error.data.msg,
    });
    dispatch(handleServerError(error));
  }
};

export const deleteGroupById = (challengeGroupId) => async (dispatch) => {
  try {
    if (window.confirm("Are you sure you want to delete this group?")) {
      const res = await axios.del(`/api/group/${challengeGroupId}`);
      await dispatch({
        type: DELETE_GROUP_BY_ID,
        payload: res.data,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_ALL_GROUPS,
      payload: error && error.data && error.data.msg,
    });
    dispatch(handleServerError(error));
  }
};

export const getSubmissionsForChallengeRound = (challengeRoundId) => async (
  dispatch
) => {
  try {
    await dispatch({
      type: GROUP_LOADING_SUBMISSIONS,
    });
    const res = await axios.get(`/api/group/submissions/${challengeRoundId}`);
    await dispatch({
      type: GROUP_GET_SUBMISSIONS,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: GROUP_ERROR_SUBMISSIONS,
      payload: error && error.data && error.data.msg,
    });
    dispatch(handleServerError(error));
  }
};

export const getJudgesForChallengeRound = (challengeRoundId) => async (
  dispatch
) => {
  try {
    await dispatch({
      type: GROUP_LOADING_JUDGES,
    });
    const res = await axios.get(`/api/group/judges/${challengeRoundId}`);
    await dispatch({
      type: GROUP_GET_JUDGES,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: GROUP_ERROR_JUDGES,
      payload: error && error.data && error.data.msg,
    });
    dispatch(handleServerError(error));
  }
};

export const submissionsToGroup = (
  challengeId,
  challengeRoundId,
  submissionsToAssign,
  submissionsToRemove,
  groupId
) => async (dispatch) => {
  try {
    const body = {
      challengeId,
      challengeRoundId,
      submissionsToAssign,
      submissionsToRemove,
      groupId,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(`/api/group/submissionsToGroup`, body, config);

    await dispatch({
      type: GET_GROUP_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const groupsToSubmission = (
  challengeId,
  challengeRoundId,
  groupsToAssign,
  groupsToRemove,
  submissionId
) => async (dispatch) => {
  try {
    const body = {
      challengeId,
      challengeRoundId,
      groupsToAssign,
      groupsToRemove,
      submissionId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/group/groupsToSubmission`, body, config);

    await dispatch({
      type: UPDATE_GROUPS_SUBMISSION,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const groupsToJudge = (
  challengeId,
  challengeRoundId,
  groupsToAssign,
  groupsToRemove,
  judgeId
) => async (dispatch) => {
  try {
    const body = {
      challengeId,
      challengeRoundId,
      groupsToAssign,
      groupsToRemove,
      judgeId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/group/groupsToJudge`, body, config);

    await dispatch({
      type: UPDATE_GROUPS_JUDGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const judgesToGroup = (
  challengeId,
  challengeRoundId,
  judgesToAssign,
  judgesToRemove,
  groupId
) => async (dispatch) => {
  try {
    const body = {
      challengeId,
      challengeRoundId,
      judgesToAssign,
      judgesToRemove,
      groupId,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(`/api/group/judgesToGroup`, body, config);

    await dispatch({
      type: GET_GROUP_BY_ID,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
