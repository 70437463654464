import axios from "../../utils/axios";
import { setAlert } from "./alert";
import {
  GET_PROJECTS,
  GET_MYPROJECTS,
  ADD_LIKE,
  REMOVE_LIKE,
  DELETE_PROJECT,
  ADD_PROJECT,
  EDIT_PROJECT,
  GET_PROJECT,
  ADD_COMMENT,
  REMOVE_COMMENT,
  UPLOAD_PROJECT_IMAGE,
  GET_PROFILE_PROJECTS,
  CLEAR_MYPROJECTS,
  CLEAR_PUBLICPROJECTS,
  ADD_LIKE_PUBLIC_PROJECT,
  REMOVE_LIKE_PUBLIC_PROJECT,
  DELETE_PROJECT_FILE,
  PROJECT_LOADING,
  CLEAR_PROJECT,
} from "./actionTypes";
import { SKIP, LIMIT, USER_TYPE } from "../../utils/globalValues";
import { handleServerError } from "./error";
//import { Event } from "../../utils/tracking";
// import analytics from "../../analytics";
import { A_ADD_EDIT_PROJECT } from "../../analytics/eventTypes";
import { id } from "date-fns/locale";

const PROJECT_STATUS_TYPE_COMPLETED = 3;

// Get Projects
export const getProjects =
  (skip, limit, typeId = USER_TYPE.STUDENT) =>
  async (dispatch) => {
    try {
      let res;
      //console.log(`Inside getProjects, typeId: ${typeId}`);
      if (skip === SKIP) {
        dispatch({ type: PROJECT_LOADING });
      }
      //console.log(skip, limit);
      if (typeId === USER_TYPE.COMPANY) {
        // Fetch all projects - both public and private
        //console.log("Inside USER_TYPE.COMPANY");
        res = await axios.get(`/api/project/all/${skip}/${limit}`);
      } else {
        res = await axios.get(`/api/project/public/${skip}/${limit}`);
      }

      //console.log(res.data);
      dispatch({
        type: GET_PROJECTS,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const clearPublicProjectsInState = () => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LOADING });
    await dispatch({
      type: CLEAR_PUBLICPROJECTS,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearMyProjects = () => async (dispatch) => {
  await dispatch({
    type: CLEAR_MYPROJECTS,
  });
};

export const clearProject = () => async (dispatch) => {
  await dispatch({
    type: CLEAR_PROJECT,
  });
};

// Get signed in user's projects
export const getMyProjects = () => async (dispatch) => {
  try {
    //await dispatch({ type: MY_PROJECT_LOADING });
    await dispatch({
      type: CLEAR_MYPROJECTS,
    });

    const res = await axios.get("/api/project/me");

    if (res.status === 200) {
      await dispatch({
        type: GET_MYPROJECTS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getAllPublicProjectsByUserId =
  (userId, typeId) => async (dispatch) => {
    try {
      let res;

      dispatch({ type: PROJECT_LOADING });

      if (typeId === USER_TYPE.COMPANY) {
        res = await axios.get(`/api/project/user/all/${userId}`);
      } else {
        res = await axios.get(`/api/project/user/public/${userId}`);
      }

      dispatch({
        type: GET_PROFILE_PROJECTS,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

// Add Like
export const addLike = (projectId, isProjectCard) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/project/like/${projectId}`);

    if (isProjectCard) {
      dispatch({
        type: ADD_LIKE,
        payload: res.data,
      });
    } else {
      dispatch({
        type: ADD_LIKE_PUBLIC_PROJECT,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
// Remove Like
export const removeLike =
  (projectId, userAccountId, isProjectCard) => async (dispatch) => {
    try {
      await axios.put(`/api/project/unlike/${projectId}`);

      if (isProjectCard) {
        dispatch({
          type: REMOVE_LIKE,
          payload: userAccountId,
        });
      } else {
        dispatch({
          type: REMOVE_LIKE_PUBLIC_PROJECT,
          payload: { userAccountId, projectId },
        });
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

// Delete Project
export const deleteProject = (projectId) => async (dispatch) => {
  if (
    window.confirm(
      "Are you sure you want to delete this project? This cannot be undone."
    )
  ) {
    try {
      await axios.del(`/api/project/${projectId}`);

      dispatch({
        type: DELETE_PROJECT,
        payload: projectId,
      });

      dispatch(setAlert("Your Project Has Been Removed :(", "success"));
    } catch (error) {
      dispatch(handleServerError(error));
    }
  }
};

export const deleteProjectFile = (fileId, projectId) => async (dispatch) => {
  try {
    //console.log(fileId, projectId);
    await axios.del(`/api/project/file/${fileId}`);

    await dispatch({
      type: DELETE_PROJECT_FILE,
      payload: { fileId, projectId },
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add Project
export const addProject =
  (formData, imageFormData, filesFormData, editing = false) =>
  async (dispatch) => {
    const projectConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const fileUploadConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      formData = {
        ...formData,
        extLinks: formData.extLinks.filter((link) => {
          return link.externalUrl !== "";
        }),
        endDate:
          formData.projectStatusId === PROJECT_STATUS_TYPE_COMPLETED
            ? formData.endDate
            : null,
      };
      //console.log("Inside redux formData:");
      //console.log(formData);

      let addProjectRes = await axios.post(
        `/api/project`,
        formData,
        projectConfig
      );
      let uploadImageRes;
      if (
        imageFormData &&
        addProjectRes.data &&
        addProjectRes.data.id !== null
      ) {
        uploadImageRes = await axios.post(
          `/api/project/upload/${addProjectRes.data.id}`,
          imageFormData,
          fileUploadConfig
        );
      }
      let uploadFilesRes;
      if (
        filesFormData &&
        addProjectRes.data &&
        addProjectRes.data.id !== null
      ) {
        uploadFilesRes = await axios.post(
          `/api/project/uploadfiles/${addProjectRes.data.id}`,
          filesFormData,
          fileUploadConfig
        );
      }

      // if (editing) {
      //   Event("PROJECT", `Editing Project`, "CLICKED EDIT PROJECT");
      // } else {
      //   Event("PROJECT", `Added New Project`, "CLICKED ADD PROJECT");
      // }

      let payload = {
        ...addProjectRes.data,
      };
      if (uploadImageRes) {
        payload = {
          ...payload,
          projectImages: [{ ...uploadImageRes.data }],
        };
      }
      if (uploadFilesRes) {
        payload = {
          ...payload,
          projectFiles: [...uploadFilesRes.data],
        };
      }
      //console.log(payload);
      if (editing) {
        await dispatch({
          type: EDIT_PROJECT,
          payload: payload,
        });
      } else {
        await dispatch({
          type: ADD_PROJECT,
          payload: payload,
        });
      }

      // dispatch(
      //   setAlert("Awesome! You have added a project to your profile.", "success")
      // );

      // analytics Tracking
      // console.log(formData, imageFormData, filesFormData, editing);
      // analytics.track(A_ADD_EDIT_PROJECT, {
      //   edit: editing,
      //   id: formData.id,
      //   isOwner: formData.isOwner,
      //   isPublic: formData.isPublic,
      //   userAccountId: formData.userAccountId,
      //   projectTeamSize: formData.projectTeam.length,
      //   projectStatusId: formData.projectStatusId,
      //   startDate: formData.startDate,
      //   endDate: formData.endDate,
      //   title: formData.title,
      // });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const uploadImage = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axios.post("/api/project/upload/image", formData, config);

    dispatch({
      type: UPLOAD_PROJECT_IMAGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Get Individual Project
export const getProject = (projectId) => async (dispatch) => {
  try {
    dispatch({ type: PROJECT_LOADING });
    const res = await axios.get(`/api/project/${projectId}`);
    //console.log(res.data);
    dispatch({
      type: GET_PROJECT,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add comment
export const addComment = (projectId, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `/api/project/comment/${projectId}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });

    dispatch(setAlert("Thank you for your comment.", "success"));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Delete comment
export const deleteComment = (projectId, commentId) => async (dispatch) => {
  if (
    window.confirm(
      "Are you sure you want to delete this comment? This cannot be undone."
    )
  ) {
    try {
      await axios.del(`/api/project/comment/${projectId}/${commentId}`);

      dispatch({
        type: REMOVE_COMMENT,
        payload: commentId,
      });

      dispatch(setAlert("Your Comment Was Removed :(", "success"));
    } catch (error) {
      dispatch(handleServerError(error));
    }
  }
};
