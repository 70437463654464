import React from "react";
// Import all the charts here
import LinearProgressChart from "./components/LinearProgressChart";
import BarChart from "./components/BarChart";
import HorizontalBarChart from "./components/HorizontalBarChart";
import BulletChart from "./components/BulletChart";
import TimeSeriesChart from "./components/TimeSeriesChart";
import NumberTitleChart from "./components/NumberTitleChart";
import InterestChart from "./components/InterestChart";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Card from "../../shared/Card";

const Chart = ({ title, subtitle, type, data, ...other }) => {
  let ChartType;
  if (type === "linear-progress") {
    ChartType = LinearProgressChart;
  } else if (type === "bar") {
    ChartType = BarChart;
  } else if (type === "horizontal-bar") {
    ChartType = HorizontalBarChart;
  } else if (type === "bullet") {
    ChartType = BulletChart;
  } else if (type === "time-series") {
    ChartType = TimeSeriesChart;
  } else if (type === "number-title") {
    ChartType = NumberTitleChart;
  } else if (type === "interest") {
    ChartType = InterestChart;
  }

  if (!data) {
    return <Box />;
  }
  return (
    <Card display="flex" flexDirection="column" p={3}>
      {(title || subtitle) && (
        <Box mb={1.5}>
          {title && (
            <Typography variant="body1" component="div" gutterBottom>
              <Box fontWeight={600} lineHeight={1.2}>
                {title}
              </Box>
            </Typography>
          )}
          {subtitle && (
            <Typography color="textSecondary" variant="body2" component="div">
              <Box lineHeight={1.2}>{subtitle}</Box>
            </Typography>
          )}
        </Box>
      )}

      <ChartType data={data} {...other} />
    </Card>
  );
};

export default Chart;
