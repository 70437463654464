import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import clsx from "clsx";
import { fade } from "@material-ui/core/styles/colorManipulator";
import LinearProgress from "@material-ui/core/LinearProgress";

// Colors
import colorList from "./colorList";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 12,
    width: "82%",
    borderRadius: theme.shape.borderRadius,
  },
  bar: (props) => ({
    backgroundColor: `${fade(props.color, 0.8)}`,
  }),
  colorPrimary: (props) => ({
    backgroundColor: `${fade(props.color, 0.2)}`,
  }),
}));

const LinearProgressStyled = ({ color, value }) => {
  const classes = useStyles({ color });
  return (
    <LinearProgress
      variant="determinate"
      // color="primary"
      // Need to find a way for the width and font Size of the number and percentage to be scaleable
      classes={classes}
      value={value}
    />
  );
};

const LinearProgressChart = ({ data }) => {
  return (
    <Box>
      {data.map((categoryCountObject, index) => {
        return (
          <Box key={index} mb={0.5}>
            <Typography variant="body2" component="h4">
              {categoryCountObject.name}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              key={index}
            >
              <LinearProgressStyled
                color={colorList[index]}
                value={
                  (categoryCountObject.count / categoryCountObject.total) * 100
                }
              />

              <Typography variant="body2" component="div">
                <Box>
                  {" "}
                  {`${(
                    (categoryCountObject.count / categoryCountObject.total) *
                    100
                  ).toFixed(1)}% (${categoryCountObject.count}) `}
                </Box>
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default LinearProgressChart;
