import React from "react";
import { Link } from "react-router-dom";
// Material UI
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
// Icons
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import EventIcon from "@material-ui/icons/Event";
// Utils
import ReactImageFallback from "react-image-fallback";
import { DEFAULT_TIME_ZONE } from "../../../utils/globalValues";
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";

const useStyles = makeStyles((theme) => ({
  headerImage: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    objectFit: "cover",
  },
  programCard: {
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    transition: "ease-in-out 0.2s",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.background.default,
    },
  },
}));

const ProgramCard = ({ program }) => {
  const classes = useStyles();

  if (!program) {
    return <></>;
  }

  return (
    <>
      <Card
        component={Link}
        to={`/programs/${program.slug}`}
        key={program.id}
        className={classes.programCard}
      >
        <CardContent>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Box
                style={{
                  position: "relative",
                  paddingBottom: "56.2%",
                }}
              >
                <ReactImageFallback
                  src={program.headerImagePath}
                  fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                  alt={`${program.title}-header-image`}
                  className={classes.headerImage}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
                <Box>
                  <Typography variant="h5" component="h3" gutterBottom>
                    <Box fontWeight={700} lineHeight={1} mb={1.5}>
                      {program.title}
                    </Box>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    <Box
                      lineHeight={1.2}
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                    >
                      {program.tagLine}
                    </Box>
                  </Typography>
                </Box>
                {/* <Box>
                  {!program.isActive && (
                    <Chip
                      icon={<NotInterestedOutlinedIcon />}
                      label="Inactive"
                      color="default"
                    />
                  )}
                  {program.registrationDeadlineDatetime && (
                    <Chip
                      icon={<EventIcon />}
                      label={`Register by ${convertUTCTimeToZonedTime(
                        program.registrationDeadlineDatetime,
                        DEFAULT_TIME_ZONE
                      )}`}
                      color="default"
                    />
                  )}

                  <Chip
                    icon={
                      program.maxPeopleTeam === 1 ? (
                        <PersonOutlinedIcon />
                      ) : (
                        <GroupOutlinedIcon />
                      )
                    }
                    label={program.maxPeopleTeam === 1 ? "Individual" : "Team"}
                    color="default"
                  />
                  {program.location && (
                    <Chip
                      icon={<LocationOnOutlinedIcon />}
                      label={program.location}
                      color="default"
                    />
                  )}
                  {program.round && program.round.length > 0 && (
                    <Chip
                      icon={<AccountBalanceOutlinedIcon />}
                      label={`${program.round.length} Round${
                        program.round.length > 1 ? "s" : ""
                      }`}
                      color="default"
                    />
                  )}
                  {program.isPublic && (
                    <Chip
                      icon={<PublicOutlinedIcon />}
                      label="Public To Pralent"
                      color="default"
                    />
                  )}
                </Box> */}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ProgramCard;
