import React, { useEffect, useState } from "react";
// Redux
import { connect } from "react-redux";
import {
  setSubmissionPrivacySetting,
  setJudgePrivacySetting,
  setCriteriaPrivacySetting,
  setReleaseJudgingScoresSetting,
} from "../../../redux/actions/challenge";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const SettingsView = ({
  challenge,
  currentRoundIndex,
  setSubmissionPrivacySetting,
  setJudgePrivacySetting,
  setCriteriaPrivacySetting,
  setReleaseJudgingScoresSetting,
}) => {
  const [round, setRound] = useState();
  useEffect(() => {
    if (challenge && challenge.round && challenge.round.length > 0) {
      setRound(challenge.round[currentRoundIndex]);
    }
  }, [challenge, currentRoundIndex]);

  // Set the privacy setting
  const handleSubmissionPrivacySetting = async () => {
    await setSubmissionPrivacySetting(
      !round.isSubmissionPublic,
      challenge.id,
      round.id
    );
  };

  const handleJudgePrivacySetting = async () => {
    await setJudgePrivacySetting(!round.isJudgesPublic, challenge.id, round.id);
  };

  const handleCriteriaPrivacySetting = async () => {
    await setCriteriaPrivacySetting(
      !round.isCriteriaPublic,
      challenge.id,
      round.id
    );
  };

  // Results Save
  const handleSaveJudgingReleaseSettings = async (name, value) => {
    if (
      name === "releaseJudging" &&
      !window.confirm(
        `Are you sure you want to ${
          value ? "release" : "make private"
        } the results?`
      )
    ) {
      return;
    }
    let privacyState = {
      releaseJudging: round.releaseJudging,
      releaseScores: round.releaseScores,
      releaseFeedback: round.releaseFeedback,
    };
    privacyState = {
      ...privacyState,
      [name]: value,
    };
    if (name === "releaseJudging") {
      privacyState = {
        ...privacyState,
        releaseScores: value,
        releaseFeedback: value,
      };
    } else if (!privacyState.releaseScores && !privacyState.releaseFeedback) {
      privacyState = {
        ...privacyState,
        releaseJudging: false,
      };
    }

    await setReleaseJudgingScoresSetting(privacyState, challenge.id, round.id);
  };

  return (
    <Container maxWidth="sm">
      <Box py={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h2">
              <Box fontWeight={600}>Settings</Box>
            </Typography>
            <Typography variant="body2" component="div" color="textSecondary">
              <Box mb={2}>
                Manage your privacy and display settings for this round
              </Box>
            </Typography>
            <Divider />
          </Grid>
          {round && (
            <>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isSubmissionPublic"
                        name="isSubmissionPublic"
                        color="primary"
                        checked={round.isSubmissionPublic}
                        onChange={handleSubmissionPrivacySetting}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Public Submissions</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            Public submissions are available for everyone to
                            view. Private submissions are viewable only within
                            your organization.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isJudgesPublic"
                        name="isJudgesPublic"
                        color="primary"
                        checked={round.isJudgesPublic}
                        onChange={handleJudgePrivacySetting}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Public Reviewers</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            Public reviewers are available for everyone to view.
                            Private reviewers are viewable only within your
                            organization.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isCriteriaPublic"
                        name="isCriteriaPublic"
                        color="primary"
                        checked={round.isCriteriaPublic}
                        onChange={handleCriteriaPrivacySetting}
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Public Criterias</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            Public criterias are available for everyone to view.
                            Private criterias are viewable only within your
                            organization.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={round && round.releaseJudging}
                        onChange={(e) =>
                          handleSaveJudgingReleaseSettings(
                            e.target.name,
                            !round.releaseJudging
                          )
                        }
                        name="releaseJudging"
                        color="primary"
                      />
                    }
                    label={
                      <Box ml={1}>
                        <Typography
                          variant="body2"
                          component="div"
                          gutterBottom
                        >
                          <Box fontWeight={600}>Release Results</Box>
                        </Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>
                            If enabled, everyone will be able to view the
                            results of only their results. The names of
                            reviewers will always be anonymous.
                          </Box>
                        </Typography>
                      </Box>
                    }
                  />
                </FormGroup>
              </Grid>
              {round && round.releaseJudging && (
                <Box ml={{ xs: 4, md: 8 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={round && round.releaseScores}
                              onChange={(e) => {
                                handleSaveJudgingReleaseSettings(
                                  e.target.name,
                                  !round.releaseScores
                                );
                              }}
                              name="releaseScores"
                              color="primary"
                            />
                          }
                          label={
                            <Box ml={1}>
                              <Typography
                                variant="body2"
                                component="div"
                                gutterBottom
                              >
                                <Box fontWeight={600}>Release Scores</Box>
                              </Typography>
                              <Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                              >
                                <Box lineHeight={1.2}>
                                  If enabled, scores for each criteria will be
                                  displayed.
                                </Box>
                              </Typography>
                            </Box>
                          }
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={round && round.releaseFeedback}
                              onChange={(e) => {
                                handleSaveJudgingReleaseSettings(
                                  e.target.name,
                                  !round.releaseFeedback
                                );
                              }}
                              color="primary"
                              name="releaseFeedback"
                            />
                          }
                          label={
                            <Box ml={1}>
                              <Typography
                                variant="body2"
                                component="div"
                                gutterBottom
                              >
                                <Box fontWeight={600}>Release Feedback</Box>
                              </Typography>
                              <Typography
                                variant="caption"
                                component="div"
                                color="textSecondary"
                              >
                                <Box lineHeight={1.2}>
                                  If enabled, feedback for each criteria will be
                                  displayed.
                                </Box>
                              </Typography>
                            </Box>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {
  setSubmissionPrivacySetting,
  setJudgePrivacySetting,
  setCriteriaPrivacySetting,
  setReleaseJudgingScoresSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
