import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import PropTypes from "prop-types";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Datefns
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Icons
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { IoCalendarOutline } from "react-icons/io5";
// hooks
import { useGetAllCalendarEventsForDateRange } from "../../hooks/calendar";
// Utils
import format from "date-fns/format";
import { DEFAULT_TIME_ZONE } from "../../utils/globalValues";
import { convertUTCTimeToZonedTime } from "../../utils/utilFunc";
// Components
import Button from "../../shared/Button";
import Spinner from "../../shared/Spinner";
import EmptyState from "../../shared/EmptyState";
import CalendarEventDialog from "./components/CalendarEventDialog";
import CalendarEventCard from "./components/CalendarEventCard";

const CommunityCalendar = ({ user, myOrganizations, companyDetailsQuery }) => {
  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  let foundManageCommunity =
    myOrganizations &&
    (myOrganizations || []).findIndex(
      (elem) => elem.companyId === companyDetails.id
    );

  const manageCommunity =
    Number.isInteger(foundManageCommunity) && foundManageCommunity >= 0;

  const [calendarEvents, setCalendarEvents] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [isOpenMonthPicker, setIsOpenMonthPicker] = useState(false);
  const [selectedMonth, handleMonthChange] = useState(new Date());

  const result = useGetAllCalendarEventsForDateRange(
    companyDetails?.id,
    selectedMonth
  );

  const addNewEventClick = (event) => {
    setOpenDialog(true);
  };

  const groupByDate = (calendarEvents) => {
    const groups = calendarEvents.reduce((groups, ev) => {
      const date = convertUTCTimeToZonedTime(
        new Date(ev.endDateTime),
        DEFAULT_TIME_ZONE,
        "E MMM d, yyyy"
      );
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(ev);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        calEvents: groups[date],
      };
    });

    const sortedGroups = groupArrays.sort(
      (a, b) => new Date(a["date"]) - new Date(b["date"])
    );
    return sortedGroups;
  };

  useEffect(() => {
    if (result.data && result.data.allCalendarEvents) {
      setCalendarEvents(groupByDate(result.data.allCalendarEvents));
    }
  }, [result.data]);

  return (
    <Box>
      <Container maxWidth="xl">
        <Box py={{ xs: 2 }} px={{ xs: 0, md: 2 }}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="space-between"
            style={{ gap: "8px" }}
          >
            <Typography variant="h5" component="h2">
              <Box
                fontWeight={600}
                display="flex"
                alignItems="center"
                style={{ gap: "8px" }}
              >
                <IoCalendarOutline /> Calendar
              </Box>
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              style={{ gap: "4px" }}
            >
              <IconButton
                onClick={() => {
                  let d = new Date(selectedMonth);
                  d.setMonth(d.getMonth() - 1, 1);
                  handleMonthChange(d);
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  open={isOpenMonthPicker}
                  onOpen={() => setIsOpenMonthPicker(true)}
                  onClose={() => setIsOpenMonthPicker(false)}
                  openTo="month"
                  views={["year", "month"]}
                  value={selectedMonth}
                  onChange={handleMonthChange}
                />
              </MuiPickersUtilsProvider>
              <IconButton
                onClick={() => {
                  let d = new Date(selectedMonth);
                  d.setMonth(d.getMonth() + 1, 1);
                  handleMonthChange(d);
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            {manageCommunity && (
              <>
                <CalendarEventDialog
                  companyDetails={companyDetails}
                  selectedMonth={selectedMonth}
                  calendarItem={null}
                  open={openDialog}
                  setOpen={setOpenDialog}
                />
                <Button
                  variant="contained"
                  onClick={() => {
                    addNewEventClick();
                  }}
                >
                  Add Calendar Event
                </Button>
              </>
            )}
          </Box>
          <Box my={2} width="100%">
            <Typography variant="h5" component="div">
              <Box fontWeight={600}>{format(selectedMonth, "MMMM")}</Box>
            </Typography>

            {result.isError && (
              <Alert severity="error">
                {result && result.data && result.data.msg}
              </Alert>
            )}
            {result.isLoading ? (
              <Spinner />
            ) : calendarEvents && calendarEvents.length > 0 ? (
              calendarEvents.map((dateEvents, index) => {
                const date = dateEvents.date;
                const calEvents = dateEvents.calEvents;

                return calEvents && calEvents.length > 0 ? (
                  <Box py={2} key={index}>
                    <Typography
                      variant="body2"
                      component="div"
                      color="textSecondary"
                    >
                      <Box
                        fontWeight={600}
                        style={{ textTransform: "uppercase" }}
                      >
                        {date}
                      </Box>
                    </Typography>
                    {calEvents.map((eventDetails, j) => {
                      return (
                        <CalendarEventCard
                          key={j}
                          calEvent={eventDetails}
                          selectedMonth={selectedMonth}
                          manageCommunity={manageCommunity}
                          companyDetails={companyDetails}
                        />
                      );
                    })}
                  </Box>
                ) : null;
              })
            ) : (
              <EmptyState
                title="No events on the calendar for this month"
                subtitle="There are no events for this month on the community calendar."
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

CommunityCalendar.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  myOrganizations: state.profile.myOrganizations,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityCalendar);
