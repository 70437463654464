import axios from "../utils/axios";
import { useQuery, useMutation } from "react-query";
import { getFirstAndLastDayOfMonth } from "../utils/utilFunc";

export const useGetAllCalendarEventsForDateRange = (
  companyId,
  selectedMonth
) => {
  const result = useQuery(
    [
      "communityCalendarEvents",
      {
        companyId: companyId,
        selectedDateRange: getFirstAndLastDayOfMonth(selectedMonth),
      },
    ],
    getAllCalendarEventsForDateRange,
    {
      enabled: !!companyId,
    }
  );
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const getAllCalendarEventsForDateRange = async ({ queryKey }) => {
  const [_key, { companyId, selectedDateRange }] = queryKey;
  const body = { companyId, selectedDateRange };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const result = await axios.post(`/api/community/calendar/all`, body, config);
  return result.data;
};

export const addEditCalendarEvent = async ({ calEventDetails }) => {
  const body = {
    calEventDetails,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/calendar`, body, config);
};

export const deleteCalendarEvent = async ({ calEventDetails }) => {
  const body = {
    calEventDetails,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/calendar/delete/`, body, config);
};
