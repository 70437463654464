import React, { useState, useEffect, Fragment } from "react";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
import DateFnsUtils from "@date-io/date-fns";
// react-query
import { useMutation, useQueryClient } from "react-query";
import { addEditCalendarEvent } from "../../../hooks/calendar";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
// Helper Functions
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
// Utils
import isBefore from "date-fns/isBefore";
// Components
import Button from "../../../shared/Button";
import { getFirstAndLastDayOfMonth } from "../../../utils/utilFunc";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  appBar: {
    boxShadow: "none",
    marginBottom: 0,
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
}));

const CalendarEventDialog = ({
  companyDetails,
  calendarItem,
  selectedMonth,
  open,
  setOpen,
  setAlert,
}) => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useState({
    id: "",
    title: "",
    companyId: companyDetails.id,
    location: "",
    url: "",
    description: "",
    startDateTime: new Date(),
    endDateTime: new Date(),
  });

  useEffect(() => {
    if (calendarItem) {
      setFormData({
        id: calendarItem.id,
        title: calendarItem.title,
        location: calendarItem.location,
        companyId: companyDetails.id,
        url: calendarItem.url,
        description: calendarItem.description,
        startDateTime: new Date(calendarItem.startDateTime),
        endDateTime: new Date(calendarItem.endDateTime),
      });
    }
  }, [calendarItem, open]);

  const addEditCalendarEventMutation = useMutation(addEditCalendarEvent, {
    onSuccess: async (data) => {
      if (data && data.data) {
        queryClient.invalidateQueries([
          "communityCalendarEvents",
          {
            companyId: companyDetails?.id,
            selectedDateRange: getFirstAndLastDayOfMonth(selectedMonth),
          },
        ]);
        await setAlert(
          `Succesfully ${
            formData?.id ? "updated" : "created"
          } the calendar event!`,
          "success"
        );
        await handleClose();
      }
    },
  });

  const handleClose = async () => {
    await setOpen(!open);
    await clearFormData();
  };

  const handleCalendarEventChange = (e) => {
    let value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const clearFormData = () => {
    setFormData({
      id: "",
      title: "",
      companyId: companyDetails.id,
      location: "",
      url: "",
      description: "",
      startDateTime: new Date(),
      endDateTime: new Date(),
    });
  };

  const validateCalendarInfo = () => {
    const startdt = formData.startDateTime;
    const enddt = formData.endDateTime;

    if (startdt && enddt && isBefore(new Date(enddt), new Date(startdt))) {
      setAlert("Please ensure the start date is before the end date.", "error");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validated = await validateCalendarInfo();
    // Set the date time to correspond to the timezone
    // DateTimePicker always uses the local time
    // First convert the datetime to UTC time
    // assign the converted GMT date based on selected timezone

    if (validated) {
      const body = {
        ...formData,
      };

      await addEditCalendarEventMutation.mutate({
        calEventDetails: body,
      });
    }
  };

  const {
    id,
    title,
    location,
    url,
    companyId,
    description,
    startDateTime,
    endDateTime,
  } = formData;

  return (
    <Fragment>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-create-program-dialog"
      >
        <form onSubmit={(e) => onSubmit(e)}>
          <AppBar className={classes.appBar} position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Box flex="1">
                <Typography variant="h6">Calendar Event</Typography>
              </Box>
              <Button type="submit" color="primary">
                Save
              </Button>
            </Toolbar>
          </AppBar>

          <Box px={3} pb={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  style={{ gap: "6px" }}
                >
                  <HelpOutlineIcon />
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      Add events to your community calendar, perfect for
                      reminders, linking outside of Pralent and much more. If
                      you would like to add an event or program for members to
                      register/attend, please create an event in your
                      organization dashboard.
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Title</InputLabel>
                  <TextField
                    placeholder="Enter a title for your event"
                    id="title"
                    name="title"
                    required
                    value={title}
                    onChange={handleCalendarEventChange}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Url</InputLabel>
                  <TextField
                    placeholder="Enter the unique event url with https:// or http://"
                    id="url"
                    name="url"
                    value={url}
                    onChange={handleCalendarEventChange}
                    type="url"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Description</InputLabel>
                  <TextField
                    placeholder="Enter a small description about the event"
                    id="description"
                    name="description"
                    multiline
                    rows={6}
                    value={description}
                    onChange={handleCalendarEventChange}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Location</InputLabel>
                  <TextField
                    placeholder="Enter the location for this event"
                    id="location"
                    name="location"
                    value={location}
                    onChange={handleCalendarEventChange}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Start Date & Time</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      name="startDateTime"
                      inputVariant="outlined"
                      required
                      value={startDateTime}
                      onChange={(newDateTime) =>
                        setFormData({
                          ...formData,
                          startDateTime: newDateTime,
                        })
                      }
                      onError={console.log}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>{" "}
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>End Date & Time</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      name="endDateTime"
                      inputVariant="outlined"
                      required
                      value={endDateTime}
                      onChange={(newDateTime) =>
                        setFormData({
                          ...formData,
                          endDateTime: newDateTime,
                        })
                      }
                      onError={console.log}
                    />
                  </MuiPickersUtilsProvider>
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </Fragment>
  );
};

const mapDispatchToProps = { setAlert };

export default connect(null, mapDispatchToProps)(CalendarEventDialog);
