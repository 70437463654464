import React from "react";
// Context
import { CommunityProvider } from "./components/CommunityContext";
// Community
import Community from "./components/Community";

const CommunityLayout = ({ children }) => {
  return (
    <CommunityProvider>
      <Community>{children}</Community>
    </CommunityProvider>
  );
};

export default CommunityLayout;
