const StudentOrgFree = () => {
  return (
    <div className="pb-12 bg-gray-50">
      <div className="pt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-md mx-auto lg:max-w-7xl">
          <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
            <div className="flex-1">
              <div>
                <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                  Student & Non-Profit Organizations
                </h3>
              </div>
              <div className="mt-4 text-lg text-gray-600">
                Get full access to all of our premium features for your entire
                student or non-profit organization for{" "}
                <span className="font-semibold text-gray-900">FREE</span>.
              </div>
            </div>
            <div className="mt-6 rounded-md lg:mt-0 lg:ml-10 lg:flex-shrink-0">
              <a
                href="mailto:sales@pralent.com"
                className="bg-gray-800 border border-gray-800 rounded-md mt-2 md:mt-4 py-2 px-8 text-sm font-semibold text-white text-center hover:bg-gray-900"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentOrgFree;
