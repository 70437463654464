import axios from "../utils/axios";
import { useQuery } from "react-query";

export const useGetProfileForPopover = (userId) => {
  const result = useQuery(
    ["profilePopover", { userId: userId }],
    getProfileForPopover,
    {
      enabled: !!open,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

export const getProfileForPopover = async ({ queryKey }) => {
  const [_key, { userId }] = queryKey;
  return await axios.get(`/api/profile/popover/${userId}`);
};

export const useUserBelongsToCompany = (companyId) => {
  const result = useQuery(
    ["belongsTo", { companyId: companyId }],
    userBelongsToCompany
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // Take care of the error message
    // console.log("Error");
  }
  return result;
};

const userBelongsToCompany = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/users/belongs/${companyId}`);
};

export const useGetUserAccountActivityCount = (userId) => {
  const result = useQuery(
    ["userGetActivityCount", { userId: userId }],
    getUserAccountActivityCount
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // TODO: Take care of the error message
    // console.log("Error");
  }
  return result;
};

const getUserAccountActivityCount = async ({ queryKey }) => {
  const [_key, {}] = queryKey;
  return await axios.get("/api/users/activity/count");
};
