/* eslint-disable no-use-before-define */
import React from "react";
// Redux
import { connect } from "react-redux";
import {
  getAllGroupsForRound,
  groupsToSubmission,
  groupsToJudge,
} from "../../redux/actions/group";
// Material UI
import { fade, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Popper from "@material-ui/core/Popper";
import DoneIcon from "@material-ui/icons/Done";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
// Icons
import { IoMdArrowDropdown } from "react-icons/io";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  popper: {
    borderRadius: theme.shape.borderRadius,
    width: 350,
    zIndex: theme.zIndex.drawer,
    fontSize: 13,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    padding: theme.spacing(1.5),
    fontWeight: 600,
    fontSize: "0.8rem",
  },
  inputBase: {
    padding: 10,
    width: "100%",
    "& input": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: `2px solid transparent`,
      fontSize: 14,
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: theme.shadows[8],
    margin: 0,
    fontSize: 13,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  tagChip: {
    cursor: "pointer",
  },
}));

const ChallengeGroupsDropdown = ({
  challenge,
  currentRoundIndex,
  selectedGroups,
  groups,
  getAllGroupsForRound,
  groupsToSubmission,
  groupsToJudge,
  type = "SUBMISSION",
  objectId = null,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([]);
  const [pendingValue, setPendingValue] = React.useState([]);

  const fetchAllGroupsForRound = () => {
    const currentRound = challenge.round[currentRoundIndex];
    getAllGroupsForRound(currentRound.id);
  };

  const handleClick = (event) => {
    let newValue = [];
    (selectedGroups || []).map((g) => {
      newValue.push({
        id: g.id,
        name: g.name,
      });
    });

    setPendingValue(newValue);
    setValue(newValue);
    setAnchorEl(event.currentTarget);

    fetchAllGroupsForRound();
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }

    let groupsToAssign = [];
    let groupsToRemove = [];

    // groups that have already been assigned
    let groupsAssignedSet = new Set();
    (selectedGroups || []).forEach((g) => groupsAssignedSet.add(g.id));
    // groups that are selected
    let pendingValueSet = new Set();
    (pendingValue || []).forEach((g) => pendingValueSet.add(g.id));

    // finding groups to assign
    groupsToAssign = [...pendingValueSet].filter(
      (elem) => !groupsAssignedSet.has(elem)
    );

    // finding groups to remove
    groupsToRemove = [...groupsAssignedSet].filter(
      (elem) => !pendingValueSet.has(elem)
    );

    const currentRound = challenge.round[currentRoundIndex];

    if (type === "SUBMISSION") {
      // add / remove groups for submissions [ REDUX ]
      groupsToSubmission(
        challenge.id,
        currentRound.id,
        groupsToAssign,
        groupsToRemove,
        objectId
      );
    } else if (type === "JUDGE") {
      // add / remove groups for judges [ REDUX ]
      groupsToJudge(
        challenge.id,
        currentRound.id,
        groupsToAssign,
        groupsToRemove,
        objectId
      );
    }

    setValue(pendingValue);

    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "add-groups" : undefined;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        {selectedGroups && selectedGroups.length > 0 ? (
          selectedGroups.map((g, index) => (
            <Chip
              key={index}
              label={g.name}
              color="default"
              className={classes.tagChip}
            />
          ))
        ) : (
          <Typography variant="caption">Add groups</Typography>
        )}
        <IoMdArrowDropdown className="text-xl" />
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <Box className={classes.header}>
          Add groups to this{" "}
          {type === "SUBMISSION"
            ? "submission"
            : type === "JUDGE"
            ? "reviewer"
            : null}
        </Box>

        <Autocomplete
          open
          onClose={handleClose}
          multiple
          autoComplete
          autoHighlight
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue}
          onChange={(event, newValue) => {
            setPendingValue(newValue);
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          loading={groups.loading}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={(option, { selected }) => (
            <>
              <DoneIcon
                style={{ visibility: selected ? "visible" : "hidden" }}
              />

              <Box ml={1} display="flex" flexGrow="1">
                <Typography variant="caption" component="div">
                  <Box fontWeight={600}>{option.name}</Box>
                </Typography>
              </Box>
            </>
          )}
          options={groups.data ? groups.data : []}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            return filtered;
          }}
          noOptionsText="You can add a group to this round using the Groups button above."
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
              placeholder="Search for a group"
              onKeyDown={(event) => {
                if (event.key === "Backspace") {
                  event.stopPropagation();
                }
              }}
            />
          )}
        />
      </Popper>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  groups: state.group.groups,
});

const mapDispatchToProps = {
  getAllGroupsForRound,
  groupsToSubmission,
  groupsToJudge,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeGroupsDropdown);
