import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getMySubmissionWithScores,
  fetchSubmissionAndFormForChallengeRound,
  clearMySubmission,
} from "../../../../redux/actions/challenge";
// Material UI
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core";
// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
// Components
import EmptyState from "../../../../shared/EmptyState";
import ChallengeCustomSubmissionView from "../../../../shared/ChallengeCustomSubmissionView";
// Utils
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  SUBMISSION_TYPE_ID,
  CUSTOM_FORM_TYPE_ID,
} from "../../../../utils/globalValues";

const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "&:hover": {
      boxShadow: theme.shadows[4],
      transition: "ease-in-out 0.2s",
    },
    transition: "ease-in-out 0.2s",
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: -1,
    minHeight: 56,
    borderRadius: theme.shape.borderRadius,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

export const ChallengeViewMySubmission = ({
  loading,
  auth,
  challenge,
  mySubmission,
  myCustomFormSubmission,
  getMySubmissionWithScores,
  fetchSubmissionAndFormForChallengeRound,
  clearMySubmission,
}) => {
  const classes = useStyles();

  const [currentRoundIndex, setCurrentRoundIndex] = useState(0);
  const [toggleResults, setToggleResults] = useState(false);
  const [submissionType, setSubmissionType] = useState(
    SUBMISSION_TYPE_ID.PROJECT
  );
  useEffect(() => {
    if (
      challenge.round &&
      0 <= currentRoundIndex &&
      currentRoundIndex < challenge.round.length
    ) {
      const round = challenge.round[currentRoundIndex];
      setSubmissionType(round.submissionTypeId);
      if (round.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM) {
        fetchSubmissionAndFormForChallengeRound(
          challenge.id,
          round.id,
          CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM
        );
      } else {
        getMySubmissionWithScores(challenge.id, round.id);
      }
    }
    return () => {
      clearMySubmission();
    };
  }, [currentRoundIndex]);

  let project;
  if (
    mySubmission &&
    mySubmission.submission &&
    mySubmission.submission.submittedProjectDetails
  ) {
    project = JSON.parse(mySubmission.submission.submittedProjectDetails);
  }
  return (
    <>
      <Box>
        {!auth.isAuthenticated ? (
          <Box color="grey.900" my={2}>
            <Typography variant="body2" color="textSecondary">
              Please login to view more information about the submissions.
            </Typography>
          </Box>
        ) : (
          <>
            <Select
              className={classes.select}
              margin="dense"
              variant="outlined"
              id="currentRoundIndex"
              value={currentRoundIndex}
              name="currentRoundIndex"
              onChange={(e) => setCurrentRoundIndex(e.target.value)}
            >
              {challenge &&
                challenge.round &&
                challenge.round.length > 0 &&
                challenge.round.map((round, index) => {
                  return (
                    <MenuItem value={index} key={index}>
                      <Typography variant="body2">{round.name}</Typography>
                    </MenuItem>
                  );
                })}
            </Select>
            {mySubmission && mySubmission.submission ? (
              <Box color="grey.900" my={2}>
                <Accordion
                  square
                  expanded={toggleResults}
                  onChange={() => setToggleResults(!toggleResults)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="my-submission-content"
                    id="my-submission-header"
                  >
                    <Box display="flex" alignItems="center">
                      <InfoIcon color="primary" />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        component="div"
                      >
                        <Box fontWeight={600} ml={1}>
                          Click Here To View Your Submission & Results (
                          {mySubmission.submission.submittedStatusTypeName})
                        </Box>
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Box>
                        {submissionType &&
                        submissionType === SUBMISSION_TYPE_ID.CUSTOM ? (
                          <Box>
                            <ChallengeCustomSubmissionView
                              myCustomFormSubmission={myCustomFormSubmission}
                            />
                          </Box>
                        ) : (
                          project && (
                            <Box
                              display="flex"
                              alignItems="start"
                              flexDirection="column"
                            >
                              <Typography variant="h6" component="div">
                                <Box fontWeight={600}>
                                  {project && project.title}
                                </Box>
                              </Typography>
                              <Typography variant="body2" component="div">
                                <Link
                                  component={RouterLink}
                                  to={`/projects/${project.id}`}
                                >
                                  View Project
                                </Link>
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>

                      {challenge &&
                      challenge.round &&
                      challenge.round.length > 0 &&
                      challenge.round[currentRoundIndex] &&
                      challenge.round[currentRoundIndex].releaseJudging ? (
                        <Box>
                          <Divider light className={classes.divider} />
                          <Box mb={2}>
                            <Typography
                              variant="h6"
                              component="div"
                              gutterBottom={false}
                            >
                              <Box fontWeight={600} lineHeight={1.2}>
                                Results
                              </Box>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              Your results have been released by the
                              organization. Please see below for more
                              information. If you have any questions about the
                              results, please contact your program organizer.
                            </Typography>
                          </Box>
                          {mySubmission &&
                          mySubmission.judgingCriteria &&
                          mySubmission.judgingCriteria.length > 0 ? (
                            mySubmission.judgingCriteria.map(
                              (criteria, index) => {
                                const releaseScores =
                                  challenge.round[currentRoundIndex]
                                    .releaseScores;
                                const releaseFeedback =
                                  challenge.round[currentRoundIndex]
                                    .releaseFeedback;
                                const scores =
                                  mySubmission &&
                                  mySubmission.submission.submissionScore &&
                                  mySubmission.submission.submissionScore
                                    .length > 0 &&
                                  mySubmission.submission.submissionScore.filter(
                                    (elem) =>
                                      elem.judgingCriteriaId === criteria.id
                                  );

                                let totalScore = 0;
                                if (scores && scores.length > 0) {
                                  for (let i = 0; i < scores.length; i++) {
                                    totalScore += scores[i].score;
                                  }
                                  totalScore /= scores.length;
                                  totalScore = totalScore.toFixed(2);
                                }

                                return (
                                  <Fragment key={index}>
                                    <Box>
                                      <Box mb={{ xs: 2, md: 4 }}>
                                        <Typography
                                          variant="body2"
                                          component="div"
                                        >
                                          <Box fontWeight={600}>
                                            {criteria.name}
                                          </Box>
                                        </Typography>

                                        <Typography
                                          variant="caption"
                                          component="div"
                                          color="textSecondary"
                                        >
                                          {criteria.description}
                                        </Typography>
                                        {releaseScores && (
                                          <Typography
                                            variant="caption"
                                            color="textSecondary"
                                          >
                                            Avg Score:{" "}
                                            <span>
                                              {totalScore} / {criteria.maxScore}
                                            </span>
                                          </Typography>
                                        )}
                                      </Box>
                                      <PerfectScrollbar>
                                        <TableContainer>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                {releaseFeedback && (
                                                  <TableCell>
                                                    Feedback
                                                  </TableCell>
                                                )}

                                                {releaseScores && (
                                                  <TableCell>Score</TableCell>
                                                )}
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {scores &&
                                                scores.length > 0 &&
                                                scores.map(
                                                  (score, scoreIndex) => {
                                                    return (
                                                      <TableRow
                                                        key={scoreIndex}
                                                      >
                                                        {releaseFeedback && (
                                                          <TableCell>
                                                            <Box whiteSpace="pre-line">
                                                              {score.feedback}
                                                            </Box>
                                                          </TableCell>
                                                        )}
                                                        {releaseScores && (
                                                          <TableCell>
                                                            {score.score}
                                                          </TableCell>
                                                        )}
                                                      </TableRow>
                                                    );
                                                  }
                                                )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </PerfectScrollbar>
                                    </Box>
                                    <Divider className={classes.divider} />
                                  </Fragment>
                                );
                              }
                            )
                          ) : (
                            <Box my={2}>
                              <Typography variant="body2" color="textSecondary">
                                Your submission has not been scored yet.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box my={2}>
                          <Typography variant="body2" color="textSecondary">
                            Your results have not been released by the
                            organization.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ) : (
              <Box my={2}>
                <EmptyState
                  minHeight={100}
                  title="You do not have a submission for this round"
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  auth: state.auth,
  challenge: state.challenge.challenge,
  myCustomFormSubmission: state.challenge.myCustomFormSubmission,
  mySubmission: state.challenge.mySubmission,
});

const mapDispatchToProps = {
  getMySubmissionWithScores,
  fetchSubmissionAndFormForChallengeRound,
  clearMySubmission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeViewMySubmission);
