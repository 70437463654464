import React from "react";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// Components
import Welcome from "./components/Welcome";
import DashboardEvents from "./components/DashboardEvents";
import DashboardPrograms from "./components/DashboardPrograms";
import DashboardJobs from "./components/DasboardJobs";
import Divider from "@material-ui/core/Divider";
// Utils
import { Helmet } from "react-helmet";

const UserDashboard = () => {
  return (
    <Box py={4} flexGrow={1} height="100%" maxHeight="100vh" overflow="auto">
      <Helmet>
        <title>My Dashboard</title>
      </Helmet>
      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" style={{ gap: "24px" }}>
          <Welcome />
          <DashboardEvents />
          <Divider />
          <DashboardPrograms />
          <Divider />
          <DashboardJobs />
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
