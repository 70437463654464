import React from "react";
// Components
import StaticNavbar from "./components/StaticNavbar";
import StaticFooter from "./components/StaticFooter";

const StaticPages = (props) => {
  return (
    <>
      <div className="bg-black">
        <main>
          <StaticNavbar />
          {props.children}
          <StaticFooter />
        </main>
      </div>
    </>
  );
};

export default StaticPages;
