import React, { useState, useEffect } from "react";
// React router
import { Link as RouterLink } from "react-router-dom";
// React query
import {
  useDeleteJobPostFromBoard,
  useUpdateJobPostBoardApprovalStatus,
} from "../../../hooks/job";
// Redux
import { connect } from "react-redux";
// Material UI
import { makeStyles, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
// Components
import Card from "../../../shared/Card";
import CustomAvatar from "../../../shared/CustomAvatar";
// Icons
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// Utils
import format from "date-fns/format";
import { JOB_POST_BOARD_STATUS } from "../../../utils/globalValues";

const CompanyToolTip = withStyles((theme) => ({
  tooltip: {
    maxWidth: "500px",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
  },
}))(Tooltip);

const STATUS_MENU_OPTIONS = [
  { label: "Denied", key: JOB_POST_BOARD_STATUS.DENIED, color: red[900] },
  { label: "Pending", key: JOB_POST_BOARD_STATUS.PENDING, color: amber[900] },
  { label: "Approved", key: JOB_POST_BOARD_STATUS.APPROVED, color: green[900] },
];

const useStyles = makeStyles((theme) => ({
  companyLogo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  TooltipSocialIcons: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1),
    // color: theme.palette.grey[800],
  },
  pendingChip: {
    backgroundColor: amber[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: amber[900],
    },
    "&:focus": {
      backgroundColor: amber[900],
    },
  },
  deniedChip: {
    backgroundColor: red[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: red[900],
    },
    "&:focus": {
      backgroundColor: red[900],
    },
  },
  approvedChip: {
    backgroundColor: green[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: green[900],
    },
    "&:focus": {
      backgroundColor: green[900],
    },
  },
}));

const ExternalJobCard = ({
  job,
  companyProfile,
  jobBoard: { data: dataJobBoard },
}) => {
  const classes = useStyles();

  const deleteJobPostFromBoardMutation = useDeleteJobPostFromBoard(
    companyProfile.id
  );
  const updateJobPostBoardApprovalStatusMutation =
    useUpdateJobPostBoardApprovalStatus();

  const [anchorElStatusMenu, setAnchorElStatusMenu] = React.useState(null);
  const openStatusMenu = Boolean(anchorElStatusMenu);

  const handleClickStatusMenu = (event) => {
    setAnchorElStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElStatusMenu(null);
  };

  const handleUpdateJobStatus = async (approvalStatus) => {
    const jobPostId = job.id;
    const jobBoardCompanyId = companyProfile.id;
    updateJobPostBoardApprovalStatusMutation.mutate({
      jobPostId,
      jobBoardCompanyId,
      approvalStatus,
    });

    handleCloseStatusMenu();
  };

  const handleDeleteJobPost = async () => {
    if (
      window.confirm(
        "Are you sure you would like to remove this job post from your job board?"
      )
    ) {
      deleteJobPostFromBoardMutation.mutate({
        jobPostId: job.id,
        jobBoardId: dataJobBoard && dataJobBoard.id,
      });
    }
  };

  const approvalStatus =
    job.JobPostBoardRelationship && job.JobPostBoardRelationship.approvalStatus;
  const approvalStatusName =
    approvalStatus === JOB_POST_BOARD_STATUS.PENDING
      ? "Pending"
      : approvalStatus === JOB_POST_BOARD_STATUS.APPROVED
      ? "Approved"
      : approvalStatus === JOB_POST_BOARD_STATUS.DENIED
      ? "Denied"
      : "";

  return (
    <Card
      key={job.id}
      borderRadius="borderRadius"
      bgcolor="background.paper"
      p={2}
      mb={2}
      display="flex"
      alignItems="center"
      width="100%"
    >
      <CustomAvatar
        alt={job?.company?.companyName}
        src={job?.company?.companyLogoPath}
        fallbackText={job?.company?.companyName}
        className={classes.companyLogo}
      />
      <Box flexGrow={1}>
        <Typography variant="h6" component="h3">
          <Box fontWeight={600}>{job.title}</Box>
        </Typography>
        <Typography
          variant="body1"
          component="div"
          color="textSecondary"
          style={{ cursor: "pointer", display: "inline-block" }}
        >
          <CompanyToolTip
            interactive
            title={
              <Box textAlign="left">
                {job.company && (
                  <>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ lineHeight: 1 }}
                        color="textPrimary"
                      >
                        <strong>{job.company.companyName}</strong>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {job.company.location && `${job.company.location}`}
                        {job.company.companyStage &&
                          ` | ${job.company.companyStage}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ lineHeight: 1.3 }}
                      >
                        {job.company.companyDescription}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" mt={2}>
                      {job.company.companyWebsiteUrl && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={job.company.companyWebsiteUrl}
                        >
                          <LanguageIcon
                            className={classes.TooltipSocialIcons}
                          />
                        </Link>
                      )}
                      {job.company.linkedinUrl && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={job.company.linkedinUrl}
                        >
                          <LinkedInIcon
                            className={classes.TooltipSocialIcons}
                          />
                        </Link>
                      )}
                      {job.company.twitterUrl && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={job.company.twitterUrl}
                        >
                          <TwitterIcon className={classes.TooltipSocialIcons} />
                        </Link>
                      )}
                      {job.company.facebookUrl && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={job.company.facebookUrl}
                        >
                          <FacebookIcon
                            className={classes.TooltipSocialIcons}
                          />
                        </Link>
                      )}
                      {job.company.instagramUrl && (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          href={job.company.instagramUrl}
                        >
                          <InstagramIcon
                            className={classes.TooltipSocialIcons}
                          />
                        </Link>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            }
            placement="bottom-start"
          >
            <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
              {job.company && job.company.companyName}{" "}
              <InfoOutlinedIcon fontSize="small" />
            </Box>
          </CompanyToolTip>
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          style={{ gap: "12px" }}
        >
          <Typography variant="caption" color="textSecondary">
            {job.jobCategory}
          </Typography>
          {job.location && (
            <Typography variant="caption" color="textSecondary">
              {job.location}
            </Typography>
          )}
          {job.postedDate && (
            <Typography variant="caption" color="textSecondary">
              Posted on {format(new Date(job.postedDate), "MMM dd, yyyy")}
            </Typography>
          )}
          {job.closeDateTime && (
            <Typography component="div" variant="caption" color="textSecondary">
              <Box mr={1}>
                Closes {format(new Date(job.closeDateTime), "MMM dd, yyyy")}
              </Box>
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
        <Menu
          id="long-menu"
          getContentAnchorEl={null}
          anchorEl={anchorElStatusMenu}
          keepMounted
          open={openStatusMenu}
          onClose={handleCloseStatusMenu}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          {STATUS_MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.key}
              selected={option.label === approvalStatusName}
              onClick={() => handleUpdateJobStatus(option.key)}
            >
              <Box
                width={12}
                height={12}
                bgcolor={option.color}
                borderRadius="borderRadius"
                marginRight={1}
              />
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        {dataJobBoard &&
          dataJobBoard.allowOutsideOrganizations &&
          dataJobBoard.outsideOrganizationsNeedApproval && (
            <Tooltip title="Change the status of this job post">
              <Chip
                label={approvalStatusName}
                onClick={handleClickStatusMenu}
                clickable
                className={
                  approvalStatus === JOB_POST_BOARD_STATUS.PENDING
                    ? classes.pendingChip
                    : approvalStatus === JOB_POST_BOARD_STATUS.APPROVED
                    ? classes.approvedChip
                    : approvalStatus === JOB_POST_BOARD_STATUS.DENIED
                    ? classes.deniedChip
                    : null
                }
              />
            </Tooltip>
          )}

        {job.isActive ? (
          <Chip label="Active" color="primary" />
        ) : (
          <Chip label="Inactive" color="default" />
        )}

        {companyProfile && (
          <Tooltip title="Open job post link">
            <IconButton
              component={RouterLink}
              to={`/c/${companyProfile.communitySlug}/jobs/${job.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Delete job post from board">
          <IconButton onClick={handleDeleteJobPost}>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
  jobBoard: state.jobs.jobBoard,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalJobCard);
