import React, { useCallback, useMemo, useRef } from "react";
import isHotkey from "is-hotkey";
import isUrl from "is-url";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, Range, createEditor } from "slate";
import { withHistory } from "slate-history";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdCode,
  MdFormatQuote,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdLink,
} from "react-icons/md";

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const useStyles = makeStyles((theme) => ({
  formControl: {
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius,
  },
  smallEditable: {
    minHeight: 200,
    margin: 5,
  },
  mediumEditable: {
    minHeight: 300,
    margin: 10,
  },
  largeEditable: {
    minHeight: 400,
    margin: 15,
  },
}));

const RichTextEditor = ({
  value,
  setValue,
  required,
  fullWidth,
  placeholder,
  size = "medium",
  readOnly = false,
}) => {
  const classes = useStyles();

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editorRef = useRef();
  if (!editorRef.current)
    editorRef.current = withLinks(withHistory(withReact(createEditor())));
  const editor = editorRef.current;

  if (readOnly) {
    return (
      <Slate
        editor={editor}
        value={value || initialValue}
        onChange={(value) => setValue(value)}
      >
        <Editable
          readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    );
  }

  return (
    <FormControl
      className={classes.formControl}
      required={required || false}
      fullWidth={fullWidth || true}
    >
      <Slate
        editor={editor}
        value={value || initialValue}
        onChange={(newValue) => setValue(newValue)}
      >
        <Box
          display="flex"
          alignItems="center"
          style={{ gap: "10px" }}
          borderRadius="borderRadius"
          p={1}
        >
          <MarkButton format="bold" icon={<MdFormatBold />} />
          <MarkButton format="italic" icon={<MdFormatItalic />} />
          <MarkButton format="underline" icon={<MdFormatUnderlined />} />
          <MarkButton format="code" icon={<MdCode />} />
          <BlockButton format="heading-one" icon={"H1"} />
          <BlockButton format="heading-two" icon={"H2"} />
          <BlockButton format="block-quote" icon={<MdFormatQuote />} />
          <BlockButton format="numbered-list" icon={<MdFormatListNumbered />} />
          <BlockButton format="bulleted-list" icon={<MdFormatListBulleted />} />
          <LinkButton icon={<MdLink />} />
        </Box>

        <Box fontSize={18} my={1}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder || "Enter some text…"}
            spellCheck
            className={
              size == "small"
                ? classes.smallEditable
                : size == "medium"
                ? classes.mediumEditable
                : size == "large"
                ? classes.largeEditable
                : null
            }
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </Box>
      </Slate>
    </FormControl>
  );
};

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "link":
      return (
        <a
          {...attributes}
          href={element.url}
          className="text-primary-400 hover:text-primary-500 underline"
        >
          {children}
        </a>
      );
    case "block-quote":
      return (
        <blockquote
          className="my-2 border-l-4 border-gray-400 py-2"
          {...attributes}
        >
          <div className="ml-4">{children}</div>
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul className="list-inside list-disc text-base" {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 className="text-2xl font-semibold leading-tight" {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 className="text-xl font-semibold leading-tight" {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li className="ml-2 text-base md:ml-4" {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol className="list-inside list-decimal text-base" {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p className="text-base" {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <span className="font-semibold">{children}</span>;
  }

  if (leaf.code) {
    children = <code className="bg-gray-200">{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  const active = isBlockActive(editor, format);
  return (
    <IconButton
      type="button"
      color={active ? "primary" : "default"}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </IconButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  const active = isMarkActive(editor, format);
  return (
    <IconButton
      type="button"
      color={active ? "primary" : "default"}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </IconButton>
  );
};

const LinkButton = ({ icon }) => {
  const editor = useSlate();
  const active = isLinkActive(editor);
  return (
    <IconButton
      type="button"
      color={active ? "primary" : "default"}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link with http://");

        if (!url) return;
        insertLink(editor, url);
      }}
    >
      {icon}
    </IconButton>
  );
};

export default RichTextEditor;
