import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// Icons
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import {
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";
// Components
import Button from "../Button";
// Utils
import { Emoji } from "emoji-mart";
import { ROOM_TYPE } from "../../utils/globalValues";

export const OrgRoomAppBar = ({ room, companyProfile }) => {
  const params = useParams();
  const location = useLocation();
  const orgId = params && params.orgId ? params.orgId : "";

  const ROOM_TABS = [
    {
      name: "Members",
      icon: <PeopleOutlinedIcon />,
      route: `/org/${orgId}/rooms/${params.roomId}/members`,
    },
    {
      name: "Edit",
      icon: <EditOutlinedIcon />,
      route: `/org/${orgId}/rooms/${params.roomId}/edit`,
    },
    // {
    //   name: "Emails",
    //   icon: <MailOutlineOutlinedIcon />,
    //   route: `/org/${orgId}/rooms/${params.roomId}/emails`,
    // },
    // {
    //   name: "Insights",
    //   icon: <PollOutlinedIcon />,
    //   route: `/org/${orgId}/rooms/${params.roomId}/insights`,
    // },
    // {
    //   name: "Settings",
    //   icon: <SettingsOutlinedIcon />,
    //   route: `/org/${orgId}/rooms/${params.roomId}/settings`,
    // },
  ];

  return (
    <Box mb={{ xs: 2, md: 4 }}>
      <Container maxWidth="lg">
        {room && (
          <Box display="flex" mb={2}>
            <Box flexGrow={1}>
              <Typography variant="h5" component="h1">
                <Box fontWeight={600}>
                  <Typography variant="h6" component="div">
                    <Box
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                      style={{ gap: "12px" }}
                    >
                      {room.type === ROOM_TYPE.CHAT ? (
                        <HiOutlineChat />
                      ) : room.type === ROOM_TYPE.FORUM ? (
                        <HiOutlinePencilAlt />
                      ) : room.type == ROOM_TYPE.BOARD ? (
                        <HiOutlineViewGrid />
                      ) : (
                        <HiHashtag />
                      )}
                      {room.name} <Emoji emoji={room.emoji} size={20} />
                    </Box>
                  </Typography>
                </Box>
              </Typography>
              {room.description && (
                <Typography
                  color="textSecondary"
                  variant="body2"
                  component="div"
                >
                  <Box>{room.description}</Box>
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body2" component="div">
                <Box fontWeight={600}>View room</Box>
              </Typography>
              <Button
                component={Link}
                to={`/c/${companyProfile.communitySlug}/rooms/${room.name}`}
                variant="outlined"
                style={{
                  textTransform: "none",
                  lineHeight: 1.1,
                }}
                endIcon={<OpenInBrowserOutlinedIcon />}
              >{`/c/${companyProfile.communitySlug}/rooms/${room.name}`}</Button>
            </Box>
          </Box>
        )}
        <Tabs
          variant="scrollable"
          scrollButtons="off"
          value={location.pathname}
        >
          {ROOM_TABS &&
            ROOM_TABS.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  label={
                    <Box display="flex" alignItems="center">
                      <Box>{tab.name}</Box>
                    </Box>
                  }
                  component={Link}
                  to={tab.route}
                  value={tab.route}
                />
              );
            })}
        </Tabs>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgRoomAppBar);
