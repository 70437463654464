import React from "react";
// React Router Dom
import { Link, useLocation } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// React query
import { useGetAllRoomsForCommunity } from "../../../hooks/room";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// Components
import PTable from "../../../shared/PTable";
import Spinner from "../../../shared/Spinner";
import EmptyState from "../../../shared/EmptyState";
// Utils
import { Emoji } from "emoji-mart";
import { ROOM_TYPE } from "../../../utils/globalValues";
import format from "date-fns/format";
// Icons
import {
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";

const OrgRoomsTable = ({ companyProfile }) => {
  const location = useLocation();
  const {
    isLoading: isLoadingRooms,
    isFetching: isFetchingRooms,
    data: { data: communityRooms } = { data: null },
    isError: isErrorRooms,
    error: errorRooms,
  } = useGetAllRoomsForCommunity(companyProfile?.id);

  const headerCells = [
    {
      id: "id",
      label: "id",
      hide: true,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "manage",
      label: "Manage",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "name",
      label: "Room Name",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
    {
      id: "type",
      label: "Type",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
    {
      id: "settings",
      label: "Settings",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "privacy",
      label: "Privacy",
      hide: false,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "createdDateTime",
      label: "Created",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
    {
      id: "viewRoom",
      label: "View",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
  ];

  const columns = [
    {
      field: "id",
      renderCell: (data) => {
        return data.id;
      },
    },
    {
      field: "manage",
      renderCell: (data) => {
        return (
          <IconButton
            component={Link}
            to={`${location.pathname}/${data.id}/members`}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      field: "name",
      renderCell: (data) => {
        return (
          <Box display="flex" alignItems="center">
            <Typography variant="body2" component="div">
              <Box fontWeight={600}>{data.name}</Box>
            </Typography>
            <Typography variant="body2" component="div">
              <Box lineHeight={0.75} ml={1} mb={0}>
                <Emoji emoji={data.emoji} size={16} />
              </Box>
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "type",
      renderCell: (data) => {
        return (
          <Box>
            {data.type === ROOM_TYPE.CHAT ? (
              <Box
                display="flex"
                alignItems="center"
                lineHeight={1.2}
                style={{ gap: "8px" }}
              >
                <HiOutlineChat /> Chat
              </Box>
            ) : data.type === ROOM_TYPE.FORUM ? (
              <Box
                display="flex"
                alignItems="center"
                lineHeight={1.2}
                style={{ gap: "8px" }}
              >
                <HiOutlinePencilAlt /> Forum
              </Box>
            ) : data.type == ROOM_TYPE.BOARD ? (
              <Box
                display="flex"
                alignItems="center"
                lineHeight={1.2}
                style={{ gap: "8px" }}
              >
                <HiOutlineViewGrid /> Board
              </Box>
            ) : (
              <HiHashtag />
            )}
          </Box>
        );
      },
    },
    {
      field: "settings",
      renderCell: (data) => {
        return (
          <Box>
            <Typography variant="body2">
              {data.isActive ? "Active" : "Archived"}
              {data.isDefault ? ", Default" : ""}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "privacy",
      renderCell: (data) => {
        return (
          <Box>
            <Typography variant="body2">
              {data.isPublic ? "Public" : "Private"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "createdDateTime",
      renderCell: (data) => {
        return format(
          new Date(data.createdDateTime),
          "E, MMM d, yyyy h:mmaaa zzz"
        );
      },
    },
    {
      field: "viewRoom",
      renderCell: (data) => {
        return (
          <Button
            component={Link}
            to={`/c/${companyProfile.communitySlug}/rooms/${data.name}`}
            variant="text"
            color="inherit"
            startIcon={<OpenInNewOutlinedIcon />}
          >
            Preview
          </Button>
        );
      },
    },
  ];

  return (
    <Box>
      {isLoadingRooms ? (
        <Spinner />
      ) : communityRooms && communityRooms.length > 0 ? (
        <PTable
          records={communityRooms}
          headerCells={headerCells}
          columns={columns}
          noRowsText="There are no rooms in your community at this time."
          isLoading={isFetchingRooms || isLoadingRooms}
        />
      ) : (
        <EmptyState
          title="No rooms have been added yet"
          subtitle="Manage all of your community rooms in one place."
          link="https://www.notion.so/Managing-members-20a7d241fa5d4fd7a7c818d8ad645346"
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    companyProfile: state.company.companyProfile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgRoomsTable);
