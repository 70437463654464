import React from "react";

import IframeResizer from "iframe-resizer-react";

const Changelog = () => {
  return (
    <div className="bg-white w-full h-full mx-auto max-w-4xl">
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-lg max-w-3xl mx-auto">
          <h1>
            <span className="block text-base text-center text-primary-600 font-semibold tracking-wide uppercase">
              New Features
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Changelog
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8 text-center">
            Below is the changelog for pralent.com which details all of the new
            features, updates, and bug fixes to the platform. If you have any
            questions about any new features, please contact{" "}
            <a
              href="mailto:info@pralent.com"
              className="text-primary-500 underline"
            >
              info@pralent.com
            </a>
            . We are happy to assist you in any way we can.
          </p>
        </div>

        <div className="h-full my-4 md:my-6 rounded-md shadow-2xl">
          <iframe
            src="https://docs.google.com/document/d/e/2PACX-1vTatQ1ez6YYVHc7BY8GdNi_ankFFaGfcbjkRf9gusktwuxAlLOqrt4a8FyLIYEZVfw3CTha02pWGpXi/pub?embedded=true"
            style={{ minWidth: "100%", height: "80rem" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Changelog;
