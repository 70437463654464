import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import {
  setAllActiveStatus,
  sendInviteToJudges,
} from "../../../redux/actions/judge";
import { enableJudgingPassword } from "../../../redux/actions/challenge";
// Material UI
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// Icons
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// Components
import ChallengeJudgeBulkUpload from "./ChallengeJudgeBulkUpload";

export const JudgesMore = ({
  challenge,
  currentRoundIndex,
  setAllActiveStatus,
  enableJudgingPassword,
  sendInviteToJudges,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activateDeactivateJudges = (activate) => {
    if (
      challenge &&
      challenge.round &&
      window.confirm(
        `Are you sure you want to ${
          activate ? "activate" : "deactivate"
        } all the reviewers?`
      )
    ) {
      const currentRoundId = challenge.round[currentRoundIndex].id;
      setAllActiveStatus(currentRoundId, activate);
      handleClose();
    }
  };

  const handleEnabledJudgingPassword = async (e) => {
    const isEnabled = e.target.checked;
    if (
      challenge &&
      challenge.round &&
      window.confirm(
        `Are you sure you want to ${
          isEnabled ? "enabled" : "disable"
        } reviewer password protection?`
      )
    ) {
      const currentRoundId = challenge.round[currentRoundIndex].id;

      await enableJudgingPassword(currentRoundId, isEnabled);
      handleClose();
    }
  };

  const handleSendingJudgeInvite = async () => {
    if (
      challenge &&
      challenge.round &&
      challenge.round.length > 0 &&
      currentRoundIndex < challenge.round.length
    ) {
      const currentRoundId = challenge.round[currentRoundIndex].id;

      await sendInviteToJudges(challenge.id, currentRoundId);
      handleClose();
    }
  };

  const [openBulkUpload, setOpenBulkUpload] = useState(false);

  return (
    <>
      <Tooltip title="More options for reviewing" placement="top">
        <IconButton color="primary" onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="reviewers-more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSendingJudgeInvite}>
          <Tooltip title="Send an invitation email to active reviewers for this round. Reviewers that have already received an email will not receive another one.">
            <Box>Send Invitation To Reviewers</Box>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => activateDeactivateJudges(true)}>
          Activate All Reviewers
        </MenuItem>
        <MenuItem onClick={() => activateDeactivateJudges(false)}>
          Deactivate All Reviewers
        </MenuItem>
        <MenuItem
          component={Link}
          to={`/programs/${challenge.slug}/reviewer/signup/${challenge.round[currentRoundIndex].id}`}
          underline="none"
        >
          <Tooltip title="Open the link that reviewers can use to enter their own information for this program round.">
            <span>Open Sign Up Link</span>
          </Tooltip>
        </MenuItem>
        <MenuItem>
          <Tooltip title="If enabled, reviewers will have to enter a unique & secure password before they begin the reviewing process. The unique password will be emailed to them when you click 'Send Invitation'.">
            <Box display="flex" alignItems="center">
              Reviewer Password Enabled
              <Switch
                color="primary"
                checked={
                  challenge &&
                  challenge.round &&
                  challenge.round.length > 0 &&
                  challenge.round[currentRoundIndex].judgingPasswordEnabled
                }
                onClick={handleEnabledJudgingPassword}
              />
            </Box>
          </Tooltip>
        </MenuItem>
        <MenuItem onClick={() => setOpenBulkUpload(true)}>
          Bulk Upload Reviewers
        </MenuItem>
      </Menu>
      <ChallengeJudgeBulkUpload
        open={openBulkUpload}
        setOpen={setOpenBulkUpload}
      />
    </>
  );
};

JudgesMore.propTypes = {
  challenge: PropTypes.object,
  currentRoundIndex: PropTypes.number,
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {
  setAllActiveStatus,
  enableJudgingPassword,
  sendInviteToJudges,
};

export default connect(mapStateToProps, mapDispatchToProps)(JudgesMore);
