import {
  GET_PROJECTS,
  GET_MYPROJECTS,
  PROJECT_ERROR,
  ADD_LIKE,
  REMOVE_LIKE,
  DELETE_PROJECT,
  ADD_PROJECT,
  EDIT_PROJECT,
  GET_PROJECT,
  ADD_COMMENT,
  REMOVE_COMMENT,
  UPLOAD_PROJECT_IMAGE,
  CLEAR_MYPROJECTS,
  CLEAR_PUBLICPROJECTS,
  ADD_LIKE_PUBLIC_PROJECT,
  REMOVE_LIKE_PUBLIC_PROJECT,
  MY_PROJECT_LOADING,
  DELETE_PROJECT_FILE,
  CLEAR_PROJECT,
  //DELETE_PROJECT_IMAGE,
  PROJECT_LOADING,
} from "../actions/actionTypes";
import { SKIP, LIMIT } from "../../utils/globalValues";
import { clearPublicProjectsInState } from "../actions/project";

const initialState = {
  publicProjects: [],
  myProjects: [],
  project: null,
  loading: true,
  error: {},
  skip: SKIP,
  limit: LIMIT,
  hasMore: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MY_PROJECT_LOADING:
      return {
        ...state,
        myProjects: [],
      };
    case PROJECT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECTS:
      return {
        ...state,
        publicProjects: [...state.publicProjects, ...payload.publicProjects],
        skip: state.skip + state.limit,
        hasMore: payload.hasMore,
        loading: false,
      };
    case CLEAR_PUBLICPROJECTS:
      return {
        ...state,
        publicProjects: [],
        skip: SKIP,
        limit: LIMIT,
        loading: false,
      };
    case GET_MYPROJECTS:
      return {
        ...state,
        myProjects: payload,
        loading: false,
      };
    case GET_PROJECT:
      return {
        ...state,
        project: payload,
        loading: false,
      };
    case ADD_PROJECT:
      return {
        ...state,
        myProjects: [payload, ...state.myProjects],
        loading: false,
      };
    case EDIT_PROJECT:
      const indx = state.myProjects.findIndex((elem) => elem.id === payload.id);
      let preMyProjects = [...state.myProjects];
      preMyProjects[indx] = {
        ...preMyProjects[indx],
        ...payload,
      };
      return {
        ...state,
        myProjects: preMyProjects,
      };

    case DELETE_PROJECT_FILE: {
      const { fileId, projectId } = payload;
      const indx = state.myProjects.findIndex((elem) => elem.id === projectId);
      let preMyProjects = [...state.myProjects];

      let projectToUpdate = preMyProjects[indx];
      let fileIndx = projectToUpdate.projectFiles.findIndex((elem) => {
        if (elem.id && elem.id === fileId) return true;
        return false;
      });
      let files = [
        ...projectToUpdate.projectFiles.slice(0, fileIndx),
        ...projectToUpdate.projectFiles.slice(
          fileIndx + 1,
          projectToUpdate.length
        ),
      ];
      preMyProjects[indx] = {
        ...preMyProjects[indx],
        projectFiles: files,
      };
      return {
        ...state,
        myProjects: preMyProjects,
      };
    }

    case DELETE_PROJECT:
      const newProjectList = state.myProjects.filter(
        (project) => project.id !== payload
      );
      return {
        ...state,
        myProjects: newProjectList,
      };
    case PROJECT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPLOAD_PROJECT_IMAGE: {
      return {
        ...state,
        myproject: {
          ...state.myproject,
          imageLocation: payload.filePath,
        },
      };
    }
    /* case DELETE_PROJECT_IMAGE: {
      return {
        ...state,
        myproject: {
          ...state.myproject,
          imageLocation: payload.filePath
        }
      };
    } */
    case ADD_LIKE:
      return {
        ...state,
        project: {
          ...state.project,
          likes: [...state.project.likes, payload],
        },
      };
    case ADD_LIKE_PUBLIC_PROJECT:
      return {
        ...state,
        publicProjects: state.publicProjects.map((project, index) => {
          if (project.id !== payload.projectId) {
            return project;
          } else {
            let newLikes = [...project.likes, payload];
            return {
              ...project,
              likes: newLikes,
            };
          }
        }),
      };
    case REMOVE_LIKE:
      return {
        ...state,
        project: {
          ...state.project,
          likes: state.project.likes.filter(
            (elem) => elem.userAccountId !== payload
          ),
        },
      };
    case REMOVE_LIKE_PUBLIC_PROJECT:
      return {
        ...state,
        publicProjects: state.publicProjects.map((project) => {
          if (project.id !== payload.projectId) {
            return project;
          } else {
            let newLikes = project.likes.filter(
              (elem) => elem.userAccountId !== payload.userAccountId
            );
            return {
              ...project,
              likes: newLikes,
            };
          }
        }),
      };

    case ADD_COMMENT:
      return {
        ...state,
        project: {
          ...state.project,
          comments: [payload, ...state.project.comments],
        },
        loading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        project: {
          ...state.project,
          comments: state.project.comments.filter(
            (comment) => comment.id !== payload
          ),
        },
        loading: false,
      };
    case CLEAR_PROJECT:
      return {
        ...state,
        project: null,
      };
    case CLEAR_MYPROJECTS:
      return {
        ...state,
        myProjects: [],
      };
    default:
      return state;
  }
}
