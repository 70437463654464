import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
// React Router
import { useParams, Link } from "react-router-dom";
// React query
import { useFetchCommunityMessagingAppSettings } from "../../hooks/community";
import { useGetAllMyRoomsForCommunity } from "../../hooks/room";
// Material UI
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// Components
import CommunityMenu from "./components/CommunityMenu";
// Icons
import {
  IoLogoSlack,
  IoLogoDiscord,
  IoCalendarOutline,
  IoPeopleOutline,
  IoBulbOutline,
  IoHomeOutline,
  IoTicketOutline,
  IoBriefcaseOutline,
  IoReaderOutline,
} from "react-icons/io5";
// Colors
import blueGrey from "@material-ui/core/colors/blueGrey";
// Utils
import { Emoji } from "emoji-mart";

const SIDEBAR_TO_DISPLAY = {
  home: true,
  calendar: true,
  members: true,
  programs: true,
  events: true,
  jobs: true,
  knowledge: true,
};

const useStyles = makeStyles((theme) => ({
  SidebarBox: {
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      minWidth: 250,
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 225,
      width: 225,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
      width: 200,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
      overflow: "hidden",
    },
  },
  List: {
    width: "100%",
    padding: 0,
  },
  ListItemIcon: (props) => ({
    margin: "auto",
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.primary,
    minWidth: 0,
    borderRadius: props.iconHover && theme.shape.borderRadius,
    borderColor:
      props.iconHover && props.currentTab ? blueGrey[300] : "transparent",
    borderWidth: props.iconHover && "3px",
    padding: props.iconHover && "3px",
    transition: "0.2s ease-in-out",
    "&:hover": {
      borderColor: !props.iconHover ? "transparent" : blueGrey[100],
      transition: "0.2s ease-in-out",
    },
  }),
  ListItemText: {
    fontSize: theme.typography.body2.fontSize,
    color:
      theme.palette.type === "dark"
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
    lineHeight: 1.2,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginLeft: theme.spacing(1.5),
  },
  ListItem: {
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(0.4),
    paddingBottom: theme.spacing(0.4),
  },
}));

const CommunitySidebar = ({ companyDetailsQuery, user }) => {
  const classes = useStyles();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  // const {
  //   isLoading: isLoadingRooms,
  //   data: { data: myCommunityRooms } = { data: null },
  //   isError: isErrorRooms,
  //   error: errorRooms,
  // } = useGetAllMyRoomsForCommunity(companyDetails.id, user && user.id);

  const {
    isLoading: isMessagingAppLoading,
    isError: isMessagingAppError,
    data: { messagingAppSettings: messagingAppSettings } = {
      messagingAppSettings: null,
    },
    error: messagingAppError,
  } = useFetchCommunityMessagingAppSettings(companyDetails);

  const { communitySlug } = useParams();

  const BASE_COMMUNITY_URL = `/c/${communitySlug}`;

  const [sidebarMenuItems, setSidebarMenuItems] = useState([]);

  useEffect(() => {
    const { programs, events, jobs, knowledge } = SIDEBAR_TO_DISPLAY;

    let newSidebarItems = [
      // Include default links
      {
        name: "Home",
        to: `${BASE_COMMUNITY_URL}`,
        leftIcon: <IoHomeOutline />,
      },
      {
        name: "Calendar",
        to: `${BASE_COMMUNITY_URL}/calendar`,
        leftIcon: <IoCalendarOutline />,
      },
      {
        name: "Members",
        to: `${BASE_COMMUNITY_URL}/members`,
        // leftIcon: "🙋",
        leftIcon: <IoPeopleOutline />,
      },
    ];

    if (programs) {
      newSidebarItems.push({
        name: "Programs",
        to: `${BASE_COMMUNITY_URL}/programs`,
        leftIcon: <IoBulbOutline />,
      });
    }
    if (events) {
      newSidebarItems.push({
        name: "Events",
        to: `${BASE_COMMUNITY_URL}/events`,
        // leftIcon: "🎟️",
        leftIcon: <IoTicketOutline />,
      });
    }
    if (jobs) {
      newSidebarItems.push({
        name: "Job Board",
        to: `${BASE_COMMUNITY_URL}/jobs`,
        // leftIcon: "💼",
        leftIcon: <IoBriefcaseOutline />,
      });
    }
    if (knowledge) {
      newSidebarItems.push({
        name: "Knowledge Base",
        to: `${BASE_COMMUNITY_URL}/knowledge`,
        // leftIcon: "📝",
        leftIcon: <IoReaderOutline />,
      });
    }

    // check for slack/discord
    if (messagingAppSettings) {
      const slackUrl = messagingAppSettings.slackUrl;
      const discordUrl = messagingAppSettings.discordUrl;

      if (slackUrl) {
        newSidebarItems.push({
          name: "Join us on Slack",
          href: slackUrl,
          leftIcon: <IoLogoSlack />,
        });
      }
      if (discordUrl) {
        newSidebarItems.push({
          name: "Join us on Discord",
          href: discordUrl,
          leftIcon: <IoLogoDiscord />,
        });
      }
    }

    // add the rooms TODO: Putting this off for now
    // if (myCommunityRooms && myCommunityRooms.length > 0) {
    //   newSidebarItems.push(null);
    //   newSidebarItems.push({
    //     name: "View All Rooms",
    //     to: `${BASE_COMMUNITY_URL}/rooms`,
    //     leftIcon: "💬",
    //   });

    //   (myCommunityRooms || []).map((room) => {
    //     if (!room.isActive) {
    //       return null;
    //     }
    //     if (user && (!room.users || room.users.length <= 0)) {
    //       return null;
    //     }
    //     if (!user && (!room.isPublic || !room.isDefault)) {
    //       return null;
    //     }

    //     const leftIcon =
    //       room.type === ROOM_TYPE.CHAT ? (
    //         <HiOutlineChat />
    //       ) : room.type === ROOM_TYPE.FORUM ? (
    //         <HiOutlinePencilAlt />
    //       ) : room.type == ROOM_TYPE.BOARD ? (
    //         <HiOutlineViewGrid />
    //       ) : (
    //         <HiHashtag />
    //       );
    //     const roomEmoji = room.emoji || "";
    //     const roomName = room.name;

    //     newSidebarItems.push({
    //       name: roomName,
    //       to: `${BASE_COMMUNITY_URL}/rooms/${roomName}`,
    //       leftIcon: leftIcon,
    //       roomEmoji: roomEmoji,
    //     });
    //   });
    // }

    setSidebarMenuItems(newSidebarItems);
  }, [messagingAppSettings]); // TODO: Need to add myCommunityRooms later

  return (
    <Box className={classes.SidebarBox}>
      {isLoading ? (
        <Spinner />
      ) : isMessagingAppError ? (
        <Alert severity="error">
          {messagingAppSettings &&
            messagingAppSettings.data &&
            messagingAppSettings.data.msg}
        </Alert>
      ) : (
        companyDetails && (
          <>
            <List className={classes.List}>
              <ListItem className={classes.ListItem} button>
                <ListItemText
                  primary={
                    <CommunityMenu
                      companyDetails={companyDetails}
                      sidebarMenuItems={sidebarMenuItems}
                    />
                  }
                />
              </ListItem>

              {!mobileScreen &&
                (sidebarMenuItems || []).map((item, index) => {
                  if (!item) {
                    return <Box key={index} py={1} />;
                  }
                  const linkProps = item.href
                    ? { component: MuiLink, href: item.href }
                    : { component: Link, to: item.to };
                  return (
                    <ListItem
                      className={classes.ListItem}
                      button
                      key={index}
                      {...linkProps}
                    >
                      <ListItemIcon className={classes.ListItemIcon}>
                        {item.leftIcon}
                      </ListItemIcon>

                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.ListItemText,
                        }}
                        primary={
                          <Box display="flex" alignItems="center">
                            <Typography variant="body1" component="div">
                              <Box fontWeight={400}>{item.name}</Box>
                            </Typography>
                            {item.roomEmoji && (
                              <Typography variant="body2" component="div">
                                <Box lineHeight={1} ml={1}>
                                  <Emoji emoji={item.roomEmoji} size={16} />
                                </Box>
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
          </>
        )
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CommunitySidebar)
);
