import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// icons
import YouTubeIcon from "@material-ui/icons/YouTube";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CodeIcon from "@material-ui/icons/Code";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// components
import PlaceholderProjectImage from "../../../shared/PlaceholderProjectImage";
// Utils
import ReactImageFallback from "react-image-fallback";

const useStyles = makeStyles((theme) => ({
  media: {
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
    width: 200,
    height: 112.5,
    minHeight: 90,
    minWidth: 160,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  button: {
    fontWeight: "600",
  },
  chipPaper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  header: {
    paddingBottom: 0,
    "& > div": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  content: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
  icon: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(1),
  },
  editButton: {
    color: theme.palette.warning.main,
    fontWeight: "600",
    fontSize: 12,
  },
  likeButton: {
    fontWeight: "600",
    "&:focus": {
      outline: "none",
    },
  },
  commentButton: {
    fontWeight: "600",
    "&:focus": {
      outline: "none",
    },
  },
  actionArea: {
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
      transition: "0.2s ease-in-out",
    },
    transition: "0.2s ease-in-out",
  },
  focusHighlight: {},
}));

const ProfileProjectView = ({
  project,
  projectCategories,
  extLinks,
  projectStatus,
}) => {
  const classes = useStyles();
  const [categoryNames, setCategoryNames] = useState([]);
  const [linkIcons] = useState({
    1: <CodeIcon />,
    2: <PermMediaIcon />,
    3: <DescriptionIcon />,
    4: <YouTubeIcon />,
    5: <AccountBalanceIcon />,
    6: <LanguageIcon />,
  });
  const [youtubeVideoId, setYouTubeVideoId] = useState("");

  const [status, setProjectStatus] = useState("");
  useEffect(() => {
    const found =
      projectStatus &&
      projectStatus.length > 0 &&
      projectStatus.find((elem) => elem.id === project.projectStatusId);
    setProjectStatus(found ? found.name : "");
  }, [project]);

  useEffect(() => {
    const categories = project.categories;
    let newCategoryNames = [];
    for (let i = 0; i < categories.length; i++) {
      const found = projectCategories.findIndex(
        (elem) => elem.id === categories[i]
      );
      if (found !== -1) {
        newCategoryNames.push(projectCategories[found].name);
      }
    }
    setCategoryNames(newCategoryNames);
  }, []);

  useEffect(() => {
    for (let i = 0; i < project.extLinks.length; i++) {
      const currentExtLink = project.extLinks[i];
      const extLinkTypeId = currentExtLink.extLinkTypeId;
      const externalUrl = currentExtLink.externalUrl;
      if (extLinkTypeId === 4) {
        const split = externalUrl.split("v=")[1];
        let string;
        if (split) {
          const secondIndex = split.indexOf("&");
          string = split.substring(
            0,
            secondIndex !== -1 ? secondIndex : split.length
          );
        }
        setYouTubeVideoId(string || "");
      }
    }
  }, [project]);

  return (
    <Fragment>
      <Box
        component={RouterLink}
        to={`/projects/${project.id}`}
        style={{ height: "100%" }}
        classes={{
          root: classes.actionArea,
          focusHighlight: classes.focusHighlight,
        }}
        display="flex"
        alignItems="center"
        p={2}
        borderRadius="borderRadius"
      >
        {project.projectImages && project.projectImages.length > 0 ? (
          <Box>
            <ReactImageFallback
              fallbackImage={
                "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
              }
              alt={`background-image`}
              className={classes.media}
              src={
                project.projectImages[0].filePath ||
                "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
              }
              title={project.projectImages[0].filePath}
            />
          </Box>
        ) : youtubeVideoId && youtubeVideoId !== "" ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
              height: "0",
            }}
          >
            <iframe
              title={youtubeVideoId}
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              frameBorder="0"
              top="0"
              left="0"
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
              }}
            />
          </div>
        ) : (
          <PlaceholderProjectImage categories={project && project.categories} />
        )}

        <Box ml={{ xs: 2, md: 4 }}>
          <Typography variant="h6" component="h3" gutterBottom>
            <Box fontWeight={500} lineHeight={1.2}>
              {project.title}
            </Box>
          </Typography>

          {categoryNames && categoryNames.length > 0 && (
            <Typography variant="caption" color="textSecondary" component="div">
              <Box lineHeight={1.2}>{categoryNames.join(", ")}</Box>
            </Typography>
          )}
          {project.skills && project.skills.length > 0 && (
            <Typography variant="caption" color="textSecondary" component="div">
              <Box lineHeight={1.2}>
                {project.skills.map((skill) => skill.name).join(", ")}
              </Box>
            </Typography>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

ProfileProjectView.propTypes = {
  project: PropTypes.object.isRequired,
  projectCategories: PropTypes.array.isRequired,
  extLinks: PropTypes.array.isRequired,
  projectStatus: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  projectCategories: state.typedef.projectCategories,
  extLinks: state.typedef.extLinks,
  projectStatus: state.typedef.projectStatus,
});

export default connect(mapStateToProps)(ProfileProjectView);
