import {
  LOADING_SUBMISSION_SCORES,
  GET_SUBMISSION_SCORES,
  LOADING_CHALLENGE,
  SET_CURRENT_ROUND_INDEX,
  GET_ALL_CHALLENGES,
  GET_CHALLENGE,
  REGISTER_USER_CHALLENGE,
  LEAVE_CHALLENGE,
  IS_USER_REGISTERED,
  CLEAR_SUBMISSIONS,
  CLEAR_REGISTRATIONS,
  CLEAR_ALL_CHALLENGES,
  CLEAR_CHALLENGE,
  LOADING_CHALLENGES,
  HAS_USER_SUBMITTED,
  ELIGIBLE_TO_SUBMIT_TO_ROUND,
  USER_SUBMIT_ELIGIBLE_STATUS,
  SHOW_SUBMISSIONS,
  SHOW_REGISTRATIONS,
  GET_MY_CHALLENGES,
  CLEAR_MY_CHALLENGES,
  ADD_UPDATE_CHALLENGE,
  ADD_UPDATE_CHALLENGE_ROUND,
  IS_PUBLISHED_CHALLENGE,
  IS_JUDGES_PUBLIC,
  IS_CRITERIA_PUBLIC,
  IS_PUBLIC_CHALLENGE,
  IS_ACTIVE_CHALLENGE,
  ADD_CHALLENGE_PROPERTIES,
  CHALLENGE_HEADER_IMAGE,
  GET_MY_SUBMISSION,
  GET_SUBMISSION_AND_PROJECT,
  GET_SUBMISSION_FORM_DATA,
  CLEAR_MY_SUBMISSION,
  CLEAR_MY_TEAM,
  CLEAR_SELECTED_SUBMISSION,
  CHALLENGE_ERROR,
  GET_ALL_PRIZES,
  ADD_EDIT_PRIZE,
  ADD_PRIZE,
  EDIT_PRIZE,
  DELETE_PRIZE,
  GET_SUBMISSION,
  SET_ROUND_AS_CURRENT,
  SET_PRIMARY_COLOR,
  ASSIGN_JUDGES_TO_SUBMISSION,
  GET_ASSIGN_JUDGE_SETTINGS,
  SORT_SUBMISSION_SCORES,
  GET_TEAM_INFORMATION,
  MODIFY_TEAM_NAME,
  ADD_TEAM_MEMBER,
  REMOVING_SELF_TEAM,
  IS_CHALLENGE_REGISTRATION_PUBLIC,
  IS_CHALLENGE_PRIZE_PUBLIC,
  IS_CHALLENGE_SUBMISSION_PUBLIC,
  RELEASE_JUDGING,
  DISQUALIFY_REGISTERED_TEAM,
  GET_CHALLENGE_SUBMISSION_CUSTOM_FORM,
  SAVE_SUBMISSION_CUSTOM_FORM,
  SUBMIT_CUSTOM_FORM,
  UPDATE_SUBMISSION_STATUS_TYPE,
  SET_CHALLENGE_STATUS_FOR_USER,
  ENABLE_JUDGING_PASSWORD,
  UPDATE_GROUPS_SUBMISSION,
} from "../actions/actionTypes";

import { DEFAULT_PAGE_SIZE } from "../../utils/globalValues";

const initialState = {
  loading: false,
  challenges: [],
  registrations: [],
  submissions: [],
  scores: [],
  currentRoundIndex: 0,
  challenge: null,
  currentUserStatus: {
    isRegistered: false,
    submittedStatusTypeId: null,
  },
  myTeam: null,
  myChallenges: [],
  mySubmission: null,
  submissionScores: {
    loading: false,
    data: null,
    error: null,
  },
  selectedSubmission: null,
  countCriteria: null,
  prizes: [],
  myCustomFormSubmission: {
    jsonSchema: {},
    uiSchema: {},
    formData: {},
  },
  assignJudgeSettings: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CHALLENGE_STATUS_FOR_USER: {
      return {
        ...state,
        currentUserStatus: {
          isRegistered: payload.isRegistered,
          submittedStatusTypeId: payload.submittedStatusTypeId,
        },
      };
    }
    case LOADING_SUBMISSION_SCORES: {
      return {
        ...state,
        submissionScores: {
          ...state.submissionScores,
          loading: true,
          error: null,
          data: null,
        },
      };
    }
    case UPDATE_GROUPS_SUBMISSION: {
      const newSubmissions = state.submissions.map((submission) => {
        if (submission.id === payload.id) {
          return {
            ...submission,
            groups: payload.groups,
          };
        }
        return submission;
      });
      return {
        ...state,
        loading: false,
        submissions: newSubmissions,
      };
    }
    case GET_SUBMISSION_SCORES: {
      return {
        ...state,
        submissionScores: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case SET_CURRENT_ROUND_INDEX:
      //console.log(`action, CurrentRoundIndex: ${payload}`);
      return {
        ...state,
        currentRoundIndex: payload,
      };

    case LOADING_CHALLENGE: {
      return {
        ...state,
        loading: true,
        currentRoundIndex: 0,
        challenge: null,
        currentUserStatus: {
          isRegistered: false,
          submittedStatusTypeId: null,
        },
        myTeam: null,
        mySubmission: null,
      };
    }

    case LOADING_CHALLENGES:
      return {
        ...state,
        loading: true,
        // challenges: [],
        // challenge: null,
        // myChallenges: [],
      };

    case ENABLE_JUDGING_PASSWORD: {
      let curChallenge = { ...state.challenge };
      if (curChallenge.round && curChallenge.round.length > 0) {
        let foundRoundIdx = curChallenge.round.findIndex(
          (elem) => elem.id === payload.challengeRoundId
        );

        if (foundRoundIdx >= 0) {
          curChallenge.round[foundRoundIdx] = {
            ...curChallenge.round[foundRoundIdx],
            judgingPasswordEnabled: payload.isEnabled,
          };
        }
        return {
          ...state,
          loading: false,
          challenge: curChallenge,
        };
      }
    }

    case UPDATE_SUBMISSION_STATUS_TYPE: {
      let newSubmissions = [...state.submissions];
      let foundSubmissionIndex = newSubmissions.findIndex(
        (elem) => elem.id === payload.submissionId
      );
      if (foundSubmissionIndex === -1) return state;
      newSubmissions[foundSubmissionIndex] = {
        ...newSubmissions[foundSubmissionIndex],
        submittedStatusTypeId: payload.submittedStatusTypeId,
      };
      return {
        ...state,
        submissions: newSubmissions,
      };
    }

    case GET_SUBMISSION: {
      return {
        ...state,
        loading: false,
        selectedSubmission: payload,
      };
    }

    case GET_SUBMISSION_FORM_DATA: {
      return {
        ...state,
        loading: false,
        selectedSubmission: payload.submission,
        myCustomFormSubmission: {
          ...state.myCustomFormSubmission,
          jsonSchema: payload.jsonSchema,
          uiSchema: payload.uiSchema,
          formData: payload.formData || "",
        },
      };
    }

    case CHALLENGE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case CLEAR_SUBMISSIONS: {
      return {
        ...state,
        submissions: [],
      };
    }
    case CLEAR_REGISTRATIONS: {
      return {
        ...state,
        registrations: [],
      };
    }

    case CHALLENGE_HEADER_IMAGE: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          headerImagePath: payload.headerImagePath,
        },
      };
    }

    case ADD_CHALLENGE_PROPERTIES: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          properties: payload,
        },
      };
    }

    case ADD_UPDATE_CHALLENGE: {
      let myChallenges = [...state.myChallenges];
      let foundChallengeIdx = myChallenges.findIndex(
        (elem) => elem.id === payload.id
      );
      if (foundChallengeIdx >= 0) {
        myChallenges[foundChallengeIdx] = payload;
      } else {
        myChallenges = [...state.myChallenges, payload];
      }
      return {
        ...state,
        loading: false,
        myChallenges: myChallenges,
        challenge: payload,
      };
    }

    case ADD_UPDATE_CHALLENGE_ROUND: {
      let curChallenge = { ...state.challenge };
      if (curChallenge.round && curChallenge.round.length > 0) {
        let foundRoundIdx = curChallenge.round.findIndex(
          (elem) => elem.id === payload.id
        );
        if (foundRoundIdx >= 0) {
          curChallenge.round[foundRoundIdx] = { ...payload };
        } else {
          curChallenge.round = [...curChallenge.round, payload];
        }
        return {
          ...state,
          loading: false,
          challenge: curChallenge,
        };
      }
    }
    case SET_ROUND_AS_CURRENT: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          currentRoundId: payload,
        },
      };
    }
    case SET_PRIMARY_COLOR: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          primaryColor: payload,
        },
      };
    }
    case IS_ACTIVE_CHALLENGE: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          isActive: payload,
        },
      };
    }
    case IS_CHALLENGE_REGISTRATION_PUBLIC: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          isRegistrationPublic: payload,
        },
      };
    }
    case IS_CHALLENGE_PRIZE_PUBLIC: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          isPrizePublic: payload,
        },
      };
    }

    case IS_CHALLENGE_SUBMISSION_PUBLIC: {
      let curChallenge = { ...state.challenge };
      if (curChallenge.round && curChallenge.round.length > 0) {
        let foundRoundIdx = curChallenge.round.findIndex(
          (elem) => elem.id === payload.challengeRoundId
        );

        if (foundRoundIdx >= 0) {
          curChallenge.round[foundRoundIdx] = {
            ...curChallenge.round[foundRoundIdx],
            isSubmissionPublic: payload.isPublic,
          };
        }
        return {
          ...state,
          loading: false,
          challenge: curChallenge,
        };
      }
    }

    case IS_JUDGES_PUBLIC: {
      let curChallenge = { ...state.challenge };
      if (curChallenge.round && curChallenge.round.length > 0) {
        let foundRoundIdx = curChallenge.round.findIndex(
          (elem) => elem.id === payload.challengeRoundId
        );

        if (foundRoundIdx >= 0) {
          curChallenge.round[foundRoundIdx] = {
            ...curChallenge.round[foundRoundIdx],
            isJudgesPublic: payload.isPublic,
          };
        }
        //console.log(payload.isPublic);
        return {
          ...state,
          loading: false,
          challenge: curChallenge,
        };
      }
    }

    case IS_CRITERIA_PUBLIC: {
      let curChallenge = { ...state.challenge };
      if (curChallenge.round && curChallenge.round.length > 0) {
        let foundRoundIdx = curChallenge.round.findIndex(
          (elem) => elem.id === payload.challengeRoundId
        );

        if (foundRoundIdx >= 0) {
          curChallenge.round[foundRoundIdx] = {
            ...curChallenge.round[foundRoundIdx],
            isCriteriaPublic: payload.isPublic,
          };
        }
        return {
          ...state,
          loading: false,
          challenge: curChallenge,
        };
      }
    }

    case RELEASE_JUDGING: {
      let curChallenge = { ...state.challenge };
      if (curChallenge.round && curChallenge.round.length > 0) {
        let foundRoundIdx = curChallenge.round.findIndex(
          (elem) => elem.id === payload.challengeRoundId
        );

        if (foundRoundIdx >= 0) {
          curChallenge.round[foundRoundIdx] = {
            ...curChallenge.round[foundRoundIdx],
            releaseJudging: payload.releaseJudging,
            releaseScores: payload.releaseScores,
            releaseFeedback: payload.releaseFeedback,
          };
        }
        return {
          ...state,
          loading: false,
          challenge: curChallenge,
        };
      }
    }
    case IS_PUBLIC_CHALLENGE: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
          isPublic: payload,
        },
      };
    }

    case GET_MY_SUBMISSION: {
      //console.log("GET_MY_SUBMISSION:", payload);
      return {
        ...state,
        loading: false,
        mySubmission: {
          submission: payload.submission,
          judgingCriteria: payload.judgingCriteria,
        },
      };
    }

    case GET_MY_CHALLENGES: {
      return {
        ...state,
        loading: false,
        myChallenges: payload,
        registrations: [],
        submissions: [],
        scores: [],
        challenge: null,
      };
    }
    case CLEAR_ALL_CHALLENGES:
      return {
        ...state,
        loading: false,
        challenges: [],
      };
    case CLEAR_MY_SUBMISSION:
      return {
        ...state,
        loading: false,
        mySubmission: null,
      };
    case CLEAR_MY_TEAM:
      return {
        ...state,
        loading: false,
        myTeam: null,
      };
    case CLEAR_SELECTED_SUBMISSION:
      return {
        ...state,
        loading: false,
        selectedSubmission: null,
        myCustomFormSubmission: {
          jsonSchema: {},
          uiSchema: {},
          formData: {},
        },
      };
    case CLEAR_MY_CHALLENGES:
      return {
        ...state,
        loading: false,
        myChallenges: [],
      };
    case CLEAR_CHALLENGE:
      return {
        ...state,
        loading: false,
        challenge: null,
        registrations: [],
        submissions: [],
        myTeam: null,
        currentUserStatus: {
          isRegistered: false,
          submittedStatusTypeId: null,
        },
      };
    case GET_ALL_CHALLENGES:
      return {
        ...state,
        loading: false,
        challenges: payload,
      };

    case GET_CHALLENGE:
      //console.log(payload);
      return {
        ...state,
        loading: false,
        challenge: payload,
      };

    case GET_CHALLENGE_SUBMISSION_CUSTOM_FORM: {
      //console.log("GET_CHALLENGE_SUBMISSION_CUSTOM_FORM:", payload);
      return {
        ...state,
        loading: false,
        mySubmission: {
          submission: payload.submission,
          judgingCriteria: payload.judgingCriteria,
        },
        myCustomFormSubmission: {
          ...state.myCustomFormSubmission,
          jsonSchema: payload.jsonSchema,
          uiSchema: payload.uiSchema,
          formData: payload.formData || {},
        },
      };
    }

    case SAVE_SUBMISSION_CUSTOM_FORM: {
      //console.log("SAVE_SUBMISSION_CUSTOM_FORM - payload:", payload);
      return {
        ...state,
        loading: false,
        challenge: {
          ...state.challenge,
          hasUserSubmitted: false,
        },
        myCustomFormSubmission: {
          ...state.myCustomFormSubmission,
          jsonSchema: payload.customFormSchema.jsonSchema,
          uiSchema: payload.customFormSchema.uiSchema,
          formData: payload.submissionDetails.customSubmissionDetails,
        },
      };
    }

    case SUBMIT_CUSTOM_FORM: {
      //console.log("SUBMIT_CUSTOM_FORM - payload:", payload);
      return {
        ...state,
        loading: false,
        challenge: {
          ...state.challenge,
          hasUserSubmitted: true,
        },
        myCustomFormSubmission: {
          ...state.myCustomFormSubmission,
          jsonSchema: payload.customFormSchema.jsonSchema,
          uiSchema: payload.customFormSchema.uiSchema,
          formData: payload.submissionDetails.customSubmissionDetails,
        },
      };
    }

    case GET_ASSIGN_JUDGE_SETTINGS: {
      return {
        ...state,
        assignJudgeSettings: payload,
      };
    }

    case ASSIGN_JUDGES_TO_SUBMISSION: {
      const newSubmissions = [...state.submissions];
      let foundSubmissionIndex = newSubmissions.findIndex(
        (elem) => elem.id === payload.submissionId
      );
      if (foundSubmissionIndex !== -1) {
        newSubmissions[foundSubmissionIndex] = {
          ...newSubmissions[foundSubmissionIndex],
          judges: payload.judges,
        };
      }
      return {
        ...state,
        loading: false,
        submissions: newSubmissions,
      };
    }
    case HAS_USER_SUBMITTED: {
      let newChallenge = {
        ...state.challenge,
        hasUserSubmitted: payload.isSubmitted,
      };
      return {
        ...state,
        loading: false,
        challenge: newChallenge,
      };
    }

    case ELIGIBLE_TO_SUBMIT_TO_ROUND: {
      let newChallenge = {
        ...state.challenge,
        isEligibleToSubmit: payload,
      };
      return {
        ...state,
        loading: false,
        challenge: newChallenge,
      };
    }

    case USER_SUBMIT_ELIGIBLE_STATUS: {
      let myChallenges = [...state.myChallenges];
      let foundChallengeIdx = myChallenges.findIndex(
        (elem) => elem.id === payload.id
      );
      if (foundChallengeIdx >= 0) {
        myChallenges[foundChallengeIdx] = {
          ...myChallenges[foundChallengeIdx],
          hasUserSubmitted: payload.hasUserSubmitted,
          isEligibleToSubmit: payload.isEligibleToSubmit,
          isCurrentRound: payload.isCurrentRound,
          isRegistered: payload.isRegistered,
        };
      }

      return {
        ...state,
        loading: false,
        myChallenges: myChallenges,
      };
    }
    case SHOW_SUBMISSIONS: {
      return {
        ...state,
        loading: false,
        submissions: payload || [],
      };
    }
    case SORT_SUBMISSION_SCORES: {
      return {
        ...state,
        loading: false,
        submissions: payload || [],
      };
    }
    case SHOW_REGISTRATIONS: {
      return {
        ...state,
        loading: false,
        registrations: payload || [],
      };
    }

    case IS_USER_REGISTERED:
    case LEAVE_CHALLENGE:
    case REGISTER_USER_CHALLENGE: {
      let newChallenge = {
        ...state.challenge,
        isRegistered: payload,
      };
      return {
        ...state,
        loading: false,
        challenge: newChallenge,
      };
    }

    case ADD_PRIZE:
      return {
        ...state,
        prizes: [payload, ...state.prizes],
        loading: false,
      };
    case EDIT_PRIZE:
      if (state.prizes && state.prizes.length > 0) {
        let prePrizes = [...state.prizes];
        const indx = state.prizes.findIndex((elem) => elem.id === payload.id);
        prePrizes[indx] = {
          ...prePrizes[indx],
          ...payload,
        };
        return {
          ...state,
          loading: false,
          prizes: prePrizes,
        };
      } else {
        return {
          ...state,
          loading: false,
          prizes: [payload],
        };
      }
    case DELETE_PRIZE:
      return {
        ...state,
        prizes: payload,
        loading: false,
      };
    case GET_ALL_PRIZES:
      return {
        ...state,
        loading: false,
        prizes: payload,
      };
    case GET_TEAM_INFORMATION:
      return {
        ...state,
        myTeam: payload || null,
      };

    case ADD_TEAM_MEMBER: {
      return {
        ...state,
        myTeam: {
          ...state.myTeam,
          members: [...state.myTeam.members, payload],
        },
      };
    }
    case REMOVING_SELF_TEAM: {
      return {
        ...state,
        challenge: {
          ...state.challenge,
        },
        currentUserStatus: {
          ...state.currentUserStatus,
          isRegistered: false,
          submittedStatusTypeId: null,
        },
        myTeam: null,
      };
    }
    case MODIFY_TEAM_NAME:
      return {
        ...state,
        myTeam: {
          ...state.myTeam,
          name: payload,
        },
      };

    case DISQUALIFY_REGISTERED_TEAM: {
      return {
        ...state,
        registrations: payload,
      };
    }
    default:
      return state;
  }
};
