import React from "react";
import { Link, useLocation } from "react-router-dom";
// react query
// Material UI
import { makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
// Icons
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";
import EventIcon from "@material-ui/icons/Event";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
// Utils
import ReactImageFallback from "react-image-fallback";
import { DEFAULT_TIME_ZONE, EVENT_ONLINE } from "../../../utils/globalValues";
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";

const useStyles = makeStyles((theme) => ({
  headerImage: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    objectFit: "cover",
  },
  eventCard: {
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    transition: "ease-in-out 0.2s",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CommunityEventCard = ({ event }) => {
  const classes = useStyles();

  const location = useLocation();

  return (
    <Card
      component={Link}
      to={`${location.pathname}/${event.slug}`}
      key={event.id}
      className={classes.eventCard}
    >
      <CardContent>
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4} xl={3}>
            <Box
              style={{
                position: "relative",
                paddingBottom: "56.2%",
              }}
            >
              <ReactImageFallback
                src={event.headerImagePath}
                fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                alt={`${event.title}-header-image`}
                className={classes.headerImage}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
              <Box>
                <Typography variant="h4" component="h3">
                  <Box fontWeight={600} lineHeight={1.2} mb={0.5}>
                    {event.title}{" "}
                  </Box>
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  color="textSecondary"
                >
                  <Box fontWeight={600} display="flex" alignItems="center">
                    <Box mr={1}>
                      <EventIcon />
                    </Box>{" "}
                    {event.startDateTime &&
                      convertUTCTimeToZonedTime(
                        event.startDateTime,
                        DEFAULT_TIME_ZONE
                      )}{" "}
                    to{" "}
                    {event.endDateTime &&
                      convertUTCTimeToZonedTime(
                        event.endDateTime,
                        DEFAULT_TIME_ZONE
                      )}
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Chip
                  clickable
                  icon={
                    event.online === EVENT_ONLINE.INPERSON ? (
                      <PeopleOutlineOutlinedIcon />
                    ) : (
                      <VideocamOutlinedIcon />
                    )
                  }
                  label={
                    event.online === EVENT_ONLINE.ONLINE
                      ? "Online"
                      : event.online === EVENT_ONLINE.HYBRID
                      ? "Hybrid"
                      : "In Person"
                  }
                  color="default"
                />
                {event.location && (
                  <Chip
                    clickable
                    icon={<LocationOnOutlinedIcon />}
                    label={event.location}
                    color="default"
                  />
                )}
                {event.requireRegistration && (
                  <>
                    <Chip
                      clickable
                      icon={<LockOpenOutlinedIcon />}
                      label="Registration Required"
                      color="default"
                    />
                    {event.registrationDeadlineDatetime && (
                      <Chip
                        clickable
                        icon={<EventIcon />}
                        label={`Register by ${convertUTCTimeToZonedTime(
                          event.registrationDeadlineDatetime,
                          DEFAULT_TIME_ZONE
                        )}`}
                        color="default"
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CommunityEventCard;
