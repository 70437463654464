import React, { useState } from "react";
import PropTypes from "prop-types";
// React Router
import { useGetAllJobsForCommunity } from "../../hooks/job";
// Material UI
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Icons
import { IoBriefcaseOutline } from "react-icons/io5";
// Components
import Spinner from "../../shared/Spinner";
import Button from "../../shared/Button";
import EmptyState from "../../shared/EmptyState";
import CommunityJobCard from "./components/CommunityJobCard";
import AddRemoveJobPostDialog from "../../shared/AddRemoveJobPostDialog";

const CommunityJobs = ({ companyDetailsQuery, communityMemberQuery }) => {
  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const {
    isLoading: isLoadingJobBoard,
    data: communityJobBoard,
    isError: isErrorJobBoard,
    error: errorJobBoard,
  } = useGetAllJobsForCommunity(companyDetails);

  const [openJobPostDialog, setJobPostDialog] = useState(false);

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container maxWidth="xl">
          <Box py={2} px={{ xs: 0, md: 2 }}>
            <Box>
              {isErrorJobBoard && (
                <Alert severity="error">
                  {errorJobBoard &&
                    errorJobBoard.data &&
                    errorJobBoard.data.msg}
                </Alert>
              )}
              {isLoadingJobBoard ? (
                <Spinner />
              ) : (
                <Box>
                  {communityJobBoard ? (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-between"
                        mb={2}
                      >
                        <Typography gutterBottom variant="h5" component="h2">
                          <Box
                            fontWeight={600}
                            display="flex"
                            alignItems="center"
                            style={{ gap: "8px" }}
                          >
                            <IoBriefcaseOutline />{" "}
                            {communityJobBoard?.title || "Job Board"}
                          </Box>
                        </Typography>
                        {communityJobBoard.allowOutsideOrganizations && (
                          <>
                            <AddRemoveJobPostDialog
                              open={openJobPostDialog}
                              setOpen={setJobPostDialog}
                              companyId={companyDetails.id}
                              jobBoard={communityJobBoard}
                            />
                            <Button
                              color="primary"
                              onClick={() => setJobPostDialog(true)}
                            >
                              Add / Remove My Jobs
                            </Button>
                          </>
                        )}
                      </Box>
                      {communityJobBoard.description && (
                        <Typography
                          variant="body1"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2} whiteSpace="pre-line">
                            {communityJobBoard.description}
                          </Box>
                        </Typography>
                      )}
                      {communityJobBoard.posts &&
                      communityJobBoard.posts.length > 0 ? (
                        <Box my={2}>
                          <Grid container spacing={2}>
                            {communityJobBoard.posts.map((job) => (
                              <Grid key={job.id} item xs={12} md={6} xl={4}>
                                <CommunityJobCard job={job} />
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      ) : (
                        <Box>
                          <EmptyState
                            title="No Jobs"
                            subtitle="There are no jobs for the community job board at this time."
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <Box>
                      <EmptyState
                        title="No Job Board"
                        subtitle="There is no job board for this community at this time."
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

CommunityJobs.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

export default CommunityJobs;
