import { handleServerError } from "./error";
import { setAlert } from "./alert";
import axios from "../../utils/axios";
import {
  LOADING_SUBMISSION_SCORES,
  GET_SUBMISSION_SCORES,
  SET_CURRENT_ROUND_INDEX,
  GET_ALL_CHALLENGES,
  GET_CHALLENGE,
  REGISTER_USER_CHALLENGE,
  LEAVE_CHALLENGE,
  IS_USER_REGISTERED,
  CLEAR_CHALLENGE,
  CLEAR_ALL_CHALLENGES,
  LOADING_CHALLENGE,
  LOADING_CHALLENGES,
  HAS_USER_SUBMITTED,
  ELIGIBLE_TO_SUBMIT_TO_ROUND,
  USER_SUBMIT_ELIGIBLE_STATUS,
  SHOW_SUBMISSIONS,
  SHOW_REGISTRATIONS,
  GET_MY_CHALLENGES,
  CLEAR_MY_CHALLENGES,
  ADD_UPDATE_CHALLENGE,
  ADD_UPDATE_CHALLENGE_ROUND,
  IS_PUBLIC_CHALLENGE,
  IS_ACTIVE_CHALLENGE,
  IS_CHALLENGE_REGISTRATION_PUBLIC,
  IS_CHALLENGE_PRIZE_PUBLIC,
  IS_CHALLENGE_SUBMISSION_PUBLIC,
  RELEASE_JUDGING,
  SET_ROUND_AS_CURRENT,
  SET_PRIMARY_COLOR,
  ADD_CHALLENGE_PROPERTIES,
  CHALLENGE_HEADER_IMAGE,
  GET_MY_SUBMISSION,
  CLEAR_MY_SUBMISSION,
  CLEAR_SELECTED_SUBMISSION,
  //CHALLENGE_ERROR,
  CLEAR_REGISTRATIONS,
  CLEAR_SUBMISSIONS,
  GET_ALL_PRIZES,
  CLEAR_MY_TEAM,
  //ADD_EDIT_PRIZE,
  //ADD_PRIZE,
  //EDIT_PRIZE,
  GET_CHALLENGE_SUBMISSION_CUSTOM_FORM,
  GET_SUBMISSION,
  GET_SUBMISSION_FORM_DATA,
  GET_SUBMISSION_AND_PROJECT,
  ASSIGN_JUDGES_TO_SUBMISSION,
  GET_ASSIGN_JUDGE_SETTINGS,
  DELETE_PRIZE,
  SORT_SUBMISSION_SCORES,
  GET_TEAM_INFORMATION,
  MODIFY_TEAM_NAME,
  ADD_TEAM_MEMBER,
  REMOVING_SELF_TEAM,
  IS_JUDGES_PUBLIC,
  IS_CRITERIA_PUBLIC,
  DISQUALIFY_REGISTERED_TEAM,
  ASSIGN_SUBMISSION_TO_JUDGES,
  SAVE_SUBMISSION_CUSTOM_FORM,
  SUBMIT_CUSTOM_FORM,
  UPDATE_SUBMISSION_STATUS_TYPE,
  SET_CHALLENGE_STATUS_FOR_USER,
  ENABLE_JUDGING_PASSWORD,
} from "./actionTypes";

import { getCurrentRound } from "../../utils/challenge";
import { getProject } from "./project";
//import analytics from "../../analytics";
// import {
//   A_REGISTER_USER_CHALLENGE,
//   A_REGISTER_ORGANIZATION_USER_CHALLENGE,
//   A_ADD_TEAM_MEMBER_CHALLENGE,
//   A_DELETE_TEAM_MEMBER_CHALLENGE,
//   A_SUBMIT_CHALLENGE,
// } from "../../analytics/eventTypes";

//const FIRST_ROUND = 1;

export const setCurrentChallengeRoundIndex =
  (currentRoundIndex) => async (dispatch) => {
    try {
      await dispatch({
        type: SET_CURRENT_ROUND_INDEX,
        payload: currentRoundIndex,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const sortSubmissionScores = (sortedArray) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_CHALLENGES,
    });
    await dispatch({
      type: SORT_SUBMISSION_SCORES,
      payload: sortedArray,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const uploadHeaderImage =
  (formData, companyId, challengeId) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const res = await axios.post(
        `/api/challenge/upload/headerImage/${companyId}/${challengeId}`,
        formData,
        config
      );

      await dispatch({
        type: CHALLENGE_HEADER_IMAGE,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const getAssignJudgeSettingsByChallengeId =
  (challengeId, challengeRoundId) => async (dispatch) => {
    try {
      const res = await axios.get(
        `/api/challenge/assignJudgeSettings/${challengeId}/${challengeRoundId}`
      );
      //console.log(res);
      await dispatch({
        type: GET_ASSIGN_JUDGE_SETTINGS,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const assignJudgesToSubmission =
  (challengeId, challengeRoundId, submissionId, judgesToAssign) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        challengeId,
        challengeRoundId,
        submissionId,
        judgesToAssign,
      };
      const res = await axios.post(
        "/api/challenge/assignJudgesToSubmission",
        body,
        config
      );
      await dispatch({
        type: ASSIGN_JUDGES_TO_SUBMISSION,
        payload: {
          submissionId: submissionId,
          judges: res.data,
        },
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const clearMySubmission = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_MY_SUBMISSION,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearMyTeam = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_MY_TEAM,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearSelectedSubmission = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_SELECTED_SUBMISSION,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearSubmissions = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_SUBMISSIONS,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const clearRegistrations = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_REGISTRATIONS,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const setRoundAsCurrent = (round) => async (dispatch) => {
  try {
    if (round) {
      await axios.post(
        `/api/challenge/${round.challengeId}/round/current/${round.id}`
      );
      await dispatch({
        type: SET_ROUND_AS_CURRENT,
        payload: round.id,
      });

      await dispatch(
        setAlert(
          `Successfully changed the active round to ${round.name}`,
          "success"
        )
      );
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const setPrimaryColorForChallenge =
  (challengeId, primaryColor) => async (dispatch) => {
    try {
      if (primaryColor) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = {
          challengeId,
          primaryColor,
        };
        const res = await axios.post(
          `/api/challenge/setprimarycolor`,
          body,
          config
        );
        await dispatch({
          type: SET_PRIMARY_COLOR,
          payload: primaryColor,
        });

        //await dispatch(setAlert("Successfully updated primary color", "success"));
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const setRegistrationPrivacySetting =
  (isPublic, challengeId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to make the registrations ${
            isPublic ? "public" : "private"
          }?`
        )
      ) {
        const body = {
          isPublic,
          challengeId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(
          "/api/challenge/registration/privacy",
          body,
          config
        );
        await dispatch({
          type: IS_CHALLENGE_REGISTRATION_PUBLIC,
          payload: isPublic,
        });
      }
    } catch (error) {}
  };

export const setPrizeSetting = (isPublic, challengeId) => async (dispatch) => {
  try {
    if (
      window.confirm(
        `Are you sure you want to make the awards ${
          isPublic ? "public" : "private"
        }?`
      )
    ) {
      const body = {
        isPublic,
        challengeId,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post("/api/challenge/prize/privacy", body, config);
      await dispatch({
        type: IS_CHALLENGE_PRIZE_PUBLIC,
        payload: isPublic,
      });
    }
  } catch (error) {}
};

export const setSubmissionPrivacySetting =
  (isPublic, challengeId, challengeRoundId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to make the submissions ${
            isPublic ? "public" : "private"
          }?`
        )
      ) {
        const body = {
          isPublic,
          challengeId,
          challengeRoundId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/challenge/submission/privacy", body, config);
        await dispatch({
          type: IS_CHALLENGE_SUBMISSION_PUBLIC,
          payload: body,
        });
      }
    } catch (error) {}
  };

export const setJudgePrivacySetting =
  (isPublic, challengeId, challengeRoundId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to make the reviewers ${
            isPublic ? "public" : "private"
          }?`
        )
      ) {
        const body = {
          isPublic,
          challengeId,
          challengeRoundId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/challenge/judge/privacy", body, config);
        await dispatch({
          type: IS_JUDGES_PUBLIC,
          payload: body,
        });
        await dispatch(
          setAlert("Successfully updated reviewers privacy settings.")
        );
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const setCriteriaPrivacySetting =
  (isPublic, challengeId, challengeRoundId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to make the criteria ${
            isPublic ? "public" : "private"
          }?`
        )
      ) {
        const body = {
          isPublic,
          challengeId,
          challengeRoundId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/challenge/criteria/privacy", body, config);
        await dispatch({
          type: IS_CRITERIA_PUBLIC,
          payload: body,
        });
        await dispatch(
          setAlert("Successfully updated criterias privacy settings.")
        );
      }
    } catch (error) {}
  };

export const setReleaseJudgingScoresSetting =
  (releaseScoresSettings, challengeId, challengeRoundId) =>
  async (dispatch) => {
    try {
      const body = {
        releaseJudging: releaseScoresSettings.releaseJudging,
        releaseScores: releaseScoresSettings.releaseScores,
        releaseFeedback: releaseScoresSettings.releaseFeedback,
        challengeId: challengeId,
        challengeRoundId: challengeRoundId,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post("/api/challenge/scores/release", body, config);
      await dispatch({
        type: RELEASE_JUDGING,
        payload: body,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const setPublicChallenge =
  (isPublic, challengeId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to make this program ${
            isPublic ? "public" : "private"
          }?`
        )
      ) {
        const body = {
          isPublic,
          challengeId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/challenge/setPublicStatus", body, config);
        await dispatch({
          type: IS_PUBLIC_CHALLENGE,
          payload: isPublic,
        });
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const getSubmissionWithProjectById =
  (submissionId) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/challenge/submission/${submissionId}`);

      if (
        res &&
        res.data &&
        res.data.submission &&
        res.data.submission.projectDetailId
      ) {
        await dispatch(getProject(res.data.submission.projectDetailId));
      }
      await dispatch({
        type: GET_SUBMISSION,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const fetchSubmissionAndFormForChallengeRound =
  (challengeId, challengeRoundId, formTypeId) => async (dispatch) => {
    try {
      // Fetch the form json/uischema from the formbuilder service
      const resForm = await axios.get(
        `/api/formbuilder/${challengeRoundId}/${formTypeId}`
      );
      // Fetch the submission data from the challenge/submission service
      const resSub = await axios.get(
        `/api/challenge/all/mySubmissions/${challengeId}/${challengeRoundId}`
      );

      let customFormData = {
        jsonSchema: resForm.data ? resForm.data.jsonSchema : {},
        uiSchema: resForm.data ? resForm.data.uiSchema : {},
        submission: resSub.data.submission,
        judgingCriteria: resSub.data.judgingCriteria,
        formData: resSub.data
          ? resSub.data.submission
            ? resSub.data.submission.customSubmissionDetails
            : {}
          : {},
      };
      await dispatch({
        type: GET_CHALLENGE_SUBMISSION_CUSTOM_FORM,
        payload: customFormData,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const getSubmissionScores = (submissionId) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_SUBMISSION_SCORES,
    });
    const res = await axios.get(
      `/api/challenge/getSubmissionScores/${submissionId}`
    );
    await dispatch({
      type: GET_SUBMISSION_SCORES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getCustomSubmissionFormById =
  (challengeId, formTypeId, submissionId) => async (dispatch) => {
    try {
      // Fetch the submision details
      const resSub = await axios.get(
        `/api/challenge/submission/${submissionId}`
      );

      if (resSub && resSub.data && resSub.data.submission) {
        const challengeRoundId = resSub.data.submission.challengeRoundId;

        // Fetch the form json/uischema from the formbuilder service
        const res = await axios.get(
          `/api/formbuilder/${challengeRoundId}/${formTypeId}`
        );

        let customFormData = {
          jsonSchema: res.data ? res.data.jsonSchema : {},
          uiSchema: res.data ? res.data.uiSchema : {},
          submission: resSub.data.submission,
          judgingCriteria: resSub.data.judgingCriteria,
          formData: resSub.data
            ? resSub.data.submission
              ? resSub.data.submission.customSubmissionDetails
              : {}
            : {},
        };
        await dispatch({
          type: GET_CHALLENGE_SUBMISSION_CUSTOM_FORM,
          payload: customFormData,
        });
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const saveCustomFormToChallengeRound =
  (customFormData, challengeId, challengeRoundId) => async (dispatch) => {
    try {
      const body = {
        customFormData,
        challengeId,
        challengeRoundId,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`/api/challenge/save/custom`, body, config);

      await dispatch({
        type: SAVE_SUBMISSION_CUSTOM_FORM,
        payload: res.data,
      });

      await dispatch(
        setAlert(
          "Successfully saved your submission for this program! You can come back to this page to make changes and submit",
          "success"
        )
      );
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const submitCustomFormToChallengeRound =
  (customFormData, challengeId, challengeRoundId) => async (dispatch) => {
    try {
      const body = {
        customFormData,
        challengeId,
        challengeRoundId,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `/api/challenge/submit/custom`,
        body,
        config
      );

      await dispatch({
        type: SUBMIT_CUSTOM_FORM,
        payload: res.data,
      });

      await dispatch(
        setAlert("Successfully submitted to the program!", "success")
      );
    } catch (error) {
      console.log(error);
      dispatch(handleServerError(error));
    }
  };

export const getSubmissionById = (submissionId) => async (dispatch) => {
  try {
    // await dispatch({
    //   type: LOADING_CHALLENGES,
    // });
    const res = await axios.get(`/api/challenge/submission/${submissionId}`);

    await dispatch({
      type: GET_SUBMISSION,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getSubmissionAndProjectById =
  (submissionId) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/challenge/submission/${submissionId}`);

      await dispatch({
        type: GET_SUBMISSION,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const setActiveChallenge =
  (isActive, challengeId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to make this program ${
            isActive ? "active" : "inactive"
          }?`
        )
      ) {
        const body = {
          isActive,
          challengeId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/challenge/setActiveStatus", body, config);
        await dispatch({
          type: IS_ACTIVE_CHALLENGE,
          payload: isActive,
        });
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const clearAllChallenges = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_ALL_CHALLENGES });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const clearMyChallenges = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_MY_CHALLENGES });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const addUpdateChallenge = (inputChallenge) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/challenge", inputChallenge, config);
    await dispatch({
      type: ADD_UPDATE_CHALLENGE,
      payload: res.data,
    });
    return true;
  } catch (error) {
    dispatch(handleServerError(error));
    return false;
  }
};

export const addUpdateChallengeRound = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/challenge/round/new", formData, config);
    await dispatch({
      type: ADD_UPDATE_CHALLENGE_ROUND,
      payload: res.data,
    });
    return true;
  } catch (error) {
    dispatch(handleServerError(error));
    return false;
  }
};

export const addChallengeProperties =
  (challengeId, properties) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = {
        properties: properties,
        challengeId: challengeId,
      };

      const res = await axios.post("/api/challenge/properties", body, config);
      await dispatch({
        type: ADD_CHALLENGE_PROPERTIES,
        payload: res.data,
      });

      await dispatch(
        setAlert(
          "Successfully updated the descriptions for the program.",
          "success"
        )
      );
      // return true;
    } catch (error) {
      dispatch(handleServerError(error));
      // return false;
    }
  };

export const getAllMyChallenges = () => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_CHALLENGES,
    });
    const res = await axios.get("/api/challenge/all/myRegistrations");
    await dispatch({
      type: GET_MY_CHALLENGES,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: GET_MY_CHALLENGES,
      payload: [],
    });
    dispatch(handleServerError(error));
  }
};

export const getPublicSubmissions = (challengeRoundId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/challenge/public/projectSubmissions/${challengeRoundId}`
    );
    await dispatch({
      type: SHOW_SUBMISSIONS,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: SHOW_SUBMISSIONS,
      payload: [],
    });
    dispatch(handleServerError(error));
  }
};

export const getMySubmissionWithScores =
  (challengeId, challengeRoundId) => async (dispatch) => {
    try {
      // await dispatch({
      //   type: LOADING_CHALLENGES,
      // });
      const res = await axios.get(
        `/api/challenge/all/mySubmissions/${challengeId}/${challengeRoundId}`
      );

      await dispatch({
        type: GET_MY_SUBMISSION,
        payload: res.data,
      });
    } catch (error) {
      await dispatch({
        type: CLEAR_MY_SUBMISSION,
      });
      dispatch(handleServerError(error));
    }
  };

export const getAllMyOrganizationChallenges =
  (companyId) => async (dispatch) => {
    try {
      await dispatch({
        type: LOADING_CHALLENGES,
      });
      //console.log("Before allByUserCompany");
      const res = await axios.get(
        `/api/challenge/allByUserCompany/${companyId}`
      );
      await dispatch({
        type: GET_MY_CHALLENGES,
        payload: res.data,
      });
    } catch (error) {
      //console.log(error);
      await dispatch({
        type: GET_MY_CHALLENGES,
        payload: [],
      });
      dispatch(handleServerError(error));
    }
  };

export const enableJudgingPassword =
  (challengeRoundId, isEnabled) => async (dispatch) => {
    try {
      const body = { challengeRoundId, isEnabled };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post("/api/challenge/enableJudgingPassword", body, config);

      await dispatch({
        type: ENABLE_JUDGING_PASSWORD,
        payload: {
          challengeRoundId,
          isEnabled,
        },
      });
      await dispatch(
        setAlert(
          `Successfully ${
            isEnabled ? "enabled" : "disabled"
          } the reviewer password for this round.`,
          "success"
        )
      );
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const getAllChallenges =
  ({ isPublic = true, isPublished = true, isActive = true }) =>
  async (dispatch) => {
    try {
      await dispatch({
        type: LOADING_CHALLENGES,
      });
      // make request to api to get all active, public, published challenges
      const body = {
        isPublic: isPublic,
        isPublished: isPublished,
        isActive: isActive,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/challenge/all", body, config);
      dispatch({
        type: GET_ALL_CHALLENGES,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const getChallengeAndSubmissions = (slug) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_CHALLENGES,
    });
    // make request to get the challenge information based on the slug
    const res = await axios.get(`/api/challenge/slug/${slug}`);
    await dispatch({
      type: GET_CHALLENGE,
      payload: res.data,
    });
    const challenge = res.data;
    let currentRound;
    if (challenge) {
      currentRound = getCurrentRound(challenge);
    } /* 
    const round =
      challenge && challenge.round && challenge.round.length
        ? challenge.round[0]
        : null; */

    await dispatch(getSubmissions(challenge.id, currentRound.id));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getChallengeAndRegistrations = (slug) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_CHALLENGES,
    });
    const res = await axios.get(`/api/challenge/slug/${slug}`);
    await dispatch({
      type: GET_CHALLENGE,
      payload: res.data,
    });
    const challenge = res.data;

    await dispatch(getRegistrations(challenge.id));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getChallenge = (slug) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_CHALLENGE,
    });

    // make request to get the challenge information based on the slug
    const res = await axios.get(`/api/challenge/slug/${slug}`);
    await dispatch({
      type: GET_CHALLENGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CLEAR_CHALLENGE,
    });
    dispatch(handleServerError(error));
  }
};
export const getMyChallenge = (slug) => async (dispatch) => {
  try {
    // await dispatch({
    //   type: LOADING_CHALLENGES,
    // });
    const res = await axios.get(`/api/challenge/byUserCompany/${slug}`);
    await dispatch({
      type: GET_CHALLENGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CLEAR_CHALLENGE,
    });
    dispatch(handleServerError(error));
  }
};

export const getSubmissions = (challengeId, roundId) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_CHALLENGES,
    });
    const res = await axios.get(
      `/api/challenge/submissions/${challengeId}/${roundId}`
    );

    await dispatch({
      type: SHOW_SUBMISSIONS,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: SHOW_SUBMISSIONS,
      payload: null,
    });
    dispatch(handleServerError(error));
  }
};

export const getSubmissionsWithScores =
  (challengeId, roundId) => async (dispatch) => {
    try {
      await dispatch({
        type: LOADING_CHALLENGES,
      });
      const res = await axios.get(
        `/api/challenge/submissions/scores/${challengeId}/${roundId}`
      );

      await dispatch({
        type: SHOW_SUBMISSIONS,
        payload: res.data,
      });
    } catch (error) {
      await dispatch({
        type: SHOW_SUBMISSIONS,
        payload: null,
      });
      dispatch(handleServerError(error));
    }
  };

export const getCurrentUserStatus = (challengeId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/challenge/getCurrentUserStatus/${challengeId}`
    );
    if (res.data) {
      await dispatch({
        type: SET_CHALLENGE_STATUS_FOR_USER,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const updateSubmissionStatusTypeId =
  (submissionId, submittedStatusTypeId) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const body = { submissionId, submittedStatusTypeId };
      const res = await axios.post(
        `/api/challenge/updateSubmissionStatusTypeId`,
        body,
        config
      );
      await dispatch({
        type: UPDATE_SUBMISSION_STATUS_TYPE,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const saveAssignmentToJudgesSettings =
  (formData) => async (dispatch) => {
    try {
      // Already Verified that there are judges already created for this challenge
      // Already Verified that there are submissions for this challenge
      // Verify that the correct number of submissions and judges are assigned to each other
      //if (await isValidJudgeAndSubmissionAssigment(formData)) {
      if (
        window.confirm(
          "Are you sure you want to assign active reviewers to submissions for this round?"
        )
      ) {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        // Check if they have already been assigned
        const resAssigned = await axios.post(
          `/api/challenge/judgesAlreadyAssigned`,
          formData,
          config
        );
        let assignJudges = true;
        if (resAssigned.data) {
          if (
            !window.confirm(
              "Are you sure you want to re-assign active reviewers to the submissions again for this round? Previous assigments will be deleted from the database on re-assignment"
            )
          ) {
            assignJudges = false;
          }
        }
        if (assignJudges) {
          const res = await axios.post(
            `/api/challenge/saveJudgeAssignments`,
            formData,
            config
          );

          await dispatch({
            type: SHOW_SUBMISSIONS,
            payload: res.data,
          });
          await dispatch(
            setAlert(
              "Successfully saved all the reviewers assignment settings.",
              "success"
            )
          );
        }
      }
      //}
    } catch (error) {
      await dispatch({
        type: SHOW_SUBMISSIONS,
        payload: null,
      });
      dispatch(handleServerError(error));
    }
  };

// Write a wrapper for all other calls that don't send default pagination parameters
export const getRegistrations = (challengeId) => async (dispatch) => {
  try {
    // await dispatch({
    //   type: LOADING_CHALLENGES,
    // });
    const res = await axios.get(`/api/challenge/registrations/${challengeId}`);
    await dispatch({
      type: SHOW_REGISTRATIONS,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: SHOW_REGISTRATIONS,
      payload: null,
    });
    dispatch(handleServerError(error));
  }
};

export const eliminateTeamFromChallenge =
  (challengeId, teamId, eliminated) => async (dispatch) => {
    try {
      const body = {
        challengeId,
        teamId,
        eliminated,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/challenge/eliminateTeam`,
        body,
        config
      );
      await dispatch({
        type: DISQUALIFY_REGISTERED_TEAM,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const hasUserSubmittedAndIsEligible =
  (challengeId, challengeRoundId) => async (dispatch) => {
    try {
      await dispatch({
        type: LOADING_CHALLENGES,
      });
      /* const hasUserSubmittedRes = await axios.get(
      `/api/challenge/hasUserSubmitted/${challengeRoundId}`
    ); */

      /* const eligibleToSubmitRes = await axios.get(
      `/api/challenge/eligibleToSubmit/${challenge.id}/${challengeRoundId}`
    ); */
      const submissionAndEligibilityStatus = await axios.get(
        `/api/challenge/submissionAndEligibilityStatus/${challengeId}/${challengeRoundId}`
      );

      if (
        submissionAndEligibilityStatus &&
        submissionAndEligibilityStatus.data
      ) {
        await dispatch({
          type: USER_SUBMIT_ELIGIBLE_STATUS,
          payload: {
            id: challengeId,
            roundId: challengeRoundId,
            hasUserSubmitted: submissionAndEligibilityStatus.data.hasSubmitted,
            isEligibleToSubmit:
              submissionAndEligibilityStatus.data.eligibleForRound,
            isCurrentRound: submissionAndEligibilityStatus.data.isCurrentRound,
            isRegistered: submissionAndEligibilityStatus.data.isRegistered,
          },
        });
      }
      return true;
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const hasUserSubmitted = (challengeRoundId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/challenge/hasUserSubmitted/${challengeRoundId}`
    );
    await dispatch({
      type: HAS_USER_SUBMITTED,
      payload: res.data || { isSubmitted: false },
    });
  } catch (error) {
    await dispatch({
      type: HAS_USER_SUBMITTED,
      payload: { isSubmitted: false },
    });
    dispatch(handleServerError(error));
  }
};

export const eligibleToSubmitToRound =
  (challengeId, challengeRound) => async (dispatch) => {
    try {
      let eligibleFlag;

      if (challengeRound && challengeRound.roundNumber === 1) {
        eligibleFlag = true;
      } else {
        const res = await axios.get(
          `/api/challenge/eligibleToSubmit/${challengeId}/${challengeRound.id}`
        );
        eligibleFlag = res && res.data;
      }

      await dispatch({
        type: ELIGIBLE_TO_SUBMIT_TO_ROUND,
        payload: eligibleFlag || false,
      });
    } catch (error) {
      await dispatch({
        type: ELIGIBLE_TO_SUBMIT_TO_ROUND,
        payload: false,
      });
      dispatch(handleServerError(error));
    }
  };

export const registerUserChallenge =
  ({ challengeId, termsAccepted, isLeader }) =>
  async (dispatch) => {
    try {
      const body = { challengeId, termsAccepted, isLeader };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.post("/api/challenge/register", body, config);

      // Mixpanel Tracking
      // analytics.track(A_REGISTER_USER_CHALLENGE, {
      //   challengeId: challengeId,
      //   isLeader: isLeader,
      // });

      await dispatch({
        type: REGISTER_USER_CHALLENGE,
        payload: true,
      });

      await dispatch(
        setAlert("Successfully registered for this program!", "success")
      );
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };
export const organizationRegisterUserChallenge =
  (email, challengeId) => async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to register ${email} for this program?`
        )
      ) {
        const isLeader = true;
        const termsAccepted = true;
        const body = {
          email: email,
          challengeId: challengeId,
          isLeader: isLeader,
          termsAccepted: termsAccepted,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(
          "/api/challenge/register/user",
          body,
          config
        );

        // Mixpanel Tracking
        // analytics.track(A_REGISTER_ORGANIZATION_USER_CHALLENGE, {
        //   challengeId: challengeId,
        //   isUserRegisteredLeader: isLeader,
        //   userRegisteredEmail: email,
        // });

        await dispatch({
          type: SHOW_REGISTRATIONS,
          payload: res.data,
        });
        await dispatch(
          setAlert("Successfully registered user for this program.", "success")
        );
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const leaveChallenge = (challengeId) => async (dispatch) => {
  try {
    if (window.confirm("Are you sure you want to leave this program?")) {
      const body = { challengeId };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.put("/api/challenge/leave", body, config);

      await dispatch({
        type: LEAVE_CHALLENGE,
        payload: false,
      });
      await dispatch(setAlert("Successfully left this program.", "success"));
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const isUserRegistered = (challengeId) => async (dispatch) => {
  try {
    if (challengeId === null) return;
    //console.log("calling isUserRegistered", challengeId);
    const res = await axios.get(`/api/challenge/isRegistered/${challengeId}`);

    await dispatch({
      type: IS_USER_REGISTERED,
      payload:
        res.data && res.data.isRegistered ? res.data.isRegistered : false,
    });
  } catch (error) {
    //console.log(error && error.response);
    dispatch(handleServerError(error));
  }
};

export const fetchTeamInformation =
  (challengeId, userId) => async (dispatch) => {
    try {
      if (challengeId === null || userId === null) return;

      //console.log(challengeId, userId);

      // get the team information for this user and this challenge
      const res = await axios.get(`/api/team/${challengeId}/${userId}`);
      await dispatch({
        type: GET_TEAM_INFORMATION,
        payload: res.data,
      });
    } catch (error) {
      await dispatch({
        type: CLEAR_MY_TEAM,
      });
      dispatch(handleServerError(error));
    }
  };
export const addTeamMember =
  (userAccountId, challengeId, teamId) => async (dispatch) => {
    try {
      if (challengeId === null || userAccountId === null || teamId === null)
        return;
      const body = {
        userAccountId,
        challengeId,
        teamId,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`/api/team/addTeamMember`, body, config);

      // Mixpanel tracking
      // analytics.track(A_ADD_TEAM_MEMBER_CHALLENGE, {
      //   challengeId: challengeId,
      //   teamMemberAddedId: userAccountId,
      //   teamId: teamId,
      // });

      await dispatch({
        type: ADD_TEAM_MEMBER,
        payload: res.data,
      });
    } catch (error) {
      await dispatch({
        type: CLEAR_MY_TEAM,
      });
      dispatch(handleServerError(error));
    }
  };
export const deleteTeamMember =
  (userAccountId, challengeId, teamId, removingSelf = false) =>
  async (dispatch) => {
    try {
      if (
        window.confirm(
          `Are you sure you want to ${
            removingSelf ? "leave" : "remove this person from"
          } this program?`
        )
      ) {
        if (challengeId === null || userAccountId === null || teamId === null)
          return;
        const body = {
          userAccountId,
          challengeId,
          teamId,
        };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(
          `/api/team/deleteTeamMember`,
          body,
          config
        );
        // Mixpanel Tracking
        // analytics.track(A_DELETE_TEAM_MEMBER_CHALLENGE, {
        //   challengeId: challengeId,
        //   teamId: teamId,
        //   removingSelf: removingSelf,
        //   teamMemberDeletedId: userAccountId,
        // });
        await dispatch({
          type: GET_TEAM_INFORMATION,
          payload: res.data,
        });
        if (removingSelf) {
          await dispatch({
            type: REMOVING_SELF_TEAM,
          });
        }
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const modifyTeamName =
  (challengeId, teamId, name) => async (dispatch) => {
    try {
      if (challengeId === null || teamId === null || name === null) return;

      const body = { challengeId, teamId, name };
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      // get the team information for this user and this challenge
      await axios.post(`/api/team/name`, body, config);
      await dispatch({
        type: MODIFY_TEAM_NAME,
        payload: name,
      });
      await dispatch(setAlert("Successfully updated team name.", "success"));
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const clearChallenge = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_CHALLENGE,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const submitToChallengeRound =
  (challengeId, roundId, submissionDetails) => async (dispatch) => {
    try {
      const body = {
        ...submissionDetails,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.log(body, challengeId, roundId);
      await axios.post(
        `/api/challenge/submit/${challengeId}/${roundId}`,
        body,
        config
      );

      //console.log(submissionDetails);
      // analytics.track(A_SUBMIT_CHALLENGE, {
      //   challengeId: challengeId,
      //   roundId: roundId,
      //   projectId: submissionDetails.projectId,
      // });

      await dispatch({
        type: HAS_USER_SUBMITTED,
        payload: { isSubmitted: true },
      });
      dispatch(
        setAlert(
          "Congrats! You have successfully submitted for this program.",
          "success",
          10000
        )
      );
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

// Get a list of all the judges assigned to this challenge round
export const fetchAllPrizes = (challengeId) => async (dispatch) => {
  try {
    if (challengeId) {
      const res = await axios.get(`/api/challenge/prize/all/${challengeId}`);

      dispatch({
        type: GET_ALL_PRIZES,
        payload: res.data,
      });
    }
  } catch (error) {
    await dispatch({
      type: GET_ALL_PRIZES,
    });
    dispatch(handleServerError(error));
  }
};

// Add new prize
export const addEditPrize = (formData) => async (dispatch) => {
  try {
    const prizeConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `/api/challenge/prize/add`,
      formData,
      prizeConfig
    );

    await dispatch({
      type: GET_ALL_PRIZES,
      payload: res.data,
    });

    await dispatch(
      setAlert(
        formData.id
          ? "Successfully edited prize detail"
          : "Successfully added new prize",
        "success"
      )
    );
    return true;
  } catch (error) {
    dispatch(handleServerError(error));
    // dispatch(handleServerError(error));
    return false;
  }
};

// Delete a prize
export const deletePrize = (prize, challengeId) => async (dispatch) => {
  try {
    const res = await axios.del(
      `/api/challenge/${challengeId}/prize/${prize.id}`
    );

    await dispatch({
      type: DELETE_PRIZE,
      payload: res.data,
    });

    await dispatch(setAlert("Successfully deleted prize", "success"));
    return true;
  } catch (error) {
    dispatch(handleServerError(error));
    return false;
  }
};
