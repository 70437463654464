import {
  ONBOARDING_CLEAR_CHALLENGE,
  ONBOARDING_FROM_CHALLENGE,
} from "../actions/actionTypes";

const initialState = {
  challengeDetails: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ONBOARDING_FROM_CHALLENGE: {
      return {
        ...state,
        challengeDetails: payload,
      };
    }
    case ONBOARDING_CLEAR_CHALLENGE: {
      return {
        ...state,
        challengeDetails: null,
      };
    }

    default:
      return state;
  }
};
