import React, { useState, useEffect } from "react";
// react-query
import { useMutation } from "react-query";
import {
  addEditMessagingAppSettingsAPI,
  useFetchCommunityMessagingAppSettings,
} from "../../../hooks/community";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import Box from "@material-ui/core/Box";
import Button from "../../../shared/Button";
import { FormHelperText } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
// Icons
import { IoLogoSlack, IoLogoDiscord } from "react-icons/io5";

const OrgMessagingAppSettings = ({ company, setAlert }) => {
  const [formData, setFormData] = useState({
    id: "",
    companyId: company && company.id,
    slackUrl: "",
    discordUrl: "",
  });

  const { isLoading, isError, data, error } =
    useFetchCommunityMessagingAppSettings(company);

  useEffect(() => {
    if (data && data.messagingAppSettings) {
      setFormData({
        ...formData,
        id: data.messagingAppSettings.id || "",
        companyId: company && company.id,
        slackUrl: data.messagingAppSettings.slackUrl || "",
        discordUrl: data.messagingAppSettings.discordUrl || "",
      });
    }
  }, [data]);

  const addEditCommunityAppSettingsMutation = useMutation(
    addEditMessagingAppSettingsAPI,
    {
      onSuccess: async (data) => {
        if (data && data.data) {
          await setAlert(
            `Succesfully ${
              event ? "updated" : "created"
            } your messaging app settings!`,
            "success"
          );
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    addEditCommunityAppSettingsMutation.mutate({
      companyId: company.id,
      messagingAppSettings: formData,
    });
  };

  const handleChange = (e) => {
    let value;
    value = e.target.value;
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const { slackUrl, discordUrl } = formData;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h2">
            <Box fontWeight={600}>Messaging</Box>
          </Typography>
          <Typography variant="body2" component="div" color="textSecondary">
            <Box>
              To keep your community members involved and informed, you can
              allow them to join specific Slack workspace and/or Discord server.
              This information will be displayed to the members of your
              community.
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoLogoSlack />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>Slack Workspace</InputLabel>
                <TextField
                  placeholder="Add an invite link to your Slack workspace"
                  id="slackUrl"
                  name="slackUrl"
                  value={slackUrl}
                  onChange={handleChange}
                  type="url"
                />
                <FormHelperText>
                  Create an invite link to your Slack workspace that never
                  expires.{" "}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://slack.com/help/articles/201330256-Invite-new-members-to-your-workspace#share-an-invitation-link"
                  >
                    Learn more about invite links in Slack.
                  </Link>
                </FormHelperText>
              </FormGroup>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Icon fontSize="large">
                <IoLogoDiscord />
              </Icon>
            </Box>
            <Box width="100%">
              <FormGroup>
                <InputLabel>Discord Server</InputLabel>
                <TextField
                  placeholder="Add an invite link to your Discord server"
                  id="discordUrl"
                  name="discordUrl"
                  value={discordUrl}
                  onChange={handleChange}
                  type="url"
                />
                <FormHelperText>
                  Create an invite link to your Discord server that never
                  expires.{" "}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.discord.com/hc/en-us/articles/208866998-Invites-101"
                  >
                    Learn more about invite links in Discord.
                  </Link>
                </FormHelperText>
              </FormGroup>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit">Save</Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  company: state.company.companyProfile,
});

const mapDispatchToProps = {
  setAlert,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgMessagingAppSettings);
