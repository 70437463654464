import React, { useState } from "react";
import { clearOnboardingChallengeDetails } from "../../redux/actions/onboarding";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// Material UI
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Components
import CustomAvatar from "../../shared/CustomAvatar";
import LogInForm from "../../shared/LogInForm";
import Button from "../../shared/Button";
// Images
import TransparentBackgroundLogo from "../../assets/brand/logos/TransparentBackgroundLogo.png";

const AVATARS = [
  "https://storage.googleapis.com/pralentpfiles/cb3ed400-42d4-4b22-8303-beeed641563d/ihbvY_/maxine_profile_investinkin.JPG",
  "https://storage.googleapis.com/pralentpfiles/94dff73c-6aab-4761-a4bb-2e6a59fbb94b/5WWBPx/Sheetal.jpg",
  "https://storage.googleapis.com/pralentpfiles/db0f7d0a-84a0-4d13-8800-cb39ef1f81eb/SamarthGowdaLinkedInProfile.png",
  "https://storage.googleapis.com/pralentpfiles/0602485e-8a81-4166-9ab1-ec7a7e1912ae/portrait2.jpg",
  "https://storage.googleapis.com/pralentpfiles/f9daa169-032c-4a86-884a-271a48b23213/0.jpeg",
  "https://storage.googleapis.com/pralentpfiles/117546f5-4137-4a72-ab8e-28b0d659cbd2/C9C30892-7356-4B78-830C-B3BD86BBCDC5.jpeg",
  "https://storage.googleapis.com/pralentpfiles/57a8b44a-0425-49a9-95ea-e18e0408d02c/SAM_3622.jpeg",
  "https://storage.googleapis.com/pralentpfiles/e472ede3-02b6-45c9-94b4-07fb919e8c1f/t4e6kc/John_Winters_Headshot.jpg",
  "https://storage.googleapis.com/pralentpfiles/a0e8d3b9-dc30-44bd-ad72-5ba94f7248db/Ariel_Profile_Circle.png",
];

const useStyles = makeStyles((theme) => ({
  Link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  logo: {
    height: 50,
    width: 50,
  },
  avatar: {
    borderWidth: 1,
    borderColor: theme.palette.background.paper,
  },
}));

const COMMUNITY_FEATURES = [
  {
    emoji: "✨",
    boldText: "Join communities.",
    unboldText: "Discover communities and groups that interest you.",
  },
  {
    emoji: "🙋",
    boldText: "Connect with others.",
    unboldText: "Build meaningful relationships with your community members.",
  },
  {
    emoji: "💼",
    boldText: "Discover new opportunities.",
    unboldText: "Find new opportunities within your community.",
  },
  {
    emoji: "🎟️",
    boldText: "Participate.",
    unboldText: "Join events, projects, competitions, programs, and more.",
  },
  // {
  //   emoji: "💬",
  //   boldText: "Discuss in rooms.",
  //   unboldText:
  //     "Each community has rooms for members to learn from each other.",
  // },
];

const UserSignUp = () => {
  const classes = useStyles();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const redirect = query.get("redirect");

  const [setupCommunity, setSetupCommunity] = useState(false);

  return (
    <Box>
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          py={2}
        >
          <Box
            p={1}
            mb={2}
            borderRadius="borderRadius"
            bgcolor="background.paper"
          >
            <img
              src={TransparentBackgroundLogo}
              alt="TransparentBackgroundLogo"
              className={classes.logo}
            />
          </Box>
          <Box my={3}>
            <Grid container spacing={mobileScreen ? 0 : 8} justify="center">
              <Grid item xs={false} md={6}>
                <Box display={mobileScreen ? "none" : "block"}>
                  <Typography gutterBottom variant="h4" component="h2">
                    <Box mb={{ xs: 2, md: 4 }} fontWeight={700}>
                      All of your communities in one place.
                    </Box>
                  </Typography>
                  <Box>
                    {(COMMUNITY_FEATURES || []).map((feature, index) => {
                      return (
                        <Box key={index} mb={{ xs: 2, md: 4 }}>
                          <Typography variant="body1" component="div">
                            <Box display="flex" alignItems="flex-start">
                              <Box fontSize="h6.fontSize" mr={2}>
                                {feature.emoji}
                              </Box>
                              <Box>
                                <Box lineHeight={1.2}>
                                  <strong>{feature.boldText}</strong>{" "}
                                  {feature.unboldText}
                                </Box>
                              </Box>
                            </Box>
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Container maxWidth="xs">
                  <Box textAlign="center">
                    <Typography gutterBottom variant="h4" component="h1">
                      <Box fontWeight={700}>Join Pralent 🏠</Box>
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      py={1}
                    >
                      <AvatarGroup max={12}>
                        {AVATARS.map((img, index) => (
                          <CustomAvatar
                            key={index}
                            src={img}
                            alt={img}
                            fallbackText={img}
                            className={classes.avatar}
                          />
                        ))}
                      </AvatarGroup>
                    </Box>
                  </Box>
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    style={{ gap: "16px" }}
                  >
                    <Button
                      size="large"
                      variant={setupCommunity ? "contained" : "outlined"}
                      onClick={() => setSetupCommunity(true)}
                      style={{ width: "50%" }}
                      component={Link}
                      to="/signup?redirect=/org/new"
                    >
                      <Box textAlign="center">I'm setting up a community</Box>
                    </Button>
                    <Button
                      size="large"
                      variant={!setupCommunity ? "contained" : "outlined"}
                      onClick={() => setSetupCommunity(false)}
                      style={{ width: "50%" }}
                      component={Link}
                      to="/signup"
                    >
                      <Box textAlign="center">I'm creating my profile</Box>
                    </Button>
                  </Box>
                  <Box mt={3}>
                    <LogInForm signup={true} />
                  </Box>
                  <Box mt={2}>
                    <Typography
                      variant="body1"
                      component="div"
                      color="textSecondary"
                    >
                      <Box lineHeight={1.2}>
                        By creating an account, you are agreeing to our{" "}
                        <Link to="/terms" className={classes.Link}>
                          terms of service
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy" className={classes.Link}>
                          privacy policy
                        </Link>
                        .
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="body1" color="textSecondary">
                      Already have an account?{" "}
                      <Link
                        className={classes.Link}
                        to={
                          `/login` + (redirect ? `?redirect=${redirect}` : "")
                        }
                      >
                        Login
                      </Link>
                    </Typography>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = { clearOnboardingChallengeDetails };

export default connect(null, mapDispatchToProps)(UserSignUp);
