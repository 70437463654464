import React from "react";
// Redux
import { connect } from "react-redux";
// React-Router
// Components
import CandidateCard from "./CandidateCard";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const CandidateDialog = ({ job, open, setOpen }) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Candidates</DialogTitle>
      <DialogContent>
        {job &&
          job.jobPostActivity &&
          job.jobPostActivity.map((c) => {
            return <CandidateCard key={c.id} job={job} candidate={c} />;
          })}
        {!job ||
          !job.jobPostActivity ||
          (job.jobPostActivity.length <= 0 && (
            <Typography variant="body2" component="div" color="textSecondary">
              <Box mb={4} textAlign="center">
                There are no candidates for this job posting at this time.
              </Box>
            </Typography>
          ))}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  myCompanyJobs: state.jobs.myCompanyJobs,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDialog);
