import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPublicJudgingInfo } from "../../../../redux/actions/judge";
// Components
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0),
  },
  CriteriaHeader: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

const ChallengeJudging = ({
  challenge,
  auth,
  judges,
  criterias,
  getPublicJudgingInfo,
}) => {
  const classes = useStyles();
  const [currentRoundIndex, setCurrentRoundIndex] = useState(-1);

  useEffect(() => {
    if (
      challenge.round &&
      0 <= currentRoundIndex &&
      currentRoundIndex < challenge.round.length
    ) {
      const round = challenge.round[currentRoundIndex];

      getPublicJudgingInfo(round.id);
    }
  }, [currentRoundIndex]);

  useEffect(() => {
    const foundIndex =
      challenge &&
      challenge.round &&
      challenge.round.length > 0 &&
      challenge.round.findIndex((elem) => elem.id === challenge.currentRoundId);
    setCurrentRoundIndex(foundIndex || 0);
  }, [challenge]);
  return (
    <Fragment>
      <Select
        className={classes.select}
        margin="dense"
        variant="outlined"
        id="currentRoundIndex"
        value={currentRoundIndex}
        name="currentRoundIndex"
        onChange={(e) => setCurrentRoundIndex(e.target.value)}
      >
        {challenge &&
          challenge.round &&
          challenge.round.length > 0 &&
          challenge.round.map((round, index) => {
            return (
              <MenuItem value={index} key={index} dense>
                <Typography variant="body2">{round.name}</Typography>
              </MenuItem>
            );
          })}
      </Select>

      {
        <Fragment>
          {challenge.round &&
          challenge.round.length > 0 &&
          challenge.round[currentRoundIndex] &&
          challenge.round[currentRoundIndex].isCriteriaPublic ? (
            <Fragment>
              {criterias && criterias.length > 0 ? (
                <>
                  <Box my={2}>
                    <Typography color="textSecondary" variant="body2">
                      Criterias
                    </Typography>
                  </Box>
                  {criterias.map((c, index) => {
                    return (
                      <>
                        <Box
                          key={index}
                          textAlign="left"
                          my={1}
                          p={1}
                          color="grey.600"
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={1}
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              color="textPrimary"
                            >
                              <Box lineHeight={1.2} fontWeight={600}>
                                {c.name}
                              </Box>
                            </Typography>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textPrimary"
                            >
                              Maximum Score: {c.maxScore}
                            </Typography>
                          </Box>

                          <Typography variant="body2" color="textSecondary">
                            <Box whiteSpace="pre-line" lineHeight={1.2}>
                              {c.description}
                            </Box>
                          </Typography>
                        </Box>
                        {index + 1 < criterias.length && <Divider />}
                      </>
                    );
                  })}
                </>
              ) : (
                <Box my={2}>
                  <Typography color="textSecondary" variant="body2">
                    There are no criterias entered for this round.
                  </Typography>
                </Box>
              )}
            </Fragment>
          ) : (
            <Box my={2}>
              <Typography color="textSecondary" variant="body2">
                The criterias have not been made public by the organization.
              </Typography>
            </Box>
          )}
          {challenge.round &&
          challenge.round.length > 0 &&
          challenge.round[currentRoundIndex] &&
          challenge.round[currentRoundIndex].isJudgesPublic ? (
            <Fragment>
              {judges && judges.length > 0 ? (
                <Fragment>
                  <Box my={2}>
                    <Typography color="textSecondary" variant="body2">
                      Reviewers
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    {judges.map((j, index) => {
                      const photoUrl = j.photoUrl;
                      const firstName = j.firstName;
                      const lastName = j.lastName;
                      return (
                        <Grid item xs={12} md={6} lg={4} key={index}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="start"
                            p={1}
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              color="textPrimary"
                            >
                              <Box lineHeight={1.2} fontWeight={600}>
                                {firstName} {lastName}
                              </Box>
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Fragment>
              ) : (
                <Box my={2}>
                  <Typography color="textSecondary" variant="body2">
                    There are no reviewers entered for this round.
                  </Typography>
                </Box>
              )}
            </Fragment>
          ) : (
            <Box my={2}>
              <Typography color="textSecondary" variant="body2">
                The reviewers information have not been made public by the
                organization.
              </Typography>
            </Box>
          )}
        </Fragment>
      }
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  auth: state.auth,
  judges: state.judge.judges,
  criterias: state.judge.criterias,
});

const mapDispatchToProps = { getPublicJudgingInfo };

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeJudging);
