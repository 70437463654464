import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { getAllGroupsForRound } from "../../redux/actions/group";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
// Components
import Button from "../Button";
import Spinner from "../Spinner";
import GroupDialogContent from "./components/GroupDialogContent";
// Icons
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles((theme) => ({
  Dialog: {
    marginBottom: 0,
  },
}));

export const ChallengeGroups = ({
  challenge,
  currentRoundIndex,
  groups,
  getAllGroupsForRound,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      const currentRound = challenge.round[currentRoundIndex];
      getAllGroupsForRound(currentRound.id);
    }
  }, [open, currentRoundIndex]);

  return (
    <>
      <Tooltip
        placement="top"
        title="Add groups to this round. Groups allow you to group together similar submissions and reviewers."
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<GroupIcon />}
          onClick={onOpen}
        >
          Groups
        </Button>
      </Tooltip>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby={`reviewer-scores-dialog`}
        className={classes.Dialog}
      >
        <Box>{groups.loading ? <Spinner /> : <GroupDialogContent />}</Box>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  groups: state.group.groups,
});

const mapDispatchToProps = { getAllGroupsForRound };

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeGroups);
