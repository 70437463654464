import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { setRegistrationPrivacySetting } from "../../../redux/actions/challenge";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
// icons
import SettingsIcon from "@material-ui/icons/Settings";
// components
import Button from "../../../shared/Button";

export const RegistrationSettings = ({
  challenge,
  setRegistrationPrivacySetting,
}) => {
  const [isRegistrationPublic, setIsRegistrationPublic] = useState(false);
  const [openSettings, setOpenSettings] = React.useState(false);

  useEffect(() => {
    if (challenge) {
      setIsRegistrationPublic(challenge.isRegistrationPublic);
    }
  }, [challenge]);

  const handleCloseSettings = () => {
    setOpenSettings(false);
  };
  const handleRegistrationPrivacyClick = () => {
    setOpenSettings(true);
  };

  return (
    <>
      <Dialog
        open={openSettings}
        onClose={handleCloseSettings}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Privacy Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Public registrations are available for everyone to view. Private
            registrations are viewable only within your organization.
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={isRegistrationPublic}
                onChange={() =>
                  setRegistrationPrivacySetting(
                    !isRegistrationPublic,
                    challenge.id
                  )
                }
                name="isPublic"
                color="primary"
              />
            }
            label={<>{isRegistrationPublic ? "Public" : "Private"}</>}
          />
        </DialogContent>
      </Dialog>
      <Tooltip
        title={"Set the privacy setting for the registrations"}
        placement="top"
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<SettingsIcon />}
          onClick={handleRegistrationPrivacyClick}
        >
          Privacy Setting
        </Button>
      </Tooltip>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {
  setRegistrationPrivacySetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationSettings);
