import React from "react";
import { Link, useParams } from "react-router-dom";
// redux
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
// Components
import Button from "../../../shared/Button";
import Card from "../../../shared/Card";
// Icons
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
// Utils
import ReactImageFallback from "react-image-fallback";
import { DEFAULT_TIME_ZONE } from "../../../utils/globalValues";
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    objectFit: "cover",
  },
}));

const OrgChallengeCard = ({ challenge, companyProfile }) => {
  const classes = useStyles();

  const { orgId } = useParams();

  if (!challenge) {
    return <Box />;
  }

  return (
    <>
      <Card
        key={challenge.id}
        bgcolor={
          challenge.isActive ? "background.paper" : "action.disabledBackground"
        }
        p={{ xs: 2, md: 4 }}
        mb={{ xs: 2 }}
      >
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4} xl={3}>
            <Box
              style={{
                position: "relative",
                paddingBottom: "56.2%",
              }}
            >
              <ReactImageFallback
                src={challenge.headerImagePath}
                fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                alt={`${challenge.title}-header-image`}
                className={classes.root}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
              <Box>
                <Typography variant="h4" component="h3">
                  <Box fontWeight={600}>{challenge.title}</Box>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box fontWeight={600} display="flex" alignItems="center">
                    {challenge.tagLine}
                  </Box>
                </Typography>
              </Box>
              <Box>
                {!challenge.isActive && (
                  <Chip
                    icon={<NotInterestedOutlinedIcon />}
                    label="Inactive"
                    color="default"
                  />
                )}
                {challenge.registrationDeadlineDatetime && (
                  <Chip
                    icon={<EventIcon />}
                    label={`Register by ${convertUTCTimeToZonedTime(
                      challenge.registrationDeadlineDatetime,
                      DEFAULT_TIME_ZONE
                    )}`}
                    color="default"
                  />
                )}

                <Chip
                  icon={
                    challenge.maxPeopleTeam === 1 ? (
                      <PersonOutlinedIcon />
                    ) : (
                      <GroupOutlinedIcon />
                    )
                  }
                  label={challenge.maxPeopleTeam === 1 ? "Individual" : "Team"}
                  color="default"
                />
                {challenge.location && (
                  <Chip
                    icon={<LocationOnOutlinedIcon />}
                    label={challenge.location}
                    color="default"
                  />
                )}
                {challenge.round && challenge.round.length > 0 && (
                  <Chip
                    icon={<AccountBalanceOutlinedIcon />}
                    label={`${challenge.round.length} Round${
                      challenge.round.length > 1 ? "s" : ""
                    }`}
                    color="default"
                  />
                )}
                {challenge.isPublic && (
                  <Chip
                    icon={<PublicOutlinedIcon />}
                    label="Public To Pralent"
                    color="default"
                  />
                )}
              </Box>
              <Box display="flex" style={{ gap: 4 }}>
                <Button
                  component={Link}
                  to={`/org/${orgId}/programs/${challenge.slug}/registrations`}
                  startIcon={<EditIcon />}
                >
                  Manage
                </Button>
                <Button
                  component={Link}
                  to={`/c/${companyProfile.communitySlug}/programs/${challenge.slug}`}
                  variant="outlined"
                  startIcon={<OpenInNewOutlinedIcon />}
                >
                  Preview
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgChallengeCard);
