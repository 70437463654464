import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Work = ({ formData, handleChange }) => {
  const { activelySeekingJob, privacySetting } = formData;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mb={2} textAlign="center">
            <Typography variant="h5" component="h1" gutterBottom>
              <Box fontWeight={600}>💼 Are you open to new opportunities?</Box>
            </Typography>
            <Typography variant="body1" component="div" color="textSecondary">
              <Box>
                We help our users gain access to new opportunities through the
                Pralent network. Please let us know if you are open to new
                opportunities.
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="activelySeekingJob"
                  name="activelySeekingJob"
                  color="primary"
                  checked={activelySeekingJob}
                  value={activelySeekingJob}
                  onChange={handleChange}
                />
              }
              label={
                <Box ml={1}>
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight={600}>Open to new opportunities</Box>
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      This will be made available to employers that have
                      communities on Pralent.
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id="privacySetting"
                  name="privacySetting"
                  color="primary"
                  checked={privacySetting}
                  value={privacySetting}
                  onChange={handleChange}
                />
              }
              label={
                <Box ml={1}>
                  <Typography variant="body2" component="div" gutterBottom>
                    <Box fontWeight={600}>
                      Make my profile public to all Pralent users
                    </Box>
                  </Typography>
                  <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                  >
                    <Box lineHeight={1.2}>
                      When you enable this setting, your profile will be
                      available for all users to view.
                    </Box>
                  </Typography>
                </Box>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default Work;
