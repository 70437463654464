import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { addChallengeProperties } from "../../../redux/actions/challenge";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
// Components
import Button from "../../../shared/Button";
import RichTextEditor from "../../../shared/RichTextEditor";
import OrgAppBar from "../../../shared/OrgAppBar";
import OrgChallengeAppBar from "../../../shared/OrgChallengeAppBar";
// Icons
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

const ChallengeDescriptions = ({ challenge, addChallengeProperties }) => {
  const [propertiesState, setPropertiesState] = useState([]);
  const [tab, setTab] = useState(null);

  const [needToSave, setNeedToSave] = useState(false);

  const handlePropertiesChange = (e, index) => {
    setNeedToSave(true);
    let oldProperties = [...propertiesState];
    let propertyToChange = oldProperties[index];
    propertyToChange = {
      ...propertyToChange,
      [e.target.name]: e.target.value,
    };
    oldProperties[index] = propertyToChange;
    setPropertiesState(oldProperties);
  };

  const handlePropertiesChangeValue = (newState, index) => {
    setNeedToSave(true);
    let oldProperties = [...propertiesState];
    let propertyToChange = oldProperties[index];

    propertyToChange = {
      ...propertyToChange,
      value: newState,
    };
    oldProperties[index] = propertyToChange;
    setPropertiesState(oldProperties);
  };

  const addNewProperty = () => {
    setNeedToSave(true);
    setTab(propertiesState.length);
    setPropertiesState([
      ...propertiesState,
      {
        challengeId: challenge.id,
        name: "Add Name",
        value: [
          {
            type: "paragraph",
            children: [
              { text: "Add information here to explain in further detail ..." },
            ],
          },
        ],
      },
    ]);
  };

  const handleDeleteProperty = (index) => {
    if (window.confirm("Are you sure you want to remove this description?")) {
      setNeedToSave(true);

      setTab(0);

      let oldProperties = [...propertiesState];

      oldProperties = oldProperties.filter((elem, i) => i !== index);
      setPropertiesState([...oldProperties]);
    }
  };

  useEffect(() => {
    if (challenge && challenge.properties && challenge.properties.length > 0) {
      let newProperties = [];
      challenge &&
        challenge.properties &&
        challenge.properties.length > 0 &&
        challenge.properties.map((prop, index) => {
          newProperties.push({
            challengeId: prop.challengeId,
            propertyOrderNumber: prop.propertyOrderNumber,
            name: prop.name,
            value: JSON.parse(prop.value),
          });
        });
      setPropertiesState(newProperties);
      setTab(0);
    }
  }, [challenge && challenge.id]);

  const handleSave = () => {
    setNeedToSave(false);
    let properties = [];
    for (let i = 0; i < propertiesState.length; i++) {
      const prop = propertiesState[i];

      properties.push({
        ...prop,
        value: JSON.stringify(prop.value),
        propertyOrderNumber: i + 1,
      });
    }
    addChallengeProperties(challenge.id, properties);
  };

  const handleMoveUpwardProperty = (index) => {
    setNeedToSave(true);

    if (index > 0) {
      let oldPropertyState = [...propertiesState];
      const previousElement = oldPropertyState[index - 1];
      oldPropertyState[index - 1] = oldPropertyState[index];
      oldPropertyState[index] = previousElement;
      if (tab === index) {
        setTab(tab - 1);
      }
      setPropertiesState(oldPropertyState);
    }
  };

  const handleMoveDownwardProperty = (index) => {
    setNeedToSave(true);

    if (index < propertiesState.length - 1) {
      let oldPropertyState = [...propertiesState];
      const nextElement = oldPropertyState[index + 1];
      oldPropertyState[index + 1] = oldPropertyState[index];
      oldPropertyState[index] = nextElement;
      if (tab === index) {
        setTab(tab + 1);
      }
      setPropertiesState(oldPropertyState);
    }
  };

  const OrgChallengeDescriptionsActions = () => {
    return (
      <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
        <Button onClick={addNewProperty}>Add Description</Button>
        <Button
          color="primary"
          disabled={!needToSave}
          onClick={handleSave}
          startIcon={<SaveIcon />}
        >
          Save All
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <OrgAppBar
          // title="Program Descriptions"
          subtitle="Descriptions contain information that is displayed on the program page"
          actions={<OrgChallengeDescriptionsActions />}
        />

        <Box display="flex" borderRadius="borderRadius">
          <Box width={1 / 4} mr={2}>
            {propertiesState &&
              propertiesState.map((prop, index) => {
                return (
                  <Box display="block" mb={2} key={index}>
                    <Box onClick={() => setTab(index)} width="100%">
                      <TextField
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Add name"
                        value={prop.name}
                        fullWidth
                        onChange={(e) => handlePropertiesChange(e, index)}
                        InputProps={{
                          endAdornment: (
                            <Box
                              display="flex"
                              alignItems="center"
                              flexDirection="row"
                            >
                              <IconButton
                                onClick={() => handleMoveUpwardProperty(index)}
                              >
                                <ArrowUpwardIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleMoveDownwardProperty(index)
                                }
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteProperty(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                );
              })}
          </Box>

          <Box width={3 / 4}>
            {propertiesState &&
              propertiesState.map((t, index) => {
                const handleChange = (newState) => {
                  handlePropertiesChangeValue(newState, index);
                };
                if (tab !== index) {
                  return <Box key={index} display="none" />;
                }
                return (
                  <Box key={index}>
                    <Box px={[1, 2]}>
                      <Typography variant="body1" gutterBottom component="div">
                        <Box fontWeight={600}>{t.name}</Box>
                      </Typography>
                      <RichTextEditor value={t.value} setValue={handleChange} />
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

export default connect(mapStateToProps, { addChallengeProperties })(
  ChallengeDescriptions
);
