import React from "react";
// import Cards from "./components/cards";
import Banner from "./components/banner";
import FAQ from "./components/faq";
import Breakdown from "./components/breakdown";
import StudentOrgFree from "./components/studentorgfree";
import AllPlanFeatures from "./components/allplanfeatures";

function Pricing() {
  return (
    <div>
      <Banner />
      <AllPlanFeatures />
      <StudentOrgFree />
      <Breakdown />
      <FAQ />
    </div>
  );
}

export default Pricing;
