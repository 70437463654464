/* eslint-disable no-use-before-define */
import React, { useEffect, Fragment } from "react";
// Redux
import { connect } from "react-redux";
import { assignJudgesToSubmission } from "../../../redux/actions/challenge";
import { fetchAllJudges } from "../../../redux/actions/judge";
// Material UI
import { fade, makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import DoneIcon from "@material-ui/icons/Done";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputBase from "@material-ui/core/InputBase";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
// Icons
import { IoMdArrowDropdown } from "react-icons/io";
// Components
import Spinner from "../../../shared/Spinner";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: "12px",
  },
  popper: {
    borderRadius: theme.shape.borderRadius,
    width: 350,
    zIndex: theme.zIndex.drawer,
    fontSize: 13,
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    padding: theme.spacing(1.5),
    fontWeight: 600,
    fontSize: "0.8rem",
  },
  inputBase: {
    padding: 10,
    width: "100%",
    "& input": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: `2px solid transparent`,
      fontSize: 14,
      "&:focus": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: theme.shadows[8],
    margin: 0,
    fontSize: 13,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  tagChip: {
    cursor: "pointer",
  },
}));

const ChallengeJudgeSubmissionDropdown = ({
  assignJudgesToSubmission,
  challenge,
  currentRoundIndex,
  judgeLoading,
  judges,
  fetchAllJudges,
  assignedJudges = [],
  submissionId = null,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([]);
  const [pendingValue, setPendingValue] = React.useState([]);

  const fetchJudges = async () => {
    await fetchAllJudges(challenge.round[currentRoundIndex].id);
  };

  const handleClick = (event) => {
    let newValue = [];
    // TAKE THE JUDGES ASSIGNED AND ADD THEM TO newValue
    assignedJudges.map((assignedJudge) => {
      const found = judges.find(
        (elem) => elem.id === assignedJudge.challengeJudgeId
      );
      if (found) {
        newValue.push(found);
      }
    });

    setPendingValue(newValue);
    setValue(newValue);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    let judgeArray = [];
    pendingValue &&
      pendingValue.map((val) => {
        judgeArray.push(val.id);
      });

    await assignJudgesToSubmission(
      challenge.id,
      challenge.round[currentRoundIndex].id,
      submissionId,
      judgeArray
    );
    await setValue(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    await setAnchorEl(null);
    await fetchJudges();
  };

  const open = Boolean(anchorEl);
  const id = open ? "assign-reviewers" : undefined;

  return (
    <>
      {judges && (
        <>
          <Box
            display="flex"
            alignItems="center"
            flexGrow="1"
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            <AvatarGroup max={10000}>
              {assignedJudges && assignedJudges.length > 0 ? (
                assignedJudges.slice(0, 6).map((judge, index) => {
                  const foundJudge =
                    judges &&
                    judges.length > 0 &&
                    judges.find((elem) => elem.id === judge.challengeJudgeId);
                  if (foundJudge) {
                    return (
                      <Tooltip
                        title={
                          <>
                            <Typography variant="caption">
                              {foundJudge.firstName} {foundJudge.lastName} (
                              {foundJudge.submissions.length || 0})
                            </Typography>
                          </>
                        }
                        placement="top"
                        key={index}
                      >
                        <Avatar
                          alt={foundJudge.firstName}
                          src={foundJudge.avatarUrl}
                          className={classes.avatar}
                        >
                          <div>
                            {foundJudge.firstName.charAt(0).toUpperCase()}
                          </div>
                        </Avatar>
                      </Tooltip>
                    );
                  }
                })
              ) : (
                <Typography variant="caption">Assign reviewers</Typography>
              )}
              {assignedJudges && assignedJudges.length > 6 && (
                <Tooltip
                  title={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                    >
                      {assignedJudges
                        .slice(6, assignedJudges.length)
                        .map((judge, index) => {
                          const foundJudge =
                            judges &&
                            judges.length > 0 &&
                            judges.find(
                              (elem) => elem.id === judge.challengeJudgeId
                            );
                          if (foundJudge) {
                            return (
                              <Typography variant="caption" key={index}>
                                {foundJudge.firstName} {foundJudge.lastName} (
                                {foundJudge.submissions.length || 0})
                              </Typography>
                            );
                          }
                        })}
                    </Box>
                  }
                  placement="top"
                >
                  <Avatar
                    alt={` +${assignedJudges.length - 6}`}
                    src={null}
                    className={classes.avatar}
                  >
                    <div>+{assignedJudges.length - 6}</div>
                  </Avatar>
                </Tooltip>
              )}
            </AvatarGroup>

            <IoMdArrowDropdown className="text-xl" />
          </Box>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="top-end"
            className={classes.popper}
          >
            <div className={classes.header}>
              Assign reviewers to this submission
            </div>
            <Autocomplete
              open
              onClose={handleClose}
              multiple
              autoComplete
              autoHighlight
              loading={judgeLoading}
              classes={{
                paper: classes.paper,
                option: classes.option,
                popperDisablePortal: classes.popperDisablePortal,
              }}
              value={pendingValue}
              onChange={(event, newValue) => {
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              disablePortal
              renderTags={() => null}
              renderOption={(option, { selected }) => (
                <>
                  {judgeLoading || anchorEl === null ? (
                    <Spinner size={20} />
                  ) : (
                    <>
                      <DoneIcon
                        style={{ visibility: selected ? "visible" : "hidden" }}
                      />
                      <Box
                        ml={1}
                        display="flex"
                        flexDirection="column"
                        flexGrow="1"
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          fontWeight={600}
                        >
                          <Typography variant="caption" component="div">
                            <strong>
                              {option.firstName} {option.lastName}
                            </strong>
                          </Typography>
                          <Typography variant="caption" component="div">
                            ({option.submissions.length || 0} Assigned)
                          </Typography>
                        </Box>
                        <Typography variant="caption">
                          {option &&
                            option.groups &&
                            option.groups.length > 0 &&
                            option.groups.map((g) => g.name).join(", ")}
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              )}
              options={[...judges].sort((a, b) => {
                // Display the selected labels first.
                let ai = value.indexOf(a);
                ai = ai === -1 ? value.length + judges.indexOf(a) : ai;
                let bi = value.indexOf(b);
                bi = bi === -1 ? value.length + judges.indexOf(b) : bi;
                return ai - bi;
              })}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName ? option.lastName : ""}`
              }
              renderInput={(params) => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  className={classes.inputBase}
                  placeholder="Search by reviewer"
                  onKeyDown={(event) => {
                    if (event.key === "Backspace") {
                      event.stopPropagation();
                    }
                  }}
                />
              )}
            />
          </Popper>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  judgeLoading: state.judge.loading,
  judges: state.judge.judges,
});

const mapDispatchToProps = { assignJudgesToSubmission, fetchAllJudges };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeJudgeSubmissionDropdown);
