import React, { Fragment } from "react";
// Redux
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Components
import RenderGroup from "../../../shared/RenderGroup";
import Listbox from "../../../shared/Listbox";

const degrees = [
  { name: "BA/BS" },
  { name: "MS" },
  { name: "PhD" },
  { name: "Coding Bootcamp" },
  { name: "High School Diploma" },
  { name: "MBA" },
  { name: "Junior College" },
  { name: "JD" },
  { name: "Certificate" },
  { name: "MFA" },
  { name: "BFA" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  label: {
    textAlign: "left",
    marginBottom: theme.spacing(0.75),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

const EducationOnboarding = ({ formData, setFormData, handleChange }) => {
  const classes = useStyles();
  const filter = createFilterOptions();

  const schools = require("../../../utils/Schools");

  const { school, fieldOfStudy, degree, startDate, completionDate } = formData;

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mb={2} textAlign="center">
            <Typography variant="h5" component="h1" gutterBottom>
              <Box fontWeight={600}>🏫 Add your education</Box>
            </Typography>
            <Typography variant="body1" component="div" color="textSecondary">
              <Box>
                Let's learn about your current or previous educational history.
              </Box>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <InputLabel required>School</InputLabel>
            <Autocomplete
              id="controlled-demo"
              autoComplete
              ListboxComponent={Listbox}
              renderGroup={RenderGroup}
              options={schools}
              getOptionLabel={(option) => {
                // typeof option === "string" ? option : option.institution
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.institution;
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    institution: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              required
              freeSolo
              value={school}
              onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                  setFormData({
                    ...formData,
                    school: newValue.inputValue,
                  });
                  return;
                }
                setFormData({
                  ...formData,
                  school: (newValue && newValue.institution) || "",
                });
              }}
              renderOption={(option) => option.institution}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="school"
                  required
                  fullWidth
                  autoComplete="school"
                  variant="outlined"
                  placeholder="School"
                />
              )}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel required>Degree</InputLabel>
            <Autocomplete
              id="controlled-demo"
              autoComplete
              options={degrees}
              getOptionLabel={(option) => {
                // typeof option === "string" ? option : option.institution
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              required
              freeSolo
              value={degree}
              onChange={(event, newValue) => {
                if (newValue && newValue.inputValue) {
                  setFormData({
                    ...formData,
                    degree: newValue.inputValue,
                  });
                  return;
                }
                setFormData({
                  ...formData,
                  degree: (newValue && newValue.name) || "",
                });
              }}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="degree"
                  name={"degree"}
                  placeholder="Degree"
                  required
                  fullWidth
                  type="text"
                  autoComplete="degree"
                  variant="outlined"
                />
              )}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel required>Field of Study</InputLabel>
            <TextField
              id="fieldOfStudy"
              name={"fieldOfStudy"}
              value={fieldOfStudy}
              onChange={handleChange}
              required
              fullWidth
              type="text"
              autoComplete="major"
              variant="outlined"
              placeholder="Field of Study (Majors / Minors)"
              style={{ marginTop: 0 }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <InputLabel required className={classes.label}>
              Start Date
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                openTo="year"
                views={["year", "month"]}
                value={startDate}
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    startDate: date,
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <InputLabel required className={classes.label}>
              Completion Date
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                inputVariant="outlined"
                openTo="year"
                views={["year", "month"]}
                value={completionDate}
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    completionDate: date,
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </FormGroup>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(EducationOnboarding);
