import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { SKIP, LIMIT, NO_AUTH_LIMIT } from "../../utils/globalValues";
import { connect } from "react-redux";
import {
  getProjects,
  clearPublicProjectsInState,
} from "../../redux/actions/project";

// Material UI
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
// Components
import SimplePublicProjectCard from "../../shared/SimplePublicProjectCard";
import Button from "../../shared/Button";

import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.main,
  },
  avatar: {
    marginBottom: theme.spacing(2),
  },
  card: {
    justify: "center",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    paddingBottom: theme.spacing(2),
  },
  cardColumns: {
    orphans: 1,
    widows: 1,
    [theme.breakpoints.up("xs")]: {
      columnCount: 1,
      gridColumnGap: "2rem",
      columnGap: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      columnCount: 2,
      gridColumnGap: "2.5rem",
      columnGap: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      columnCount: 3,
      gridColumnGap: "2.5rem",
      columnGap: "2.5rem",
    },
  },
}));

// Import the data and action method below
const PublicProjects = ({
  publicProjects,
  skip,
  limit,
  hasMore,
  getProjects,
  clearPublicProjectsInState,
  user,
  isAuthenticated,
  loading,
}) => {
  useEffect(() => {
    skip = SKIP;
    limit = LIMIT;
    // On first visit to the page, clear anything that is stored in publicProjects array in state.
    clearPublicProjectsInState();
    // Load public projects into state
    //console.log(user);
    getProjects(skip, limit, user && user.typeId);
  }, []);

  const fetchProjects = () => {
    getProjects(skip, limit, user && user.typeId);
  };

  const handleLoadMore = () => {
    if (hasMore) {
      fetchProjects();
    }
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Helmet>
        <title>Projects | Pralent</title>
        <meta name="description" content="Showcase your skills and projects." />
      </Helmet>
      <Box py={[1, 2]}>
        <Container maxWidth="md">
          <Box textAlign="left" mb={[2, 3, 4]}>
            <Typography variant="h5">
              <strong>Projects</strong>
            </Typography>
            <Typography color="textSecondary">
              Get started viewing projects built by the community.
            </Typography>
          </Box>

          {loading || !publicProjects ? (
            <Grid container spacing={2}>
              {Array.from(new Array(9)).map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card className={classes.card}>
                      <Skeleton
                        className={classes.avatar}
                        variant={"rect"}
                        width={"100%"}
                        height={200}
                      />
                      <Box pl={2}>
                        <Skeleton width={"90%"} />
                        <Skeleton width={"90%"} />
                        <Skeleton width={"90%"} />
                        <Skeleton width={"60%"} />
                        <Skeleton width={"45%"} />
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          ) : publicProjects.length > 0 ? (
            <>
              <Grid container spacing={2} justify="center">
                {publicProjects.map((project, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <SimplePublicProjectCard project={project} />
                    </Grid>
                  );
                })}
              </Grid>
              <Box mt={[2, 4, 6]}>
                {isAuthenticated ? (
                  hasMore && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                    >
                      <Button size="medium" onClick={() => handleLoadMore()}>
                        Load More
                      </Button>
                    </Box>
                  )
                ) : !hasMore ? null : publicProjects.length < NO_AUTH_LIMIT ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <Button size="medium" onClick={() => handleLoadMore()}>
                      Load More
                    </Button>
                  </Box>
                ) : (
                  <Typography variant="body1">
                    Please login to view more projects.
                  </Typography>
                )}
              </Box>
            </>
          ) : (
            <Typography>There are no projects available right now.</Typography>
          )}
        </Container>
      </Box>
    </Fragment>
  );
};
// Declare below to check if the properties are available
PublicProjects.propTypes = {
  publicProjects: PropTypes.array,
  getProjects: PropTypes.func.isRequired,
  clearPublicProjectsInState: PropTypes.func.isRequired,
};

// Import data and action methods below
const mapStateToProps = (state) => ({
  publicProjects: state.project.publicProjects,
  loading: state.project.loading,
  skip: state.project.skip,
  limit: state.project.limit,
  hasMore: state.project.hasMore,
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  getProjects,
  clearPublicProjectsInState,
})(PublicProjects);
