import React, { useEffect } from "react";
import {
  Prompt,
  Link as RouterLink,
  useHistory,
  useParams,
} from "react-router-dom";
// Redux
import { connect } from "react-redux";
import {
  getSubmissionWithScores,
  updateCurrentSubmissionIndex,
  clearCurrentSubmission,
} from "../../redux/actions/judge";
import {
  getCustomSubmissionFormById,
  clearSelectedSubmission,
  clearMySubmission,
} from "../../redux/actions/challenge";
// Components
import ComplexProjectCard from "../../shared/ComplexProjectCard";
import ChallengeCustomSubmissionView from "../../shared/ChallengeCustomSubmissionView";
import SubmissionTeam from "../../shared/SubmissionTeam";
import ProjectFiles from "../../shared/ProjectFiles";
import ProjectLinks from "../../shared/ProjectLinks";
import ScoreCard from "./components/ScoreCard";
import Navigation from "./components/Navigation";
import Alert from "../../shared/Alert";
import Button from "../../shared/Button";
import AuthenticateJudge from "../../shared/AuthenticateJudge";
import OrgAppBar from "../../shared/OrgAppBar/OrgAppBar";
import ChallengeThemeProvider from "../../shared/ChallengeThemeProvider";
import LinkToHelp from "../../shared/LinkToHelp";
// Utils
import {
  SUBMISSION_TYPE_ID,
  CUSTOM_FORM_TYPE_ID,
} from "../../utils/globalValues";
// Material UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// Other
import { Helmet } from "react-helmet";

const Submission = ({
  getSubmissionWithScores,
  judgeDetails,
  challengeDetails,
  submissions,
  currentSubmission,
  myCustomFormSubmission,
  updateCurrentSubmissionIndex,
  getCustomSubmissionFormById,
  clearCurrentSubmission,
  clearSelectedSubmission,
  clearMySubmission,
  loading,
  dirtyState,
}) => {
  const history = useHistory();

  const { submissionId, token, slug } = useParams();

  useEffect(() => {
    const currentSubmissionIndex =
      submissions &&
      submissions.length > 0 &&
      submissions.findIndex((elem) => elem.id === submissionId);
    if (currentSubmissionIndex) {
      updateCurrentSubmissionIndex(currentSubmissionIndex);
    } else {
      updateCurrentSubmissionIndex(0);
    }
  }, [submissionId]);

  const getSubmissionWithScoresForJudge = async () => {
    await getSubmissionWithScores(submissionId, token);
  };

  useEffect(() => {
    if (slug && token) {
      // Get a list of submission (a very light list)
      // Get the current submission from state
      // If null, set it to the first submission id from the list
      // set the current index
      if (submissions && submissions.length <= 0) {
        /// redirect to the starting page
        return history.push(`/programs/${slug}/reviewer/${token}`);
      } else {
        getSubmissionWithScoresForJudge();
      }
    }
    return () => {
      clearCurrentSubmission();
      clearMySubmission();
      clearSelectedSubmission();
    };
  }, [submissionId, slug, token]);

  useEffect(() => {
    if (currentSubmission) {
      if (currentSubmission.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM) {
        getCustomSubmissionFormById(
          currentSubmission.challengeId,
          CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM,
          currentSubmission.id
        );
      }
    }
  }, [currentSubmission]);

  const currentRound =
    judgeDetails &&
    challengeDetails &&
    challengeDetails.round &&
    challengeDetails.round.length > 0 &&
    challengeDetails.round.find(
      (elem) => elem.id === judgeDetails.challengeRoundId
    );

  const JudgeActions = () => (
    <>
      <Button
        variant="text"
        color="default"
        component={RouterLink}
        to={`/programs/${slug}/reviewer/${token}`}
      >
        Back to Submissions
      </Button>
      <LinkToHelp
        label="Reviewing 101"
        link="https://www.notion.so/Review-c611ae7c790c4c70aa76e92f50e41128"
      />
    </>
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {(currentSubmission &&
            currentSubmission &&
            currentSubmission.team &&
            currentSubmission.team.name) ||
            "Pralent Review"}
        </title>
      </Helmet>
      <ChallengeThemeProvider challenge={challengeDetails}>
        <AuthenticateJudge />
        <Box p={[1, 2]}>
          <Container>
            <Prompt
              message="Are you sure you want to continue without saving?"
              when={dirtyState}
            />
            <Box display={{ xs: "block", sm: "none" }}>
              <Alert
                color="red"
                title="Viewing submissions"
                text="Reviewing submissions is best completed on laptop, tablet or desktop devices."
              />
            </Box>
            {challengeDetails && (
              <>
                <OrgAppBar
                  title={`${challengeDetails.title} ${
                    currentRound ? `- ${currentRound.name}` : ""
                  }`}
                  subtitle={challengeDetails.tagLine}
                  actions={<JudgeActions />}
                />
              </>
            )}

            <Box display="block">
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6} md={7} lg={8}>
                  <Navigation />
                  {currentSubmission &&
                    currentSubmission.submissionTypeId ===
                      SUBMISSION_TYPE_ID.PROJECT && (
                      <Box>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          currentSubmission &&
                          currentSubmission.project && (
                            <>
                              <SubmissionTeam
                                challenge={challengeDetails}
                                submission={currentSubmission}
                              />
                              <ComplexProjectCard
                                project={currentSubmission.project}
                                judging={true}
                              />
                              <ProjectFiles
                                project={currentSubmission.project}
                              />
                              <ProjectLinks
                                project={currentSubmission.project}
                              />
                            </>
                          )
                        )}
                      </Box>
                    )}
                  {currentSubmission &&
                    currentSubmission.submissionTypeId ===
                      SUBMISSION_TYPE_ID.CUSTOM && (
                      <Box>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          currentSubmission &&
                          currentSubmission.customSubmissionDetails && (
                            <div>
                              <SubmissionTeam
                                challenge={challengeDetails}
                                submission={currentSubmission}
                              />
                              <ChallengeCustomSubmissionView
                                myCustomFormSubmission={myCustomFormSubmission}
                              />
                            </div>
                          )
                        )}
                      </Box>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                  {currentSubmission &&
                    ((currentSubmission.project &&
                      currentSubmission.submissionTypeId ===
                        SUBMISSION_TYPE_ID.PROJECT) ||
                      (currentSubmission.customSubmissionDetails &&
                        currentSubmission.submissionTypeId ===
                          SUBMISSION_TYPE_ID.CUSTOM)) && <ScoreCard />}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </ChallengeThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  judgeDetails: state.judge.judgeDetails,
  challengeDetails: state.judge.challengeDetails,
  submissions: state.judge.submissions,
  currentSubmission: state.judge.currentSubmission,
  loading: state.judge.loading,
  dirtyState: state.judge.dirtyState,
  myCustomFormSubmission: state.challenge.myCustomFormSubmission,
});

const mapDispatchToProps = {
  getSubmissionWithScores,
  getCustomSubmissionFormById,
  updateCurrentSubmissionIndex,
  clearCurrentSubmission,
  clearSelectedSubmission,
  clearMySubmission,
};

export default connect(mapStateToProps, mapDispatchToProps)(Submission);
