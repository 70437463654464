import React, { useState } from "react";
// React-router
import { Link } from "react-router-dom";
// Material UI
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Button from "../../../shared/Button";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: "pointer",
  },
  speakerDialogAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
  },
}));

const EventSpeaker = ({ speaker }) => {
  const classes = useStyles();
  const id = speaker.id;
  const src =
    speaker.profile || speaker.user ? speaker.user.avatar : speaker.avatar;

  const name = speaker.profile
    ? `${speaker.profile.firstName} ${speaker.profile.lastName}`
    : speaker.user
    ? speaker.user.userName
    : speaker.firstName
    ? `${speaker.firstName} ${speaker.lastName || ""}`
    : "Speaker";

  const aboutMe = speaker.profile ? speaker.profile.aboutMe : speaker.bio;

  const [openSpeakerDialog, setOpenSpeakerDialog] = useState(false);

  const handleOpenSpeakerDialog = () => {
    setOpenSpeakerDialog(true);
  };

  const handleCloseSpeakerDialog = () => {
    setOpenSpeakerDialog(false);
  };

  return (
    <>
      <Dialog
        onClose={handleCloseSpeakerDialog}
        aria-labelledby={`${name}-speaker-dialog-${id}`}
        open={openSpeakerDialog}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          p={{ xs: 2, md: 4, lg: 6 }}
        >
          <Avatar
            className={classes.speakerDialogAvatar}
            src={src}
            alt={name ? name : "Speaker"}
          />
          <Box>
            <Typography gutterBottom variant="h6" component="div">
              <Box fontWeight={600}>{name}</Box>
            </Typography>
            {aboutMe && (
              <Typography variant="body1" color="textSecondary">
                {aboutMe}
              </Typography>
            )}
          </Box>
        </Box>
        {speaker.profile && (
          <Button
            size="medium"
            component={Link}
            to={`/@${speaker.user && speaker.user.userName}`}
          >
            View Profile
          </Button>
        )}
      </Dialog>
      <Tooltip title={name ? name : "Speaker"}>
        <Avatar
          className={classes.avatar}
          src={src}
          alt={name ? name : "Speaker"}
          onClick={handleOpenSpeakerDialog}
        />
      </Tooltip>
    </>
  );
};

export default EventSpeaker;
