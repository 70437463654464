// React
import React, { useState } from "react";
// React router
import { useParams, Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// React query
import { useGetAllMyRoomsForCommunity } from "../../hooks/room";
// Material UI
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Alert from "@material-ui/lab/Alert";
// Icons
import {
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";
// Components
import Spinner from "../../shared/Spinner";
// Utils
import { Emoji } from "emoji-mart";
import { ROOM_TYPE } from "../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  ListItem: {
    marginBottom: theme.spacing(1.5),
    boxShadow: theme.shadows[0],
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    transition: "ease-in-out 0.2s",
    "&:hover": {
      transition: "ease-in-out 0.2s",
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CommunityRooms = ({ companyDetailsQuery, user }) => {
  const classes = useStyles();
  const { communitySlug } = useParams();

  const BASE_COMMUNITY_URL = `/c/${communitySlug}`;

  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const {
    isLoading: isLoadingRooms,
    isFetching: isFetchingRooms,
    data: { data: communityRooms } = { data: null },
    isError: isErrorRooms,
    error: errorRooms,
  } = useGetAllMyRoomsForCommunity(companyDetails?.id, user && user.id);

  return (
    <Container maxWidth="xl">
      {isLoadingRooms ? (
        <Spinner />
      ) : isErrorRooms ? (
        <Alert severity="error">
          {errorRooms && errorRooms.error.data && errorRooms.error.data.msg}
        </Alert>
      ) : (
        <Box py={2} px={{ xs: 0, md: 2 }}>
          <Typography variant="h5" component="h2">
            <Box mb={2} fontWeight={600}>
              💬 All Rooms
            </Box>
          </Typography>
          <List>
            {communityRooms &&
              communityRooms.map((room, index) => {
                if (!room.isActive) {
                  return null;
                }

                if (!user && !room.isPublic) {
                  return null;
                }

                if (user) {
                  if (
                    !room.isPublic &&
                    (!room.users || room.users.length <= 0)
                  ) {
                    return null;
                  }
                }

                return (
                  <ListItem
                    button
                    component={Link}
                    to={`${BASE_COMMUNITY_URL}/rooms/${room.name}`}
                    key={index}
                    className={classes.ListItem}
                  >
                    <Box py={1}>
                      <Typography variant="h6" component="div">
                        <Box
                          fontWeight={600}
                          display="flex"
                          alignItems="center"
                          style={{ gap: "12px" }}
                        >
                          {room.type === ROOM_TYPE.CHAT ? (
                            <HiOutlineChat />
                          ) : room.type === ROOM_TYPE.FORUM ? (
                            <HiOutlinePencilAlt />
                          ) : room.type == ROOM_TYPE.BOARD ? (
                            <HiOutlineViewGrid />
                          ) : (
                            <HiHashtag />
                          )}
                          {room.name} <Emoji emoji={room.emoji} size={20} />
                        </Box>
                      </Typography>
                      {room.description && (
                        <Typography
                          variant="body2"
                          component="div"
                          color="textSecondary"
                        >
                          <Box lineHeight={1.2}>{room.description}</Box>
                        </Typography>
                      )}
                    </Box>
                    <Divider light />
                  </ListItem>
                );
              })}
          </List>
        </Box>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityRooms);
