import React from "react";
import MuiButton from "@material-ui/core/Button";

const Button = (props, ref) => {
  const { children, ...other } = props;

  return (
    <MuiButton style={{ textDecoration: "none" }} ref={ref} {...other}>
      {children}
    </MuiButton>
  );
};

export default React.forwardRef(Button);
