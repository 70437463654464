import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getMyChallenge } from "../../redux/actions/challenge";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
// components
import Button from "../Button";
// Icons
import { IoMdTrendingUp } from "react-icons/io";
import OpenInBrowserOutlinedIcon from "@material-ui/icons/OpenInBrowserOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

export const OrgChallengeAppBar = ({
  challenge,
  companyProfile,
  getMyChallenge,
}) => {
  const { slug } = useParams();
  const fetchChallenge = async () => {
    await getMyChallenge(slug);
  };
  useEffect(() => {
    if (slug) {
      fetchChallenge();
    }
    // return () => clearChallenge();
  }, [slug]);

  const params = useParams();
  const location = useLocation();
  const orgId = params && params.orgId ? params.orgId : "";
  const CHALLENGE_TABS = [
    {
      name: "Registrations",
      route: `/org/${orgId}/programs/${params.slug}/registrations`,
    },
    {
      name: "Details",
      route: `/org/${orgId}/programs/${params.slug}/details`,
    },
    {
      name: "Round",
      route: `/org/${orgId}/programs/${params.slug}/round`,
    },
    {
      name: "Insights",
      route: `/org/${orgId}/programs/${params.slug}/insights`,
      icon: <IoMdTrendingUp />,
    },
    {
      name: "Settings",
      route: `/org/${orgId}/programs/${params.slug}/settings`,
    },
  ];
  return (
    <Box mb={4}>
      {challenge && (
        <Box display="flex" mb={2}>
          <Box flexGrow={1}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" component="h1">
                <Box fontWeight={600}>{challenge.title}</Box>{" "}
              </Typography>
              {!challenge.isActive && (
                <Box ml={1}>
                  <Tooltip title="Activate this program in the settings tab">
                    <Chip
                      icon={<ErrorOutlineOutlinedIcon />}
                      label="Inactive"
                    />
                  </Tooltip>
                </Box>
              )}
            </Box>
            <Typography color="textSecondary" variant="body2" component="div">
              <Box>{challenge.tagLine}</Box>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="div">
              <Box fontWeight={600}>View program</Box>
            </Typography>
            <Button
              component={Link}
              to={`/c/${companyProfile.communitySlug}/programs/${challenge.slug}`}
              variant="outlined"
              style={{
                textTransform: "none",
                lineHeight: 1.1,
              }}
              endIcon={<OpenInBrowserOutlinedIcon />}
            >{`/c/${companyProfile.communitySlug}/programs/${challenge.slug}`}</Button>
          </Box>
        </Box>
      )}
      {params && params.slug && challenge && (
        <Tabs variant="scrollable" value={location.pathname}>
          {CHALLENGE_TABS &&
            CHALLENGE_TABS.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  component={Link}
                  to={challenge ? tab.route : "/org/${orgId}/programs"}
                  value={
                    location.pathname.startsWith(tab.route)
                      ? location.pathname
                      : tab.route
                  }
                  label={<Box>{tab.name}</Box>}
                />
              );
            })}
        </Tabs>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { getMyChallenge };

export default connect(mapStateToProps, mapDispatchToProps)(OrgChallengeAppBar);
