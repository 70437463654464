import React, { useState } from "react";
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

// colors
import colorList from "./colorList";

const BulletChart = ({ data }) => {
  const theme = useTheme();
  return (
    <Box>
      {data.map((categoryCountObject, index) => {
        return (
          // Maybe I could reuse skills component here
          <Box
            key={index}
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
            style={{ paddingBottom: "3%" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexGrow={1}
            >
              <Box display="flex" alignItems="flex-start">
                <FiberManualRecordIcon
                  style={{
                    fontSize: 15,
                    // Remember to find a way to make it display different colors for each bullet
                    color: colorList[index],
                  }}
                />
                <Typography variant="body2" component="div">
                  <Box px={1} lineHeight={1}>
                    {categoryCountObject.name}
                  </Box>
                </Typography>
              </Box>
            </Box>

            <Typography variant="body2" component="div">
              <Box>{`${(
                (categoryCountObject.count / categoryCountObject.total) *
                100
              ).toFixed(1)}% (${categoryCountObject.count}) `}</Box>
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
export default BulletChart;
