import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// React router
import { useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { getProfileByUserName } from "../../redux/actions/profile";
import { getAllPublicProjectsByUserId } from "../../redux/actions/project";
// Profile Components
import ProfileCard from "./components/ProfileCard";
import ProfileExp from "./components/ProfileExp";
import ProfileInterests from "./components/ProfileInterests";
import ProfileProjects from "./components/ProfileProjects";
import ProfileSkills from "./components/ProfileSkills";
import ProfileNav from "./components/ProfileNav";
//Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
// Utils
import { USER_TYPE } from "../../utils/globalValues";

import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  noProfile: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  textDecoration: {
    textDecoration: "none",
    "&:hover": {
      transition: "0.2s ease-in-out",
      backgroundColor: "transparent",
      outline: "none",
      textDecoration: "none",
      color: theme.palette.primary.light,
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  aboutMe: {
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.25",
    },
    fontSize: "1rem",
  },
}));

const Profile = ({
  getProfileByUserName,
  getAllPublicProjectsByUserId,
  user,
  profile: { profile, loading },
}) => {
  const classes = useStyles();

  const { userName } = useParams();

  useEffect(() => {
    getProfileByUserName(userName);
  }, [userName]);

  useEffect(() => {
    if (profile && profile.user && !profile.projects) {
      getAllPublicProjectsByUserId(
        profile.user.id,
        user && user.typeId ? user.typeId : USER_TYPE.STUDENT
      );
    }
  }, [profile, user, getAllPublicProjectsByUserId]);

  const [projectsOrExperiences, setProjectsOrExperiences] = useState(true);

  useEffect(() => {
    if (!profile) return;
    if (
      (profile.experience && profile.experience.length > 0) ||
      (profile.projects && profile.projects.length > 0)
    ) {
      setProjectsOrExperiences(true);
    } else {
      setProjectsOrExperiences(false);
    }
  }, [profile]);

  return (
    <Box flexGrow={1} height="100%" maxHeight="100vh" overflow="auto">
      {profile && (
        <Helmet>
          <title>
            {profile.firstName} {profile.lastName} | Pralent Profile
          </title>
          <meta name="description" content={profile.aboutMe} />
        </Helmet>
      )}
      {loading ? (
        <CircularProgress />
      ) : profile === null ? (
        <Box textAlign="center" fontSize="body1.fontSize">
          Could not find this profile.
        </Box>
      ) : (
        <Box px={0} pb={4}>
          <ProfileCard />
          <Container maxWidth="md">
            <ProfileProjects />
            <ProfileSkills />
            <ProfileInterests />
            <ProfileExp />
          </Container>
        </Box>
      )}
    </Box>
  );
};

Profile.propTypes = {
  getProfileByUserName: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getProfileByUserName,
  getAllPublicProjectsByUserId,
})(Profile);
