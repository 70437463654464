import React, { Fragment } from "react";
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
// Components
import InterestsButton from "./InterestsButton";
// Images
import no_interests from "../../../assets/images/user_interface_.svg";

const Interests = ({ myprofile, typedef }) => {
  const questionsAnswersTypedef = typedef.questionsAnswers;
  return (
    <Fragment>
      <InterestsButton />
      {myprofile &&
      myprofile.questionsAnswers &&
      myprofile.questionsAnswers.length > 0 ? (
        <div>
          {myprofile.questionsAnswers.map((val) => {
            const { questionId, answers } = val;
            const questionFind = questionsAnswersTypedef.find(
              (elem) => elem.id === questionId
            );
            if (!questionFind) return;
            const possibleAnswers = questionFind.questionAnswer;

            return (
              <Box mt={2} key={questionId}>
                <Typography variant="body2" component="div" gutterBottom>
                  <Box fontWeight={600}>
                    {questionsAnswersTypedef.find(
                      (elem) => elem.id === questionId
                    ).description || ""}
                  </Box>
                </Typography>

                <Box display="flex" flexWrap="wrap">
                  {answers && answers.length > 0 ? (
                    answers.map((id, index) => {
                      const label = possibleAnswers.find(
                        (ans) => ans.id === id
                      );
                      if (!label) return;
                      return (
                        <Chip
                          key={index}
                          color="default"
                          label={label.answer}
                        />
                      );
                    })
                  ) : (
                    <Typography variant="caption" color="textSecondary">
                      You have not answered this question.
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </div>
      ) : (
        <Box
          display="block"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            height="100%"
            width="350px"
            src={no_interests}
            alt="no interests added image"
          />
          <Typography variant="body1" component="div" color="textSecondary">
            There are no interests added yet. Answer a few interest questions to
            help the community learn more about you!
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
  typedef: state.typedef,
});

export default connect(mapStateToProps)(Interests);
