import React from "react";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import colorList from "./colorList";

const InterestChart = ({ data }) => {
  return (
    <Box>
      <Grid container spacing={3}>
        {data.map((interestObject, index) => {
          return (
            <Grid item xs={12} md={6} key={index}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Typography variant="body2" component="div" gutterBottom>
                  <Box fontWeight={600}>{interestObject.question}</Box>
                </Typography>

                <Box display="flex" flexDirection="column" width="100%">
                  {interestObject.answer
                    .slice(0, 5)
                    .map((answerObject, index) => {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          alignItems="center"
                          width="100%"
                          mb={0.25}
                        >
                          <FiberManualRecordIcon
                            style={{
                              fontSize: 10,
                              // Remember to find a way to make it display different colors for each bullet
                              color: colorList[index],
                            }}
                          />
                          <Box ml={1}>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                            >
                              {answerObject.name}
                            </Typography>
                          </Box>
                          <Typography
                            variant="body2"
                            component="div"
                            color="textSecondary"
                          >
                            <Box ml={0.75}>
                              (
                              {(
                                (answerObject.count / answerObject.total) *
                                100
                              ).toFixed(0)}
                              %, {answerObject.count})
                            </Box>
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default InterestChart;
