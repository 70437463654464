import React from "react";
// Components
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
// Icons
import EventIcon from "@material-ui/icons/Event";
// Utils
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";
import { serializeToString } from "../../../utils/serializeToString";
import { EVENT_ONLINE } from "../../../utils/globalValues";
import {
  ICalendar,
  GoogleCalendar,
  YahooCalendar,
  OutlookCalendar,
} from "datebook";

const EventDateTime = ({
  event,
  startDateTime,
  endDateTime,
  timezone = "America/New_York",
  size = "md",
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadEvent = (calendarType) => {
    if (event) {
      const communitySlug =
        event && event.company && event.company.communitySlug;
      const eventUrl = `${document.location.origin}/c/${communitySlug}/events/${event.slug}`;
      const options = {
        title: event.title,
        location:
          event.online === EVENT_ONLINE.ONLINE ? event.link : event.location,
        description: `<a href="${eventUrl}">${eventUrl}</a>\n\n${
          event.description ? serializeToString(event.description) : ""
        }`,
        start: new Date(startDateTime),
        end: new Date(endDateTime),
      };
      switch (calendarType) {
        case "apple": {
          const icalendar = new ICalendar(options);
          icalendar.download();
          break;
        }
        case "google": {
          const googleCalendar = new GoogleCalendar(options);
          const googleCalendarUrl = googleCalendar.render();
          window.open(googleCalendarUrl);
          break;
        }
        case "yahoo": {
          const yahooCalendar = new YahooCalendar(options);
          const yahooCalendarUrl = yahooCalendar.render();
          window.open(yahooCalendarUrl);
          break;
        }
        case "outlook": {
          const outlookCalendar = new OutlookCalendar(options);
          const outlookCalendarUrl = outlookCalendar.render();
          window.open(outlookCalendarUrl);
          break;
        }
        default:
          break;
      }
    }
  };
  return (
    <>
      <Tooltip title="Add to calendar">
        <Link
          component="button"
          variant={size === "sm" ? "body2" : "body1"}
          onClick={handleClick}
        >
          <Typography component="div" color="textSecondary">
            <Box
              lineHeight={1.2}
              display="flex"
              alignItems="center"
              textAlign="left"
            >
              <Box mr={1}>
                <EventIcon />
              </Box>{" "}
              {startDateTime &&
                convertUTCTimeToZonedTime(startDateTime, timezone)}{" "}
              to{" "}
              {endDateTime && convertUTCTimeToZonedTime(endDateTime, timezone)}
            </Box>
          </Typography>
        </Link>
      </Tooltip>
      <Menu
        id="event-add-to-calendar-button"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => downloadEvent("apple")}>
          Apple Calendar
        </MenuItem>
        <MenuItem onClick={() => downloadEvent("google")}>Google</MenuItem>
        <MenuItem onClick={() => downloadEvent("yahoo")}>Yahoo</MenuItem>
        <MenuItem onClick={() => downloadEvent("outlook")}>Outlook</MenuItem>
      </Menu>
    </>
  );
};

export default EventDateTime;
