import { handleServerError } from "./error";
import { setAlert } from "./alert";
import {
  CREATE_COMPANY_PROFILE,
  GET_COMPANY_PROFILE,
  ADD_JOB,
  GET_ALL_COMPANY_JOBS,
  CLEAR_COMPANY,
  ADD_USER_COMPANY_RELATIONSHIP,
  DELETE_USER_COMPANY_RELATIONSHIP,
  ADD_COMPANY_LOGO,
  UPDATE_ORG_ID,
} from "./actionTypes";
import axios from "../../utils/axios";

export const updateOrgId = (orgId) => async (dispatch) => {
  try {
    await dispatch({
      type: UPDATE_ORG_ID,
      payload: { orgId },
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const uploadCompanyLogo = (companyId, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axios.post(
      `/api/company/upload/companyLogo/${companyId}`,
      formData,
      config
    );

    await dispatch({
      type: ADD_COMPANY_LOGO,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearCompany = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_COMPANY,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const createCompanyProfile = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/company", formData, config);
    await dispatch({
      type: CREATE_COMPANY_PROFILE,
      payload: res.data,
    });

    await dispatch(
      setAlert(
        formData.id
          ? "Successfully edited your organization profile"
          : "Successfully added your organization profile",
        "success"
      )
    );
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getCompanyProfile = (organizationId) => async (dispatch) => {
  try {
    // make request to api
    const res = await axios.get(`/api/company/${organizationId}`);
    if (res && res.data && res.status === 200) {
      dispatch({
        type: GET_COMPANY_PROFILE,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getAllCompanyJobs = (companyId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/company/jobs/all/${companyId}`);
    // make request to api to get all company's jobs
    await dispatch({
      type: GET_ALL_COMPANY_JOBS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const addUserCompanyRelationship =
  (companyId, userIdToAdd) => async (dispatch) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const body = {
        companyId,
        userIdToAdd,
      };
      const res = await axios.post(
        "/api/company/userCompanyRelationship",
        body,
        config
      );
      await dispatch({
        type: ADD_USER_COMPANY_RELATIONSHIP,
        payload: res.data,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

export const deleteUserCompanyRelationship =
  (companyId, deleteUserId) => async (dispatch) => {
    try {
      if (window.confirm("Are you sure you want to delete this user?")) {
        await axios.del(
          `/api/company/userCompanyRelationship/${companyId}/${deleteUserId}`
        );
        await dispatch({
          type: DELETE_USER_COMPANY_RELATIONSHIP,
          payload: deleteUserId,
        });
      }
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };
