import {
  CREATE_COMPANY_PROFILE,
  GET_COMPANY_PROFILE,
  GET_ALL_COMPANY_JOBS,
  CLEAR_COMPANY,
  ADD_USER_COMPANY_RELATIONSHIP,
  DELETE_USER_COMPANY_RELATIONSHIP,
  ADD_COMPANY_LOGO,
  UPDATE_ORG_ID,
} from "../actions/actionTypes";

const initialState = {
  orgId: null,
  recruiterProfile: null,
  companyProfile: null,
  jobs: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_ORG_ID: {
      return {
        ...state,
        orgId: payload.orgId,
      };
    }
    case ADD_COMPANY_LOGO: {
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          companyLogoPath: payload,
        },
      };
    }
    case CLEAR_COMPANY:
      return {
        ...state,
        orgId: null,
        recruiterProfile: null,
        companyProfile: null,
        jobs: [],
      };
    case GET_COMPANY_PROFILE:
    case CREATE_COMPANY_PROFILE: {
      let companyProfile = payload && payload.company;
      companyProfile.team = payload && payload.team;
      //console.log("companyProfile:", companyProfile);
      return {
        ...state,
        companyProfile: companyProfile,
      };
    }
    case GET_ALL_COMPANY_JOBS: {
      return {
        ...state,
        jobs: payload,
      };
    }
    case ADD_USER_COMPANY_RELATIONSHIP: {
      let team = [payload, ...state.companyProfile.team];

      let newCompanyProfile = {
        ...state.companyProfile,
        team: team,
      };
      return {
        ...state,
        companyProfile: newCompanyProfile,
      };
    }
    case DELETE_USER_COMPANY_RELATIONSHIP: {
      let team = [...state.companyProfile.team];
      team = team.filter((t) => t.userAccountId !== payload);

      let newCompanyProfile = {
        ...state.companyProfile,
        team: team,
      };
      return {
        ...state,
        companyProfile: newCompanyProfile,
      };
    }
    default:
      return state;
  }
}
