import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
// Components
import Spinner from "../../shared/Spinner";

const PrivateRoute = ({
  component: Component,
  location: { pathname },
  auth: { isAuthenticated, loading },
  ...rest
}) => {
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            !isAuthenticated ? (
              <Redirect to={`/login?redirect=${pathname}`} />
            ) : (
              <>
                <Component {...props} />
              </>
            )
          }
        />
      )}
    </div>
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
