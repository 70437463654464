import { handleServerError } from "./error";
import { setAlert } from "./alert";
import axios from "../../utils/axios";

import {
  ADD_NEW_FIELD,
  DELETE_FIELD,
  CHANGE_FIELD,
  UPDATE_FIELD,
  INSERT_FIELD,
  RESET_FIELD,
  UPDATE_FORM_TITLE,
  UPDATE_FORM_DESCRIPTION,
  CLEAR_CUSTOM_FORM,
  SAVE_CUSTOM_FORM,
  FETCH_CUSTOM_FORM,
} from "./actionTypes";

// Add new field
export const addNewField = (formField) => async (dispatch) => {
  try {
    //console.log("Inside action->addNewField");
    //console.log(formField);
    await dispatch({
      type: ADD_NEW_FIELD,
      payload: formField,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Update field
export const updateField = (formField, index) => async (dispatch) => {
  try {
    //console.log("Inside action->changeField");
    //console.log(formField, index);
    await dispatch({
      type: UPDATE_FIELD,
      payload: {
        formField,
        index,
      },
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const changeField = (formField, index) => async (dispatch) => {
  try {
    //console.log(formField, index);
    await dispatch({
      type: CHANGE_FIELD,
      payload: {
        formField,
        index,
      },
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const deleteField = (formField, index) => async (dispatch) => {
  try {
    //console.log(formField, index);
    await dispatch({
      type: DELETE_FIELD,
      payload: {
        formField,
        index,
      },
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const fetchCustomForm = (challengeRoundId, formTypeId) => async (
  dispatch
) => {
  try {
    const res = await axios.get(
      `/api/formBuilder/${challengeRoundId}/${formTypeId}`
    );
    await dispatch({
      type: FETCH_CUSTOM_FORM,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: CLEAR_CUSTOM_FORM,
    });
    dispatch(handleServerError(error));
  }
};

// Update field
export const saveCustomForm = (
  challengeId,
  challengeRoundId,
  jsonSchema,
  uiSchema,
  formTypeId
) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = {
      challengeId,
      challengeRoundId,
      jsonSchema,
      uiSchema,
      formTypeId,
    };

    const res = await axios.post(`/api/formBuilder/save`, body, config);

    await dispatch({
      type: SAVE_CUSTOM_FORM,
      payload: res.data,
    });

    await dispatch(
      setAlert(
        "Successfully saved the custom form for this program round.",
        "success"
      )
    );
  } catch (error) {
    console.log(error);
    await dispatch({
      type: CLEAR_CUSTOM_FORM,
    });
    dispatch(handleServerError(error));
  }
};

export const clearCustomForm = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_CUSTOM_FORM });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
