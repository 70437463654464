import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import RichTextEditor from "../../../../shared/RichTextEditor";

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 600,
    textTransform: "uppercase",
    marginBottom: theme.spacing(3),
    letterSpacing: "0.05em",
    background: `linear-gradient(180deg, rgba(255,255,255,0) 65%, ${theme.palette.primary.main} 65%)`,
    display: "inline-block",
  },
}));

const ChallengeDetails = ({ challenge }) => {
  const classes = useStyles();
  return (
    <Box>
      {(challenge.properties || []).map((val, index) => {
        const parsedValue = JSON.parse(val.value);
        if (parsedValue) {
          return (
            <Box
              p={2}
              bgcolor="background.paper"
              borderRadius="borderRadius"
              key={index}
              mb={{ xs: 2 }}
            >
              <Typography variant="h4" component="h3" className={classes.name}>
                {val.name}
              </Typography>
              <Box>
                <RichTextEditor value={parsedValue} readOnly={true} />
              </Box>
              {/* {index < challenge.properties.length - 1 && (
                <Box mt={{ xs: 2, md: 4, lg: 6 }}>
                  <Divider />
                </Box>
              )} */}
            </Box>
          );
        }
      })}
    </Box>
  );
};

export default ChallengeDetails;
