import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IoMdCheckmark, IoMdRemove } from "react-icons/io";

const PLANS = [
  {
    name: "Basic",
    price: "FREE",
    per: null,
    discount: null,
    tagline:
      "For creating small communities and organizing programs with basic features.",
    action: {
      text: "Request Invite",
      href: "mailto:sales@pralent.com",
    },
  },
  {
    name: "Pro",
    price: "Contact Us",
    per: null,
    // discount: {
    //   price: "FREE",
    //   per: null,
    // },
    tagline:
      "For creating medium-sized communities and organizing larger programs.",
    action: {
      text: "Get A Demo",
      to: "/request-demo",
    },
  },
  {
    name: "Premium",
    price: "Contact Us",
    per: null,
    discount: null,
    tagline:
      "For large organizations that want full control, customization, and capabilities.",
    action: {
      text: "Get A Demo",
      to: "/request-demo",
    },
  },
];

const FEATURE_SECTIONS = [
  {
    sectionName: "Programs & Challenges",
    features: [
      {
        name: "Dedicated & customizable program page",
        plans: [true, true, true],
      },
      {
        name: "Multiple rounds / stages",
        plans: [true, true, true],
      },
      {
        name: "Private programs",
        plans: ["Public Only", true, true],
      },
      {
        name: "User & team registrations",
        plans: [true, true, true],
      },
      {
        name: "Program-wide email notifications",
        plans: [true, true, true],
      },
      {
        name: "Contact users & teams",
        plans: [true, true, true],
      },
      {
        name: "Submissions",
        plans: ["Up to 100", "Up to 300", "Custom"],
      },
      {
        name: "Custom submission forms with file uploads",
        plans: [true, true, true],
      },
      {
        name: "Collect reviewers scores & feedback",
        plans: [true, true, true],
      },
      {
        name: "Create custom review criteria",
        plans: [true, true, true],
      },
      {
        name: "Instantly release scores and/or feedback",
        plans: [true, true, true],
      },
      {
        name: "Manage reviewers progress & full score breakdowns",
        plans: [false, true, true],
      },
      {
        name: "Add groups / categories to submissions & reviewers",
        plans: [false, "Up to 3 Groups", "Unlimited Groups"],
      },
      {
        name: "Intelligent auto-assign of reviewers & submissions",
        plans: [false, false, true],
      },

      {
        name: "Password protection for each reviewer's scoring",
        plans: [false, false, true],
      },
    ],
  },
  {
    sectionName: "Customization & Branding",
    features: [
      {
        name: "Logo branding",
        plans: [true, true, true],
      },
      {
        name: "Social sharing",
        plans: [true, true, true],
      },
      {
        name: "Custom emails (Coming Soon)",
        plans: [false, false, true],
      },
      {
        name: "Advanced brand theme",
        plans: [false, false, true],
      },
      {
        name: "Custom white-label solutions - custom domain",
        plans: [false, false, true],
      },
    ],
  },
  {
    sectionName: "Insights & Reporting",
    features: [
      {
        name: "Basic insights",
        plans: [true, true, true],
      },
      {
        name: "Downloadable data reports",
        plans: [false, false, true],
      },
      {
        name: "Advanced insights and custom reports",
        plans: [false, false, true],
      },
    ],
  },
  //   {
  //     sectionName: "Community",
  //     features: [
  //       {
  //         name: "Organization Page (Coming Soon)",
  //         plans: [true, true, true],
  //       },
  //       {
  //         name: "Advanced Member Profiles",
  //         plans: [true, true, true],
  //       },
  //       {
  //         name: "Events (Coming Soon)",
  //         plans: [true, true, true],
  //       },
  //       {
  //         name: "Messaging (Coming Soon)",
  //         plans: [true, true, true],
  //       },
  //       {
  //         name: "Job Board (Coming Soon)",
  //         plans: [true, true, true],
  //       },
  //     ],
  //   },
  {
    sectionName: "Privacy & Security",
    features: [
      {
        name: "Control over public and private information",
        plans: [true, true, true],
      },
      //   {
      //     name: "SOC 2 Type 2 compliant",
      //     plans: [true, true, true],
      //   },
      //   {
      //     name: "GDPR compliance",
      //     plans: [true, true, true],
      //   },
    ],
  },
  {
    sectionName: "Services",
    features: [
      {
        name: "Team seats",
        plans: ["1", "Up to 3", "Unlimited"],
      },
      {
        name: "Email and chat support",
        plans: [true, true, true],
      },
      {
        name: "Pre-launch check with the Pralent Team",
        plans: [true, true, true],
      },
      {
        name: "Designated account manager",
        plans: [false, false, true],
      },
      {
        name: "Onboarding and training",
        plans: [false, false, true],
      },
    ],
  },
];

const Breakdown = () => {
  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto md:pb-16 px-4 sm:px-6 lg:px-8 lg:flex lg:justify-between">
        <div className="max-w-3xl">
          <h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl sm:tracking-tight lg:text-4xl">
            Compare Plans
          </h2>
          <p className="mt-5 text-lg text-gray-500">
            Here’s a full feature breakdown. If you are wondering which plan
            would be best for your needs, we are happy to assist you.{" "}
            <Link
              to="/request-demo"
              target="_blank"
              rel="noreferrer noopener"
              className="text-primary-500 hover:text-primary-600"
            >
              Let's talk
            </Link>
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto bg-gray-50 sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto md:hidden">
          {PLANS &&
            PLANS.map((p, i) => {
              return (
                <Fragment key={i}>
                  <div className="px-4 my-8">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                      {p.name}
                    </h2>

                    <p className="mt-4">
                      <span
                        className={`text-4xl font-extrabold ${
                          p.discount
                            ? "line-through text-gray-500"
                            : "text-gray-900"
                        }`}
                      >
                        {p.price}
                        {p.per && (
                          <span className="text-base font-medium text-gray-500">
                            {p.per}
                          </span>
                        )}
                      </span>
                      {p.discount && (
                        <span
                          className={`ml-2 text-4xl font-extrabold text-gray-900`}
                        >
                          {p.discount.price}
                          {p.discount.per && (
                            <span className="text-base font-medium text-gray-500">
                              {p.discount.per}
                            </span>
                          )}
                        </span>
                      )}
                    </p>
                    <p className="mt-4 text-sm text-gray-500">{p.tagline}</p>
                    {p.action && p.action.to ? (
                      <Link
                        className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                        aria-describedby={`tier-${p.name}`}
                        to={p.action.to}
                      >
                        {p.action.text}
                      </Link>
                    ) : (
                      p.action.href && (
                        <a
                          href={p.action.href}
                          className="mt-6 block border border-gray-800 rounded-md bg-gray-800 w-full py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                          aria-describedby={`tier-${p.name}`}
                        >
                          {p.action.text}
                        </a>
                      )
                    )}
                  </div>
                  {FEATURE_SECTIONS &&
                    FEATURE_SECTIONS.map((fs, j) => {
                      return (
                        <table className="w-full" key={j}>
                          <caption className="bg-gray-100 border-t border-gray-200 py-3 px-4 text-sm font-bold text-gray-900 text-left">
                            {fs.sectionName}
                          </caption>
                          <thead>
                            <tr>
                              <th className="sr-only" scope="col">
                                {fs.sectionName}
                              </th>
                              <th className="sr-only" scope="col">
                                Included
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                            {fs.features &&
                              fs.features.map((f, k) => {
                                const value = f.plans[i];
                                return (
                                  <tr
                                    className="border-t border-gray-200"
                                    key={k}
                                  >
                                    <th
                                      className="py-3 px-4 text-sm font-normal text-gray-500 text-left"
                                      scope="row"
                                    >
                                      {f.name}
                                    </th>
                                    <td className="py-3 pr-4">
                                      {typeof value === "string" ? (
                                        <span className="text-gray-500 text-sm font-normal">
                                          {value}
                                        </span>
                                      ) : value === true ? (
                                        <IoMdCheckmark className="text-base md:text-xl" />
                                      ) : (
                                        <IoMdRemove />
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      );
                    })}
                </Fragment>
              );
            })}
        </div>

        <div className="hidden md:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th
                  className="pb-4 px-6 text-sm font-medium text-gray-900 text-left"
                  scope="col"
                >
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>

                {PLANS &&
                  PLANS.map((p, i) => {
                    return (
                      <th
                        key={i}
                        className="md:w-1/4 lg:w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
                        scope="col"
                      >
                        {p.name}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th
                  className="py-8 px-6 text-sm font-medium text-gray-900 text-left align-top"
                  scope="row"
                >
                  Pricing
                </th>

                {PLANS &&
                  PLANS.map((p, i) => {
                    return (
                      <td key={i} className="h-full py-8 px-6 align-top">
                        <div className="relative h-full table">
                          <p>
                            <span
                              className={`text-4xl font-extrabold ${
                                p.discount
                                  ? "line-through text-gray-500"
                                  : "text-gray-900"
                              }`}
                            >
                              {p.price}
                              {p.per && (
                                <span className="text-base font-medium text-gray-500">
                                  {p.per}
                                </span>
                              )}
                            </span>
                            {p.discount && (
                              <span
                                className={`ml-2 text-4xl font-extrabold text-gray-900`}
                              >
                                {p.discount.price}
                                {p.discount.per && (
                                  <span className="text-base font-medium text-gray-500">
                                    {p.discount.per}
                                  </span>
                                )}
                              </span>
                            )}
                          </p>
                          <p className="mt-4 mb-16 text-sm text-gray-500">
                            {p.tagline}
                          </p>

                          {p.action && p.action.to ? (
                            <Link
                              className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                              aria-describedby={`tier-${p.name}`}
                              to={p.action.to}
                            >
                              {p.action.text}
                            </Link>
                          ) : (
                            p.action.href && (
                              <a
                                href={p.action.href}
                                className="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                                aria-describedby={`tier-${p.name}`}
                              >
                                {p.action.text}
                              </a>
                            )
                          )}
                        </div>
                      </td>
                    );
                  })}
              </tr>
              {FEATURE_SECTIONS &&
                FEATURE_SECTIONS.map((fs, i) => {
                  return (
                    <Fragment key={i}>
                      <tr>
                        <th
                          className="bg-gray-100 py-3 pl-6 text-base font-bold text-gray-900 text-left sticky top-0 "
                          colSpan="4"
                          scope="colgroup"
                        >
                          {fs.sectionName}
                        </th>
                      </tr>
                      {fs.features &&
                        fs.features.map((f, j) => {
                          return (
                            <tr key={j}>
                              <th
                                className="py-3 px-6 text-sm font-normal text-gray-500 text-left md:text-sm"
                                scope="row"
                              >
                                {f.name}
                              </th>
                              {f.plans &&
                                f.plans.map((p, k) => {
                                  return (
                                    <td className="py-3 px-6" key={k}>
                                      <div className="text-center flex flex-row justify-center items-center">
                                        {typeof p === "string" ? (
                                          <span className="text-gray-500 text-sm font-normal">
                                            {p}
                                          </span>
                                        ) : p === true ? (
                                          <IoMdCheckmark className="text-base md:text-xl" />
                                        ) : (
                                          <IoMdRemove />
                                        )}
                                      </div>
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </Fragment>
                  );
                })}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>

                {PLANS &&
                  PLANS.map((p, i) => {
                    return (
                      <td key={i} className="pt-5 px-6">
                        {p.action && p.action.to ? (
                          <Link
                            className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                            aria-describedby={`tier-${p.name}`}
                            to={p.action.to}
                          >
                            {p.action.text}
                          </Link>
                        ) : (
                          p.action.href && (
                            <a
                              href={p.action.href}
                              className="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                              aria-describedby={`tier-${p.name}`}
                            >
                              {p.action.text}
                            </a>
                          )
                        )}
                      </td>
                    );
                  })}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Breakdown;
