import {
  GET_ALL_ACTIVE_JOBS,
  REMOVE_APPLICATION_TO_JOB,
  APPLY_JOB,
  ADD_JOB,
  EDIT_JOB,
  DELETE_JOB,
  CLEAR_JOB,
  LOADING_JOB,
  ERROR_JOB,
  GET_JOB,
  CLEAR_JOB_BOARD,
  LOADING_JOB_BOARD,
  GET_JOB_BOARD,
  ERROR_JOB_BOARD,
  GET_MY_COMPANY_JOBS,
  LOADING_MY_COMPANY_JOBS,
  ERROR_MY_COMPANY_JOBS,
  GET_MY_COMPANY_EXT_JOBS,
  LOADING_MY_COMPANY_EXT_JOBS,
  ERROR_MY_COMPANY_EXT_JOBS,
  DELETE_EXT_JOB_POST,
  UPDATE_EXT_JOB_POST_BOARD_APPROVAL,
  UPDATE_CANDIDATE_APPLICATION_STATUS,
  DELETE_JOB_EXT_BOARD,
} from "../actions/actionTypes";

const initialState = {
  publicJobs: [],
  job: {
    loading: false,
    data: null,
    error: null,
  },
  currentJobPostActivity: {
    loading: false,
    data: null,
    error: null,
  },
  jobBoard: {
    loading: false,
    data: null,
    error: null,
  },
  myCompanyJobs: {
    loading: false,
    data: null,
    error: null,
  },
  myCompanyExtJobs: {
    loading: false,
    data: null,
    error: false,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_MY_COMPANY_JOBS: {
      return {
        ...state,
        myCompanyJobs: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GET_MY_COMPANY_JOBS: {
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case ERROR_MY_COMPANY_JOBS: {
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }
    case UPDATE_CANDIDATE_APPLICATION_STATUS: {
      const { jobPostId, candidateUserId, newStatus } = payload;
      const newJobs = (state.myCompanyJobs.data || []).map((job) => {
        if (job.id === jobPostId) {
          const newJobPostActivity = (job.jobPostActivity || []).map(
            (candidate) => {
              if (candidate.userAccountId === candidateUserId) {
                return {
                  ...candidate,
                  applicationStatusId: newStatus,
                };
              }
              return candidate;
            }
          );
          return {
            ...job,
            jobPostActivity: newJobPostActivity,
          };
        }
        return job;
      });
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: newJobs,
          error: null,
        },
      };
    }
    case LOADING_MY_COMPANY_EXT_JOBS: {
      return {
        ...state,
        myCompanyExtJobs: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GET_MY_COMPANY_EXT_JOBS: {
      return {
        ...state,
        myCompanyExtJobs: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case ERROR_MY_COMPANY_EXT_JOBS: {
      return {
        ...state,
        myCompanyExtJobs: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }
    case DELETE_JOB_EXT_BOARD: {
      let newMyCompanyJobs = (state.myCompanyJobs.data || []).map((job) => {
        if (job.id === payload.jobPostId) {
          const boards = (job.boards || []).filter(
            (b) => b.id !== payload.jobBoardId
          );
          return {
            ...job,
            boards: boards,
          };
        }
        return job;
      });
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: newMyCompanyJobs,
          error: null,
        },
      };
    }
    case DELETE_EXT_JOB_POST: {
      let newExtCompanyJobs =
        state.myCompanyExtJobs &&
        state.myCompanyExtJobs.data &&
        state.myCompanyExtJobs.data.posts &&
        state.myCompanyExtJobs.data.posts.filter(
          (elem) => elem.id !== payload.jobPostId
        );
      return {
        ...state,
        myCompanyExtJobs: {
          loading: false,
          data: {
            ...state.myCompanyExtJobs,
            posts: newExtCompanyJobs,
          },
          error: null,
        },
      };
    }
    case UPDATE_EXT_JOB_POST_BOARD_APPROVAL: {
      let newExtCompanyJobs =
        state.myCompanyExtJobs &&
        state.myCompanyExtJobs.data &&
        state.myCompanyExtJobs.data.posts &&
        state.myCompanyExtJobs.data.posts.map((elem) => {
          if (elem.id == payload.jobPostId) {
            return {
              ...elem,
              JobPostBoardRelationship: {
                ...elem.JobPostBoardRelationship,
                approvalStatus: payload.approvalStatus,
              },
            };
          } else {
            return elem;
          }
        });
      return {
        ...state,
        myCompanyExtJobs: {
          loading: false,
          data: {
            ...state.myCompanyExtJobs,
            posts: newExtCompanyJobs,
          },
          error: null,
        },
      };
    }
    case DELETE_JOB: {
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: state.myCompanyJobs.data.filter((elem) => elem.id !== payload),
          error: null,
        },
      };
    }
    case ADD_JOB: {
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: [payload, ...state.myCompanyJobs.data],
          error: null,
        },
      };
    }
    case EDIT_JOB: {
      let newMyCompanyJobs =
        state.myCompanyJobs &&
        state.myCompanyJobs.data &&
        state.myCompanyJobs.data.map((elem) => {
          if (elem.id == payload.id) {
            return {
              ...elem,
              ...payload,
            };
          } else {
            return elem;
          }
        });
      return {
        ...state,
        myCompanyJobs: {
          loading: false,
          data: newMyCompanyJobs,
          error: null,
        },
      };
    }

    case GET_ALL_ACTIVE_JOBS: {
      return {
        ...state,
        publicJobs: payload,
      };
    }
    case CLEAR_JOB: {
      return {
        ...state,
        job: {
          loading: false,
          data: null,
          error: null,
        },
      };
    }
    case LOADING_JOB: {
      return {
        ...state,
        job: {
          ...state.job,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GET_JOB: {
      return {
        ...state,
        job: {
          loading: false,
          data: payload,
          error: false,
        },
      };
    }
    case ERROR_JOB: {
      return {
        ...state,
        job: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }
    case LOADING_JOB_BOARD: {
      return {
        ...state,
        jobBoard: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GET_JOB_BOARD: {
      return {
        ...state,
        jobBoard: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case ERROR_JOB_BOARD: {
      return {
        ...state,
        jobBoard: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }
    case CLEAR_JOB_BOARD: {
      return {
        ...state,
        jobBoard: {
          loading: false,
          data: null,
          error: null,
        },
      };
    }
    case APPLY_JOB: {
      return {
        ...state,
        currentJobPostActivity: {
          loading: false,
          data: payload || null,
          error: null,
        },
      };
    }
    case REMOVE_APPLICATION_TO_JOB: {
      return {
        ...state,
        currentJobPostActivity: {
          loading: false,
          data: null,
          error: null,
        },
      };
    }
    default:
      return state;
  }
}
