// Avatar
import SebastianAvatar from "../../../assets/avatars/sebastian.png";
import NadiaAvatar from "../../../assets/avatars/nadia.png";
// Images
import ProgramPage from "../../../assets/screenshots/user-program-page.png";
import ProgramJudging from "../../../assets/images/ProgramJudging.png";
import ProgramSubmissions from "../../../assets/screenshots/org-program-submissions.png";
import ProgramFormBuilder from "../../../assets/screenshots/org-program-form-builder.png";
import ProgramUserProfile from "../../../assets/screenshots/profile-pages/sample-profile.png";
import ProgramInsights from "../../../assets/screenshots/org-program-analytics-v2.png";
// Icons
import {
  IoMdThumbsUp,
  IoMdDocument,
  IoMdGlobe,
  IoIosBuild,
  IoIosPerson,
  IoIosTrendingUp,
  IoIosCheckmarkCircle,
} from "react-icons/io";

const Features = () => {
  return (
    <>
      <div className="bg-white">
        <div className="max-w-5xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-red-600 tracking-wide uppercase">
              Features
            </h2>
            <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              An all-in-one solution
            </p>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              Custom submissions, advanced reviewing, in-depth user profiles,
              informative insights, and more.
            </p>
          </div>
        </div>
      </div>

      <div className="relative bg-white pb-32 lg:overflow-hidden">
        <div className="relative">
          <div className="lg:items-center lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-br from-orange-400 to-primary-400 text-gray-100 text-xl">
                    <IoMdDocument />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Powerful submission management
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Get a full view of your participants, submissions, results,
                    reviewers, insights, and more.
                  </p>
                </div>
              </div>
              <dl className="text-gray-600 mt-6 space-y-2 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-4">
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Team or individual submissions
                    </p>
                  </dt>
                </div>
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Group together similar submissions
                    </p>
                  </dt>
                </div>
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      View and download in-depth reports
                    </p>
                  </dt>
                </div>
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Advanced program privacy and security
                    </p>
                  </dt>
                </div>
              </dl>
              <div className="mt-8 border-t border-gray-200 pt-6">
                <blockquote>
                  <div>
                    <p className="text-base text-gray-500">
                      &ldquo;Utilizing Pralent for our competition was a no
                      brainer. It was super smooth to setup, and the customer
                      support along the way was amazing if we needed any help.
                      Would definitely recommend this for any organization
                      looking to host any type of challenge in the
                      future.&rdquo;
                    </p>
                  </div>
                  <footer className="mt-3">
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          className="h-6 w-6 rounded-full"
                          src={SebastianAvatar}
                          alt="Sebastian Yang Photo"
                        />
                      </div>
                      <div className="text-base font-medium text-gray-700">
                        Sebastian Yang, Carnegie Mellon Entrepreneurship
                        Association
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
            <div className="lg:col-span-3 mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <div className="shadow-xl rounded-lg">
                  <img
                    className="bg-white w-full rounded-lg"
                    src={ProgramSubmissions}
                    alt="Program Submissions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-24">
          <div className="lg:items-center lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-4">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-br from-orange-400 to-primary-400 text-gray-100 text-xl">
                    <IoMdThumbsUp />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Streamline your review process
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Whether you have a small team of reviewers or a large
                    comprehensive program, you have full control of your entire
                    review process.
                  </p>
                </div>
              </div>
              <dl className="text-gray-600 mt-6 space-y-2 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-4">
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Customizable criteria and workflow
                    </p>
                  </dt>
                </div>
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Track reviewer progress
                    </p>
                  </dt>
                </div>
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Anyonymous scoring and feedback release
                    </p>
                  </dt>
                </div>
                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      Auto-assign reviewers to submissions
                    </p>
                  </dt>
                </div>

                <div className="relative">
                  <dt className="flex items-center">
                    <IoIosCheckmarkCircle />
                    <p className="ml-2 text-lg leading-6 font-medium">
                      View and download in-depth reports
                    </p>
                  </dt>
                </div>
              </dl>
            </div>

            <div className="lg:col-span-3 mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <div className="shadow-xl rounded-lg">
                  <img
                    className="bg-white w-full rounded-lg"
                    src={ProgramJudging}
                    alt="Program Reviewing & Judging"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative mt-24">
          <div className="lg:items-center lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-br from-orange-400 to-primary-400 text-gray-100 text-xl">
                    <IoIosBuild />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Create custom registration and submission forms
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Over 10+ Form Field types. Accept all the information you
                    need such as file uploads, links, images, checkboxes,
                    multiple selects, and much more. Customize the form to
                    exactly what information you need to capture.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <div className="shadow-xl rounded-lg">
                  <img
                    className="w-full rounded-lg"
                    src={ProgramFormBuilder}
                    alt="Program Form Builder"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:items-center lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-4">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-br from-orange-400 to-primary-400 text-gray-100 text-xl">
                    <IoIosPerson />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    See in-depth information about your participants
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Understand more about your participants with in-depth
                    profiles. Participants are able to add everything from their
                    education information, past projects, experiences,
                    interests, skills, and much more.
                  </p>
                </div>
                <dl className="text-gray-600 mt-6 space-y-2 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-4">
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        Display and showcase personal projects
                      </p>
                    </dt>
                  </div>
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        Learn about relevant skills and interests
                      </p>
                    </dt>
                  </div>
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        Advanced profile privacy and security
                      </p>
                    </dt>
                  </div>
                </dl>

                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;Participating in a pitch competition hosted on
                        Pralent gave my early-stage startup the perfect
                        opportunity to execute our plan. This also gave us the
                        confidence we needed to continue with our idea during
                        these uncertain times.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={NadiaAvatar}
                            alt="Nadia Susanto Photo"
                          />
                        </div>
                        <div className="text-base font-medium text-gray-700">
                          Nadia Susanto, Business Administration Student
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <div className="shadow-xl rounded-lg">
                  <img
                    className="w-full rounded-lg"
                    src={ProgramUserProfile}
                    alt="Program user profiles"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:items-center lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-br from-orange-400 to-primary-400 text-gray-100 text-xl">
                    <IoMdGlobe />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Customize your program to your brand
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Create your own page and application forms. Work with your
                    team to customize everything to fit your brand. Be able to
                    provide consistent experiences across your programs. If
                    you're interested, you can also work with the Pralent team
                    to take the customization to the next level.
                  </p>
                </div>
                <dl className="text-gray-600 mt-6 space-y-2 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-4">
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        Custom url and header image
                      </p>
                    </dt>
                  </div>
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        Customize information shown to participants
                      </p>
                    </dt>
                  </div>
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        Custom colors and logos
                      </p>
                    </dt>
                  </div>
                  <div className="relative">
                    <dt className="flex items-center">
                      <IoIosCheckmarkCircle />
                      <p className="ml-2 text-lg leading-6 font-medium">
                        More coming soon!
                      </p>
                    </dt>
                  </div>
                </dl>
              </div>
            </div>
            <div className="lg:col-span-3 mt-12 sm:mt-16 lg:mt-0">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <div className="shadow-xl rounded-lg">
                  <img
                    className="w-full rounded-lg"
                    src={ProgramPage}
                    alt="Program page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:items-center lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-span-2 px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-4">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-br from-orange-400 to-primary-400 text-gray-100 text-xl">
                    <IoIosTrendingUp />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Gain important insight into your program and continue to
                    improve for the future
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Have full visibility throughout your entire program and
                    understand when participants are signing up, when users are
                    submitting, the demographic breakdown of participants, and
                    much more.
                  </p>
                </div>
              </div>
            </div>
            <div className="lg:col-span-3 mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <div className="shadow-xl rounded-lg">
                  <img
                    className="w-full rounded-lg"
                    src={ProgramInsights}
                    alt="Program insights"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
