import React, { Fragment } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { updateCurrentSubmissionIndex } from "../../../redux/actions/judge";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Button from "../../../shared/Button";
// Icons
import { IoMdArrowForward, IoMdArrowBack } from "react-icons/io";

const Navigation = ({
  submissions,
  currentIndex,
  currentSubmission,
  updateCurrentSubmissionIndex,
  dirtyState,
}) => {
  const history = useHistory();

  const { slug, token } = useParams();

  const handleNextSubmission = () => {
    // let confirmMovingOn =
    //   !dirtyState ||
    //   window.confirm("Are you sure you want to continue without saving?");

    // if (!confirmMovingOn) {
    //   return;
    // }

    let newIndex = currentIndex + 1;
    //console.log("Next");
    //console.log(submissions.length, newIndex);
    if (
      submissions &&
      submissions.length > 0 &&
      newIndex < submissions.length
    ) {
      history.push(
        `/programs/${slug}/reviewer/${token}/${submissions[newIndex].id}`
      );
    }
  };

  const handlePreviousSubmission = () => {
    // let confirmMovingOn =
    //   !dirtyState ||
    //   window.confirm("Are you sure you want to continue without saving?");

    // if (!confirmMovingOn) {
    //   return;
    // }
    //console.log("Previous");
    let newIndex = currentIndex - 1;
    if (
      submissions &&
      submissions.length > 0 &&
      newIndex < submissions.length &&
      newIndex >= 0
    ) {
      // updateCurrentSubmissionIndex(newIndex);

      history.push(
        `/programs/${slug}/reviewer/${token}/${submissions[newIndex].id}`
      );
    }
  };

  return (
    <Box display="block">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Button
          onClick={handlePreviousSubmission}
          disabled={currentIndex <= 0}
          startIcon={<IoMdArrowBack />}
        >
          Previous
        </Button>
        <Typography>
          <strong>
            {currentIndex + 1} / {submissions.length}
          </strong>
        </Typography>
        <Button
          onClick={handleNextSubmission}
          disabled={currentIndex >= submissions.length - 1}
          endIcon={<IoMdArrowForward />}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  submissions: state.judge.submissions,
  currentSubmission: state.judge.currentSubmission,
  currentIndex: state.judge.currentIndex,
  dirtyState: state.judge.dirtyState,
});

const mapDispatchToProps = { updateCurrentSubmissionIndex };

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
