import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
// Material UI
import { makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  CookieConsentAlert: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1.2,
  },
}));

const CookieConsent = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    const accepted = Cookies.get("CookiePolicyConsent");
    if (accepted === undefined || accepted === false) {
      setOpen(true);
      Cookies.set("CookiePolicyConsent", "true", { expires: 365 });
    }
  }, []);
  return (
    <div className={classes.root}>
      <Snackbar open={open} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          className={classes.CookieConsentAlert}
          severity="info"
          color="warning"
          variant="standard"
        >
          This website uses cookies or other identifiers,
          which are necessary for its functioning and required to achieve the
          purposes illustrated in the{" "}
          <Link
            href="/privacy"
            color="inherit"
            target="_blank"
            underline="always"
          >
            privacy policy
          </Link>
          . You accept the use of cookies or other identifiers by closing or
          dismissing this notice, by clicking a link or button or by continuing
          to browse otherwise.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CookieConsent;
