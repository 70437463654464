import React, { useState } from "react";
// redux
import { connect } from "react-redux";
import { organizationRegisterUserChallenge } from "../../../redux/actions/challenge";
// material ui
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
// components
import Button from "../../../shared/Button";

export const RegisterUser = ({
  challenge,
  organizationRegisterUserChallenge,
}) => {
  const [registerEmail, setRegisterEmail] = useState("");
  const [openRegisterUser, setOpenRegisterUser] = useState(false);

  const handleRegisterClick = () => {
    setRegisterEmail("");
    setOpenRegisterUser(true);
  };

  const handleRegisterCloseSettings = () => {
    setOpenRegisterUser(false);
  };

  const onSubmitRegisterEmail = (e) => {
    e.preventDefault();
    organizationRegisterUserChallenge(registerEmail, challenge.id);

    setOpenRegisterUser(false);
  };
  return (
    <>
      <Dialog
        open={openRegisterUser}
        onClose={handleRegisterCloseSettings}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Register User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter an email below of a user that you would like to
            manually register for this program.
          </DialogContentText>
          <Box mb={{ xs: 2, md: 4 }}>
            <form onSubmit={onSubmitRegisterEmail}>
              <Box display="flex" alignItems="stretch">
                <TextField
                  id=""
                  type="email"
                  required
                  value={registerEmail}
                  name="registerEmail"
                  placeholder="Enter an email address"
                  onChange={(e) => setRegisterEmail(e.target.value)}
                  fullWidth
                />
                <Button type="submit">Submit</Button>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <Tooltip title={"Register user to the program"} placement="top">
        <Button variant="contained" onClick={handleRegisterClick}>
          Register New User
        </Button>
      </Tooltip>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = { organizationRegisterUserChallenge };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
