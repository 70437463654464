import React, { useEffect } from "react";
// Redux
import { connect } from "react-redux";
import {
  getAllChallenges,
  clearAllChallenges,
} from "../../redux/actions/challenge";
// Material UI
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
// Components
import ChallengeListItem from "../../shared/ChallengeListItem";
import Spinner from "../../shared/Spinner";

import { Helmet } from "react-helmet";

const Challenges = ({
  getAllChallenges,
  clearAllChallenges,
  challenges,
  loading,
}) => {
  useEffect(() => {
    getAllChallenges({ isPublic: true, isPublished: true, isActive: true });

    return () => {
      clearAllChallenges();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Programs | Pralent</title>
        <meta
          name="description"
          content="Build your skills and network by participating in programs."
        />
      </Helmet>

      <Box
        py={[1, 2]}
        flexGrow={1}
        height="100%"
        maxHeight="100vh"
        overflow="auto"
      >
        <Container maxWidth="md">
          <Box textAlign="left" mb={[2, 3, 4]}>
            <Typography variant="h5">
              <strong>Programs</strong>
            </Typography>
            <Typography color="textSecondary">
              Participate in these public programs to build your skills and
              network.
            </Typography>
          </Box>

          {loading || !challenges ? (
            <Box pt={5}>
              <Spinner />
            </Box>
          ) : (
            <Box>
              <List>
                {challenges &&
                  challenges.map((challenge, index) => {
                    return (
                      <ChallengeListItem key={index} challenge={challenge} />
                    );
                  })}
              </List>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenges: state.challenge.challenges,
  loading: state.challenge.loading,
});

const mapDispatchToProps = {
  getAllChallenges,
  clearAllChallenges,
};

export default connect(mapStateToProps, mapDispatchToProps)(Challenges);
