import React, { useState } from "react";
import { connect } from "react-redux";
import { Line, defaults } from "react-chartjs-2";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

const TimeSeriesChart = ({ data }) => {
  // Removing Chart legend
  defaults.global.legend.display = false;
  const theme = useTheme();
  const graphData = {
    // need to change this to show Mon Jan 1 as the label
    labels: data.map((dayCountObject) => dayCountObject.day),
    datasets: [
      {
        data: data.map((dayCountObject) => dayCountObject.count),
        backgroundColor: `${fade(theme.palette.primary.main, 0.1)}`,
        borderColor: `${fade(theme.palette.primary.main, 0.0)} 2px solid`,
        borderWidth: 2,
        borderWidth: 3,
        borderColor: `${fade(theme.palette.primary.main, 0.9)}`,
        pointBackgroundColor: `${fade(theme.palette.primary.main, 1)}`,
        pointRadius: 1,
        pointHoverRadius: 3,
      },
    ],
  };

  return (
    <Box height="100%">
      <Line
        data={graphData}
        width={300}
        height={300}
        options={{
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0.025)",
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0.025)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default TimeSeriesChart;
