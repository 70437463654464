import { Link } from "react-router-dom";
import InfoSection from "../../shared/InfoSection";
// Images
import orginsightsdemographics from "../../assets/screenshots/insights-pages/org-insights-demographics.png";
import orginsightsactivity from "../../assets/screenshots/insights-pages/org-insights-activity.png";
import orgprograminsights from "../../assets/screenshots/insights-pages/org-program-registration-insights.png";
import orginsightsinterests from "../../assets/screenshots/insights-pages/org-insights-interests.png";
// Icons
import { CheckIcon } from "@heroicons/react/outline";
import { BiBrain } from "react-icons/bi";
import { RiCameraLensFill, RiQuestionAnswerLine } from "react-icons/ri";
import { MdTrackChanges } from "react-icons/md";

const features = [
  {
    name: "Registrations",
    description:
      "Track signups at every level of your community including events, programs, members, and more.",
  },
  {
    name: "Activity & Inactivity",
    description:
      "Keep track of how engaged your community has been and how many members have been inactive.",
  },
  {
    name: "Diversity",
    description:
      "View a comprehensive breakdown of key diversity metrics such as race and gender.",
  },
  {
    name: "Educational Background",
    description:
      "Always wondered what are the most common schools, majors and graduation years?",
  },
  {
    name: "Interests",
    description:
      "Learn more about the interests of your community through our curated interests questions.",
  },
  {
    name: "Top Skills",
    description:
      "Understand what types of skills your event, program, and community attract.",
  },
  {
    name: "Compare",
    description:
      "Compare and contrast to learn more about why some things worked well and how you can improve on others.",
    comingSoon: true,
  },
  {
    name: "Member Activity",
    description:
      "View the most engaged members and learn more about how each individual has interacted so far.",
    comingSoon: true,
  },
];

export default function FeaturesInsights() {
  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="bg-gradient-to-br from-green-100 via-white to-primary-100 pb-8 sm:pb-12 lg:pb-12">
          <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
              <div>
                <div className="mt-20">
                  <div>
                    <span className="rounded bg-green-100 px-2.5 py-1 text-xs font-semibold text-green-600 tracking-wide uppercase">
                      Insights
                    </span>
                  </div>
                  <div className="mt-6 sm:max-w-xl">
                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      📈 Advanced insights to propel your community forward
                    </h1>
                    <p className="mt-6 text-xl text-gray-600">
                      Empower yourself to make more informed community decisions
                      by seeing insights for every community, event, program,
                      and more.
                    </p>
                    <div className="mt-4 md:mt-8">
                      <Link
                        to="/signup?redirect=/org/new"
                        className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-opacity-80 sm:px-8"
                      >
                        Get started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
              <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                  {/* <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" /> */}
                  <svg
                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={392}
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                  </svg>
                </div>
                <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                  <img
                    className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                    src={orginsightsdemographics}
                    alt="org insights demographics"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative overflow-hidden">
          <InfoSection
            title="Learn what drives community engagement"
            subtitle="Track how active your members are and how many are participating in your community. Make better decisions on ways to improve."
            image={orginsightsactivity}
            topText="Engagement"
            textLeft={false}
            color="green"
            secondaryColor="primary"
            icon={BiBrain}
          />
          <InfoSection
            title="See a snapshot of your community, events, programs, and more"
            subtitle="Deep insights into your community members activity. Analyze attributes like registrations, signups, diversity, educational background, interests, and more."
            image={orgprograminsights}
            topText="Members"
            textLeft
            bggray
            color="green"
            secondaryColor="primary"
            icon={RiCameraLensFill}
          />
          <InfoSection
            title="Track your diversity goals"
            subtitle="Strive towards creating an equitable and inclusive community for all by understanding the diversity breakdown at all levels of your community."
            image={orginsightsdemographics}
            topText="Diversity"
            textLeft={false}
            color="green"
            secondaryColor="primary"
            icon={MdTrackChanges}
          />
          <InfoSection
            title="Learn more about what interests your members"
            subtitle="Members have the option of answering our curated interest questions which allow you to see what interests them at a deeper level."
            image={orginsightsinterests}
            topText="Interests"
            textLeft
            bggray
            color="green"
            secondaryColor="primary"
            icon={RiQuestionAnswerLine}
          />
        </div>

        {/* Gradient Feature Section */}
        <div className="bg-gray-900">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-center text-3xl md:text-4xl font-extrabold text-gray-100 tracking-tight">
              Features that allow you to make data driven decisions
            </h2>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <CheckIcon
                      className="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-semibold text-gray-100">
                      {feature.name}{" "}
                      {feature.comingSoon && (
                        <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-200 text-pink-800">
                          Coming Soon
                        </span>
                      )}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-400">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to dive in?</span>
              <span className="block">
                Understand your community at a deeper level.
              </span>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/signup?redirect=/org/new"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
