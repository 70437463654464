import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from "react";
// Redux
import { connect } from "react-redux";
import {
  getRoomPosts,
  handleClearPostsKeys,
} from "../../../redux/actions/room";
// Context
import { useRoomMembersContext, useRoomContext } from "./RoomContext";
// Material UI
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
// Icons
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
// Components
import Post from "./Post";
import Spinner from "../../Spinner";
import EmptyState from "../../EmptyState";
import Button from "../../Button";
import { ROOM_TYPE } from "../../../utils/globalValues";

import { Virtuoso, VirtuosoGrid } from "react-virtuoso";

import styled from "styled-components";

const LIMIT = 50;

const GridItemContainer = styled.div`
  padding: 0.5rem;
  width: 33%;
  display: flex;
  flex: none;
  align-content: stretch;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const GridItemWrapper = styled.div`
  flex: 1;
`;

const GridListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

const RoomPosts = ({
  posts: reduxPosts,
  getRoomPosts,
  handleClearPostsKeys,
  parentPostId,
  isReply = false,
  preview = false,
}) => {
  const { room } = useRoomContext();

  const lastPostOnBottom = isReply ? true : room.type === ROOM_TYPE.CHAT;

  const lookupId = isReply ? parentPostId : room && room.id;
  const roomPosts = (reduxPosts && lookupId && reduxPosts[lookupId]) || {};

  const {
    isLoading: isLoadingPosts = false,
    keys = null,
    posts: posts = null,
    count: count = null,
    error: errorPosts = null,
    isError: isErrorPosts = false,
  } = roomPosts;

  const { isLoadingRoomMembers, roomMembers } = useRoomMembersContext();

  const [offset, setOffset] = React.useState(0);

  const [firstItemIndex, setFirstItemIndex] = useState(count || 100000000);

  const handleGetPosts = async () => {
    await getRoomPosts(
      isReply ? true : false,
      room ? room.id : "",
      parentPostId || "",
      offset,
      LIMIT,
      lastPostOnBottom
    );
    setOffset((offset) => offset + LIMIT);
  };

  useEffect(() => {
    if (room && room.id) {
      handleGetPosts();
    }

    return () => {
      setOffset(0);
      setFirstItemIndex(100000000);
      handleClearPostsKeys(lookupId);
    };
  }, [room]);

  const prependItems = useCallback(async () => {
    // const nextFirstItemIndex = firstItemIndex - offset;
    console.log("calling end");
    if (offset < count) {
      await handleGetPosts();
      const nextFirstItemIndex = count - keys.length;
      setFirstItemIndex(() => nextFirstItemIndex);
    }
    return false;
  }, [offset, count, firstItemIndex, keys, handleGetPosts]);

  return (
    <Box width="100%" height="100%">
      {isLoadingPosts || isLoadingRoomMembers ? (
        <Box display="flex" flexDirection="column" height="100%" p={2}>
          {[...Array(8)].map((_, index) => (
            <Box
              key={index}
              display="flex"
              mb={2}
              style={{ gap: "6px" }}
              width="100%"
            >
              <Skeleton
                animation="wave"
                variant="circle"
                width={40}
                height={40}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                height={75}
                width="100%"
              />
            </Box>
          ))}
        </Box>
      ) : isErrorPosts ? (
        <Alert severity="error">
          {errorPosts && errorPosts.data && errorPosts.data.msg}
        </Alert>
      ) : (
        <Box height="100%" width="100%" minHeight={200}>
          {roomMembers && keys && keys.length > 0 ? (
            <>
              <Box height="100%" width="100%">
                {isReply || room.type === ROOM_TYPE.CHAT ? (
                  <Virtuoso
                    firstItemIndex={firstItemIndex}
                    initialTopMostItemIndex={LIMIT - 1}
                    data={keys}
                    startReached={prependItems}
                    components={{
                      Header: () => {
                        if (isReply) return null;
                        return (
                          <div
                            style={{
                              padding: "2rem",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {offset < count
                              ? "Loading ..."
                              : "This is the very beginning of the messages."}
                          </div>
                        );
                      },
                    }}
                    itemContent={(index, postKey) => {
                      const post = posts[postKey];
                      const postMember = roomMembers[post.postedBy];
                      const postUser = postMember && postMember.user;
                      const postProfile = postMember && postMember.profile;
                      return (
                        <Box>
                          <Post
                            post={post}
                            postUser={postUser}
                            postProfile={postProfile}
                          />
                        </Box>
                      );
                    }}
                  />
                ) : room.type === ROOM_TYPE.FORUM ? (
                  <Virtuoso
                    endReached={prependItems}
                    data={keys}
                    overscan={50}
                    itemContent={(index, postKey) => {
                      const post = posts[postKey];
                      const postMember = roomMembers[post.postedBy];
                      const postUser = postMember && postMember.user;
                      const postProfile = postMember && postMember.profile;
                      return (
                        <Box>
                          <Post
                            post={post}
                            postUser={postUser}
                            postProfile={postProfile}
                          />
                        </Box>
                      );
                    }}
                    components={{
                      Footer: () => {
                        return (
                          <div
                            style={{
                              padding: "2rem",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {offset < count ? "Loading ..." : "End reached!"}
                          </div>
                        );
                      },
                    }}
                  />
                ) : (
                  <VirtuosoGrid
                    totalCount={keys.length || 0}
                    endReached={prependItems}
                    data={keys}
                    components={{
                      Item: GridItemContainer,
                      List: GridListContainer,
                      ScrollSeekPlaceholder: ({ height, width, index }) => (
                        <GridItemContainer>
                          <GridItemWrapper>{"--"}</GridItemWrapper>
                        </GridItemContainer>
                      ),
                      Footer: () => {
                        return (
                          <div
                            style={{
                              padding: "2rem",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {offset < count ? "Loading ..." : "End reached!"}
                          </div>
                        );
                      },
                    }}
                    itemContent={(index) => {
                      const postKey =
                        keys && keys.length && index < keys.length
                          ? keys[index]
                          : null;
                      if (!postKey)
                        return (
                          <GridItemContainer>
                            <GridItemWrapper>{"--"}</GridItemWrapper>
                          </GridItemContainer>
                        );
                      const post = posts[postKey];
                      const postMember = roomMembers[post.postedBy];
                      const postUser = postMember && postMember.user;
                      const postProfile = postMember && postMember.profile;
                      return (
                        <GridItemWrapper>
                          <Post
                            post={post}
                            postUser={postUser}
                            postProfile={postProfile}
                          />
                        </GridItemWrapper>
                      );
                    }}
                  />
                )}
              </Box>
            </>
          ) : (
            !isReply && (
              <Box
                height="70vh"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box m="auto">
                  <EmptyState
                    title={`Send your first message to ${room.name}`}
                    subtitle={
                      room.description ||
                      "Start a conversation to see messages here"
                    }
                    icon={SendOutlinedIcon}
                  />
                </Box>
              </Box>
            )
          )}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  posts: state.room.posts,
});

const mapDispatchToProps = { getRoomPosts, handleClearPostsKeys };

export default connect(mapStateToProps, mapDispatchToProps)(RoomPosts);
