import React from "react";
// Components
import Hero from "./components/hero";
import UseCases from "./components/usecases";
import Features from "./components/features";
import Testimonials from "./components/testimonials";
import CTA from "./components/cta";

const FeaturesPrograms = () => {
  return (
    <>
      <Hero />
      <UseCases />
      <Features />
      <Testimonials />
      <CTA />
    </>
  );
};

export default FeaturesPrograms;
