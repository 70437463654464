import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { clearOnboardingChallengeDetails } from "../../redux/actions/onboarding";
// Material UI
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core";
// Components
import LogInForm from "../../shared/LogInForm";
import TransparentBackgroundLogo from "../../assets/brand/logos/TransparentBackgroundLogo.png";

const useStyles = makeStyles((theme) => ({
  Link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  Card: {
    maxWidth: 500,
    boxShadow: theme.shadows[4],
  },
  logo: {
    height: 50,
    width: 50,
  },
}));

const Login = ({ clearOnboardingChallengeDetails }) => {
  const classes = useStyles();

  const theme = useTheme();

  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const redirect = query.get("redirect");

  useEffect(() => {
    clearOnboardingChallengeDetails();
  }, []);

  return (
    <Box>
      <Container maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          minWidth="100%"
          py={2}
        >
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box mb={2}>
              <img
                src={TransparentBackgroundLogo}
                alt="TransparentBackgroundLogo"
                className={classes.logo}
              />
            </Box>
            <Typography variant="h4" component="h1">
              <Box fontWeight={700}>Welcome to Pralent 👋</Box>
            </Typography>
          </Box>

          <Box my={3} width="100%">
            <LogInForm />
          </Box>
          <Typography variant="body1" color="textSecondary">
            Need an account?{" "}
            <Link
              className={classes.Link}
              to={`/signup` + (redirect ? `?redirect=${redirect}` : "")}
            >
              Sign Up
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

const mapDispatchToProps = { clearOnboardingChallengeDetails };

export default connect(null, mapDispatchToProps)(Login);
