// React
import React from "react";
// React router
import { useParams, useHistory } from "react-router";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// React query
import { useMutation, useQueryClient } from "react-query";
import { leaveRoomById, joinRoomById } from "../../../hooks/room";
// Context
import { useRoomContext, useUserInRoomContext } from "./RoomContext";
import {
  useCompanyContext,
  useCommunityMemberContext,
} from "../../../layouts/Community/components/CommunityContext";
// Material UI
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// Icons
import {
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// Components
import Button from "../../Button";
import RoomMembers from "./RoomMembers";
// Utils
import useClipboard from "react-use-clipboard";
import { Emoji } from "emoji-mart";
import { ROOM_TYPE, MEMBER_STATUS } from "../../../utils/globalValues";

const RoomHeader = ({ setAlert }) => {
  const { communitySlug } = useParams();

  const companyContext = useCompanyContext();
  let companyDetails;
  if (companyContext && companyContext.data && companyContext.data.data) {
    companyDetails = companyContext.data.data;
  }

  const communityMemberContext = useCommunityMemberContext();
  let communityMember;
  if (
    communityMemberContext &&
    communityMemberContext.data &&
    communityMemberContext.data.data
  ) {
    communityMember = communityMemberContext.data.data;
  }

  const { isLoadingRoom, room, isErrorRoom, errorRoom } = useRoomContext();
  const { isLoadingUserInRoom, userInRoom } = useUserInRoomContext();

  const history = useHistory();

  const queryClient = useQueryClient();

  const joinRoomByIdMutation = useMutation(joinRoomById, {
    onSuccess: async (data) => {
      if (data && data.data) {
        await queryClient.invalidateQueries([
          "userInRoom",
          {
            roomId: room.id,
          },
        ]);
        await queryClient.invalidateQueries([
          "myCommunityRooms",
          {
            communityId: companyDetails.id,
          },
        ]);
        await queryClient.invalidateQueries([
          "roomMembers",
          { roomId: room.id, onlyApproved: true },
        ]);
      }
    },
  });

  const leaveRoomMutation = useMutation(leaveRoomById, {
    onSuccess: async (data) => {
      if (data && data.data) {
        if (room.belongsToCommunity && companyDetails && companyDetails.id) {
          await queryClient.invalidateQueries([
            "myCommunityRooms",
            {
              communityId: companyDetails.id,
            },
          ]);
          history.push(`/c/${communitySlug}/rooms`);
        } else {
          history.push(`/chats/new`);
        }
      }
    },
  });

  const handleLeaveRoom = () => {
    if (window.confirm("Are you sure you would like to leave this room?")) {
      leaveRoomMutation.mutate({
        roomId: room.id,
      });
      handleCloseMenuMore();
    }
  };

  const handleJoinRoom = () => {
    if (
      !communityMember ||
      communityMember.isBanned ||
      communityMember.approvalStatus !== MEMBER_STATUS.APPROVED
    ) {
      history.push(`/c/${companyDetails.communitySlug}`);
    } else {
      joinRoomByIdMutation.mutate({
        roomId: room.id,
      });
    }
  };

  const [anchorElMenuMore, setAnchorElMenuMore] = React.useState(null);
  const openMenuMore = Boolean(anchorElMenuMore);

  const handleClickMenuMore = (event) => {
    setAnchorElMenuMore(event.currentTarget);
  };

  const handleCloseMenuMore = () => {
    setAnchorElMenuMore(null);
  };

  const [roomName, setRoomName] = useClipboard(room?.name);
  const [roomLink, setRoomLink] = useClipboard(document.location.href);

  const onCopyRoomInfo = (type) => {
    if (type === "name") {
      setRoomName();
    } else if (type === "link") {
      setRoomLink();
    }
    setAlert(`Successfully copied room ${type}.`, "success");
  };

  return (
    <Box bgcolor="background.default" zIndex="appBar" flex="0 1 auto">
      <Box
        py={1.5}
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="h6" component="div">
            <Box
              fontWeight={600}
              display="flex"
              alignItems="center"
              style={{ gap: "12px" }}
              lineHeight={1.2}
            >
              {room.type === ROOM_TYPE.CHAT ? (
                <HiOutlineChat />
              ) : room.type === ROOM_TYPE.FORUM ? (
                <HiOutlinePencilAlt />
              ) : room.type == ROOM_TYPE.BOARD ? (
                <HiOutlineViewGrid />
              ) : (
                <HiHashtag />
              )}
              {room.name}
              {room.emoji && <Emoji emoji={room.emoji} size={20} />}
            </Box>
          </Typography>
          {room.description && (
            <Typography variant="body2" component="div" color="textSecondary">
              <Box lineHeight={1.2}>{room.description}</Box>
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
          <RoomMembers />
          {userInRoom ? (
            <>
              <IconButton
                aria-label="more"
                aria-controls="menu-more"
                aria-haspopup="true"
                onClick={handleClickMenuMore}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-more"
                anchorEl={anchorElMenuMore}
                keepMounted
                open={openMenuMore}
                onClose={handleCloseMenuMore}
              >
                <MenuItem onClick={() => onCopyRoomInfo("name")}>
                  Copy Room Name
                </MenuItem>
                <MenuItem onClick={() => onCopyRoomInfo("link")}>
                  Copy Room Link
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLeaveRoom}>Leave Room</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Tooltip
                title={
                  !communityMember ||
                  communityMember.isBanned ||
                  communityMember.approvalStatus !== MEMBER_STATUS.APPROVED
                    ? "Join the community first before joining the room"
                    : "Click here to join this room"
                }
              >
                <Button onClick={handleJoinRoom}>Join</Button>
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
      <Divider light />
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setAlert };

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(RoomHeader)
);
