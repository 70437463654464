const USE_CASES = [
  {
    title: "Pitch Competitions",
    description:
      "Organize pitch competitions to find, mentor, and fund founders and startups from across the world.",
  },
  {
    title: "Hackathons",
    description:
      "Build real prototypes, identify top developers, and foster innovation through your organization.",
  },
  {
    title: "Open Innovation Challenges",
    description:
      "Accelerate your business by organizing challenges that bring together top industry professionals and students.",
  },
  {
    title: "Accelerator Applications",
    description:
      "Open applications for your accelerator or incubator program. Create a smooth process of reviewing potential investment opportunities.",
  },
  {
    title: "Internal Challenges",
    description:
      "Foster a culture of innovation throughout your organization to keep your organization moving forward.",
  },
  {
    title: "Recruiting Challenges",
    description:
      "Implement an innovative approach to attract, qualify, and hire candidates into your organization.",
  },
  {
    title: "Grants & Scholarships",
    description:
      "Build high-quality review workflows and custom forms for your grant and scholarship applications.",
  },
  {
    title: "Fellowships",
    description:
      "Create a cohesive experience for your fellowship program and leverage our advanced profiles in your review process.",
  },
  {
    title: "... More",
    description:
      "Pralent's powerful submission and review software can be utilized for many other programs including program admissions, board applications, and research opportunities.",
  },
];

const UseCases = () => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-red-600 uppercase tracking-wide">
            Use Cases
          </h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">
            What programs can you manage on Pralent?
          </p>
          <p className="mt-4 text-lg text-gray-500">
            Pralent has the features to power any program, yet simple enough for
            your team to use.
          </p>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-5 sm:grid-flow-row sm:gap-x-6 sm:gap-y-4 lg:gap-x-8">
            {USE_CASES &&
              USE_CASES.map((u, i) => {
                return (
                  <div key={i} className="flex">
                    <svg
                      className="flex-shrink-0 h-6 w-6 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <div className="ml-3">
                      <dt className="text-lg leading-6 font-semibold text-gray-900">
                        {u.title}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        {u.description}
                      </dd>
                    </div>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
