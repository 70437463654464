import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  box: {
    transition: "ease-in-out 0.2s",
    "&:hover": {
      boxShadow: theme.shadows[4],
      transition: "ease-in-out 0.2s",
    },
  },
}));

const Card = ({ children, ...other }) => {
  const classes = useStyles();
  return (
    <Box
      p={2}
      bgcolor="background.paper"
      borderRadius="borderRadius"
      width="100%"
      height="100%"
      className={classes.box}
      {...other}
    >
      {children}
    </Box>
  );
};

export default Card;
