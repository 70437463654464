import OrgAppBar from "../../shared/OrgAppBar";
// Material UI
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
// Components
import EmptyState from "../../shared/EmptyState";

const OrgNewsletters = () => {
  return (
    <>
      <OrgAppBar
        title={
          <>
            📧 Newsletters{" "}
            <Chip label={<Box fontWeight={600}>COMING SOON</Box>} />
          </>
        }
        subtitle="Send newsletters to your members"
      />
      <Container maxWidth="sm">
        <EmptyState
          title="No newsletters created yet"
          subtitle="Newsletters are perfect ways to connect with and engage your community members through email."
        />
      </Container>
    </>
  );
};

export default OrgNewsletters;
