import React from "react";
import PropTypes from "prop-types";
// React Router
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
// Components
import Spinner from "../../shared/Spinner";
import CommunityInfo from "./components/CommunityInfo";

const CommunityHome = ({ companyDetailsQuery }) => {
  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box>
          <Box>
            {companyDetails && (
              <CommunityInfo companyDetails={companyDetails} />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

CommunityHome.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

export default CommunityHome;
