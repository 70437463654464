import React from "react";
import PropTypes from "prop-types";
// React Router
// react query
import { useGetAllEventsForCommunity } from "../../hooks/event";
// Material UI
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import EmptyState from "../../shared/EmptyState";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Icons
import { IoTicketOutline, IoHourglassOutline } from "react-icons/io5";
// Components
import Spinner from "../../shared/Spinner";
import CommunityEventCard from "./components/CommunityEventCard";
// Utils

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const CommunityEvents = ({ companyDetailsQuery }) => {
  const classes = useStyles();

  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const {
    isLoading: isLoadingEvents,
    data: { data: communityEvents } = { data: null },
    isError: isErrorEvents,
    error: errorEvents,
  } = useGetAllEventsForCommunity(companyDetails);

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container maxWidth="xl">
          <Box py={2} px={{ xs: 0, md: 2 }}>
            <Box>
              {isErrorEvents && (
                <Alert severity="error">
                  {errorEvents && errorEvents.data && errorEvents.data.msg}
                </Alert>
              )}
              {isLoadingEvents ? (
                <Spinner />
              ) : (
                <Box>
                  {communityEvents ? (
                    <>
                      <Typography variant="h5" component="h2">
                        <Box
                          fontWeight={600}
                          display="flex"
                          alignItems="center"
                          style={{ gap: "8px" }}
                        >
                          <IoTicketOutline /> Upcoming Events
                        </Box>
                      </Typography>
                      <Box my={2}>
                        <Grid container spacing={2}>
                          {communityEvents.current &&
                          communityEvents.current.length > 0 ? (
                            communityEvents.current.map((event) => {
                              return (
                                <Grid item xs={12} width="100%" key={event.id}>
                                  <CommunityEventCard event={event} />
                                </Grid>
                              );
                            })
                          ) : (
                            <Grid item xs={12}>
                              <EmptyState
                                title="No upcoming events"
                                subtitle="There are no upcoming events at this time."
                                minHeight={300}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                      <Divider className={classes.divider} />
                      <Typography variant="h5" component="h2">
                        <Box
                          fontWeight={600}
                          display="flex"
                          alignItems="center"
                          style={{ gap: "8px" }}
                        >
                          <IoHourglassOutline /> Past Events
                        </Box>
                      </Typography>
                      <Box my={2}>
                        <Grid container spacing={2}>
                          {communityEvents.ended &&
                          communityEvents.ended.length > 0 ? (
                            communityEvents.ended.map((event) => {
                              return (
                                <Grid item xs={12} width="100%" key={event.id}>
                                  <CommunityEventCard event={event} />
                                </Grid>
                              );
                            })
                          ) : (
                            <Grid item xs={12}>
                              <EmptyState
                                title="No past events"
                                subtitle="There are no past events at this time."
                                minHeight={300}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <Box>
                      There are no events for the community at this time.
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

CommunityEvents.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

export default CommunityEvents;
