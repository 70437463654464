import {
  GET_POSTS,
  LOADING_POSTS,
  CLEAR_POSTS_KEYS,
  UPDATE_REACTION_POSTS,
} from "../actions/actionTypes";

const initialState = {
  posts: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_REACTION_POSTS: {
      const { id, updatedReaction } = payload;
      let initialPosts =
        state.posts && state.posts[id] && state.posts[id].posts;

      let postToUpdate = initialPosts[updatedReaction.postId];
      let reactions = postToUpdate.reactions || {};

      reactions[updatedReaction.emoji] = {
        ...updatedReaction,
      };

      postToUpdate = {
        ...postToUpdate,
        reactions: reactions,
      };

      initialPosts[updatedReaction.postId] = {
        ...postToUpdate,
      };

      return {
        ...state,
        posts: {
          ...state.posts,
          [id]: {
            ...state.posts[id],
            posts: initialPosts,
          },
        },
      };
    }
    case CLEAR_POSTS_KEYS: {
      const { id } = payload;
      return {
        ...state,
        posts: {
          ...state.posts,
          [id]: {
            ...state.posts[id],
            isLoading: false,
            isError: false,
            error: null,
            keys: [],
          },
        },
      };
    }
    case LOADING_POSTS: {
      const { id } = payload;
      return {
        ...state,
        posts: {
          ...state.posts,
          [id]: {
            ...state.posts[id],
            keys: [],
            isLoading: true,
            isError: false,
            error: null,
          },
        },
      };
    }
    case GET_POSTS: {
      const {
        isReply,
        roomId,
        postId,
        offset,
        limit,
        posts,
        count,
        lastPostOnBottom,
      } = payload;
      const id = isReply ? postId : roomId;

      let initialPosts =
        state.posts && state.posts[id] && state.posts[id].posts;
      let initialKeys = state.posts && state.posts[id] && state.posts[id].keys;

      initialPosts = {
        ...initialPosts,
        ...posts,
      };

      let updateKeys = [];
      if (offset !== 0) {
        updateKeys = [...initialKeys];
      }

      let postKeys = Object.keys(posts) || [];

      if (lastPostOnBottom) {
        postKeys = postKeys.reverse();
        updateKeys = [...postKeys, ...updateKeys];
      } else {
        updateKeys = [...updateKeys, ...postKeys];
      }

      return {
        ...state,
        posts: {
          ...state.posts,
          [id]: {
            ...state.posts[id],
            isLoading: false,
            count: count,
            posts: initialPosts,
            keys: updateKeys,
          },
        },
      };
    }

    default:
      return state;
  }
};
