import { handleServerError } from "./error";
import { setAlert } from "./alert";
import {
  ONBOARDING_CLEAR_CHALLENGE,
  ONBOARDING_FROM_CHALLENGE,
} from "./actionTypes";

export const setOnboardingChallengeDetails =
  (challengeDetails) => async (dispatch) => {
    try {
      await dispatch({
        type: ONBOARDING_FROM_CHALLENGE,
        payload: challengeDetails,
      });
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };
export const clearOnboardingChallengeDetails = () => async (dispatch) => {
  try {
    await dispatch({
      type: ONBOARDING_CLEAR_CHALLENGE,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
