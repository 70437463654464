import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { fetchAllPrizes, deletePrize } from "../../../redux/actions/challenge";
// Material UI
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
// Components
import ChallengePrizeDialog from "./ChallengePrizeDialog";
// Icons
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// Images

export const ChallengePrizes = ({
  challenge,
  prizes,
  fetchAllPrizes,
  deletePrize,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [editPrize, setEditPrize] = useState(null);

  useEffect(() => {
    async function fetchAllPrizesForChallenge() {
      const challengeId = challenge && challenge.id;
      await fetchAllPrizes(challengeId);
    }

    fetchAllPrizesForChallenge();
  }, [challenge]);

  const handleEditPrizeModal = (index) => {
    setEditPrize(prizes[index]);
    setOpenDialog(true);
  };

  const handleDeletePrizeModal = (index) => {
    const item = prizes[index];
    if (
      window.confirm("Are you sure you want to delete the prize: " + item.name)
    ) {
      deletePrize(item, challenge.id);
    }
  };

  return (
    <>
      <ChallengePrizeDialog
        prize={editPrize}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Edit</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Ranking Order</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Number of Awards</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prizes && prizes.length > 0 ? (
              prizes.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <IconButton
                        aria-label="edit-prize"
                        onClick={() => handleEditPrizeModal(index)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.ranking || "None"}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.numberAwarded}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete-prize"
                        onClick={() => handleDeletePrizeModal(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>There are no awards entered.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
// Ability to also add/edit a prize to this challenge

const mapStateToProps = (state) => ({
  prizes: state.challenge.prizes,
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {
  fetchAllPrizes,
  deletePrize,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePrizes);
