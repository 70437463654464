import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { setResetPasswordToken } from "../../redux/actions/auth";
import { setAlert } from "../../redux/actions/alert";
// Material UI
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// Components
import Button from "../../shared/Button";

const ForgotPassword = ({ setResetPasswordToken, setAlert }) => {
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    const target = e.target;
    const value =
      target.type === "checkbox" || target.type === "switch"
        ? target.checked
        : target.value;

    setEmail(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email !== "") {
      let re =
        /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|\\[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-x7f])+)\])$/;
      if (re.test(email)) {
        setResetPasswordToken(email);
        setEmail("");
      } else {
        setAlert("Please enter a valid email address", "error");
      }
    }
  };
  return (
    <Box>
      <Container maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Box width="100%">
            <Box textAlign="center" mb={3}>
              <Typography variant="h4" component="h1">
                <Box fontWeight={600}>Forgot Password</Box>
              </Typography>
            </Box>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormGroup>
                    <TextField
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                      required
                      placeholder="Enter your email"
                      fullWidth
                      size="medium"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Button size="large" type={"submit"} fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default connect(null, { setResetPasswordToken, setAlert })(
  ForgotPassword
);
