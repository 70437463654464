import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import {
  getGroupById,
  deleteGroupById,
  addEditGroup,
} from "../../../redux/actions/group";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// Icons
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
// components
import GroupTabs from "./GroupTabs";
import Button from "../../Button";
import LinkToHelp from "../../LinkToHelp";

const useStyles = makeStyles((theme) => ({
  List: {
    width: "100%",
    minHeight: "100vh",
    maxWidth: 350,
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(2),
  },
  ListItem: {
    borderRadius: theme.shape.borderRadius,
  },
  Note: {
    marginTop: theme.spacing(2),
    lineHeight: 1.2,
    fontSize: "0.75rem",
  },
}));

export const GroupDialogContent = ({
  challenge,
  currentRoundIndex,
  groups,
  getGroupById,
  deleteGroupById,
  addEditGroup,
}) => {
  const classes = useStyles();

  const [groupValue, setGroupValue] = useState(0);

  const handleGroupValue = (newValue) => {
    setGroupValue(newValue);
  };

  const fetchGroupForIndex = async (index = 0) => {
    const groupData = groups.data;
    if (groupData && 0 <= index && index < groupData.length) {
      const currentGroup = groupData[index];
      getGroupById(currentGroup.id);
    }
  };

  useEffect(() => {
    if (groupValue !== null) {
      fetchGroupForIndex(groupValue);
    }
  }, [groupValue]);

  const deleteGroup = async (groupId) => {
    await deleteGroupById(groupId);
    await handleGroupValue(null);
  };

  const handleAddNewGroup = async () => {
    const currentRound = challenge.round[currentRoundIndex];
    await addEditGroup(null, challenge.id, currentRound.id, "New Group");
    await handleGroupValue(0);
  };

  return (
    <>
      <Box display="flex" height="100%">
        <List className={classes.List}>
          <ListSubheader disableGutters disableSticky>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" component="div">
                <Box mr={1}>
                  Groups for {challenge.round[currentRoundIndex].name}{" "}
                </Box>
              </Typography>
              <LinkToHelp
                label="Groups 101"
                link="https://www.notion.so/Submissions-badd1ba1036149508dd1b2256faccbbe#76fe860c323347ee8f161c2b7aa10a18"
              />
            </Box>
          </ListSubheader>

          {groups.data &&
            groups.data.map((g, index) => {
              const labelId = `program-group-list-label-${g.name}`;
              return (
                <ListItem
                  className={classes.ListItem}
                  key={index}
                  role={undefined}
                  button
                  onClick={() => handleGroupValue(index)}
                >
                  <ListItemText
                    id={labelId}
                    primary={
                      <Box
                        fontWeight={index === groupValue ? 600 : 400}
                        lineHeight="1"
                        color={
                          index === groupValue ? "primary.main" : "text.primary"
                        }
                      >
                        {g.name}
                      </Box>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="Delete this group" placement="top">
                      <IconButton
                        edge="end"
                        aria-label="delete group"
                        onClick={() => deleteGroup(g.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          <ListItem mt={[1, 2]}>
            <Button onClick={handleAddNewGroup} startIcon={<AddIcon />}>
              Add New Group
            </Button>
          </ListItem>
        </List>
        <GroupTabs />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
  groups: state.group.groups,
});

const mapDispatchToProps = { getGroupById, deleteGroupById, addEditGroup };

export default connect(mapStateToProps, mapDispatchToProps)(GroupDialogContent);
