import React, { useState, useEffect, useCallback } from "react";
// React query
import { useJoinRoomByUserName } from "../../../hooks/room";
// Context
import {
  useRoomContext,
  useUserInRoomContext,
  useRoomMembersContext,
} from "./RoomContext";
// Material UI
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";
// Shared
import Button from "../../Button";
import CustomAvatar from "../../CustomAvatar";
import Spinner from "../../Spinner";
import ProfilePopover from "../../ProfilePopover";
import {
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";
import SearchIcon from "@material-ui/icons/Search";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
// utils
import { USER_ROOM_STATUS, ROOM_TYPE } from "../../../utils/globalValues";
import { Emoji } from "emoji-mart";
// Virtualization
import { Virtuoso } from "react-virtuoso";

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.shape.borderRadius,
  },
  memberPreviewAvatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    fontSize: theme.typography.body2.fontSize,
    borderRadius: 2,
    textAlign: "center",
  },
  membersButton: {
    padding: theme.spacing(0.5),
    borderStyle: "solid",
    borderWidth: theme.spacing(0.025),
    borderColor: theme.palette.action.disabled,
    borderRadius: 5,
  },
  roomMemberDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 75,
    textAlign: "left",
    width: "100%",
    transition: "0.2s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      transition: "0.2s",
    },
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
  },
  dialogPaper: {
    overflow: "auto",
    height: "100%",
  },
}));

const RoomMembers = () => {
  const classes = useStyles();

  const { isLoadingRoom, room, isErrorRoom, errorRoom } = useRoomContext();

  const { isLoadingUserInRoom, userInRoom } = useUserInRoomContext();

  const {
    isLoadingRoomMembers,
    roomMembers,
    isErrorRoomMembers,
    errorRoomMembers,
  } = useRoomMembersContext();

  let joinRoomByUserNameMutation = useJoinRoomByUserName();

  const [roomMembersKeys, setRoomMembersKeys] = useState([]);
  const [memberQuery, setMemberQuery] = useState("");

  useEffect(() => {
    let newKeys = Object.keys(roomMembers || []);
    if (memberQuery) {
      let lowerCaseMemberQuery = memberQuery.toLowerCase();

      const queriedKeys = Object.keys(roomMembers).filter((key) => {
        const member = roomMembers[key];
        if (!member || !member.profile) return false;
        const profile = member.profile;

        const name = `${profile.firstName} ${profile.lastName || ""}`;

        return name.toLowerCase().indexOf(lowerCaseMemberQuery) !== -1;
      });

      newKeys = [...queriedKeys];
    }

    setRoomMembersKeys(newKeys);
    return () => {
      setRoomMembersKeys([]);
    };
  }, [roomMembers, memberQuery]);

  const [showMembers, setShowMembers] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowMembers(open);
  };

  const disabled =
    room.needApprovalToJoin &&
    (!userInRoom || userInRoom.approvalStatus !== USER_ROOM_STATUS.APPROVED);

  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [addNewMemberUserName, setAddNewMemberUserName] = useState("");

  const handleOpenAddMemberDialog = () => {
    setShowMembers(false);
    setOpenAddMemberDialog(true);
  };

  const handleCloseAddMemberDialog = () => {
    setOpenAddMemberDialog(false);
  };

  const onChangeAddNewMemberUserName = (e) => {
    setAddNewMemberUserName(e.target.value);
  };

  const addNewMember = (e) => {
    e.preventDefault();
    joinRoomByUserNameMutation.mutate({
      userName: addNewMemberUserName,
      roomId: room.id,
    });
  };

  return (
    <>
      {isLoadingRoomMembers ? (
        <Spinner />
      ) : isErrorRoomMembers ? (
        <Alert severity="error">
          {errorRoomMembers &&
            errorRoomMembers.data &&
            errorRoomMembers.data.msg}
        </Alert>
      ) : (
        <>
          <Tooltip
            title={
              <Box p={1}>
                <Typography variant="body2" component="div">
                  <Box lineHeight={1.2} fontWeight={600}>
                    {!disabled
                      ? "View all the members of this room"
                      : "You need to be an approved member of this room to view all of the members."}
                  </Box>
                </Typography>
                {!disabled && roomMembers && roomMembersKeys.length > 3 && (
                  <Typography variant="body2" component="div">
                    <Box lineHeight={1.2}>
                      Includes{" "}
                      {(roomMembersKeys || []).slice(0, 3).map((key) => {
                        const member = roomMembers[key];
                        if (!member || !member.user) {
                          return null;
                        }

                        const user = member.user;
                        const profile = member.profile;

                        let name;
                        if (profile) {
                          name = `${profile.firstName || ""} ${
                            profile.lastName || ""
                          }`;
                        } else if (user) {
                          name = `${user.userName || ""}`;
                        }

                        return `${name}, `;
                      })}
                      and more.
                    </Box>
                  </Typography>
                )}
              </Box>
            }
          >
            <ButtonBase
              onClick={toggleDrawer(true)}
              className={classes.membersButton}
            >
              {(Object.keys(roomMembers) || [])
                .slice(0, 3)
                .map((key, index) => {
                  const member = roomMembers[key];
                  if (!member.user) {
                    return null;
                  }

                  const user = member.user;

                  return (
                    <Box key={index} mr={-0.5}>
                      <CustomAvatar
                        src={user.avatar}
                        fallbackText={user.userName}
                        alt={`${user.userName}-image`}
                        className={classes.memberPreviewAvatar}
                      />
                    </Box>
                  );
                })}
              {Object.keys(roomMembers) && (
                <Box pl={1.5} pr={0.75}>
                  <Typography variant="body1" component="div">
                    <Box fontWeight={600}>
                      {Object.keys(roomMembers).length}
                    </Box>
                  </Typography>
                </Box>
              )}
            </ButtonBase>
          </Tooltip>

          <Dialog
            open={openAddMemberDialog}
            onClose={handleCloseAddMemberDialog}
            fullWidth={true}
            maxWidth="sm"
          >
            <form onSubmit={addNewMember}>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                px={{ xs: 1, sm: 4 }}
                py={2}
                style={{ gap: "24px" }}
              >
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="h5" component="div">
                    <Box fontWeight={600}>Add people</Box>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    <Box
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                      style={{ gap: "12px" }}
                    >
                      {room.type === ROOM_TYPE.CHAT ? (
                        <HiOutlineChat />
                      ) : room.type === ROOM_TYPE.FORUM ? (
                        <HiOutlinePencilAlt />
                      ) : room.type == ROOM_TYPE.BOARD ? (
                        <HiOutlineViewGrid />
                      ) : (
                        <HiHashtag />
                      )}
                      {room.name}{" "}
                      {room.emoji && <Emoji emoji={room.emoji} size={20} />}
                    </Box>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: "8px" }}
                >
                  {joinRoomByUserNameMutation.isError ? (
                    <Alert severity="error">
                      {joinRoomByUserNameMutation.error &&
                        joinRoomByUserNameMutation.error.data &&
                        joinRoomByUserNameMutation.error.data.msg}
                    </Alert>
                  ) : null}

                  {joinRoomByUserNameMutation.isSuccess ? (
                    <Alert severity="success">
                      Successfully added new member to this room!
                    </Alert>
                  ) : null}

                  <TextField
                    autoFocus
                    id="name"
                    type="text"
                    fullWidth
                    placeholder="Enter a username"
                    size="medium"
                    required
                    onChange={onChangeAddNewMemberUserName}
                  />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ gap: "4px" }}
                >
                  <Button
                    onClick={handleCloseAddMemberDialog}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Submit</Button>
                </Box>
              </Box>
            </form>
          </Dialog>
          <Dialog
            open={showMembers}
            onClose={toggleDrawer(false)}
            scroll="paper"
            classes={{
              paper: classes.dialogPaper,
            }}
            fullWidth={true}
            maxWidth="sm"
          >
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Box px={{ xs: 1, sm: 4 }} py={2}>
                <Typography variant="h5" component="div">
                  <Box
                    fontWeight={600}
                    display="flex"
                    alignItems="center"
                    style={{ gap: "12px" }}
                  >
                    {room.type === ROOM_TYPE.CHAT ? (
                      <HiOutlineChat />
                    ) : room.type === ROOM_TYPE.FORUM ? (
                      <HiOutlinePencilAlt />
                    ) : room.type == ROOM_TYPE.BOARD ? (
                      <HiOutlineViewGrid />
                    ) : (
                      <HiHashtag />
                    )}
                    {room.name}{" "}
                    {room.emoji && <Emoji emoji={room.emoji} size={20} />}
                  </Box>
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  color="textSecondary"
                >
                  <Box fontWeight={600}>
                    Members ({Object.keys(roomMembers).length || 0})
                  </Box>
                </Typography>
              </Box>

              {!disabled ? (
                <>
                  {isLoadingRoomMembers ? (
                    <Spinner />
                  ) : (
                    <>
                      <Box px={{ xs: 1, sm: 4 }} pb={2}>
                        <FormGroup>
                          <TextField
                            id="memberQuery"
                            name="memberQuery"
                            value={memberQuery}
                            onChange={(e) => setMemberQuery(e.target.value)}
                            placeholder={`Find members in ${room.name}`}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormGroup>
                      </Box>
                      {!room.belongsToCommunity && (
                        <ButtonBase
                          className={classes.roomMemberDiv}
                          onClick={handleOpenAddMemberDialog}
                        >
                          <Box display="flex" alignItems="center">
                            <Box
                              borderRadius="borderRadius"
                              p={1.75}
                              bgcolor="primary.main"
                              m="auto"
                            >
                              <PersonAddOutlinedIcon />
                            </Box>

                            <Box ml={2}>
                              <Typography variant="h6" component="div">
                                <Box fontWeight={600}>Add people</Box>
                              </Typography>
                            </Box>
                          </Box>
                        </ButtonBase>
                      )}
                      <Box height="100%" width="100%" flex={1}>
                        <Virtuoso
                          data={roomMembersKeys}
                          itemContent={(index) => {
                            if (!roomMembersKeys)
                              return <Box className={classes.roomMemberDiv} />;
                            const member =
                              roomMembers[roomMembersKeys[index]] || {};

                            if (
                              member.approvalStatus !==
                              USER_ROOM_STATUS.APPROVED
                            ) {
                              return null;
                            }

                            if (!member.user || !member.profile) {
                              return null;
                            }

                            const user = member.user;
                            const profile = member.profile;
                            const education = profile.education;

                            return (
                              <div>
                                <ProfilePopover userId={user.id} key={index}>
                                  <ButtonBase className={classes.roomMemberDiv}>
                                    <Box display="flex" alignItems="center">
                                      <CustomAvatar
                                        src={user.avatar}
                                        fallbackText={profile.firstName}
                                        alt={`${profile.firstName}-image`}
                                        className={classes.userAvatar}
                                      />
                                      <Box ml={2}>
                                        <Typography
                                          variant="h6"
                                          component="div"
                                        >
                                          <Box fontWeight={600}>
                                            {profile.firstName}{" "}
                                            {profile.lastName}
                                          </Box>
                                        </Typography>

                                        {education && education.length > 0 && (
                                          <>
                                            <Typography
                                              variant="body2"
                                              component="div"
                                              color="textSecondary"
                                            >
                                              <Box lineHeight={1.2}>
                                                {education[0].school},{" "}
                                                {new Date(
                                                  education[0].completionDate
                                                ).getFullYear()}
                                              </Box>
                                            </Typography>

                                            <Typography
                                              variant="body2"
                                              component="div"
                                              color="textSecondary"
                                            >
                                              <Box lineHeight={1.2}>
                                                {education[0].degree} -{" "}
                                                {education[0].fieldOfStudy}
                                              </Box>
                                            </Typography>
                                          </>
                                        )}
                                      </Box>
                                    </Box>
                                  </ButtonBase>
                                </ProfilePopover>
                              </div>
                            );
                          }}
                        />
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Box px={2}>
                    <Typography variant="body1" component="div">
                      You need to be an approved member of this room to view all
                      of the members.
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
};

export default RoomMembers;
