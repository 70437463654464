import React from "react";
// Redux
import { connect } from "react-redux";
// React router
import { Redirect } from "react-router-dom";
// Components
import Hero from "./components/Hero";
import Features from "./components/Features";
import UseCases from "./components/UseCases";
import CallToAction from "./components/CallToAction";
import Product from "./components/Product";
import Socials from "./components/Socials";

const Home = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to={`/dashboard`} />;
  }
  return (
    <div>
      <Hero />
      <UseCases />
      <Features />
      {/* <Product /> */}
      <CallToAction />
      <Socials />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
