import cmu from "../../assets/images/cmu-wordmark-stacked.png";
import richmond from "../../assets/images/university-of-richmond.png";
import penn from "../../assets/images/UniversityofPennsylvania.png";
import princeton from "../../assets/images/princeton.png";
import drexel from "../../assets/images/drexel.png";
import georgiatech from "../../assets/images/georgia-tech.png";
import nyu from "../../assets/images/nyu.png";
import purdue from "../../assets/images/purdue_logo.png";
import notre_dame from "../../assets/images/university-notre-dame.png";

import { IoIosMail, IoIosChatbubbles } from "react-icons/io";

const LOGOS = [
  {
    name: "Carnegie Mellon Univeristy",
    image: cmu,
  },
  {
    name: "University of Notre Dame",
    image: notre_dame,
  },

  {
    name: "Richmond College",
    image: richmond,
  },
  {
    name: "Purdue Univeristy",
    image: purdue,
  },
];

const RequestDemo = () => {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-6">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-3 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
              Schedule your demo
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Find a time that works for you, and one of our experts will
              personally walk you through what Pralent can do for your
              organization.
            </p>
            <h5 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl mt-3">
              Trusted by the best institutions.
            </h5>
            <div className="grid grid-cols-2 gap-0.5 md:gap-3 lg:gap-6">
              {LOGOS &&
                LOGOS.map((l, i) => {
                  return (
                    <div
                      key={i}
                      className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
                    >
                      <img
                        className="max-h-12 absolute object-cover"
                        src={l.image}
                        alt={l.name}
                      />
                    </div>
                  );
                })}
            </div>
            <dl className="mt-8 md:mt-12 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>4765 Forbes Ave </p>
                  <p className="mt-1">Pittsburgh, PA 15213</p>
                </dd>
              </div>

              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex items-center">
                  <IoIosMail />

                  <a href="mailto:sales@pralent.com" className="ml-3">
                    sales@pralent.com
                  </a>
                </dd>
              </div>
              <div
                className="mt-3 cursor-pointer"
                onClick={() => global.$crisp.push(["do", "chat:open"])}
              >
                <dt className="sr-only">Chat</dt>
                <dd className="flex items-center">
                  <IoIosChatbubbles />

                  <span className="ml-3">Chat with us</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none min-h-screen">
            <div
              class="calendly-inline-widget"
              data-url="https://calendly.com/pralent/30-min"
              style="min-width:320px;height:630px;"
            ></div>
            <script
              type="text/javascript"
              src="https://assets.calendly.com/assets/external/widget.js"
              async
            ></script>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDemo;
