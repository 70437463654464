import React from "react";
// React Router
// React Query
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
// Icons
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
import JoinCommunity from "../../../shared/JoinCommunity";
// Utils
import ReactImageFallback from "react-image-fallback";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    fontSize: "60px",
    transform: "translateY(-50%)",
    borderRadius: 99999,
    boxShadow: theme.shadows[4],
    objectFit: "cover",
    borderColor: theme.palette.common.white,
    borderWidth: theme.spacing(1),
  },
  headerImage: {
    width: "100%",
    maxHeight: 600,
    objectFit: "cover",
    borderRadius: 20,
  },
  TooltipSocialIcons: {
    fontSize: theme.typography.h3.fontSize,
    marginRight: theme.spacing(1.5),
    color: theme.palette.text.primary,
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

const CommmunityInfo = ({ companyDetails }) => {
  const classes = useStyles();

  return (
    <Box pb={4}>
      <ReactImageFallback
        title={companyDetails.companyName}
        src={
          companyDetails.headerImageUrl ||
          "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
        }
        fallbackImage={
          companyDetails.headerImageUrl ||
          "https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
        }
        alt={`${companyDetails.companyName}-header-image`}
        className={classes.headerImage}
      />

      <Container maxWidth="md">
        <CustomAvatar
          src={companyDetails.companyLogoPath}
          alt={companyDetails.companyName}
          fallbackText={companyDetails.companyName}
          className={classes.logo}
        />
        <Box style={{ marginTop: -45 }}>
          <Box>
            <Typography variant="h3" component="h1" gutterBottom>
              <Box fontWeight={700}>{companyDetails.companyName}</Box>
            </Typography>
          </Box>
          <Typography variant="body1" component="div" color="textSecondary">
            <Box whiteSpace="pre-line" lineHeight={1.2} fontWeight={400}>
              {companyDetails.companyDescription}
            </Box>
          </Typography>
          <Box>
            <Box my={{ xs: 2, md: 4 }}>
              <JoinCommunity companyDetails={companyDetails} />
            </Box>

            <Box display="flex" flexDirection="row" mt={2}>
              {companyDetails.companyWebsiteUrl && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.companyWebsiteUrl}
                >
                  <LanguageIcon className={classes.TooltipSocialIcons} />
                </Link>
              )}
              {companyDetails.linkedinUrl && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.linkedinUrl}
                >
                  <LinkedInIcon className={classes.TooltipSocialIcons} />
                </Link>
              )}
              {companyDetails.twitterUrl && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.twitterUrl}
                >
                  <TwitterIcon className={classes.TooltipSocialIcons} />
                </Link>
              )}
              {companyDetails.facebookUrl && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.facebookUrl}
                >
                  <FacebookIcon className={classes.TooltipSocialIcons} />
                </Link>
              )}
              {companyDetails.instagramUrl && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={companyDetails.instagramUrl}
                >
                  <InstagramIcon className={classes.TooltipSocialIcons} />
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CommmunityInfo;
