import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// React router
import { Link } from "react-router-dom";
import { Route, useRouteMatch, useLocation } from "react-router";
// Redux
import { connect } from "react-redux";
import {
  setCurrentChallengeRoundIndex,
  setRoundAsCurrent,
} from "../../redux/actions/challenge";
// Material UI
import AppBar from "@material-ui/core/AppBar";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// Components
import OrgChallengeAppBar from "../../shared/OrgChallengeAppBar";
import OrgAppBar from "../../shared/OrgAppBar";
import SubmissionView from "./components/SubmissionView";
import ResultsView from "./components/ResultsView";
import JudgesView from "./components/JudgesView";
import CriteriasView from "./components/CriteriasView";
import FormBuilderView from "./components/FormBuilderView";
import SettingsView from "./components/SettingsView";
import AddEditChallengeRound from "./components/AddEditChallengeRound";
import CustomSwitch from "../../shared/CustomSwitch";
import Button from "../../shared/Button";
// icons
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Utils
import PerfectScrollbar from "react-perfect-scrollbar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`submissions-tabpanel-${index}`}
      aria-labelledby={`submissions-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `submissions-tab-${index}`,
    "aria-controls": `submissions-tabpanel-${index}`,
  };
}

const OrgChallengeRound = ({
  challenge,
  currentRoundIndex,
  setCurrentChallengeRoundIndex,
  setRoundAsCurrent,
}) => {
  let { url } = useRouteMatch();
  const location = useLocation();

  const [activeRoundId, setActiveRoundId] = useState(null);

  useEffect(() => {
    if (challenge && challenge.currentRoundId)
      setActiveRoundId(challenge.currentRoundId);
  }, [challenge]);

  const onChangeCurrentRoundIndex = async (currentIndex) => {
    await setCurrentChallengeRoundIndex(currentIndex);
  };

  const handleSetActiveRoundChange = () => {
    const round = challenge.round[currentRoundIndex];
    if (
      challenge.round[currentRoundIndex].id !== activeRoundId &&
      window.confirm(
        `Are you sure you want to make ${round.name} the active round?`
      )
    ) {
      setRoundAsCurrent(round);
    }
  };

  const ChallengeRoundActions = () => {
    return (
      <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
        <Button component={Link} to={`${url}/new`}>
          Add New Round
        </Button>
        <Tooltip
          title={
            challenge.round[currentRoundIndex].id === activeRoundId
              ? `${challenge.round[currentRoundIndex].name} is the active round`
              : `Click here to make ${challenge.round[currentRoundIndex].name} the active round`
          }
        >
          <Button
            onClick={handleSetActiveRoundChange}
            variant={"text"}
            startIcon={
              challenge.round[currentRoundIndex].id === activeRoundId ? (
                <CheckCircleIcon />
              ) : (
                <CheckCircleOutlineOutlinedIcon />
              )
            }
          >
            {challenge.round[currentRoundIndex].id === activeRoundId
              ? "Active"
              : "Activate"}
          </Button>
        </Tooltip>
        <Select
          margin="dense"
          id="roundIndx"
          variant="outlined"
          value={currentRoundIndex}
          name="currentRoundIndex"
          onChange={(e) => onChangeCurrentRoundIndex(e.target.value)}
        >
          {challenge &&
            challenge.round &&
            challenge.round.length > 0 &&
            challenge.round.map((round, index) => {
              return (
                <MenuItem value={index} key={index}>
                  {round.name}
                </MenuItem>
              );
            })}
        </Select>
      </Box>
    );
  };

  return (
    <>
      <Box height="100%" margin="auto">
        <OrgChallengeAppBar />
        <Box>
          <OrgAppBar
            title="Round"
            subtitle="Manage everything from submissions to reviewing for each round"
            actions={<ChallengeRoundActions />}
          />
          <PerfectScrollbar>
            {location.pathname !== `${url}/new` && (
              <AppBar position="static">
                <Tabs
                  // value={tabValue}
                  value={location.pathname}
                  // onChange={handleTabChange}
                  aria-label="round tabs"
                >
                  <Tab
                    label="Edit"
                    component={Link}
                    value={`${url}`}
                    to={`${url}`}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Submission Form"
                    component={Link}
                    value={`${url}/form`}
                    to={`${url}/form`}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Submissions"
                    component={Link}
                    value={`${url}/submissions`}
                    to={`${url}/submissions`}
                    {...a11yProps(2)}
                  />

                  <Tab
                    label="Reviewers"
                    component={Link}
                    value={`${url}/reviewers`}
                    to={`${url}/reviewers`}
                    {...a11yProps(3)}
                  />
                  <Tab
                    label="Criterias"
                    component={Link}
                    value={`${url}/criterias`}
                    to={`${url}/criterias`}
                    {...a11yProps(4)}
                  />
                  <Tab
                    label="Results"
                    component={Link}
                    value={`${url}/results`}
                    to={`${url}/results`}
                    {...a11yProps(5)}
                  />
                  <Tab
                    label="Settings"
                    component={Link}
                    value={`${url}/settings`}
                    to={`${url}/settings`}
                    {...a11yProps(6)}
                  />
                </Tabs>
              </AppBar>
            )}

            <CustomSwitch>
              <Route exact path={`/org/:orgId/programs/:slug/round/form`}>
                <FormBuilderView />
              </Route>
              <Route
                exact
                path={`/org/:orgId/programs/:slug/round/submissions`}
              >
                <SubmissionView />
              </Route>
              <Route exact path={`/org/:orgId/programs/:slug/round/results`}>
                <ResultsView />
              </Route>
              <Route exact path={`/org/:orgId/programs/:slug/round/reviewers`}>
                <JudgesView />
              </Route>
              <Route exact path={`/org/:orgId/programs/:slug/round/criterias`}>
                <CriteriasView />
              </Route>
              <Route exact exact path={`/org/:orgId/programs/:slug/round`}>
                <AddEditChallengeRound
                  round={challenge.round[currentRoundIndex]}
                />
              </Route>
              <Route exact path={`/org/:orgId/programs/:slug/round/new`}>
                <AddEditChallengeRound round={null} />
              </Route>
              <Route exact path={`/org/:orgId/programs/:slug/round/settings`}>
                <SettingsView />
              </Route>
            </CustomSwitch>
          </PerfectScrollbar>
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = { setCurrentChallengeRoundIndex, setRoundAsCurrent };

export default connect(mapStateToProps, mapDispatchToProps)(OrgChallengeRound);
