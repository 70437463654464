import React from "react";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Colors
import blueGrey from "@material-ui/core/colors/blueGrey";
// Components
import SocialMediaShare from "../../../shared/SocialMediaShare";

export default function EventSocialMediaShare({ event }) {
  return (
    <Box>
      <SocialMediaShare
        url={`https://www.pralent.com/c/${
          event.company && event.company.communitySlug
        }/events/${event.slug}`}
        title={event.title}
        popover={false}
      />
    </Box>
  );
}
