import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
// Material UI
import { makeStyles, withStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
// Icons
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
// Date
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isBefore from "date-fns/isBefore";
// Components
import ChallengeRegister from "./ChallengeRegister";
import SocialMediaShare from "../../../shared/SocialMediaShare";
import ChallengeHeaderImagePath from "../../../shared/ChallengeHeaderImagePath";
import Button from "../../../shared/Button";
// Utils
import { SUBMITTED_STATUS_TYPE_ID } from "../../../utils/globalValues";

const CompanyImageAvatarTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: "500px",
    boxShadow: theme.shadows[8],
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  CompanyImageAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.h3.fontSize,
    cursor: "pointer",
  },
  TooltipSocialIcons: {
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1),
  },
  ChallengeHeaderChip: {
    textTransform: "uppercase",
    margin: theme.spacing(0.5),
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 600,
  },
}));

const ChallengeHeader = ({ challenge, currentUserStatus, myTeam }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [currentRound, setCurrentRound] = useState(null);

  useEffect(() => {
    if (challenge && challenge.round && challenge.round.length > 0) {
      const rounds = challenge.round;
      const currentRoundId = challenge.currentRoundId;
      const found = rounds.find((elem) => elem.id === currentRoundId);
      if (found) {
        setCurrentRound(found);
      }
    }
  }, [challenge]);

  const submissionDateInfo = () => {
    if (
      currentUserStatus &&
      !currentUserStatus.isRegistered &&
      challenge &&
      challenge.registrationDeadlineDatetime &&
      isBefore(new Date(), new Date(challenge.registrationDeadlineDatetime))
    ) {
      return `Registration: ${formatDistanceToNow(
        new Date(challenge.registrationDeadlineDatetime)
      )} left`;
    }
    if (currentRound && currentRound.endDatetime) {
      if (isBefore(new Date(), new Date(currentRound.endDatetime))) {
        return `${formatDistanceToNow(
          new Date(currentRound.endDatetime)
        )} left`;
      } else if (
        currentRound.lateSubmissionAllowed &&
        isBefore(new Date(), new Date(currentRound.lateSubmissionDateTime))
      ) {
        return `Accepting Late: ${formatDistanceToNow(
          new Date(currentRound.lateSubmissionDateTime)
        )} left`;
      } else {
        return `Ended: ${formatDistanceToNow(
          new Date(currentRound.endDatetime)
        )} ago`;
      }
    } else {
      return "No Deadline";
    }
  };
  return (
    <>
      {myTeam && myTeam.eliminatedFlag ? (
        <Box width="100%" mb={2}>
          <Alert variant="standard" severity="error">
            <Typography variant="body1" component="div">
              <Box fontWeight={600}>Eliminated</Box>
            </Typography>
            <Typography variant="body2">
              Your team has been marked as eliminated by the organizers of this
              program. Please contact support or the organizers if this is a
              problem.
            </Typography>
          </Alert>
        </Box>
      ) : (
        challenge &&
        currentUserStatus &&
        currentUserStatus.submittedStatusTypeId ===
          SUBMITTED_STATUS_TYPE_ID.SUBMITTED && (
          <Box width="100%" mb={2}>
            <Alert variant="standard" severity="success">
              <Typography variant="body1" component="div">
                <Box fontWeight={600}>Submitted!</Box>
              </Typography>
              <Typography variant="body2">
                There is a submission for you for this program. If this is a
                problem or an issue, please contact support.
              </Typography>
            </Alert>
          </Box>
        )
      )}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ChallengeHeaderImagePath challenge={challenge} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box display="flex" flexDirection="column" style={{ gap: "12px" }}>
            <Typography variant="h3" component="h1">
              <Box fontWeight={700} lineHeight={1}>
                {challenge.title}
              </Box>
            </Typography>
            <Box display="flex" alignItems="center">
              <CompanyImageAvatarTooltip
                interactive
                title={
                  <Box textAlign="left">
                    {challenge.company && (
                      <>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="body1" component="div">
                            <Box fontWeight={600}>
                              {challenge.company.companyName}
                            </Box>
                          </Typography>
                          <Typography
                            variant="body2"
                            // color="textSecondary"
                            gutterBottom
                          >
                            {challenge.company.location &&
                              `${challenge.company.location}`}
                            {challenge.company.companyStage &&
                              ` | ${challenge.company.companyStage}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ lineHeight: 1.3 }}
                          >
                            {challenge.company.companyDescription}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" mt={2}>
                          {challenge.company.companyWebsiteUrl && (
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              href={challenge.company.companyWebsiteUrl}
                            >
                              <LanguageIcon
                                className={classes.TooltipSocialIcons}
                              />
                            </Link>
                          )}
                          {challenge.company.linkedinUrl && (
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              href={challenge.company.linkedinUrl}
                            >
                              <LinkedInIcon
                                className={classes.TooltipSocialIcons}
                              />
                            </Link>
                          )}
                          {challenge.company.twitterUrl && (
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              href={challenge.company.twitterUrl}
                            >
                              <TwitterIcon
                                className={classes.TooltipSocialIcons}
                              />
                            </Link>
                          )}
                          {challenge.company.facebookUrl && (
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              href={challenge.company.facebookUrl}
                            >
                              <FacebookIcon
                                className={classes.TooltipSocialIcons}
                              />
                            </Link>
                          )}
                          {challenge.company.instagramUrl && (
                            <Link
                              target="_blank"
                              rel="noopener noreferrer"
                              href={challenge.company.instagramUrl}
                            >
                              <InstagramIcon
                                className={classes.TooltipSocialIcons}
                              />
                            </Link>
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                }
                placement="bottom-start"
              >
                <Box display={{ xs: "none", md: "block" }}>
                  <Avatar
                    variant="rounded"
                    src={challenge.company && challenge.company.companyLogoPath}
                    alt={`${
                      (challenge.company && challenge.company.companyName) ||
                      "pralent"
                    }-logo`}
                    className={classes.CompanyImageAvatar}
                  />
                </Box>
              </CompanyImageAvatarTooltip>
              <Typography variant="h6" color="textSecondary" component="div">
                <Box lineHeight={1.2}>
                  Hosted by {challenge.company.companyName}
                </Box>
              </Typography>
            </Box>
            <Typography variant="h6" compnent="div" color="textSecondary">
              <Box mb={1} lineHeight={1.2}>
                {challenge.tagLine}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Chip
              color="primary"
              className={classes.ChallengeHeaderChip}
              label={
                <>
                  {currentRound && currentRound.name}, {submissionDateInfo()}
                </>
              }
            />
            <Chip
              color="default"
              className={classes.ChallengeHeaderChip}
              label={
                challenge.totalPrizeValue
                  ? challenge.totalPrizeValue
                  : "Knowledge"
              }
            />
            <Chip
              color="default"
              className={classes.ChallengeHeaderChip}
              label={
                challenge.categoryTypes
                  ? challenge.categoryTypes
                  : "General Program"
              }
            />
            <Chip
              color="default"
              className={classes.ChallengeHeaderChip}
              label={challenge.location ? challenge.location : "Online"}
            />
            <Chip
              color="default"
              className={classes.ChallengeHeaderChip}
              label={challenge.maxPeopleTeam === 1 ? "Individual" : "Team"}
            />
          </Box>
          {challenge &&
            currentUserStatus &&
            currentUserStatus.isRegistered !== undefined && (
              <Box mt={2}>
                <ChallengeRegister />
              </Box>
            )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  currentUserStatus: state.challenge.currentUserStatus,
  myTeam: state.challenge.myTeam,
  myOrganizations: state.profile.myOrganizations,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeHeader);
