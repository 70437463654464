import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

import { SUBMITTED_STATUS_TYPE_ID } from "./globalValues";

// export const isAfterRegistrationDate = (challenge) => {
//   if (challenge && challenge.round && challenge.round.length > 0) {
//     let today = new Date();
//     let registrationDate = new Date(challenge.round[0].endDatetime);
//     return isAfter(today, registrationDate);
//   } else {
//     return null;
//   }
// };

// Get the current round index of the challenge, which challenge round is active
export const getCurrentRound = (challenge) => {
  if (
    challenge &&
    challenge.currentRoundId &&
    challenge.round &&
    challenge.round.length > 0
  ) {
    let currentRoundIndx = challenge.round.findIndex(
      (elem) => elem.id === challenge.currentRoundId
    );
    return challenge.round[currentRoundIndx];
  } else {
    return null;
  }
};

export const registrationDetails = (challenge) => {
  if (challenge && challenge.registrationDeadlineDatetime) {
    return isBefore(
      new Date(),
      new Date(challenge.registrationDeadlineDatetime)
    );
  } else {
    return null;
  }
};

// Use the current round index to determine if a "Submission" button needs to be displayed
// for the signed in user.
export const submissionDetails = (challenge) => {
  if (challenge && challenge.round && challenge.round.length > 0) {
    // Get the current round
    const currentRound = getCurrentRound(challenge);

    let today = new Date();
    let startDatetime = new Date(currentRound.startDatetime);
    if (isBefore(today, startDatetime)) return -1;
    if (currentRound.endDatetime === null) return 0;
    let endDatetime = new Date(currentRound.endDatetime);
    let lateSubmissionAllowed = currentRound.lateSubmissionAllowed;
    let lateSubmissionDateTime = new Date(
      currentRound.lateSubmissionDateTime && currentRound.lateSubmissionDateTime
    );
    if (lateSubmissionAllowed && lateSubmissionDateTime) {
      // If late submission is enabled
      if (isAfter(today, lateSubmissionDateTime)) {
        return 1;
      } else {
        return 0;
      }
    } else {
      // If late submission is not allowed, just check the enddatetime
      if (isAfter(today, endDatetime)) return 1;
    }
    return 0;
  }
};

// export const challengeDates = (challenge) => {
//   let afterRegistration = isAfterRegistrationDate(challenge);
//   let submissionRelation = submissionDetails(challenge);
//   return {
//     afterRegistration,
//     submissionRelation,
//   };
// };

export const returnTextAction = (
  isRegistered,
  submittedStatusTypeId,
  eliminatedFlag,
  submissionRelation,
  isBeforeRegistration,
  isActive,
  isAuthenticated,
  userTypeId,
  round,
  currentRound
) => {
  if (
    !isActive ||
    isRegistered === null ||
    isRegistered === undefined ||
    submissionRelation === null
  ) {
    return {
      text: null,
      action: null,
      leave: null,
    };
  }

  // If user is not logged in
  if (!isAuthenticated) {
    if (
      (isBeforeRegistration === null && submissionRelation < 1) ||
      isBeforeRegistration
    ) {
      return {
        text: "Join Program",
        action: "SEND_TO_LOGIN",
        leave: false,
      };
    } else {
      return {
        text: "Closed",
        action: null,
        leave: false,
        disabled: true,
      };
    }
  }

  if (eliminatedFlag) {
    return {
      text: null,
      action: null,
      leave: null,
    };
  }

  if (!isRegistered) {
    if (
      (isBeforeRegistration === null && submissionRelation < 1) ||
      isBeforeRegistration
    ) {
      return {
        text: "Join Program",
        action: "OPEN_REGISTRATION_MODAL",
        leave: false,
      };
    } else {
      return {
        text: "Closed",
        action: null,
        leave: false,
        disabled: true,
      };
    }
  }

  // User is registered for challenge and has submitted
  if (submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SUBMITTED) {
    return {
      text: "Submitted",
      action: null,
      leave: false,
      disabled: true,
    };
  }
  // if the user is registered
  if (submissionRelation === -1) {
    return {
      text: `Submissions Open in ${formatDistanceToNow(
        new Date(currentRound.startDatetime)
      )}`,
      action: null,
      leave: true,
      disabled: true,
    };
  } else if (submissionRelation === 0) {
    let text = `Start Submission`;

    if (submittedStatusTypeId === SUBMITTED_STATUS_TYPE_ID.SAVED) {
      text = `Edit Submission`;
    }

    return {
      text: text,
      action: "SUBMIT_CHALLENGE",
      leave: true,
    };
  }

  return {
    text: "Submission Closed",
    action: null,
    leave: false,
    disabled: true,
  };
};
