import React from "react";
// Redux
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/auth";
// React router
import { Link, useLocation } from "react-router-dom";
// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MuiLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
// Icons
import { IoChatbubblesOutline, IoNotificationsOutline } from "react-icons/io5";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
import InstallPWA from "../../../shared/InstallPWA";
import Button from "../../../shared/Button";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 15,
    borderRadius: "50%",
    cursor: "pointer",
  },
  menuIcons: {
    padding: 0,
  },
}));

const UserDashboardMenu = ({
  auth: { isAuthenticated, user },
  myprofile,
  logout,
}) => {
  const location = useLocation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    await handleClose();
  };

  const [anchorElPopover, setAnchorElPopover] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? "user-dashboard-popover" : undefined;

  return (
    <Box display="flex" alignItems="center" style={{ gap: "12px" }}>
      {isAuthenticated ? (
        <>
          <Popover
            id={idPopover}
            open={openPopover}
            anchorEl={anchorElPopover}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box minWidth={325}>
              <Typography variant="body2" component="div">
                <Box p={1.5} fontWeight={600}>
                  Notifications
                </Box>
              </Typography>
              <Divider />
              <Box p={2} textAlign="center">
                <Typography variant="body1" component="div">
                  <Box fontWeight={600}>No new notifications!</Box>
                </Typography>
                <Box fontSize="h1.fontSize">✋</Box>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  High five!
                </Typography>
              </Box>
            </Box>
          </Popover>
          {/* <IconButton
            className={classes.menuIcons}
            component={Link}
            to="/chats/new"
          >
            <IoChatbubblesOutline fontWeight={600} />
          </IconButton>
          <IconButton
            className={classes.menuIcons}
            aria-describedby={idPopover}
            onClick={handleClickPopover}
          >
            <IoNotificationsOutline />
          </IconButton> */}
          <Box className={classes.menuButton}>
            <InstallPWA />
          </Box>

          <CustomAvatar
            src={user?.avatar}
            alt={user?.userName}
            fallbackText={user?.userName}
            onClick={handleMenu}
            className={classes.avatar}
          />
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <Box display="flex" alignItems="center" p={1}>
              <CustomAvatar
                src={user?.avatar}
                fallbackText={user?.userName}
                alt="user-avatar"
                className={classes.avatar}
              />
              <Box ml={1}>
                <Typography variant="body2" component="div">
                  <Box lineHeight={1.2} fontWeight={600}>
                    {myprofile
                      ? `${myprofile.firstName} ${myprofile.lastName}`
                      : user?.userName}
                  </Box>
                </Typography>
                <Box ml={0.5} display="flex" alignItems="center">
                  <StyledBadge variant="dot" />
                  <Typography
                    variant="body2"
                    component="div"
                    color="textSecondary"
                  >
                    <Box ml={1}>Active</Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <MenuItem component={Link} to={`/dashboard`}>
              Dashboard
            </MenuItem>

            <MenuItem component={Link} to="/me">
              Edit profile
            </MenuItem>
            <MenuItem component={Link} to={`/@${user?.userName}`}>
              View profile
            </MenuItem>

            <MenuItem component={Link} to="/settings">
              Settings
            </MenuItem>
            <MenuItem
              component={MuiLink}
              href="https://help.pralent.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box color="text.primary">Help Center</Box>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>Sign out of Pralent</MenuItem>
          </Menu>
        </>
      ) : (
        <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
          <Button
            variant="text"
            color="default"
            component={Link}
            to={`/login?redirect=${location.pathname}`}
          >
            Login
          </Button>
          <Button component={Link} to="/signup" variant="text">
            Sign Up
          </Button>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    myprofile: state.profile.myprofile,
  };
};

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardMenu);
