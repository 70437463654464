import React from "react";
// Redux
import { connect } from "react-redux";
import { getCurrentProfile } from "../../../redux/actions/profile";
// React Query
import { useQuery } from "react-query";
// Hooks
import { useGetProjectTotalForUser } from "../../../hooks/project";
import { useGetUserAccountActivityCount } from "../../../hooks/userAccount";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import Button from "../../../shared/Button";
// Icons
import { IoCompassOutline } from "react-icons/io5";

const Welcome = ({ myprofile, getCurrentProfile }) => {
  const {
    isLoading: isLoadingProjectTotalData,
    data: { data: projectTotalData } = { data: null },
    isError: isErrorProjectTotalData,
    error: errorProjectTotalData,
    isFetching: isFetchingPorjectTotalData,
  } = useGetProjectTotalForUser(myprofile && myprofile.user?.id);

  useQuery(
    ["myprofile", myprofile && myprofile.user?.id],
    async () => await getCurrentProfile()
  );

  const {
    isLoading: isLoadingUserTotals,
    data: { data: userTotals } = { data: null },
    isError: isErrorUserTotals,
    error: errorUserTotals,
    isFetching: isFetchingUserTotals,
  } = useGetUserAccountActivityCount(myprofile && myprofile.user?.id);

  return (
    <Box p={4} bgcolor="background.paper" borderRadius={16}>
      <Typography variant="h4" component="div" gutterBottom>
        <Box>
          <strong>
            {myprofile
              ? `👋 Welcome back, ${myprofile && myprofile.firstName}`
              : "👋 Welcome"}
            !
          </strong>
        </Box>
      </Typography>

      {userTotals ? (
        <Box display="flex" flexDirection="column">
          <Typography variant="body1" component="div">
            <Box mt={1} fontWeight={600}>
              Your Pralent Totals
            </Box>
          </Typography>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            style={{ gap: "28px" }}
          >
            <Typography variant="body1" component="div">
              <Box display="flex">
                <Box fontWeight={700}>{userTotals.totalEvents || 0}</Box>
                <Box ml={1} fontWeight={400} color="text.secondary">
                  Events
                </Box>
              </Box>
            </Typography>
            <Typography variant="body1" component="div">
              <Box display="flex">
                <Box fontWeight={700}>{userTotals.totalPrograms || 0}</Box>
                <Box ml={1} fontWeight={400} color="text.secondary">
                  Programs
                </Box>
              </Box>
            </Typography>
            <Typography variant="body1" component="div">
              <Box display="flex">
                <Box fontWeight={700}>{userTotals.totalJobsApplied || 0}</Box>
                <Box ml={1} fontWeight={400} color="text.secondary">
                  Jobs Applied
                </Box>
              </Box>
            </Typography>
            {/* <Typography variant="body1" component="div">
              <Box display="flex">
                <Box fontWeight={700}>
                  {projectTotalData && projectTotalData.projectTotal > 0
                    ? projectTotalData.projectTotal
                    : 0}
                </Box>
                <Box ml={1} fontWeight={400} color="text.secondary">
                  Projects
                </Box>
              </Box>
            </Typography> */}
            <Typography variant="body1" component="div">
              <Box display="flex">
                <Box fontWeight={700}>{userTotals.totalCommunities}</Box>
                <Box ml={1} fontWeight={400} color="text.secondary">
                  Communities
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  myprofile: state.profile.myprofile,
});

const mapDispatchToProps = { getCurrentProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
