import React, { useState } from "react";
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Components
import ChallengeSidebar from "./ChallengeSidebar";
import ChallengeDetails from "./ChallengeDetails";

const ChallengeDescriptions = ({ challenge }) => {
  return (
    <Box>
      {challenge.properties && challenge.properties.length > 0 ? (
        <>
          <ChallengeDetails challenge={challenge} />
        </>
      ) : (
        <Typography variant="body2" color="textSecondary">
          There is no other information entered about this program.
        </Typography>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeDescriptions);
