import React, { Fragment, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { fetchAllPrizes } from "../../../../redux/actions/challenge";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const ChallengePrizes = ({
  loading,
  auth,
  challenge,
  prizes,
  fetchAllPrizes,
}) => {
  const fetchPrizes = async () => {
    await fetchAllPrizes(challenge.id);
  };

  useEffect(() => {
    fetchPrizes();
  }, [challenge]);

  return (
    <Fragment>
      {challenge && challenge.isPrizePublic ? (
        <Fragment>
          {prizes && prizes.length > 0 ? (
            <Fragment>
              <Typography variant="body2" color="textSecondary">
                These are the awards entered by the organization for this
                program.
              </Typography>
              <Box>
                {prizes.map((prize, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        textAlign="left"
                        width="100%"
                        p={1}
                        my={1}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <Typography
                            variant="body1"
                            component="div"
                            color="textPrimary"
                          >
                            <Box lineHeight={1.2} fontWeight={600}>
                              {prize.name}
                            </Box>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="div"
                            color="textPrimary"
                          >
                            Awarding {prize.numberAwarded}
                          </Typography>
                        </Box>

                        <Typography variant="body2" color="textSecondary">
                          <Box whiteSpace="pre-line" lineHeight={1.2}>
                            {prize.description}
                          </Box>
                        </Typography>
                      </Box>
                      {index + 1 < prizes.length && <Divider />}
                    </>
                  );
                })}
              </Box>
            </Fragment>
          ) : (
            <Typography variant="body2" color="textSecondary">
              There are no awards entered for this program.
            </Typography>
          )}
        </Fragment>
      ) : (
        <Typography variant="body2" color="textSecondary">
          Awards are not public at this time.
        </Typography>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  challenge: state.challenge.challenge,
  prizes: state.challenge.prizes,
  auth: state.auth,
});

const mapDispatchToProps = { fetchAllPrizes };

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePrizes);
