import React from "react";
import PropTypes from "prop-types";
// React Router
// react query
import { useQuery } from "react-query";
import { getAllArticlesForCommunity } from "../../hooks/knowledge";
// Material UI
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
// Icons
import { IoReaderOutline } from "react-icons/io5";
// Components
import EmptyState from "../../shared/EmptyState";
import Spinner from "../../shared/Spinner";
import CommunityArticleCard from "./components/CommunityArticleCard";

const CommunityKnowledgeBase = ({ companyDetailsQuery }) => {
  const {
    isLoading,
    data: { data: companyDetails } = { data: null },
    isError,
    error,
  } = companyDetailsQuery;

  const {
    isLoading: isLoadingArticles,
    data: { data: communityArticles } = { data: null },
    isError: isErrorArticles,
    error: errorArticles,
  } = useQuery(
    ["communityKnowledgeBase", { companyId: companyDetails.id }],
    getAllArticlesForCommunity,
    {
      enabled: !!companyDetails,
    }
  );

  return (
    <Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container maxWidth="xl">
          <Box py={2} px={{ xs: 0, md: 2 }}>
            <Box>
              {isErrorArticles && (
                <Alert severity="error">
                  {errorArticles &&
                    errorArticles.data &&
                    errorArticles.data.msg}
                </Alert>
              )}
              {isLoadingArticles ? (
                <Spinner />
              ) : (
                <Box>
                  <Typography variant="h5" component="h2">
                    <Box
                      mb={2}
                      fontWeight={600}
                      display="flex"
                      alignItems="center"
                      style={{ gap: "8px" }}
                    >
                      <IoReaderOutline /> Knowledge Base
                    </Box>
                  </Typography>
                  {communityArticles && communityArticles.length > 0 ? (
                    <Grid container spacing={2}>
                      {communityArticles.map((article) => (
                        <Grid key={article.id} item xs={12} md={6}>
                          <CommunityArticleCard article={article} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <EmptyState
                      title="No articles"
                      subtitle="There are no knowledge base articles for the community at this time."
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};

CommunityKnowledgeBase.propTypes = {
  companyDetailsQuery: PropTypes.object,
};

export default CommunityKnowledgeBase;
