import React from "react";
// Redux
import { connect } from "react-redux";
// Material UI
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// Icons
import CloseIcon from "@material-ui/icons/Close";

const Demographics = ({ genderTypes, raceTypes, formData, setFormData }) => {
  const { gender, race } = formData;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mb={2} textAlign="center">
            <Typography variant="h5" component="h1" gutterBottom>
              <Box fontWeight={600}>✨ About me</Box>
            </Typography>
            <Typography variant="body1" component="div" color="textSecondary">
              <Box>
                We believe that communities should be inclusive to all
                individuals. This information is only ever visible to community
                organizers in an anonymous way to help them build more diverse
                and well-rounded programs, events, opportunities and more.
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Gender (Optional)</InputLabel>
            <Autocomplete
              id="gender-autocomplete"
              options={genderTypes}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              value={gender}
              onChange={(event, newValue) => {
                setFormData({
                  ...formData,
                  gender: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="gender"
                  fullWidth
                  autoComplete="gender"
                  variant="outlined"
                  placeholder="Select Gender"
                />
              )}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <InputLabel>Race/Ethnicity (Optional)</InputLabel>
            <Autocomplete
              multiple
              id="race-autocomplete"
              options={raceTypes}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              value={race}
              onChange={(event, value) =>
                setFormData({ ...formData, race: value })
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { onDelete, ...others } = getTagProps({ index });
                  return (
                    <Chip
                      key={index}
                      label={option && option.name}
                      onDelete={onDelete}
                      deleteIcon={<CloseIcon />}
                      {...others}
                    />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="race"
                  fullWidth
                  autoComplete="race"
                  variant="outlined"
                  placeholder="Select Race"
                />
              )}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  genderTypes: state.typedef.genderTypes,
  raceTypes: state.typedef.raceTypes,
});

export default connect(mapStateToProps)(Demographics);
