// Utils
import axios from "../utils/axios";
import { useQuery } from "react-query";

export const useFetchCommunityMembers = (companyId) => {
  try {
    const result = useQuery(
      ["fetchCommunityMembers", { companyId }],
      fetchCommunityMembers
    );
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

const fetchCommunityMembers = async (queryParams) => {
  try {
    //console.log("fetchCommunityMembers:", queryParams);
    const [_key, { companyId }] = queryParams.queryKey;

    const res = await axios.get(`api/community/member/all/${companyId}`);
    return res.data;
  } catch (error) {}
};

export const useFetchCommunityMessagingAppSettings = (companyDetails) => {
  try {
    const result = useQuery(
      ["communityMessagingAppSettings", { companyId: companyDetails.id }],
      fetchCommunityMessagingAppSettings,
      {
        enabled: !!companyDetails?.id,
      }
    );
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

const fetchCommunityMessagingAppSettings = async ({ queryKey }) => {
  try {
    const [_key, { companyId }] = queryKey;

    const res = await axios.get(`api/community/messaging/${companyId}`);
    return res.data;
  } catch (error) {}
};

export const addEditMessagingAppSettingsAPI = async ({
  companyId,
  messagingAppSettings,
}) => {
  try {
    const body = {
      companyId,
      messagingAppSettings,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await axios.post(`api/community/messaging/settings`, body, config);
  } catch (error) {
    await handleServerError(error);
  }
};

export const joinCommunity = async ({ companyId, isPublicToCommunity }) => {
  const body = {
    companyId,
    isPublicToCommunity,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/member/join`, body, config);
};

export const useRemoveUserFromCommunity = async ({ companyId, memberId }) => {
  const body = {
    companyId,
    memberId,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/member/remove`, body, config);
};

export const useBanUserFromCommunity = async ({
  companyId,
  memberId,
  isBanned,
}) => {
  const body = {
    companyId,
    memberId,
    isBanned,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/member/banstatus`, body, config);
};

export const updateIsPublic = async ({ companyId, isPublicToCommunity }) => {
  const body = {
    companyId,
    isPublicToCommunity,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/member/updateIsPublic`, body, config);
};

export const useGetAllMyCommunities = (user) => {
  try {
    const result = useQuery(["myCommunities", user?.id], getAllMyCommunities, {
      enabled: !!user?.id,
    });
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

export const getAllMyCommunities = async ({ queryKey }) => {
  const [_key] = queryKey;
  return await axios.get(`/api/community/member/me/all`);
};

export const useGetAllPublicMembers = (companyDetails) => {
  try {
    const result = useQuery(
      ["communityMembers", { companyId: companyDetails.id }],
      getAllPublicMembers,
      {
        enabled: !!companyDetails,
      }
    );
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

const getAllPublicMembers = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  const res = await axios.get(`/api/community/member/all/public/${companyId}`);
  return res.data;
};

export const deleteMemberMe = async ({ companyId }) => {
  return await axios.del(`/api/community/member/me/${companyId}`);
};

export const useGetCommunityMember = (companyDetails) => {
  try {
    const result = useQuery(
      [
        "communityMember",
        {
          companyId: companyDetails && companyDetails.id,
        },
      ],
      getCommunityMember,
      {
        enabled: !!companyDetails,
      }
    );

    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

const getCommunityMember = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/community/member/id/${companyId}`);
};

export const useFetchCommunitySettings = (companyId) => {
  try {
    const result = useQuery(
      ["fetchCommunitySettings", { companyId }],
      fetchCommunitySettings
    );

    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

const fetchCommunitySettings = async (queryParams) => {
  try {
    const [_key, { companyId }] = queryParams.queryKey;
    //console.log("fetchCommunitySettings:", queryParams);
    const res = await axios.get(`/api/community/company/${companyId}`);
    return res.data;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

export const updateMemberApprovalStatus = async ({
  companyId,
  memberId,
  approvalStatus,
}) => {
  const body = {
    companyId,
    memberId,
    approvalStatus,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/member/approvalstatus`, body, config);
};

export const updateCommunitySettingsAPI = async ({ data }) => {
  try {
    const body = {
      data,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(`updateCommunitySettingsAPI`, data);
    return await axios.post(`/api/community`, body, config);
  } catch (error) {
    // await handleServerError(error);
  }
};
export const useFetchInvitedUsers = (companyId) => {
  try {
    const result = useQuery(
      ["useFetchInvitedUsers", { companyId }],
      fetchInvitedUsers
    );
    if (result.isError) {
      // Take care of the error message
      // console.log("Error");
    }
    return result;
  } catch (error) {
    //dispatch(handleServerError(error));
  }
};

const fetchInvitedUsers = async (queryParams) => {
  try {
    const [_key, { companyId }] = queryParams.queryKey;

    const res = await axios.get(`/api/community/invited/${companyId}`);
    return res.data;
  } catch (error) {}
};

export const sendInviteToUsers = async ({ companyId, usersToInvite }) => {
  const body = {
    companyId,
    usersToInvite,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/community/invite`, body, config);
};
