export const SKIP = 0;
export const LIMIT = 12;
export const NO_AUTH_LIMIT = 24;
export const DESC_SORT = 1;
export const ASC_SORT = -1;
export const DEFAULT_PAGE_SIZE = 50;
export const SUBMISSION_TAB = 1;
export const RESULTS_TAB = 2;
export const JUDGES_TAB = 3;
export const CRITERIA_TAB = 4;
export const DEFAULT_PRIMARY_COLOR = "#3861F6";
export const DEFAULT_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const USER_TYPE = {
  STUDENT: 1,
  COMPANY: 2,
  OTHER: 3,
};

export const GENDER_TYPE = {
  PREFER_NOT_TO_ANSWER: 1,
  MALE: 2,
  FEMALE: 3,
  NON_BINARY: 3,
  GENDER_NOT_LISTED: 3,
};

export const JUDGE_ASSIGNMENT_TYPE_ID = {
  ASSIGN_ALL: 1,
  ASSIGN_GROUP: 2,
  INTELLASSIGN: 3,
  ASSIGN_SUBMISSION_TO_JUDGES: 4,
  ASSIGN_JUDGES_TO_SUBMISSION: 5,
};

export const CUSTOM_FORM_TYPE_ID = {
  REGISTRATION_FORM: 1,
  SUBMISSION_FORM: 2,
};

export const SUBMISSION_TYPE_ID = {
  CUSTOM: 1,
  PROJECT: 2,
};
export const SUBMITTED_STATUS_TYPE_ID = {
  SUBMITTED: 1,
  SAVED: 2,
};

// Git it some arbitrary number
export const MAX_TEAM_MEMBERS_ALLOWED = 50;

export const MAX_PER_TEAM_OPTION = {
  INDIVIDUAL: "Individual",
  TEAM: "Team",
};

export const JOB_POST_BOARD_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
};

export const JOB_APPLICATION_STATUS = {
  APPLIED: 1,
  REVIEWED: 2,
  INTERVIEW: 3,
  OFFER: 4,
  REJECTED: 5,
  CLOSED: 6,
};

export const EVENT_ONLINE = {
  ONLINE: 1,
  HYBRID: 2,
  INPERSON: 3,
};

export const EVENT_REGISTRATION_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
};

export const MEMBER_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
};

export const ROOM_TYPE = {
  CHAT: 1,
  FORUM: 2,
  BOARD: 3,
};

export const USER_ROOM_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
};

export const POST_ROOM_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  DENIED: 3,
};

export const CALENDAR_EVENT_TYPE = {
  CHALLENGE_REGISTRATION_DEADLINE: 1,
  CHALLENGE_ROUND_START_DATE: 2,
  CHALLENGE_ROUND_END_DATE: 3,
  CHALLENGE_ROUND_LATE_SUBMISSION: 4,
  EVENT_REGISTRATION_DATE: 5,
  EVENT_END_DATE: 6,
  CALENDAR_CUSTOM_EVENT: 7,
  CALENDAR_END_DATE: 8,
};

export const THEME_TYPE = {
  SYSTEM: 1,
  LIGHT: 2,
  DARK: 3,
  ASH: 4,
  MIDNIGHT: 5,
  PALE: 6,
  DAWN: 7,
};

export const POST_TYPES = {
  MESSAGE: 1,
  PROGRAM: 2,
  EVENT: 3,
  JOB: 4,
  PROJECT: 5,
  PROFILE: 6,
};
