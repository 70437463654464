import React, { useState, useEffect } from "react";
// React router
import { useLocation } from "react-router-dom";
// react-query
import { useMutation, useQueryClient } from "react-query";
import {
  sendInviteToUsers,
  useFetchCommunityMessagingAppSettings,
  useFetchInvitedUsers,
} from "../../../hooks/community";
// Redux
import { connect } from "react-redux";
import { setAlert } from "../../../redux/actions/alert";
// Material UI
import PTable from "../../../shared/PTable";
import Box from "@material-ui/core/Box";
import Button from "../../../shared/Button";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// Icons
import {
  SiSlack,
  SiMailchimp,
  SiHubspot,
  SiSubstack,
  SiZoom,
} from "react-icons/si";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
// Utils
import Papa from "papaparse";
import useClipboard from "react-use-clipboard";
// Components
import EmptyState from "../../../shared/EmptyState"

const INVITE_CSV_OPTIONS = [
  {
    logo: SiSlack,
    name: "Slack",
    href: "https://slack.com/help/articles/4405848563603-Download-a-list-of-members-in-your-workspace",
  },
  {
    logo: SiMailchimp,
    name: "Mailchimp",
    href: "https://mailchimp.com/help/view-export-contacts/",
  },
  {
    logo: SiHubspot,
    name: "Hubspot",
    href: "https://knowledge.hubspot.com/crm-setup/export-contacts-companies-deals-or-tickets",
  },
  {
    logo: SiSubstack,
    name: "Substack",
    href: "https://support.substack.com/hc/en-us/articles/360037465992-How-do-I-export-my-email-or-subscriber-list-",
  },
  {
    logo: SiZoom,
    name: "Zoom",
    href: "https://support.zoom.us/hc/en-us/articles/201363183-Managing-users#h_93d5a2b6-1a3d-4bbc-bdde-ccc6ae89e999",
  },
];

const OrgInviteManager = ({ companyProfile, setAlert }) => {
  const [fileData, setFileData] = React.useState({
    file: null,
    data: null,
  });
  let usersToInvite = [];

  const {
    isSuccess,
    isFetching: isUserInvitedFetching,
    isLoading: isUserInvitedLoading,
    isError: isUserInvitedError,
    data: dataUsersInvited = null,
    error: errorfetching,
  } = useFetchInvitedUsers(companyProfile.id);

  const queryClient = useQueryClient();

  const sendInviteToUsersMutation = useMutation(sendInviteToUsers, {
    onSuccess: async (data) => {
      queryClient.setQueryData([
        "invitedUserData",
        { companyId: companyProfile.id, usersToInvite: usersToInvite },
      ]);
      await queryClient.invalidateQueries([
        "useFetchInvitedUsers",
        { companyId: companyProfile.id },
      ]);
      await setAlert(
        `Successfully sent invitation to uploaded users!`,
        "success"
      );
      setFileData({
        file: null,
        data: null,
      });
    },
    onError: async (error) => {
      await setAlert(
        error?.data?.msg || "Error sending invitation to uploaded users: ",
        "error"
      );
    },
  });

  const handleChange = async (e) => {
    const fileToUpload = e.target.files[0];
    if (fileToUpload) {
      Papa.parse(fileToUpload, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setFileData({
            file: fileToUpload,
            data: results.data,
          });
        },
      });
    }
  };

  const handleDelete = (deleteIndex) => {
    if (fileData.data && fileData.data.length) {
      let newFileData = fileData.data.filter(
        (_, index) => index !== deleteIndex
      );
      setFileData({
        ...fileData,
        data: newFileData,
      });
    }
  };

  const onSubmit = async () => {
    let error = false;
    if (!fileData.data || fileData.length === 0) {
      setAlert("There are no users to send invites", "error");
      return;
    }

    for (let indx = 0; indx < fileData.data.length; indx++) {
      const row = fileData.data[indx];
      if (row.email !== "" && row.fullname !== "") {
        let re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(row.email)) {
          setAlert("Please make sure that the email is a valid address");
          error = true;
          break;
        } else {
          usersToInvite.push({
            fullname: row.fullname,
            email: row.email,
          });
        }
      } else {
        setAlert(
          "Please make sure that all rows have entries for email, and fullname",
          "error"
        );
        error = true;
        break;
      }
    }
    if (!error && usersToInvite) {
      const result = sendInviteToUsersMutation.mutate({
        companyId: companyProfile.id,
        usersToInvite: usersToInvite,
      });
      if (result) {
      }
    }
  };

  const headerCells = [
    {
      id: "id",
      label: "id",
      hide: true,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "fullname",
      label: "Full Name",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
    {
      id: "email",
      label: "Email Address",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
    {
      id: "emailSentStatus",
      label: "Status",
      hide: false,
      disableSorting: false,
      filterable: false,
    },
  ];

  const columns = [
    {
      field: "id",
      renderCell: (data) => {
        return data.id;
      },
    },
    {
      field: "fullname",
      renderCell: (data) => {
        return data.fullname;
      },
    },
    {
      field: "email",
      renderCell: (data) => {
        return data.email;
      },
    },
    {
      field: "emailSentStatus",
      renderCell: (data) => {
        return `${data.emailSentStatus ? "Sent" : "Not Sent"}`;
      },
    },
  ];

  const [isCopiedLink, setCopiedLink] = useClipboard(
    `${document.location.origin}/c/${companyProfile.communitySlug}/invite`
  );

  const onClickCopyInviteLink = () => {
    setCopiedLink();
    setAlert(`Copied community member invite link`, "success");
  };

  return (
    <Box>
      {!companyProfile.isCommunityEnabled ? (
        <Box>
          <EmptyState title="Enable members first" subtitle="Please enable members to join the community in the Community tab before inviting them to join" />
        </Box>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                <Box fontWeight={600}>Invite Link</Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box>
                  Anyone with this invite link can join as a member of your
                  community.{" "}
                  {companyProfile.doMembersNeedApproval &&
                    "Since you have required membership approval, new members will still need to be approved manually."}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={`${document.location.origin}/c/${companyProfile.communitySlug}/invite`}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FileCopyIcon
                        onClick={onClickCopyInviteLink}
                        style={{ cursor: "pointer" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                <Box fontWeight={600}>Invite from CSV</Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box>
                  Invite your members from other platforms to join your
                  community
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                style={{ gap: "12px" }}
              >
                {INVITE_CSV_OPTIONS.map((option, index) => {
                  const IconLogo = option.logo;
                  return (
                    <Tooltip
                      title={`Learn how to export users from ${option.name}`}
                      key={index}
                    >
                      <IconButton
                        component={Link}
                        href={option.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon fontSize="large">
                          <IconLogo />
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                <Box fontWeight={600}>How to send invites from csv</Box>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                1. Download or create a csv file (see some examples above)
              </Typography>
              <Typography variant="body1" color="textSecondary">
                2. Rename the csv file to contain the columns{" "}
                <i>
                  <strong>fullname</strong>
                </i>{" "}
                and{" "}
                <i>
                  <strong>email</strong>
                </i>{" "}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                3. Upload the csv below
              </Typography>
              <Typography variant="body2" color="textSecondary">
                * Only upload 500 email addresses at a time
              </Typography>
              <Typography variant="body1" color="textSecondary">
                4. Check the emails are processed and click invite
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <input
                  accept=".csv"
                  style={{ display: "none" }}
                  id="bulk-upload-button-file"
                  type="file"
                  onChange={handleChange}
                  value={""}
                />
                <label htmlFor="bulk-upload-button-file">
                  <Button variant="contained" color="primary" component="span">
                    Select CSV File
                  </Button>
                </label>
              </FormGroup>
              {fileData && fileData.data && (
                <Box mt={2}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" gutterBottom>
                      {fileData.file && fileData.file.name} |{" "}
                      {fileData.data.length || 0} Rows
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell>User Name</TableCell> */}
                            <TableCell>Full Name</TableCell>
                            {/* <TableCell>Status</TableCell> */}
                            <TableCell>Email</TableCell>
                            <TableCell>Delete</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fileData.data &&
                            fileData.data.map((row, index) => {
                              let re =
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                              let validEmail = false;

                              if (re.test(row.email)) {
                                validEmail = true;
                              }

                              return (
                                <TableRow key={index}>
                                  {/* <TableCell component="th" scope="row">
                                {row.username}
                              </TableCell> */}
                                  <TableCell>{row.fullname}</TableCell>
                                  {/* <TableCell>{row.status}</TableCell> */}
                                  <TableCell>
                                    {!validEmail && (
                                      <Box
                                        color="error.main"
                                        fontSize="caption.fontSize"
                                      >
                                        Invalid Email
                                      </Box>
                                    )}
                                    {row.email}
                                  </TableCell>
                                  <TableCell>
                                    <IconButton
                                      onClick={() => handleDelete(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box my={2} display="flex" justifyContent="flex-end">
                    <Button onClick={onSubmit}>Send Invite</Button>
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant="h5" component="h2">
                  <Box fontWeight={600}>Invites Sent</Box>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Email invites that you have sent
                </Typography>
              </Box>
              <>
                <Box height="100%" margin="auto">
                  <PTable
                    records={dataUsersInvited}
                    headerCells={headerCells}
                    columns={columns}
                    noRowsText="There are no invites sent at this time."
                    isLoading={isUserInvitedLoading || isUserInvitedFetching}
                  />
                </Box>
              </>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {
  setAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgInviteManager);
