import React, { useContext, useMemo } from "react";
// React router dom
import { useParams } from "react-router-dom";
// react query
import { useGetCompanyDetailsBySlug } from "../../../hooks/company";
import { useGetCommunityMember } from "../../../hooks/community";

const CompanyContext = React.createContext();
const CommunityMemberContext = React.createContext();

export const useCompanyContext = () => {
  return useContext(CompanyContext);
};

export const useCommunityMemberContext = () => {
  return useContext(CommunityMemberContext);
};

export const CommunityProvider = ({ children }) => {
  const { communitySlug } = useParams();

  const companyDetailsQuery = useGetCompanyDetailsBySlug(communitySlug);

  const companyDetailsData = companyDetailsQuery?.data?.data;

  const communityMemberQuery = useGetCommunityMember(companyDetailsData);

  const CompanyContextValue = useMemo(
    () => companyDetailsQuery,
    [companyDetailsQuery]
  );

  const CommunityMemberContextValue = useMemo(
    () => communityMemberQuery,
    [communityMemberQuery]
  );

  return (
    <CompanyContext.Provider value={CompanyContextValue}>
      <CommunityMemberContext.Provider value={CommunityMemberContextValue}>
        {children}
      </CommunityMemberContext.Provider>
    </CompanyContext.Provider>
  );
};
