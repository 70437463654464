import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getPublicSubmissions,
  getMySubmissionWithScores,
  fetchSubmissionAndFormForChallengeRound,
  clearMySubmission,
} from "../../../../redux/actions/challenge";
import {
  SUBMISSION_TYPE_ID,
  CUSTOM_FORM_TYPE_ID,
} from "../../../../utils/globalValues";
// Material Ui
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
// Components
import SimplePublicProjectCard from "../../../../shared/SimplePublicProjectCard";

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(0),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ChallengeSubmissions = ({
  loading,
  auth,
  challenge,
  submissions,
  mySubmission,
  myCustomFormSubmission,
  getPublicSubmissions,
  getMySubmissionWithScores,
  fetchSubmissionAndFormForChallengeRound,
  clearMySubmission,
}) => {
  const classes = useStyles();
  const [currentRoundIndex, setCurrentRoundIndex] = useState(-1);
  const [toggleResults, setToggleResults] = useState(false);
  const [submissionType, setSubmissionType] = useState(
    SUBMISSION_TYPE_ID.PROJECT
  );

  useEffect(() => {
    if (
      challenge.round &&
      0 <= currentRoundIndex &&
      currentRoundIndex < challenge.round.length
    ) {
      const round = challenge.round[currentRoundIndex];
      setSubmissionType(round.submissionTypeId);
      if (round.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM) {
        fetchSubmissionAndFormForChallengeRound(
          challenge.id,
          round.id,
          CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM
        );
      } else {
        getMySubmissionWithScores(challenge.id, round.id);
      }
      getPublicSubmissions(round.id);
    }
    return () => {
      clearMySubmission();
    };
  }, [currentRoundIndex]);

  useEffect(() => {
    const foundIndex =
      challenge &&
      challenge.round &&
      challenge.round.length > 0 &&
      challenge.round.findIndex((elem) => elem.id === challenge.currentRoundId);
    setCurrentRoundIndex(foundIndex || 0);
  }, [challenge]);

  return (
    <Box width="100%" color="grey.600">
      <Select
        className={classes.select}
        margin="dense"
        variant="outlined"
        id="currentRoundIndex"
        value={currentRoundIndex}
        name="currentRoundIndex"
        onChange={(e) => setCurrentRoundIndex(e.target.value)}
      >
        {challenge &&
          challenge.round &&
          challenge.round.length > 0 &&
          challenge.round.map((round, index) => {
            return (
              <MenuItem value={index} key={index}>
                <Typography variant="body2">{round.name}</Typography>
              </MenuItem>
            );
          })}
      </Select>
      {/* Showing submission if it exists */}

      <Box>
        {submissions && submissions.length > 0 ? (
          <Fragment>
            <Box my={2}>
              <Typography variant="body2" color="textSecondary">
                Only the people who have made their submissions public are
                displayed.
              </Typography>
            </Box>
            <Box my={2}>
              <Grid container spacing={2}>
                {submissionType === SUBMISSION_TYPE_ID.PROJECT &&
                  submissions.map((project, index) => {
                    return (
                      <Grid key={index} item xs={12} sm={6} md={4}>
                        <SimplePublicProjectCard
                          project={project}
                          key={index}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Fragment>
        ) : (
          <Box my={2}>
            <Typography variant="body2" color="textSecondary">
              Submissions are not public at this time.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  auth: state.auth,
  challenge: state.challenge.challenge,
  submissions: state.challenge.submissions,
  myCustomFormSubmission: state.challenge.myCustomFormSubmission,
  mySubmission: state.challenge.mySubmission,
});

const mapDispatchToProps = {
  getPublicSubmissions,
  getMySubmissionWithScores,
  fetchSubmissionAndFormForChallengeRound,
  clearMySubmission,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeSubmissions);
