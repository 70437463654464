import React, { useState, useEffect } from "react";
import { setAlert } from "../../../redux/actions/alert";
import { connect } from "react-redux";
// Material UI
import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Chip from "@material-ui/core/Chip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// Icons
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
// Components
import Button from "../../../shared/Button";
// Utils
import { formFieldConfig } from "../../../utils/CustomFormConfig";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  formFieldSetting: {
    transition: "all 0.2s ease-out",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      transition: "all 0.2s ease-out",
    },
  },
}));

const FormFieldSettings = ({
  index,
  formField,
  handleSaveField,
  moveElementUp,
  moveElementDown,
  handleDeleteField,
  setAlert,
}) => {
  const classes = useStyles();

  const [fieldOptionsAnchorEl, setFieldOptionsAnchorEl] = useState(null);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [formData, setFormData] = useState({});

  const fieldConfigProperties =
    formFieldConfig.properties[
      (formData && formData.fieldType) || formField.fieldType
    ];

  const handleFieldOptionsOpen = (e) => {
    setFieldOptionsAnchorEl(e.currentTarget);
  };
  const handleFieldOptionsClose = () => {
    setFieldOptionsAnchorEl(null);
  };

  const handleSubmit = async () => {
    if (!formData.title) {
      await setAlert("Please enter a title for this field", "error");
    } else {
      await handleSaveField(formData, index);
      await setToggleDrawer(false);
    }
  };

  useEffect(() => {
    setFormData({
      ...formField,
    });
  }, [index, formField, toggleDrawer]);

  const changeFormData = (e) => {
    const target = e.target;
    const name = target.name;
    const dataset = target.dataset;
    const value = target.type === "checkbox" ? target.checked : target.value;

    let updatedItem = {};
    if (dataset && dataset.fieldtype === "file-accept") {
      updatedItem = {
        ...formData,
        accept: {
          ...formData.accept,
          [name]: value,
        },
      };
    } else if (dataset && dataset.fieldtype === "enum") {
      const enumIndex = name;
      let enums = [...formData.enum];
      enums[enumIndex] = value;
      updatedItem = {
        ...formData,
        enum: [...enums],
      };
    } else {
      updatedItem = {
        ...formData,
        [name]: value,
      };
    }

    setFormData(updatedItem);
  };

  const addNewEnum = () => {
    setFormData((formData) => ({
      ...formData,
      enum: [...(formData.enum || []), ""],
    }));
  };

  const deleteEnum = (enumIndex) => {
    setFormData((formData) => ({
      ...formData,
      enum: (formData.enum || []).filter((_, i) => enumIndex !== i),
    }));
  };

  const moveEnumUp = (enumIndex) => {
    if (!formData.enum) return;
    if (enumIndex > 0) {
      let oldEnums = [...(formData.enum || [])];
      const previousElement = oldEnums[enumIndex - 1];
      oldEnums[enumIndex - 1] = oldEnums[enumIndex];
      oldEnums[enumIndex] = previousElement;
      setFormData((formData) => ({
        ...formData,
        enum: oldEnums,
      }));
    }
  };
  const moveEnumDown = (enumIndex) => {
    if (!formData.enum) return;
    if (enumIndex < formData.enum.length - 1) {
      let oldEnums = [...(formData.enum || [])];
      const nextElement = oldEnums[enumIndex + 1];
      oldEnums[enumIndex + 1] = oldEnums[enumIndex];
      oldEnums[enumIndex] = nextElement;
      setFormData((formData) => ({
        ...formData,
        enum: oldEnums,
      }));
    }
  };

  const changePralentFieldType = async (newFieldType) => {
    await setFormData((formData) => ({
      ...formData,
      fieldType: newFieldType,
    }));
    await handleFieldOptionsClose();
  };

  const FieldIcon = fieldConfigProperties.icon;

  const handleDrawerOpen = () => {
    setToggleDrawer(true);
  };

  const handleDrawerClose = async () => {
    if (!formData.title) {
      await setAlert("Please enter a title for this field", "error");
    } else if (
      _.isEqual(formField, formData) ||
      window.confirm("Are you sure you want to continue without saving?")
    ) {
      setToggleDrawer(false);
    }
  };

  return (
    <>
      <Box key={index}>
        <Box
          border={1}
          bgcolor="background.paper"
          borderColor="action.selected"
          display="flex"
          flexDirection="row"
          alignItems="center"
          mb={1}
          p={1}
          onClick={handleDrawerOpen}
          className={classes.formFieldSetting}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexGrow={1}
          >
            <IconButton onClick={handleDrawerOpen}>
              <EditIcon />
            </IconButton>
            <Typography variant="body2" color="textPrimary" component="div">
              <Box ml={1} fontWeight={600} lineHeight={1.2}>
                {formData.title} {formData.required && " *"}
              </Box>
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Chip
                variant="outlined"
                label={
                  <Typography variant="caption">
                    <FieldIcon style={{ fontSize: "15px" }} />{" "}
                    {fieldConfigProperties && fieldConfigProperties.name}
                  </Typography>
                }
                color="default"
              />
              <IconButton
                onClick={() => moveElementUp(index)}
                disableFocusRipple
                disableRipple
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => moveElementDown(index)}
                disableFocusRipple
                disableRipple
              >
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton
                onClick={() => handleDeleteField(index)}
                disableFocusRipple
                disableRipple
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Drawer anchor="right" open={toggleDrawer} onClose={handleDrawerClose}>
          <Box p={{ xs: 2 }} pb={{ sm: 10 }} maxWidth={500}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" component="div">
                    <Box>Add / Edit New Field</Box>
                  </Typography>
                  <Tooltip
                    placement="left"
                    title={
                      <Box>
                        {fieldConfigProperties &&
                        fieldConfigProperties.switchTo ? (
                          <Box>
                            <Typography variant="caption">
                              Switch this field to{" "}
                            </Typography>
                            {fieldConfigProperties &&
                              fieldConfigProperties.switchTo &&
                              fieldConfigProperties.switchTo.map(
                                (option, switchIndex) => {
                                  const field =
                                    formFieldConfig.properties[option];
                                  return (
                                    <Typography
                                      variant="caption"
                                      key={switchIndex}
                                    >
                                      {field.name}
                                      {switchIndex !==
                                        fieldConfigProperties.switchTo.length -
                                          1 && ", "}
                                    </Typography>
                                  );
                                }
                              )}
                          </Box>
                        ) : (
                          <Typography variant="caption">
                            Unable to switch this field to other field types.
                          </Typography>
                        )}
                      </Box>
                    }
                  >
                    <Chip
                      clickable={
                        fieldConfigProperties && fieldConfigProperties.switchTo
                          ? true
                          : false
                      }
                      onClick={handleFieldOptionsOpen}
                      variant="outlined"
                      label={
                        <Typography variant="caption">
                          <FieldIcon style={{ fontSize: "15px" }} />{" "}
                          {fieldConfigProperties && fieldConfigProperties.name}
                        </Typography>
                      }
                      color="default"
                    />
                  </Tooltip>
                  {fieldConfigProperties && fieldConfigProperties.switchTo && (
                    <Menu
                      id="field-switch-to-menu"
                      anchorEl={fieldOptionsAnchorEl}
                      keepMounted
                      open={Boolean(fieldOptionsAnchorEl)}
                      onClose={handleFieldOptionsClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      {fieldConfigProperties &&
                        fieldConfigProperties.switchTo &&
                        fieldConfigProperties.switchTo.map(
                          (option, switchIndex) => {
                            const field = formFieldConfig.properties[option];
                            const Icon = field.icon;
                            return (
                              <Tooltip
                                placement="left"
                                key={switchIndex}
                                title={(field && field.about) || ""}
                              >
                                <MenuItem
                                  onClick={() => changePralentFieldType(option)}
                                >
                                  <Icon /> <Box ml={1}>{field.name}</Box>
                                </MenuItem>
                              </Tooltip>
                            );
                          }
                        )}
                    </Menu>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel required>Field Title</InputLabel>
                  <TextField
                    name="title"
                    required
                    placeholder="Enter a title for this field"
                    value={formData.title}
                    onChange={changeFormData}
                    error={!formData.title || formData.title === ""}
                    helperText={
                      (!formData.title || formData.title === "") &&
                      "Please enter a field title"
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Description</InputLabel>
                  <TextField
                    name="description"
                    placeholder="Enter a description for this field"
                    multiline
                    rows={6}
                    value={formData.description}
                    onChange={changeFormData}
                  />
                </FormGroup>
              </Grid>
              {["checkboxes", "multiselect", "select", "radio"].includes(
                formData.fieldType
              ) && (
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel required>Choices</InputLabel>
                    <Typography color="textSecondary" variant="caption">
                      <Box lineHeight="1.2" mb={1.5}>
                        NOTE: Please do not change these options after
                        submissions have been made. Contact support with any
                        further questions.
                      </Box>
                    </Typography>
                    {formData.enum &&
                      formData.enum.map((choice, enumIndex) => {
                        return (
                          <Box key={enumIndex} mb={0.5} width="100%">
                            <TextField
                              name={`${enumIndex}`}
                              placeholder="Enter a choice"
                              inputProps={{
                                "data-fieldtype": "enum",
                              }}
                              value={choice}
                              onChange={changeFormData}
                              error={!choice || choice === ""}
                              helperText={
                                (!choice || choice === "") &&
                                "Please enter a choice here"
                              }
                              style={{ width: "100%" }}
                              autoFocus={formData.enum.length - 1 === enumIndex}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => moveEnumUp(enumIndex)}
                                    >
                                      <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => moveEnumDown(enumIndex)}
                                    >
                                      <ArrowDownwardIcon />
                                    </IconButton>

                                    <IconButton
                                      onClick={() => deleteEnum(enumIndex)}
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        );
                      })}
                  </FormGroup>
                  <Box mt={1}>
                    <Button onClick={addNewEnum}>Add Choice</Button>
                  </Box>
                </Grid>
              )}
              {formData.fieldType === "file" && (
                <Grid item xs={12}>
                  <FormGroup>
                    <InputLabel required>
                      File Types (Max size: 100MB)
                    </InputLabel>
                    <Grid container spacing={2}>
                      {formData.accept &&
                        Object.keys(formData.accept).map(
                          (fileAcceptType, j) => {
                            const checked = formData.accept[fileAcceptType];

                            return (
                              <Grid key={j} item xs={12} sm={4} md={3} lg={2}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      inputProps={{
                                        "data-fieldtype": `file-accept`,
                                      }}
                                      checked={checked}
                                      onChange={changeFormData}
                                      name={fileAcceptType}
                                      color="primary"
                                    />
                                  }
                                  label={fileAcceptType}
                                />
                              </Grid>
                            );
                          }
                        )}
                    </Grid>
                  </FormGroup>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormGroup>
                  <InputLabel>Required</InputLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData.required}
                        onChange={changeFormData}
                        name="required"
                        color="primary"
                      />
                    }
                    label="Is this field required?"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="outlined"
                    onClick={() => {
                      if (handleDeleteField(index)) {
                        setToggleDrawer(false);
                      }
                    }}
                  >
                    Delete
                  </Button>
                  <Box ml={1}>
                    <Button variant="contained" onClick={handleSubmit}>
                      Save
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(FormFieldSettings);
