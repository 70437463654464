import React from "react";

import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import isUrl from "../../../utils/isUrl";

import ReactPlayer from "react-player/lazy";

const URLWidget = (props) => {
  const _onChange = ({ target: { value } }) =>
    props.onChange(value === "" ? props.options.emptyValue : value);

  return (
    <>
      {props.disabled && props.value && ReactPlayer.canPlay(props.value) && (
        <Box maxWidth="700px">
          <Box my={2} style={{ position: "relative", paddingTop: "56.25%" }}>
            <ReactPlayer
              style={{ position: "absolute", top: 0, left: 0 }}
              controls={true}
              url={props.value}
              fallback={<Box />}
              width="100%"
              height="100%"
            />
          </Box>
        </Box>
      )}
      <Box display={props.disabled ? "none" : "block"} width="100%">
        <TextField
          id={props.id}
          value={props.value || ""}
          type="url"
          onChange={_onChange}
          required={props.required}
          placeholder={props.placeholder || "Add a url with http://"}
          autoFocus={props.autofocus}
          disabled={props.disabled}
          label={null}
          fullWidth
        />
      </Box>
      {props.disabled && (
        <Box>
          <Typography variant="body2" color="textPrimary" component="div">
            <Link
              href={isUrl(props.value) ? props.value : `http://${props.value}`}
              color="primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em>{props.value}</em>
            </Link>
          </Typography>
        </Box>
      )}
    </>
  );
};

export default URLWidget;
