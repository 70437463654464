import {
  SET_ALERT,
  REMOVE_ALERT,
  CLEAR_ALL_ALERTS,
  SERVER_ERROR,
  CLOSE_SERVER_ERROR,
} from "../actions/actionTypes";

const initialState = {
  alerts: [],
  serverError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SERVER_ERROR:
      return {
        ...state,
        serverError: true,
      };
    case CLOSE_SERVER_ERROR: {
      return {
        ...state,
        serverError: false,
      };
    }
    case SET_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, payload],
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== payload),
      };
    case CLEAR_ALL_ALERTS:
      return { ...state, serverError: false, alerts: [] };
    default:
      return state;
  }
}
