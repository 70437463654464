import { Link } from "react-router-dom";

import ProgramHome from "../../../assets/images/ProgramHome.png";

const CTA = () => {
  return (
    <>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-br from-yellow-500 to-red-500 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Ready to dive in?</span>
                  <span className="block">Get started for free today.</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-gray-200">
                  No matter your organization, we are happy to personally walk
                  you through Pralent and how it can help your program.
                </p>
                <Link
                  to="/signup?redirect=/org/new"
                  className="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-red-600 hover:bg-red-50"
                >
                  Get Started
                </Link>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-3">
              <img
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-12 lg:translate-y-20"
                src={ProgramHome}
                alt="Program Home"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTA;
