import { handleServerError } from "./error";
import { setAlert } from "./alert";
import {
  GET_ALL_ACTIVE_JOBS,
  REMOVE_APPLICATION_TO_JOB,
  APPLY_JOB,
  LOADING_JOB,
  GET_JOB,
  ERROR_JOB,
  CLEAR_JOB_BOARD,
  LOADING_JOB_BOARD,
  GET_JOB_BOARD,
  ERROR_JOB_BOARD,
  GET_MY_COMPANY_JOBS,
  LOADING_MY_COMPANY_JOBS,
  ERROR_MY_COMPANY_JOBS,
  ADD_JOB,
  EDIT_JOB,
  DELETE_JOB,
  GET_MY_COMPANY_EXT_JOBS,
  LOADING_MY_COMPANY_EXT_JOBS,
  ERROR_MY_COMPANY_EXT_JOBS,
  DELETE_EXT_JOB_POST,
  UPDATE_EXT_JOB_POST_BOARD_APPROVAL,
  UPDATE_CANDIDATE_APPLICATION_STATUS,
  DELETE_JOB_EXT_BOARD,
} from "./actionTypes";
import axios from "../../utils/axios";

export const clearCompanyJobBoard = () => async (dispatch) => {
  try {
    await dispatch({ type: CLEAR_JOB_BOARD });
  } catch (error) {
    await dispatch(handleServerError(error));
  }
};

export const getAllJobsForCompany = (companyId) => async (dispatch) => {
  try {
    await dispatch({ type: LOADING_MY_COMPANY_JOBS });
    const res = await axios.get(`/api/jobs/company/${companyId}`);
    await dispatch({ type: GET_MY_COMPANY_JOBS, payload: res.data });
  } catch (error) {
    console.log(error);
    await dispatch({ type: ERROR_MY_COMPANY_JOBS, payload: error });
    await dispatch(handleServerError(error));
  }
};

export const getAllExternalJobsForCompany = (companyId) => async (dispatch) => {
  try {
    await dispatch({ type: LOADING_MY_COMPANY_EXT_JOBS });
    const res = await axios.get(`/api/jobs/company/${companyId}/external`);
    await dispatch({ type: GET_MY_COMPANY_EXT_JOBS, payload: res.data });
  } catch (error) {
    await dispatch({ type: ERROR_MY_COMPANY_EXT_JOBS, payload: error });
    dispatch(handleServerError(error));
  }
};

export const clearJob = () => async (dispatch) => {
  try {
    await dispatch({ type: CLEAR_JOB });
  } catch (error) {
    await dispatch(handleServerError(error));
  }
};

export const getJobById = (jobId) => async (dispatch) => {
  try {
    await dispatch({ type: LOADING_JOB });
    const res = await axios.get(`/api/jobs/${jobId}`);
    await dispatch({
      type: GET_JOB,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: ERROR_JOB,
      payload: error,
    });
    dispatch(handleServerError(error));
  }
};

export const getJobBoardByCompanyId = (companyId) => async (dispatch) => {
  try {
    await dispatch({ type: LOADING_JOB_BOARD });
    const res = await axios.get(`/api/jobs/jobBoard/${companyId}`);
    await dispatch({
      type: GET_JOB_BOARD,
      payload: res.data || null,
    });
  } catch (error) {
    await dispatch({
      type: ERROR_JOB_BOARD,
      payload: error,
    });
    dispatch(handleServerError(error));
  }
};

export const createUpdateJobBoard =
  (companyId, jobBoardDetails) => async (dispatch) => {
    try {
      const body = {
        companyId,
        jobBoardDetails,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`/api/jobs/jobBoard`, body, config);
      await dispatch({
        type: GET_JOB_BOARD,
        payload: res.data || null,
      });
      await dispatch(
        setAlert("Successfully updated job board settings", "success")
      );
    } catch (error) {
      await dispatch({
        type: ERROR_JOB_BOARD,
        payload: error,
      });
      await dispatch(handleServerError(error));
    }
  };

export const deleteJobPost = (jobPostId) => async (dispatch) => {
  try {
    const res = await axios.del(`/api/jobs/${jobPostId}`);
    await dispatch({
      type: DELETE_JOB,
      payload: res.data,
    });
    await dispatch(setAlert("Deleted job post", "success"));
  } catch (error) {
    await dispatch(handleServerError(error));
  }
};

export const deleteExternalJobPostFromBoard =
  (jobPostId, jobBoardId) => async (dispatch) => {
    try {
      const res = await axios.del(
        `/api/jobs/${jobPostId}/external/${jobBoardId}`
      );
      await dispatch({
        type: DELETE_EXT_JOB_POST,
        payload: res.data,
      });
      await dispatch(
        setAlert("Deleted external job post from your board", "success")
      );
    } catch (error) {
      await dispatch(handleServerError(error));
    }
  };

export const deleteMyJobPostFromExternalBoard =
  (jobPostId, jobBoardId) => async (dispatch) => {
    try {
      const res = await axios.del(
        `/api/jobs/${jobPostId}/external/${jobBoardId}`
      );
      await dispatch({
        type: DELETE_JOB_EXT_BOARD,
        payload: res.data,
      });
      await dispatch(
        setAlert("Deleted job post from the external job board", "success")
      );
    } catch (error) {
      await dispatch(handleServerError(error));
    }
  };

export const updateCandidateApplicationStatus =
  (jobPostId, candidateUserId, newStatus) => async (dispatch) => {
    try {
      const body = { jobPostId, candidateUserId, newStatus };
      const config = { headers: { "Content-Type": "application/json" } };
      const res = await axios.post(`/api/jobs/candidate/status`, body, config);

      await dispatch({
        type: UPDATE_CANDIDATE_APPLICATION_STATUS,
        payload: res.data,
      });
    } catch (error) {
      await dispatch(handleServerError(error));
    }
  };

export const updateExternalJobPostBoardApprovalStatus =
  (jobPostId, jobBoardCompanyId, approvalStatus) => async (dispatch) => {
    try {
      const body = {
        jobPostId,
        jobBoardCompanyId,
        approvalStatus,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `/api/jobs/jobPostBoard/approvalStatus`,
        body,
        config
      );
      await dispatch({
        type: UPDATE_EXT_JOB_POST_BOARD_APPROVAL,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      await dispatch(handleServerError(error));
    }
  };

export const createUpdateJob = (companyId, jobDetails) => async (dispatch) => {
  try {
    const body = {
      companyId,
      jobDetails,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/jobs`, body, config);

    await dispatch({
      type: jobDetails.id ? EDIT_JOB : ADD_JOB,
      payload: res.data,
    });

    await dispatch(
      setAlert(
        jobDetails.id
          ? "Successfully edited your job"
          : "Successfully added a job",
        "success"
      )
    );
  } catch (error) {
    console.log(error);
    await dispatch(handleServerError(error));
  }
};

export const applyToJob = (jobPostId) => async (dispatch) => {
  try {
    const body = {
      jobPostId: jobPostId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/jobs/apply`, body, config);
    await dispatch({
      type: APPLY_JOB,
      payload: res.data,
    });
    await dispatch(setAlert("Successfully applied to this job", "success"));
  } catch (error) {
    await dispatch(handleServerError(error));
  }
};

export const getJobPostActivity = (jobPostId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/jobs/jobPostActivity/${jobPostId}`);
    await dispatch({
      type: APPLY_JOB,
      payload: res.data,
    });
  } catch (error) {
    await dispatch(handleServerError(error));
  }
};

export const removeApplicationToJob = (jobPostId) => async (dispatch) => {
  try {
    await axios.del(`/api/jobs/apply/${jobPostId}`);
    await dispatch({
      type: REMOVE_APPLICATION_TO_JOB,
    });
    await dispatch(
      setAlert("Removed your application from this job post", "success")
    );
  } catch (error) {
    await dispatch(handleServerError(error));
  }
};
