import React from "react";
// Material UI
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const ChallengeTheme = ({ challenge, children }) => {
  return (
    <ThemeProvider
      theme={(theme) =>
        createMuiTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              main:
                (challenge && challenge.primaryColor) ||
                theme.palette.primary.main,
            },
          },
        })
      }
    >
      {children}
    </ThemeProvider>
  );
};

export default ChallengeTheme;
