import React, { useState, useEffect } from "react";
// Material UI
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
// Emoji mart
import "emoji-mart/css/emoji-mart.css";
import { Picker, Emoji } from "emoji-mart";

const EmojiPicker = ({ emojiColons, handleEmojiChange }) => {
  const [state, setState] = useState({
    native: true,
    set: "apple",
    theme: "auto",
    emoji: emojiColons || ":ok_hand:",
    title: "Pick your emoji…",
    useButton: false,
    showPreview: false,
  });

  const handleEmojiSelect = (emoji) => {
    handleEmojiChange(emoji);
  };

  useEffect(() => {
    setState({
      ...state,
      emoji: emojiColons,
    });
  }, [emojiColons]);

  const [anchorElPopover, setAnchorElPopover] = React.useState(null);

  const handleClickPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? "emoji-picker-popover" : undefined;

  return (
    <>
      <IconButton onClick={handleClickPopover}>
        <Emoji
          set={"apple"}
          emoji={state.emoji}
          size={24}
          fallback={(emoji, props) => {
            return emoji ? `:${emoji.short_names[0]}:` : props.emoji;
          }}
        />
      </IconButton>
      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Picker {...state} onSelect={handleEmojiSelect} />
      </Popover>
    </>
  );
};

export default EmojiPicker;
