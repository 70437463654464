// React router dom
import { Link } from "react-router-dom";
// React query
import {
  useFetchMyJobsAndPostedStatus,
  useAddExternalJobToJobBoard,
  useDeleteMyJobPostFromExternalBoard,
} from "../../hooks/job";
// Material UI
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import amber from "@material-ui/core/colors/amber";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
// Components
import Spinner from "../Spinner";
import CustomAvatar from "../CustomAvatar";
import EmptyState from "../EmptyState";
// Utils
import { JOB_POST_BOARD_STATUS } from "../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  accordionSummary: {
    padding: 0,
    margin: 0,
  },
  accordionDetails: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.spacing(2),
  },
  companyLogo: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  jobPostCard: {
    cursor: "pointer",
    transition: "0.2s ease-in-out",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
    "&:hover": {
      transition: "0.2s ease-in-out",
      backgroundColor: theme.palette.action.hover,
    },
  },
  pendingChip: {
    backgroundColor: amber[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: amber[900],
    },
    "&:focus": {
      backgroundColor: amber[900],
    },
  },
  deniedChip: {
    backgroundColor: red[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: red[900],
    },
    "&:focus": {
      backgroundColor: red[900],
    },
  },
  approvedChip: {
    backgroundColor: green[900],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: green[900],
    },
    "&:focus": {
      backgroundColor: green[900],
    },
  },
}));

const CommunityJobPostDialog = ({ open, setOpen, jobBoard, companyId }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const {
    isLoading,
    data: myJobs,
    isError,
    error,
  } = useFetchMyJobsAndPostedStatus(companyId, open);

  const addExternalJobToJobBoardMutation = useAddExternalJobToJobBoard();

  const deleteMyJobPostFromExternalBoardMutation =
    useDeleteMyJobPostFromExternalBoard(companyId);

  const handleAddJobToJobBoard = (jobPostId) => {
    const jobBoardId = jobBoard.id;
    addExternalJobToJobBoardMutation.mutate({
      jobBoardId,
      jobPostId,
      companyId,
    });
  };

  const handleDeleteJobPostFromBoard = (jobPostId) => {
    const jobBoardId = jobBoard.id;
    deleteMyJobPostFromExternalBoardMutation.mutate({
      jobPostId,
      jobBoardId,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <Box p={{ xs: 2, md: 4 }}>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        ) : (
          <Box>
            <Box mb={2}>
              <Typography variant="h5" component="h3" gutterBottom>
                <Box fontWeight={600}>
                  Add or remove your job(s) to this job board
                </Box>
              </Typography>
              <Typography variant="body1" component="div" color="textSecondary">
                <Box lineHeight={1.2}>
                  Select from an existing job that you have created to add to
                  this community job board. If you do not have any job postings,
                  follow the instructions to post a job.
                </Box>
              </Typography>
            </Box>
            <Accordion className={classes.accordionRoot}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="add-job-post-accordion-content"
                id="add-job-post-accordion-header"
                className={classes.accordionSummary}
              >
                <Box display="flex" alignItems="center">
                  <CheckCircleOutlineOutlinedIcon color="primary" />
                  <Typography variant="body1" component="div">
                    <Box ml={1} fontWeight={600}>
                      INSTRUCTIONS TO POST A JOB
                    </Box>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Box mb={1}>
                  <Typography
                    variant="h4"
                    component="div"
                    color="textSecondary"
                  >
                    <Box fontWeight={600}>01</Box>
                  </Typography>
                  <Typography>
                    All jobs must be tied to a community page. Follow the steps
                    to create a community page.
                  </Typography>
                  <Box mt={0.5}>
                    <Typography color="primary" component={Link} to="/org/new">
                      Create Community
                    </Typography>
                  </Box>
                </Box>
                <Box mb={0.5}>
                  <Typography
                    variant="h4"
                    component="div"
                    color="textSecondary"
                  >
                    <Box fontWeight={600}>02</Box>
                  </Typography>
                  <Typography>
                    Within the Recruit tab in your community dashboard, create a
                    new job post.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    component="div"
                    color="textSecondary"
                  >
                    <Box fontWeight={600}>03</Box>
                  </Typography>
                  <Typography>
                    Navigate back to this modal, where you will be able to add
                    any job posts you own to this job board.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            {myJobs && myJobs.length > 0 ? (
              <Box mt={2}>
                <Typography
                  variant="body1"
                  component="div"
                  color="textSecondary"
                >
                  <Box fontWeight={600}>My Jobs</Box>
                </Typography>
                <List>
                  {myJobs.map((job, index) => {
                    const approvalStatus = job.approvalStatus;
                    const approvalStatusName =
                      approvalStatus === JOB_POST_BOARD_STATUS.PENDING
                        ? "Pending"
                        : approvalStatus === JOB_POST_BOARD_STATUS.APPROVED
                        ? "Approved"
                        : approvalStatus === JOB_POST_BOARD_STATUS.DENIED
                        ? "Denied"
                        : "";

                    return (
                      <ListItem
                        button
                        className={classes.jobPostCard}
                        key={index}
                      >
                        <Box display="flex" alignItems="center" width="100%">
                          <Box>
                            <CustomAvatar
                              alt={job?.companyName}
                              src={job?.companyLogoPath}
                              fallbackText={job?.companyName}
                              className={classes.companyLogo}
                            />
                          </Box>
                          <Box flexGrow={1}>
                            <Typography variant="body1" component="div">
                              <Box lineHeight={1.2} fontWeight={600}>
                                {job.title}
                              </Box>
                            </Typography>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                            >
                              <Box>{job.companyName}</Box>
                            </Typography>
                          </Box>
                          <Box ml={1}>
                            {job.approvalStatus && (
                              <Tooltip
                                title={`Job post is ${approvalStatusName.toLowerCase()} in this job board`}
                              >
                                <Chip
                                  label={approvalStatusName}
                                  className={
                                    approvalStatus ===
                                    JOB_POST_BOARD_STATUS.PENDING
                                      ? classes.pendingChip
                                      : approvalStatus ===
                                        JOB_POST_BOARD_STATUS.APPROVED
                                      ? classes.approvedChip
                                      : approvalStatus ===
                                        JOB_POST_BOARD_STATUS.DENIED
                                      ? classes.deniedChip
                                      : null
                                  }
                                />
                              </Tooltip>
                            )}
                            {job.alreadyPostedInExternalCommunity ? (
                              <Chip
                                label="Remove"
                                clickable
                                onClick={() =>
                                  handleDeleteJobPostFromBoard(job.jobId)
                                }
                              />
                            ) : (
                              <Chip
                                label="Add"
                                clickable
                                onClick={() =>
                                  handleAddJobToJobBoard(job.jobId)
                                }
                                color="primary"
                              />
                            )}
                          </Box>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            ) : (
              <Box>
                <EmptyState
                  title="No jobs"
                  subtitle="You do not have any job posts to add here."
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default CommunityJobPostDialog;
