import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/outline";
// Components
import InfoSection from "../../shared/InfoSection";
// Images
import eventpage from "../../assets/screenshots/event-pages/event-page.png";
import eventregistrationsettings from "../../assets/screenshots/event-pages/event-registration-settings.png";
import communityevents from "../../assets/screenshots/event-pages/community-events.png";
import orgeventattendees from "../../assets/screenshots/event-pages/org-event-attendees.png";
import orgeventregistrations from "../../assets/screenshots/event-pages/org-event-registrations.png";
// Icons
import { IoMdSettings, IoMdPeople } from "react-icons/io";
import { CgWebsite } from "react-icons/cg";
import { BsGraphUp } from "react-icons/bs";

const features = [
  {
    name: "Custom Branding",
    description:
      "Customize your event page with a custom header image, description, and tags. Bring your overall community brand into the page as well.",
  },
  {
    name: "Calendar",
    description:
      "All events are automatically added to your community’s calendar. Also, each attendee can easily add your event to their personal calendar on the event page.",
  },
  {
    name: "In-Person, Hybrid, and Online",
    description:
      "Let your attendees know whether your event is in-person, hybrid, or online. Add links to Zoom meetings and information about physical locations as well.",
  },
  {
    name: "Insights",
    description:
      "Get easy access to registration and demographics data including gender race, school, education level, interests, skills, and more.",
  },
  {
    name: "Social Media",
    description:
      "In a couple of clicks you and your event attendees can share your event to the entire world through social media.",
  },
  {
    name: "Attendee Management",
    description:
      "You can also individually approve, deny, and remove attendees, and set a limit to the number of attendees.",
  },
  {
    name: "Registration Settings",
    description:
      "Customize the method of registration for your event. Require registration, limit attendees or setup an approval process.",
  },
  {
    name: "Custom Emails",
    description:
      "Advanced email capabilities including email scheduling, email customization, email reminders, and more!",
    comingSoon: true,
  },
];

export default function FeaturesEvents() {
  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="bg-gradient-to-br from-orange-100 via-white to-red-100 pb-8 sm:pb-12 lg:pb-12">
          <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
              <div>
                <div className="mt-20">
                  <div>
                    <span className="rounded bg-red-100 px-2.5 py-1 text-xs font-semibold text-red-600 tracking-wide uppercase">
                      Events
                    </span>
                  </div>
                  <div className="mt-6 sm:max-w-xl">
                    <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                      🎟️ Host events for your members
                    </h1>
                    <p className="mt-6 text-xl text-gray-500">
                      Create and host personalized experiences of any kind that
                      you can share with your community.
                    </p>
                    <div className="mt-4 md:mt-8">
                      <Link
                        to="/signup?redirect=/org/new"
                        className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-500 bg-opacity-90 hover:bg-opacity-80 sm:px-8"
                      >
                        Get started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
              <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <div className="hidden sm:block">
                  <svg
                    className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                    width={404}
                    height={392}
                    fill="none"
                    viewBox="0 0 404 392"
                  >
                    <defs>
                      <pattern
                        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                        x={0}
                        y={0}
                        width={20}
                        height={20}
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x={0}
                          y={0}
                          width={4}
                          height={4}
                          className="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width={404}
                      height={392}
                      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                  </svg>
                </div>
                <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                  <img
                    className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                    src={communityevents}
                    alt="Community events page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative overflow-hidden">
          <InfoSection
            title="Customize your event page for your attendees"
            subtitle="Your event deserves a clear and unique page for every participant to see. Customize your header photo, descriptions, tags and more all to your liking."
            image={eventpage}
            topText="Event Page"
            textLeft={false}
            color="orange"
            secondaryColor="pink"
            icon={CgWebsite}
          />
          <InfoSection
            title="Advanced settings"
            subtitle="Link to your Zoom or location or both! Manage event privacy and custom registration settings."
            image={eventregistrationsettings}
            topText="Settings"
            textLeft
            color="orange"
            secondaryColor="pink"
            icon={IoMdSettings}
            bggray
          />
          <InfoSection
            title="View event attendees and manage approvals"
            subtitle="Limit the number of registrations, setup an approval process for your event, and more."
            image={orgeventattendees}
            topText="Attendees"
            textLeft={false}
            color="orange"
            secondaryColor="pink"
            icon={IoMdPeople}
          />
          <InfoSection
            title="Track registrations and learn about your attendees"
            subtitle="Analyze event registrations and learn more about the individuals attending your event."
            image={orgeventregistrations}
            topText="Insights"
            textLeft
            color="orange"
            secondaryColor="pink"
            icon={BsGraphUp}
            bggray
          />
        </div>

        {/* Gradient Feature Section */}
        <div className="bg-gray-900">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-center text-3xl md:text-4xl font-extrabold text-gray-100 tracking-tight">
              Advanced features to host your event
            </h2>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <CheckIcon
                      className="absolute h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p className="ml-9 text-lg leading-6 font-semibold text-gray-100">
                      {feature.name}{" "}
                      {feature.comingSoon && (
                        <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-pink-200 text-pink-800">
                          Coming Soon
                        </span>
                      )}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-9 text-base text-gray-400">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to dive in?</span>
              <span className="block">Get started with events today.</span>
            </h2>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/signup?redirect=/org/new"
                  className="px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-500 bg-opacity-90 hover:bg-opacity-80 sm:px-8"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
