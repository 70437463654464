import React, { useEffect } from "react";
// React-Router
import { Link } from "react-router-dom";
import { Route, useRouteMatch, useLocation } from "react-router";
// Redux
import { connect } from "react-redux";
import {
  getJobBoardByCompanyId,
  clearCompanyJobBoard,
} from "../../redux/actions/jobs";
// Components
import OrgAppBar from "../../shared/OrgAppBar";
import Spinner from "../../shared/Spinner";
import CustomSwitch from "../../shared/CustomSwitch";
import JobBoardSettingsView from "./components/JobBoardSettingsView";
import JobsView from "./components/JobsView";
// Material UI
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Alert from "@material-ui/lab/Alert";
// Icons
import { IoBriefcaseOutline } from "react-icons/io5";
// Utils
import PerfectScrollbar from "react-perfect-scrollbar";

function a11yProps(index) {
  return {
    id: `jobs-tab-${index}`,
    "aria-controls": `jobs-tabpanel-${index}`,
  };
}

const OrgJobBoard = ({
  jobBoard: {
    loading: jobBoardLoading,
    data: jobBoard,
    error: jobBoardError,
  } = { loading: false, data: null, error: null },
  companyProfile,
  getJobBoardByCompanyId,
}) => {
  let { url } = useRouteMatch();
  let location = useLocation();

  useEffect(() => {
    if (companyProfile && companyProfile.id) {
      getJobBoardByCompanyId(companyProfile.id);
    }
  }, [companyProfile]);

  return (
    <>
      {jobBoardError && jobBoardError.data && (
        <Alert severity="error">{jobBoardError.data.msg}</Alert>
      )}
      {jobBoardLoading ? (
        <Spinner />
      ) : (
        <>
          <OrgAppBar
            title={
              <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
                <IoBriefcaseOutline /> Job Board
              </Box>
            }
            subtitle="View all of the jobs in your job board"
          />

          <PerfectScrollbar>
            <Box>
              <AppBar position="static" style={{ paddingBottom: 0 }}>
                <Tabs
                  variant="scrollable"
                  scrollButtons="off"
                  value={location.pathname}
                  aria-label="jobs tabs"
                >
                  <Tab
                    component={Link}
                    value={`${url}`}
                    to={`${url}`}
                    label="Edit"
                    {...a11yProps(`${url}`)}
                  />
                  <Tab
                    component={Link}
                    value={`${url}/jobs`}
                    to={`${url}/jobs`}
                    label="Jobs"
                    {...a11yProps(`${url}/jobs`)}
                  />
                </Tabs>
              </AppBar>

              <CustomSwitch>
                <Route exact path={`${url}`}>
                  <JobBoardSettingsView />
                </Route>
                <Route exact path={`${url}/jobs`}>
                  <JobsView />
                </Route>
              </CustomSwitch>
            </Box>
          </PerfectScrollbar>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  jobBoard: state.jobs.jobBoard,
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = { getJobBoardByCompanyId, clearCompanyJobBoard };

export default connect(mapStateToProps, mapDispatchToProps)(OrgJobBoard);
