import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import { addLike, removeLike } from "../../redux/actions/project";
// Utils
import Moment from "react-moment";
import Markdown from "markdown-to-jsx";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Chip from "@material-ui/core/Chip";
// icons
import YouTubeIcon from "@material-ui/icons/YouTube";
import PermMediaIcon from "@material-ui/icons/PermMedia";
import CodeIcon from "@material-ui/icons/Code";
import LanguageIcon from "@material-ui/icons/Language";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
// components
import Button from "../../shared/Button";
import SocialMediaShare from "../../shared/SocialMediaShare";
import PlaceholderProjectImage from "../PlaceholderProjectImage";
import {
  MarkdownH1,
  MarkdownH2,
  MarkdownH3,
  MarkdownH4,
  Paragraph,
  ATag,
  Ul,
  Ol,
} from "./components/DescriptionTags";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: "50px",
    height: "50px",
  },
  button: {
    fontWeight: "600",
  },
  chipPaper: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  chip: {
    margin: theme.spacing(0.2),
    fontSize: 12,
    borderRadius: theme.shape.borderRadius,
    fontWeight: 400,
  },
  header: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingBottom: 0,
    "& > div": {
      textDecoration: "none",
      color: theme.palette.text.primary,
    },
    "&:hover": {
      textDecoration: "none",
    },
  },
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  editButton: {
    color: theme.palette.warning.main,
    fontWeight: "600",
    fontSize: 12,
  },
  likeButton: {
    fontWeight: "600",
    "&:focus": {
      outline: "none",
    },
  },
  commentButton: {
    fontWeight: "600",
    "&:focus": {
      outline: "none",
    },
  },
  description: {
    whiteSpace: "pre-line",
  },
}));

const ComplexProjectCard = ({
  user,
  isAuthenticated,
  addLike,
  removeLike,
  project,
  projectCategories,
  extLinks,
  projectStatus,
  judging = false,
}) => {
  const classes = useStyles();
  const [categoryNames, setCategoryNames] = useState([]);
  const [linkIcons] = useState({
    1: <CodeIcon />,
    2: <PermMediaIcon />,
    3: <DescriptionIcon />,
    4: <YouTubeIcon />,
    5: <AccountBalanceIcon />,
    6: <LanguageIcon />,
  });
  const [youtubeVideoId, setYouTubeVideoId] = useState("");

  const [status, setProjectStatus] = useState("");
  useEffect(() => {
    const found =
      projectStatus &&
      projectStatus.length > 0 &&
      projectStatus.find((elem) => elem.id === project.projectStatusId);
    setProjectStatus(found ? found.name : "");
  }, [project]);

  useEffect(() => {
    const categories = project.categories;
    let newCategoryNames = [];
    if (categories) {
      for (let i = 0; i < categories.length; i++) {
        const found = projectCategories.findIndex(
          (elem) => elem.id === categories[i]
        );
        if (found !== -1) {
          newCategoryNames.push(projectCategories[found].name);
        }
      }
      setCategoryNames(newCategoryNames);
    }
  }, [project]);

  useEffect(() => {
    if (project && project.extLinks) {
      for (let i = 0; i < project.extLinks.length; i++) {
        const currentExtLink = project.extLinks[i];
        const extLinkTypeId = currentExtLink.extLinkTypeId;
        const externalUrl = currentExtLink.externalUrl;
        if (extLinkTypeId === 4) {
          const split = externalUrl.split("v=")[1];
          let string;
          if (split) {
            const secondIndex = split.indexOf("&");
            string = split.substring(
              0,
              secondIndex !== -1 ? secondIndex : split.length
            );
          }
          setYouTubeVideoId(string || "");
        }
      }
    }
  }, [project]);

  const handleLikeButtonClick = (projectId) => {
    const isProjectCard = true;
    if (!user || !isAuthenticated) return;
    const found =
      project && project.likes.find((elem) => elem.userAccountId === user.id);
    if (found) {
      removeLike(projectId, user.id, isProjectCard);
    } else {
      addLike(projectId, isProjectCard);
    }
  };

  return (
    <>
      <Box display="block" height="100%" width="100%">
        {project &&
        project.projectImages &&
        project.projectImages.length > 0 ? (
          <CardMedia
            className={classes.media}
            image={project.projectImages[0].filePath}
            title={project.projectImages[0].filePath}
          />
        ) : youtubeVideoId && youtubeVideoId !== "" ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              paddingBottom: "56.25%",
              height: "0",
            }}
          >
            <iframe
              title={youtubeVideoId}
              src={`https://www.youtube.com/embed/${youtubeVideoId}`}
              frameBorder="0"
              top="0"
              left="0"
              width="100%"
              height="100%"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
              }}
            />
          </div>
        ) : (
          <PlaceholderProjectImage categories={project && project.categories} />
        )}

        <CardContent className={classes.content}>
          <Typography variant="h5" component="div">
            <Box fontWeight={600} lineHeight={1.2} mb={2}>
              {project && project.title}
            </Box>
          </Typography>
          <Box className={classes.chipPaper}>
            {categoryNames &&
              categoryNames.length > 0 &&
              categoryNames.map((name, index) => {
                return (
                  <Chip key={index} label={name} className={classes.chip} />
                );
              })}
            {project &&
              project.skills &&
              project.skills.length > 0 &&
              project.skills.map((skill, index) => {
                return (
                  <Chip
                    key={index}
                    label={skill.name}
                    className={classes.chip}
                  />
                );
              })}
          </Box>
          <Chip
            label={
              <div>
                {project && project.startDate && (
                  <Moment format="MMM YYYY">{project.startDate}</Moment>
                )}{" "}
                -{" "}
                {project && project.projectStatusId === 3 && project.endDate ? (
                  <Moment format="MMM YYYY">{project.endDate}</Moment>
                ) : (
                  "Present"
                )}
              </div>
            }
            className={classes.chip}
            icon={<AccessTimeIcon color="primary" />}
          />

          <Chip
            label={status}
            className={classes.chip}
            icon={<TimelapseIcon color="primary" />}
          />
          {project && project.description && project.description !== "" && (
            <Box className={classes.description} mt={{ xs: 2, md: 4 }}>
              <Markdown
                options={{
                  forceBlock: true,
                  overrides: {
                    h1: {
                      component: MarkdownH1,
                    },
                    h2: {
                      component: MarkdownH2,
                    },
                    h3: {
                      component: MarkdownH3,
                    },
                    h4: {
                      component: MarkdownH4,
                    },
                    a: {
                      component: ATag,
                    },
                    p: {
                      component: Paragraph,
                    },
                    ul: {
                      component: Ul,
                    },
                    ol: {
                      component: Ol,
                    },
                  },
                }}
              >
                {project && project.description}
              </Markdown>
            </Box>
          )}
        </CardContent>
        {!judging && (
          <CardActions>
            {!isAuthenticated ? (
              <RouterLink to="/login">
                <Button
                  variant="text"
                  color="default"
                  startIcon={<FavoriteBorderIcon />}
                >
                  Like{"  "}
                  {project && project.likes && project.likes.length > 0 && (
                    <Fragment>
                      <span>&#183;</span>
                      {"  "}
                      {project.likes.length}
                    </Fragment>
                  )}
                </Button>
              </RouterLink>
            ) : (
              <Button
                variant="text"
                color={
                  project &&
                  project.likes.find((elem) => elem.userAccountId === user.id)
                    ? "primary"
                    : "default"
                }
                onClick={() => handleLikeButtonClick(project.id)}
                startIcon={<FavoriteBorderIcon />}
              >
                Like
                {project && project.likes && project.likes.length > 0 && (
                  <Fragment>
                    <span style={{ margin: "0px 5px" }}>&#183;</span>
                    {project.likes.length}
                  </Fragment>
                )}
              </Button>
            )}
            <SocialMediaShare
              url={`${document.location.origin}/projects/${project.id}`}
              title={`Check out this project - ${project.title}`}
            />
          </CardActions>
        )}
      </Box>
    </>
  );
};

ComplexProjectCard.propTypes = {
  user: PropTypes.object,
  project: PropTypes.object.isRequired,
  projectCategories: PropTypes.array.isRequired,
  extLinks: PropTypes.array.isRequired,
  projectStatus: PropTypes.array.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  projectCategories: state.typedef.projectCategories,
  extLinks: state.typedef.extLinks,
  projectStatus: state.typedef.projectStatus,
});

export default connect(mapStateToProps, { addLike, removeLike })(
  ComplexProjectCard
);
