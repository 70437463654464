import axios from "../../utils/axios";
import { setAlert } from "./alert";

import {
  GET_PROFILE,
  GET_PROFILES,
  GET_REPOS,
  UPDATE_PROFILE,
  ACCOUNT_DELETED,
  CLEAR_PROFILE,
  CLEAR_PROFILES,
  CLEAR_MYPROFILE,
  GET_MYPROFILE,
  PROFILE_LOADING,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_RESUME,
  DELETE_EXPERIENCE,
  ADD_PROFILE_LINKS,
  ADD_PROFILE_ANSWERS,
  GET_PROFILE_SKILLS,
  ADD_PROFILE_SKILLS,
  LOADING_ORGANIZATIONS,
  GET_MY_ORGANIZATIONS,
  CLEAR_MY_ORGANIZATIONS,
} from "./actionTypes";
import { SKIP, LIMIT, USER_TYPE } from "../../utils/globalValues";
import { handleServerError } from "./error";
//import { Event } from "../../utils/tracking";
//import analytics from "../../analytics";
// import {
//   A_ADD_EDIT_EXPERIENCE,
//   A_CREATE_EDIT_PROFILE,
//   A_ADD_EDIT_EDUCATION,
//   A_UPLOAD_RESUME,
//   A_ADD_EDIT_LINKS_PROFILE,
//   A_ADD_EDIT_QUESTIONS_PROFILE,
//   A_ADD_EDIT_SKILLS,
// } from "../../analytics/eventTypes";

// Get the current users profile
export const getCurrentProfile = () => async (dispatch) => {
  await dispatch({
    type: PROFILE_LOADING,
  });
  try {
    const res = await axios.get("/api/profile/me");
    if (res.status !== 204) {
      dispatch({
        type: GET_MYPROFILE,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_MYPROFILE,
        payload: null,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Get all profiles public
export const getPublicProfiles = (skip, limit, typeId) => async (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });

  //if (skip === SKIP) {
  dispatch({
    type: PROFILE_LOADING,
  });
  //}
  try {
    //const res = await axios.get("/api/profile/all/public");
    let res;
    if (typeId === USER_TYPE.COMPANY) {
      res = await axios.get(`/api/profile/all/publicprivate/${skip}/${limit}`);
    } else {
      res = await axios.get(`/api/profile/all/public/${skip}/${limit}`);
    }

    dispatch({
      type: GET_PROFILES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearProfilesInState = () => async (dispatch) => {
  try {
    await dispatch({ type: PROFILE_LOADING });
    await dispatch({
      type: CLEAR_PROFILES,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Get profile by ID
export const getProfileByID = (userId) => async (dispatch) => {
  dispatch({
    type: PROFILE_LOADING,
  });
  try {
    const res = await axios.get(`/api/profile/user/${userId}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
}; // Get profile by Username
export const getProfileByUserName = (userName) => async (dispatch) => {
  dispatch({
    type: PROFILE_LOADING,
  });
  try {
    const res = await axios.get(`/api/profile/username/${userName}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data || null,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Get github repos
export const getGithubRepos = (username) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/profile/github/${username}`);

    dispatch({
      type: GET_REPOS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Create or update a profile
export const createProfile =
  (formData, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.log("Creating a new profile");
      const res = await axios.post("/api/profile", formData, config);

      // analytics Track Profile Creation
      // analytics.track(A_CREATE_EDIT_PROFILE, { edit: edit });
      // analytics.people.set({
      //   $name: `${formData.firstName} ${formData.lastName}`,
      //   firstName: formData.firstName,
      //   lastName: formData.lastName,
      //   aboutMe: formData.aboutMe,
      //   race: formData.race,
      //   gender: formData.gender,
      //   activelySeekingJob: formData.activelySeekingJob,
      //   privacySetting: formData.privacySetting,
      // });

      //Event("PROFILE", `Created User Profile`, "CREATE USER PROFILE");

      dispatch({
        type: GET_MYPROFILE,
        payload: res.data,
      });
      dispatch(
        setAlert(
          edit
            ? "Great work! Your profile has been successfully edited."
            : "Ready to go! Your profile has been successfully created.",
          "success"
        )
      );
    } catch (error) {
      console.log(error);
      dispatch(handleServerError(error));
    }
  };

export const getAllMyOrganizations = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/company/all/myOrganizations");
    await dispatch({
      type: GET_MY_ORGANIZATIONS,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: GET_MY_ORGANIZATIONS,
      payload: [],
    });
    dispatch(handleServerError(error));
  }
};

export const clearAllOrganizations = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_MY_ORGANIZATIONS });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Create or update a profile
export const addProfile =
  (formData, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //console.log("Creating a new profile 2");
      const res = await axios.post("/api/profile", formData, config);
      //Event("PROFILE", `Created User Profile`, "CREATE USER PROFILE");
      dispatch({
        type: GET_MYPROFILE,
        payload: res.data,
      });
      dispatch(
        setAlert(
          edit
            ? "Great Work! You Have edited Your Profile"
            : "Ready To Go! Your Profile Has Been Created",
          "success"
        )
      );
    } catch (error) {
      dispatch(handleServerError(error));
    }
  };

// Get the current user's skills
export const getUserSkills = () => async (dispatch) => {
  dispatch({
    type: PROFILE_LOADING,
  });
  try {
    const res = await axios.get("/api/profile/skill/");
    if (res.status !== 204) {
      dispatch({
        type: GET_PROFILE_SKILLS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PROFILE_SKILLS,
        payload: null,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add skills
export const addProfileSkills = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.post("/api/profile/skillset", formData, config);

    // analytics.track(A_ADD_EDIT_SKILLS);
    // analytics.people.set({
    //   numberOfSkills: formData.selectedSkills.length,
    // });

    //Event("PROFILE", `Added User Skills`, "MODIFIED PROFILE SKILLS");
    dispatch({
      type: ADD_PROFILE_SKILLS,
      payload: formData,
    });
    dispatch(setAlert("Successfully updated your skills.", "success"));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add experience
export const addExperience = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/profile/experience", formData, config);

    // analytics analytics tracking function
    // analytics.track(A_ADD_EDIT_EXPERIENCE, {
    //   edit: formData.hasOwnProperty("id"),
    //   companyName: formData.companyName,
    //   jobTitle: formData.jobTitle,
    //   description: formData.description,
    //   isCurrentJob: formData.isCurrentJob,
    //   endDate: formData.endDate,
    //   startDate: formData.startDate,
    // });

    //Event("PROFILE", `Added User Experience`, "MODIFIED EXPERIENCE");
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });
    dispatch(
      setAlert(
        "Looking Good! You Have Added Experience To Your Profile.",
        "success"
      )
    );
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Upload User Profile Image
export const uploadProfileImage = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/users/upload", formData, config);

    dispatch({
      type: UPLOAD_PROFILE_IMAGE,
      payload: res.data,
    });
    dispatch(setAlert("You Have Added an Image To Your Profile.", "success"));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add demographics
export const addDemographics = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/profile/demographics", formData, config);

    // Chaning Democgraphics people (profile) function analytics
    // analytics.people.set({
    //   race: formData.race,
    //   gender: formData.gender,
    // });

    //Event("PROFILE", `Added Demographics Information`, "ADD DEMOGRAPHICS");
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });
    dispatch(
      setAlert("Successfully updated demographics in your profile.", "success")
    );
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
// Add education
export const addEducation = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/profile/education", formData, config);

    // analytics tracking
    // console.log(formData);
    // analytics.track(A_ADD_EDIT_EDUCATION, {
    //   school: formData.school,
    //   degree: formData.degree,
    //   degreeCompletionDate: formData.completionDate,
    //   fieldOfStudy: formData.fieldOfStudy,
    //   degreeStartDate: formData.startDate,
    // });
    // analytics.people.set({
    //   school: formData.school,
    //   degree: formData.degree,
    //   degreeCompletionDate: formData.completionDate,
    //   fieldOfStudy: formData.fieldOfStudy,
    //   degreeStartDate: formData.startDate,
    // });

    //Event("PROFILE", `Added User Education`, "ADD EDUCATION");
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });
    dispatch(
      setAlert("Successfully updated education in your profile.", "success")
    );
  } catch (error) {
    console.log(error);
    dispatch(handleServerError(error));
  }
};

// Delete experience
export const deleteExperience = (exp_id) => async (dispatch) => {
  if (
    window.confirm(
      "Are you sure you want to delete this experience? This cannot be undone."
    )
  ) {
    try {
      await axios.del(`/api/profile/experience/${exp_id}`);

      dispatch({
        type: DELETE_EXPERIENCE,
        payload: exp_id,
      });
      dispatch(setAlert("Your Experience Has Been Removed", "success"));
    } catch (error) {
      dispatch(handleServerError(error));
    }
  }
};
// Delete education
export const deleteEducation = (edu_id) => async (dispatch) => {
  try {
    const res = await axios.del(`api/profile/education/${edu_id}`);
    dispatch({
      type: UPDATE_PROFILE,
      payload: res.data,
    });
    dispatch(setAlert("Your Education Has Been Removed", "success"));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const clearMyProfile = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_MYPROFILE,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
// Delete Account
export const deleteAccount = () => async (dispatch) => {
  if (
    window.confirm(
      "Are you sure you want to delete your account? This cannot be undone."
    )
  ) {
    try {
      await axios.del(`api/profile`);

      dispatch({ type: CLEAR_PROFILE });
      dispatch({ type: CLEAR_MYPROFILE });
      dispatch({ type: ACCOUNT_DELETED });

      dispatch(setAlert("Your account has been permanently deleted :("));
    } catch (error) {
      dispatch(handleServerError(error));
    }
  }
};

export const uploadResume = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axios.post(
      "/api/profile/upload/resume",
      formData,
      config
    );

    // analytics Tracking
    // analytics.people.set({
    //   hasResume: true,
    // });
    // analytics.track(A_UPLOAD_RESUME);

    dispatch({
      type: UPLOAD_RESUME,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add links
export const addProfileLinks = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      "/api/profile/publicProfileLink",
      formData,
      config
    );

    // analytics Tracking
    // analytics.people.set({
    //   codeRepositoryURL: formData.publicProfileLinks[0].url,
    //   linkedinURL: formData.publicProfileLinks[1].url,
    //   personalWebsiteURL: formData.publicProfileLinks[2].url,
    // });
    // analytics.track(A_ADD_EDIT_LINKS_PROFILE);
    dispatch({
      type: ADD_PROFILE_LINKS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Add answers
export const addProfileQuestions = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      "/api/profile/questionchoice",
      formData,
      config
    );

    // analytics tracking
    let trackProfileQuestions = false;
    for (let i = 0; i < formData.questionChoice.length; i++) {
      if (
        formData.questionChoice[i].answers &&
        formData.questionChoice[i].answers.length > 0
      ) {
        trackProfileQuestions = true;
        break;
      }
    }
    // analytics.people.set({
    //   answeredProfileQuestions: trackProfileQuestions,
    // });
    // analytics.track(A_ADD_EDIT_QUESTIONS_PROFILE);

    dispatch({
      type: ADD_PROFILE_ANSWERS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
