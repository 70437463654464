import {
  GET_SKILLSET,
  QUESTIONS_ANSWERS,
  PROJECT_CATEGORIES,
  PROJECT_STATUS,
  EXTLINK_TYPE,
  PUBLIC_PROFILE_TYPE,
  JOB_CATEGORY_TYPE,
  GENDER_TYPE,
  RACE_TYPE
} from "./actionTypes";
import axios from "../../utils/axios";
import { handleServerError } from "./error";

export const loadTypeDef = () => async (dispatch) => {
  await dispatch(getSkillSet());
  await dispatch(getQuestionsPlusAnswers());
  await dispatch(getProjectCategories());
  await dispatch(getProjectStatus());
  await dispatch(getExtLinkTypes());
  await dispatch(getPublicProfileTypes());
  await dispatch(getJobCategoryType());
  await dispatch(getGenderType());
  await dispatch(getRaceType());
};

export const getSkillSet = () => async (dispatch) => {
  try {
    //const res = await axios({ url: "/api/typedef/skillset", method: "GET" });
    const res = await axios.get("/api/typedef/skillset");
    dispatch({
      type: GET_SKILLSET,
      payload: res.data,
    });
  } catch (error) {
    //console.log(error && error.response);
    dispatch(handleServerError(error));
  }
};

export const getQuestionsPlusAnswers = () => async (dispatch) => {
  try {
    /* const res = await axios({
      url: "/api/typedef/questionplusanswers",
      method: "GET",
    }); */
    const res = await axios.get("/api/typedef/questionplusanswers");
    dispatch({
      type: QUESTIONS_ANSWERS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getProjectCategories = () => async (dispatch) => {
  try {
    /* const res = await axios({
      url: "/api/typedef/projectcategories",
      method: "GET",
    }); */
    const res = await axios.get("/api/typedef/projectcategories");
    dispatch({
      type: PROJECT_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getProjectStatus = () => async (dispatch) => {
  try {
    /* const res = await axios({
      url: "/api/typedef/projectstatus",
      method: "GET",
    }); */
    const res = await axios.get("/api/typedef/projectstatus");
    dispatch({
      type: PROJECT_STATUS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getExtLinkTypes = () => async (dispatch) => {
  try {
    /* const res = await axios({
      url: "/api/typedef/extlinktype",
      method: "GET",
    }); */
    const res = await axios.get("/api/typedef/extlinktype");
    dispatch({
      type: EXTLINK_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getPublicProfileTypes = () => async (dispatch) => {
  try {
    /* const res = await axios({
      url: "/api/typedef/publicprofiletype",
      method: "GET",
    }); */
    const res = await axios.get("/api/typedef/publicprofiletype");
    dispatch({
      type: PUBLIC_PROFILE_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getJobCategoryType = () => async (dispatch) => {
  try {
    /* const res = await axios({
      url: "/api/typedef/jobcategorytype",
      method: "GET",
    }); */
    const res = await axios.get("/api/typedef/jobcategorytype");
    dispatch({
      type: JOB_CATEGORY_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getGenderType = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/typedef/gendertype");
    dispatch({
      type: GENDER_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getRaceType = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/typedef/racetype");
    dispatch({
      type: RACE_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};