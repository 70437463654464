import React, { Fragment, useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
// Components
import ChallengeRegistrationsDownload from "./ChallengeRegistrationsDownload";
import RegistrationSettings from "./RegistrationSettings";
import RegisterUser from "./RegisterUser";
import Button from "../../../shared/Button";
import OrgAppBar from "../../../shared/OrgAppBar";
import LinkToHelp from "../../../shared/LinkToHelp";
//Icons
import EmailIcon from "@material-ui/icons/Email";

export const ChallengeRegistrationsActions = ({ loading, registrations }) => {
  const [allEmails, setAllEmails] = useState([]);
  const [allTeams, setAllTeams] = useState([]);

  useEffect(() => {
    let newEmails = [];
    let teams = [];

    if (!loading && registrations) {
      for (let indx = 0; indx < registrations.length; indx++) {
        if (registrations[indx].team && registrations[indx].team.members) {
          teams.push(registrations[indx].team);
          const members = registrations[indx].team.members;
          for (let i = 0; i < members.length; i++) {
            const member = members[i];
            if (member && member.user) {
              const foundEmail = member.user && member.user.email;
              newEmails.push(foundEmail);
            }
          }
        }
      }
    }
    setAllTeams(teams);
    setAllEmails(newEmails);
  }, [registrations]);

  const AppBarActions = () => {
    return (
      <>
        <RegisterUser />

        {allEmails && allEmails.length > 0 && (
          <Tooltip
            title={"Contact all teams registered for the program"}
            placement="top"
          >
            <Button
              variant="text"
              color="primary"
              startIcon={<EmailIcon />}
              href={`mailto:${allEmails && allEmails.join(",")}`}
            >
              Contact All
            </Button>
          </Tooltip>
        )}

        <ChallengeRegistrationsDownload />

        <RegistrationSettings />
      </>
    );
  };

  return (
    <OrgAppBar
      title="Registrations"
      subtitle={
        <Box display="flex" alignItems="center">
          <Typography variant="body2" component="div">
            <Box mr={1}>
              {allTeams.length || 0} Teams, {allEmails.length || 0} People have
              registered
            </Box>
          </Typography>
          <LinkToHelp
            label="Registrations 101"
            link="https://www.notion.so/Registrations-56717bcaf7694d0bbf20e97b47db2760"
          />
        </Box>
      }
      actions={<AppBarActions />}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  registrations: state.challenge.registrations,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeRegistrationsActions);
