import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// Icons
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const ManageAppBar = ({ myOrganizations, companyDetails }) => {
  let foundManageCommunity =
    myOrganizations &&
    (myOrganizations || []).findIndex(
      (elem) => elem.companyId === companyDetails.id
    );

  const manageCommunity =
    Number.isInteger(foundManageCommunity) && foundManageCommunity >= 0;

  if (!manageCommunity) return <Box />;

  return (
    <Box m={1}>
      <Button
        fullWidth
        component={Link}
        to={`/org/${companyDetails?.orgId}/home`}
      >
        Click here to manage your community <ArrowForwardIcon />
      </Button>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  myOrganizations: state.profile.myOrganizations,
});

export default connect(mapStateToProps)(ManageAppBar);
