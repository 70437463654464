import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// Icons
import { AiOutlineFile } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(2),
  },
  text: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export const ProjectFiles = ({ project }) => {
  const classes = useStyles();

  return (
    <Box my={[2, 4]}>
      <Box mb={[1, 2]}>
        <Typography variant="h6">
          <strong>Files</strong>
        </Typography>
      </Box>
      {project && project.projectFiles && project.projectFiles.length ? (
        project.projectFiles.map((file, index) => {
          return (
            <Box mb={[1, 2]} key={index}>
              <a href={file.filePath} target="_blank" rel="noopener noreferrer">
                <Box display="flex" alignItems="center">
                  <AiOutlineFile className={classes.icon} />
                  <Typography variant="body2" className={classes.text}>
                    {file.fileName}
                  </Typography>
                </Box>
              </a>
            </Box>
          );
        })
      ) : (
        <div>
          <Typography variant="body2">
            There are no files for this project.
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default ProjectFiles;
