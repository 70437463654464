import React from "react";
// react query
import { useMutation, useQueryClient } from "react-query";
import {
  useGetAllMembersInRoom,
  removeUserFromRoom,
} from "../../../hooks/room";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
// Icons
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
// Components
import CustomAvatar from "../../../shared/CustomAvatar";
import Button from "../../../shared/Button";
import PTable from "../../../shared/PTable";
import Spinner from "../../../shared/Spinner";
import ProfilePopover from "../../../shared/ProfilePopover";
import OrgRoomMemberStatus from "./OrgRoomMemberStatus";
// Utils
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";
import { DEFAULT_TIME_ZONE } from "../../../utils/globalValues";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const MemberInfo = ({ member }) => {
  const classes = useStyles();

  const memberUser = member.user;
  const memberProfile = member.profile;

  const memberEducation =
    memberProfile &&
    memberProfile.education &&
    memberProfile.education.length > 0 &&
    memberProfile.education[0];
  return (
    <Box minWidth={250} display="flex" alignItems="center">
      <CustomAvatar
        src={memberUser && memberUser.avatar}
        fallbackText={memberUser && memberUser.userName}
        alt={`${memberUser && memberUser.userName}-avatar`}
        className={classes.avatar}
      />
      <Box>
        <Typography variant="body1" component="div">
          <Box lineHeight={1.2} fontWeight={600}>
            {memberProfile
              ? `${memberProfile.firstName} ${memberProfile.lastName}`
              : memberUser && memberUser.userName}
          </Box>
        </Typography>
        {memberEducation && (
          <>
            <Typography variant="caption" component="div" color="textSecondary">
              <Box lineHeight={1.2}>{memberEducation.fieldOfStudy}</Box>
            </Typography>
            <Typography variant="caption" component="div" color="textSecondary">
              <Box lineHeight={1.2}>
                {memberEducation.school},{" "}
                {memberEducation.completionDate &&
                  new Date(memberEducation.completionDate).getFullYear()}
              </Box>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

const OrgRoomMembersTable = ({ orgRoom }) => {
  const roomId = orgRoom?.id;

  const queryClient = useQueryClient();

  const {
    isFetching: isFetchingRoomMembers,
    isLoading: isLoadingRoomMembers,
    data: roomMembers,
    isError: isErrorRoomMembers,
    error: errorRoomMembers,
  } = useGetAllMembersInRoom(roomId, false);

  const roomMembersKeys = Object.keys(roomMembers || {});

  const removeUserFromRoomMutation = useMutation(removeUserFromRoom, {
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [
          "roomMembers",
          {
            roomId: roomId,
            onlyApproved: false,
          },
        ],
        (oldMembers) => {
          let updatedMembers = { ...oldMembers };
          delete updatedMembers[variables.userAccountId];
          return updatedMembers;
        }
      );
    },
  });

  const handleRemoveUserFromRoom = (userAccountId, roomId) => {
    if (
      window.confirm(
        "Are you sure that you want to remove this user from the room?"
      )
    ) {
      removeUserFromRoomMutation.mutate({
        userAccountId: userAccountId,
        roomId: roomId,
      });
    }
  };

  const headerCells = [
    {
      id: "id",
      label: "id",
      hide: true,
      disableSorting: true,
      filterable: false,
    },
    {
      id: "member",
      label: "Member",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const name1 =
          param1 && param1.profile && param1.profile.firstName
            ? param1.profile.firstName
            : param1 && param1.user && param1.user.userName
            ? param1.user.userName
            : "";
        const name2 =
          param2 && param2.profile && param2.profile.firstName
            ? param2.profile.firstName
            : param2 && param2.user && param2.user.userName
            ? param2.user.userName
            : "";

        return name1.localeCompare(name2);
      },
    },
    {
      id: "email",
      label: "Email address",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        const email1 =
          param1 && param1.user && param1.user.email ? param1.user.email : "";
        const email2 =
          param2 && param2.user && param2.user.email ? param2.user.email : "";

        return email1.localeCompare(email2);
      },
    },
    {
      id: "joinedDateTime",
      label: "Joined Date & Time",
      hide: false,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        return (param1?.createdDateTime || "").localeCompare(
          param2?.createdDateTime || ""
        );
      },
    },
    {
      id: "approvalStatus",
      label: "Approval Status",
      hide: !orgRoom.needApprovalToJoin,
      disableSorting: false,
      filterable: true,
      sortComparator: (param1, param2) => {
        return param1?.approvalStatus > param2?.approvalStatus;
      },
    },
    {
      id: "actions",
      label: "Actions",
      hide: false,
      disableSorting: true,
      filterable: true,
    },
  ];

  const columns = [
    {
      field: "id",
      renderCell: (data) => {
        return data.id;
      },
    },
    {
      field: "member",
      renderCell: (member) => {
        const memberUser = member.user;

        return (
          <ProfilePopover userId={memberUser && memberUser.id}>
            <MemberInfo member={member} />
          </ProfilePopover>
        );
      },
    },
    {
      field: "email",
      renderCell: (data) => {
        const email = data && data.user && data.user.email;
        return (
          <Link
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            color="textPrimary"
            href={`mailto:${email}`}
          >
            {email}
          </Link>
        );
      },
    },
    {
      field: "joinedDateTime",
      renderCell: (data) => {
        const joinedDateTime = new Date(data.createdDateTime);
        return convertUTCTimeToZonedTime(
          joinedDateTime,
          DEFAULT_TIME_ZONE,
          "MMM d, yyyy h:mmaaa zzz"
        );
      },
    },
    {
      field: "approvalStatus",
      renderCell: (data) => {
        return <OrgRoomMemberStatus data={data} roomId={roomId} />;
      },
    },
    {
      field: "actions",
      renderCell: (data) => {
        return (
          <Button
            startIcon={<DeleteOutlinedIcon />}
            variant="text"
            color="default"
            onClick={() =>
              handleRemoveUserFromRoom(data.userAccountId, data.roomId)
            }
          >
            Remove
          </Button>
        );
      },
    },
  ];

  const records = roomMembersKeys.map(
    (userAccountId) => roomMembers[userAccountId]
  );

  return (
    <Box>
      {isLoadingRoomMembers ? (
        <Spinner />
      ) : isErrorRoomMembers ? (
        <Alert severity="error">
          {errorRoomMembers &&
            errorRoomMembers.data &&
            errorRoomMembers.data.msg}
        </Alert>
      ) : (
        <PTable
          records={records}
          headerCells={headerCells}
          columns={columns}
          noRowsText="There are no members in your room at this time."
          isLoading={isFetchingRoomMembers || isLoadingRoomMembers}
        />
      )}
    </Box>
  );
};

export default OrgRoomMembersTable;
