import { Redirect, Switch, useLocation } from "react-router";

const CustomSwitch = (props) => {
  const { children } = props;
  let location = useLocation();

  return (
    <Switch location={location}>
      {children}
      <Redirect to={{ pathname: "/404", from: location }} />
    </Switch>
  );
};

export default CustomSwitch;
