import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { validateJudge, acceptJudgeTerms } from "../../redux/actions/judge";
// Material UI
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// Components
import Button from "../../shared/Button";
import Alert from "../../shared/Alert";
import AuthenticateJudge from "../../shared/AuthenticateJudge";
import ChallengeThemeProvider from "../../shared/ChallengeThemeProvider";
// assets
import time from "../../assets/images/time.svg";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Helmet } from "react-helmet";

const Index = ({
  validateJudge,
  acceptJudgeTerms,
  judgeDetails,
  submissions,
  judgingCriteria,
  challengeDetails,
  loading,
}) => {
  const { slug, token } = useParams();
  const history = useHistory();

  const [totalPossibleScore, setTotalPossibleScore] = useState(0);
  useEffect(() => {
    let maxPossibleScore = 0;
    for (let i = 0; i < judgingCriteria.length; i++) {
      maxPossibleScore += judgingCriteria[i].maxScore;
    }
    setTotalPossibleScore(maxPossibleScore);
  }, [judgingCriteria]);

  // Handle Submission of Form
  const handleJudgeStart = async (submissionIndex = 0) => {
    if (
      judgeDetails.inviteAccepted ||
      window.confirm(
        "You accept that you are going to review the submissions fairly and will provide feedback when appropriate."
      )
    ) {
      // By clicking on "accept", the reviewer has accepted the terms and condition
      // send a request to the back end to save this information
      if (judgeDetails && !judgeDetails.inviteAccepted) {
        await acceptJudgeTerms(judgeDetails.id, judgeDetails.challengeRoundId);
      }
      if (
        submissions &&
        submissions.length > 0 &&
        submissionIndex >= 0 &&
        submissionIndex < submissions.length
      ) {
        const currentSubmissionId = submissions[submissionIndex || 0].id;
        history.push(
          `/programs/${slug}/reviewer/${token}/${currentSubmissionId}`
        );
      }
    }
  };

  useEffect(() => {
    async function validateJudgeByToken() {
      await validateJudge(slug, token);
    }

    if (slug && token) {
      validateJudgeByToken();
      // If judge was already validated and accepted terms, they should directly be redirected to the list of submissions
      // page for judging the program submissions
    }
  }, [slug, token]);

  const currentRound =
    judgeDetails &&
    challengeDetails &&
    challengeDetails.round &&
    challengeDetails.round.length > 0 &&
    challengeDetails.round.find(
      (elem) => elem.id === judgeDetails.challengeRoundId
    );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {challengeDetails &&
            `${challengeDetails.title} Review | ${judgeDetails.firstName} ${judgeDetails.lastName}`}
        </title>
      </Helmet>
      <ChallengeThemeProvider challenge={challengeDetails}>
        <AuthenticateJudge />
        <Box py={[2, 4, 6]} height={"100%"}>
          <Container maxWidth="lg">
            <Box display={{ xs: "block", sm: "none" }}>
              <Alert
                color="red"
                title="Viewing submissions"
                text="Reviewing submissions is best completed on laptop, tablet or desktop devices."
              />
            </Box>
            <Typography color="textSecondary" variant="subtitle1">
              REVIEWER
            </Typography>
            {judgeDetails && (
              <Typography variant="h5" component="div">
                <Box fontWeight={600}>
                  Welcome, {judgeDetails.firstName} {judgeDetails.lastName}
                </Box>
              </Typography>
            )}
            {challengeDetails && (
              <Box lineHeight={1}>
                <Typography color="textSecondary" variant="h6">
                  <Link
                    component={RouterLink}
                    to={`/programs/${slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {challengeDetails.title}
                  </Link>{" "}
                  {currentRound && <span>- {currentRound.name}</span>}
                </Typography>
                <Typography color="textSecondary" variant="body1">
                  {challengeDetails.tagLine}
                </Typography>
              </Box>
            )}
            {judgeDetails && judgeDetails.isActive ? (
              <>
                <Box display="flex" alignItems="center" py={[1, 2]}>
                  {judgeDetails && (
                    <Button
                      size="medium"
                      color="primary"
                      onClick={() => handleJudgeStart(0)}
                    >
                      {judgeDetails && judgeDetails.inviteAccepted
                        ? "Continue Reviewing"
                        : "Get Started"}
                    </Button>
                  )}
                </Box>
                <Box my={[2, 4]}>
                  {loading ? (
                    <CircularProgress />
                  ) : submissions && submissions.length ? (
                    <>
                      <PerfectScrollbar>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {!challengeDetails.maxPeopleTeam
                                    ? "Team"
                                    : ""}{" "}
                                  Name
                                </TableCell>
                                {judgingCriteria &&
                                  judgingCriteria.map((criteria, index) => {
                                    return (
                                      <TableCell key={index}>
                                        {criteria.name}
                                      </TableCell>
                                    );
                                  })}
                                <TableCell>Total Score</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {submissions &&
                                submissions.map((sub, index) => {
                                  const team = sub.team;
                                  const submissionScore = sub.submissionScore;
                                  let totalScore = 0;

                                  if (
                                    submissionScore &&
                                    submissionScore.length > 0
                                  ) {
                                    for (let score of submissionScore) {
                                      totalScore += score.score;
                                    }
                                  }

                                  let scores = [];
                                  if (
                                    submissionScore &&
                                    submissionScore.length > 0
                                  ) {
                                    judgingCriteria &&
                                      judgingCriteria.map((criteria) => {
                                        const foundScore = submissionScore.find(
                                          (elem) =>
                                            elem.judgingCriteriaId ===
                                            criteria.id
                                        );
                                        scores.push(
                                          foundScore && foundScore.score
                                        );
                                      });
                                  }

                                  return (
                                    <TableRow key={index}>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          component="div"
                                        >
                                          <Link
                                            component="button"
                                            onClick={() =>
                                              handleJudgeStart(index)
                                            }
                                          >
                                            {team && team.name}
                                          </Link>
                                        </Typography>
                                      </TableCell>
                                      {judgingCriteria &&
                                        judgingCriteria.map(
                                          (criteria, criteriaIndex) => {
                                            const currentScore =
                                              scores[criteriaIndex];
                                            return (
                                              <TableCell
                                                key={`${criteria.id} ${sub.id}`}
                                              >
                                                {isNaN(currentScore)
                                                  ? "---"
                                                  : currentScore}
                                              </TableCell>
                                            );
                                          }
                                        )}

                                      <TableCell>
                                        {totalScore
                                          ? `${totalScore} / ${totalPossibleScore}`
                                          : "---"}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </PerfectScrollbar>
                    </>
                  ) : (
                    <>
                      <Box textAlign="center" mx="auto" width={[1, 1 / 2]}>
                        <img src={time} alt="time-image" />
                        <Typography variant="h6">
                          There are no submissions assigned to you at this time.
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <Box my={[2, 4]}>
                <Typography variant="body1">
                  You are not activated by the organization, please contact the
                  organizers or{" "}
                  <Link href="mailto:support@pralent.com">
                    support@pralent.com
                  </Link>{" "}
                  if this is an issue.
                </Typography>
              </Box>
            )}
          </Container>
        </Box>
      </ChallengeThemeProvider>
    </>
  );
};

const mapStateToProps = (state) => ({
  judgeDetails: state.judge.judgeDetails,
  submissions: state.judge.submissions,
  judgingCriteria: state.judge.judgingCriteria,
  challengeDetails: state.judge.challengeDetails,
  loading: state.judge.loading,
});

const mapDispatchToProps = {
  validateJudge,
  acceptJudgeTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
