import React from "react";
// Icons
import { IoLogoTwitter } from "react-icons/io5";

const Socials = () => {
  return (
    <div className="px-4 py-12">
      <div className="mx-auto max-w-2xl">
        <a
          className="cursor-pointer"
          href="https://twitter.com/pralenthq"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="rounded-xl bg-gradient-to-r from-sky-500 via-blue-500 to-blue-900 text-left">
            <div className="rounded-xl bg-gray-900 bg-opacity-70 p-4 md:p-8 lg:p-16">
              <IoLogoTwitter className="mb-4 text-2xl text-blue-500 md:text-3xl lg:text-4xl" />
              <h2 className="text-xl font-bold text-white sm:text-2xl">
                <span className="block">Stay up to date</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-400">
                We would love to connect with you on Twitter. Follow{" "}
                <span className="font-semibold text-white">@PralentHQ</span> to
                receive the latest updates.
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Socials;
