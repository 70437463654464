import React, { useState } from "react";
// react-router-dom
import { useParams } from "react-router-dom";
// react-query
import { useGetEventBySlugForOrganization } from "../../hooks/event";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// Components
import Spinner from "../../shared/Spinner";
import OrgEventAppBar from "../../shared/OrgEventAppBar";
import EventInsightsDemographics from "./components/EventInsightsDemographics";
import EventInsightsRegistrations from "./components/EventInsightsRegistrations";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

export const OrgEventInsights = () => {
  const { slug } = useParams();
  const {
    isLoading,
    isError,
    data: { data: orgEvent } = { data: null },
    error,
  } = useGetEventBySlugForOrganization(slug);

  // State for tabs
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Box>
          <OrgEventAppBar event={orgEvent} />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h5" component="h2">
                    <Box fontWeight={600}>Insights</Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Tabs value={value} onChange={handleChangeTab}>
              <Tab label="Registrations" />
              <Tab label="Demographics" />
            </Tabs>

            {/* Registration */}
            <TabPanel value={value} index={0}>
              <EventInsightsRegistrations eventId={orgEvent.id} />
            </TabPanel>
            {/* Demographics */}
            <TabPanel value={value} index={1}>
              <EventInsightsDemographics eventId={orgEvent.id} />
            </TabPanel>
          </Container>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgEventInsights);
