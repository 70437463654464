import axios from "axios";
import store from "../redux/store/store";
import { logout } from "../redux/actions/auth";
import Cookies from "js-cookie";
/* const { GoogleAuth } = require("google-auth-library");
const googleAuth = new GoogleAuth();
let googleClient; */

const baseURL = process.env.REACT_APP_SERVICE_URL;

// Create an Axios Client with defaults
const client = axios.create({
  baseURL: baseURL,
});

const request = function (options) {
  // Get the accessToken from the cookie
  const accessToken = Cookies.get("token");

  // checking if user exists and accessToken is expired
  const state = store.getState();
  const isAuthenticated = state.auth.isAuthenticated;
  const user = state.auth.user;
  const token = state.auth.token;
  const currentCompanyId = (state.company && state.company.orgId) || null;
  if (!accessToken && (isAuthenticated || user || token)) {
    store.dispatch(logout());
  }

  const { method, url, data, config } = options;
  const onSuccess = (response) => {
    // log each request response
    //console.log(response);
    return response;
  };
  const onError = function (error) {
    // some error happened with the server side
    if (error && error.response) {
      return Promise.reject(error.response || error.message);
    } else {
      //console.error("Error Message:", error.message);
    }
    //console.log(error.response);
    // some error happened while processing the request
    //else console.error("Error Message:", error.message);
  };

  let headers = {};

  if (config && config.headers) {
    headers = {
      ...headers,
      ...config.headers,
    };
  }

  // accessToken is from Cookie and will add Authorization only if cookie exists
  if (accessToken) {
    headers["x-auth-token"] = accessToken;
  }
  if (currentCompanyId) {
    headers["x-cur-company"] = currentCompanyId;
  }

  /* try {
    // Create a Google Auth client with the Renderer service url as the target audience.
    console.log("Before getIdTokenClient:", baseURL);
    if (!googleClient)
      googleClient = await googleAuth.getIdTokenClient(baseURL);
    console.log("googleClient:", googleClient);
    // Fetch the client request headers and add them to the service request headers.
    // The client request headers include an ID token that authenticates the request.
    const clientHeaders = await googleClient.getRequestHeaders();
    console.log("clientHeaders:", clientHeaders["Authorization"]);
    headers["Authorization"] = clientHeaders["Authorization"];
  } catch (err) {
    console.log(err.message);
    throw Error("could not create an identity token: ", err);
  } */

  //console.log("headers:", headers);
  return client({
    method: method,
    baseURL: baseURL,
    url: url,
    data: data,
    headers: headers,
  })
    .then(onSuccess)
    .catch(onError);
};

export default request;
