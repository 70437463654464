import React from "react";
// react-router-dom
import { useParams } from "react-router-dom";
// hooks
import { useGetEventBySlugForOrganization } from "../../hooks/event";
// Redux
import { connect } from "react-redux";
// Material UI
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Alert from "@material-ui/lab/Alert";
// Components
import Spinner from "../../shared/Spinner";
import OrgEventAppBar from "../../shared/OrgEventAppBar";
import EmptyState from "../../shared/EmptyState";

export const OrgEventEmails = () => {
  const { slug } = useParams();
  const {
    isLoading,
    isError,
    data: { data: orgEvent } = { data: null },
    error,
  } = useGetEventBySlugForOrganization(slug);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Box>
          <OrgEventAppBar event={orgEvent} />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  <Box fontWeight={600}>
                    📧 Emails <Chip label="Coming Soon" />
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <EmptyState
                  title="No emails yet"
                  subtitle="Keep an eye out for advanced email capabilities which include email scheduling, email customization, email reminders, and more!"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgEventEmails);
