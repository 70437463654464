import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../../shared/Spinner";
import { getProject, clearProject } from "../../../redux/actions/project";
import {
  getSubmissionAndProjectById,
  clearSelectedSubmission,
  getCustomSubmissionFormById,
} from "../../../redux/actions/challenge";
import ComplexProjectCard from "../../../shared/ComplexProjectCard";
import ChallengeCustomSubmissionView from "../../../shared/ChallengeCustomSubmissionView";
import SubmissionTeam from "../../../shared/SubmissionTeam";
import ProjectFiles from "../../../shared/ProjectFiles";
import ProjectLinks from "../../../shared/ProjectLinks";
// Material UI
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
// Utils
import {
  CUSTOM_FORM_TYPE_ID,
  SUBMISSION_TYPE_ID,
} from "../../../utils/globalValues";

// This needs to be modified in the future to display custom question detail/submissions
// or project details depending on what was used by the organization for submitting to the
// competition
export const ChallengeViewSubmissionDetailsDialog = ({
  open,
  setOpen,
  submissionId,
  selectedSubmission,
  getSubmissionAndProjectById,
  getCustomSubmissionFormById,
  myCustomFormSubmission,
  getProject,
  clearProject,
  clearSelectedSubmission,
  project,
  challenge,
  loading,
}) => {
  useEffect(() => {
    if (submissionId && open) {
      getSubmissionAndProjectById(submissionId);
    }
    return () => {
      clearProject();
      clearSelectedSubmission();
    };
  }, [open]);

  useEffect(() => {
    if (selectedSubmission && selectedSubmission.submission) {
      if (
        selectedSubmission.submission.submissionTypeId ===
        SUBMISSION_TYPE_ID.PROJECT
      ) {
        if (selectedSubmission.submission.projectDetailId) {
          getProject(selectedSubmission.submission.projectDetailId);
        }
      }
      if (
        selectedSubmission.submission.submissionTypeId ===
        SUBMISSION_TYPE_ID.CUSTOM
      ) {
        //console.log("SUBMISSION_TYPE_ID.CUSTOM");
        getCustomSubmissionFormById(
          selectedSubmission.submission.challengeId,
          CUSTOM_FORM_TYPE_ID.SUBMISSION_FORM,
          selectedSubmission.submission.id
        );
      }
    }
  }, [selectedSubmission]);

  const handleClose = () => {
    setOpen(false);
    // clean mycustom form details
    clearProject();
    clearSelectedSubmission();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby={`submission-dialog`}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          alignItems="center"
          justify="space-between"
          py={[1, 2]}
          px={[1]}
        >
          {selectedSubmission &&
          selectedSubmission.submission &&
          selectedSubmission.submission.submissionTypeId ===
            SUBMISSION_TYPE_ID.PROJECT ? (
            <>
              <Box>
                {loading ? (
                  <Spinner />
                ) : (
                  selectedSubmission &&
                  selectedSubmission.submission &&
                  project && (
                    <Box>
                      <SubmissionTeam
                        challenge={challenge}
                        submission={selectedSubmission.submission}
                      />
                      <ComplexProjectCard project={project} judging={true} />
                      <ProjectFiles project={project} />
                      <ProjectLinks project={project} />
                    </Box>
                  )
                )}
              </Box>
            </>
          ) : (
            selectedSubmission &&
            selectedSubmission.submission &&
            selectedSubmission.submission.submissionTypeId ===
              SUBMISSION_TYPE_ID.CUSTOM && (
              <Box>
                <Box my={[1]}>
                  <Chip
                    label={
                      selectedSubmission.submission.submittedStatusTypeName
                    }
                    size="medium"
                    variant="outlined"
                  />
                </Box>
                <SubmissionTeam
                  challenge={challenge}
                  submission={selectedSubmission.submission}
                />

                <ChallengeCustomSubmissionView
                  myCustomFormSubmission={myCustomFormSubmission}
                />
              </Box>
            )
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

ChallengeViewSubmissionDetailsDialog.propTypes = {
  selectedSubmission: PropTypes.object,
  getSubmissionAndProjectById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedSubmission: state.challenge.selectedSubmission,
  project: state.project.project,
  challenge: state.challenge.challenge,
  loading: state.challenge.loading,
  myCustomFormSubmission: state.challenge.myCustomFormSubmission,
});

const mapDispatchToProps = {
  getSubmissionAndProjectById,
  getProject,
  clearProject,
  clearSelectedSubmission,
  getCustomSubmissionFormById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeViewSubmissionDetailsDialog);
