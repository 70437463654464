import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { DEFAULT_TIME_ZONE } from "./globalValues";
import momentTimeZone from "moment-timezone";

export const getActiveTimeZones = () => {
  const filterTimeZones = [
    "UCT",
    "PST8PDT",
    "GB",
    "MST7MDT",
    "EST5EDT",
    "W-SU",
    "CST6CDT",
    "HST",
    "MST",
    "Universal",
    "EET",
    "WET",
    "EST",
    "CET",
    "MET",
    "GMT",
    "Etc",
    "US",
    "Antartica",
    "Antarctica",
    "Arctic",
    "Atlantic",
    "Australia",
    "Brazil",
    "Europe",
    "Indian",
    "Pacific",
    "Canada",
  ];
  const filterTimeZonesRegex = `^${filterTimeZones.join("|^")}`;

  const allowedTimeZones = momentTimeZone.tz.names().filter(
    (timezone) =>
      //timezone.startsWith("A") ||
      !new RegExp(filterTimeZonesRegex).test(timezone)
  );
  //.sort((timezoneA, timezoneB) => timezoneA.localeCompare(timezoneB));
  // .map((timezone) => ({
  //   timezone,
  // }));

  if (allowedTimeZones) {
    return allowedTimeZones;
  } else {
    return momentTimeZone.tz.names();
  }
};

export const convertTimeToUTC = (dateTime, timeZone) => {
  //console.log("convertTimeToUTC, dateTime:", dateTime, timeZone);
  let utcDate = null;
  if (dateTime) {
    const pattern = "MMM d yyyy h:mm ";
    const formattedDateTime = new Date(dateTime);
    utcDate = zonedTimeToUtc(formattedDateTime, timeZone);
    //console.log("convertTimeToUTC, dateTime:", utcDate, timeZone);
  }
  return utcDate;
};

export const convertUTCTimeToZonedTime = (
  dateTime,
  timeZone = DEFAULT_TIME_ZONE,
  pattern = "E, MMM d, yyyy h:mmaaa zzz"
) => {
  let formattedDateTime = null;
  if (timeZone === null || timeZone === "") {
    timeZone = DEFAULT_TIME_ZONE;
  }
  if (dateTime) {
    const zonedDate = utcToZonedTime(dateTime, timeZone);
    formattedDateTime = format(zonedDate, pattern, { timeZone: timeZone });
  }
  return formattedDateTime;
};

// use this to get the duplicate time in the current system time zone - used only for datetime picker
export const formatToTimeZone = (dateTime, timeZone) => {
  let formattedDateTime = null;
  if (dateTime) {
    const zonedDate = utcToZonedTime(dateTime, timeZone);
    //const pattern = "MMM d yyyy h:mm a O";
    //formattedDateTime = format(zonedDate, pattern, { timeZone: timeZone });
    formattedDateTime = format(new Date(zonedDate), "MMM d yyyy h:mm a O");
  }
  return formattedDateTime;
};

export const getFirstAndLastDayOfMonth = (date) => {
  const startDateTime = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDateTime = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return { startDateTime, endDateTime };
};
