import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { login } from "../../redux/actions/auth";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
// icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// Components
import Button from "../Button";
import GoogleLoginButton from "../GoogleLoginButton";
// Material UI
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
// Utils
import { magic } from "../../utils/magic";

const useStyles = makeStyles((theme) => ({
  Divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  LogInFormCaption: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  ShowPassword: {
    fontSize: theme.typography.caption.fontSize,
    cursor: "pointer",
    marginTop: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LogInForm = ({
  login,
  isAuthenticated,
  actionCallback,
  signup = false,
  buttonText = "Continue",
}) => {
  const classes = useStyles();

  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const redirect = query.get("redirect");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const { firstName, lastName, email } = formData;

  const [loadingAuth, setLoadingAuth] = useState(false);

  // Handle Submission of Form
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoadingAuth(true);

      const didToken = await magic.auth.loginWithMagicLink({ email });

      const body = { firstName, lastName, email, signup };

      const userId = await login(didToken, body);

      if (actionCallback) {
        await actionCallback(userId);
      }

      setLoadingAuth(false);
    } catch (error) {
      setLoadingAuth(false);
    }
  };

  // Handle Form Change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  if (isAuthenticated) {
    const locationSignUpOrLogIn =
      location.pathname === "/login" || location.pathname === "/signup";

    return (
      <Redirect
        to={
          redirect
            ? redirect
            : !locationSignUpOrLogIn
            ? location.pathname
            : signup
            ? "/onboarding"
            : "/dashboard"
        }
      />
    );
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loadingAuth}>
        <CircularProgress color="inherit" disableShrink />
      </Backdrop>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container spacing={2}>
          {signup && (
            <>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <TextField
                    id="firstName"
                    name={"firstName"}
                    value={firstName}
                    onChange={(e) => handleChange(e)}
                    required
                    type="text"
                    autoComplete="firstName"
                    size="medium"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <TextField
                    id="lastName"
                    name={"lastName"}
                    value={lastName}
                    onChange={(e) => handleChange(e)}
                    required
                    type="text"
                    autoComplete="lastName"
                    size="medium"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormGroup>
              <TextField
                id="email"
                name={"email"}
                value={email}
                onChange={(e) => handleChange(e)}
                required
                type="email"
                autoComplete="email"
                size="medium"
                placeholder="Email"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <Button size="large" type={"submit"} fullWidth>
                {buttonText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

LogInForm.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LogInForm);
