import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { connect } from "react-redux";
// Material UI
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const ProfileExperienceCard = ({ experience }) => {
  return (
    <Box width="100%" px={2} pb={2}>
      <Typography variant="body1" component="div">
        <Box fontWeight={500}>
          {experience.jobTitle} @ {experience.companyName}
        </Box>
      </Typography>

      <Typography variant="body2" color="textSecondary">
        <Moment format="MM/YYYY">{experience.startDate}</Moment> -{" "}
        {experience.endDate === null ? (
          " Present"
        ) : (
          <Moment format="MM/YYYY">{experience.endDate}</Moment>
        )}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {experience.location}
      </Typography>

      <Typography variant="caption" color="textSecondary" component="div">
        <Box my={1} whiteSpace="pre-line">
          {experience.description}
        </Box>
      </Typography>
    </Box>
  );
};

const ProfileExp = ({ profile: { experience } }) => {
  return (
    <Fragment>
      {experience && experience.length > 0 && (
        <>
          <Box my={{ xs: 2, md: 4 }}>
            <Divider light />
          </Box>
          <Box mb={{ xs: 2 }}>
            <Typography variant="h5" component="div">
              <Box px={2} fontWeight={600}>
                Experiences
              </Box>
            </Typography>
          </Box>

          {experience.map((exp, index) => (
            <div key={index}>
              <ProfileExperienceCard experience={exp} />
              {/* {index < experience.length - 1 && (
                <Box my={2}>
                  <Divider light />
                </Box>
              )} */}
            </div>
          ))}
        </>
      )}
    </Fragment>
  );
};

ProfileExp.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile.profile,
});

export default connect(mapStateToProps)(ProfileExp);
