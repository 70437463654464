import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import {
  getJobById,
  applyToJob,
  removeApplicationToJob,
  getJobPostActivity,
  clearJob,
} from "../../redux/actions/jobs";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Alert from "@material-ui/lab/Alert";
// Icons
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LanguageIcon from "@material-ui/icons/Language";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
// Components
import RichTextEditor from "../../shared/RichTextEditor";
import Button from "../../shared/Button";
import CustomAvatar from "../../shared/CustomAvatar";
import SocialMediaShare from "../../shared/SocialMediaShare";
import Spinner from "../../shared/Spinner";
// Utils
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { format, isAfter, isBefore } from "date-fns";

const useStyles = makeStyles((theme) => ({
  orgLogo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: theme.shape.borderRadius,
    objectFit: "object-cover",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
  },
  TooltipSocialIcons: {
    fontSize: theme.typography.h6.fontSize,
    marginRight: theme.spacing(1.5),
    color: theme.palette.grey[500],
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

export const Job = ({
  auth: { isAuthenticated, user },
  job,
  currentJobPostActivity,
  jobCategoryType,
  getJobById,
  applyToJob,
  removeApplicationToJob,
  getJobPostActivity,
  clearJob,
}) => {
  const classes = useStyles();
  const { jobId } = useParams();

  const { loading = false, data: jobDetails = {}, error = null } = job || {};

  const [openApplicationLinkDialog, setOpenApplicationLinkDialog] =
    useState(false);

  const handleCloseApplicationLink = () => {
    setOpenApplicationLinkDialog(false);
  };

  useEffect(() => {
    getJobById(jobId);
    return () => {
      clearJob();
    };
  }, [jobId]);

  useEffect(() => {
    if (jobDetails && user) {
      getJobPostActivity(jobDetails.id);
    }
  }, [jobDetails]);

  const handleApplyToJob = async () => {
    if (isAuthenticated && user) {
      await applyToJob(jobDetails.id);
      if (jobDetails.applicationLink) {
        setOpenApplicationLinkDialog(true);
      }
    }
  };

  const handleRemoveApplicationToJob = async () => {
    if (isAuthenticated && user) {
      await removeApplicationToJob(jobDetails.id);
    }
  };

  const jobCategory =
    jobDetails && jobCategoryType.find((elem) => elem.id === jobDetails.typeId);
  return (
    <>
      <Dialog
        open={openApplicationLinkDialog}
        onClose={handleCloseApplicationLink}
        aria-labelledby="external-application-link-dialog-title"
        aria-describedby="external-application-link-dialog-description"
      >
        <DialogTitle id="external-application-link-dialog-title">
          Apply Externally
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="external-application-link-dialog-description">
            This application also requires you to visit an external website and
            complete an application. Please click the button below to view the
            external application website.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseApplicationLink} variant="text">
            Close
          </Button>
          <Button
            component={Link}
            href={jobDetails && jobDetails.applicationLink}
            target="_blank"
            rel="noopener noreferrer"
            autoFocus
          >
            Application Link
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        flexGrow={1}
        height="100%"
        maxHeight="100vh"
        overflow="auto"
        p={{ xs: 2, md: 4 }}
      >
        <Container maxWidth="lg">
          <Box p={{ xs: 0, md: 2, lg: 4 }}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {error ? (
                  <Box textAlign="center" fontSize="h6.fontSize">
                    {error && error.data && error.data.msg}
                  </Box>
                ) : (
                  jobDetails && (
                    <Grid container spacing={3}>
                      {!jobDetails.isActive && (
                        <Grid item xs={12}>
                          <Alert severity="warning">
                            This job post is no longer active.
                          </Alert>
                        </Grid>
                      )}
                      {currentJobPostActivity &&
                        currentJobPostActivity.data &&
                        currentJobPostActivity.data.jobPostId ===
                          jobDetails.id && (
                          <Grid item xs={12}>
                            <Alert
                              severity="success"
                              action={
                                <Button
                                  onClick={handleRemoveApplicationToJob}
                                  color="inherit"
                                  variant="text"
                                >
                                  Remove Application
                                </Button>
                              }
                            >
                              Congrats, you have applied to this job!
                            </Alert>
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box display="flex" alignItems="center">
                            {jobDetails && jobDetails.company && (
                              <CustomAvatar
                                src={jobDetails.company.companyLogoPath}
                                fallbackText={jobDetails.company.companyName}
                                alt="organization-logo"
                                className={classes.orgLogo}
                              />
                            )}
                            <Box ml={2}>
                              <Typography variant="h6" component="h1">
                                <Box fontWeight={600}>{jobDetails.title}</Box>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                {jobDetails.company &&
                                  jobDetails.company.companyName}
                              </Typography>
                              <Box
                                display="flex"
                                alignItems="center"
                                flexWrap="wrap"
                                mt={0.5}
                                style={{ gap: "10px" }}
                              >
                                {jobCategory && (
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <Box display="flex" alignItems="center">
                                      <WorkOutlineOutlinedIcon
                                        className={classes.icon}
                                      />{" "}
                                      {jobCategory.jobType}
                                    </Box>
                                  </Typography>
                                )}
                                {jobDetails.location && (
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <Box display="flex" alignItems="center">
                                      <LocationOnOutlinedIcon
                                        className={classes.icon}
                                      />{" "}
                                      {jobDetails.location}
                                    </Box>
                                  </Typography>
                                )}
                                {jobDetails.postedDate && (
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <Box display="flex" alignItems="center">
                                      <ScheduleOutlinedIcon
                                        className={classes.icon}
                                      />{" "}
                                      Posted{" "}
                                      {formatDistanceToNow(
                                        new Date(jobDetails.postedDate)
                                      )}{" "}
                                      ago
                                    </Box>
                                  </Typography>
                                )}
                                <Typography
                                  variant="body2"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box display="flex" alignItems="center">
                                    <AttachMoneyOutlinedIcon
                                      className={classes.icon}
                                    />{" "}
                                    {jobDetails.paid ? "Paid" : "Unpaid"}
                                  </Box>
                                </Typography>
                                {jobDetails.closeDateTime && (
                                  <Typography
                                    variant="body2"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <Box display="flex" alignItems="center">
                                      <HourglassEmptyOutlinedIcon
                                        className={classes.icon}
                                      />{" "}
                                      {isBefore(
                                        new Date(),
                                        new Date(jobDetails.closeDateTime)
                                      )
                                        ? `Closes in ${formatDistanceToNow(
                                            new Date(jobDetails.closeDateTime)
                                          )}`
                                        : "Closed"}{" "}
                                      on{" "}
                                      {format(
                                        new Date(jobDetails.closeDateTime),
                                        "MMM d, yyyy"
                                      )}
                                    </Box>
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        {jobDetails.description && (
                          <Box>
                            <Typography
                              variant="body2"
                              component="div"
                              color="textSecondary"
                            >
                              <Box
                                fontWeight={600}
                                style={{ textTransform: "uppercase" }}
                              >
                                Job Description
                              </Box>
                            </Typography>
                            <Box mt={2}>
                              <RichTextEditor
                                value={jobDetails.description}
                                readOnly={true}
                              />
                            </Box>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box>
                          <Box mb={2}>
                            {!jobDetails.isActive ? (
                              <Box />
                            ) : jobDetails.closeDateTime &&
                              isAfter(
                                new Date(),
                                new Date(jobDetails.closeDateTime)
                              ) ? (
                              <Alert severity="info">
                                <Box lineHeight={1.2}>
                                  This job posting is closed and no longer
                                  accepting applications.
                                </Box>
                              </Alert>
                            ) : !isAuthenticated ? (
                              <Button
                                fullWidth
                                size="large"
                                component={RouterLink}
                                to={`/login?redirect=${`/jobs/${jobDetails.id}`}`}
                              >
                                Apply
                              </Button>
                            ) : currentJobPostActivity &&
                              currentJobPostActivity.data &&
                              currentJobPostActivity.data.jobPostId ===
                                jobDetails.id ? (
                              <>
                                {jobDetails.applicationLink && (
                                  <Button
                                    fullWidth
                                    component={Link}
                                    href={jobDetails.applicationLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="outlined"
                                    size="large"
                                  >
                                    External Application Link
                                  </Button>
                                )}
                              </>
                            ) : jobDetails.acceptApplicationsThroughPralent ? (
                              <Button
                                fullWidth
                                onClick={handleApplyToJob}
                                size="large"
                              >
                                Apply
                              </Button>
                            ) : (
                              jobDetails.applicationLink && (
                                <Button
                                  fullWidth
                                  component={Link}
                                  href={jobDetails.applicationLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  size="large"
                                >
                                  Apply
                                </Button>
                              )
                            )}

                            {jobDetails.contactEmail && (
                              <Typography
                                variant="body2"
                                component="div"
                                color="textSecondary"
                              >
                                <Box mt={1}>
                                  Contact:{" "}
                                  <Link
                                    href={`mailto:${jobDetails.contactEmail}`}
                                  >
                                    {jobDetails.contactEmail}
                                  </Link>
                                </Box>
                              </Typography>
                            )}
                          </Box>
                          <Divider light className={classes.divider} />
                          {jobDetails.company && (
                            <>
                              <Typography
                                variant="body2"
                                component="div"
                                color="textSecondary"
                              >
                                <Box
                                  fontWeight={600}
                                  style={{ textTransform: "uppercase" }}
                                >
                                  About {jobDetails.company.companyName}
                                </Box>
                              </Typography>

                              <Box mt={2}>
                                {jobDetails.company.location && (
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <Box my={1} lineHeight={1.2}>
                                      Location: {jobDetails.company.location}
                                    </Box>
                                  </Typography>
                                )}
                                {jobDetails.company.companyStage && (
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="textSecondary"
                                  >
                                    <Box my={1} lineHeight={1.2}>
                                      Type: {jobDetails.company.companyStage}
                                    </Box>
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  component="div"
                                  color="textSecondary"
                                >
                                  <Box whiteSpace="pre-line" lineHeight={1.2}>
                                    {jobDetails.company.companyDescription}
                                  </Box>
                                </Typography>
                                <Divider light className={classes.divider} />
                                <Box display="flex" flexDirection="row" mt={2}>
                                  {jobDetails.company.companyWebsiteUrl && (
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={
                                        jobDetails.company.companyWebsiteUrl
                                      }
                                    >
                                      <LanguageIcon
                                        className={classes.TooltipSocialIcons}
                                      />
                                    </Link>
                                  )}
                                  {jobDetails.company.linkedinUrl && (
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={jobDetails.company.linkedinUrl}
                                    >
                                      <LinkedInIcon
                                        className={classes.TooltipSocialIcons}
                                      />
                                    </Link>
                                  )}
                                  {jobDetails.company.twitterUrl && (
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={jobDetails.company.twitterUrl}
                                    >
                                      <TwitterIcon
                                        className={classes.TooltipSocialIcons}
                                      />
                                    </Link>
                                  )}
                                  {jobDetails.company.facebookUrl && (
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={jobDetails.company.facebookUrl}
                                    >
                                      <FacebookIcon
                                        className={classes.TooltipSocialIcons}
                                      />
                                    </Link>
                                  )}
                                  {jobDetails.company.instagramUrl && (
                                    <Link
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={jobDetails.company.instagramUrl}
                                    >
                                      <InstagramIcon
                                        className={classes.TooltipSocialIcons}
                                      />
                                    </Link>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                          <Divider light className={classes.divider} />
                          <Box mt={2}>
                            {jobDetails.company && (
                              <SocialMediaShare
                                title={`Job Post - ${jobDetails.title} @ ${jobDetails.company.companyName}`}
                                url={`${document.location.origin}/jobs/${jobDetails.id}`}
                                popover={false}
                              />
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                )}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

Job.propTypes = {};

const mapStateToProps = (state) => ({
  auth: state.auth,
  job: state.jobs.job,
  currentJobPostActivity: state.jobs.currentJobPostActivity,
  jobCategoryType: state.typedef.jobCategoryType,
});

const mapDispatchToProps = {
  getJobById,
  applyToJob,
  removeApplicationToJob,
  getJobPostActivity,
  clearJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(Job);
