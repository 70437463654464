import React from "react";
// react-router-dom
import { useParams } from "react-router-dom";
// react-query
import { useGetEventBySlugForOrganization } from "../../hooks/event";
// Material UI
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
// Components
import Spinner from "../../shared/Spinner";
import OrgEventAppBar from "../../shared/OrgEventAppBar";
import OrgEventAttendeeTable from "./components/OrgEventAttendeeTable";

export const OrgEventAttendees = () => {
  const { slug } = useParams();
  const {
    isLoading,
    isError,
    data: { data: orgEvent } = { data: null },
    error,
  } = useGetEventBySlugForOrganization(slug);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <Box>
          <OrgEventAppBar event={orgEvent} />
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" component="h2">
                  <Box fontWeight={600}>Attendees</Box>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box mb={2}>
                    These are the attendees that have registered for your event.{" "}
                    {orgEvent &&
                    orgEvent.requireRegistration &&
                    orgEvent.requireRegistrationApproval
                      ? "This event requires that attendees are approved once they register."
                      : null}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <OrgEventAttendeeTable orgEvent={orgEvent} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default OrgEventAttendees;
