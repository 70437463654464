// React
import React from "react";
// Redux
import { connect } from "react-redux";
// Context
import {
  useRoomContext,
  useRoomMembersContext,
  usePostReplyContext,
} from "./RoomContext";
// Material UI
import { makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// Icons
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import {
  HiHashtag,
  HiOutlineChat,
  HiOutlineViewGrid,
  HiOutlinePencilAlt,
} from "react-icons/hi";
// Components
import RoomPosts from "./RoomPosts";
import PostForm from "./PostForm";
import Post from "./Post";
// Utils
import { ROOM_TYPE } from "../../../utils/globalValues";
import { Emoji } from "emoji-mart";

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    margin: 0,
  },
  drawerClosed: {
    width: 0,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    maxWidth: "100vw",
    margin: 0,
    backgroundColor: theme.palette.background.default,
  },
}));

const ReplyDrawerContent = () => {
  const { room } = useRoomContext();
  const { roomMembers } = useRoomMembersContext();
  const { replyPostInfo, handleDrawerClose } = usePostReplyContext();

  const drawerPostId = replyPostInfo && replyPostInfo.id;

  const postMember = replyPostInfo ? roomMembers[replyPostInfo.postedBy] : null;
  const postUser = postMember && postMember.user;
  const postProfile = postMember && postMember.profile;

  return (
    <Box height="100%" overflow="hidden">
      {/* <Toolbar /> */}

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        pt={6}
      >
        <Box flex="0 1 auto" overflow="auto">
          <Box
            display="flex"
            alignItems="center"
            zIndex="appBar"
            // position="sticky"
            // top={0}
            py={1.25}
            px={2}
            bgcolor="background.paper"
          >
            <Box>
              <Typography variant="h6" component="div">
                <Box fontWeight={600}>Replies</Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
                  {room.type === ROOM_TYPE.CHAT ? (
                    <HiOutlineChat />
                  ) : room.type === ROOM_TYPE.FORUM ? (
                    <HiOutlinePencilAlt />
                  ) : room.type == ROOM_TYPE.BOARD ? (
                    <HiOutlineViewGrid />
                  ) : (
                    <HiHashtag />
                  )}
                  {room.name}{" "}
                  {room.emoji && <Emoji emoji={room.emoji} size={16} />}
                </Box>
              </Typography>
            </Box>
            <Box flexGrow={1} />
            <IconButton onClick={handleDrawerClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <Post
            post={replyPostInfo}
            postUser={postUser}
            postProfile={postProfile}
          />
        </Box>
        <Box flex="1 1 auto" pt={1}>
          <RoomPosts parentPostId={drawerPostId} isReply={true} />
        </Box>
        <Box flex="0 1 40px">
          <PostForm parentPostId={drawerPostId} isReply={true} />
        </Box>
      </Box>
    </Box>
  );
};

const ReplyDrawer = () => {
  const classes = useStyles();

  const { replyPostInfo } = usePostReplyContext();

  const drawerPostId = replyPostInfo && replyPostInfo.id;

  return (
    <>
      <Drawer
        className={drawerPostId ? classes.drawer : classes.drawerClosed}
        variant="persistent"
        anchor="right"
        open={drawerPostId ? true : false}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawerPostId && <ReplyDrawerContent />}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyDrawer);
