export default function GlassFeature({ feature }) {
  return (
    <div key={feature.name} className={`h-full`}>
      <div className={`${feature.bgcolor} rounded-xl h-full`}>
        <div className="bg-black bg-opacity-70 w-full h-full rounded-xl ">
          <div
            className={`bg-gray-700 bg-opacity-40 w-full h-full rounded-xl px-4 md:px-8 lg:px-16 grid grid-cols-1 ${
              feature.fullWidth ? "md:grid-cols-3 md:space-x-8" : ""
            }`}
          >
            <div
              className={`my-auto ${
                feature.fullWidth
                  ? "col-span-1 py-4 md:py-8 lg:py-16"
                  : "pt-4 md:pt-8 lg:pt-16"
              }`}
            >
              <div className="flex items-center justify-between mb-4">
                {feature.icon && (
                  <div className="flex items-center justify-center h-12 w-12 rounded-xl bg-gray-300 bg-opacity-10 text-white">
                    <div className="text-lg mx-auto" aria-hidden="true">
                      {feature.icon}
                    </div>
                  </div>
                )}
                {feature.comingSoon && (
                  <span
                    className={`rounded-full px-3 py-1 ${feature.bgcolor} bg-opacity-50 text-white text-sm`}
                  >
                    COMING SOON
                  </span>
                )}
              </div>
              <h3 className="text-lg font-bold text-gray-100 tracking-normal mb-2 md:text-xl lg:text-2xl">
                {feature.name}{" "}
              </h3>
              {feature.description && (
                <p className="text-base text-gray-400 leading-tight">
                  {feature.description}
                </p>
              )}
            </div>
            {feature.image ? (
              <div
                className={`pt-4 md:pt-8 ${
                  feature.fullWidth ? "col-span-2" : ""
                }`}
              >
                <img
                  src={feature.image}
                  className={`w-full h-80 rounded-t-xl object-cover object-top shadow-lg z-10`}
                />
              </div>
            ) : (
              <div className="h-4 md:h-8 lg:h-16" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
