import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
// Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

// colors
import colorList from "./colorList";
const backgroundColorList = colorList.map((color) => `${fade(color, 0.8)}`);

const BarChart = ({ data }) => {
  // Need to show percentages when user is hovering and get rid of the square color
  // Need to find way to round corners

  const theme = useTheme();
  const graphData = {
    labels: data.map((nameCountObject) => nameCountObject.name),
    datasets: [
      {
        data: data.map((nameCountObject) => nameCountObject.count),
        backgroundColor: backgroundColorList,
        borderColor: "rgba(255, 99, 132, 0.03)", //`${fade(theme.palette.primary.main, 0.0)} 2px solid`,
        borderWidth: 2,
        hoverBackgroundColor: theme.palette.primary.main,
        hoverBorderColor: `${fade(theme.palette.primary.main, 0.05)}`,
      },
    ],
  };

  return (
    <Box>
      <Bar
        data={graphData}
        width={300}
        height={300}
        options={{
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0.025)",
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0.025)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default BarChart;
