import axios from "../../utils/axios";
import { setAlert } from "./alert";
import {
  JUDGE_VALIDATED,
  JUDGE_ACCEPT_TERMS,
  JUDGE_GET_SUBMISSION,
  JUDGE_SAVE_SCORES,
  UPDATE_SUBMISSION_INDEX,
  GET_PREVIOUS_SUBMISSION,
  CLEAR_CURRENT_SUBMISSION,
  SET_JUDGE_LOADING,
  SET_CRITERIA_LOADING,
  CLEAR_ALL_JUDGING,
  UPDATE_DIRTY_SCORE,
  GET_ALL_JUDGES,
  GET_ALL_CRITERIA,
  ADD_JUDGE,
  EDIT_JUDGE,
  ADD_CRITERIA,
  EDIT_CRITERIA,
  SET_ALL_ACTIVE_STATUS,
  GET_JUDGE_SCORING_PROGRESS,
  AUTHENTICATE_JUDGE,
  GET_JUDGE_SCORES,
  LOADING_JUDGE_SCORES,
  DELETE_JUDGE,
  DELETE_CRITERIA,
  CLEAR_JUDGE_SUBMISSION,
} from "./actionTypes";
import { handleServerError } from "./error";

export const bulkUploadJudges = (
  challengeId,
  challengeRoundId,
  dataToUpload
) => async (dispatch) => {
  try {
    const body = {
      challengeId,
      challengeRoundId,
      dataToUpload,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(`/api/judge/uploadBulk`, body, config);
    await dispatch(setAlert(res.data && res.data.msg, "success"));
    return true;
  } catch (error) {
    await dispatch(setAlert(error.data.msg, "error", 10000));
    return false;
  }
};

export const clearAllJudgeToSubmissionAssignments = (
  challengeRoundId
) => async (dispatch) => {
  try {
    const res = await axios.del(`/api/judge/assign/all/${challengeRoundId}`);
    await dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const assignAllJudgesToAllSubmissions = (challengeRoundId) => async (
  dispatch
) => {
  try {
    const body = {
      challengeRoundId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/judge/assign/all", body, config);
    await dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    dispatch(handleServerError(error));
    throw error;
  }
};

export const assignJudgesToSubmissionsIntellassign = (
  challengeRoundId,
  intellassignSettings
) => async (dispatch) => {
  try {
    const body = {
      challengeRoundId,
      intellassignSettings,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      "/api/judge/assign/intellassign",
      body,
      config
    );
    await dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    dispatch(handleServerError(error));
    throw error;
  }
};

export const assignJudgesToSubmissionsByGroup = (
  challengeRoundId,
  groupSettings
) => async (dispatch) => {
  try {
    const body = {
      challengeRoundId,
      groupSettings,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post("/api/judge/assign/group", body, config);
    await dispatch(setAlert(res.data.msg, "success"));
  } catch (error) {
    dispatch(handleServerError(error));
    throw error;
  }
};

export const deleteCriteria = (judgingCriteriaId) => async (dispatch) => {
  try {
    if (window.confirm(`Are you sure you want to delete this criteria?`)) {
      const res = await axios.del(`/api/judge/criteria/${judgingCriteriaId}`);
      await dispatch({
        type: DELETE_CRITERIA,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const deleteJudge = (challengeJudgeId) => async (dispatch) => {
  try {
    if (window.confirm(`Are you sure you want to delete this reviewer?`)) {
      const res = await axios.del(`/api/judge/${challengeJudgeId}`);
      await dispatch({
        type: DELETE_JUDGE,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const authenticateJudge = (challengeJudgeId, password) => async (
  dispatch
) => {
  try {
    const body = { challengeJudgeId, password };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/judge/authenticateJudge", body, config);
    //console.log(res);
    await dispatch({
      type: AUTHENTICATE_JUDGE,
      payload: res.data,
    });
  } catch (error) {
    //console.log(error);
    dispatch(handleServerError(error));
  }
};

export const setAllActiveStatus = (challengeRoundId, areAllActive) => async (
  dispatch
) => {
  try {
    const body = { challengeRoundId, areAllActive };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(`/api/judge/setAllActiveStatus`, body, config);

    await dispatch({
      type: SET_ALL_ACTIVE_STATUS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getPublicJudgingInfo = (challengeRoundId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/judge/publicJudgingInfo/${challengeRoundId}`
    );
    await dispatch({
      type: GET_ALL_JUDGES,
      payload: res.data.judges,
    });
    await dispatch({
      type: GET_ALL_CRITERIA,
      payload: res.data.criterias,
    });
  } catch (error) {
    await dispatch({
      type: GET_ALL_JUDGES,
      payload: [],
    });
    await dispatch({
      type: GET_ALL_CRITERIA,
      payload: [],
    });
    dispatch(handleServerError(error));
  }
};

export const clearJudges = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ALL_JUDGING,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getJudgesScoringProgress = (challengeRoundId) => async (
  dispatch
) => {
  try {
    const res = await axios.get(
      `/api/judge/scoringProgress/${challengeRoundId}`
    );
    await dispatch({
      type: GET_JUDGE_SCORING_PROGRESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const getJudgeScores = (challengeJudgeId) => async (dispatch) => {
  try {
    await dispatch({
      type: LOADING_JUDGE_SCORES,
    });
    const res = await axios.get(
      `/api/judge/getJudgeScores/${challengeJudgeId}`
    );
    await dispatch({
      type: GET_JUDGE_SCORES,
      payload: res.data,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Get a list of all the judges assigned to this challenge round
export const fetchAllJudges = (roundId) => async (dispatch) => {
  try {
    await dispatch(setJudgeLoading());

    const res = await axios.get(`/api/judge/all/${roundId}`);

    dispatch({
      type: GET_ALL_JUDGES,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: GET_ALL_JUDGES,
      payload: [],
    });
    dispatch(handleServerError(error));
  }
};

// Get a list of all the judging criteria assigned to this challenge round
export const fetchAllJudgingCriterias = (roundId) => async (dispatch) => {
  try {
    //console.log(roundId);
    const res = await axios.get(`/api/judge/criteria/all/${roundId}`);

    //console.log(res.data);
    dispatch({
      type: GET_ALL_CRITERIA,
      payload: res.data,
    });
  } catch (error) {
    await dispatch({
      type: GET_ALL_CRITERIA,
    });
    dispatch(handleServerError(error));
  }
};

// Add new judge
export const addEditJudge = (formData) => async (dispatch) => {
  try {
    let res;
    const judgeConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (formData) {
      res = await axios.post(`/api/judge/add`, formData, judgeConfig);
    }
    if (formData.id) {
      await dispatch({
        type: EDIT_JUDGE,
        payload: formData,
      });
    } else {
      await dispatch({
        type: ADD_JUDGE,
        payload: res.data,
      });
    }

    await dispatch(
      setAlert(
        formData.id
          ? "Successfully edited the reviewer's detail"
          : "Successfully added new reviewer",
        "success"
      )
    );
    return true;
  } catch (error) {
    dispatch(handleServerError(error));
    return false;
  }
};

// Add new judge
export const sendInviteToJudges = (challengeId, challengeRoundId) => async (
  dispatch
) => {
  try {
    if (
      window.confirm(
        "Are you sure you want to send an invite to all the active reviewers that have not received an email yet?"
      )
    ) {
      const judgeConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = { challengeId, challengeRoundId };

      const res = await axios.post(
        `/api/judge/sendInviteToAll`,
        body,
        judgeConfig
      );

      await dispatch({
        type: GET_ALL_JUDGES,
        payload: res.data,
      });

      await dispatch(
        setAlert("Successfully sent email to reviewers.", "success")
      );
      return true;
    }
  } catch (error) {
    dispatch(handleServerError(error));
    return false;
  }
};

// Add new criteria
export const addEditCriteria = (formData) => async (dispatch) => {
  try {
    //console.log(formData);

    let res;
    const criteriaConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (formData) {
      res = await axios.post(
        `/api/judge/criteria/add`,
        formData,
        criteriaConfig
      );
    }
    if (formData.id) {
      await dispatch({
        type: EDIT_CRITERIA,
        payload: formData,
      });
    } else {
      await dispatch({
        type: ADD_CRITERIA,
        payload: res.data,
      });
    }

    await dispatch(
      setAlert(
        formData.id
          ? "Successfully edited the reviewing criteria"
          : "Successfully added new reviewing criteria",
        "success"
      )
    );
    return true;
  } catch (error) {
    dispatch(handleServerError(error));
    // dispatch(handleServerError(error));
    return false;
  }
};

// Validate Judge
export const validateJudge = (slug, token) => async (dispatch) => {
  try {
    await dispatch(setJudgeLoading());

    let res;

    if (slug && token) {
      res = await axios.get(`/api/judge/validate/${slug}/${token}`);

      dispatch({
        type: JUDGE_VALIDATED,
        payload: res.data,
      });
    }
  } catch (error) {
    await dispatch({
      type: CLEAR_ALL_JUDGING,
    });
    dispatch(handleServerError(error));
  }
};

export const setJudgingCompleted = (judgeId, challengeRoundId) => async (
  dispatch
) => {
  try {
    let res = await axios.post(
      `/api/judge/saveCompleted/${judgeId}/${challengeRoundId}`
    );
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const updateCurrentSubmissionIndex = (index) => async (dispatch) => {
  try {
    await dispatch({
      type: UPDATE_SUBMISSION_INDEX,
      payload: index,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const updateDirtyScoreState = (dirtyState) => async (dispatch) => {
  try {
    await dispatch({
      type: UPDATE_DIRTY_SCORE,
      payload: dirtyState,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const clearCurrentSubmission = () => async (dispatch) => {
  try {
    await dispatch({
      type: CLEAR_CURRENT_SUBMISSION,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const setJudgeLoading = () => async (dispatch) => {
  try {
    await dispatch({
      type: SET_JUDGE_LOADING,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const setCriteriaLoading = () => async (dispatch) => {
  try {
    await dispatch({
      type: SET_CRITERIA_LOADING,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
export const getPreviousSubmission = (numberOfSubmissions, index) => async (
  dispatch
) => {
  try {
    await dispatch({
      type: GET_PREVIOUS_SUBMISSION,
      payload: index,
    });
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const saveJudgeScores = (
  roundId,
  submissionId,
  judgeId,
  judgeScores
) => async (dispatch) => {
  try {
    let res;
    const judgeConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(judgeScores);
    if (judgeScores && judgeScores.length > 0) {
      res = await axios.post(
        `/api/judge/savescores/${roundId}/${submissionId}/${judgeId}`,
        judgeScores,
        judgeConfig
      );

      await dispatch({
        type: JUDGE_SAVE_SCORES,
        payload: res.data,
      });
      await dispatch(
        setAlert(
          "Successfully updated score for this submission",
          "success",
          1500
        )
      );
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Validate Judge
export const acceptJudgeTerms = (judgeId, challengeRoundId) => async (
  dispatch
) => {
  try {
    await dispatch(setJudgeLoading());

    let res;

    if (judgeId) {
      res = await axios.get(
        `/api/judge/acceptTerms/${judgeId}/${challengeRoundId}`
      );

      dispatch({
        type: JUDGE_ACCEPT_TERMS,
        payload: true,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

// Get List of all challenge submissions
export const getSubmissionWithScores = (currentSubmissionId, judgeId) => async (
  dispatch
) => {
  try {
    await dispatch({
      type: CLEAR_JUDGE_SUBMISSION,
    });
    await dispatch(setJudgeLoading());

    let res;

    if (currentSubmissionId) {
      res = await axios.get(
        `/api/judge/${judgeId}/submission/${currentSubmissionId}`
      );

      dispatch({
        type: JUDGE_GET_SUBMISSION,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

/// change reducer to update submission id and index
