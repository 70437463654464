import React, { useState, useEffect, Fragment } from "react";
// React router
import { useHistory, useParams } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { addUpdateChallengeRound } from "../../../redux/actions/challenge";
import { setAlert } from "../../../redux/actions/alert";
// Components
import Button from "../../../shared/Button";
// Material UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import isBefore from "date-fns/isBefore";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
// Helper Functions
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { SUBMISSION_TYPE_ID } from "../../../utils/globalValues";
// Date Time control with timezone
import "react-datetime/css/react-datetime.css";
import { formatToTimeZone, convertTimeToUTC } from "../../../utils/utilFunc";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
  },
  inputLabel: {
    display: "flex",
    alignItems: "center",
  },
  appBar: {
    boxShadow: "none",
  },
}));

const AddEditChallengeRound = ({
  challenge,
  company,
  round = null,
  setAlert,
  addUpdateChallengeRound,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { orgId, slug } = useParams();

  const [formData, setFormData] = useState({
    challengeId: challenge.id,
    companyId: company && company.id,
    name: "",
    roundNumber: 1,
    isCurrentRound: true,
    isPublic: true,
    isFinalRound: true,
    startDatetime: null,
    endDatetime: null,
    lateSubmissionAllowed: false,
    lateSubmissionDateTime: null,
    submissionTypeId: SUBMISSION_TYPE_ID.CUSTOM,
  });

  const handleChange = (e) => {
    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.multiple) {
      value = Array.from(e.target.selectedOptions, (item) => item.value);
    } else {
      value = e.target.value;
    }

    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  // TODO
  // CHECK IF LATE SUBMISSION DATE IS AFTER END DATE IF END DATE HAS A VALID DATE
  // VERIFY LATE SUBMISSION DATE HAS A VALUE IF LATE SUBMISSION ALLOWED FLAG IS SET TO TRUE
  const handleRoundDateChange = (name, date) => {
    setFormData({
      ...formData,
      [name]: date,
    });
  };

  const handleRadioChange = (e) => {
    setFormData({
      ...formData,
      submissionTypeId: parseInt(e.target.value),
    });
  };

  const {
    challengeId,
    companyId,
    name,
    roundNumber,
    isCurrentRound,
    isPublic,
    isFinalRound,
    startDatetime,
    endDatetime,
    lateSubmissionAllowed,
    lateSubmissionDateTime,
    submissionTypeId,
  } = formData;

  const clearFormData = () => {
    setFormData({
      id: null,
      challengeId: challenge.id,
      companyId: company && company.id,
      name: "",
      roundNumber: 1,
      isCurrentRound: false,
      isPublic: true,
      isFinalRound: true,
      startDatetime: null,
      endDatetime: null,
      lateSubmissionAllowed: false,
      lateSubmissionDateTime: null,
      submissionTypeId: SUBMISSION_TYPE_ID.CUSTOM,
    });
  };

  const validateChallengeRoundInfo = () => {
    const startdt = formData.startDatetime;
    const enddt = formData.endDatetime;
    const lateSubmission = formData.lateSubmissionAllowed;
    const lateSubmissiondt = formData.lateSubmissionDateTime;

    if (startdt && enddt && isBefore(new Date(enddt), new Date(startdt))) {
      setAlert("Please ensure the start date is before the end date.", "error");
      return false;
    }
    if (lateSubmission && !enddt) {
      setAlert("Please add an end date for the round.", "error");
      return false;
    }
    if (lateSubmission && !lateSubmissiondt) {
      setAlert("Please add a late submission date for the round.", "error");
      return false;
    }
    if (!lateSubmission && lateSubmissiondt) {
      setAlert("Please enable late submission checkbox.", "error");
      return false;
    }
    if (
      lateSubmission &&
      isBefore(new Date(lateSubmissiondt), new Date(enddt))
    ) {
      setAlert(
        "Please ensure the late submission date is after the end date.",
        "error"
      );
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const validated = true;

    if (validated) {
      if (validateChallengeRoundInfo()) {
        // update the round number for this round with the max number + 1
        // Change the dates to UTC date times

        formData.startDatetime = convertTimeToUTC(
          formData.startDatetime,
          challenge.timeZone
        );

        formData.endDatetime = convertTimeToUTC(
          formData.endDatetime,
          challenge.timeZone
        );

        formData.lateSubmissionDateTime = convertTimeToUTC(
          formData.lateSubmissionDateTime,
          challenge.timeZone
        );

        const body = {
          ...formData,
        };

        const res = await addUpdateChallengeRound(body);
        if (res) {
          setAlert(
            `Successfully ${
              round && round.id ? "updated" : "created"
            } this round`,
            "success"
          );
          if (!round || !round.id) {
            history.push(`/org/${orgId}/programs/${slug}/round`);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (round) {
      setFormData({
        ...formData,
        id: round.id,
        companyId: company && company.id,
        challengeId: challenge.id,
        name: round.name,
        roundNumber: round.roundNumber,
        isCurrentRound: round.isCurrentRound,
        isPublic: round.isPublic,
        isFinalRound: round.isFinalRound,
        startDatetime: formatToTimeZone(
          round.startDatetime,
          challenge.timeZone
        ),
        endDatetime: formatToTimeZone(round.endDatetime, challenge.timeZone),
        lateSubmissionAllowed: round.lateSubmissionAllowed,
        lateSubmissionDateTime: formatToTimeZone(
          round.lateSubmissionDateTime,
          challenge.timeZone
        ),
        submissionTypeId: round.submissionTypeId
          ? round.submissionTypeId
          : SUBMISSION_TYPE_ID.CUSTOM,
      });
    } else {
      clearFormData();
    }
  }, [round]);

  return (
    <Container maxWidth="sm">
      <Box py={4}>
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                <Box fontWeight={600}>
                  {round ? "Edit" : "Create new"} round
                </Box>
              </Typography>
              <Typography variant="body2" component="div" color="textSecondary">
                <Box mb={2}>Add a new round to accept submissions</Box>
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel className={classes.inputLabel}>
                  <span className="mr-2">Round Name</span>
                  <Tooltip
                    title={
                      <Fragment>
                        <p className="p-1 text-sm font-normal">
                          For example, Round 1 or Early Decision
                        </p>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputLabel>
                <TextField
                  required
                  className={classes.input}
                  variant="outlined"
                  placeholder="Enter the round name"
                  type="text"
                  onChange={handleChange}
                  name="name"
                  value={name}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel className={classes.inputLabel}>
                  <span className="mr-2">Time Zone</span>
                  <Tooltip
                    title={
                      <Fragment>
                        <p className="p-1 text-sm font-normal">
                          Time Zone for all rounds in this program
                        </p>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputLabel>
                {challenge && challenge.timeZone}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel className={classes.inputLabel}>
                  <span className="mr-2">Start Date & Time</span>
                  <Tooltip
                    title={
                      <Fragment>
                        <p className="p-1 text-sm font-normal">
                          When does this round start? Leave blank if no start
                          date.
                        </p>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputLabel>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    name="startDatetime"
                    inputVariant="outlined"
                    value={startDatetime}
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        ["startDatetime"]: date,
                      });
                    }}
                    onError={console.log}
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              handleRoundDateChange("startDatetime", null);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel className={classes.inputLabel}>
                  <span className="mr-2">End Date & Time</span>
                  <Tooltip
                    title={
                      <Fragment>
                        <p className="p-1 text-sm font-normal">
                          When does this round end? Last date/time for
                          submissions. Leave blank if forever.
                        </p>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputLabel>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    name="endDatetime"
                    inputVariant="outlined"
                    value={endDatetime}
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        ["endDatetime"]: date,
                      });
                    }}
                    onError={console.log}
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleRoundDateChange("endDatetime", null)
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={lateSubmissionAllowed}
                      value={lateSubmissionAllowed}
                      name="lateSubmissionAllowed"
                      onChange={handleChange}
                    />
                  }
                  label="Are you accepting late submissions?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel className={classes.inputLabel}>
                  <span className="mr-2">Late Submission Date & Time</span>
                  <Tooltip
                    title={
                      <Fragment>
                        <p className="p-1 text-sm font-normal">
                          If this round allows late submissions, what date &
                          time is the latest submission allowed?
                        </p>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputLabel>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    name="lateSubmissionDateTime"
                    inputVariant="outlined"
                    value={lateSubmissionDateTime}
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        ["lateSubmissionDateTime"]: date,
                      });
                    }}
                    onError={console.log}
                    className={classes.input}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleRoundDateChange(
                                "lateSubmissionDateTime",
                                null
                              )
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel className={classes.inputLabel}>
                  <span className="mr-2">Select Submission Type</span>
                  <Tooltip
                    title={
                      <Fragment>
                        <p className="p-1 text-sm font-normal">
                          Select what type of submission you would like to use
                          for this round
                        </p>
                      </Fragment>
                    }
                    placement="right"
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </InputLabel>
                <RadioGroup
                  aria-label="submissionTypeId"
                  name="submissionTypeId"
                  value={submissionTypeId}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio onChange={(e) => handleRadioChange(e)} />}
                    label="Custom Submission Form"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio onChange={(e) => handleRadioChange(e)} />}
                    label="Pralent Project"
                  />
                </RadioGroup>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit" color="primary">
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  company: state.company.companyProfile,
});

const mapDispatchToProps = { addUpdateChallengeRound, setAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditChallengeRound);
