import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// Redux
import { connect } from "react-redux";
import {
  getRegistrations,
  clearRegistrations,
  eliminateTeamFromChallenge,
} from "../../../redux/actions/challenge";
// Components
import Spinner from "../../../shared/Spinner";
import Button from "../../../shared/Button";
// Material UI
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Tooltip from "@material-ui/core/Tooltip";
// Icons
import MailIcon from "@material-ui/icons/Mail";
import RestoreIcon from "@material-ui/icons/Restore";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
  Card: {
    width: "100%",
    height: "100%",
    boxShadow: theme.shadows[0],
  },
  CardActionArea: {
    width: "100%",
    height: "100%",
  },
  CardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  Avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  Eliminated: {
    fontSize: theme.typography.caption.fontSize,
    lineHeight: 1,
    color: theme.palette.error.main,
  },
}));

export const ProfileView = ({
  loading,
  challenge,
  registrations,
  getRegistrations,
  clearRegistrations,
  eliminateTeamFromChallenge,
}) => {
  const classes = useStyles();

  const fetchRegistrations = async () => {
    await getRegistrations(challenge.id);
  };

  const eliminateTeam = (team) => {
    // Disqualify the team
    if (team) {
      if (
        window.confirm(
          `Are you sure you want to ${
            team.eliminatedFlag ? "reinstate" : "eliminate"
          } ${team.name}?`
        )
      ) {
        eliminateTeamFromChallenge(challenge.id, team.id, !team.eliminatedFlag);
      }
    }
  };

  useEffect(() => {
    if (challenge) {
      fetchRegistrations();
    }
    return () => clearRegistrations();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Box height="100%" margin="auto">
        <Grid container spacing={2}>
          {registrations && registrations.length > 0 ? (
            registrations.map((reg, index) => {
              const team = reg.team;

              if (!team) return;

              let teamEmails = [];
              team &&
                team.members &&
                team.members.length > 0 &&
                team.members.map((member) => {
                  const user = member.user;
                  if (user) {
                    teamEmails.push(user.email);
                  }
                });

              return (
                <Fragment key={index}>
                  {team &&
                    team.members &&
                    team.members.length > 0 &&
                    team.members.map((member, index) => {
                      const profile = member.profile;
                      const user = member.user;
                      if (!user) return;

                      const education =
                        profile &&
                        profile.education &&
                        profile.education.length > 0 &&
                        profile.education[0];

                      return (
                        <Grid item xs={12} md={6} lg={4} key={index}>
                          <Card className={classes.Card}>
                            <CardContent>
                              <Box className={classes.CardContent}>
                                <Avatar
                                  src={user.avatar}
                                  alt={user.userName}
                                  className={classes.Avatar}
                                />
                                {team.eliminatedFlag && (
                                  <Typography className={classes.Eliminated}>
                                    Eliminated
                                  </Typography>
                                )}
                                <Typography
                                  variant="body2"
                                  component="p"
                                  color="primary"
                                >
                                  {team.name}
                                </Typography>
                                <Typography variant="body1" component="h2">
                                  <Box fontWeight={600}>
                                    {profile ? (
                                      <>
                                        {profile.firstName} {profile.lastName}
                                      </>
                                    ) : (
                                      <>{user && user.userName}</>
                                    )}
                                  </Box>
                                </Typography>
                                {education && (
                                  <>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      <Box lineHeight={1.2}>
                                        {education && education.school},{" "}
                                        {education &&
                                          education.completionDate &&
                                          new Date(
                                            education.completionDate
                                          ).getFullYear()}
                                      </Box>
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      <Box lineHeight={1.2}>
                                        {education && education.degree}{" "}
                                        {education && education.fieldOfStudy}
                                      </Box>
                                    </Typography>
                                  </>
                                )}
                              </Box>
                            </CardContent>
                            <CardActions>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                              >
                                <Box>
                                  <Tooltip
                                    placement="bottom"
                                    title={
                                      team.eliminatedFlag
                                        ? `Reinstate this person back into the program. If you have team submission enabled, the entire team will also be reinstated.`
                                        : "Eliminate this person from the program. If you have team submission enabled, the entire team will be eliminated and no longer be able to submit, but will continue to have access to all their previous materials."
                                    }
                                  >
                                    <Button
                                      onClick={() => eliminateTeam(team)}
                                      variant="text"
                                      color={
                                        team.eliminatedFlag
                                          ? "primary"
                                          : "default"
                                      }
                                      startIcon={
                                        team.eliminatedFlag ? (
                                          <RestoreIcon />
                                        ) : (
                                          <ClearAllIcon />
                                        )
                                      }
                                    >
                                      {team.eliminatedFlag
                                        ? "Reinstate"
                                        : "Eliminate"}
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <Tooltip
                                    placement="bottom"
                                    title="Open up this person's email"
                                  >
                                    <Button
                                      href={`mailto:${user && user.email}`}
                                      startIcon={<MailIcon />}
                                      variant="text"
                                      color="default"
                                    >
                                      Mail
                                    </Button>
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <Tooltip
                                    placement="bottom"
                                    title="View this person's Pralent profile"
                                  >
                                    <Button
                                      component={Link}
                                      to={`/@${user.userName}`}
                                      startIcon={<PersonIcon />}
                                      variant="text"
                                      color="default"
                                    >
                                      Profile
                                    </Button>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                </Fragment>
              );
            })
          ) : (
            <>
              <Box p={[2, 3, 4]}>
                <Typography variant="body2">
                  There are no registered users at this time.
                </Typography>
              </Box>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};
ProfileView.propTypes = {
  //registrations: PropTypes.array,
};

const mapStateToProps = (state) => ({
  loading: state.challenge.loading,
  registrations: state.challenge.registrations,
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {
  getRegistrations,
  clearRegistrations,
  eliminateTeamFromChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
