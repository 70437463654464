import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";
import { getJudgeScores } from "../../../redux/actions/judge";
// Material UI
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// icons
import LaunchIcon from "@material-ui/icons/Launch";
import InfoIcon from "@material-ui/icons/Info";
// Components
import Spinner from "../../../shared/Spinner";

export const JudgeScoresDialog = ({
  challenge,
  challengeJudgeId,
  judgeScores,
  getJudgeScores,
}) => {
  const [open, setOpen] = useState(false);
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open && challengeJudgeId) {
      getJudgeScores(challengeJudgeId);
    }
  }, [challengeJudgeId, open]);

  return (
    <>
      <Tooltip title="View the scores and feedback by this reviewer">
        <IconButton onClick={onOpen}>
          <LaunchIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={onClose}
        aria-labelledby={`reviewer-scores-dialog`}
      >
        <Container maxWidth="lg">
          {judgeScores && judgeScores.loading ? (
            <Spinner />
          ) : (
            <>
              <Box p={[2, 4]}>
                {judgeScores && judgeScores.data && judgeScores.data.judge && (
                  <>
                    <Typography variant="h5">
                      <strong>
                        {judgeScores.data.judge.firstName}{" "}
                        {judgeScores.data.judge.lastName}
                      </strong>
                    </Typography>
                  </>
                )}
                <Typography variant="body1" color="textSecondary">
                  These are the scores and feedback for the submissions that
                  have been assigned to this reviewer.
                </Typography>
                {judgeScores &&
                judgeScores.data &&
                judgeScores.data.judgeScores ? (
                  Object.keys(judgeScores.data.judgeScores).map(
                    (submissionId) => {
                      const {
                        criteriaScores = [],
                        teamName = "",
                      } = judgeScores.data.judgeScores[submissionId];
                      return (
                        <Box my={[2, 4]} key={submissionId}>
                          <Box mb={[1, 2]}>
                            <Typography
                              component="div"
                              variant="caption"
                              color="textSecondary"
                            >
                              <Box style={{ textTransform: "uppercase" }}>
                                {challenge &&
                                challenge.maxPeopleTeam &&
                                challenge.maxPeopleTeam === 1
                                  ? "Applicant"
                                  : "Team"}
                              </Box>
                            </Typography>
                            <Typography variant="h6">
                              <strong>{teamName}</strong>
                            </Typography>
                          </Box>
                          <Box>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Criteria</TableCell>
                                    <TableCell>Feedback</TableCell>
                                    <TableCell>Score</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {criteriaScores.length > 0 ? (
                                    criteriaScores.map((score) => {
                                      return (
                                        <TableRow key={score.id}>
                                          <TableCell>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              alignItems="center"
                                            >
                                              <Tooltip
                                                title={
                                                  score.criteriaDescription
                                                }
                                              >
                                                <InfoIcon
                                                  fontSize="small"
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                />
                                              </Tooltip>
                                              <Box lineHeight="1">
                                                {score.criteriaName}
                                              </Box>
                                            </Box>
                                          </TableCell>
                                          <TableCell>
                                            <Box whiteSpace="pre-line">
                                              {score.feedback}
                                            </Box>
                                          </TableCell>
                                          <TableCell>{score.score}</TableCell>
                                        </TableRow>
                                      );
                                    })
                                  ) : (
                                    <TableRow>
                                      <TableCell>
                                        There are no scores for this submission.
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      );
                    }
                  )
                ) : (
                  <Box>There are no submissions assigned to this reviewer.</Box>
                )}
              </Box>
            </>
          )}
        </Container>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  judgeScores: state.judge.judgeScores,
});

const mapDispatchToProps = { getJudgeScores };

export default connect(mapStateToProps, mapDispatchToProps)(JudgeScoresDialog);
