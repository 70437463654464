import {
  LOADING_GROUP,
  GET_GROUP_BY_ID,
  ERROR_GROUP,
  LOADING_ALL_GROUPS,
  GET_ALL_GROUPS,
  ERROR_ALL_GROUPS,
  DELETE_GROUP_BY_ID,
  ADD_EDIT_GROUPS,
  GROUP_LOADING_SUBMISSIONS,
  GROUP_GET_SUBMISSIONS,
  GROUP_ERROR_SUBMISSIONS,
  GROUP_LOADING_JUDGES,
  GROUP_GET_JUDGES,
  GROUP_ERROR_JUDGES,
} from "../actions/actionTypes";

const initialState = {
  groups: {
    loading: false,
    data: null,
    error: null,
  },
  group: {
    loading: false,
    data: null,
    error: null,
  },
  submissions: {
    loading: false,
    data: null,
    error: null,
  },
  judges: {
    loading: false,
    data: null,
    error: null,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOADING_GROUP: {
      return {
        ...state,
        group: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GET_GROUP_BY_ID: {
      return {
        ...state,
        group: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case ERROR_GROUP: {
      return {
        ...state,
        group: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }
    case LOADING_ALL_GROUPS: {
      return {
        ...state,
        groups: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GET_ALL_GROUPS: {
      return {
        ...state,
        groups: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case ERROR_ALL_GROUPS: {
      return {
        ...state,
        groups: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }
    case DELETE_GROUP_BY_ID: {
      let currentGroups = state.groups.data;
      currentGroups = currentGroups.filter((g) => g.id !== payload);
      return {
        ...state,
        groups: {
          loading: false,
          data: currentGroups,
          error: null,
        },
        group: {
          loading: false,
          data: null,
          error: null,
        },
      };
    }

    case ADD_EDIT_GROUPS: {
      const edit = payload.edit;
      const newGroup = payload.group;
      let newGroups = [...state.groups.data];
      if (edit) {
        const indexToUpdate =
          state.groups &&
          state.groups.data &&
          state.groups.data.findIndex((elem) => elem.id === newGroup.id);
        newGroups[indexToUpdate] = {
          id: newGroup.id,
          name: newGroup.name,
        };
      } else {
        newGroups = [newGroup, ...newGroups];
      }

      return {
        ...state,
        groups: {
          loading: false,
          data: newGroups,
          error: null,
        },
      };
    }

    case GROUP_LOADING_JUDGES: {
      return {
        ...state,
        judges: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GROUP_GET_JUDGES: {
      return {
        ...state,
        judges: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case GROUP_ERROR_JUDGES: {
      return {
        ...state,
        judges: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }

    case GROUP_LOADING_SUBMISSIONS: {
      return {
        ...state,
        submissions: {
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case GROUP_GET_SUBMISSIONS: {
      return {
        ...state,
        submissions: {
          loading: false,
          data: payload,
          error: null,
        },
      };
    }
    case GROUP_ERROR_SUBMISSIONS: {
      return {
        ...state,
        submissions: {
          loading: false,
          data: null,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
}
