import React from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
// hooks
import { useGetAllEventsForOrganization } from "../../hooks/event";
// Redux
import { connect } from "react-redux";
// Material UI
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
// Icons
import { IoTicketOutline } from "react-icons/io5";
// Components
import Spinner from "../../shared/Spinner";
import Button from "../../shared/Button";
import OrgAppBar from "../../shared/OrgAppBar";
import EmptyState from "../../shared/EmptyState";
import OrgEventCard from "./components/OrgEventCard";

export const OrgEvents = ({ companyProfile }) => {
  const location = useLocation();
  const {
    isLoading,
    isError,
    data: { data: events } = { data: null },
    error,
  } = useGetAllEventsForOrganization(companyProfile);

  return (
    <>
      <OrgAppBar
        title={
          <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
            <IoTicketOutline /> Events
          </Box>
        }
        subtitle="View all of the events in your community"
        actions={
          <>
            <Button component={Link} to={`${location.pathname}/new`}>
              Create New Event
            </Button>
          </>
        }
      />
      <Box>
        {isLoading ? (
          <Spinner />
        ) : isError ? (
          <Alert severity="error">
            {error && error.data && error.data.msg}
          </Alert>
        ) : (
          <Box>
            {events && events.length > 0 ? (
              <>
                {events.map((event) => {
                  return <OrgEventCard key={event.id} event={event} />;
                })}
              </>
            ) : (
              <EmptyState
                title="No events created yet"
                subtitle="Create and manage events for your community members to attend. Manage event attendees, links, emails, insights, and more."
                link="https://www.notion.so/Creating-an-event-0ef2450f46c0467c833797bd732a8693"
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

OrgEvents.propTypes = {};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgEvents);
