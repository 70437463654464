import axios from "../utils/axios";
import { useQuery, useMutation } from "react-query";

export const addEditEvent = async ({ companyId, eventDetails }) => {
  const body = {
    companyId,
    eventDetails,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/event`, body, config);
};

export const duplicateEvent = async ({ eventToDuplicateId }) => {
  const body = {
    eventToDuplicateId,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/event/duplicate`, body, config);
};

export const uploadEventHeaderImage = async ({
  companyId,
  headerImageFormData,
}) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return await axios.post(
    `/api/event/upload/headerImage/${companyId}`,
    headerImageFormData,
    config
  );
};

export const registerUserForEvent = async ({ userAccountId, eventId }) => {
  const body = {
    userAccountId,
    eventId,
  };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/event/register`, body, config);
};

export const updateAttendeeRegistrationStatus = async ({
  eventId,
  registrationId,
  registrationStatus,
}) => {
  const body = { eventId, registrationId, registrationStatus };
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.post(`/api/event/register/status`, body, config);
};

export const removeUserFromEvent = async ({ registrationId, eventId }) => {
  return await axios.del(`/api/event/register/${registrationId}/${eventId}`);
};

export const deleteEvent = async ({ eventId }) => {
  return await axios.del(`/api/event/org/id/${eventId}`);
};

export const useGetNumberOfEventRegistrations = (event) => {
  const result = useQuery(
    ["numberOfEventRegistrations", { eventId: event && event.id }],
    getNumberOfEventRegistrations,
    { enabled: !!event, onError: (error) => console.log(error) }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getNumberOfEventRegistrations = async ({ queryKey }) => {
  const [_key, { eventId }] = queryKey;
  return await axios.get(`/api/event/id/${eventId}/numberOfRegistrations`);
};

export const useGetAttendingUsersForEvent = (event) => {
  const result = useQuery(
    ["eventRegistrationsAttending", { eventId: event && event.id }],
    getAttendingUsersForEvent,
    { enabled: !!event, onError: (error) => console.log(error) }
  );
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAttendingUsersForEvent = async ({ queryKey }) => {
  const [_key, { eventId }] = queryKey;
  return await axios.get(`/api/event/id/${eventId}/registrations/attending`);
};

export const useGetEventBySlug = (slug) => {
  const result = useQuery(["event", { slug: slug }], getEventBySlug);
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getEventBySlug = async ({ queryKey }) => {
  const [_key, { slug }] = queryKey;
  return await axios.get(`/api/event/slug/${slug}`);
};

export const useGetUserEventActivity = (event, user) => {
  const result = useQuery(
    [
      "userEventActivity",
      { eventId: event.id, userAccountId: user && user.id },
    ],
    getUserEventActivity,
    {
      enabled: !!user,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getUserEventActivity = async ({ queryKey }) => {
  const [_key, { eventId, userAccountId }] = queryKey;
  return await axios.get(`/api/event/activity/${eventId}/${userAccountId}`);
};

export const useGetAllUserEventActivities = () => {
  const result = useQuery(["userEventActivities"], getAllUserEventActivities);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllUserEventActivities = async ({ queryKey }) => {
  const [_key] = queryKey;
  return await axios.get(`/api/event/user/activity/all/`);
};

export const useGetAllEventsForOrganization = (companyProfile) => {
  const result = useQuery(
    ["orgEvents", { companyId: companyProfile.id }],
    getAllEventsForOrganization
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllEventsForOrganization = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/event/org/${companyId}/all`);
};

export const useGetEventBySlugForOrganization = (slug) => {
  const result = useQuery(
    ["orgEvent", { eventSlug: slug }],
    getEventBySlugForOrganization,
    {
      enabled: !!slug,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getEventBySlugForOrganization = async ({ queryKey }) => {
  const [_key, { eventSlug }] = queryKey;
  return await axios.get(`/api/event/org/slug/${eventSlug}`);
};

export const useGetAllEventRegistrationsForOrganization = (eventId) => {
  const result = useQuery(
    ["orgEventAttendees", { eventId: eventId }],
    getAllEventRegistrationsForOrganization,
    {
      enabled: !!eventId,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllEventRegistrationsForOrganization = async ({ queryKey }) => {
  const [_key, { eventId }] = queryKey;
  return await axios.get(`/api/event/org/id/${eventId}/registrations`);
};

export const useGetAllEventsForCommunity = (companyDetails) => {
  const result = useQuery(
    ["communityEvents", { companyId: companyDetails.id }],
    getAllEventsForCommunity,
    {
      enabled: !!companyDetails,
    }
  );
  //console.log("userResult:", userResult);
  if (result.isError) {
    // console.log("Error");
  }
  return result;
};

export const getAllEventsForCommunity = async ({ queryKey }) => {
  const [_key, { companyId }] = queryKey;
  return await axios.get(`/api/event/community/all/id/${companyId}`);
};
