import React from "react";
import { useState } from "react";
// React Query
import { useGetAllUserEventActivities } from "../../../hooks/event";
// Material UI
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LockIcon from "@material-ui/icons/Lock";
import Divider from "@material-ui/core/Divider";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import Spinner from "../../../shared/Spinner";
import EmptyState from "../../../shared/EmptyState";
import EventCard from "./EventCard";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      py={2}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const DashboardEvents = () => {
  const classes = useStyles();
  // State for tabs
  const [value, setValue] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const {
    isLoading,
    data: { data: data } = { data: null },
    isError,
    error,
    isFetching,
  } = useGetAllUserEventActivities();
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {/* Displaying events information */}
          <Box>
            <Typography variant="h5" component="h2">
              <Box fontWeight={700} pb={2}>
                🎟️ RSVP'd Events
              </Box>
            </Typography>
            <Tabs value={value} onChange={handleChangeTab}>
              <Tab label="Upcoming" />
              <Tab label="Past" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container spacing={2}>
                {data.upcomingEvents && data.upcomingEvents.length > 0 ? (
                  data.upcomingEvents.map((upcomingEvent) => {
                    if (upcomingEvent.event) {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          width="100%"
                          key={upcomingEvent.event.id}
                        >
                          <EventCard event={upcomingEvent.event} />
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <Grid item xs={12}>
                    <EmptyState
                      title="No upcoming events"
                      subtitle="There are no upcoming events at this time."
                      minHeight={300}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Grid container spacing={2}>
                {data.pastEvents && data.pastEvents.length > 0 ? (
                  data.pastEvents.map((pastEvent) => {
                    if (pastEvent.event) {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          lg={4}
                          width="100%"
                          key={pastEvent.event.id}
                        >
                          <EventCard event={pastEvent.event} />
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <Grid item xs={12}>
                    <EmptyState
                      title="No past events"
                      subtitle="There are no past events at this time."
                      minHeight={300}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
          </Box>
        </>
      )}
    </>
  );
};

export default DashboardEvents;
