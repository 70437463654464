import React from "react";
// Redux
import { setAlert } from "../../../redux/actions/alert";
// React Router
import { Link, useLocation } from "react-router-dom";
// React Query
import { useMutation, useQueryClient } from "react-query";
import { deleteMemberMe } from "../../../hooks/community";
// Redux
import { connect } from "react-redux";
// Material UI
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiLink from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// Icons
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
// Components
import CustomAvatar from "../../CustomAvatar";
// utils
import useClipboard from "react-use-clipboard";
import { Emoji } from "emoji-mart";

const useStyles = makeStyles((theme) => ({
  companyLogo: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.shape.borderRadius,
    objectFit: "object-cover",
    marginRight: theme.spacing(1.5),
  },
  menuItem: {
    whiteSpace: "normal",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color:
      theme.palette.type === "dark"
        ? theme.palette.text.secondary
        : theme.palette.text.primary,
  },
}));

const CommunityMenu = ({
  companyDetails,
  user,
  myOrganizations,
  setAlert,
  sidebarMenuItems,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  // Get the community member information
  const queryClient = useQueryClient();
  const communityMemberQuery = queryClient.getQueryData([
    "communityMember",
    { companyId: companyDetails.id },
  ]);
  // Leave community mutation
  const deleteMemberMeMutation = useMutation(deleteMemberMe, {
    onSuccess: async (data) => {
      if (data && data.data) {
        await queryClient.invalidateQueries([
          "communityMember",
          { companyId: companyDetails.id },
        ]);
        await queryClient.invalidateQueries(["myCommunities", user.id]);
        await queryClient.invalidateQueries([
          "myCommunityRooms",
          {
            communityId: companyDetails.id,
            userAccountId: user.id,
          },
        ]);
        await handleClose();
      }
    },
  });

  let foundManageCommunity =
    myOrganizations &&
    (myOrganizations || []).findIndex(
      (elem) => elem.companyId === companyDetails.id
    );

  const manageCommunity =
    Number.isInteger(foundManageCommunity) && foundManageCommunity >= 0;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isCopiedInviteLink, setCopiedInviteLink] = useClipboard(
    `${document.location.origin}/c/${companyDetails.communitySlug}/invite`
  );

  const handleInvite = async () => {
    await setCopiedInviteLink();
    await setAlert("Copied community page link", "success");
  };

  const handleLeaveCommunity = () => {
    if (
      !manageCommunity &&
      window.confirm("Are you sure you would like to leave this community?")
    ) {
      deleteMemberMeMutation.mutate({
        companyId: companyDetails.id,
      });
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setAnchorEl(open ? event.currentTarget : null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "community-menu-popover" : undefined;

  const OpenComponent = ({ children }) => {
    if (!mobileScreen) {
      return (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          elevation={6}
        >
          {children}
        </Popover>
      );
    } else {
      return (
        <SwipeableDrawer
          anchor={"left"}
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          variant="temporary"
        >
          {children}
        </SwipeableDrawer>
      );
    }
  };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        {mobileScreen && <MenuIcon />}
        <Box
          lineHeight={1.2}
          fontWeight={600}
          fontSize="h6.fontSize"
          pr={{ xs: 0, sm: 1 }}
          pl={{ xs: 1, sm: 0 }}
          flexGrow={1}
        >
          {companyDetails.companyName}
        </Box>
        {!mobileScreen && manageCommunity ? (
          <SettingsIcon />
        ) : (
          <KeyboardArrowDownOutlinedIcon />
        )}
      </Box>
      <OpenComponent>
        <Box maxWidth={"100vw"} maxHeight="100vh" overflow="auto">
          <Box p={1.5} display="flex" alignItems="center">
            <CustomAvatar
              src={companyDetails.companyLogoPath}
              fallbackText={companyDetails.companyName}
              alt="companyDetails-logo"
              className={classes.companyLogo}
            />
            <Box>
              <Typography gutterBottom variant="body2" component="div">
                <Box lineHeight={1.2} fontWeight={600}>
                  {companyDetails.companyName}
                </Box>
              </Typography>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >
                <Box flexWrap="wrap" lineHeight={1.2}>
                  {document.location.origin}/c/
                  {companyDetails.communitySlug}
                </Box>
              </Typography>
            </Box>
          </Box>
          {mobileScreen &&
            (sidebarMenuItems || []).map((item, index) => {
              if (!item) {
                return null;
              }
              const linkProps = item.href
                ? { component: MuiLink, href: item.href }
                : { component: Link, to: item.to };
              return (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  {...linkProps}
                >
                  <Box display="flex" alignItems="center">
                    <Box fontSize="caption.fontSize">{item.leftIcon}</Box>
                    <Box px={1.5}>{item.name}</Box>{" "}
                    {item.roomEmoji && (
                      <Emoji emoji={item.roomEmoji} size={16} />
                    )}
                  </Box>
                </MenuItem>
              );
            })}
          {manageCommunity && (
            <>
              <Divider />
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={`/org/${companyDetails.orgId}/home`}
              >
                Manage
              </MenuItem>
            </>
          )}
          {communityMemberQuery?.data && (
            <>
              <Divider />
              <MenuItem className={classes.menuItem} onClick={handleInvite}>
                Invite people to {companyDetails.companyName}
              </MenuItem>
              {!manageCommunity && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={handleLeaveCommunity}
                >
                  Leave Community
                </MenuItem>
              )}
            </>
          )}
        </Box>
      </OpenComponent>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  myOrganizations: state.profile.myOrganizations,
});

const mapDispatchToProps = { setAlert };

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CommunityMenu)
);
