import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// Material UI
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
// Components
import FormBuilder from "./FormBuilder";
// Utils
import { SUBMISSION_TYPE_ID } from "../../../utils/globalValues";

const ChallengeFormBuilder = ({ company, challenge, currentRoundIndex }) => {
  const [currentRound, setCurrentRound] = useState(null);
  useEffect(() => {
    if (challenge && challenge.round && challenge.round.length > 0) {
      setCurrentRound(challenge.round[currentRoundIndex]);
    }
  }, [challenge, currentRoundIndex]);

  return (
    <Fragment>
      <Box>
        {currentRound &&
        currentRound.submissionTypeId === SUBMISSION_TYPE_ID.CUSTOM ? (
          <FormBuilder challenge={challenge} />
        ) : (
          <Fragment>
            <Box textAlign="left">
              <p>
                The round does not have custom forms enabled. Please select
                custom forms in the round settings in the{" "}
                <Link
                  to={`/org/${company.orgId}/programs/${
                    challenge && challenge.slug
                  }`}
                  component={RouterLink}
                >
                  Home page
                </Link>{" "}
                of your program. If you have already launched your program and
                have submissions for this round, please do not change the
                submission type.
              </p>
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
  company: state.company.companyProfile,
  currentRoundIndex: state.challenge.currentRoundIndex,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeFormBuilder);
