import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { deleteComment } from "../../../redux/actions/project";

// MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  block: {
    display: "block",
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
}));

const CommentItem = ({
  projectId,
  comment: { id, text, userAccount, commentDate },
  auth,
  deleteComment,
}) => {
  const classes = useStyles();
  const today = moment();
  const diff = today.diff(moment(commentDate), "days");
  const date =
    diff === 0
      ? "Today"
      : diff === 1
      ? "Yesterday"
      : diff < 30
      ? `${diff} days ago`
      : diff < 365
      ? `${today.diff(moment(commentDate), "months")} months ago`
      : `${today.diff(moment(commentDate), "years")} months ago`;

  return (
    <Fragment>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt={userAccount && userAccount.userName.charAt(0).toUpperCase()}
            src={userAccount && userAccount.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="body1" component="span">
              <Box fontWeight={600} lineHeight={1}>
                {userAccount && userAccount.userName}
              </Box>
            </Typography>
          }
          secondary={
            <Fragment>
              <Typography
                component="span"
                variant="caption"
                className={classes.block}
                color="textPrimary"
              >
                {date}
              </Typography>
              <Typography
                component="span"
                variant="body1"
                className={classes.block}
                color="textPrimary"
              >
                {text}
              </Typography>
            </Fragment>
          }
        />
        <ListItemSecondaryAction>
          {auth.isAuthenticated &&
            auth.user &&
            userAccount &&
            userAccount.id === auth.user.id && (
              <IconButton onClick={() => deleteComment(projectId, id)}>
                <DeleteTwoToneIcon className={classes.deleteIcon} />
              </IconButton>
            )}
        </ListItemSecondaryAction>
      </ListItem>
    </Fragment>
  );
};

CommentItem.propTypes = {
  projectId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteComment })(CommentItem);
