import {
  JUDGE_VALIDATED,
  JUDGE_ACCEPT_TERMS,
  JUDGE_GET_SUBMISSION,
  JUDGE_SAVE_SCORES,
  UPDATE_SUBMISSION_INDEX,
  GET_PREVIOUS_SUBMISSION,
  CLEAR_CURRENT_SUBMISSION,
  SET_JUDGE_LOADING,
  CLEAR_ALL_JUDGING,
  UPDATE_DIRTY_SCORE,
  GET_ALL_JUDGES,
  GET_ALL_CRITERIA,
  ADD_JUDGE,
  EDIT_JUDGE,
  ADD_CRITERIA,
  EDIT_CRITERIA,
  SET_ALL_ACTIVE_STATUS,
  GET_JUDGE_SCORING_PROGRESS,
  AUTHENTICATE_JUDGE,
  GET_JUDGE_SCORES,
  LOADING_JUDGE_SCORES,
  UPDATE_GROUPS_JUDGE,
  DELETE_JUDGE,
  DELETE_CRITERIA,
  CLEAR_JUDGE_SUBMISSION,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  judgeDetails: null,
  judgeAuth: {
    isAuthenticated: null,
    judgeId: null,
  },
  challengeDetails: null,
  submissions: [],
  judgingCriteria: [],
  currentIndex: 0,
  currentSubmission: null,
  acceptedTerms: null,
  dirtyState: false,
  judgeScores: {
    loading: false,
    data: null,
    error: null,
  },
  judges: [],
  judgesScoringProgress: null,
  criterias: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_JUDGE_SCORES: {
      return {
        ...state,
        judgeScores: {
          ...state.judgeScores,
          loading: true,
          data: null,
          error: null,
        },
      };
    }
    case DELETE_CRITERIA: {
      let newCriterias = [...state.criterias];
      newCriterias = newCriterias.filter((elem) => elem.id !== payload);
      return {
        ...state,
        criterias: newCriterias,
      };
    }
    case DELETE_JUDGE: {
      let newJudges = [...state.judges];
      newJudges = newJudges.filter((elem) => elem.id !== payload);
      return {
        ...state,
        judges: newJudges,
      };
    }
    case UPDATE_GROUPS_JUDGE: {
      const newJudges = state.judges.map((judge) => {
        if (judge.id === payload.id) {
          return {
            ...judge,
            groups: payload.groups,
          };
        }
        return judge;
      });
      return {
        ...state,
        loading: false,
        judges: newJudges,
      };
    }
    case GET_JUDGE_SCORES: {
      return {
        ...state,
        judgeScores: {
          ...state.judgeScores,
          loading: false,
          error: null,
          data: payload,
        },
      };
    }
    case ADD_JUDGE:
      return {
        ...state,
        judges: [payload, ...state.judges],
        loading: false,
      };
    case EDIT_JUDGE:
      if (state.judges && state.judges.length > 0) {
        let preJudges = [...state.judges];
        const indx = state.judges.findIndex((elem) => elem.id === payload.id);
        preJudges[indx] = {
          ...preJudges[indx],
          ...payload,
        };
        return {
          ...state,
          loading: false,
          judges: preJudges,
        };
      } else {
        return {
          ...state,
          loading: false,
          judges: [payload],
        };
      }
    case GET_JUDGE_SCORING_PROGRESS: {
      return {
        ...state,
        loading: false,
        judgesScoringProgress: payload,
      };
    }
    case AUTHENTICATE_JUDGE: {
      return {
        ...state,
        judgeAuth: {
          ...state.judgeAuth,
          isAuthenticated: payload.isAuthenticated,
          judgeId: payload.challengeJudgeId,
        },
      };
    }
    case SET_ALL_ACTIVE_STATUS: {
      let newJudges = [...state.judges];
      newJudges = newJudges.map((judge) => {
        return { ...judge, isActive: payload };
      });
      return {
        ...state,
        judges: newJudges,
      };
    }
    case GET_ALL_JUDGES:
      return {
        ...state,
        loading: false,
        judges: payload,
      };

    case ADD_CRITERIA:
      return {
        ...state,
        criterias: [payload, ...state.criterias],
        loading: false,
      };
    case EDIT_CRITERIA:
      if (state.criterias && state.criterias.length > 0) {
        let preCriterias = [...state.criterias];
        const indx = state.criterias.findIndex(
          (elem) => elem.id === payload.id
        );
        preCriterias[indx] = {
          ...preCriterias[indx],
          ...payload,
        };
        return {
          ...state,
          loading: false,
          criterias: preCriterias,
        };
      } else {
        return {
          ...state,
          loading: false,
          criterias: [payload],
        };
      }
    case GET_ALL_CRITERIA:
      return {
        ...state,
        criterias: payload,
      };
    case JUDGE_VALIDATED:
      return {
        ...state,
        loading: false,
        judgeDetails: payload.judgeDetails,
        submissions: payload.challengeSubmissions,
        judgingCriteria: payload.judgingCriteria,
        challengeDetails: payload.challengeDetails,
      };
    case SET_JUDGE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case CLEAR_ALL_JUDGING: {
      return {
        ...state,
        loading: false,
        judgeDetails: null,
        challengeDetails: null,
        submissions: [],
        judgingCriteria: [],
        currentIndex: 0,
        currentSubmission: null,
        acceptedTerms: null,
        dirtyState: false,
        judges: null,
        criterias: null,
      };
    }
    case UPDATE_SUBMISSION_INDEX:
      return {
        ...state,
        loading: false,
        currentIndex: payload,
      };
    case GET_PREVIOUS_SUBMISSION:
      return {
        ...state,
        loading: false,
        currentSubmission: payload,
      };
    case JUDGE_ACCEPT_TERMS:
      return {
        ...state,
        loading: false,
        acceptedTerms: payload,
      };

    case CLEAR_JUDGE_SUBMISSION: {
      return {
        ...state,
        currentSubmission: null,
      };
    }

    case JUDGE_GET_SUBMISSION:
      return {
        ...state,
        loading: false,
        currentSubmission: {
          ...payload.submissionDetails,
          project: payload.projectDetails,
        },
      };
    case UPDATE_DIRTY_SCORE: {
      return {
        ...state,
        dirtyState: payload,
      };
    }
    case CLEAR_CURRENT_SUBMISSION:
      return {
        ...state,
        loading: false,
        currentSubmission: null,
        dirtyState: false,
      };

    case JUDGE_SAVE_SCORES:
      return {
        ...state,
        loading: false,
        dirtyState: false,
        currentSubmission: {
          ...state.currentSubmission,
          submissionScore: [...payload],
        },
      };
    default:
      return state;
  }
}
