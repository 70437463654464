import React, { useState } from "react";
// React router dom
import { useLocation, useParams } from "react-router";
// hooks
import {
  useCompanyContext,
  useCommunityMemberContext,
} from "./CommunityContext";
// Material UI
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// Icons
// Components
import Spinner from "../../../shared/Spinner";
import CommunitySidebar from "../../../shared/CommunitySidebar";
import CommunityLock from "../../../shared/CommunityLock";
import ManageAppBar from "./ManageAppBar";
// Material UI
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
// Layout
import UserDashboardLayout from "../../UserDashboard";
// Utils
import { MEMBER_STATUS } from "../../../utils/globalValues";

const CommunityTheme = ({ primaryColor, children }) => {
  const isHex = primaryColor && /^#([0-9A-F]{3}){1,2}$/i.test(primaryColor);
  return (
    <ThemeProvider
      theme={(theme) =>
        createMuiTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              main: (isHex && primaryColor) || theme.palette.primary.main,
            },
          },
        })
      }
    >
      {children}
    </ThemeProvider>
  );
};

const Community = (props) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const companyDetailsQuery = useCompanyContext();
  const communityMemberQuery = useCommunityMemberContext();

  const { communitySlug } = useParams();
  const location = useLocation();

  const companyDetailsData = companyDetailsQuery?.data?.data;

  const primaryColor = companyDetailsData?.primaryColor;

  const memberInfo = communityMemberQuery?.data?.data;

  const showPageDetails =
    !memberInfo?.isBanned &&
    (!companyDetailsData?.isCommunityEnabled ||
      !companyDetailsData?.doMembersNeedApproval ||
      memberInfo?.approvalStatus === MEMBER_STATUS.APPROVED);

  return (
    <UserDashboardLayout>
      <CommunityTheme primaryColor={primaryColor}>
        <Box width="100%" height="100%">
          {companyDetailsData && (
            <ManageAppBar companyDetails={companyDetailsData} />
          )}
          {companyDetailsQuery && companyDetailsQuery.isError && (
            <Alert severity="error">
              {companyDetailsQuery.error &&
                companyDetailsQuery.error.data &&
                companyDetailsQuery.error.data.msg}
            </Alert>
          )}
          {communityMemberQuery.isError && (
            <Alert severity="error">
              {communityMemberQuery.error &&
                communityMemberQuery.error.data &&
                communityMemberQuery.error.data.msg}
            </Alert>
          )}
          {(companyDetailsQuery && companyDetailsQuery.isLoading) ||
          communityMemberQuery.isLoading ? (
            <Spinner />
          ) : (
            companyDetailsQuery &&
            companyDetailsQuery.data && (
              <>
                <Box
                  display="flex"
                  flexDirection={mobileScreen ? "column" : "row"}
                  height="100%"
                  width="100%"
                >
                  {location?.pathname !== `/c/${communitySlug}/invite` && (
                    <CommunitySidebar
                      companyDetailsQuery={companyDetailsQuery}
                      communityMemberQuery={communityMemberQuery}
                      showPageDetails={showPageDetails}
                    />
                  )}
                  <Box
                    flexGrow={1}
                    height="100%"
                    maxHeight="100vh"
                    overflow="auto"
                    pb={6}
                  >
                    {location?.pathname !== `/c/${communitySlug}/invite` &&
                    location?.pathname !== `/c/${communitySlug}` &&
                    !showPageDetails ? (
                      <CommunityLock companyDetails={companyDetailsData} />
                    ) : (
                      React.Children.map(props.children, (child) =>
                        React.cloneElement(child, {
                          companyDetailsQuery: companyDetailsQuery,
                          communityMemberQuery: communityMemberQuery,
                        })
                      )
                    )}
                  </Box>
                </Box>
              </>
            )
          )}
        </Box>
      </CommunityTheme>
    </UserDashboardLayout>
  );
};

export default Community;
