import axios from "../../utils/axios";
import { handleServerError } from "./error";
import { LOADING_CHALLENGES } from "../actions/actionTypes";

export const exportCustomSubmissions = (challengeRoundId, fields) => async (
  dispatch
) => {
  try {
    const body = {
      challengeRoundId,
      fields,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/reports/downloadSubmissionsCustom`,
      body,
      config
    );

    return res.data;
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const exportProjectSubmissions = (challengeRoundId, fields) => async (
  dispatch
) => {
  try {
    const body = {
      challengeRoundId,
      fields,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/reports/downloadSubmissionsProject`,
      body,
      config
    );
    return res.data;
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const downloadResults = (challengeRoundId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = { challengeRoundId };

    const res = await axios.post(`/api/reports/downloadResults/`, body, config);
    if (res) {
      return res.data;
    } else {
      return "";
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};

export const downloadJudgesWithScores = (challengeRoundId) => async (
  dispatch
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = { challengeRoundId };

    const res = await axios.post(
      `/api/reports/downloadJudgesWithScores/`,
      body,
      config
    );
    if (res) {
      return res.data;
    } else {
      return "";
    }
  } catch (error) {
    dispatch(handleServerError(error));
  }
};
