import React, { useState } from "react";
// Redux
import { connect } from "react-redux";
// hooks
import { usefetchChallengeRound } from "../../../hooks/insights";
// Material UI
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
// Components
import Chart from "../../../shared/Chart";
import WeekPicker from "../../../shared/DatePickers/WeekPicker";
import DateRangePicker from "../../../shared/DatePickers/DateRangePicker";
// date fns
import startOfWeek from "date-fns/startOfWeek";
import addWeeks from "date-fns/addWeeks";
import format from "date-fns/format";

const ChallengeInsightsSubmissions = ({ challenge }) => {
  const theme = useTheme();

  // const [date, datePicker] = useState(new Date());
  const [dateRange, setDateRange] = useState({
    startDate: startOfWeek(new Date()),
    endDate: startOfWeek(addWeeks(new Date(), 1)),
  });

  const [roundIndex, setRoundIndex] = useState(0);

  const handleRoundChange = (e) => {
    setRoundIndex(e.target.value);
  };

  const {
    isLoading,
    isError,
    data: { data: data } = { data: null },
    error,
  } = usefetchChallengeRound(challenge.round[roundIndex].id, dateRange);
  // Used for reponsive css in submissions/registrations converrts flexDirection from column to row
  // Returns boolean based on if the screen size is sm or less
  const isScreenSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box my={2}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} />
          {/* <WeekPicker date={date} datePicker={datePicker} /> */}
        </Box>
        <Box>
          <InputLabel id="challenge-round-insights-select-label">
            Round
          </InputLabel>
          <FormControl>
            <Select
              labelId="challenge-round-insights-select-label"
              id="challenge-round-insights-select"
              value={roundIndex}
              onChange={handleRoundChange}
              variant="outlined"
              margin="dense"
            >
              {challenge &&
                challenge.round &&
                challenge.round.length > 0 &&
                challenge.round.map((round, index) => {
                  return (
                    <MenuItem key={index} value={index}>
                      {round.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {isLoading ? (
        <Box mt={2}>
          <Skeleton
            animation="wave"
            variant="rect"
            width={"100%"}
            height={400}
          />
        </Box>
      ) : isError ? (
        <Alert severity="error">{error && error.data && error.data.msg}</Alert>
      ) : (
        <>
          {data ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={9}>
                <Chart
                  data={data?.submissionsDateData}
                  title="Submissions"
                  subtitle={`${format(
                    dateRange.startDate,
                    "MMM dd, yyyy"
                  )} - ${format(dateRange.endDate, "MMM dd, yyyy")}`}
                  type="time-series"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ gap: "16px" }}
                >
                  <Chart
                    type="number-title"
                    data={[
                      {
                        count: data?.submissionsInTimePeriod,
                        name: `Submissions`,
                        subText: `${format(
                          dateRange.startDate,
                          "MMM dd, yyyy"
                        )} - ${format(dateRange.endDate, "MMM dd, yyyy")}`,
                      },
                    ]}
                    orientation={isScreenSmOrSmaller ? "row" : "column"}
                  />
                  <Chart
                    type="number-title"
                    data={[
                      {
                        count: data?.totalSubmissions,
                        name: `Total Submissions`,
                      },
                      {
                        count: data?.totalReviewers,
                        name: `Total Reviewers`,
                      },
                    ]}
                    orientation={isScreenSmOrSmaller ? "row" : "column"}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box textAlign="center" width="100%" py={{ xs: 2, md: 4 }}>
              <Typography variant="body1" component="div">
                <Box fontWeight={400}>
                  Your challenge has no registrations at the moment, please
                  comeback when more people register.
                </Box>
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  challenge: state.challenge.challenge,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeInsightsSubmissions);
