import axios from "../utils/axios";
import { useQuery, useMutation } from "react-query";

export const useGetProjectTotalForUser = (userAccountId) => {
  const result = useQuery(
    ["userProjectTotal", { userAccountId: userAccountId }],
    getProjectTotalForUser,
    { enabled: !!userAccountId }
  );
  if (result.isError) {
    console.log("Error");
  }

  return result;
};

export const getProjectTotalForUser = async ({ queryKey }) => {
  const [_key, { userAccountId }] = queryKey;
  return await axios.get(`/api/project/${userAccountId}/total`);
};
