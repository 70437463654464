// icons
import ShortTextIcon from "@material-ui/icons/ShortText";
import SubjectIcon from "@material-ui/icons/Subject";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LinkIcon from "@material-ui/icons/Link";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PaletteIcon from "@material-ui/icons/Palette";

export const formFieldConfig = {
  properties: {
    shortText: {
      name: "Short Text",
      icon: ShortTextIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      jsonSchema: {
        type: "string",
        title: "Short Text Title",
        description: "Description for the short text field",
      },
      uiSchema: {},
    },
    multiline: {
      name: "Multiline Text",
      icon: SubjectIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      jsonSchema: {
        type: "string",
        title: "Multiline Text Title",
        description: "Description for the long text field",
      },
      uiSchema: { "ui:widget": "textarea" },
    },
    file: {
      name: "File Upload",
      icon: CloudUploadIcon,
      about:
        "Collect files such as PDF, CSV, Excel, Word, PowerPoint, Image, etc.",
      jsonSchema: {
        type: "string",
        title: "Upload a file",
        description: "Description for the file upload field",
      },
      uiSchema: {
        "ui:widget": "file",
        "ui:options": {
          accept:
            ".pdf,.doc,.docx,.pptx,.ppt,.xlsx,.xls,.csv,.png,.jpg,.jpeg,.gif,.svg,.psd,.ai,.mp4,.mov,.mp3,.wav,.txt,.md,.tex,.zip,.py,.ipynb,.js,.html",
        },
      },
    },
    email: {
      name: "Email Address",
      icon: AlternateEmailIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      jsonSchema: {
        format: "email",
        type: "string",
        title: "Email",
        description: "Description for the email address field",
      },
      uiSchema: {},
    },
    uri: {
      name: "Url Address",
      icon: LinkIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      jsonSchema: {
        format: "uri",
        type: "string",
        title: "Url",
        description: "Description for the url address field",
      },
      uiSchema: {},
    },
    number: {
      name: "Number",
      icon: FormatListNumberedIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      jsonSchema: {
        type: "number",
        title: "Number",
        description: "Description for the number field",
      },
      uiSchema: {},
    },
    date: {
      name: "Date",
      icon: CalendarTodayIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      jsonSchema: {
        format: "date",
        about: "Add a date picker to your form",
        type: "string",
        title: "Date",
        description: "Description for the date field",
      },
      uiSchema: {},
    },
    checkboxes: {
      name: "Checkboxes",
      icon: CheckBoxIcon,
      switchTo: ["checkboxes", "multiselect", "select", "radio"],
      about: "One or more checkboxes one after the other",
      jsonSchema: {
        title: "Checkboxes Title",
        type: "array",
        description: "Description for the checkboxes field",
        uniqueItems: true,
      },
      uiSchema: {
        "ui:widget": "checkboxes",
      },
    },
    multiselect: {
      name: "Multiselect",
      icon: ArrowDropDownCircleIcon,
      switchTo: ["checkboxes", "multiselect", "select", "radio"],
      about: "A multiple select / dropdown list",
      jsonSchema: {
        title: "Multiselect Title",
        type: "array",
        description: "Description for the multiselect field",
        uniqueItems: true,
      },
      uiSchema: {},
    },
    select: {
      name: "Select",
      icon: ArrowDropDownIcon,
      switchTo: ["checkboxes", "multiselect", "select", "radio"],
      about: "A single select / dropdown list",
      jsonSchema: {
        title: "Select Title",
        type: "string",
        description: "Description for the select field",
        uniqueItems: true,
      },
      uiSchema: {},
    },
    radio: {
      name: "Radio",
      icon: RadioButtonCheckedIcon,
      switchTo: ["checkboxes", "multiselect", "select", "radio"],
      about: "One or more radio buttons one after the other",
      jsonSchema: {
        title: "Radio Title",
        type: "string",
        description: "Description for the radio field",
        uniqueItems: true,
      },
      uiSchema: {
        "ui:widget": "radio",
      },
    },
    color: {
      name: "Color Picker",
      icon: PaletteIcon,
      switchTo: [
        "shortText",
        "multiline",
        "email",
        "uri",
        "number",
        "date",
        "color",
      ],
      about: "Add a color picker to your form",
      jsonSchema: {
        type: "string",
        title: "Color Picker Title",
        description: "Description for the color picker field",
      },
      uiSchema: {
        "ui:widget": "color",
      },
    },
  },
};

export const defaultJsonSchema = {
  title: "",
  description: "",
  type: "object",
  required: [],
  properties: {},
};

export const defaultUiSchema = {
  "ui:order": [],
};

export const defaultWidget = {};
