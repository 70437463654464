import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
// react query
import { useMutation, useQueryClient } from "react-query";
import { duplicateEvent } from "../../../hooks/event";
// Material UI
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
// Components
import Button from "../../../shared/Button";
import Card from "../../../shared/Card";
// Icons
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import PublicOutlinedIcon from "@material-ui/icons/PublicOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
// Utils
import ReactImageFallback from "react-image-fallback";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { DEFAULT_TIME_ZONE, EVENT_ONLINE } from "../../../utils/globalValues";
import { convertUTCTimeToZonedTime } from "../../../utils/utilFunc";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    objectFit: "cover",
  },
}));

const OrgEventCard = ({ event, companyProfile }) => {
  const classes = useStyles();

  const { params } = useRouteMatch();

  const queryClient = useQueryClient();

  const duplicateEventMutation = useMutation(duplicateEvent, {
    onSuccess: (data) => {
      if (data.data) {
        queryClient.setQueryData(
          ["orgEvents", { companyId: event.companyId }],
          (prevEvents) => {
            return {
              ...prevEvents,
              data: [data.data, ...prevEvents.data],
            };
          }
        );
      }
    },
  });

  const handleDuplicateEvent = () => {
    if (
      window.confirm("Are you sure you would like to duplicate this event?")
    ) {
      duplicateEventMutation.mutate({ eventToDuplicateId: event.id });
    }
  };

  return (
    <>
      <Card
        key={event.id}
        borderRadius="borderRadius"
        bgcolor={
          event.isActive && isBefore(new Date(), new Date(event.endDateTime))
            ? "background.paper"
            : "action.disabledBackground"
        }
        p={{ xs: 2, md: 4 }}
        mb={{ xs: 2 }}
        width="100%"
      >
        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} md={4} xl={3}>
            <Box
              style={{
                position: "relative",
                paddingBottom: "56.2%",
              }}
            >
              <ReactImageFallback
                src={event.headerImagePath}
                fallbackImage="https://raw.githubusercontent.com/Pralent/CMS/master/default-background.png"
                alt={`${event.title}-header-image`}
                className={classes.root}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} xl={9}>
            <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
              <Box>
                <Typography variant="h4" component="h3">
                  <Box fontWeight={600}>{event.title}</Box>
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="textSecondary"
                >
                  <Box fontWeight={600} display="flex" alignItems="center">
                    <Box mr={1}>
                      <EventIcon />
                    </Box>{" "}
                    {event.startDateTime &&
                      convertUTCTimeToZonedTime(
                        event.startDateTime,
                        DEFAULT_TIME_ZONE
                      )}{" "}
                    to{" "}
                    {event.endDateTime &&
                      convertUTCTimeToZonedTime(
                        event.endDateTime,
                        DEFAULT_TIME_ZONE
                      )}
                  </Box>
                </Typography>
              </Box>
              <Box>
                {!event.isActive && (
                  <Chip
                    icon={<NotInterestedOutlinedIcon />}
                    label="Inactive"
                    color="default"
                  />
                )}
                {event.requireRegistration &&
                  event.registrationDeadlineDatetime && (
                    <Chip
                      icon={<EventIcon />}
                      label={`Register by ${convertUTCTimeToZonedTime(
                        event.registrationDeadlineDatetime,
                        DEFAULT_TIME_ZONE
                      )}`}
                      color="default"
                    />
                  )}
                {isAfter(new Date(), new Date(event.endDateTime)) && (
                  <Chip
                    icon={<EventBusyOutlinedIcon />}
                    label="Past Event"
                    color="default"
                  />
                )}
                <Chip
                  icon={<GroupOutlinedIcon />}
                  label={
                    <Box>
                      {(event.userEventActivity &&
                        event.userEventActivity.length) ||
                        0}{" "}
                      Attendees
                    </Box>
                  }
                  color="default"
                />
                <Chip
                  icon={
                    event.online === EVENT_ONLINE.INPERSON ? (
                      <PeopleOutlineOutlinedIcon />
                    ) : (
                      <VideocamOutlinedIcon />
                    )
                  }
                  label={
                    event.online === EVENT_ONLINE.ONLINE
                      ? "Online"
                      : event.online === EVENT_ONLINE.HYBRID
                      ? "Hybrid"
                      : "In Person"
                  }
                  color="default"
                />
                {event.isPublicToPralent && (
                  <Chip
                    icon={<PublicOutlinedIcon />}
                    label="Public To Pralent"
                    color="default"
                  />
                )}
              </Box>
              <Box display="flex" style={{ gap: 4 }}>
                <Button
                  component={Link}
                  to={`/org/${params.orgId}/events/${event.slug}/attendees`}
                  startIcon={<EditIcon />}
                >
                  Manage
                </Button>
                <Button
                  component={Link}
                  to={`/c/${companyProfile.communitySlug}/events/${event.slug}`}
                  variant="outlined"
                  startIcon={<OpenInNewOutlinedIcon />}
                >
                  Preview
                </Button>
                <Button
                  onClick={handleDuplicateEvent}
                  variant="outlined"
                  color="default"
                  startIcon={<FileCopyOutlinedIcon />}
                >
                  Duplicate
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  companyProfile: state.company.companyProfile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrgEventCard);
